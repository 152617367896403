import React from 'react';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';

const TableV6 = ({
	minRows = 0,
	columns,
	data,
	loading,
	pageSize,
	onSortedChange,
	manual = true,
	resizable = false,
	NoDataText
}) => {
	return (
		<ReactTable
			minRows={minRows}
			columns={columns}
			data={data}
			loading={loading}
			pageSize={pageSize}
			// onSortedChange={onSortedChange}
			manual={manual}
			resizable={resizable}
			showPagination={false}
			NoDataComponent={() => (
				<span className="rtNoDataFound fs-12 regular-text">
					{NoDataText ? NoDataText : 'No data found'}
				</span>
			)}
		/>
	);
};

export default TableV6;
