import styled from 'styled-components';
import sectionImage from '../../../Assets/images/section-common-back.png';

const VenuePortalLoginWrraper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	padding-right: 45px !important;

	.containCover {
		margin-top: 38px;
	}

	.inputContainWrraper {
		padding-bottom: 60px;
	}

	.headingStyle {
		padding-top: 30px;
		font-size: 42px;
		font-weight: 700;
		font-family: 'montserrat-bold' !important;
		color: #313132eb;
		padding-bottom: 3%;
	}

	.helperLink {
		user-select: none;
		padding-top: 10px;
		text-align: center;
		font-size: 24px;
		font-weight: 400;
		color: #000000;
		font-family: 'poppins-regular';
		.helperLinkSpan {
			cursor: pointer;
			font-family: 'montserrat-semi-bold';
			color: #f95c69;
		}
	}

	.policyText {
		text-align: center;
		padding-bottom: 10px;
		font-size: 20px;
		font-family: 'montserrat-semi-bold' !important;
		color: #000000;
		.policyTextTermsConditions,
		.policyTextPrivacy {
			cursor: pointer;
			color: #f95c69;
			font-family: 'montserrat-semi-bold' !important;
		}
	}

	@media (max-width: 600px) {
		padding-right: 0px !important;
		.containCover {
			margin-top: 17.5px;
		}
		.headingStyle {
			font-size: 22px;
			padding-bottom: 10%;
		}
		.inputContainWrraper {
			padding-bottom: 25px;
		}
		.helperLink {
			font-size: 11.5px;
		}
		.policyText {
			font-size: 11.5px;
		}
	}

	@media only screen and (min-width: 600px) and (max-width: 960px) {
		padding-right: 0px !important;
		.containCover {
			margin-top: 28px;
		}
		.headingStyle {
			font-size: 31.5px;
			padding-bottom: 7%;
		}
		.inputContainWrraper {
			padding-bottom: 40px;
		}
		.helperLink {
			font-size: 18px;
		}
		.policyText {
			font-size: 15px;
		}
	}

	@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
		.containCover {
			margin-top: 21.3px;
		}
		.headingStyle {
			padding-top: 30px;
			font-size: 23.6px;
		}

		.helperLink {
			font-size: 13.5px;
		}

		.policyText {
			font-size: 11.25px;
		}

		.inputContainWrraper {
			padding-bottom: 30px;
		}
	}
`;
export default VenuePortalLoginWrraper;
