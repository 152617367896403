import styled from 'styled-components';

const PageWrapper = styled.div`
	background-color: #fffdfd;
	color: ${(props) => props.layoutTheme.textColor} !important;
	.defaultBoxShadow {
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
	}
	.dataCard {
		border-radius: 16px;
		background-color: #ffffff;
	}
	.totalPerformingCard {
		border-radius: 16px;
		background-color: #ffffff;
		.percentage {
			width: 72px;
			display: flex;
			justify-content: end;
		}
	}
	@media only screen and (max-width: 767px) {
		padding: 12px;
		.border-radius-16 {
			border-radius: 0px !important;
		}
		.defaultBoxShadow {
			box-shadow: unset;
		}
	}
`;

export default PageWrapper;
