import OrderCard from '../OrderCard';

const NewOrders = ({ data, getVenueOrderList, viewOnly }) => {
	return (
		<div className="d-flex flex-column gap-4">
			{data?.length > 0 &&
				data?.map((item, index) => (
					<OrderCard
						key={index}
						index={index}
						orders={data}
						data={item}
						getVenueOrderList={getVenueOrderList}
						viewOnly={viewOnly}
					/>
				))}
		</div>
	);
};

export default NewOrders;
