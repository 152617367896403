import styled from 'styled-components';

export const MapWrapper = styled.div`
	height: 400px;
	position: relative;
	.searchInputWrapper {
		position: absolute;
		top: 12px;
		left: 12px;
		right: 12px;
		z-index: 10;
		.inputBox {
			background-color: white !important;
			box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04) !important;
		}
	}
	.suggestionsWrapper {
		position: absolute;
		top: 56px;
		left: 12px;
		right: 12px;
		z-index: 10;
		background-color: white;
		border-radius: 6px;
		.suggestion {
			padding: 7px 12px;
			font-family: 'montserrat-medium' !important;
			font-size: 12px;
			&:hover {
				background-color: #ff5f5f !important;
				color: white;
			}
			cursor: pointer;
		}
	}
	.gm-fullscreen-control {
		display: none;
	}
	.gm-style-mtc {
		display: none;
	}
	.gm-svpc {
		display: none;
	}
`;
