import TableV6 from '../../../Common/TableV6';
import TableStyle from '../../../Common/TableStyle';
import { Pagination } from '../../../Common/Pagination';
import TableSkeleton from './TableSkeleton';

const TaxesTable = ({
	loading,
	tableData,
	tableColumns,
	handlePageChange,
	totalRows,
	currentPage
}) => {
	return (
		<div>
			{loading ? (
				<>
					<TableSkeleton />
				</>
			) : (
				<TableStyle version={6}>
					<TableV6
						columns={tableColumns}
						data={tableData}
						NoDataText={'No data found'}
					/>
					{/* {tableData?.length > 0 && (
						<Pagination
							handlePageChange={handlePageChange}
							total={totalRows}
							currentPage={currentPage}
						/>
					)} */}
				</TableStyle>
			)}
		</div>
	);
};

export default TaxesTable;
