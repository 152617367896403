import styled from 'styled-components';

const ImageUploadWrapper = styled.div`
	.imageContainer {
		width: 100%;
		height: 215px;
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: center;
		justify-content: center;
		border: 1.5px solid #31313259;
		border-style: dashed;
		.imageSelectionLabel {
			position: absolute;
			z-index: 1;
			text-align: center;
		}
		.image {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border: 0px !important;
			outline: 0px !important;
			position: absolute;
		}
		img {
			border: 0px !important;
			outline: 0px !important;
		}
		.suggestionParagraph {
			font-size: 13px;
			max-width: 20rem;
			color: #31313259;
			margin-bottom: 20px;
		}

		.sugesstion0 {
			font-size: 20px;
			font-weight: 600;
			color: #313132eb;
		}

		.sugesstion1 {
			margin-top: 8px;
			font-size: 14px;
			font-weight: 600;
			color: #31313259;
		}

		.icon {
			position: absolute;
			width: 27px;
			height: 26px;
			// background-color: #ff5f5f;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 100%;
			bottom: -10px;
			right: -10px;
			cursor: ${(props) => (props.disabled ? '' : 'pointer')};
		}
	}
`;

export default ImageUploadWrapper;
