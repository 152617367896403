import ComingSoon from '../Components/Common/ComingSoon';
import {
	Security,
	StaticPage,
	RoleAndPermission,
	Dashboard,
	Live,
	Todo,
	OrderHistory,
	ManageMenu,
	VenueSalesAnalytics,
	PerformanceAnalytics,
	CustomerAnalytics,
	Home,
	SummaryReport,
	FinancialReport,
	Settings,
	VenueCalender,
	VenueSecurity,
	Legal,
	ConnectVenue,
	Support,
	PosIntegration,
	Rewards,
	InAppAdvertising,
	MyTabStaff,
	AddStaff,
	DocketFeatures,
	DocketPrinters,
	Subscription,
	ManageMenuItem,
	VenueAccount,
	RegisterSubscription,
	ManageMenuItemForm,
	VenueDashboard,
	VenueProfile
} from '../Pages';
import { AdminPanelRoutes, VenuePanelRoutes } from '../Utils/routes';
import { LAYOUT } from '../Helper/constant';
import AdminRoute from '../Components/Common/RouteWrappers/AdminRoute';
import VenueRoute from '../Components/Common/RouteWrappers/VenueRoute';
import { ManageAccount } from '../Pages/UserProfile/ManageAccount';

export const adminRoutes = [
	{
		layout: LAYOUT.dashboard,
		path: AdminPanelRoutes.live,
		component: (
			<AdminRoute>
				<Live />
			</AdminRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: AdminPanelRoutes.dashboard,
		component: (
			<AdminRoute>
				<Dashboard />
			</AdminRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: AdminPanelRoutes.editProfile,
		component: (
			<AdminRoute>
				<ManageAccount />
			</AdminRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: AdminPanelRoutes.security,
		component: (
			<AdminRoute>
				<Security />
			</AdminRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: AdminPanelRoutes.staticPage,
		component: (
			<AdminRoute>
				<StaticPage />
			</AdminRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: AdminPanelRoutes.rolePermission,
		component: (
			<AdminRoute>
				<RoleAndPermission />
			</AdminRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: AdminPanelRoutes.todo,
		component: (
			<AdminRoute>
				<Todo />
			</AdminRoute>
		)
	}
];

export const venueRoutes = [
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.home,
		component: (
			<VenueRoute>
				<Home />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.dashboard,
		component: (
			<VenueRoute>
				<VenueDashboard />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.manageAccount,
		component: (
			<VenueRoute>
				<ManageAccount />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.venueProfile,
		component: (
			<VenueRoute>
				<VenueProfile />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.connectVenue,
		component: (
			<VenueRoute>
				<ConnectVenue />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.createVenueAccount,
		component: (
			<VenueRoute>
				<VenueAccount />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.mytabStaff,
		component: (
			<VenueRoute>
				<ComingSoon pageTitle="MyTab Staff" />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.addMytabStaff,
		component: (
			<VenueRoute>
				<AddStaff />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.security,
		component: (
			<VenueRoute>
				<Security />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.staticPage,
		component: (
			<VenueRoute>
				<StaticPage />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.orderHistory,
		component: (
			<VenueRoute>
				<OrderHistory />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.financialReport,
		component: (
			<VenueRoute>
				<FinancialReport />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.summaryReport,
		component: (
			<VenueRoute>
				<SummaryReport />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.salesAnalytics,
		component: (
			<VenueRoute>
				<ComingSoon pageTitle="Sales Analytics" />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.performanceAnalytics,
		component: (
			<VenueRoute>
				<ComingSoon pageTitle="Performance Analytics" />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.customerAnalytics,
		component: (
			<VenueRoute>
				<ComingSoon pageTitle="Customer Analytics" />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.settings,
		component: (
			<VenueRoute>
				<Settings />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.calender,
		component: (
			<VenueRoute>
				<ComingSoon pageTitle="Calender" />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.venueSecurity,
		component: (
			<VenueRoute>
				<VenueSecurity />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.legal,
		component: (
			<VenueRoute>
				<Legal />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.support,
		component: (
			<VenueRoute>
				<Support />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.docketFeatures,
		component: (
			<VenueRoute>
				<DocketFeatures />
			</VenueRoute>
		)
	},
	{
		path: VenuePanelRoutes.docketPrinters,
		component: <DocketPrinters />
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.posIntegration,
		component: (
			<VenueRoute>
				<PosIntegration />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.rewards,
		component: (
			<VenueRoute>
				<ComingSoon pageTitle={'Rewards'} />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.advertising,
		component: (
			<VenueRoute>
				<ComingSoon pageTitle={'In App Advertising'} />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.manageMenu,
		component: (
			<VenueRoute>
				<ManageMenu />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.addManageMenu,
		component: (
			<VenueRoute>
				<ManageMenuItemForm />
			</VenueRoute>
		)
	},
	{
		layout: LAYOUT.dashboard,
		path: VenuePanelRoutes.editManageMenu + '/:id',
		component: (
			<VenueRoute>
				<ManageMenuItemForm />
			</VenueRoute>
		)
	}
];
