import React, { useEffect, useRef, useState } from 'react';
import { Map, GoogleApiWrapper } from 'google-maps-react';
import { GOOGLE_MAP_KEY } from '../../../Helper/constant';
import { MapWrapper } from './index.style';

const GoogleMap = ({ google, getSelectedLocation }) => {
	const mapRef = useRef(null);
	const markerRef = useRef(null);
	const searchInputRef = useRef(null);
	const autocompleteRef = useRef(null);
	const suggestionsRef = useRef(null);
	const [suggestions, setSuggestions] = useState([]);
	const [showSuggestion, setShowSuggestion] = useState(false);
	const [selectedLocation, setSelectedLocation] = useState({
		lat: null,
		lng: null,
		description: ''
	});
	useEffect(() => {
		const map = mapRef.current.map;
		// Initialize the Places Autocomplete service
		const autocomplete = new google.maps.places.Autocomplete(
			searchInputRef.current
		);
		autocompleteRef.current = autocomplete;
		autocomplete.addListener('place_changed', () => {
			const place = autocomplete.getPlace();
			if (!place.geometry) {
				return;
			}
			// Remove the previous marker if it exists
			if (markerRef.current) {
				markerRef.current.setMap(null); // Remove the marker from the map
			}
			// Create a new marker at the selected location
			const newMarker = new google.maps.Marker({
				position: place.geometry.location,
				map: map,
				title: place.name
			});
			// Set the new marker in the marker ref
			markerRef.current = newMarker;
			// Center the map on the selected location
			map.panTo(place.geometry.location);
			// Update selectedLocation state
			setSelectedLocation({
				lat: place.geometry.location.lat(),
				lng: place.geometry.location.lng(),
				description: place.formatted_address
			});
			searchInputRef.current.value = place.formatted_address;
		});
		// Add a click event listener to the map
		map.addListener('click', (e) => {
			const clickedLat = e.latLng.lat();
			const clickedLng = e.latLng.lng();
			// Remove the previous marker if it exists
			if (markerRef.current) {
				markerRef.current.setMap(null); // Remove the marker from the map
			}
			// Create a new marker at the clicked location
			const newMarker = new google.maps.Marker({
				position: { lat: clickedLat, lng: clickedLng },
				map: map,
				title: 'New Marker'
			});
			// Set the new marker in the marker ref
			markerRef.current = newMarker;
			// Reverse geocode to get the location description
			const geocoder = new google.maps.Geocoder();
			geocoder.geocode(
				{ location: { lat: clickedLat, lng: clickedLng } },
				(results, status) => {
					if (
						status === google.maps.GeocoderStatus.OK &&
						results[0]
					) {
						// Get the first result (usually the most accurate)
						const description = results[0].formatted_address;
						// Update selectedLocation state with the description
						setSelectedLocation({
							lat: clickedLat,
							lng: clickedLng,
							description: description
						});
						searchInputRef.current.value = description;
					}
				}
			);
		});

		// Get the user's current location
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const userLocation = {
						lat: position.coords.latitude,
						lng: position.coords.longitude
					};
					// Create a marker at the user's current location
					const initialMarker = new google.maps.Marker({
						position: userLocation,
						map: map,
						title: 'Current Location'
					});
					// Set the initial marker in the marker ref
					markerRef.current = initialMarker;
					// Center the map on the user's location
					map.panTo(userLocation);
					// Reverse geocode to get the user's location description
					const geocoder = new google.maps.Geocoder();

					geocoder.geocode(
						{ location: userLocation },
						(results, status) => {
							if (
								status === google.maps.GeocoderStatus.OK &&
								results[0]
							) {
								// Get the first result (usually the most accurate)
								const description =
									results[0].formatted_address;

								// Update selectedLocation state with the description
								setSelectedLocation({
									lat: userLocation.lat,
									lng: userLocation.lng,
									description: description
								});
								searchInputRef.current.value = description;
							}
						}
					);
				},
				(error) => {
					console.error('Error getting user location:', error);
				},
				{ enableHighAccuracy: true }
			);
		} else {
			console.error('Geolocation is not available in this browser.');
		}
		// Add an event listener to the input for user input
		searchInputRef.current.addEventListener('input', () => {
			const query = searchInputRef.current.value;
			// Use the AutocompleteService to get location predictions
			autocompleteService.getPlacePredictions(
				{ input: query },
				(predictions) => {
					if (predictions) {
						// Store the suggestions in state
						setSuggestions(predictions);
					}
				}
			);
		});
		// Initialize the Places Autocomplete service for suggestions
		const autocompleteService =
			new google.maps.places.AutocompleteService();
	}, [google]);
	const handleSuggestionClick = (suggestion) => {
		// Set the value of the search input to the clicked suggestion
		searchInputRef.current.value = suggestion.description;
		// Use the Places Service to get details of the selected place
		const placesService = new google.maps.places.PlacesService(
			mapRef.current.map
		);
		placesService.getDetails(
			{ placeId: suggestion.place_id },
			(place, status) => {
				if (
					status === google.maps.places.PlacesServiceStatus.OK &&
					place
				) {
					// Remove the previous marker if it exists
					if (markerRef.current) {
						markerRef.current.setMap(null); // Remove the marker from the map
					}
					// Create a new marker at the selected location
					const newMarker = new google.maps.Marker({
						position: place.geometry.location,
						map: mapRef.current.map,
						title: place.name
					});
					// Set the new marker in the marker ref
					markerRef.current = newMarker;
					// Center the map on the selected location
					mapRef.current.map.panTo(place.geometry.location);
					// Update selectedLocation state
					setSelectedLocation({
						lat: place.geometry.location.lat(),
						lng: place.geometry.location.lng(),
						description: place.formatted_address
					});
					searchInputRef.current.value = place.formatted_address;
				}
			}
		);
		// Clear the suggestions
		setSuggestions([]);
	};
	useEffect(() => {
		if (searchInputRef?.current?.value) {
			setShowSuggestion(true);
		} else {
			setShowSuggestion(false);
		}
	}, [searchInputRef?.current?.value]);
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				suggestionsRef.current &&
				!suggestionsRef.current.contains(event.target)
			) {
				setShowSuggestion(false);
			}
		};
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);
	useEffect(() => {
		getSelectedLocation(selectedLocation);
	}, [selectedLocation]);
	return (
		<MapWrapper>
			<div className="searchInputWrapper">
				<input
					ref={searchInputRef}
					type="text"
					placeholder="Search for a location"
					className={`inputBox min-height-40 no-border fs-12 medium-text plr-12 w-100`}
				/>
			</div>
			{showSuggestion && (
				<div className="suggestionsWrapper" ref={suggestionsRef}>
					{suggestions?.length > 0 &&
						suggestions?.map((suggestion, index) => (
							<div
								key={suggestion?.id}
								className="suggestion"
								onClick={() =>
									handleSuggestionClick(suggestion)
								}
								style={{
									borderRadius: `${
										index === 0
											? '6px 6px 0 0'
											: index === suggestions?.length - 1
											? '0 0 6px 6px'
											: ''
									}`
								}}
							>
								{suggestion?.description}
							</div>
						))}
				</div>
			)}
			<Map
				ref={mapRef}
				google={google}
				zoom={14}
				style={{
					width: '100%',
					height: '400px',
					borderRadius: '6px'
				}}
			></Map>
		</MapWrapper>
	);
};

export default GoogleApiWrapper({
	apiKey: GOOGLE_MAP_KEY
})(GoogleMap);
