export const UserCircleIcon = ({ fill, stroke, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 52 52" fill="none">
			<circle cx="26" cy="26" r="25" stroke="#F9F9F9" strokeWidth="2" />
			<g clipPath="url(#clip0_3737_4630)">
				<g clipPath="url(#clip1_3737_4630)">
					<path
						d="M34.3805 22.9707C34.396 27.5464 30.5612 31.3247 25.9753 31.308C21.324 31.2717 17.463 27.5101 17.6136 22.5997C17.747 18.2497 21.3169 14.5363 26.0087 14.5393C30.6088 14.5429 34.3645 18.202 34.3805 22.9707Z"
						fill={fill ?? '#FF5F5F'}
					/>
					<path
						d="M25.7177 8.00183C15.8122 8.15131 7.98774 16.2855 8.00025 25.917C7.94843 35.9439 16.0296 43.8553 25.6867 43.9977C35.9738 44.1489 43.98 35.8426 43.9937 26.0605C44.008 15.8728 35.6886 7.85175 25.7177 8.00183ZM39.493 36.0802C39.3876 36.2232 39.3453 36.1624 39.2739 36.0654C38.424 34.9159 37.3633 34.013 36.1007 33.343C34.9554 32.735 33.7363 32.3931 32.4445 32.2901C31.9461 32.2508 31.4482 32.2538 30.9503 32.2532C27.4376 32.252 23.925 32.2514 20.4124 32.2532C18.9652 32.2543 17.5763 32.5271 16.2649 33.1542C14.8719 33.8207 13.695 34.7539 12.7671 35.9945C12.6028 36.2148 12.6004 36.2148 12.4324 35.9867C10.7643 33.7218 9.72857 31.1996 9.33133 28.4136C9.21281 27.5798 9.15445 26.743 9.15028 25.8991C9.21222 21.8076 10.5356 18.1866 13.193 15.0604C14.991 12.9444 17.1838 11.3751 19.7567 10.3424C21.1914 9.76648 22.6815 9.39961 24.2222 9.24715C27.9945 8.87492 31.5321 9.61521 34.7523 11.6169C38.8635 14.1713 41.4637 17.8304 42.4816 22.583C42.7728 23.9421 42.886 25.3214 42.824 26.7073C42.668 30.1663 41.5543 33.293 39.493 36.0802Z"
						fill={fill ?? '#FF5F5F'}
					/>
					<path
						d="M34.3805 22.9707C34.396 27.5464 30.5612 31.3247 25.9753 31.308C21.324 31.2717 17.463 27.5101 17.6136 22.5997C17.747 18.2497 21.3169 14.5363 26.0087 14.5393C30.6088 14.5429 34.3645 18.202 34.3805 22.9707Z"
						fill={fill ?? '#FF5F5F'}
					/>
					<path
						d="M25.7177 8.00183C15.8122 8.15131 7.98774 16.2855 8.00025 25.917C7.94843 35.9439 16.0296 43.8553 25.6867 43.9977C35.9738 44.1489 43.98 35.8426 43.9937 26.0605C44.008 15.8728 35.6886 7.85175 25.7177 8.00183ZM39.493 36.0802C39.3876 36.2232 39.3453 36.1624 39.2739 36.0654C38.424 34.9159 37.3633 34.013 36.1007 33.343C34.9554 32.735 33.7363 32.3931 32.4445 32.2901C31.9461 32.2508 31.4482 32.2538 30.9503 32.2532C27.4376 32.252 23.925 32.2514 20.4124 32.2532C18.9652 32.2543 17.5763 32.5271 16.2649 33.1542C14.8719 33.8207 13.695 34.7539 12.7671 35.9945C12.6028 36.2148 12.6004 36.2148 12.4324 35.9867C10.7643 33.7218 9.72857 31.1996 9.33133 28.4136C9.21281 27.5798 9.15445 26.743 9.15028 25.8991C9.21222 21.8076 10.5356 18.1866 13.193 15.0604C14.991 12.9444 17.1838 11.3751 19.7567 10.3424C21.1914 9.76648 22.6815 9.39961 24.2222 9.24715C27.9945 8.87492 31.5321 9.61521 34.7523 11.6169C38.8635 14.1713 41.4637 17.8304 42.4816 22.583C42.7728 23.9421 42.886 25.3214 42.824 26.7073C42.668 30.1663 41.5543 33.293 39.493 36.0802Z"
						fill={fill ?? '#FF5F5F'}
					/>
					<path
						d="M34.3805 22.9707C34.396 27.5464 30.5612 31.3247 25.9753 31.308C21.324 31.2717 17.463 27.5101 17.6136 22.5997C17.747 18.2497 21.3169 14.5363 26.0087 14.5393C30.6088 14.5429 34.3645 18.202 34.3805 22.9707Z"
						fill={fill ?? '#FF5F5F'}
					/>
					<path
						d="M34.3805 22.9707C34.396 27.5464 30.5612 31.3247 25.9753 31.308C21.324 31.2717 17.463 27.5101 17.6136 22.5997C17.747 18.2497 21.3169 14.5363 26.0087 14.5393C30.6088 14.5429 34.3645 18.202 34.3805 22.9707Z"
						fill={fill ?? '#FF5F5F'}
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_3737_4630">
					<rect
						width="36.0044"
						height="36"
						fill="white"
						transform="translate(7.99805 8)"
					/>
				</clipPath>
				<clipPath id="clip1_3737_4630">
					<rect
						width="35.9935"
						height="36"
						fill="white"
						transform="translate(8 8)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
