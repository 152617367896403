import axios from 'axios';
import { baseUrl } from '../Helper/constant';
import authActions from '../Redux/auth/actions';
import { store } from '../Redux/store';

const axiosInstance = axios.create({});

axiosInstance.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error?.response?.status === 401) {
			if (store.getState().auth.isLogin) {
				store.dispatch(authActions.logout());
				store.dispatch(
					authActions.set_unauthorize_message(
						'Your session has expired. Please log back in to continue'
					)
				);
				store.dispatch(authActions.prevenue_process(false));
				store.dispatch(
					authActions.prevenue_account_process_change(null)
				);
			}

			store.dispatch(authActions.prevenue_process(null));
			store.dispatch(authActions.prevenue_account_process_change(null));
			return;
		}
		return Promise.reject(error);
	}
);

const Api = (method, urlData, params) => {
	const url = `${baseUrl}${urlData}`;
	const headers = {};
	if (store.getState().auth.accessToken) {
		headers['Authorization'] = store.getState().auth.accessToken;
		// headers['ngrok-skip-browser-warning'] = '6024';
	}
	let axiosObj = {
		method: method,
		url: url,
		data: params,
		headers: headers
	};
	return axiosInstance(axiosObj);
};

export default Api;
