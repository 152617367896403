import React from 'react';

const OutlinedButton = ({ buttonTitle, onClick }) => {
	return (
		<div className="outlineWrraper" onClick={onClick}>
			<div className="button">{buttonTitle}</div>
		</div>
	);
};

export default OutlinedButton;
