import styled from 'styled-components';

const FormWrapper = styled.div`
	padding-bottom: 2px;
	.borderBox {
		border: 1px solid #eaeaea;
		border-radius: 8px;
	}
	.isServeAlcohol {
		border: 1px solid #eaeaea;
		border-radius: 6px;
		padding: 25px 18px;
		display: flex;
		justify-content: space-between;
		gap: 24px;
		@media only screen and (min-width: 992px) and (max-width: 1020px) {
			flex-direction: column;
			gap: 8px;
			padding: 14px 18px;
		}
	}
	.serviceType {
		border: 1px solid #eaeaea;
		border-radius: 6px;
		padding: 25px 18px;
		display: flex;
		justify-content: space-between;
		gap: 24px;
		@media only screen and (min-width: 992px) and (max-width: 1165px) {
			flex-direction: column;
			gap: 8px;
			padding: 14px 18px;
		}
	}
`;

export default FormWrapper;
