import styled from 'styled-components';

export const ModalWrapper = styled.div`
	.timeText {
		width: 58px;
	}
	.timePickerContainerWidth {
		width: 140px;
	}
	.switchContainer {
		width: 48px;
	}
	.saveContainer {
		width: 40px;
	}
	.deleteContainer {
		width: 50px;
	}
	.operatingHoursCard {
		.timePickerContainer {
			background-color: #f9f9f9 !important;
			border-radius: 8px !important;
		}
	}
`;
