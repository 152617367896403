import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import brand_1 from '../../../Assets/images/brand-1.png';
import brand_2 from '../../../Assets/images/brand-2.png';
import brand_3 from '../../../Assets/images/brand-3.png';
import brand_4 from '../../../Assets/images/brand-4.png';
import brand_5 from '../../../Assets/images/brand-5.png';
import brand_6 from '../../../Assets/images/brand-6.png';
import brand_7 from '../../../Assets/images/brand-7.png';
import brand_8 from '../../../Assets/images/brand-8.png';
import brand_9 from '../../../Assets/images/brand-9.png';
import brand_10 from '../../../Assets/images/brand-10.png';
import brand_11 from '../../../Assets/images/brand-11.png';
import brand_12 from '../../../Assets/images/brand-12.png';
import brand_13 from '../../../Assets/images/brand-13.png';

const PosCardWrapper = styled.div`
	color: #ffffff;
	background-color: #fc6461;
	border-radius: 12px;
	padding: 18px;
	.brandImgsContainer {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(93px, 1fr));
		gap: 13px;
	}
	.brandImgWrapper {
		width: 100%;
		height: auto;
		.brandImg {
			display: block;
			width: 100%;
			height: auto;
			border-radius: 11px;
		}
	}
`;

const PosCard = () => {
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	const brandImages = [
		brand_1,
		brand_2,
		brand_3,
		brand_4,
		brand_5,
		brand_6,
		brand_7,
		brand_8,
		brand_9,
		brand_10,
		brand_11,
		brand_12,
		brand_13
	];
	return (
		<PosCardWrapper
			{...allThemeData}
			className="d-flex flex-column flex-lg-row"
			style={{ gap: '12px' }}
		>
			<div className="flex-1">
				<p className="fs-15 semi-bold-text text-left">
					Connecting your POS is a piece of cake!
				</p>
				<p className="fs-12 medium-text text-left pa-t-12">
					Doshii integrates with the hospitality industry's leading
					POS systems. Can't see yours on the list? Reach out and
					we'll get it sorted.
				</p>
			</div>
			<div className="flex-3">
				<div className="brandImgsContainer">
					{brandImages?.length > 0 &&
						brandImages?.map((img, index) => (
							<div key={index} className="brandImgWrapper">
								<img
									src={img}
									alt="brand_1"
									className="brandImg"
								/>
							</div>
						))}
				</div>
			</div>
		</PosCardWrapper>
	);
};

export default PosCard;
