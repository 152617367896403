import CustomPopover from '../../Common/Popover/CustomPopover';

const PickupLocationPopOver = ({
	popover,
	popoverToggle,
	popoverId,
	setOpenModal,
	setModalData,
	cardData,
	deletePickupLocation,
	setDeleteConfirmModal,
	setDeletePickuplocationId
}) => {
	return (
		<CustomPopover
			isOpen={popover}
			target={popoverId}
			handleToggle={popoverToggle}
			placement="bottom-end"
			className="Order-Popover"
		>
			<div className="d-flex flex-column pa-12">
				<div
					className="pa-b-12 themeText fs-12 medium-text cursor-pointer"
					onClick={() => {
						popoverToggle();
						setOpenModal(true);
						setModalData({
							type: 'edit',
							data: cardData
						});
					}}
				>
					Edit
				</div>
				<div
					className="themeText fs-12 medium-text cursor-pointer"
					onClick={() => {
						popoverToggle();
						setDeletePickuplocationId(cardData?.id);
						setDeleteConfirmModal(true);
					}}
				>
					Delete
				</div>
			</div>
		</CustomPopover>
	);
};

export default PickupLocationPopOver;
