import styled from 'styled-components';

export const TabsWrapper = styled.div`
	.customScrollableNavbar {
		overflow-x: scroll;
		scroll-behavior: smooth;
		margin: 0px;
		padding: 0px;
		&::-webkit-scrollbar {
			background: transparent; /* make scrollbar transparent */
			-webkit-appearance: none;
			width: 0;
			height: 0;
		}
		.rts___tabs {
			padding: 0px !important;
		}
		.rts___tabs___container {
			padding: 0px !important;
		}
		button {
			border: none;
			outline: none;
			background-color: #fff;
			box-shadow: none;
			border-radius: 0px !important;
			margin: 0;
			&:disabled {
				i {
					display: none;
				}
			}
		}
		.rts___left___nav___btn,
		.rts___right___nav___btn {
			border-bottom: 2px solid #ededed;
			max-width: 100%;
			padding: 12px 10px;
			border-radius: 0px !important;
		}
		.customScrollableNavItem {
			color: #bababa;
			padding: 0px 15px 8px;
			white-space: nowrap;
			border-bottom: 2px solid #ededed;
			cursor: pointer;
			z-index: 2;
			&.active {
				color: ${(props) => props.layoutTheme.textColor};
				border-bottom: 2px solid
					${(props) => props.layoutTheme.headingColor};
			}
		}
		.borderBottom {
			border-bottom: 2px solid #ededed;
		}
	}
`;

export const PageWrapper = styled.div`
	height: 100%;
	color: ${(props) => props.layoutTheme.textColor} !important;
	@media only screen and (max-width: 767px) {
		height: 100%;
		padding: 10px;
		margin-bottom: 0px;
		border-radius: 16px;
	}
	@media only screen and (max-width: 575.98px) {
		border-radius: 0px;
	}
	.bank-card {
		border: 1px solid #eaeaea;
		border-radius: 8px;
		.city {
			color: #bababa;
		}
	}
	.permissionCard {
		min-height: 100px;
		border: 1px solid #eaeaea;
		border-radius: 8px;
		margin-bottom: 20px;
	}
	.staticText {
		p {
			font-size: 16px;
			font-family: 'montserrat-medium';
		}
	}
	.customSelect2__dropdown-indicator {
		flex: 1;
	}
	.serviceTypeSwitch {
		.form-check-label {
			margin-bottom: 0px !important;
			font-size: 18px !important;
			padding-right: 10px !important;
			font-family: 'montserrat-regular';
		}
		.themeSwitch {
			margin-top: 5px;
		}
	}
	.borderBox {
		border: 1px solid #eaeaea;
		border-radius: 6px;
	}
`;
