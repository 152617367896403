import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const RefundInitiateModalSkeleton = () => {
	return (
		<>
			<div className="orderHistoryModals">
				<form>
					<div className="orderCard d-flex flex-column p-3 pt-0">
						<div className="orderCardHeader d-flex p-1">
							<div className="d-flex align-items-center gap-3 flex-1">
								<div className="flex-1 d-flex flex-column flex-sm-row justify-content-between align-items-center gap-2">
									<div>
										<p className="fs-16 medium-text themeText">
											<Skeleton
												height="20px"
												width={'108px'}
												borderRadius={12}
											/>
										</p>
										<p className="fs-16 regular-text themeText pt-2">
											<Skeleton
												height="20px"
												width={'108px'}
												borderRadius={12}
											/>
										</p>
									</div>
									<p className="fs-16 medium-text themeText">
										<Skeleton
											height="20px"
											width={'185px'}
											borderRadius={12}
										/>
									</p>
								</div>
							</div>
						</div>
						<hr className="themeHr mt-2 mb-2" />
						<div className="orderCardBody p-1">
							<div className="d-flex flex-column flex-1 gap-1">
								<div className="d-flex justify-content-between">
									<p className="fs-16 medium-text themeText">
										<Skeleton
											height="20px"
											width={'151px'}
											borderRadius={12}
										/>
									</p>
									<p className="fs-16 medium-text themeText">
										<Skeleton
											height="20px"
											width={'151px'}
											borderRadius={12}
										/>
									</p>
								</div>
								{Array(2)
									.fill('1')
									.map((item, index) => {
										return (
											<div
												key={index}
												className="d-flex justify-content-between gap-1 pa-b-4"
											>
												<div className="d-flex flex-1 gap-2 align-items-center">
													<p className="fs-16 medium-text qtyBox d-flex align-items-center justify-content-center">
														<Skeleton
															height="28px"
															width={'28px'}
															borderRadius={6}
														/>
													</p>
													<p className="fs-16 medium-text themeText">
														<Skeleton
															height="20px"
															width={'233px'}
															borderRadius={12}
														/>
													</p>
												</div>
												<div className="d-flex flex-1 gap-2 gap-md-1 align-items-center justify-content-end">
													<div className="pr-8">
														<Skeleton
															height="20px"
															width={'20px'}
															borderRadius={2}
														/>
													</div>
													<Skeleton
														height="28px"
														width={'83px'}
														borderRadius={6}
													/>
												</div>
											</div>
										);
									})}
							</div>
						</div>
						<hr className="themeHr mt-2 mb-2" />
						<div className="orderCardPickup p-1">
							<p className="fs-16 medium-text themeText">
								<Skeleton
									height="20px"
									width={'133px'}
									borderRadius={12}
								/>
							</p>
							{Array(2)
								.fill('1')
								.map((item, index) => {
									return (
										<div
											key={index}
											className="d-flex flex-row align-items-center mt-2 justify-content-between"
										>
											<div className="d-flex align-items-center">
												<div className="pr-12">
													<Skeleton
														height="20px"
														width={'20px'}
														borderRadius={2}
													/>
												</div>
												<Skeleton
													height="20px"
													width={'233px'}
													borderRadius={12}
												/>
											</div>
											<div>
												<p className="fs-16 medium-text themeText pl-10">
													<Skeleton
														height="20px"
														width={'233px'}
														borderRadius={12}
													/>
												</p>
											</div>
										</div>
									);
								})}
						</div>
						<hr className="themeHr mt-2 mb-2" />
						<div className="orderCardTotal p-1">
							<div className="d-flex justify-content-between gap-2">
								<div className="d-flex flex-row align-items-center gap-1">
									<p className="fs-16 regular-text themeText">
										<Skeleton
											height="20px"
											width={'233px'}
											borderRadius={12}
										/>
									</p>
								</div>
								<div>
									<p className="fs-20 medium-text themeText">
										<Skeleton
											height="20px"
											width={'233px'}
											borderRadius={12}
										/>
									</p>
								</div>
							</div>
						</div>
					</div>
					<Skeleton height="47px" width="100%" borderRadius={12} />
					<div className="pa-t-4">
						<Skeleton
							height="47px"
							width="100%"
							borderRadius={12}
						/>
					</div>
				</form>
			</div>
		</>
	);
};

export default RefundInitiateModalSkeleton;
