import styled from 'styled-components';

const progressBar = styled.div`
	.progress_bar {
		height: 5px;
		border-radius: 4px;
	}
	.progress_bar_text {
		white-space: pre !important;
		width: 80px !important;
	}
`;
export default progressBar;
