import styled from 'styled-components';

const StyleWrapper = styled.div`
	color: #313132 !important;
	.label {
		display: flex;
		align-items: center;
		gap: 2px;
		font-family: 'montserrat-bold';
		font-size: 20px;
		text-transform: uppercase;
		padding-bottom: 6px;
		.requiredImg {
			width: 22px;
			height: 19px;
		}
	}

	.input,
	.searchInput {
		width: 100%;
		height: 65px;
		padding-inline: 16px;
		color: #313132eb !important;
		font-family: 'poppins-regular';
		font-size: 24px;
		border: 2px solid #31313259 !important;
		outline: none;
		border-radius: 0 !important;
	}
	.searchInput {
		border-left: none !important;
	}

	.inputWrapper {
		display: flex;
		align-items: center;
		height: 65px;
		.input,
		.searchInput {
			width: 100%;
			height: 100%;
			padding-left: 16px;
			color: #313132eb !important;
			font-family: 'poppins-regular';
			font-size: 24px;
			border: 2px solid #31313259;
			outline: none;
			border-radius: none;
			${(props) => props}
		}
		.iconWrapper {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 2px solid #31313259;
			border-left: none;
			padding-inline: 8px;
			cursor: pointer;
		}
		.iconWrapper-suggestor-search {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 2px solid #31313259;
			padding-inline: 8px;
			cursor: pointer;
			border-right: 0;
		}
		.iconWrapper-suggestor {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 2px solid #31313259;
			padding-inline: 8px;
			cursor: pointer;
			border-left: 0;
		}
	}
	.error {
		padding-top: 6px;
		font-family: 'montserrat-semi-bold';
		font-size: 18px;
		color: #ff5f5f;
	}
	@media (max-width: 600px) {
		.label {
			font-size: 12px;

			.requiredImg {
				object-fit: contain;
				width: 15px;
				height: 12px;
			}
		}
		.inputWrapper {
			height: 45px;
			.input {
				font-size: 12px;
			}
		}
		.input,
		.searchInput {
			height: 45px;
			font-size: 12px;
		}
		.error {
			font-size: 10px;
		}
	}

	@media only screen and (min-width: 600px) and (max-width: 960px) {
		.label {
			font-size: 15px;
		}
		.inputWrapper {
			height: 57.7px;
			.input {
				font-size: 18px;
			}
		}
		.input,
		.searchInput {
			height: 57.7px;
			font-size: 18px;
		}
		.error {
			font-size: 12px;
		}
	}

	@media only screen and (max-width: 1224px) {
	}

	@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
		.label {
			font-size: 11.2px;

			.requiredImg {
				width: 12.3px;
				height: 10.6px;
			}
		}
		.inputWrapper {
			height: 53.4px;
			.input {
				font-size: 13.5px;
			}
		}
		.input,
		.searchInput {
			height: 53.4px;
			font-size: 13.5px;
		}
		.error {
			font-size: 10px;
		}
	}
`;

export default StyleWrapper;
