import styled from 'styled-components';

const SwitchWrapper = styled.div`
	display: inline-block;
	cursor: pointer;
	.custom-control.material-switch {
		--activeCircleColor: #ff5f5f;
		--inActiveCircleColor: #fafafa;
		--activeTrackColor: #ffe7e7;
		--inActiveTrackColor: #9e9e9e;
		padding-left: 0;
	}
	.custom-control.material-switch .material-switch-control-indicator {
		display: inline-block;
		position: relative;
		width: 36px;
		height: 14px;
		background: var(--inActiveTrackColor);
		border-radius: 60px;
		transition: 0.3s;
	}
	.custom-control.material-switch
		.material-switch-control-input:checked
		~ .material-switch-control-indicator {
		background-color: var(--activeTrackColor);
	}
	.custom-control.material-switch .material-switch-control-indicator::after {
		content: '';
		display: block;
		position: absolute;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		transition: 0.3s;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		background: var(--inActiveCircleColor);
		box-shadow: 0 2px 10px #aaa;
	}
	.custom-control.material-switch
		.material-switch-control-input:checked
		~ .material-switch-control-indicator::after {
		background-color: var(--activeCircleColor);
		top: 50%;
		left: 100%;
		transform: translate(-100%, -50%);
	}
`;

export default SwitchWrapper;
