import { useRef } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// import ModuleWrapper from './customDatePicker.style';
import ModuleWrapper from './customTimePicker.style';

import { CalendarIcon } from '../../Icons';

const CustomTimePicker = ({
	date,
	handleDateChange,
	customClassName,
	icon,
	iconClassName,
	portalId,
	timeCaption,
	placeholderText = 'HH:MM A',
	...props
}) => {
	const timePicker = useRef();
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));

	return (
		<ModuleWrapper {...allThemeData}>
			<div className="position-relative">
				<DatePicker
					selected={date}
					onChange={handleDateChange}
					placeholderText={placeholderText}
					ref={timePicker}
					popperPlacement="bottom"
					popperProps={{ strategy: 'fixed' }}
					portalId={portalId ?? ''}
					className={`inputBox min-height-40 no-border fs-12 medium-text plr-12 todoDatePicker flex-4 zIndex-3 ${
						customClassName ? customClassName : ''
					}`}
					timeCaption={timeCaption ?? 'Time'}
					dateFormat="h:mm aa"
					showTimeSelect
					showTimeSelectOnly
					{...props}
				/>
				{icon && (
					<CalendarIcon
						height={32}
						width={32}
						className={`position-absolute right-0 ${
							iconClassName ?? ''
						} zIndex-1`}
						onClick={() => {
							timePicker.current.click();
						}}
					/>
				)}
			</div>
		</ModuleWrapper>
	);
};

export default CustomTimePicker;
