import styled from 'styled-components';

const CardWrapper = styled.div`
	.orderCard {
		border: 1px solid
			${(props) =>
				props.variant === 'green'
					? '#84d465'
					: props.variant === 'red'
					? '#FF482F'
					: '#ffeb5b'} !important;
		border-radius: 8px;
		.cardHeader {
			height: 40px;
			padding-inline: 18px;
			background-color: ${(props) =>
				props.variant === 'green'
					? '#84d465'
					: props.variant === 'red'
					? '#FF482F'
					: '#ffeb5b'} !important;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-radius: 8px 8px 0 0;
		}
		.cardBody {
			padding: 18px 0;
		}
		.cardFooter {
			padding: 16px 24px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
	.horizontalLine {
		border-top: 1px solid #eaeaea !important;
	}
	.horizontalLine2 {
		border-top: 2px solid #eaeaea !important;
	}
	.pickupLocationLabel {
		flex-basis: 116px !important;
	}
	.pickupLocationValue {
		flex: 1;
	}
	.bellButton {
		border: 1px solid #f94d73 !important;
	}
	.popoverContent {
		width: 162px !important;
		padding-block: 6px !important;
	}
	.threeDotContainer {
		flex-basis: 148px !important;
		display: flex;
		justify-content: flex-end;
	}
`;

export default CardWrapper;
