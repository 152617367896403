import styled from 'styled-components';

const HeaderWrapper = styled.div`
	padding-bottom: 12px !important;
	.headerBack {
		min-height: 59px;
		background: ${(props) => props.topbarTheme.backgroundColor};
		padding: 15px 10px;
		margin-left: -${(props) => (props.themeSetting.toolbarAlignValue === 'above' && !props.mini ? props.drawerWidth : props.themeSetting.toolbarAlignValue === 'above' && props.mini ? (props.miniDrawerWidth === props.drawerWidth ? '80px' : props.miniDrawerWidth) : '0px')};
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
		position: relative;
		z-index: 110;
		@media only screen and (max-width: 767.98px) {
			margin-left: 0px !important;
			padding: 10px;
		}

		.drawer-handle-arrow {
			@media only screen and (max-width: 767.98px) {
				display: none;
			}
		}
		.rounded {
			border-radius: 50% !important;
		}
		.mini-drawer-menu-icon {
			margin: 6px auto;
			justify-content: center;
			cursor: pointer;
			display: none;
			color: ${(props) => props.topbarTheme.textColor};
			@media only screen and (max-width: 767.98px) {
				display: block;
			}
			@media only screen and (max-width: 380px) {
				img {
					width: 70px;
				}
			}
			i {
				font-size: 20px;
				margin-right: 10px;
				position: relative;
				top: 2px;
			}
			.app-name {
				font-weight: 600;
			}
		}

		.top-header-icon {
			padding: 8px;
			border-radius: 50%;
			cursor: pointer;
			width: 35px;
			height: 35px;
			background: ${(props) => props.topbarTheme.textColor};
			color: ${(props) => props.topbarTheme.backgroundColor};
			display: flex !important;
			align-items: center;
			justify-content: center;
			@media only screen and (max-width: 575.98px) {
				width: 32px;
				height: 32px;
				padding: 6px;
				margin-top: 2px;
			}
			i {
				font-size: 16px;
				@media only screen and (max-width: 575.98px) {
					font-size: 13px;
				}
			}
		}

		/* .top-header-profile-class {
            border-radius: 50%; 
            width: 160px;
            height: 35px;
            cursor:pointer;
            @media  only screen and (max-width: 575.98px) {
                width: 160px;
                height: 32px;
                margin-top: 2px;
            }
        } */

		.profile-popover {
			padding-right: 15px;
			@media only screen and (max-width: 1024px) {
				padding-right: 10px;
			}
			@media only screen and (max-width: 575.98px) {
				padding-right: 0px;
			}
		}

		.pr-sm-5 {
			@media only screen and (max-width: 575.98px) {
				padding-right: 5px !important;
			}
		}

		.venue-dropdown {
			.activeColor {
				color: ${(props) => props.layoutTheme.buttonColor3} !important;
			}
			button {
				outline: 0 !important;
				border: 0px !important;
				@media only screen and (max-width: 575.98px) {
					padding-left: 10px;
					padding-right: 10px;
					padding-top: 5px;
					margin-top: 6px;
					margin-bottom: 6px;
					img {
						height: 24px;
						width: 24px;
					}
				}
				@media only screen and (max-width: 380px) {
					padding-left: 5px;
					padding-right: 5px;
				}
				.venue-name {
					padding-left: 10px;
					padding-right: 10px;
					color: ${(props) => props.layoutTheme.textColor};
					@media only screen and (max-width: 575.98px) {
						padding-left: 5px;
						padding-right: 5px;
						font-size: 12px !important;
					}
				}
				&:hover {
					outline: 0 !important;
					border: 0px !important;
				}
				&:active {
					outline: 0 !important;
					border: 0px !important;
					background-color: #fff !important;
					color: ${(props) => props.layoutTheme.textColor};
				}
			}
			.dropdown-menu {
				border: 0px !important;
			}
		}
		.profile-popover {
			padding-right: 15px;
			@media only screen and (max-width: 1024px) {
				padding-right: 10px;
			}
			@media only screen and (max-width: 575.98px) {
				padding-right: 0px;
			}
		}

		.pr-sm-5 {
			@media only screen and (max-width: 575.98px) {
				padding-right: 5px !important;
			}
		}
	}

	.list-inline-item {
		cursor: pointer !important;
	}

	.search-input {
		.mb-3 {
			margin: 0 !important;
		}
	}

	@media only screen and (max-width: 575.98px) {
		.list-inline.all-menu {
			svg {
				width: 24px;
			}
		}
	}

	.searchResult {
		border-radius: 0px 0px 10px 10px;
		background-color: #fff;
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
	}

	.searchResultItem {
		padding: 0px 10px;
		&:hover {
			background-color: ${(props) =>
				props.layoutTheme.buttonColor3} !important;
			color: #fff;
			svg path {
				fill: #fff;
			}
		}
	}

	.profilePic {
		height: 32px;
		width: 32px;
		border-radius: 50%;
		object-fit: cover;
	}
	.support {
		color: ${(props) => props.layoutTheme.textColor} !important;
		&:hover {
			color: ${(props) => props.sidebarTheme.activeColor} !important;
		}
	}
	.supportActive {
		color: ${(props) => props.sidebarTheme.activeColor} !important;
	}
	.logo-mini {
		opacity: 1;
		text-align: center;
		padding: 6px 0px 5px;
		@media only screen and (max-width: 767.98px) {
			display: none;
		}
		img {
			height: 30px;
		}
		.full-name-logo {
			display: ${(props) =>
				!props.mini
					? 'block'
					: props.miniDrawerWidth === props.drawerWidth
					? 'block'
					: 'none'};
			margin-right: 10px;
		}
	}
	.sidebar-header {
		position: absolute;
		top: 0;
		left: ${(props) =>
			props?.bars?.length > 0 ? '-80px' : '0px'} !important;
		z-index: 112;
		padding: 8px 0.7rem;
		min-height: 59px;
		background-color: #ffffff !important;
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
		display: block;
		@media only screen and (max-width: 767.98px) {
			padding: 12px 0.7rem;
			display: none !important;
		}
		.simple-text {
			text-transform: uppercase;
			white-space: nowrap;
			font-size: 1rem;
			color: rgb(255, 255, 255);
			font-weight: 400;
			line-height: 30px;
			text-decoration: none;
			overflow: hidden;
		}
		.logo-mini {
			opacity: 1;
			text-align: center;
			margin-left: ${(props) =>
				props?.bars?.length > 0 ? '12px' : '0px'} !important;
			padding: 6px 0px 5px;
			img {
				height: 30px;
				@media only screen and (max-width: 767.98px) {
					height: 30px;
				}
			}
			.full-name-logo2 {
				display: ${(props) =>
					!props.mini
						? 'block'
						: props.miniDrawerWidth === props.drawerWidth
						? 'block'
						: 'none'};
				margin-left: 10px;
			}
		}
		.logo-text {
			display: block;
			opacity: 1;
			transform: translateZ(0px);
			padding: 11px 0px 5px;
			/* color: ${(props) =>
				props.themeSetting.sidebarTransParentValue === 'on'
					? 'white'
					: props.sidebarTheme.textColor} !important; */
			font-weight: 600;
		}
	}
	.userCircleIconWrapper {
		background-color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 29px;
		height: 29px;
		border-radius: 100%;
		border: 3px solid #f9f9f9;
	}
`;

export default HeaderWrapper;
