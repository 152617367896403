import { Button } from 'reactstrap';
import CustomModal from '../../Common/Modal/CustomModal';
import CustomButton from '../../Common/CustomButton';

const DeleteConfirmModal = ({
	handleModal,
	isOpen,
	loading,
	handleDeleteProduct
}) => {
	return (
		<CustomModal
			isOpen={isOpen}
			handleModal={handleModal}
			title="Delete Product"
			size="md"
		>
			<div className="w-100">
				<p className="text-center fs-12 medium-text pa-b-18">
					Are you sure you want to delete this product?
				</p>
				<div className="d-flex" style={{ gap: '12px' }}>
					<div className="flex-1">
						<Button
							className="borderButtonFullWidth"
							onClick={handleModal}
						>
							No
						</Button>
					</div>
					<div className="flex-1">
						<CustomButton
							className="themeButtonFullWidth"
							loading={loading}
							onClick={handleDeleteProduct}
						>
							Yes
						</CustomButton>
					</div>
				</div>
			</div>
		</CustomModal>
	);
};

export default DeleteConfirmModal;
