import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import moment from 'moment';

import CustomTimePicker from '../../Common/DateRangePicker/CustomTimePicker';
import { VenueApiRoutes } from '../../../Utils/routes';
import Api from '../../../Helper/Api';
import Switch from '../../Common/Switch';

const OperatingHoursCard = (props) => {
	let today = new Date();
	let date =
		today.getFullYear() +
		'/' +
		parseInt(today.getMonth() + 1) +
		'/' +
		today.getDate();
	const authData = useSelector((state) => ({ ...state.auth }));
	const [saveLoading, setSaveLoading] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [showSaveButton, setShowSaveButton] = useState(false);
	const [initialData, setInitialData] = useState(null);
	const [data, setData] = useState(null);
	const [errorMsg, setErrorMsg] = useState({
		fromTime: '',
		toTime: ''
	});
	const handleStartDateChange = (date) => {
		setData((prevData) => ({ ...prevData, activeHours: date }));
	};
	const handleEndDateChange = (date) => {
		setData((prevData) => ({ ...prevData, inActiveHours: date }));
	};
	const handleSwitchChange = (event) => {
		setData((prevData) => ({
			...prevData,
			status: Number(prevData.status) ? '0' : '1'
		}));
	};
	const handleSave = async () => {
		if (!data?.activeHours && !data?.inActiveHours) {
			setErrorMsg({
				fromTime: 'Please select from time.',
				toTime: 'Please select to time.'
			});
		} else if (!data?.activeHours && data?.inActiveHours) {
			setErrorMsg({
				fromTime: 'Please select from time.',
				toTime: ''
			});
		} else if (data?.activeHours && !data?.inActiveHours) {
			setErrorMsg({
				fromTime: '',
				toTime: 'Please select to time.'
			});
		} else {
			setErrorMsg({
				fromTime: '',
				toTime: ''
			});
			let payload = {
				bar_id: authData?.selectedVenue?.id,
				sub_category_id: props?.subCategoryId,
				week_day: props?.weekDay?.toString(),
				active_hours: moment(data?.activeHours).format('HH:mm:ss'),
				in_active_hours: moment(data?.inActiveHours).format('HH:mm:ss'),
				status: data?.status,
				id: data?.id
			};
			if (Number(data?.id) <= 0) {
				delete payload.id;
			}
			setSaveLoading(true);
			if(props.getProductListData) props.getProductListData();
			try {
				const res = await Api(
					'PUT',
					VenueApiRoutes.addUpdateProductItemActiveHours,
					payload
				);
				if (res?.data?.status) {
					setShowSaveButton(false);
					if (props.refetch) props.refetch();
					toast.success(res?.data?.message);
				} else {
					toast.error(res?.data?.message);
				}
				setSaveLoading(false);
			} catch (err) {
				setSaveLoading(false);
				if (err?.message) {
					toast.error(err?.message);
				}
			}
		}
	};
	const handleDelete = async () => {
		let itemActiveHours =
			props?.operatingHoursData?.[props?.outerIndex]
				?.WeekDay_ItemActiveHours;
		if (itemActiveHours?.[props?.innerIndex]?.id === -1) {
			itemActiveHours.splice(props?.innerIndex, 1);
			props.setOperatingHoursData((prevData) => {
				if (prevData?.length > 0) {
					return prevData?.map((item, i) => {
						if (i === props.outerIndex) {
							return {
								...item,
								WeekDay_ItemActiveHours: itemActiveHours
							};
						}
						return item;
					});
				}
			});
		} else {
			try {
				setDeleteLoading(true);
				const res = await Api(
					'DELETE',
					VenueApiRoutes.deleteProductItemActiveHours,
					{
						bar_id: authData?.selectedVenue?.id,
						id: itemActiveHours?.[props?.innerIndex]?.id
					}
				);
				if (res?.data?.status) {
					if (props.refetch) props.refetch();
					toast.success(res?.data?.message);
				} else {
					toast.error(res?.data?.message);
				}
				setDeleteLoading(false);
			} catch (err) {
				setDeleteLoading(false);
				if (err?.message) {
					toast.error(err?.message);
				}
			}
		}
	};
	useEffect(() => {
		setData({
			id: props?.data?.id,
			activeHours:
				props?.data?.activeHours !== null
					? new Date(date + ' ' + props?.data?.activeHours)
					: null,
			inActiveHours:
				props?.data?.inActiveHours !== null
					? new Date(date + ' ' + props?.data?.inActiveHours)
					: null,
			status: props?.data?.status
		});
		setInitialData({
			id: props?.data?.id,
			activeHours:
				props?.data?.activeHours !== null
					? new Date(date + ' ' + props?.data?.activeHours)
					: null,
			inActiveHours:
				props?.data?.inActiveHours !== null
					? new Date(date + ' ' + props?.data?.inActiveHours)
					: null,
			status: props?.data?.status
		});
	}, [props?.data]);
	useEffect(() => {
		if (
			data?.activeHours?.getTime() !==
				initialData?.activeHours?.getTime() ||
			data?.inActiveHours?.getTime() !==
				initialData?.inActiveHours?.getTime() ||
			data?.status !== initialData?.status ||
			data?.id === -1
		) {
			setShowSaveButton(true);
		} else {
			setShowSaveButton(false);
		}
	}, [data, initialData]);
	return (
		<div className="operatingHoursCard">
			<div
				className={`d-flex justify-content-between ${
					props?.innerIndex == props?.innerLength - 1
						? 'pa-b-0'
						: 'pa-b-18'
				}`}
				style={{ gap: '12px' }}
			>
				<div className="d-flex align-items-center justify-content-start fs-11 regular-text themeText timeText">
					Time {props?.id + 1}
				</div>
				<div>
					<div className="d-flex align-items-center justify-content-center timePickerContainer timePickerContainerWidth">
						<CustomTimePicker
							date={data?.activeHours}
							handleDateChange={handleStartDateChange}
							customClassName="placeholder-center text-center w-100"
							timeIntervals={30}
							placeholderText="Time"
						/>
					</div>
					{errorMsg?.fromTime && (
						<p className="fs-10 semi-bold-text headingTextColor pa-t-6">
							{errorMsg?.fromTime}
						</p>
					)}
				</div>
				<div>
					<div className="d-flex align-items-center justify-content-center timePickerContainer timePickerContainerWidth">
						<CustomTimePicker
							date={data?.inActiveHours}
							handleDateChange={handleEndDateChange}
							customClassName="placeholder-center text-center w-100"
							timeIntervals={30}
							placeholderText="Time"
						/>
					</div>
					{errorMsg?.toTime && (
						<p className="fs-10 semi-bold-text headingTextColor pa-t-6">
							{errorMsg?.toTime}
						</p>
					)}
				</div>
				<div className="d-flex align-items-center justify-content-center switchContainer">
					<Switch
						checked={Number(data?.status) ? true : false}
						onChange={handleSwitchChange}
					/>
				</div>
				<div className="d-flex align-items-center justify-content-center saveContainer">
					{showSaveButton && (
						<div className="mb-2 w-100">
							<Button
								type="button"
								className="themeButton plr-8 ptb-6 fs-10 regular-text"
								onClick={handleSave}
								disabled={saveLoading}
							>
								Save
							</Button>
						</div>
					)}
				</div>
				<div className="d-flex align-items-center justify-content-center deleteContainer">
					<div className="mb-2 w-100">
						<Button
							type="button"
							className="themeButton plr-8 ptb-6 fs-10 regular-text"
							onClick={handleDelete}
							disabled={deleteLoading}
						>
							Delete
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OperatingHoursCard;
