import React from 'react';
import { useState } from 'react';
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle
} from 'reactstrap';
import {
	AreaChart,
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer
} from 'recharts';
import { DownArrowIcon, UpArrowIcon } from '../Icons';

const data = [
	{
		name: 'JAN',
		uv: 10,
		pv: 0
	},
	{
		name: 'FEB',
		uv: 20,
		pv: 13
	},
	{
		name: 'MAR',
		uv: 18,
		pv: 28
	},
	{
		name: 'APR',
		uv: 25,
		pv: 19
	},
	{
		name: 'MAY',
		uv: 31,
		pv: 8
	},
	{
		name: 'JUN',
		uv: 28,
		pv: 18
	},
	{
		name: 'JUL',
		uv: 35,
		pv: 3
	},
	{
		name: 'AUG',
		uv: 30,
		pv: 30
	},
	{
		name: 'SEPT',
		uv: 24,
		pv: 18
	},
	{
		name: 'OCT',
		uv: 30,
		pv: 23
	},
	{
		name: 'NOV',
		uv: 23,
		pv: 18
	},
	{
		name: 'DEC',
		uv: 29,
		pv: 23
	}
];

const IncomeGrowthChart = () => {
	const [dateRangePickerLabel, setDateRangePickerLabel] =
		useState('This Month');
	const [dateDropdown, setDateDropdown] = useState(false);

	const handleDateRangeLabel = (label) => {
		setDateRangePickerLabel(label);
	};

	return (
		<div className="d-flex w-100 h-100 flex-column gap-3">
			<div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-3">
				<div className="fs-20 semi-bold-text">
					Customer Growth & Venue Growth
				</div>
				<div>
					<Dropdown
						isOpen={dateDropdown}
						toggle={() => setDateDropdown(!dateDropdown)}
						direction="down"
						className="dashboard-dropdown"
					>
						<DropdownToggle
							color="#fff"
							className="ptb-0 dropdownToggle"
						>
							<span
								className={`dropdown-name fs-12 medium-text ${
									dateDropdown ? 'activeColor' : ''
								}`}
							>
								{dateRangePickerLabel}
							</span>
							<span>
								{dateDropdown ? (
									<UpArrowIcon height={7} width={12} />
								) : (
									<DownArrowIcon height={7} width={12} />
								)}
							</span>
						</DropdownToggle>
						<DropdownMenu className="datePickerDropdown">
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'Today'
										? 'active'
										: ''
								}`}
								onClick={() => handleDateRangeLabel('Today')}
							>
								Today
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'Yesterday'
										? 'active'
										: ''
								}`}
								onClick={() =>
									handleDateRangeLabel('Yesterday')
								}
							>
								Yesterday
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'Last Week'
										? 'active'
										: ''
								}`}
								onClick={() =>
									handleDateRangeLabel('Last Week')
								}
							>
								Last Week
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'This Month'
										? 'active'
										: ''
								}`}
								onClick={() =>
									handleDateRangeLabel('This Month')
								}
							>
								This Month
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'Last Month'
										? 'active'
										: ''
								}`}
								onClick={() =>
									handleDateRangeLabel('Last Month')
								}
							>
								Last Month
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'Last Year'
										? 'active'
										: ''
								}`}
								onClick={() =>
									handleDateRangeLabel('Last Year')
								}
							>
								Last Year
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'Current Year'
										? 'active'
										: ''
								}`}
								onClick={() =>
									handleDateRangeLabel('Current Year')
								}
							>
								Current Year
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'All'
										? 'active'
										: ''
								}`}
								onClick={() => handleDateRangeLabel('All')}
							>
								All
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</div>
			</div>
			<ResponsiveContainer width="99%" height={250}>
				<AreaChart
					width={500}
					height={400}
					data={data}
					margin={{
						left: -30,
						right: 10
					}}
				>
					<defs>
						<linearGradient
							id="colorUv"
							x1="0"
							y1="0"
							x2="0"
							y2="1"
						>
							<stop
								offset="5%"
								stopColor="#FF9F9F"
								stopOpacity={0.8}
							/>
							<stop
								offset="95%"
								stopColor="#FFE6E6"
								stopOpacity={0}
							/>
						</linearGradient>
					</defs>
					<CartesianGrid horizontal vertical={false} />
					<XAxis dataKey="name" axisLine={false} tickLine={false} />
					<YAxis axisLine={false} tickLine={false} />
					<Tooltip />
					<Area
						type="monotone"
						dataKey="uv"
						stroke="#FF5F5F"
						fill="url(#colorUv)"
					/>
				</AreaChart>
			</ResponsiveContainer>
		</div>
	);
};

export default IncomeGrowthChart;
