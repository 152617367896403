import styled from 'styled-components';

const PageWrapper = styled.div`
	width: 100%;
	height: 100%;
	color: ${(props) => props.layoutTheme.textColor} !important;
	background: linear-gradient(243.46deg, #ffb3ba -11.23%, #ffffff 21.89%);
	padding: 32px 16px;
	@media only screen and (min-width: 768px) {
		padding: 32px 64px;
	}
	@media only screen and (min-width: 1600px) {
		padding: 32px 232px 0 232px;
	}
`;

export default PageWrapper;
