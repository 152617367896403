const PremiumFeaturesIcon = ({ fill, width, height, ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 499.65 744.09"
			width={width}
			height={height}
		>
			<g id="Layer_2" data-name="Layer 2">
				<g id="Layer_1-2" data-name="Layer 1">
					<path
						className="cls-1"
						fill={fill ?? '#242424'}
						d="M254.54,244.22C115,241.85,2.83,351.45.06,486.9-2.88,630,107.26,742.65,249.22,744.09c138.89-.35,249.43-112.37,250.17-247.1C500.17,355.11,387.69,246.49,254.54,244.22Zm-4.92,476.65c-125.47-.58-226.86-102.6-226.36-227.75C23.76,368.53,125.84,267,250,267.4c125.25.36,226.36,100.71,226.29,227.46C476.2,618.27,377.21,720.15,249.62,720.87ZM437.88,434.18c-1.61-5.14-6.11-7.59-14-7.59q-60.3,0-120.58.06c-2.63,0-3.86-.64-4.68-3.29-4.12-13.32-8.48-26.56-12.76-39.82q-12-37.11-24-74.24c-1.75-5.47-5.13-8.72-10.89-9.25-6.56-.6-11.26,3.13-13.72,10.74q-18.12,56.13-36.18,112.28c-.84,2.63-1.95,3.62-4.88,3.6-20-.16-39.94-.08-59.92-.08s-39.94,0-59.91,0c-5.66,0-10.86,3.65-12.47,8.5-1.36,4.13.57,8.31,5.56,12,.3.22.61.42.92.64q48.3,35,96.65,70c2.16,1.55,2.42,2.78,1.66,5.14q-12.15,37.46-24.12,75c-4.25,13.27-8.54,26.53-12.75,39.82-2.38,7.51-.24,12.64,6.28,16,5.19,2.63,9.31.49,13.43-2.5q48.29-35.05,96.57-70.1c2.2-1.59,3.57-1.58,5.75,0,14,10.35,28.23,20.55,42.36,30.79q27.11,19.65,54.21,39.31c3.71,2.7,7.63,4.26,12.23,2.72,6.84-2.3,9.77-8.79,7.34-16.35q-18.21-56.49-36.52-113c-.9-2.75-.75-4.19,1.8-6q48-34.51,95.77-69.29C437.72,444.44,439.71,440,437.88,434.18Zm-51,17q-36.83,26.63-73.7,53.18c-7,5.05-8.18,8.77-5.49,17q13.95,43,27.91,85.91c.34,1,.67,2.08,1.31,4.06L286.37,575,260.88,556.6c-7.89-5.68-11.89-5.66-19.89.11L168.46,609c-.91.66-1.84,1.27-3.32,2.3,3.22-10,6.24-19.46,9.31-28.87q9.93-30.52,19.91-61c2.67-8.18,1.4-12-5.49-17q-37-26.67-74-53.36c-.67-.49-1.32-1-2.29-1.79,1.55-.82,2.9-.54,4.17-.54q45.31,0,90.62,0c7.23,0,11.93-3.42,14.18-10.33q14.16-43.49,28.3-87c.34-1,.73-2.08,1.27-3.64,6.74,20.76,13.3,41,19.86,61.21,3.3,10.18,6.56,20.37,9.88,30.55,2.15,6.58,5.76,9.17,12.79,9.17H389.1C389,450.37,387.71,450.61,386.89,451.2Z"
					/>
					<path
						className="cls-1"
						fill={fill ?? '#242424'}
						d="M498.64,111.93c-2.46-4.54-8.65-7.64-13.84-7.11-11,1.14-22.09,2.2-33.14,3.25-35.14,3.36-70.29,6.65-105.42,10.11-2.62.26-4-.35-5.53-2.41Q300.62,61,260.37,6.3c-6.16-8.38-14.85-8.39-21-.06q-14.75,20-29.45,40.1Q184.33,81.16,158.74,116c-.91,1.24-1.49,2.64-3.71,2.41-15.26-1.57-30.54-2.95-45.81-4.43q-47.68-4.61-95.35-9.26A12.65,12.65,0,0,0,1.07,112c-2,4.4-1.17,8.51,2.74,12.59q54.78,57.18,109.6,114.35c5.2,5.41,9.66,6.31,16.52,3.47,1.26-.53,2.49-1.14,3.73-1.72a271.4,271.4,0,0,1,116.56-25.63,266.74,266.74,0,0,1,45.84,3.66,279,279,0,0,1,74.88,23.66c6.54,3.08,11.17,2.1,16.15-3.15l31.44-33.14q38.42-40.46,76.82-80.93C499.56,120.72,500.72,115.78,498.64,111.93Zm-43.17,20.58q-39.62,41.38-79.23,82.79c-1.52,1.59-2.67,1.89-4.74,1a306.64,306.64,0,0,0-82.45-23.14,293.39,293.39,0,0,0-73.19-.6,304.39,304.39,0,0,0-88,23.89c-2.41,1.06-3.34-.08-4.66-1.46q-40-41.78-80.07-83.53c.19-1.84,1.44-1,2.37-.92l76,7.34c13.28,1.29,26.56,2.66,39.86,3.86,5,.46,8.75-1.83,11.71-5.93Q198.94,100,225,64.36l24.86-34.23c4.1,5.6,8.08,11,12,16.4q32.22,44.34,64.37,88.73c3.7,5.14,8.15,6.94,14.42,6.27,21.46-2.3,42.95-4.26,64.43-6.33l51.83-5C457.08,131.41,456.07,131.87,455.47,132.51Z"
					/>
					<path
						className="cls-2"
						fill={fill ?? '#242424'}
						d="M437.88,434.18c-1.61-5.14-6.11-7.59-14-7.59q-60.3,0-120.58.06c-2.63,0-3.86-.64-4.68-3.29-4.12-13.32-8.48-26.56-12.76-39.82q-12-37.11-24-74.24c-1.75-5.47-5.13-8.72-10.89-9.25-6.56-.6-11.26,3.13-13.72,10.74q-18.12,56.13-36.18,112.28c-.84,2.63-1.95,3.62-4.88,3.6-20-.16-39.94-.08-59.92-.08s-39.94,0-59.91,0c-5.66,0-10.86,3.65-12.47,8.5-1.36,4.13.57,8.31,5.56,12,.3.22.61.42.92.64q48.3,35,96.65,70c2.16,1.55,2.42,2.78,1.66,5.14q-12.15,37.46-24.12,75c-4.25,13.27-8.54,26.53-12.75,39.82-2.38,7.51-.24,12.64,6.28,16,5.19,2.63,9.31.49,13.43-2.5q48.29-35.05,96.57-70.1c2.2-1.59,3.57-1.58,5.75,0,14,10.35,28.23,20.55,42.36,30.79q27.11,19.65,54.21,39.31c3.71,2.7,7.63,4.26,12.23,2.72,6.84-2.3,9.77-8.79,7.34-16.35q-18.21-56.49-36.52-113c-.9-2.75-.75-4.19,1.8-6q48-34.51,95.77-69.29C437.72,444.44,439.71,440,437.88,434.18Zm-51,17q-36.83,26.63-73.7,53.18c-7,5.05-8.18,8.77-5.49,17q13.95,43,27.91,85.91c.34,1,.67,2.08,1.31,4.06L286.37,575,260.88,556.6c-7.89-5.68-11.89-5.66-19.89.11L168.46,609c-.91.66-1.84,1.27-3.32,2.3,3.22-10,6.24-19.46,9.31-28.87q9.93-30.52,19.91-61c2.67-8.18,1.4-12-5.49-17q-37-26.67-74-53.36c-.67-.49-1.32-1-2.29-1.79,1.55-.82,2.9-.54,4.17-.54q45.31,0,90.62,0c7.23,0,11.93-3.42,14.18-10.33q14.16-43.49,28.3-87c.34-1,.73-2.08,1.27-3.64,6.74,20.76,13.3,41,19.86,61.21,3.3,10.18,6.56,20.37,9.88,30.55,2.15,6.58,5.76,9.17,12.79,9.17H389.1C389,450.37,387.71,450.61,386.89,451.2Z"
					/>
					<path
						className="cls-1"
						fill={fill ?? '#242424'}
						d="M437.88,434.18c-1.61-5.14-6.11-7.59-14-7.59q-60.3,0-120.58.06c-2.63,0-3.86-.64-4.68-3.29-4.12-13.32-8.48-26.56-12.76-39.82q-12-37.11-24-74.24c-1.75-5.47-5.13-8.72-10.89-9.25-6.56-.6-11.26,3.13-13.72,10.74q-18.12,56.13-36.18,112.28c-.84,2.63-1.95,3.62-4.88,3.6-20-.16-39.94-.08-59.92-.08s-39.94,0-59.91,0c-5.66,0-10.86,3.65-12.47,8.5-1.36,4.13.57,8.31,5.56,12,.3.22.61.42.92.64q48.3,35,96.65,70c2.16,1.55,2.42,2.78,1.66,5.14q-12.15,37.46-24.12,75c-4.25,13.27-8.54,26.53-12.75,39.82-2.38,7.51-.24,12.64,6.28,16,5.19,2.63,9.31.49,13.43-2.5q48.29-35.05,96.57-70.1c2.2-1.59,3.57-1.58,5.75,0,14,10.35,28.23,20.55,42.36,30.79q27.11,19.65,54.21,39.31c3.71,2.7,7.63,4.26,12.23,2.72,6.84-2.3,9.77-8.79,7.34-16.35q-18.21-56.49-36.52-113c-.9-2.75-.75-4.19,1.8-6q48-34.51,95.77-69.29C437.72,444.44,439.71,440,437.88,434.18Zm-51,17q-36.83,26.63-73.7,53.18c-7,5.05-8.18,8.77-5.49,17q13.95,43,27.91,85.91c.34,1,.67,2.08,1.31,4.06L286.37,575,260.88,556.6c-7.89-5.68-11.89-5.66-19.89.11L168.46,609c-.91.66-1.84,1.27-3.32,2.3,3.22-10,6.24-19.46,9.31-28.87q9.93-30.52,19.91-61c2.67-8.18,1.4-12-5.49-17q-37-26.67-74-53.36c-.67-.49-1.32-1-2.29-1.79,1.55-.82,2.9-.54,4.17-.54q45.31,0,90.62,0c7.23,0,11.93-3.42,14.18-10.33q14.16-43.49,28.3-87c.34-1,.73-2.08,1.27-3.64,6.74,20.76,13.3,41,19.86,61.21,3.3,10.18,6.56,20.37,9.88,30.55,2.15,6.58,5.76,9.17,12.79,9.17H389.1C389,450.37,387.71,450.61,386.89,451.2Z"
					/>
				</g>
			</g>
		</svg>
	);
};

export default PremiumFeaturesIcon;
