export const OrderHistoryIcon = ({ fill, width, height, ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 735.33 674.34"
			width={width}
			height={height}
		>
			<g id="Layer_2" data-name="Layer 2">
				<g id="Layer_1-2" data-name="Layer 1">
					<path
						className="cls-1"
						d="M87.71,302.26c13.62-15.13,26.55-29.5,39.49-43.86,3.84-4.27,7.63-8.58,11.54-12.79,5.3-5.72,12.54-6.3,17.92-1.54,5.2,4.6,5.58,12.38.52,18.07-11.69,13.16-23.53,26.18-35.33,39.25-12.79,14.18-25.8,28.17-38.33,42.58-7.41,8.53-17.25,6.1-22.35-2-19.44-30.76-39.09-61.39-58.61-92.1-5.41-8.51-1.9-17.89,7.33-20,5-1.12,9.66.75,12.81,5.61,7.53,11.6,14.93,23.29,22.38,34.95,5.76,9,11.52,18,17.72,27.77.87-5.73,1.5-10.79,2.41-15.8Q90.92,140.14,209.72,57.58A315.89,315.89,0,0,1,338.92,5.21C439-11.44,529.27,11.89,609.07,74.33c47,36.78,80.5,84,102.61,139.33a328,328,0,0,1,21.89,89.73c7.44,74.87-8.74,144.53-47.38,208.8-35.23,58.62-84.49,102-146.47,130.89a325.72,325.72,0,0,1-97.18,28.31c-86.54,10.93-166-8.5-237.79-57.95-56.89-39.2-97.08-91.81-122.1-156.1-2.9-7.44-.19-14.41,6.58-17.13s13.56.46,16.47,8A315.65,315.65,0,0,0,150,527.52a314.16,314.16,0,0,0,243.3,122.12c53.13.78,103.49-11,150.31-36.13C627.7,568.32,680.29,498.6,702.78,406a289.61,289.61,0,0,0,6.6-95.92Q700.14,211.2,636.19,135c-32-38.15-71.48-66.47-117.24-85.8a304.4,304.4,0,0,0-145.5-23.48c-55.88,4.62-107.16,22.42-153.07,54.53C161.05,121.74,120.74,177.47,99,246.57a286.6,286.6,0,0,0-11.1,51.82C87.8,299.35,87.81,300.32,87.71,302.26Z"
						fill={fill ?? '#242424'}
					/>
					<path
						className="cls-1"
						d="M385.43,237.91q0-48.49,0-97c0-7.67,2.8-12.11,8.73-14a12.12,12.12,0,0,1,15.74,9.54,38.28,38.28,0,0,1,.33,6q0,89.12-.07,178.23c0,3.43.86,4.27,4.26,4.25q49.8-.19,99.61-.06c9.33,0,15.37,8.64,12,17-1.92,4.69-5.6,7.17-10.57,7.76a33.37,33.37,0,0,1-3.74.1H400.86c-10.87,0-15.43-4.57-15.43-15.48Q385.42,286,385.43,237.91Z"
						fill={fill ?? '#242424'}
					/>
				</g>
			</g>
		</svg>
	);
};
