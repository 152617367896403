import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import moment from 'moment';
import * as yup from 'yup';
import * as validation from '../../../../Helper/YupValidation';
import CustomModal from '../../../Common/Modal/CustomModal';
import FormInput from '../../../Form/FormInput';
import { DownArrowIcon, UpArrowIcon } from '../../../Icons';
import Switch from '../../../Common/Switch';
import { VenueApiRoutes } from '../../../../Utils/routes';
import Api from '../../../../Helper/Api';
import CustomButton from '../../../Common/CustomButton';

const defaultOperationalHours = [
	{
		weekDay: 0,
		isClosed: false
	},
	{
		weekDay: 1,
		isClosed: false
	},
	{
		weekDay: 2,
		isClosed: false
	},
	{
		weekDay: 3,
		isClosed: false
	},
	{
		weekDay: 4,
		isClosed: false
	},
	{
		weekDay: 5,
		isClosed: false
	},
	{
		weekDay: 6,
		isClosed: false
	}
];

const TaxModal = ({
	isOpen,
	handleModal,
	modalData,
	getTableData,
	setModalData
}) => {
	const authDetails = useSelector((state) => ({ ...state.auth }));
	const [loading, setLoading] = useState(false);
	let isEdit = modalData?.type === 'edit';
	const validationSchema = yup.object().shape({
		name: validation.YUP_VALIDATION.TAX_NAME,
		taxPercentage: validation.YUP_VALIDATION.TAX_PERCENTAGE
	});
	const [showOperationalHours, setShowOperationalHours] = useState(false);
	const closeModal = () => {
		handleModal();
		setModalData({
			type: '',
			data: null
		});
	};
	const submitFormHandler = async () => {
		let payload = {
			bar_id: '' + authDetails?.selectedVenue?.id,
			status: values?.status ? 'Active' : 'Inactive',
			operating_bar_tax: values?.operationalHours?.map((item) => {
				return {
					week_day: '' + item?.weekDay,
					is_closed: item?.isClosed ? '1' : '0'
				};
			}),
			name: values?.name,
			percentage: Number(values?.taxPercentage)
		};
		if (isEdit) {
			payload.id = modalData?.data?.id;
		}
		try {
			setLoading(true);
			const res = await Api(
				!isEdit ? 'POST' : 'PUT',
				!isEdit ? VenueApiRoutes?.addTax : VenueApiRoutes?.editTax,
				payload
			);
			if (res?.data?.status) {
				handleModal();
				toast.success(res?.data?.message);
				await getTableData();
			} else {
				toast.error(res?.data?.message);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	const {
		values,
		handleChange,
		handleSubmit,
		handleBlur,
		touched,
		errors,
		setFieldValue,
		setValues
	} = useFormik({
		initialValues: {
			name: '',
			taxPercentage: '',
			status: false,
			operationalHours: [...defaultOperationalHours]
		},
		validationSchema,
		onSubmit: submitFormHandler
	});
	useEffect(() => {
		if (modalData?.type === 'edit' && modalData?.data) {
			let operationalHours = [];
			if (modalData?.data?.operationalHours?.length > 0) {
				operationalHours = modalData?.data?.operationalHours?.map(
					(item) => {
						return {
							weekDay: Number(item?.weekDay),
							isClosed: item?.isClosed
						};
					}
				);
				operationalHours.sort((item1, item2) => {
					if (item1.weekDay < item2.weekDay) {
						return -1;
					} else if (item1.weekDay > item2.weekDay) {
						return 1;
					} else {
						return 0;
					}
				});
			} else {
				operationalHours = [...defaultOperationalHours];
			}
			setValues({
				name: modalData?.data?.name,
				taxPercentage: modalData?.data?.taxAmount,
				status: modalData?.data?.status === 'Active' ? true : false,
				operationalHours: operationalHours
			});
		} else {
			setValues({
				name: '',
				taxPercentage: '',
				status: true,
				operationalHours: defaultOperationalHours
			});
		}
	}, [modalData]);
	return (
		<CustomModal
			title={`${
				modalData?.type === 'add' ? 'Add New Tax' : 'Manage Tax'
			}`}
			size="md"
			isOpen={isOpen}
			handleModal={closeModal}
			style={{ maxWidth: '568px', width: '100%' }}
		>
			<form
				className="overflow-hidden"
				onSubmit={handleSubmit}
				autoComplete="off"
			>
				<Row>
					<Col sm={12} className="pa-b-14">
						<FormInput
							type="text"
							name="name"
							label="Name"
							placeholder="Enter Tax name (this will appear on customer's receipt)"
							value={values.name}
							onChange={handleChange}
							onBlur={handleBlur}
							error={touched.name && !!errors.name}
							errorMsg={errors.name}
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={12} className="pa-b-14">
						<FormInput
							type="number"
							name="taxPercentage"
							label="Percentage"
							placeholder="Enter Percentage"
							value={values.taxPercentage}
							onChange={handleChange}
							onBlur={handleBlur}
							error={
								touched.taxPercentage && !!errors.taxPercentage
							}
							errorMsg={errors.taxPercentage}
						/>
					</Col>
				</Row>
				<div className="pa-b-14">
					<div className="d-flex justify-content-between">
						<span className="fs-12 medium-text">Enable Tax</span>
						<Switch
							name="status"
							onChange={handleChange}
							checked={values?.status}
						/>
					</div>
				</div>
				<Row className="">
					<Col sm={12}>
						<div className="operationalHoursCard pa-18">
							<div
								className="d-flex align-items-center justify-content-between cursor-pointer"
								onClick={() =>
									setShowOperationalHours(
										!showOperationalHours
									)
								}
							>
								<p className="fs-12 medium-text">
									Set Operating Days
								</p>
								{showOperationalHours ? (
									<UpArrowIcon height={6} width={12} />
								) : (
									<DownArrowIcon height={6} width={12} />
								)}
							</div>
							{showOperationalHours && (
								<>
									<div className="horizontalRule mtb-18" />
									<div className="d-flex align-items-center justify-content-between">
										<p className="fs-11 medium-text">Day</p>
										<p className="fs-11 medium-text">
											Status
										</p>
									</div>
									{values?.operationalHours?.length > 0 &&
										values?.operationalHours?.map(
											(item, index) => (
												<div
													key={index}
													className={`pa-t-12`}
												>
													<div className="d-flex align-items-center justify-content-between">
														<p className="fs-11 regular-text">
															{moment()
																.day(
																	item?.weekDay +
																		1
																)
																.format('dddd')}
														</p>
														<Switch
															name={`operationalHours.${index}.isClosed`}
															onChange={
																handleChange
															}
															checked={
																values
																	?.operationalHours[
																	index
																].isClosed
															}
														/>
													</div>
												</div>
											)
										)}
								</>
							)}
						</div>
					</Col>
				</Row>
				<div className="pa-t-18">
					<div className="d-flex flex-column" style={{ gap: '18px' }}>
						<CustomButton
							type="submit"
							className="themeButtonFullWidth"
						>
							Save
						</CustomButton>
						<Button
							type="button"
							className="borderButtonFullWidth"
							onClick={handleModal}
						>
							Cancel
						</Button>
					</div>
				</div>
			</form>
		</CustomModal>
	);
};

export default TaxModal;
