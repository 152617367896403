import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';

import HeaderWrapper from './header.style';
import { HamburgerIcon, BellIcon, TodoCheckIcon, Cross2 } from '../Icons/index';
import appNameLogo from '../../Assets/images/Logo.svg';
import ProfilePopOver from './ProfilePopOver';
import TodoPopOver from './TodoPopOver';
import { drawerWidth } from '../../Helper/constant';
import {
	CommonRoutes,
	VenueApiRoutes,
	VenuePanelRoutes
} from '../../Utils/routes';
import LogoutConfirmModal from './LogoutConfirmModal';
import authActions from '../../Redux/auth/actions';
import Api from '../../Helper/Api';

const Header = (props) => {
	const profileData = useSelector((state) => ({ ...state.auth }));
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const _767PX = useMediaQuery({ maxWidth: 767 });
	const [todoPopover, setTodoPopover] = useState(false);
	const [openLogoutModal, setOpenLogoutModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const { drawerMiniMethod, mini, closeDrawer } = props;

	const todoPopoverToggle = () => {
		setTodoPopover((prev) => !prev);
	};
	const handleLogoutModal = () => {
		setOpenLogoutModal((prev) => !prev);
	};
	const userSignOut = async () => {
		let api =
			profileData.login_type === 'venue'
				? VenueApiRoutes.logout
				: '/cms/admin/logout';
		setLoading(true);
		try {
			const res = await Api(
				profileData.login_type === 'venue' ? 'POST' : 'DELETE',
				api
			);
			if (res?.data?.status) {
				setOpenLogoutModal(false);
				dispatch(authActions.logout());
				toast.success(res?.data?.message);
				navigate(CommonRoutes.login);
			} else {
				toast.error(res?.data?.message);
			}
			setLoading(false);
		} catch (err) {
			toast.error(err.response);
			setLoading(false);
		}
	};
	return (
		<HeaderWrapper {...props} bars={profileData?.bars}>
			<div className={`headerBack`}>
				<div className="list-inline all-menu ma-0 d-flex justify-content-between">
					<div className="list-inline-item feed-text-area-icon">
						<div
							className="mini-drawer-menu-icon"
							// onClick={drawerMiniMethod}
						>
							{profileData.isLogin &&
								profileData.login_type === 'venue' &&
								profileData.bars.length > 0 && (
									<HamburgerIcon
										width={28}
										height={28}
										onClick={drawerMiniMethod}
									/>
								)}
							<img
								src={appNameLogo}
								alt="app-name"
								height={30}
								className="pl-10"
								onClick={() =>
									location.pathname.includes('admin')
										? navigate('/admin/live')
										: navigate(VenuePanelRoutes.home)
								}
							/>
						</div>
					</div>
					<div
						className="sidebar-header d-flex justify-content-start align-items-center"
						style={{ width: drawerWidth }}
					>
						{profileData?.bars?.length > 0 && (
							<>
								{mini ? (
									<div
										className={`logo-img ${
											!_767PX && 'ml-14'
										}`}
									>
										<HamburgerIcon
											width={28}
											height={28}
											onClick={drawerMiniMethod}
											className="cursor-pointer"
										/>
									</div>
								) : (
									<div
										className={`logo-img ${
											!_767PX && 'ml-14'
										}`}
									>
										<Cross2
											width={28}
											height={28}
											onClick={closeDrawer}
											className="cursor-pointer"
										/>
									</div>
								)}
							</>
						)}
						<NavLink
							to={`${
								location.pathname.includes('admin')
									? '/admin/live'
									: VenuePanelRoutes.home
							}`}
							className="logo-mini"
						>
							<div className="full-name-logo2 simple-text p-0">
								<img src={appNameLogo} alt="app-name" />
							</div>
						</NavLink>
					</div>
					<div className="d-flex flex-1 justify-content-end gap-1">
						{location.pathname.includes('admin') && (
							<>
								<div className="list-inline-item align-items-center todo-popover justify-content-center d-flex">
									<TodoCheckIcon
										width={32}
										height={32}
										id="todoPopover"
										onClick={todoPopoverToggle}
									/>
								</div>
								<div className="list-inline-item align-items-center justify-content-center d-flex">
									<BellIcon width={32} height={32} />
								</div>
							</>
						)}
						{location.pathname.includes('venue') && (
							<div className="d-flex align-items-center pr-18 themeText">
								<NavLink
									to={'/venue/support'}
									className={({ isActive }) =>
										`fs-14 medium-text cursor-pointer text-decoration-none support ${
											isActive ? 'supportActive' : ''
										}`
									}
								>
									{' '}
									Support
								</NavLink>
							</div>
						)}
						<div className="list-inline-item feed-text-area-icon pull-right profile-popover align-items-center justify-content-center d-flex">
							<ProfilePopOver
								profileData={profileData}
								setOpenLogoutModal={setOpenLogoutModal}
							/>
						</div>
					</div>
					{location.pathname.includes('admin') && (
						<TodoPopOver
							todoPopover={todoPopover}
							todoPopoverToggle={todoPopoverToggle}
						/>
					)}
				</div>
			</div>
			{openLogoutModal && (
				<LogoutConfirmModal
					isOpen={openLogoutModal}
					handleModal={handleLogoutModal}
					loading={loading}
					userSignOut={userSignOut}
				/>
			)}
		</HeaderWrapper>
	);
};
export default Header;
