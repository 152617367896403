export const TodoCheckIcon = ({ fill, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 32 32" fill="none">
			<path
				d="M9.5 20.875C8.84375 20.875 8.375 21.3906 8.375 22C8.375 22.6562 8.84375 23.125 9.5 23.125C10.1094 23.125 10.625 22.6562 10.625 22C10.625 21.3906 10.1094 20.875 9.5 20.875ZM21.5 21.25H13.25C12.8281 21.25 12.5 21.625 12.5 22C12.5 22.4219 12.8281 22.75 13.25 22.75H21.5C21.875 22.75 22.25 22.4219 22.25 22C22.25 21.625 21.875 21.25 21.5 21.25ZM9.73438 17.5C9.92188 17.5 10.1094 17.4531 10.25 17.3125L13.0156 14.5469C13.2969 14.2656 13.2969 13.7969 13.0156 13.5156C12.7344 13.1875 12.2188 13.1875 11.9375 13.5156L9.73438 15.7188L8.89062 14.875C8.60938 14.5938 8.09375 14.5938 7.8125 14.875C7.53125 15.1562 7.53125 15.625 7.8125 15.9531L9.21875 17.3125C9.35938 17.4531 9.54688 17.5 9.73438 17.5ZM9.5 11.125C10.1094 11.125 10.625 10.6562 10.625 10C10.625 9.39062 10.1094 8.875 9.5 8.875C8.84375 8.875 8.375 9.39062 8.375 10C8.375 10.6562 8.84375 11.125 9.5 11.125ZM21.5 15.25H14.75C14.3281 15.25 14 15.625 14 16C14 16.4219 14.3281 16.75 14.75 16.75H21.5C21.875 16.75 22.25 16.4219 22.25 16C22.25 15.625 21.875 15.25 21.5 15.25ZM23 4H8C6.3125 4 5 5.35938 5 7V25C5 26.6875 6.3125 28 8 28H23C24.6406 28 26 26.6875 26 25V7C26 5.35938 24.6406 4 23 4ZM24.5 25C24.5 25.8438 23.7969 26.5 23 26.5H8C7.15625 26.5 6.5 25.8438 6.5 25V7C6.5 6.20312 7.15625 5.5 8 5.5H23C23.7969 5.5 24.5 6.20312 24.5 7V25ZM21.5 9.25H13.25C12.8281 9.25 12.5 9.625 12.5 10C12.5 10.4219 12.8281 10.75 13.25 10.75H21.5C21.875 10.75 22.25 10.4219 22.25 10C22.25 9.625 21.875 9.25 21.5 9.25Z"
				fill={fill ?? '#242424'}
			/>
		</svg>
	);
};
