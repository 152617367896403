export const UserCheckIcon = ({ fill, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 26 21" fill="none">
			<path
				d="M10.7031 12.5449H6.75781C3.00781 12.5449 0 15.5918 0 19.3418C0 20.084 0.585938 20.6699 1.32812 20.6699H16.1328C16.875 20.6699 17.5 20.084 17.5 19.3418C17.5 15.5918 14.4531 12.5449 10.7031 12.5449ZM1.875 18.7949C2.14844 16.373 4.21875 14.4199 6.75781 14.4199H10.7031C13.2422 14.4199 15.2734 16.373 15.5859 18.7949H1.875ZM8.75 10.6699C11.4844 10.6699 13.75 8.44336 13.75 5.66992C13.75 2.93555 11.4844 0.669922 8.75 0.669922C5.97656 0.669922 3.75 2.93555 3.75 5.66992C3.75 8.44336 5.97656 10.6699 8.75 10.6699ZM8.75 2.54492C10.4688 2.54492 11.875 3.95117 11.875 5.66992C11.875 7.42773 10.4688 8.79492 8.75 8.79492C6.99219 8.79492 5.625 7.42773 5.625 5.66992C5.625 3.95117 6.99219 2.54492 8.75 2.54492ZM24.6875 5.94336C24.2969 5.5918 23.7109 5.5918 23.3594 5.98242L19.9609 9.6543L18.4375 8.16992C18.0859 7.7793 17.5 7.7793 17.1484 8.16992C16.7578 8.52148 16.7578 9.10742 17.1484 9.45898L19.3359 11.6465C19.4922 11.8418 19.7266 11.9199 20 11.9199C20.2344 11.9199 20.5078 11.8418 20.6641 11.6465L24.7266 7.27148C25.0781 6.88086 25.0781 6.29492 24.6875 5.94336Z"
				fill={fill ?? '#FF5F5F'}
			/>
		</svg>
	);
};
