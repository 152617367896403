import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import LoginForm from '../../Components/Authentication/LoginForm';
import loginPageImg from '../../Assets/images/loginPageImg.png';
import PageWrapper from './login.style';
import authActions from '../../Redux/auth/actions';

export const Login = (props) => {
	const allthemeData = useSelector((state) => ({ ...state.themeChanger }));
	const authData = useSelector((state) => ({ ...state.auth }));
	const dispatch = useDispatch();

	useEffect(() => {
		if (authData?.unAuthorizeMessage) {
			toast.error(authData?.unAuthorizeMessage);
			dispatch(authActions.set_unauthorize_message(''));
		}
	}, [authData?.unAuthorizeMessage]);
	return (
		<PageWrapper {...allthemeData}>
			<div className="formWrapper pl-26">
				<LoginForm {...props} />
			</div>
			<div className="imageWrapper">
				<div className="backWrraper">
					<p className="text">
						Manage your MyTab Venues in one place
					</p>
					<img src={loginPageImg} alt="side-img" className="image" />
				</div>
			</div>
			{/* <div className="pageContainer">
				<div className="imageContainer">
					<div className="pageImageWrapper">
						<img
							src={loginImage}
							alt="app-photos"
							className="pageImage"
						/>
					</div>
				</div>
				<div className="formCardContainer">
					<LoginForm {...props} />
				</div>
			</div> */}
		</PageWrapper>
	);
};
