import styled from 'styled-components';

const NewSwitchWrapper = styled.div`
	display: inline-block;
	cursor: pointer;
	.custom-control.material-switch {
		--activeCircleColor: #ffffff;
		--inActiveCircleColor: #ffffff;
		--activeTrackColor: rgb(0, 204, 50);
		--inActiveTrackColor: #f95c69;
		padding-left: 0;
	}
	.custom-control.material-switch .material-switch-control-indicator {
		display: inline-block;
		position: relative;
		width: 142px;
		padding: 2px;
		height: 45px;
		background: var(--inActiveTrackColor);
		border-radius: 60px;
		transition: 0.3s;
	}
	.custom-control.material-switch
		.material-switch-control-input:checked
		~ .material-switch-control-indicator {
		background-color: var(--activeTrackColor);
	}
	.custom-control.material-switch .material-switch-control-indicator::after {
		content: '';
		display: block;
		position: absolute;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		transition: 0.3s;
		top: 50%;
		left: 8px;
		transform: translateY(-50%);
		background: var(--inActiveCircleColor);
		box-shadow: 0 2px 10px #aaa;
	}
	.custom-control.material-switch
		.material-switch-control-input:checked
		~ .material-switch-control-indicator::after {
		background-color: var(--activeCircleColor);
		top: 50%;
		left: 96%;
		transform: translate(-100%, -50%);
	}
	.openSwitchText {
		font-family: 'poppins-medium';
		font-size: 22px;
	}
	.closeSwitchText {
		font-family: 'poppins-medium';
		position: absolute;
		right: 5.5px;
		font-size: 22px;
	}
	@media (max-width: 600px) {
		.custom-control.material-switch .material-switch-control-indicator {
			width: 75.9px;
			height: 30.3px;
		}
		.custom-control.material-switch
			.material-switch-control-indicator::after {
			width: 20.9px;
			height: 20.3px;
			left: 3px;
		}

		.openSwitchText {
			left: 10px;
		}

		.openSwitchText,
		.closeSwitchText {
			font-family: 'poppins-medium';
			position: absolute;
			font-size: 12px;
		}
	}
	@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
		.custom-control.material-switch .material-switch-control-indicator {
			width: 80.9px;
			height: 25.3px;
		}
		.openSwitchText {
			left: 10px !important;
		}
		.openSwitchText,
		.closeSwitchText {
			font-family: 'poppins-medium';
			position: absolute;
			font-size: 12.3px;
		}
		.custom-control.material-switch
			.material-switch-control-indicator::after {
			height: 16.8px;
			width: 16.8px;
			left: 4px;
		}
	}
`;

export default NewSwitchWrapper;
