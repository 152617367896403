import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
	AdminPanelRoutes,
	CommonRoutes,
	VenuePanelRoutes
} from '../../../../Utils/routes';

const PublicRoute = ({ children }) => {
	const authDetails = useSelector((state) => state.auth);
	if (authDetails?.isLogin) {
		if (authDetails?.login_type === 'admin') {
			return <Navigate to={AdminPanelRoutes.live} />;
		}
		if (authDetails?.login_type === 'venue') {
			return <Navigate to={VenuePanelRoutes.home} />;
		}
	}

	return children;
};

export default PublicRoute;
