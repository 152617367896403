import React, { useEffect, useState } from 'react';
import PageWrapper from '../../Authentication/register.style';
import createAccountImage from '../../../Assets/images/loginPageImg.png';
import NewFormInput from '../../../Components/Form/NewFormInput';
import NewFormPasswordInput from '../../../Components/Form/NewFormPasswordInput';
import CustomButton from '../../../Components/Common/CustomButton';
import Agreement from '../../../Components/Authentication/Agreement';
import VenuePortalLoginWrraper from './venuePortalLogin.style';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as validation from '../../../Helper/YupValidation';
import { toast } from 'react-toastify';
import {
	CommonRoutes,
	VenueApiRoutes,
	VenuePanelRoutes,
	VenueProtectedRoutes
} from '../../../Utils/routes';
import Api from '../../../Helper/Api';
import { useLocation, useNavigate } from 'react-router-dom';
import NewVerifyOtpModal from '../../../Components/UserProfile/ConnectVenue/NewVerifyOtpModal';

const VenuePortalLogin = () => {
	const [isOpenVerifyOtpModal, setIsOpenVerifyOtpModal] = useState(false);
	const [modalData, setModalData] = useState(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const { state } = useLocation();

	useEffect(() => {
		if (!state || state === null) {
			navigate('/');
		}
	}, [state]);

	const handleVerifyOtpModal = () => {
		setIsOpenVerifyOtpModal((prev) => !prev);
	};

	// useEffect(()=>{
	// 	dispatch(authActions.venue_change_login());
	// },[])

	const initialValues = {
		email: '',
		password: ''
	};
	const validationSchema = yup.object().shape({
		email: validation.YUP_VALIDATION.EMAIL,
		password: validation.YUP_VALIDATION.OLD_PASSWORD
	});

	const {
		handleSubmit,
		touched,
		errors,
		handleChange,
		values,
		resetForm,
		handleBlur
	} = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			try {
				setLoading(true);
				const res = await Api('POST', VenueApiRoutes.connectVenue, {
					...values
				});
				setLoading(false);
				if (res?.data?.status) {
					// resetForm();
					toast.success(res?.data?.message);
					setModalData({
						email: values?.email,
						password: values?.password
					});
					setIsOpenVerifyOtpModal(true);
				} else {
					toast.error(res?.data?.message);
				}
			} catch (err) {
				setLoading(false);
				if (err?.message) {
					toast.error(err?.message);
				}
			}
		}
	});
	return (
		<PageWrapper sectionImage={createAccountImage}>
			<div className="formWrapper pl-26">
				<div>
					<p className="headingStyle pr-45">
						Connect an existing MyTab Venue to your Management
						Portal
					</p>
					<VenuePortalLoginWrraper>
						<div className="containCover">
							<form
								onSubmit={handleSubmit}
								className="formContain"
							>
								<div className="inputContainWrraper">
									<NewFormInput
										autoComplete="off"
										tootlTipMessage="Please enter the email used to create your MyTab Venue account."
										type="email"
										name="email"
										label="Email"
										placeholder="Email"
										showRequired
										value={values?.email}
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched?.email && !!errors?.email
										}
										errorMsg={errors?.email}
									/>
								</div>

								<div className="inputContainWrraper">
									<NewFormPasswordInput
										autoComplete="off"
										tootlTipMessage="Please enter the password used for your MyTab Venue account."
										name="password"
										label="Password"
										showRequired
										placeholder="Password"
										value={values?.password}
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched?.password &&
											!!errors?.password
										}
										errorMsg={errors?.password}
									/>
								</div>

								<div>
									<CustomButton
										type="submit"
										className="newThemeButtonFullWidth pa-t-10"
										loading={loading}
									>
										Connect now
									</CustomButton>
								</div>
							</form>
							<p className="helperLink">
								Need to create your management portal? Click
								<span
									className="helperLinkSpan"
									onClick={() =>
										navigate(
											VenueProtectedRoutes.connectVenue
										)
									}
								>
									{' '}
									here{' '}
								</span>
								to go back.
							</p>
						</div>
					</VenuePortalLoginWrraper>
				</div>
				<Agreement />
			</div>
			<div className="imageWrapper">
				<div className="backWrraper">
					<p className="text">
						Manage your MyTab Venues in one place
					</p>
					<img
						src={createAccountImage}
						alt="side-img"
						className="image"
					/>
				</div>
			</div>
			<NewVerifyOtpModal
				isOpen={isOpenVerifyOtpModal}
				handleModal={handleVerifyOtpModal}
				navigateTo={() => navigate(VenuePanelRoutes.dashboard)}
				modalData={modalData}
				routeState={{ ...state }}
			/>
		</PageWrapper>
	);
};

export default VenuePortalLogin;
