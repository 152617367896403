import React from 'react';
import PageWrapper from '../../Authentication/register.style';
import createAccountImage from '../../../Assets/images/loginPageImg.png';
import FormWrapper from '../../../Components/Authentication/loginForm.style';
import Agreement from '../../../Components/Authentication/Agreement';
import CustomButton from '../../../Components/Common/CustomButton';
import OutlinedButton from '../../../Components/Common/CustomButton/OutlinedButton';
import ConnectVenueWrraper from './newConnectVenue.style';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
	CommonRoutes,
	VenuePanelRoutes,
	VenueProtectedRoutes
} from '../../../Utils/routes';
import { useSelector } from 'react-redux';
import authActions from '../../../Redux/auth/actions';
import { useDispatch } from 'react-redux';

const NewConnectVenue = () => {
	const navigate = useNavigate();
	const { state } = useLocation();

	return (
		<PageWrapper sectionImage={createAccountImage}>
			<div className="formWrapper pl-26 pr-45">
				<ConnectVenueWrraper>
					<h1 className="headingStyle headingCustomPadding">
						Let’s link your MyTab Venue(s) to your Management Portal
					</h1>
					<div className="buttonsGroup">
						<div>
							<p className="buttonHeadText">
								Have a MyTab Venue account already? Press the
								button below.
							</p>
							<CustomButton
								className={'newThemeButtonFullWidth'}
								onClick={() => {
									navigate(
										VenueProtectedRoutes.connectExistingVenue,
										{ state: { ...state } }
									);
								}}
							>
								Connect an existing MyTab Venue account
							</CustomButton>
						</div>
						<div className="d-flex align-items-center gap-2">
							<hr className="hrLine" />
							<text className="optionalText">OR</text>
							<hr className="hrLine" />
						</div>
						<div>
							<p className="buttonHeadText pt-0">
								Don’t have a MyTab Venue account? Let’s set one
								up!
							</p>
							<OutlinedButton
								disabled
								onClick={() => {
									navigate(
										VenueProtectedRoutes.createVenuePortal,
										{
											state: { ...state }
										}
									);
								}}
								buttonTitle={'Create a MyTab Venue account'}
							/>
						</div>
					</div>
				</ConnectVenueWrraper>
				<Agreement />
			</div>
			<div className="imageWrapper">
				<div className="backWrraper">
					<p className="text">
						Manage your MyTab Venues in one place
					</p>
					<img
						src={createAccountImage}
						alt="side-img"
						className="image"
					/>
				</div>
			</div>
		</PageWrapper>
	);
};

export default NewConnectVenue;
