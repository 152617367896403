import { Row, Col, Button } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const VenueProfileFormSkeleton = () => {
	return (
		<div>
			<Row>
				<Col sm={12} className="pa-b-20">
					<Skeleton height="75px" width={'75px'} borderRadius={100} />
				</Col>
			</Row>
			<Row className="ma-b-18" style={{ '--bs-gutter-x': '18px' }}>
				<Col lg={6}>
					<Skeleton height="12px" width={'115px'} borderRadius={6} />
					<Skeleton height="40px" width={'100%'} borderRadius={6} />
				</Col>
				<Col lg={6}>
					<Skeleton height="12px" width={'115px'} borderRadius={6} />
					<Skeleton height="40px" width={'100%'} borderRadius={6} />
				</Col>
			</Row>
			<Row className="ma-b-18" style={{ '--bs-gutter-x': '18px' }}>
				<Col lg={6}>
					<Skeleton height="12px" width={'115px'} borderRadius={6} />
					<Skeleton height="40px" width={'100%'} borderRadius={6} />
				</Col>
				<Col lg={6}>
					<Skeleton height="12px" width={'115px'} borderRadius={6} />
					<Skeleton height="40px" width={'100%'} borderRadius={6} />
				</Col>
			</Row>
			<Row className="ma-b-18" style={{ '--bs-gutter-x': '18px' }}>
				<Col lg={6}>
					<Skeleton height="12px" width={'115px'} borderRadius={6} />
					<Skeleton height="40px" width={'100%'} borderRadius={6} />
				</Col>
				<Col lg={6}>
					<Skeleton height="77px" width={'100%'} borderRadius={6} />
				</Col>
			</Row>
			<Row className="ma-b-18" style={{ '--bs-gutter-x': '18px' }}>
				<Col lg={6}>
					<Skeleton height="77px" width={'100%'} borderRadius={6} />
				</Col>
				<Col lg={6}>
					<Skeleton height="12px" width={'115px'} borderRadius={6} />
					<Skeleton height="40px" width={'100%'} borderRadius={6} />
				</Col>
			</Row>
			<Row className="ma-b-18">
				<Col lg={6}>
					<Skeleton height="51px" width={'100%'} borderRadius={6} />
				</Col>
			</Row>
			<div className="d-flex" style={{ gap: '18px' }}>
				<div>
					<Skeleton height="51px" width={'150px'} borderRadius={6} />
				</div>
				<div>
					<Skeleton height="51px" width={'150px'} borderRadius={6} />
				</div>
			</div>
		</div>
	);
};

export default VenueProfileFormSkeleton;
