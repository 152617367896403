import StyleWrapper from './index.style';
import formRequiredImg from '../../../Assets/images/formRequired.png';
import { Tooltip } from 'reactstrap';
import { useState } from 'react';
import AcceptIcon from '../../../Assets/images/accept.svg';
import NotAcceptIcon from '../../../Assets/images/not-accept.svg';
import SearchIcon from '../../../Assets/images/search.svg';

const NewFormInput = ({
	id,
	label,
	error,
	errorMsg,
	searchBar,
	disabled,
	style,
	showRequired = false,
	suggestionMatcher = false,
	inputSuggestionMatched,
	customInputClass,
	tootlTipMessage = 'Required',
	...rest
}) => {
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const toggle = () => setTooltipOpen(!tooltipOpen);

	const passwordSuggestor = (passwordSuggestionMatched = null) => {
		if (passwordSuggestionMatched === null) {
			return <></>;
		} else if (passwordSuggestionMatched) {
			return (
				<img
					src={AcceptIcon}
					height={18}
					width={18}
					alt="not-accept"
					className="valid-icon-size"
				/>
			);
		} else if (!passwordSuggestionMatched) {
			return (
				<img
					src={NotAcceptIcon}
					height={18}
					width={18}
					alt="matched"
					className="valid-icon-size"
				/>
			);
		} else {
			return <></>;
		}
	};

	return (
		<StyleWrapper borderRight={suggestionMatcher ? '0 !important' : ''}>
			{label && (
				<span className="label">
					{label}&nbsp;
					{showRequired && (
						<>
							<img
								src={formRequiredImg}
								alt="form-required"
								className="requiredImg"
								id={
									id ??
									'tooltiptarget' +
										label
											.split('')
											.filter((s) => s !== ' ')
											.join('')
								}
							/>
							<Tooltip
								className="tooltip-style"
								placement="right-start"
								isOpen={tooltipOpen}
								autohide={false}
								target={
									id ??
									'tooltiptarget' +
										label
											.split('')
											.filter((s) => s !== ' ')
											.join('')
								}
								toggle={toggle}
							>
								{tootlTipMessage}
							</Tooltip>
						</>
					)}
				</span>
			)}
			<div className="inputWrapper">
				{searchBar && (
					<div className="iconWrapper-suggestor-search">
						<img
							src={SearchIcon}
							height={18}
							width={18}
							alt="matched"
							className="valid-icon-size"
						/>
					</div>
				)}
				<input
					className={searchBar ? `searchInput` : `input`}
					disabled={disabled}
					style={style}
					{...rest}
					// id={id}
				/>
				{suggestionMatcher && (
					<div className="iconWrapper-suggestor">
						{passwordSuggestor(inputSuggestionMatched)}
					</div>
				)}
			</div>

			{error && <p className="error">{errorMsg}</p>}
		</StyleWrapper>
	);
};

export default NewFormInput;
