export const RightArrowIcon = ({ color, width, height, ...props }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6 3.5L11 8.5L6 13.5"
				stroke={color ?? 'black'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
