import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Radium from 'radium';
import Sidebar from '../Components/Sidebar/Sidebar';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import GlobalWrapper from './global.style';
import { drawerWidth, miniDrawerWidth } from '../Helper/constant';
import settingActions from '../Redux/themeSettings/actions';
import useDevice from '../Hooks/useDevice';

const { sidebarMini } = settingActions;

const Layout = ({ children }) => {
	// let routeScrollHeight;
	const mainPanel = useRef();
	const dispatch = useDispatch();
	const { isTabletOrMobile } = useDevice();

	const statedrawerWidth = drawerWidth;
	const [stateminiDrawerWidth, setStateminiDrawerWidth] =
		useState(miniDrawerWidth);
	const allthemedata = useSelector((state) => ({
		...state?.themeChanger,
		themeSetting: state?.themeSetting
	}));

	const authData = useSelector((state) => ({
		...state.auth
	}));

	const [mini, setMini] = useState(
		allthemedata.themeSetting.sidebarMiniValue
			? allthemedata.themeSetting.sidebarMiniValue === 'on'
			: false
	);

	// useEffect(() => {
	// 	if (isTabletOrMobile) {
	// 		setMini(true);
	// 	} else {
	// 		setMini(false);
	// 	}
	// }, [isTabletOrMobile]);

	const drawerMiniMethod = () => {
		if (mini) {
			setMini(false);
			dispatch(sidebarMini('off'));
		} else {
			setMini(true);
			dispatch(sidebarMini('on'));
		}
	};

	const mouseEnter = () => {
		setMini(false);
		dispatch(sidebarMini('off'));
		// if (mini) {
		// 	setStateminiDrawerWidth(drawerWidth);
		// }
	};

	const mouseLeave = () => {
		setMini(true);
		dispatch(sidebarMini('on'));
		// if (mini) {
		// 	setStateminiDrawerWidth(miniDrawerWidth);
		// }
	};

	const closeDrawer = () => {
		setMini(true);
		dispatch(sidebarMini('on'));
	};

	let routeArrowIcon = {
		backgroundColor: allthemedata?.sidebarTheme?.backgroundColor,
		color: allthemedata?.sidebarTheme?.textColor,
		borderBottomLeftRadius: 0,
		borderTopLeftRadius: 0,
		position: 'fixed',
		zIndex: 1,
		fontSize: '18px',
		padding: '4px 5px'
	};

	const mainPanelWidth = {
		height: 'calc(100vh - 51px)',
		width:
			authData?.isLogin &&
			authData?.login_type === 'venue' &&
			authData?.bars?.length > 0
				? `calc(100% - ${stateminiDrawerWidth})`
				: `100%`,
		// statedrawerWidth
		'@media (max-width: 767.98px)': {
			width: `100%`
		}
	};
	// if (allthemedata.themeSetting.toolbarDisplayValue === "hide" && allthemedata.themeSetting.footerDisplayValue === "hide") {
	//   routeScrollHeight = {
	//     height: "calc(100vh - 0px)"
	//   };
	// } else if (allthemedata.themeSetting.toolbarDisplayValue === "hide") {
	//   routeScrollHeight = {
	//     height: "calc(100vh - 51px)"
	//   };
	// } else if (allthemedata.themeSetting.footerDisplayValue === "hide") {
	//   routeScrollHeight = {
	//     height: "calc(100vh - 65px)"
	//   };
	// } else {
	//   routeScrollHeight = {
	//     height: "calc(100vh - 112px)"
	//   };
	// }

	// useEffect(() => {
	// 	setMini(true);
	// }, [allthemedata.themeSetting.sidebarMiniValue]);
	return (
		<GlobalWrapper {...allthemedata} mini={mini}>
			<div className="d-flex flex-column flex-1 w-100">
				<div className="main-body">
					{authData?.isLogin &&
						authData?.login_type === 'venue' &&
						authData?.bars?.length > 0 && (
							<Sidebar
								{...allthemedata}
								mini={mini}
								drawerWidth={statedrawerWidth}
								miniDrawerWidth={stateminiDrawerWidth}
								onMouseEnter={mouseEnter}
								onMouseLeave={mouseLeave}
								closeDrawer={closeDrawer}
								drawerMiniMethod={drawerMiniMethod}
							/>
						)}
					<div
						id="main-panel"
						className="main-panel"
						ref={mainPanel}
						style={mainPanelWidth}
					>
						{allthemedata?.themeSetting?.toolbarDisplayValue ===
							'show' && (
							<Header
								drawerMiniMethod={drawerMiniMethod}
								mini={mini}
								drawerWidth={statedrawerWidth}
								miniDrawerWidth={statedrawerWidth}
								closeDrawer={closeDrawer}
								{...allthemedata}
							/>
						)}
						{allthemedata?.themeSetting?.toolbarDisplayValue !==
							'show' && (
							<button
								style={routeArrowIcon}
								className="c-btn mini-drawer-menu-icon-hide-topbar"
								onClick={drawerMiniMethod}
							>
								<i className="fas fa-bars" />
							</button>
						)}
						{/* <Scrollbars style={routeScrollHeight} autoHide> */}
						{/* Route Layout Start*/}
						<div className="route-height">{children}</div>
						{/* Route Layout Finish*/}
						{/* </Scrollbars> */}
					</div>
					<div className="main-body-overlay"></div>
				</div>
				<div className="layout-footer">
					<Footer {...allthemedata} />
				</div>
			</div>
		</GlobalWrapper>
	);
};

export default Radium(Layout);
