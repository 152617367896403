export const TrashBinIcon = ({ fill, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 24 24" fill="none">
			<path
				d="M18.9062 6.0625C19.3633 6.0625 19.75 6.44922 19.75 6.90625C19.75 7.39844 19.3633 7.75 18.9062 7.75H18.4844L17.6406 19.1758C17.5352 20.3711 16.5859 21.25 15.3906 21.25H8.32422C7.12891 21.25 6.17969 20.3711 6.07422 19.1758L5.23047 7.75H4.84375C4.35156 7.75 4 7.39844 4 6.90625C4 6.44922 4.35156 6.0625 4.84375 6.0625H7.26953L8.57031 4.12891C8.92188 3.60156 9.55469 3.25 10.2227 3.25H13.4922C14.1602 3.25 14.793 3.60156 15.1445 4.12891L16.4453 6.0625H18.9062ZM10.2227 4.9375C10.1172 4.9375 10.0117 5.00781 9.97656 5.07812L9.30859 6.0625H14.4062L13.7383 5.07812C13.7031 5.00781 13.5977 4.9375 13.4922 4.9375H10.2227ZM16.7969 7.75H6.91797L7.76172 19.0703C7.79688 19.3516 8.04297 19.5625 8.32422 19.5625H15.3906C15.6719 19.5625 15.918 19.3516 15.9531 19.0703L16.7969 7.75Z"
				fill={fill ?? '#242424'}
			/>
		</svg>
	);
};
