import styled from 'styled-components';

const PageWrapper = styled.div`
	background-color: #fffdfd;
	color: ${(props) => props.layoutTheme.textColor} !important;
	.defaultBoxShadow {
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
	}
	.dateDropdownBox {
		background: #f9f9f9;
		border-radius: 8px;
		min-height: 52px;
		width: 98px;
	}
	@media only screen and (max-width: 767px) {
		padding: 12px;
		.border-radius-16 {
			border-radius: 0px !important;
		}
		.defaultBoxShadow {
			box-shadow: unset;
		}
	}
	.analyticsCard {
		background-color: #ffffff;
	}
`;

export default PageWrapper;
