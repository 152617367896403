import { useDispatch, useSelector } from 'react-redux';
import 'react-tabs-scrollable/dist/rts.css';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PageWrapper from './index.style';
import PageStructure from '../../../Components/Common/PageStructure';
import { PlusIcon } from '../../../Components/Icons';
import ConnectVenueCard from '../../../Components/UserProfile/ConnectVenue/ConnectVenueCard';
import ConnectVenueSkeleton from '../../../Components/UserProfile/ConnectVenue/ConnectVenueSkeleton';
import VenueAccountVerificationModal from '../../../Components/UserProfile/ConnectVenue/VenueAccountVerificationModal';
import useDevice from '../../../Hooks/useDevice';
import { VenueApiRoutes, VenuePanelRoutes } from '../../../Utils/routes';
import Api from '../../../Helper/Api';
import authActions from '../../../Redux/auth/actions';
import { useCachedData } from '../../../Hooks/useCachedData';
import DeleteConfirmModal from '../../../Components/UserProfile/ConnectVenue/DeleteConfirmModal';

export const ConnectVenue = () => {
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	const authData = useSelector((state) => ({
		...state?.auth
	}));
	const bars = authData?.bars;
	const selectedVenue = authData?.selectedVenue;
	const { data, isLoading, isError, error, refetch } = useCachedData(
		'GET',
		VenueApiRoutes.getConnectedVenue
	);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
	const [isOpenAccountVerificationModal, setIsOpenAccountVerificationModal] =
		useState(false);
	const [loading, setLoading] = useState(false);
	const [deletedVenueId, setDeletedVenueId] = useState(null);
	const handleAccountVerificationModal = () => {
		setIsOpenAccountVerificationModal((prev) => !prev);
	};
	const toggleDeleteModal = () => {
		setIsOpenDeleteModal((prev) => !prev);
	};
	// API to delete venue
	const handleDelete = async () => {
		let barId = deletedVenueId;
		try {
			const res = await Api('POST', VenueApiRoutes.deleteVenue, {
				bar_id: barId
			});
			if (res?.data?.status) {
				let updatedBars = [];
				if (bars?.length > 0) {
					bars?.forEach((item) => {
						if (item?.id != barId) {
							updatedBars?.push(item);
						}
					});
				}
				if (barId == selectedVenue?.id) {
					if (updatedBars?.length > 0) {
						dispatch(
							authActions.set_selected_venue(updatedBars[0])
						);
					} else {
						dispatch(authActions.set_selected_venue(null));
					}
				}
				dispatch(authActions.update_venue_list(updatedBars));
				toast.success(res?.data?.message);
				toggleDeleteModal();
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	useEffect(() => {
		if (data?.data?.length > 0) {
			let connectedVenueData = data?.data?.map((item, index) => {
				return {
					avatar: item?.bar?.avatar,
					docketStatus: item?.bar?.docketStatus,
					id: item?.bar_id,
					managerName: item?.bar?.managerName,
					posStatus: item?.bar?.posStatus,
					restaurantName: item?.bar?.restaurantName,
					venueId: item?.bar?.venueId,
					docketCommission: item?.bar?.docketCommission,
					posFee: item?.bar?.posFee,
					passcodeStatus: item?.bar?.passcodeStatus,
					passcodeLength: item?.bar?.passcodeLength,
					countryCode: item?.bar?.countryCode,
					mobile: item?.bar?.mobile
				};
			});
			dispatch(authActions.update_venue_list(connectedVenueData));
			if (!selectedVenue) {
				dispatch(authActions.set_selected_venue(connectedVenueData[0]));
			}
		}
		if (data?.data?.length === 0) {
			dispatch(authActions.update_venue_list([]));
		}
	}, [data, data?.data]);
	useEffect(() => {
		setLoading(isLoading);
		if (isError) {
			toast.error(error);
		}
	}, [isLoading, isError, error]);
	useEffect(() => {
		refetch();
	}, [selectedVenue?.id]);
	return (
		<>
			<PageStructure
				title="MyTab Venues Connected To Your CMS Account"
				buttonName="Connect Your MyTab Venue"
				buttonIcon={
					<PlusIcon height="18" width="18" className="mr-6" />
				}
				handleButtonClick={() =>
					setIsOpenAccountVerificationModal(true)
				}
			>
				{loading ? (
					<ConnectVenueSkeleton />
				) : (
					<PageWrapper {...allThemeData}>
						{bars?.length > 0 &&
							bars?.map((item, index) => {
								return (
									<div
										className={`${
											index === 0 ? '' : 'pa-t-24'
										}`}
										key={item?.id}
									>
										<ConnectVenueCard
											title={item?.restaurantName}
											image={item?.avatar}
											id={`connect_venue_popover_${item?.id}`}
											barId={item?.id}
											setIsOpenDeleteModal={
												setIsOpenDeleteModal
											}
											setDeletedVenueId={
												setDeletedVenueId
											}
										/>
									</div>
								);
							})}
						<div className="pa-t-32 pa-b-2">
							<Button
								className="borderButtonFullWidth border-radius-12"
								onClick={() =>
									navigate(
										VenuePanelRoutes.createVenueAccount
									)
								}
							>
								<PlusIcon height={18} width={18} /> Create a
								MyTab Venue account
							</Button>
						</div>
					</PageWrapper>
				)}
			</PageStructure>
			<VenueAccountVerificationModal
				isOpen={isOpenAccountVerificationModal}
				handleModal={handleAccountVerificationModal}
				navigateTo={() => navigate(VenuePanelRoutes.connectVenue)}
				refetch={refetch}
			/>
			<DeleteConfirmModal
				isOpen={isOpenDeleteModal}
				handleModal={toggleDeleteModal}
				handleDelete={handleDelete}
			/>
		</>
	);
};
