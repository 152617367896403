import styled from 'styled-components';

export const HeaderWrapper = styled.div`
	color: ${(props) => props.layoutTheme.textColor} !important;
	.customScrollableNavbar {
		overflow-x: scroll;
		scroll-behavior: smooth;
		margin: 0px;
		padding: 0px;
		&::-webkit-scrollbar {
			background: transparent; /* make scrollbar transparent */
			-webkit-appearance: none;
			width: 0;
			height: 0;
		}
		.rts___tabs {
			padding: 0px !important;
		}
		.rts___tabs___container {
			padding: 0px !important;
		}
		button {
			border: none;
			outline: none;
			background-color: #fff;
			box-shadow: none;
			border-radius: 0px !important;
			margin: 0;
			&:disabled {
				i {
					display: none;
				}
			}
		}
		.rts___left___nav___btn,
		.rts___right___nav___btn {
			border-bottom: 2px solid #ededed;
			max-width: 100%;
			padding: 12px 10px;
			border-radius: 0px !important;
		}
		.customScrollableNavItem {
			color: #bababa;
			padding: 8px 25px;
			white-space: nowrap;
			border-bottom: 2px solid #ededed;
			z-index: 2;
			cursor: pointer;
			&.active {
				color: ${(props) => props.layoutTheme.textColor};
				border-bottom: 2px solid
					${(props) => props.layoutTheme.headingColor};
			}
		}
		.borderBottom {
			border-bottom: 2px solid #ededed;
		}
	}
	.customSelect2__dropdown-indicator {
		flex: 1;
	}
	.dashboard-dropdown {
		.activeColor {
			color: ${(props) => props.layoutTheme.buttonColor3} !important;
		}
		.dropdown-item,
		.dropdownToggle {
			outline: 0 !important;
			border: 0px !important;
			padding-left: 10px;
			padding-right: 10px;
			padding-top: 10px;
			&.active {
				background-color: ${(props) =>
					props.layoutTheme.buttonColor3} !important;
			}
			@media only screen and (max-width: 575.98px) {
				padding-top: 5px;
				margin-top: 6px;
				margin-bottom: 6px;
			}
			.dropdown-name {
				padding-right: 5px;
				color: ${(props) => props.layoutTheme.textColor};
			}
			&:hover {
				outline: 0 !important;
				border: 0px !important;
			}
			&:active {
				outline: 0 !important;
				border: 0px !important;
				background-color: #fff !important;
				color: ${(props) => props.layoutTheme.textColor};
			}
		}
		.dropdown-menu {
			border: 0px !important;
			@media only screen and (max-width: 360px) {
			}
		}
	}
	.dateDropdownBox {
		background: #f9f9f9;
		border-radius: 8px;
		min-height: 52px;
	}
	.orderTypeDropdownBox {
		background: transparent;
		border: 1px solid #f94d73;
		border-radius: 8px;
		min-height: 52px;
	}
	.dropdown-item.dropdownCalender:hover {
		background-color: #fff;
	}
	.dropdownCalenderParent {
		border-right: 1px solid #eaeaea;
		margin-right: 10px;
		padding-right: 15px;
		@media only screen and (max-width: 575px) {
			border-right: 0px;
			margin-right: 0px;
			padding-right: 0px;
		}
	}
	.datePickerDropdown {
		max-height: 300px;
		overflow: auto;
		margin-top: 15px;
	}
`;

export const PageWrapper = styled.div`
	color: ${(props) => props.layoutTheme.textColor} !important;
`;
