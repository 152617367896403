import { Fragment, useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Collapse, CardBody, Card } from 'reactstrap';
import classNames from 'classnames';
import NavSecondChild from './NavSecondChild';
import { DownArrowIcon, UpArrowIcon } from '../Icons';

const NavList = (props) => {
	const [collapse, setCollapse] = useState(false);
	const location = useLocation();

	let dynamicList;
	const { listNameStyle, list, mini, miniDrawerWidth, drawerWidth } = props;

	useEffect(() => {
		if (list?.child) {
			const finalList = list.child.filter(
				(a) => !a.hasOwnProperty('child')
			);
			const threeList = list.child.filter((a) =>
				a.hasOwnProperty('child')
			);
			const finalThreelevel = [];
			if (threeList.length) {
				threeList.forEach((element) => {
					element.child.forEach((ele) => {
						finalThreelevel.push(ele.routePath);
					});
				});
			}
			if (
				(finalList.length &&
					finalList
						.map((a) => a.routePath)
						.includes(location.pathname)) ||
				(finalThreelevel && finalThreelevel.includes(location.pathname))
			) {
				setCollapse(true);
			}
		}
	}, [location.pathname, list.child]);

	const CurrentRoute = location.pathname;
	if (list.child) {
		const finalList = list.child.filter((a) => !a.hasOwnProperty('child'));
		const threeList = list.child.filter((a) => a.hasOwnProperty('child'));
		const finalThreelevel = [];
		if (threeList.length) {
			threeList.forEach((element) => {
				element.child.forEach((ele) => {
					finalThreelevel.push(ele.routePath);
				});
			});
		}
		dynamicList = (
			<li className="pos-relative mr-24 pa-b-12">
				{!mini || miniDrawerWidth === drawerWidth ? (
					collapse ? (
						<UpArrowIcon
							height={6}
							width={8}
							onClick={() => setCollapse((prev) => !prev)}
							className="arrow-sidebar"
						/>
					) : (
						<DownArrowIcon
							height={6}
							width={8}
							onClick={() => setCollapse((prev) => !prev)}
							className="arrow-sidebar"
						/>
					)
				) : (
					''
				)}
				<div
					onClick={(e) => {
						setCollapse((prev) => !prev);
						e.preventDefault();
					}}
					className={classNames(
						((finalList.length &&
							finalList
								.map((a) => a.routePath)
								.includes(CurrentRoute)) ||
							(finalThreelevel &&
								finalThreelevel.includes(
									`/${CurrentRoute.split('/')[2]}`
								))) &&
							'active',
						'nav-link',
						'main-list',
						'cursor-pointer'
					)}
				>
					{/* <i className={list.iconClass} /> */}
					{mini === false || miniDrawerWidth === drawerWidth ? (
						<>
							<div className="d-flex align-items-center gap-3">
								<span>{list.icon}</span>
								<p
									style={listNameStyle}
									className="fs-14 medium-text itemHeight"
								>
									{list.name}
								</p>
							</div>
							<Collapse isOpen={collapse}>
								<Card className="background-transparent border-none">
									<CardBody
										className={classNames(
											'pa-0',
											props?.themeSetting
												?.activeLinkStyle ===
												'style4' &&
												'active-sidebar-type-class'
										)}
									>
										{list.child
											? list.child.map((childList, i) => {
													return (
														<Fragment key={i}>
															{childList.child ? (
																<NavSecondChild
																	CurrentRoute={
																		CurrentRoute
																	}
																	listNameStyle={
																		listNameStyle
																	}
																	childList={
																		childList
																	}
																	mini={mini}
																	miniDrawerWidth={
																		miniDrawerWidth
																	}
																	drawerWidth={
																		drawerWidth
																	}
																/>
															) : (
																<NavLink
																	to={
																		childList.routePath
																	}
																	className="nav-link child-list"
																	key={i}
																>
																	<div className="pl-25 pa-b-12">
																		<p
																			style={
																				listNameStyle
																			}
																			className="fs-14 medium-text itemHeight"
																		>
																			{
																				childList.name
																			}
																		</p>
																	</div>
																</NavLink>
															)}
														</Fragment>
													);
											  })
											: ''}
									</CardBody>
								</Card>
							</Collapse>
						</>
					) : (
						<div className="d-flex align-items-center gap-3">
							<span>{list.icon}</span>
						</div>
					)}
				</div>
			</li>
		);
	} else {
		dynamicList = (
			<li className="mr-24 pa-b-12">
				<NavLink
					to={list.routePath}
					className="nav-link main-list d-flex align-items-center"
				>
					{mini === false || miniDrawerWidth === drawerWidth ? (
						<div className="d-flex align-items-center gap-3">
							<span>{list.icon}</span>
							<p
								style={listNameStyle}
								className="fs-14 medium-text itemHeight"
							>
								{list.name}
							</p>
						</div>
					) : (
						<div className="d-flex align-items-center gap-3">
							<span>{list.icon}</span>
						</div>
					)}
				</NavLink>
			</li>
		);
	}
	return <Fragment>{dynamicList}</Fragment>;
};

export default NavList;
