import styled from 'styled-components';

const WrapperDiv = styled.div`
	.menuSubHeadingsModal {
		.row {
			margin-right: 0 !important;
			margin-left: 0 !important;
		}
	}
	.addPrinterModal {
		.inputBox {
			border-radius: 8px !important;
		}
	}
`;

export default WrapperDiv;
