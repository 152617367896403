import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import 'react-tabs-scrollable/dist/rts.css';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import PageWrapper from './docketPrinters.style';
import DocketPrintersTable from '../../../Components/PremiumFeatures/DocketPrinting/DocketPrintersTable';
import PageStructure from '../../../Components/Common/PageStructure';
import AddPrinterModal from '../../../Components/PremiumFeatures/DocketPrinting/AddPrinterModal';
import MenuSubHeadingsModal from '../../../Components/PremiumFeatures/DocketPrinting/MenuSubHeadingsModal';
import UnactivateConfirmModal from '../../../Components/PremiumFeatures/DocketPrinting/UnactivateConfirmModal';
import {
	data,
	formatDocketPrintersTableData
} from '../../../Components/PremiumFeatures/DocketPrinting/utils';
import Api from '../../../Helper/Api';
import { VenuePanelRoutes } from '../../../Utils/routes';

export const DocketPrinters = () => {
	const state = useSelector((state) => ({ ...state }));
	const allThemeData = state.themeChanger;
	const authDetails = state.auth;
	const [tableLoading, setTableLoading] = useState(false);
	const [tableData, setTableData] = useState(data);
	const [tableDataCount, setTableDataCount] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [isPrinterModalOpen, setIsPrinterModalOpen] = useState(false);
	const [isMenuSubheadingsModalOpen, setIsMenuSubheadingsModalOpen] =
		useState(false);
	const [unactivateConfirmModal, setUnactivateConfirmModal] = useState(false);
	const handleUnactivateConfirmModal = () => {
		setUnactivateConfirmModal((prev) => !prev);
	};
	const handleAddPrinterModal = () => {
		setIsPrinterModalOpen((prev) => !prev);
	};
	const handleMenuSubheadingsModal = () => {
		setIsMenuSubheadingsModalOpen(!isMenuSubheadingsModalOpen);
	};
	const handlePageChange = ({ selected }) => {
		let pageNo = selected + 1;
		setCurrentPage(pageNo);
	};
	const columns = [
		{
			Header: (
				<span className="fs-14 medium-text pl-14">Printer Name</span>
			),
			accessor: 'printer_name',
			className: 'justify-content-start pl-24 fs-14 regular-text',
			minWidth: 150,
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class justify-content-start'
		},
		{
			Header: 'IP Address',
			accessor: 'ip_address',
			className: 'fs-14 regular-text',
			minWidth: 150,
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-14 medium-text'
		},
		{
			Header: 'Print Docket',
			accessor: 'print_docket',
			className: 'fs-14 regular-text',
			minWidth: 120,
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-14 medium-text'
		},
		{
			Header: 'Action',
			accessor: 'action',
			Cell: (row) => {
				return (
					<span
						onClick={() => handleMenuSubheadingsModal()}
						className="fs-14 medium-text"
					>
						{row.value}
					</span>
				);
			},
			className: 'themeLinkText cursor-pointer fs-14 medium-text',
			minWidth: 120,
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-14 medium-text'
		}
	];
	const getTableData = async (payload) => {
		try {
			setTableLoading(true);
			const res = await Api('POST', '/api route', payload);
			setTableLoading(false);
			if (res?.data?.status) {
				const tableDetails = formatDocketPrintersTableData(
					res?.data?.data?.rows
				);
				setTableData(tableDetails);
				setTableDataCount(res?.data?.data?.count);
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setTableLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	useEffect(() => {
		// getTableData();
	}, []);
	if (!authDetails?.docketPrintingFeature) {
		return <Navigate to={VenuePanelRoutes.docketFeatures} />;
	}
	return (
		<>
			<PageStructure
				title="Your Connected Docket Printers"
				buttonName="Unactivate Feature"
				handleButtonClick={() => setUnactivateConfirmModal(true)}
			>
				<PageWrapper {...allThemeData}>
					{/* <p className="fs-20 medium-text pa-b-32">
						Network Printers
					</p>
					<div className="pa-b-32">
						<Button
							className="no-border pageButton d-flex justify-content-center align-items-center mt-0 plr-40 borderButton border-radius-8"
							onClick={() => {
								setIsPrinterModalOpen(true);
							}}
						>
							<PlusIcon height={24} width={24} />{' '}
							<span className="pl-10 fs-18 medium-text">
								Add Printer
							</span>
						</Button>
					</div> */}
					<DocketPrintersTable
						columns={columns}
						data={tableData}
						loading={tableLoading}
						handlePageChange={handlePageChange}
						totalRows={tableDataCount}
						currentPage={currentPage}
					/>
				</PageWrapper>
			</PageStructure>
			<AddPrinterModal
				type={'add'}
				isOpen={isPrinterModalOpen}
				handleModal={handleAddPrinterModal}
			/>
			<MenuSubHeadingsModal
				isOpen={isMenuSubheadingsModalOpen}
				handleModal={handleMenuSubheadingsModal}
			/>
			<UnactivateConfirmModal
				isOpen={unactivateConfirmModal}
				handleModal={handleUnactivateConfirmModal}
			/>
		</>
	);
};
