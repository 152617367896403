export const roleAndPermissionData = [
	{
		id: '01',
		name: 'Sub-Admin',
		status: true,
		permission: [
			{
				id: 1,
				name: 'User Management',
				add: true,
				view: true,
				edit: true,
				delete: true,
				status: true
			},
			{
				id: 2,
				name: 'Venue Management',
				add: true,
				view: true,
				edit: true,
				delete: true,
				status: true
			},
			{
				id: 3,
				name: 'Category Management',
				add: true,
				view: true,
				edit: true,
				delete: true,
				status: true
			},
			{
				id: 4,
				name: 'Promo Code Management',
				add: false,
				view: true,
				edit: false,
				delete: false,
				status: false
			},
			{
				id: 5,
				name: 'Template Management',
				add: false,
				view: true,
				edit: false,
				delete: false,
				status: false
			},
			{
				id: 6,
				name: 'Pages Management',
				add: true,
				view: true,
				edit: true,
				delete: true,
				status: true
			},
			{
				id: 7,
				name: 'Order Management',
				add: false,
				view: true,
				edit: false,
				delete: false,
				status: false
			},
			{
				id: 8,
				name: 'Transaction Management',
				add: false,
				view: false,
				edit: false,
				delete: false,
				status: false
			}
		]
	},
	{
		id: '02',
		name: 'Venue Employee',
		status: false,
		permission: [
			{
				id: 1,
				name: 'User Management',
				add: false,
				view: false,
				edit: false,
				delete: false,
				status: false
			},
			{
				id: 2,
				name: 'Venue Management',
				add: false,
				view: false,
				edit: false,
				delete: false,
				status: false
			},
			{
				id: 3,
				name: 'Category Management',
				add: true,
				view: true,
				edit: true,
				delete: true,
				status: true
			},
			{
				id: 4,
				name: 'Promo Code Management',
				add: false,
				view: true,
				edit: false,
				delete: false,
				status: false
			},
			{
				id: 5,
				name: 'Template Management',
				add: false,
				view: true,
				edit: false,
				delete: false,
				status: false
			},
			{
				id: 6,
				name: 'Pages Management',
				add: false,
				view: true,
				edit: false,
				delete: false,
				status: false
			},
			{
				id: 7,
				name: 'Order Management',
				add: false,
				view: true,
				edit: false,
				delete: false,
				status: false
			},
			{
				id: 8,
				name: 'Transaction Management',
				add: false,
				view: false,
				edit: false,
				delete: false,
				status: false
			}
		]
	},
	{
		id: '03',
		name: 'Supervisor',
		status: true,
		permission: [
			{
				id: 1,
				name: 'User Management',
				add: true,
				view: true,
				edit: true,
				delete: true,
				status: true
			},
			{
				id: 2,
				name: 'Venue Management',
				add: false,
				view: false,
				edit: false,
				delete: false,
				status: false
			},
			{
				id: 3,
				name: 'Category Management',
				add: true,
				view: true,
				edit: true,
				delete: true,
				status: true
			},
			{
				id: 4,
				name: 'Promo Code Management',
				add: true,
				view: true,
				edit: true,
				delete: true,
				status: true
			},
			{
				id: 5,
				name: 'Template Management',
				add: false,
				view: true,
				edit: false,
				delete: false,
				status: false
			},
			{
				id: 6,
				name: 'Pages Management',
				add: true,
				view: true,
				edit: true,
				delete: true,
				status: true
			},
			{
				id: 7,
				name: 'Order Management',
				add: true,
				view: true,
				edit: true,
				delete: true,
				status: true
			},
			{
				id: 8,
				name: 'Transaction Management',
				add: true,
				view: true,
				edit: true,
				delete: true,
				status: true
			}
		]
	},
	{
		id: '04',
		name: 'Manager',
		status: false,
		permission: [
			{
				id: 1,
				name: 'User Management',
				add: false,
				view: true,
				edit: false,
				delete: false,
				status: false
			},
			{
				id: 2,
				name: 'Venue Management',
				add: false,
				view: true,
				edit: false,
				delete: false,
				status: false
			},
			{
				id: 3,
				name: 'Category Management',
				add: true,
				view: true,
				edit: true,
				delete: true,
				status: true
			},
			{
				id: 4,
				name: 'Promo Code Management',
				add: false,
				view: true,
				edit: false,
				delete: false,
				status: false
			},
			{
				id: 5,
				name: 'Template Management',
				add: false,
				view: true,
				edit: false,
				delete: false,
				status: false
			},
			{
				id: 6,
				name: 'Pages Management',
				add: false,
				view: true,
				edit: false,
				delete: false,
				status: false
			},
			{
				id: 7,
				name: 'Order Management',
				add: true,
				view: true,
				edit: false,
				delete: false,
				status: false
			},
			{
				id: 8,
				name: 'Transaction Management',
				add: false,
				view: true,
				edit: false,
				delete: false,
				status: false
			}
		]
	}
];
