export const UpArrowIcon = ({ stroke, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 12 7" fill="none">
			<path
				d="M11 5.99951L6 1.00071L1 5.99951"
				stroke={stroke ?? 'black'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
