import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { CommonRoutes } from '../../../../Utils/routes';

const AdminRoute = ({ children }) => {
	const authDetails = useSelector((state) => state.auth);

	if (authDetails?.isLogin) {
		if (authDetails?.login_type === 'admin') {
			return children;
		} else {
			// Navigate to 404 Not Found Page
			return <Navigate to={CommonRoutes.pageNotFound} />;
		}
	} else {
		// Navigate to login page
		return <Navigate to={CommonRoutes.login} />;
	}
};

export default AdminRoute;
