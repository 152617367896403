import Select, { components } from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { DownArrowIcon } from '../Icons';

const select2Style = {
	control: (styles, state) => ({
		...styles,
		flex: '1',
		backgroundColor: '#F9F9F9',
		boxShadow: 'none !important',
		borderColor: '#FFF !important',
		outline: state.isFocused && '#FFF',
		textAlign: 'left',
		padding: 6,
		borderRadius: 8,
		justifyContent: 'end',
		'&:hover': {
			borderColor: '#FFF',
			outline: 'none'
		}
	}),
	option: (styles, { isDisabled, isSelected }) => {
		return {
			...styles,
			boxShadow: 'none !important',
			borderColor: '#FFF !important',
			textAlign: 'left',
			cursor: isDisabled ? 'not-allowed' : 'default',
			background: isSelected
				? '#FF5F5F !important'
				: '#FFFFFF !important',
			color: isSelected ? '#FFFFFF !important' : '#242424 !important',
			'&:hover': {
				background: isSelected
					? '#FF5F5F !important'
					: 'rgba(0,0,0,0.1) !important',
				color: isSelected ? '#FFFFFF !important' : '#000000 !important'
			},
			fontFamily: 'montserrat-medium',
			fontWeight: '400',
			margin: '0px'
		};
	}
};

const DropdownIndicator = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<DownArrowIcon height={7} width={12} />
		</components.DropdownIndicator>
	);
};

const ValueContainer = ({ children, ...props }) => {
	return (
		<components.DropdownIndicator {...props}>
			{children}
		</components.DropdownIndicator>
	);
};

const ReactFormSelect = ({
	id,
	label,
	error,
	errorMsg,
	placeholder,
	options,
	value,
	handleChange,
	maxMenuHeight = 500,
	isSearchable = false,
	menuPlacement = 'bottom'
}) => {
	return (
		<FormGroup>
			{label && (
				<Label
					for={id}
					className="fs-14 medium-text themeText label-color"
				>
					{label}
				</Label>
			)}
			<Select
				placeholder={placeholder}
				className="Select2CustomWidth reactFormSelect justify-content-center"
				classNamePrefix="customSelect2"
				components={{
					IndicatorSeparator: () => null,
					DropdownIndicator,
					ValueContainer
				}}
				options={options}
				isSearchable={isSearchable}
				styles={select2Style}
				menuPlacement={menuPlacement}
				maxMenuHeight={maxMenuHeight}
				value={value}
				onChange={handleChange}
			/>
			{error && (
				<p className="fs-12 semi-bold-text fw-bold">
					<span className="headingTextColor">{errorMsg}</span>
				</p>
			)}
		</FormGroup>
	);
};

export default ReactFormSelect;
