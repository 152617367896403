import { Progress } from 'reactstrap';
import StyleWrapper from './progressBar.style';

const Progressbar = ({ title, subtitle, percentage, ...props }) => {
	return (
		<StyleWrapper>
			<p className="fs-14 medium-text">{title}</p>
			<div className="d-flex align-items-center">
				<div className="flex-1">
					<Progress
						className="progress_bar"
						barStyle={{
							background: '#FD6461'
						}}
						value={percentage}
					/>
				</div>
				<div className="progress_bar_text d-flex pl-10 fs-14 regular-text justify-content-start">
					{subtitle}
				</div>
			</div>
		</StyleWrapper>
	);
};

export default Progressbar;
