import { useSelector } from 'react-redux';

import ResetPasswordForm from '../../Components/Authentication/ResetPasswordForm';
import PageWrapper from './resetPassword.style';
import passwordCheckImage from '../../Assets/images/reset-password.png';
import Agreement from '../../Components/Authentication/Agreement';
import loginPageImg from '../../Assets/images/loginPageImg.png';
export const ResetPassword = (props) => {
	const allthemeData = useSelector((state) => ({ ...state.themeChanger }));

	return (
		<PageWrapper {...allthemeData}>
			<div className="formWrapper pl-26 pr-45">
				<ResetPasswordForm {...props} />
				<Agreement />
			</div>
			<div className="imageWrapper">
				<div className="backWrraper">
					<p className="text">
						Manage your MyTab Venues in one place
					</p>
					<img src={loginPageImg} alt="side-img" className="image" />
				</div>
			</div>
			{/* <div>
				<div className="pageContainer">
					<div className="imageContainer">
						<img
							src={passwordCheckImage}
							alt="app-photos"
							className="pageImage"
						/>
					</div>
					<div className="formCardContainer">
						<ResetPasswordForm {...props} />
					</div>
				</div>
			</div> */}
		</PageWrapper>
	);
};
