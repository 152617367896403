export const CancelIcon = ({ stroke, roundBorder, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 32 32" fill="none">
			{roundBorder && (
				<path
					d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
					stroke={stroke ?? 'black'}
					strokeWidth="2"
				/>
			)}
			<path
				d="M20 12L12 20"
				stroke={stroke ?? 'black'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M20 20L12 12"
				stroke={stroke ?? 'black'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
