import { useFormik } from 'formik';
import * as yup from 'yup';
import { Col, Row } from 'reactstrap';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import * as validation from '../../../Helper/YupValidation';
import FormInput from '../../Form/FormInput';
import CustomButton from '../../Common/CustomButton';
import { VenueApiRoutes } from '../../../Utils/routes';
import Api from '../../../Helper/Api';

const VenueChangePassword = () => {
	const authData = useSelector((state) => ({ ...state.auth }));
	const [loading, setLoading] = useState(false);
	const validationSchema = yup.object().shape({
		currentPassword: validation.YUP_VALIDATION.CURRENT_PASSWORD,
		newPassword: validation.YUP_VALIDATION.NEW_PASSWORD,
		confirmPassword: validation.YUP_VALIDATION.RE_ENTER_NEW_PASSWORD
	});
	const submitFormHandler = async () => {
		const payload = {
			bar_id: authData?.selectedVenue?.id,
			old_password: values?.currentPassword,
			new_password: values?.newPassword
		};
		setLoading(true);
		try {
			const res = await Api(
				'POST',
				VenueApiRoutes.changeVenuePassword,
				payload
			);
			setLoading(false);
			if (res?.data?.status) {
				toast.success(res?.data?.message);
				resetForm();
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	const {
		handleSubmit,
		values,
		handleChange,
		handleBlur,
		touched,
		errors,
		resetForm
	} = useFormik({
		initialValues: {
			currentPassword: '',
			newPassword: '',
			confirmPassword: ''
		},
		validationSchema,
		onSubmit: submitFormHandler
	});
	return (
		<>
			<p className="fs-12 regular-text themeText">
				Please enter your new password below for{' '}
				{authData?.selectedVenue?.restaurantName} MyTab Venue app.
				Remember use a mix of letters, numbers and symbols to increase
				the strength of your password and do not share it with anyone.
				Your new password must be different from previous passwords.
			</p>
			<form onSubmit={handleSubmit} className="pa-t-24">
				<Row>
					<Col lg={6} className="pa-b-20">
						<FormInput
							id="password"
							type="password"
							name="currentPassword"
							label="Current Password"
							placeholder="Enter Current Password"
							value={values?.currentPassword}
							onChange={handleChange}
							onBlur={handleBlur}
							error={
								touched?.currentPassword &&
								!!errors?.currentPassword
							}
							errorMsg={errors?.currentPassword}
						/>
					</Col>
				</Row>
				<Row>
					<Col lg={6} className="pa-b-20">
						<FormInput
							id="password"
							type="password"
							name="newPassword"
							label="New Password"
							placeholder="Enter New Password"
							value={values?.newPassword}
							onChange={handleChange}
							onBlur={handleBlur}
							error={
								touched?.newPassword && !!errors?.newPassword
							}
							errorMsg={errors?.newPassword}
						/>
					</Col>
				</Row>
				<Row>
					<Col lg={6} className="pa-b-20">
						<FormInput
							id="password"
							type="password"
							name="confirmPassword"
							label="Re-enter New Password"
							placeholder="Re-enter New Password"
							value={values?.confirmPassword}
							onChange={handleChange}
							onBlur={handleBlur}
							error={
								touched?.confirmPassword &&
								!!errors?.confirmPassword
							}
							errorMsg={errors?.confirmPassword}
						/>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
						<CustomButton
							type="submit"
							className="themeButton ma-b-8"
							loading={loading}
						>
							Change Password
						</CustomButton>
					</Col>
				</Row>
			</form>
		</>
	);
};

export default VenueChangePassword;
