import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ArrowsIn, ClockIcon } from '../../Icons';
import CardWrapper from './index.style';
import OrderItemGroup from './OrderItemGroup';

const OrderCard = ({ data, getVenueOrderList, viewOnly, index, orders }) => {
	const authData = useSelector((state) => ({ ...state.auth }));
	const [variant, setVariant] = useState('green');
	const [isCollapse, setIsCollapse] = useState(false);
	const [collapse, setCollapse] = useState([]);
	const handleCollapse = () => {
		setCollapse((prev) => prev?.map((collapseItem, i) => !collapseItem));
		setIsCollapse((prev) => !prev);
	};
	useEffect(() => {
		if (data?.order_items_group?.length > 0) {
			setCollapse(data?.order_items_group?.map((item) => false));
		}
		if (
			Number(authData?.selectedVenue?.posStatus) &&
			!(
				data?.posOrderStatus == 'Pending' ||
				data?.posOrderStatus == 'Accepted' ||
				data?.posOrderStatus == 'Completed'
			)
		) {
			setVariant('red');
		} else if (
			data?.docketPrintingStatus !== null &&
			authData?.selectedVenue?.docketStatus === '1'
		) {
			if (data?.docketPrintingStatus == '0') {
				setVariant('red');
			} else {
				setVariant('green');
			}
		} else if (
			data?.order_items_group?.find((item) => {
				return (
					new Date().getTime() > new Date(item?.wait_time).getTime()
				);
			})
		) {
			setVariant('yellow');
		} else {
			setVariant('green');
		}
	}, [data, data?.order_items_group]);
	return (
		<CardWrapper
			variant={variant}
			className={`cursor-pointer ${
				index == orders?.length - 1 ? 'ma-b-1' : ''
			}`}
			onClick={() => handleCollapse()}
		>
			<div className={`orderCard`}>
				<div className={`cardHeader`}>
					<p className="fs-14 semi-bold-text text-white orderNo">
						{data?.orderServiceType === 'PICKUP'
							? data?.pickupCode
							: `Table ${data?.orderTableNumbers[0]?.tableCode}`}
					</p>
					{isCollapse && (
						<p className="fs-14 semi-bold-text text-white">
							Order #{data?.orderNo}
						</p>
					)}
					<div className="d-flex align-items-center gap-1 orderWaitTime">
						<ClockIcon height={12} width={12} />
						<p className="fs-12 medium-text text-white">
							{new Date(
								data?.order_items_group[0]?.wait_time
							).toLocaleString('en-US', {
								hour: 'numeric',
								minute: 'numeric',
								hour12: true
							})}
						</p>
						{isCollapse && (
							<span className="pl-16">
								<ArrowsIn width={'22'} height={'22'} />
							</span>
						)}
					</div>
				</div>
				<div className="cardBody">
					<div className="d-flex flex-column">
						{data?.order_items_group?.length > 0 &&
							data?.order_items_group?.map((item, index) => {
								return (
									<OrderItemGroup
										key={index}
										item={item}
										index={index}
										handleCollapse={handleCollapse}
										collapse={collapse}
										data={data}
										authData={authData}
										getVenueOrderList={getVenueOrderList}
										viewOnly={viewOnly}
									/>
								);
							})}
					</div>
				</div>
			</div>
		</CardWrapper>
	);
};

export default OrderCard;
