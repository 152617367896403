import config, { getCurrentTheme } from '../../Settings/themeConfig';
import actions from './actions';

const initState = {
	isActivated: false,

	changeThemes: getCurrentTheme(
		'changeThemes',
		config.changeThemes.defaultTheme || 'themedefault'
	),

	topbarTheme: getCurrentTheme(
		'topbarTheme',
		config.topbarTheme.defaultTheme || 'mytabDefault'
	),

	sidebarTheme: getCurrentTheme(
		'sidebarTheme',
		config.sidebarTheme.defaultTheme || 'mytabDefault'
	),

	layoutTheme: getCurrentTheme(
		'layoutTheme',
		config.layoutTheme.defaultTheme || 'mytabDefault'
	),

	footerTheme: getCurrentTheme(
		'footerTheme',
		config.footerTheme.defaultTheme || 'mytabDefault'
	)
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case actions.SWITCH_ACTIVATION:
			return {
				...state,
				isActivated: !state.isActivated
			};
		case actions.CHANGE_THEME:
			return {
				...state,
				[action.attribute]: action.theme
			};
		default:
			return state;
	}
};

export default reducer;
