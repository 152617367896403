import { useEffect, useState } from 'react';
import * as yup from 'yup';
import OtpInput from 'react-otp-input';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

import * as validation from '../../../Helper/YupValidation';
import CustomModal from '../../Common/Modal/CustomModal';
import ModalWrapper from './verifyOtp.style';
import passwordImage from '../../../Assets/images/password-icon.png';
import CustomButton from '../../Common/CustomButton';
import authActions from '../../../Redux/auth/actions';
import { VenueApiRoutes } from '../../../Utils/routes';
import Api from '../../../Helper/Api';
import { auth } from '../../../firebase.config';
import { handleFirebaseError } from './utils';

const VerifyOtpModal = ({
	isOpen,
	handleModal,
	showTwoField,
	setShowTwoField,
	confirmationResult,
	formValues,
	setCurrentMobileNoVerified
}) => {
	const state = useSelector((state) => ({ ...state }));
	const allThemeData = state.themeChanger;
	const authData = state.auth;
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [resendOtpLoading, setResendOtpLoading] = useState(false);
	const [confirmRes, setConfirmRes] = useState(confirmationResult);
	const [resendCaptchVerifier, setResendCaptchVerifier] = useState(null);
	const [captchToken, setCaptchToken] = useState('');
	const [firstTimeResend, setFirstTimeResend] = useState(true);

	const validationSchema = yup.object().shape({
		otp: validation.YUP_VALIDATION.OTP
	});
	const handleOTPChange = (otp) => {
		verifyOtpFormik.setFieldValue('otp', otp);
	};
	const handleResendOTP = () => {
		let formatedMobileNo = '';
		if (showTwoField) {
			formatedMobileNo =
				'+' + formValues?.newCountryCode + formValues?.newMobileNumber;
		} else {
			formatedMobileNo =
				'+' +
				formValues?.currentCountryCode +
				formValues?.currentMobileNumber;
		}
		if (firstTimeResend) {
			const recaptchaVerifier = new RecaptchaVerifier(
				auth,
				'recaptcha-container-resend',
				{
					size: 'invisible',
					callback: (res) => {
						setResendOtpLoading(true);
						signInWithPhoneNumber(
							auth,
							formatedMobileNo,
							resendCaptchVerifier
						)
							.then((confirmationRes) => {
								setConfirmRes(confirmationRes);
								setResendOtpLoading(false);
								toast.success('OTP sent successfully.');
							})
							.catch((error) => {
								setResendOtpLoading(false);
								handleFirebaseError(error);
							});
					},
					'expired-callback': () => {}
				}
			);
			setResendCaptchVerifier(recaptchaVerifier);
			recaptchaVerifier.verify().then(setCaptchToken);
			setFirstTimeResend(false);
		} else {
			setResendOtpLoading(true);
			signInWithPhoneNumber(auth, formatedMobileNo, resendCaptchVerifier)
				.then((confirmationRes) => {
					setConfirmRes(confirmationRes);
					setResendOtpLoading(false);
					toast.success('OTP sent successfully.');
				})
				.catch((error) => {
					setResendOtpLoading(false);
					toast.error('Something went wrong.');
				});
		}
	};
	const updateVenueMobileNo = async () => {
		return await Api('POST', VenueApiRoutes.updateVenueMobileNo, {
			bar_id: authData?.selectedVenue?.id,
			old_country_code: formValues?.currentCountryCode,
			old_mobile: formValues?.currentMobileNumber,
			country_code: formValues?.newCountryCode,
			mobile: formValues?.newMobileNumber
		});
	};
	const handelOnsubmit = async () => {
		if (showTwoField) {
			setLoading(true);
			confirmRes
				.confirm(verifyOtpFormik?.values?.otp)
				.then((result) => {
					updateVenueMobileNo()
						.then((res) => {
							if (res?.data?.status) {
								// toast.success('OTP verified successfully.');
								dispatch(
									authActions.update_selected_venue({
										countryCode: formValues?.newCountryCode,
										mobile: formValues?.newMobileNumber
									})
								);
								setLoading(false);
								setShowTwoField(false);
								handleModal();
								toast.success(res?.data?.message);
							} else {
								setLoading(false);
								verifyOtpFormik.resetForm();
								toast.error(res?.data?.message);
							}
						})
						.catch((error) => {
							setLoading(false);
							verifyOtpFormik.resetForm();
							if (error?.response?.data?.message) {
								toast.error(error?.response?.data?.message);
							}
						});
				})
				.catch((error) => {
					setLoading(false);
					verifyOtpFormik.resetForm();
					toast.error('Wrong otp entered.');
				});
		} else {
			setLoading(true);
			confirmRes
				.confirm(verifyOtpFormik?.values?.otp)
				.then((result) => {
					setLoading(false);
					setShowTwoField(true);
					setCurrentMobileNoVerified(true);
					handleModal();
					toast.success('OTP verified successfully.');
				})
				.catch((error) => {
					setLoading(false);
					verifyOtpFormik.resetForm();
					toast.error('Wrong otp entered.');
				});
		}
	};
	const verifyOtpFormik = useFormik({
		initialValues: {
			otp: ''
		},
		validationSchema,
		validateOnChange: false,
		validateOnBlur: false,
		onSubmit: handelOnsubmit
	});
	return (
		<CustomModal
			isOpen={isOpen}
			size="md"
			handleModal={handleModal}
			modalClassName="verifyOtp"
		>
			<ModalWrapper {...allThemeData}>
				<div className="pa-b-30 d-flex justify-content-center">
					<img
						src={passwordImage}
						alt="password-icon"
						className="phoneImage"
					/>
				</div>
				<p className="fs-24 bold-text text-center pa-b-12">
					OTP Verification
				</p>
				<p className="fs-12 medium-text modalSubTitle text-center pa-b-12">
					For security purposes, please enter 6 digit OTP verification
					code sent to{' '}
					{showTwoField
						? `+${formValues?.newCountryCode} ${formValues?.newMobileNumber}`
						: `+${formValues?.currentCountryCode} ${formValues?.currentMobileNumber}`}{' '}
					to verify your new account mobile number.
				</p>
				<p className="fs-12 regular-text modalSubTitle text-center pa-b-30">
					If you require assistance please contact us at
					venue@mytabinfo.com, thank you.
				</p>
				<form
					className="otpForm text-center"
					onSubmit={verifyOtpFormik.handleSubmit}
				>
					<OtpInput
						value={verifyOtpFormik?.values?.otp}
						onChange={handleOTPChange}
						numInputs={6}
						containerStyle={{
							justifyContent: 'center'
						}}
						shouldAutoFocus={true}
					/>
					{verifyOtpFormik?.errors?.otp && (
						<p className="fs-10 semi-bold-text headingTextColor pa-t-6">
							{verifyOtpFormik?.errors?.otp}
						</p>
					)}
					<div className="ptb-24">
						<CustomButton
							type="submit"
							className="themeButtonFullWidth"
							loading={loading}
						>
							Verify
						</CustomButton>
					</div>
					<p className="fs-10 medium-text themeText text-center d-flex justify-content-center">
						Didn't receive a code? Click&nbsp;
						<button
							id="recaptcha-container-resend"
							type="button"
							className={`p-0 m-0 bg-transparent border-0 outline-none headingTextColor fs-10 bold-text ${
								resendOtpLoading ? 'opacity-50' : ''
							}`}
							onClick={handleResendOTP}
							disabled={resendOtpLoading}
						>
							here
						</button>
						&nbsp;to resend.
					</p>
				</form>
			</ModalWrapper>
		</CustomModal>
	);
};

export default VerifyOtpModal;
