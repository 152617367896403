export const DownArrowIcon = ({ stroke, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 12 7" fill="none">
			<path
				d="M11 1.00049L6 5.99929L1 1.00049"
				stroke={stroke ?? 'black'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
