export const PlusIcon = ({ stroke, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 24 24" fill="none">
			<path
				d="M3.75 12H20.25"
				stroke={stroke ?? '#F94D73'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12 3.75V20.25"
				stroke={stroke ?? '#F94D73'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
