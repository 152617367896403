export const formatTableData = (data) => {
	let tableData = [];
	if (data && data?.length > 0) {
		tableData = data?.map((item) => {
			return {
				id: item?.id,
				name: item?.name,
				taxAmount: item?.percentage,
				status: item?.status,
				operationalHours: item?.operating_bar_taxes
			};
		});
	}
	return tableData;
};
