import React, { useEffect, useState } from 'react';
import { CardImg, CardImgOverlay, CardTitle } from 'reactstrap';
import { useSelector } from 'react-redux';
import CustomPopover2 from '../../Common/Popover/CustomPopover2';
import { ThreeDotIcon } from '../../Icons';
import { StyledCard } from './connectVenueCard.style';
import defaultVenueCardImg from '../../../Assets/images/default-venue-card-img.png';

const ConnectVenueCard = ({
	title,
	image,
	id,
	barId,
	setIsOpenDeleteModal,
	setDeletedVenueId
}) => {
	const authData = useSelector((state) => ({
		...state?.auth
	}));
	const [isSelected, setIsSelected] = useState(false);
	useEffect(() => {
		if (barId == authData?.selectedVenue?.id) {
			setIsSelected(true);
		}
	}, [barId, authData?.selectedVenue?.id]);
	return (
		<>
			<StyledCard inverse selected={isSelected}>
				<CardImg
					alt="Card image cap"
					src={image ?? defaultVenueCardImg}
					style={{
						width: '100%'
					}}
				/>
				<CardImgOverlay className="d-flex flex-column justify-content-between venueCardImgOverlay">
					<div className="d-flex justify-content-end dotIconWrapper">
						<CustomPopover2
							button={
								<ThreeDotIcon
									height="18px"
									width="18px"
									fill="#fff"
									className="cursor-pointer"
								/>
							}
							placement="bottom-end"
						>
							<div className="pa-12">
								<div
									className="themeText fs-12 medium-text cursor-pointer"
									onClick={() => {
										setDeletedVenueId(barId);
										setIsOpenDeleteModal(true);
									}}
								>
									Delete
								</div>
							</div>
						</CustomPopover2>
					</div>
					<CardTitle tag="h5" className="ma-b-0 fs-18 medium-text">
						{title}
					</CardTitle>
				</CardImgOverlay>
			</StyledCard>
		</>
	);
};

export default ConnectVenueCard;
