import PageStructure from '../../../Components/Common/PageStructure';
import VenueProfileForm from '../../../Components/Settings/VenueProfileForm';

export const VenueProfile = () => {
	return (
		<PageStructure title="Venue Profile">
			<VenueProfileForm />
		</PageStructure>
	);
};
