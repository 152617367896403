import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CardWrapper from './index.style';
import OrderItemGroup from './OrderItemGroup';
import PreparedCardPopover from '../PreparedCardPopover';
import RefundModal from '../RefundModal';
import IntoxicatedModal from '../IntoxicatedModal';
import NotPickedUpModal from '../NotPickedUpModal';
import Api from '../../../Helper/Api';
import { VenueApiRoutes } from '../../../Utils/routes';

const PrepareCard = ({ data, getVenueOrderList, viewOnly, orders, index }) => {
	const authData = useSelector((state) => ({ ...state.auth }));
	const [variant, setVariant] = useState('green');
	const [orderPopover, setOrderPopover] = useState(false);
	const [refundModal, setRefundModal] = useState(false);
	const [refundModalData, setRefundModalData] = useState(null);
	const [intoxicatedModal, setIntoxicatedModal] = useState(false);
	const [intoxicatedOrderLoading, setIntoxicatedOrderLoading] =
		useState(false);
	const [notPickedUpModal, setNotPickedUpModal] = useState(false);
	const [notPickedUpModalData, setNotPickedUpModalData] = useState(null);
	const [notPickedUpOrderLoading, setNotPickedUpOrderLoading] =
		useState(false);
	const orderPopoverToggle = () => {
		setOrderPopover(!orderPopover);
	};
	const handleRefundModal = () => {
		setRefundModal((prev) => !prev);
	};
	const handleIntoxicatedModal = () => {
		setIntoxicatedModal((prev) => !prev);
	};
	const handleNotPickedUpModal = () => {
		setNotPickedUpModal((prev) => !prev);
	};
	const handleNotPickedUpOrder = async (item) => {
		let itemsIds = item?.items?.map((value) => value?.id);
		let id = itemsIds?.join(',');
		try {
			setNotPickedUpOrderLoading(true);
			const res = await Api(
				'POST',
				VenueApiRoutes.updateOrderItemStatus,
				{
					bar_id: '' + authData?.selectedVenue?.id,
					id: id,
					orderStatus: 'NotPickedup'
				}
			);
			if (res?.data?.status) {
				toast.success(res?.data?.message);
				setNotPickedUpModal(false);
				await getVenueOrderList();
			} else {
				toast.error(res?.data?.message);
			}
			setNotPickedUpOrderLoading(false);
		} catch (err) {
			setNotPickedUpOrderLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	const handleIntoxicatedOrder = async () => {
		try {
			setIntoxicatedOrderLoading(true);
			const res = await Api('POST', VenueApiRoutes.orderIntoxicated, {
				bar_id: '' + authData?.selectedVenue?.id,
				id: '' + data?.id
			});
			if (res?.data?.status) {
				toast.success(res?.data?.message);
				setIntoxicatedModal(false);
				await getVenueOrderList();
			} else {
				toast.error(res?.data?.message);
			}
			setIntoxicatedOrderLoading(false);
		} catch (err) {
			setIntoxicatedOrderLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	useEffect(() => {
		// Handle card color variant
		if (
			Number(authData?.selectedVenue?.posStatus) &&
			!(
				data?.posOrderStatus == 'Pending' ||
				data?.posOrderStatus == 'Accepted' ||
				data?.posOrderStatus == 'Completed'
			)
		) {
			setVariant('red');
		} else if (
			data?.docketPrintingStatus !== null &&
			authData?.selectedVenue?.docketStatus === '1'
		) {
			if (data?.docketPrintingStatus == '0') {
				setVariant('red');
			} else {
				setVariant('green');
			}
		} else if (
			data?.order_items_group?.find((item) => {
				return (
					new Date().getTime() > new Date(item?.wait_time).getTime()
				);
			})
		) {
			setVariant('yellow');
		} else {
			setVariant('green');
		}
	}, [data, data?.order_items_group]);
	return (
		<CardWrapper
			variant={variant}
			className={`${index == orders?.length - 1 ? 'ma-b-1' : ''}`}
		>
			<div className={`orderCard`}>
				<div className={`cardHeader`}>
					<p className="fs-14 semi-bold-text text-white orderNo">
						{data?.orderServiceType === 'PICKUP'
							? data?.pickupCode
							: `Table ${data?.orderTableNumbers[0]?.tableCode}`}
					</p>
					<div className="d-flex align-items-center gap-1 orderWaitTime">
						<div
							className={`${viewOnly ? 'invisible' : 'visible'}`}
						>
							<PreparedCardPopover
								iconColor="#FFFFFF"
								setRefundModal={setRefundModal}
								setIntoxicatedModal={setIntoxicatedModal}
								setNotPickedUpModal={setNotPickedUpModal}
								setNotPickedUpModalData={
									setNotPickedUpModalData
								}
								setRefundModalData={setRefundModalData}
								item={data?.order_items_group[0]}
								data={data}
							/>
						</div>
					</div>
				</div>
				<div className="cardBody">
					<div className="d-flex flex-column">
						{data?.order_items_group?.length > 0 &&
							data?.order_items_group?.map((item, index) => {
								return (
									<OrderItemGroup
										key={index}
										item={item}
										index={index}
										data={data}
										authData={authData}
										getVenueOrderList={getVenueOrderList}
										setRefundModal={setRefundModal}
										setIntoxicatedModal={
											setIntoxicatedModal
										}
										setNotPickedUpModal={
											setNotPickedUpModal
										}
										setNotPickedUpModalData={
											setNotPickedUpModalData
										}
										setRefundModalData={setRefundModalData}
										viewOnly={viewOnly}
										a
									/>
								);
							})}
					</div>
				</div>
			</div>
			{refundModal && refundModalData && (
				<RefundModal
					isOpen={refundModal}
					handleModal={handleRefundModal}
					refundModalData={refundModalData}
					getVenueOrderList={getVenueOrderList}
				/>
			)}
			{intoxicatedModal && (
				<IntoxicatedModal
					isOpen={intoxicatedModal}
					handleModal={handleIntoxicatedModal}
					handleIntoxicatedOrder={handleIntoxicatedOrder}
					intoxicatedOrderLoading={intoxicatedOrderLoading}
				/>
			)}
			{notPickedUpModal && notPickedUpModalData && (
				<NotPickedUpModal
					isOpen={notPickedUpModal}
					handleModal={handleNotPickedUpModal}
					handleNotPickedUpOrder={handleNotPickedUpOrder}
					notPickedUpModalData={notPickedUpModalData}
					notPickedUpOrderLoading={notPickedUpOrderLoading}
				/>
			)}
		</CardWrapper>
	);
};

export default PrepareCard;
