import { FormGroup, Input, Label } from 'reactstrap';

const FormSwitch = ({ id, label, error, errorMsg, className, ...rest }) => {
	return (
		<FormGroup switch className="p-0">
			{label && (
				<Label
					check
					for={id}
					className="fs-14 medium-text themeText label-color pr-5"
				>
					{label}
				</Label>
			)}
			<Input
				type="switch"
				className={`${error ? 'is-invalid' : ''} ${
					className ? className : ''
				} themeSwitch fs-16 medium-text ml-0 float-none cursor-pointer`}
				{...rest}
			/>
			{error && (
				<p className="fs-12 semi-bold-text fw-bold">
					<span className="headingTextColor">{errorMsg}</span>
				</p>
			)}
		</FormGroup>
	);
};

export default FormSwitch;
