import {
	InputGroup,
	InputGroupText,
	Input,
	FormGroup,
	Label
} from 'reactstrap';

const FormInputGroup = ({
	id,
	error,
	errorMsg,
	className,
	icon,
	iconPlacement,
	label,
	helperText,
	formGroupClassName,
	iconBackgroundClass,
	...rest
}) => {
	return (
		<FormGroup
			className={`mb-0 formGroupIcon ${
				formGroupClassName ? formGroupClassName : ''
			}`}
		>
			{label && (
				<Label
					for={id}
					className="fs-12 medium-text themeText label-color ma-b-6"
				>
					{label}
				</Label>
			)}
			<InputGroup>
				{iconPlacement === 'start' && (
					<InputGroupText className="bg-white inputGroup min-height-40 no-border border-radius-0">
						{icon}
					</InputGroupText>
				)}
				<Input
					{...rest}
					className={`${error ? 'is-invalid' : ''} ${
						className ? className : ''
					} inputBox inputBoxGroup min-height-40 no-border fs-12 medium-text  ${
						iconPlacement === 'start' ? 'pl-0 pr-12' : 'pr-0'
					}`}
					style={{ zIndex: '0' }}
				/>
				{iconPlacement === 'end' && (
					<InputGroupText
						className={`bg-white inputGroup min-height-40 no-border border-radius-0 ${
							iconBackgroundClass ? '' : ''
						}`}
					>
						{icon}
					</InputGroupText>
				)}
			</InputGroup>
			{helperText && (
				<p className="fs-10 medium-text helperText pt-1">
					{helperText}
				</p>
			)}
			{error && (
				<p className="fs-10 semi-bold-text headingTextColor pa-t-6">
					{errorMsg}
				</p>
			)}
		</FormGroup>
	);
};

export default FormInputGroup;
