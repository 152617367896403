import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import * as validation from '../../Helper/YupValidation';
import Api from '../../Helper/Api';
import logo from '../../Assets/images/Logo.svg';
import FormInput from '../Form/FormInput';
import VerifyOtpModal from './VerifyOtpModal';
import authActions from '../../Redux/auth/actions';
import { CommonRoutes } from '../../Utils/routes';
import CustomButton from '../Common/CustomButton';
import NewFormInput from '../Form/NewFormInput';
import NewFormPasswordInput from '../Form/NewFormPasswordInput';
import FormWrapper from './loginForm.style';

const LoginForm = ({ type }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [loginRole, setLoginRole] = useState('venue');
	const [verifyOtpModal, setVerifyOtpModal] = useState(false);
	const [modalData, setModalData] = useState({});
	const [loading, setLoading] = useState(false);

	const handleVerifyOtpModal = () => {
		setVerifyOtpModal((prev) => !prev);
	};

	const validationSchema = yup.object().shape({
		email: validation.YUP_VALIDATION.EMAIL,
		password: validation.YUP_VALIDATION.PASSWORD
	});

	const handelOnsubmit = async () => {
		const payload = {
			email: loginFormik?.values?.email,
			password: loginFormik?.values?.password
		};

		let api =
			loginRole === 'venue' ? '/venue/auth/login' : '/cms/admin/login';
		if (loginRole !== 'venue') {
			toast.error('You are not allowed to login');
		} else {
			try {
				setLoading(true);
				const res = await Api('POST', api, payload);
				setLoading(false);
				if (res?.data?.status) {
					// loginFormik.resetForm();
					setVerifyOtpModal(true);
					setModalData({
						admin_id: res?.data?.data?.id,
						email: res?.data?.data?.email
					});
				} else {
					toast.error(res?.data?.message);
				}
			} catch (err) {
				setLoading(false);
				if (err?.message) {
					toast.error(err?.message);
				}
			}
		}
	};

	const loginFormik = useFormik({
		initialValues: {
			email: '',
			password: ''
		},
		validationSchema,
		onSubmit: handelOnsubmit
	});

	return (
		<FormWrapper>
			<div>
				<p className="headingStyle">Welcome back, let's login.</p>
				<div className="containCover">
					<form
						onSubmit={loginFormik.handleSubmit}
						className="formContain"
					>
						<div className="inputContainWrraper">
							<NewFormInput
								autoComplete="off"
								tootlTipMessage="Enter the email used to create your Management Portal"
								type="email"
								name="email"
								label="Email"
								placeholder="Email"
								value={loginFormik?.values?.email}
								onChange={loginFormik.handleChange}
								onBlur={loginFormik.handleBlur}
								error={
									loginFormik?.touched?.email &&
									!!loginFormik?.errors?.email
								}
								errorMsg={loginFormik?.errors?.email}
								showRequired
							/>
						</div>

						<div className="inputContainWrraper">
							<NewFormPasswordInput
								autoComplete="off"
								name="password"
								label="Password"
								placeholder="Password"
								value={loginFormik?.values?.password}
								onChange={loginFormik.handleChange}
								onBlur={loginFormik.handleBlur}
								error={
									loginFormik?.touched?.password &&
									!!loginFormik?.errors?.password
								}
								errorMsg={loginFormik?.errors?.password}
							/>
							<Link
								to={`${
									type === 'venue'
										? CommonRoutes.forgotPassword
										: '/admin/forgot-password'
								}`}
								className="fs-11 medium-text forgetPasswordLink text-decoration-none"
							>
								Forgot Password?
							</Link>
						</div>
						<div>
							<CustomButton
								type="submit"
								className="newThemeButtonFullWidth pa-t-10"
								loading={loading}
							>
								Let's Go!
							</CustomButton>
						</div>
					</form>
					<p className="helperLink">
						Need to create your management portal? Click
						<span
							className="helperLinkSpan"
							onClick={() => navigate('/')}
						>
							{' '}
							here{' '}
						</span>
						to go back.
					</p>
				</div>
			</div>

			<p className="policyText">
				By continuing you agree to our
				<span
					className="policyTextTermsConditions"
					onClick={() => {
						window.open(
							'https://mytabinfo.com/policies/terms-of-service',
							'_blank'
						);
					}}
				>
					{' '}
					Terms & Conditions{' '}
				</span>
				and
				<span
					className="policyTextPrivacy"
					onClick={() => {
						window.open(
							'https://mytabinfo.com/policies/privacy-policy',
							'_blank'
						);
					}}
				>
					{' '}
					Privacy Policy
				</span>
			</p>

			<VerifyOtpModal
				closeHandler={() => setVerifyOtpModal(false)}
				modalFor={'login'}
				handleModal={handleVerifyOtpModal}
				isOpen={verifyOtpModal}
				// isOpen={true}
				type={loginRole}
				modalData={modalData}
			/>
		</FormWrapper>
	);
};

export default LoginForm;
