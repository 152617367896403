import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { CommonRoutes, VenueProtectedRoutes } from '../Utils/routes';

const VenueProtectedLayout = ({ forPublic }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const preVenueProcess = useSelector(
		(state) => state?.auth?.preVenueProcess
	);

	const preAccountProcess = useSelector(
		(state) => state?.auth?.preAccountProcess
	);

	const accountProcesses = useSelector(
		(state) => state?.auth?.accountProcesses
	);

	useEffect(() => {
		/**
		 * where forPublic is false
		 * preVenueProcess null or false
		 * at not login process
		 * try to access venue route
		 */
		if ((!preVenueProcess || preVenueProcess === null) && !forPublic) {
			navigate(CommonRoutes.landingPage, { replace: true });
			return;
		}
		/**
		 * where forPublic is true
		 * preVenueProcess  is true
		 * call at not login
		 * after login not access public
		 */
		if (forPublic === true && preVenueProcess === true) {
			navigate(VenueProtectedRoutes.connectVenue, { replace: true });
			return;
		}
		/**
		 * where forPublic is false
		 * preVenueProcess  is true
		 * accountProcesses is true
		 * call at
		 * after login and create account done
		 */
		if (
			!forPublic &&
			accountProcesses &&
			preVenueProcess &&
			preAccountProcess
		) {
			accountProcesses !== location.pathname &&
				navigate(accountProcesses.toString(), {
					replace: true
				});
			return;
		}
	}, [forPublic, navigate]);

	return <></>;
};

export default VenueProtectedLayout;
