import Scrollbars from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import NewOrders from '../../Components/VenueDashboard/NewOrders';
import PreparedOrders from '../../Components/VenueDashboard/PreparedOrders';
import PageWrapper from './index.style';
import Switch from '../../Components/Common/Switch';
import { VenueApiRoutes } from '../../Utils/routes';
import EmptyData from '../../Components/Common/EmptyData';
import OrdersSkeleton from '../../Components/VenueDashboard/OrdersSkeleton';
import authActions from '../../Redux/auth/actions';
import VerifyPasswordModal from '../../Components/VenueDashboard/VerifyPasswordModal';
import { useCachedData } from '../../Hooks/useCachedData';

export const VenueDashboard = () => {
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	const authData = useSelector((state) => ({ ...state.auth }));
	const dispatch = useDispatch();
	const { data, isLoading, isError, error, refetch } = useCachedData(
		'POST',
		VenueApiRoutes.venueOrderList,
		{
			bar_id: '' + authData?.selectedVenue?.id
		}
	);
	const [viewOnly, setViewOnly] = useState(true);
	const [getOrdersLoading, setGetOrdersLoading] = useState(false);
	const [ordersData, setOrdersData] = useState(null);
	const [verifyPasscodeModal, setVerifyPasscodeModal] = useState(false);
	const handleViewOnlyChange = () => {
		if (viewOnly) {
			setVerifyPasscodeModal(true);
		} else {
			dispatch(
				authActions?.set_dashboard_status({
					barId: authData?.selectedVenue?.id,
					dashboardStatus: 'VIEW'
				})
			);
		}
	};
	const handleVerifyPasscodeModal = () => {
		setVerifyPasscodeModal((prev) => !prev);
	};

	useEffect(() => {
		const interval = setInterval(async () => {
			if (refetch) await refetch();
		}, 8000);
		return () => clearInterval(interval);
	}, []);
	useEffect(() => {
		if (authData?.selectedVenue?.dashboardStatus === 'EDIT') {
			setViewOnly(false);
		} else {
			setViewOnly(true);
		}
	}, [authData?.selectedVenue?.dashboardStatus]);
	useEffect(() => {
		setOrdersData(data?.data);
	}, [data, data?.data]);
	useEffect(() => {
		setGetOrdersLoading(isLoading);
		if (isError) {
			toast.error(error);
		}
	}, [isLoading, isError, error]);
	useEffect(() => {
		if (refetch) refetch();
	}, [authData?.selectedVenue?.id]);

	return (
		<PageWrapper {...allThemeData}>
			<div className="page">
				<div className="page-header">
					<div className="d-flex justify-content-end">
						<div className="d-flex align-items-center gap-2">
							<span
								className={`fs-12 regular-text ${
									viewOnly ? 'headingTextColor' : ''
								}`}
							>
								View Only
							</span>
							<Switch
								checked={!viewOnly}
								onChange={handleViewOnlyChange}
							/>
							<span
								className={`fs-12 regular-text ${
									viewOnly ? '' : 'headingTextColor'
								}`}
							>
								Editable
							</span>
						</div>
					</div>
				</div>
				<div className="page-body">
					<Scrollbars
						autoHide
						className="customScrollbar"
						renderTrackVertical={({ style, ...props }) => (
							<div
								{...props}
								style={{
									...style,
									zIndex: 5,
									position: 'absolute',
									width: '2px',
									right: '2px',
									bottom: '2px',
									top: '2px',
									borderRadius: '3px'
								}}
							/>
						)}
					>
						<div className="cardsContainer">
							{/* New Orders Section */}
							<div className="flex-1 d-flex flex-column newOrdersWrapper">
								<h1 className="fs-15 medium-text">
									{getOrdersLoading ? (
										<Skeleton
											height="16px"
											width="180px"
											borderRadius={4}
										/>
									) : (
										`Orders - ${
											ordersData?.newOrder?.length ?? '0'
										}`
									)}
								</h1>
								<div className="flex-1 d-flex pa-t-24">
									<Scrollbars
										autoHide
										className="customScrollbar"
										renderTrackVertical={({
											style,
											...props
										}) => (
											<div
												{...props}
												style={{
													...style,
													zIndex: 5,
													position: 'absolute',
													width: '2px',
													right: '2px',
													bottom: '2px',
													top: '2px',
													borderRadius: '3px'
												}}
											/>
										)}
									>
										<div className="pr-8 h-100">
											<>
												{getOrdersLoading ? (
													<OrdersSkeleton />
												) : (
													<>
														{ordersData?.newOrder
															?.length > 0 ? (
															<NewOrders
																data={
																	ordersData?.newOrder
																}
																getVenueOrderList={
																	refetch
																}
																viewOnly={
																	viewOnly
																}
															/>
														) : (
															<div className="h-100 d-flex justify-content-center align-items-center">
																<EmptyData content="No new orders" />
															</div>
														)}
													</>
												)}
											</>
										</div>
									</Scrollbars>
								</div>
							</div>
							<div className="customDivider" />
							{/* Prepared Orders Section */}
							<div className="flex-1 d-flex flex-column preparedOrdersWrapper">
								<h1 className="fs-15 medium-text">
									{getOrdersLoading ? (
										<Skeleton
											height="16px"
											width="180px"
											borderRadius={4}
										/>
									) : (
										`Prepared - ${
											ordersData?.pickupOrder?.length ??
											'0'
										}`
									)}
								</h1>
								<div className="flex-1 d-flex pa-t-24">
									<Scrollbars
										autoHide
										className="customScrollbar"
										renderTrackVertical={({
											style,
											...props
										}) => (
											<div
												{...props}
												style={{
													...style,
													zIndex: 5,
													position: 'absolute',
													width: '2px',
													right: '2px',
													bottom: '2px',
													top: '2px',
													borderRadius: '3px'
												}}
											/>
										)}
									>
										<div className="plr-8 h-100">
											<>
												{getOrdersLoading ? (
													<OrdersSkeleton />
												) : (
													<>
														{ordersData?.pickupOrder
															?.length > 0 ? (
															<PreparedOrders
																data={
																	ordersData?.pickupOrder
																}
																getVenueOrderList={
																	refetch
																}
																viewOnly={
																	viewOnly
																}
															/>
														) : (
															<div className="h-100 d-flex justify-content-center align-items-center">
																<EmptyData content="No prepared orders" />
															</div>
														)}
													</>
												)}
											</>
										</div>
									</Scrollbars>
								</div>
							</div>
						</div>
					</Scrollbars>
				</div>
			</div>

			{verifyPasscodeModal && (
				<VerifyPasswordModal
					isOpen={verifyPasscodeModal}
					handleModal={handleVerifyPasscodeModal}
				/>
			)}
		</PageWrapper>
	);
};
