import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, TabContent, TabPane } from 'reactstrap';
import { Tab, Tabs } from 'react-tabs-scrollable';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Filter, PlusIcon } from '../../Components/Icons';
import Menuitems from '../../Components/ManageMenu/Menuitems';
import OperatingHoursModal from '../../Components/ManageMenu/OperatingHoursModal';
import { VenueApiRoutes, VenuePanelRoutes } from '../../Utils/routes';
import PageStructure from '../../Components/Common/PageStructure';
import { PageWrapper, TabsWrapper } from './index.style';
import TabsSkeleton from '../../Components/ManageMenu/Skeleton/TabsSkeleton';
import ProductItemSkeleton from '../../Components/ManageMenu/Skeleton/ProductItemSkeleton';
import { useCachedData } from '../../Hooks/useCachedData';
import Rearrange from '../../Components/Icons/Rearrange';
import RearrangeMenuModal2 from '../../Components/ManageMenu/RearrangeMenuModal2';
import FilterPopover from '../../Components/ManageMenu/FilterPopover';
import EmptyData from '../../Components/Common/EmptyData';
import uiActions from '../../Redux/ui/actions';
import Api from '../../Helper/Api';

export const ManageMenu = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const state = useSelector((state) => ({ ...state }));
	const allThemeData = state.themeChanger;
	const authData = state.auth;
	const location = useLocation();
	const [tabId, setTabId] = useState();
	const [tabMenuLoading, setTabMenuLoading] = useState(false);
	const [tabMenuData, setTabMenuData] = useState([]);
	const [categoryName, setCategoryName] = useState(
		location.state?.categoryName || ''
	);
	const [subCategoryId, setSubCategoryId] = useState(null);
	const [isPopularExist, setIsPopularExist] = useState(false);
	const [rearrangeMenuModal, setRearrangeMenuModal] = useState(false);
	const [operatingHoursModal, setOperatingHoursModal] = useState(false);
	const [filterPopover, setFilterPopover] = useState(false);
	const [posStatus, setPosStatus] = useState(false);
	const [serviceType, setServiceType] = useState('BOTH');
	const categoryNameRef = useRef(categoryName);
	const handleFilterPopover = () => {
		setFilterPopover((prev) => !prev);
	};
	// const cachedProductListData = useCachedData(
	// 	'POST',
	// 	VenueApiRoutes.getProductList,
	// 	{
	// 		// category_id: authData?.selectedVenue?.posStatus == 1 ? '-1' : '1',
	// 		bar_id: authData?.selectedVenue?.id,
	// 		serviceType: serviceType
	// 		// showPopular: 1
	// 	}
	// );

	// const { data, isLoading, isError, error, refetch, isFetching } =
	// 	cachedProductListData;

	const getProductListData = async () => {
		dispatch(uiActions?.page_loading(true));
		try {
			const res = await Api('POST', VenueApiRoutes.getProductList, {
				bar_id: authData?.selectedVenue?.id,
				serviceType: serviceType
			});
			if (res?.data?.status) {
				if (res?.data?.data[0]?.categoryName === 'Popular') {
					setIsPopularExist(true);
				} else {
					setIsPopularExist(false);
				}
				setTabMenuData(res?.data?.data);

				if (categoryNameRef?.current && res?.data?.data.length > 0) {
					const index = res?.data?.data.findIndex(
						(item) =>
							item?.categoryName === categoryNameRef?.current
					);
					if (index !== -1) {
						setTabId(index);
					} else {
						setTabId(0);
					}
				} else {
					setTabId(0);
				}

				setTabMenuLoading(false);
			} else {
				toast.error(res?.data?.message);
				setTabMenuLoading(false);
			}
			dispatch(uiActions?.page_loading(false));
		} catch (err) {
			setTabMenuLoading(false);
			dispatch(uiActions?.page_loading(false));
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	const handleRearrangeMenuModal = () => {
		setRearrangeMenuModal(!rearrangeMenuModal);
	};
	const handleOperatingHoursModal = () => {
		setOperatingHoursModal(!operatingHoursModal);
	};
	// define an onClick function to bind the value on tab click
	const onTabClick = (e, index) => {
		setCategoryName(e?.target?.textContent);
		setTabId(index);
	};

	// useEffect(() => {
	// 	if (data?.data) {
	// 		if (data?.data[0]?.categoryName === 'Popular') {
	// 			setIsPopularExist(true);
	// 		} else {
	// 			setIsPopularExist(false);
	// 		}
	// 		setTabMenuData(data?.data);
	// 	}
	// 	if (data?.status == 0) {
	// 		toast.error(data?.message);
	// 	}
	// }, [data, data?.data]);

	// useEffect(() => {
	// 	if (isLoading) {
	// 		setTabMenuLoading(isLoading);
	// 	} else {
	// 		setTabMenuLoading(false);
	// 	}
	// 	// if (isError) {
	// 	// 	toast.error(error);
	// 	// }
	// 	if (!isLoading) {
	// 		if (isFetching) {
	// 			dispatch(uiActions.page_loading(true));
	// 		} else {
	// 			dispatch(uiActions.page_loading(false));
	// 		}
	// 	}
	// }, [isLoading, isError, error, isFetching]);

	useEffect(() => {
		categoryNameRef.current = categoryName;
	}, [categoryName]);

	useEffect(() => {
		if (authData?.selectedVenue?.posStatus === '1') {
			setPosStatus(true);
		} else {
			setPosStatus(false);
		}
	}, [authData?.selectedVenue?.posStatus]);

	// useEffect(() => {
	// 	if (refetch) refetch();
	// 	setTabMenuLoading(false);
	// }, [authData?.selectedVenue?.id, serviceType]);

	useEffect(() => {
		if (authData?.selectedVenue?.id) {
			setTabMenuLoading(true);
			(async () => {
				await getProductListData();
			})();
		}
	}, [authData?.selectedVenue?.id, serviceType]);
	return (
		<>
			<PageStructure
				title="Manage Menu"
				{...(posStatus
					? {}
					: {
							buttonName: 'Add Item',
							buttonIcon: (
								<PlusIcon
									height="18"
									width="18"
									className="mr-6"
								/>
							),
							buttonClassName: 'pr-0'
					  })}
				handleButtonClick={() => {
					navigate(VenuePanelRoutes.addManageMenu);
				}}
				pageText={
					<TabsWrapper {...allThemeData}>
						<>
							<div className="flex-1 customScrollableNavbar position-relative zIndex-1 pa-t-24">
								{tabMenuLoading ? (
									<div className="pa-b-8">
										<TabsSkeleton />
									</div>
								) : (
									<>
										{tabMenuData?.length > 0 ? (
											<Tabs
												activeTab={tabId}
												onTabClick={onTabClick}
												leftBtnIcon={
													<i className="fa fa-angle-left"></i>
												}
												rightBtnIcon={
													<i className="fa fa-angle-right"></i>
												}
												navBtnsIconColor={'#4F4F4F'}
											>
												{tabMenuData?.length > 0 &&
													tabMenuData?.map(
														(item, i) => (
															<Tab
																key={i}
																className={`customScrollableNavItem fs-12 regular-text ${
																	tabId === i
																		? 'active'
																		: ''
																}`}
															>
																{
																	item?.categoryName
																}
															</Tab>
														)
													)}
											</Tabs>
										) : (
											''
										)}
										{tabMenuData?.length > 0 ? (
											<div className="borderBottom position-absolute bottom-0 w-100 zIndex-1" />
										) : (
											''
										)}
										{tabMenuData?.length > 0 && (
											<div className="pt-2 outline-none rearrangeIcon">
												<Rearrange
													width={'18px'}
													height={'18px'}
													onClick={() =>
														setRearrangeMenuModal(
															true
														)
													}
													fill="#F94D73"
													className="cursor-pointer"
												/>
												<Filter
													width={'16px'}
													height={'16px'}
													fill="#F94D73"
													className="cursor-pointer"
													id="manage-menu-filter-popover"
												/>
												<FilterPopover
													target="manage-menu-filter-popover"
													isOpen={filterPopover}
													handleToggle={
														handleFilterPopover
													}
													serviceType={serviceType}
													setServiceType={
														setServiceType
													}
												/>
											</div>
										)}
									</>
								)}
							</div>
						</>
					</TabsWrapper>
				}
			>
				<PageWrapper {...allThemeData}>
					{tabMenuLoading ? (
						<ProductItemSkeleton />
					) : tabMenuData?.length === 0 ? (
						<div className="w-100 h-100 d-flex justify-content-center align-items-center">
							<EmptyData content="No Data Found" />
						</div>
					) : (
						<TabContent
							activeTab={'' + tabId}
							className="overflow-hidden"
						>
							{tabMenuData?.length > 0 &&
								tabMenuData?.map((nav, i) => (
									<TabPane key={i} tabId={'' + i}>
										{isPopularExist &&
											tabId !== 0 &&
											tabMenuData[i]?.categoryProduct
												?.length > 0 && (
												<Button
													className="themeButton"
													onClick={() => {
														setSubCategoryId(
															nav?.categoryID
														);
														setOperatingHoursModal(
															true
														);
													}}
												>
													Set Opening Hours
												</Button>
											)}
										{!isPopularExist &&
											tabMenuData[i]?.categoryProduct
												?.length > 0 && (
												<Button
													className="themeButton"
													onClick={() => {
														setSubCategoryId(
															nav?.categoryID
														);
														setOperatingHoursModal(
															true
														);
													}}
												>
													Set Opening Hours
												</Button>
											)}
										<Menuitems
											isPopularExist={isPopularExist}
											menuItemId={i}
											categoryData={tabMenuData[i]}
											refetch={getProductListData}
										/>
									</TabPane>
								))}
						</TabContent>
					)}
				</PageWrapper>
			</PageStructure>
			{tabMenuData && tabMenuData?.length !== 0 && (
				<RearrangeMenuModal2
					isOpen={rearrangeMenuModal}
					handleModal={handleRearrangeMenuModal}
					tabMenuData={tabMenuData}
					activeTabMenu={tabMenuData[tabId]?.categoryName}
					setTabId={setTabId}
					setMenuItems={setTabMenuData}
					isPopularExist={isPopularExist}
					refetch={getProductListData}
				/>
			)}
			{operatingHoursModal && (
				<OperatingHoursModal
					isOpen={operatingHoursModal}
					handleModal={handleOperatingHoursModal}
					subCategoryId={subCategoryId}
					getProductListData={getProductListData}
				/>
			)}
		</>
	);
};
