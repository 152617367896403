import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { CommonRoutes, VenuePanelRoutes } from '../../../../Utils/routes';

const VenueRoute = ({ children }) => {
	const authDetails = useSelector((state) => state.auth);
	const { pathname } = useLocation();
	const NoVenueConnectedRoutes = [
		VenuePanelRoutes.connectVenue,
		VenuePanelRoutes.createVenueAccount,
		VenuePanelRoutes.support
	];
	if (authDetails?.isLogin) {
		if (authDetails?.login_type === 'venue') {
			// check subscription
			// if (authDetails?.subscriptions?.length === 0) {
			// 	if (pathname === VenuePanelRoutes.registerSubscription) {
			// 		return children;
			// 	} else {
			// 		return (
			// 			<Navigate to={VenuePanelRoutes.registerSubscription} />
			// 		);
			// 	}
			// }
			// check any venue is connected or not
			if (authDetails?.bars?.length === 0) {
				if (
					NoVenueConnectedRoutes.find((route) => {
						return pathname.includes(route);
					})
				) {
					return children;
				} else {
					return <Navigate to={VenuePanelRoutes.connectVenue} />;
				}
			} else {
				if (pathname === VenuePanelRoutes?.addManageMenu) {
					if (Number(authDetails?.selectedVenue?.posStatus) === 1) {
						// Navigate to 404 Not Found Page
						return <Navigate to={CommonRoutes.pageNotFound} />;
					} else {
						return children;
					}
				} else {
					return children;
				}
			}
		} else {
			// Navigate to 404 Not Found Page
			return <Navigate to={CommonRoutes.pageNotFound} />;
		}
	} else {
		// Navigate to login page
		return <Navigate to={CommonRoutes.landingPage} />;
	}
};

export default VenueRoute;
