export const ComputerIcon = ({ fill, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 48 48" fill="none">
			<path
				d="M38 8H10C7.75 8 6 9.8125 6 12V30C6 32.25 7.75 34 10 34H19.3125L18.125 38H15C14.4375 38 14 38.5 14 39C14 39.5625 14.4375 40 15 40H33C33.5 40 34 39.5625 34 39C34 38.5 33.5 38 33 38H29.8125L28.625 34H38C40.1875 34 42 32.25 42 30V12C42 9.8125 40.1875 8 38 8ZM20.1875 38L21.4375 34H26.5L27.75 38H20.1875ZM40 30C40 31.125 39.0625 32 38 32H10C8.875 32 8 31.125 8 30V26H40V30ZM40 24H8V12C8 10.9375 8.875 10 10 10H38C39.0625 10 40 10.9375 40 12V24Z"
				fill={fill ?? '#242424'}
			/>
		</svg>
	);
};
