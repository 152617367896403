import FormSwitch from '../../Form/FormSwitch';

export const formatDocketPrintersTableData = (data) => {
	let tableData = [];
	if (data?.length > 0) {
		tableData = data.map((item) => {
			return {
				id: item?.productID,
				printer_name: item?.product_name,
				ip_address: item?.sub_category_name,
				print_docket: item?.soldQunitity
			};
		});
	}
	return tableData;
};

export const data = [
	{
		id: 1,
		printer_name: 'PNT-001 Mark',
		ip_address: '192.168.1.7',
		print_docket: <FormSwitch />,
		action: 'Assign Subheading'
	},
	{
		id: 2,
		printer_name: 'TM-OP65',
		ip_address: '1925.168.1.7',
		print_docket: <FormSwitch />,
		action: 'Assign Subheading'
	},
	{
		id: 3,
		printer_name: 'TM-OP6-PP',
		ip_address: '192.168.2.1',
		print_docket: <FormSwitch />,
		action: 'Assign Subheading'
	}
];
