import { useState } from 'react';
import { useSelector } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';

import useDevice from '../../Hooks/useDevice';
import PageWrapper from './index.style';
import Progressbar from '../../Components/Common/Progressbar';
import { GlobeComponent } from '../../Components/Common/GlobeComponent';
import Analytics from '../../Components/Dashboard/Analytics';
import {
	DownArrowIcon,
	UpArrowIcon,
	EllipseIcon
} from '../../Components/Icons';

export const Live = () => {
	const { isTabletOrMobile, isMobile, isBigScreenHeight } = useDevice();
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	const [dataType, setDataType] = useState('live');
	const [cityDropdown, setCityDropdown] = useState(false);
	const [isGlobeFull, setIsGlobeFull] = useState(false);
	const [dateDropdown, setDateDropdown] = useState(false);

	return (
		<Scrollbars
			style={{
				height: `${
					isMobile ? 'calc(100vh - 145px)' : 'calc(100vh - 111px)'
				}`
			}}
			className="custom-scrollbar"
		>
			<PageWrapper
				{...allThemeData}
				className={`d-flex flex-column flex-xl-row gap-md-3 min-height-100`}
			>
				{/* <PageWrapper {...allThemeData} className={`d-flex flex-column flex-xl-row gap-md-3 h-100 `}> */}
				<div className="border-radius-16 flex-5 d-flex flex-column p-3 defaultBoxShadow bg-white">
					<div>
						<p className="fs-16 medium-text themeText">
							Here is what is happening with MyTab today
						</p>
						<p className="d-flex flex-row align-items-center gap-2 fs-14 medium-text themeText pt-2">
							<EllipseIcon height={14} width={14} /> MyTab Venue
							<EllipseIcon
								height={14}
								width={14}
								fill="#FD6461"
							/>{' '}
							MyTab Customer
						</p>
					</div>
					<div className="d-flex flex-column justify-content-evenly h-100">
						<div className="d-flex align-items-center justify-content-center mt-20">
							<GlobeComponent
								width={
									isBigScreenHeight
										? !isGlobeFull
											? 600
											: 500
										: !isGlobeFull
										? isMobile
											? 500
											: 300
										: 300
								}
								height={
									isBigScreenHeight
										? !isGlobeFull
											? 550
											: 440
										: !isGlobeFull
										? isMobile
											? 440
											: 240
										: 240
								}
							/>
						</div>
						<div className="d-flex justify-content-center position-relative">
							<div className="buttonGroup d-flex align-items-center justify-content-between p-0 position-relative">
								<div
									className={`activeBackground position-absolute ${
										dataType !== 'live' ? 'active' : ''
									}`}
								></div>
								<div
									className={`flex-1 buttonType ${
										dataType === 'live' ? 'active' : ''
									}`}
									onClick={() => setDataType('live')}
								>
									<span className="fs-14 semi-bold-text">
										{/* <LiveIcon height={24} width={24} />  */}
										Live
									</span>
								</div>
								<div
									className={`flex-1 buttonType ${
										dataType === 'overview' ? 'active' : ''
									}`}
									onClick={() => setDataType('overview')}
								>
									<span className="fs-14 semi-bold-text">
										{/* <OverviewIcon height={24} width={24} />  */}
										Overview
									</span>
								</div>
							</div>
							<div
								className="d-flex align-items-center justify-content-end"
								onClick={() => setIsGlobeFull((prev) => !prev)}
							>
								{isGlobeFull ? (
									<UpArrowIcon height={18} width={18} />
								) : (
									<DownArrowIcon height={18} width={18} />
								)}
							</div>
						</div>
						{isGlobeFull && (
							<div
								className={`d-flex flex-column justify-content-end flex-lg-row ${
									!isTabletOrMobile ? 'gap-5' : 'gap-3'
								}`}
							>
								<div className="flex-1 h-100">
									<p className="fs-16 semi-bold-text pb-2">
										Venues
									</p>
									<div>
										<Progressbar
											title="Melbourne • Australia"
											subtitle="6 sessions"
											percentage="100"
										/>
									</div>
									<div>
										<Progressbar
											title="Miami • USA"
											subtitle="5 sessions"
											percentage="90"
										/>
									</div>
									<div>
										<Progressbar
											title="Perth • Australia"
											subtitle="4 sessions"
											percentage="80"
										/>
									</div>
									<div>
										<Progressbar
											title="Sydney • Australia"
											subtitle="3 sessions"
											percentage="70"
										/>
									</div>
								</div>
								<div className="flex-1 h-100">
									<p className="fs-16 semi-bold-text pb-2">
										Customers
									</p>
									<div>
										<Progressbar
											title="Melbourne • Australia"
											subtitle="6 sessions"
											percentage="100"
										/>
									</div>
									<div>
										<Progressbar
											title="Miami • USA"
											subtitle="5 sessions"
											percentage="80"
										/>
									</div>
									<div>
										<Progressbar
											title="Perth • Australia"
											subtitle="4 sessions"
											percentage="60"
										/>
									</div>
									<div>
										<Progressbar
											title="Sydney • Australia"
											subtitle="3 sessions"
											percentage="45"
										/>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<Analytics
					cityDropdown={cityDropdown}
					setCityDropdown={setCityDropdown}
					dateDropdown={dateDropdown}
					setDateDropdown={setDateDropdown}
				/>
			</PageWrapper>
		</Scrollbars>
	);
};
