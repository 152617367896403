import { Button, Col, Row } from 'reactstrap';
import CustomModal from '../../../Common/Modal/CustomModal';
import FormInput from '../../../Form/FormInput';
import ReactFormSelect from '../../../Form/ReactFormSelect';
import WrapperDiv from '../MenuSubHeadingsModal/index.style';

const AddPrinterModal = ({ type, isOpen, handleModal }) => {
	const printerModalOptions = [
		{
			value: '1',
			label: 'Printer 1'
		},
		{
			value: '2',
			label: 'Printer 2'
		},
		{
			value: '3',
			label: 'Printer 3'
		}
	];

	return (
		<CustomModal
			title={`${type === 'add' ? 'Add Printer' : 'Manage Printer'}`}
			size="md"
			isOpen={isOpen}
			handleModal={handleModal}
		>
			<WrapperDiv>
				<div className="addPrinterModal">
					<form className="overflow-hidden p-1">
						<Row>
							<Col sm={12}>
								<FormInput
									id="name"
									type="text"
									name="name"
									label="Printer Name"
									placeholder="Enter Printer Name"
									// value={editProfileFormik.values.name}
									// onChange={editProfileFormik.handleChange}
									// error={editProfileFormik.touched.name && !!editProfileFormik.errors.name}
									// errorMsg={editProfileFormik.errors.name}
								/>
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								<ReactFormSelect
									id="printer_modal"
									label="Printer Model"
									placeholder="Select Printer Model"
									options={printerModalOptions}
									maxMenuHeight={150}
									isSearchable={false}
									// value={}
									// handleChange={handleChange}
								/>
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								<FormInput
									id="ip_address"
									type="text"
									name="ip_address"
									label="IP Address"
									placeholder="Enter IP Address"
									fromGroupClassName="mb-0"
									// value={editProfileFormik.values.name}
									// onChange={editProfileFormik.handleChange}
									// error={editProfileFormik.touched.name && !!editProfileFormik.errors.name}
									// errorMsg={editProfileFormik.errors.name}
								/>
								<p className="pb-3 headingTextColor fs-14 medium-text cursor-pointer">
									Want to find Printer’s IP Address?
								</p>
							</Col>
						</Row>
						<Row>
							<Col
								sm={12}
								className="d-flex align-items-center justify-content-center"
							>
								<Button
									type="button"
									className="fs-18 medium-text themeButtonFullWidth ptb-10 plr-60 mt-10 text-center mb-2"
								>
									Add
								</Button>
							</Col>
						</Row>
					</form>
				</div>
			</WrapperDiv>
		</CustomModal>
	);
};

export default AddPrinterModal;
