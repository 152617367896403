import { useSelector } from 'react-redux';

import ForgotPasswordForm from '../../Components/Authentication/ForgotPasswordForm';
import PageWrapper from './forgotPassword.style';
import phoneImage from '../../Assets/images/phone.png';
import bannerTextImage from '../../Assets/images/forgotPasswordBannerText.png';
import forgotpasswordPageImg from '../../Assets/images/forgotpassword-page-img.png';
import createAccountImage from '../../Assets/images/loginPageImg.png';
import Agreement from '../../Components/Authentication/Agreement';

export const ForgotPassword = (props) => {
	const allthemeData = useSelector((state) => ({ ...state.themeChanger }));

	return (
		<PageWrapper {...allthemeData}>
			<div className="formWrapper pl-26 pr-45">
				<ForgotPasswordForm {...props} />
				<Agreement />
			</div>
			<div className="imageWrapper">
				<div className="backWrraper">
					<p className="text">
						Manage your MyTab Venues in one place
					</p>
					<img
						src={createAccountImage}
						alt="side-img"
						className="image"
					/>
				</div>
			</div>
			{/* <div>
				<div className="pageContainer">
					<div className="imageContainer">
						<div className="bannerCard">
							<div className="bannerCardBody">
								<div className="textContentWrapper">
									<img
										src={bannerTextImage}
										alt="app-photos"
										className="bannerTextImage"
									/>
								</div>
								<div className="phoneImageWrapper">
									<img
										src={phoneImage}
										alt="app-photos"
										className="phoneImage"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="formCardContainer">
						<ForgotPasswordForm {...props} />
					</div>
				</div>
			</div> */}
		</PageWrapper>
	);
};
