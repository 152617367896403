import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
	TermsOfService,
	PrivacyPolicy,
	HelpAndSupport
} from '../StaticPageModals';

import FooterWrapper from './footer.style';
import { AppName } from '../../Helper/constant';

const Footer = (props) => {
	const location = useLocation();

	const [tncModal, setTncModal] = useState(false);
	const [privacyModal, setPrivacyModal] = useState(false);
	const [hnsModal, setHnsModal] = useState(false);

	const handleTncModal = () => {
		setTncModal((prev) => !prev);
	};

	const handlePrivacyModal = () => {
		setPrivacyModal((prev) => !prev);
	};

	const handleHnsModal = () => {
		setHnsModal((prev) => !prev);
	};
	return (
		<FooterWrapper {...props}>
			<div className="footerBack d-flex align-items-center justify-content-center position-absolute">
				{location?.pathname.includes('admin') ||
				location?.pathname === '/admin/login' ||
				location?.pathname === '/login' ||
				location?.pathname === '/landing-page' ||
				location?.pathname === '/register' ? (
					<ul className="list-inline ma-0 text-center">
						<li className="list-inline-item fs-12 medium-text">
							Copyright © {new Date().getFullYear()} {AppName}{' '}
							<span className="pipe fs-12 medium-text"> | </span>{' '}
							<span className="fs-12 regular-text">
								All Rights Reserved
							</span>
						</li>
					</ul>
				) : (
					<div className="container d-flex justify-content-between">
						<ul className="list-inline ma-0">
							<li className="list-inline-item fs-12 medium-text">
								Copyright © {new Date().getFullYear()} {AppName}{' '}
								<span className="pipe fs-12 medium-text">
									{' '}
									|{' '}
								</span>{' '}
								<span className="fs-12 regular-text">
									All Rights Reserved
								</span>
							</li>
						</ul>
						<ul className="list-inline ma-0">
							<li className="list-inline-item">
								<span
									className="cursor-pointer fs-12 medium-text"
									onClick={() => {
										window.open(
											'https://mytabinfo.com/policies/terms-of-service',
											'_blank'
										);
									}}
								>
									Terms and Conditions
								</span>
								<span className="pipe"> | </span>
							</li>
							<li className="list-inline-item">
								<span
									className="cursor-pointer fs-12 medium-text"
									onClick={() => {
										window.open(
											'https://mytabinfo.com/policies/privacy-policy',
											'_blank'
										);
									}}
								>
									Privacy Policy
								</span>
							</li>
						</ul>
					</div>
				)}
			</div>
			<TermsOfService handleModal={handleTncModal} isOpen={tncModal} />
			<PrivacyPolicy
				handleModal={handlePrivacyModal}
				isOpen={privacyModal}
			/>
			<HelpAndSupport handleModal={handleHnsModal} isOpen={hnsModal} />
		</FooterWrapper>
	);
};

export default Footer;
