import React from 'react';

const Right = ({ width, height, fill, ...props }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M27 9L13 23L6 16"
				stroke={fill ?? '#FF5F5F'}
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default Right;
