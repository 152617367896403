export const CircleCheckIcon = ({ fill, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 32 33" fill="none">
			<path
				d="M14.6871 21.0898C14.3121 21.4648 13.6246 21.4648 13.2496 21.0898L9.24963 17.0898C8.87463 16.7148 8.87463 16.0273 9.24963 15.6523C9.62463 15.2773 10.3121 15.2773 10.6871 15.6523L13.9996 18.9648L21.2496 11.6523C21.6246 11.2773 22.3121 11.2773 22.6871 11.6523C23.0621 12.0273 23.0621 12.7148 22.6871 13.0898L14.6871 21.0898ZM31.9996 16.3398C31.9996 25.2148 24.8121 32.3398 15.9996 32.3398C7.12463 32.3398 -0.000366211 25.2148 -0.000366211 16.3398C-0.000366211 7.52734 7.12463 0.339844 15.9996 0.339844C24.8121 0.339844 31.9996 7.52734 31.9996 16.3398ZM15.9996 2.33984C8.24963 2.33984 1.99963 8.65234 1.99963 16.3398C1.99963 24.0898 8.24963 30.3398 15.9996 30.3398C23.6871 30.3398 29.9996 24.0898 29.9996 16.3398C29.9996 8.65234 23.6871 2.33984 15.9996 2.33984Z"
				fill={fill ?? '#FF5F5F'}
			/>
		</svg>
	);
};
