import CustomModal from '../Common/Modal/CustomModal';
import rightImg from '../../Assets/images/right.png';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';

const Div = styled.div`
	overflow: hidden;
	.salesAnalytics {
		@media only screen and (min-width: 992px) {
			border-right: 1px solid #d4d4d4;
		}
	}
	.performanceAnalytics {
		@media only screen and (max-width: 991px) {
			padding-top: 40px;
		}
	}
`;

const SubscriptionModal = ({
	handleModal,
	isOpen,
	modalData = [],
	modalTitle,
	modalType
}) => {
	return (
		<CustomModal
			isOpen={isOpen}
			handleModal={handleModal}
			title={modalTitle ? modalTitle : ''}
			size="xl"
		>
			<Div>
				<Row>
					{modalData?.length > 0 &&
						modalData?.map((item, index) => {
							return (
								<Col lg="6">
									<div
										className={`${
											index === 0
												? 'salesAnalytics'
												: 'performanceAnalytics'
										}`}
									>
										{item?.title !== '' && (
											<p className="fs-24 semi-bold-text">
												{item?.title}
											</p>
										)}
										{item?.data?.length > 0 &&
											item?.data?.map((item, index) => {
												return (
													<div
														key={index}
														className="pa-t-16 d-flex align-items-center"
													>
														<div>
															<img
																src={rightImg}
																alt="right_icon_img"
															/>
														</div>
														<span
															className="fs-18 semi-bold-text pl-16 text-break"
															style={{
																color: '#242424'
															}}
														>
															{item}
														</span>
													</div>
												);
											})}
									</div>
								</Col>
							);
						})}
					{/* <Col lg="6">
						<div className="salesAnalytics">
							<p className="fs-24 semi-bold-text">
								Venue Sales Analytics
							</p>
							{salesAnalytics.map((item, index) => {
								return (
									<div
										key={index}
										className="pa-t-16 d-flex align-items-center"
									>
										<div>
											<img
												src={rightImg}
												alt="right_icon_img"
											/>
										</div>
										<span
											className="fs-18 semi-bold-text pl-16 text-break"
											style={{ color: '#242424' }}
										>
											{item}
										</span>
									</div>
								);
							})}
						</div>
					</Col>
					<Col lg="6">
						<div className="performanceAnalytics">
							<p className="fs-24 semi-bold-text">
								Venue Performance Analytics
							</p>
							{performanceAnalytics.map((item, index) => {
								return (
									<div
										key={index}
										className="pa-t-16 d-flex align-items-center"
									>
										<div>
											<img
												src={rightImg}
												alt="right_icon_img"
											/>
										</div>
										<span
											className="fs-18 semi-bold-text pl-16 text-break"
											style={{ color: '#242424' }}
										>
											{item}
										</span>
									</div>
								);
							})}
						</div>
					</Col> */}
				</Row>
			</Div>
		</CustomModal>
	);
};
export default SubscriptionModal;
