import styled from 'styled-components';

const ModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	@media only screen and (max-width: 768px) {
		padding: 0px;
	}
	.cancleIconWrapper {
		position: absolute;
		top: 8px;
		right: 8px;
		text-align: right;
	}
	.modalTitle {
		padding-top: 40px;
		padding-bottom: 16px;
		@media only screen and (max-width: 768px) {
			padding-top: 20px;
		}
	}
	.modalSubTitle {
		padding-bottom: 20px;
	}
	.headingTextColor2 {
		color: ${(props) => props.layoutTheme.buttonColor2};
	}
	.borderButton {
		border: 1px solid ${(props) => props.layoutTheme.buttonColor2} !important;
		color: ${(props) => props.layoutTheme.buttonColor2} !important;
		background-color: #fff !important;
		font-family: 'montserrat-medium';
		font-size: 14px;
		padding-block: 14px;
		padding-inline: 47px;
	}

	.borderButtonFullWidth {
		border: 1px solid ${(props) => props.layoutTheme.buttonColor2} !important;
		color: ${(props) => props.layoutTheme.buttonColor2} !important;
		background-color: #fff !important;
		width: 100%;
		font-family: 'montserrat-medium';
		font-size: 14px;
		padding-block: 14px;
		padding-inline: 47px;
	}

	.themeButton {
		background: linear-gradient(350.67deg, #f94d73 0%, #fd6461 92.95%);
		/* background: ${(props) => props.layoutTheme.buttonColor}; */
		color: ${(props) => props.layoutTheme.buttonTextColor};
		border: 0px;
		font-family: 'montserrat-medium';
		font-size: 14px;
		padding-block: 14px;
		padding-inline: 47px;
	}

	.themeBorderButton {
		border: 1px solid ${(props) => props.layoutTheme.buttonColor2} !important;
		color: ${(props) => props.layoutTheme.buttonColor2} !important;
		background-color: #fff !important;
		font-family: 'montserrat-medium';
		font-size: 14px;
		padding-block: 14px;
		padding-inline: 47px;
	}

	.themeButtonFullWidth {
		background: linear-gradient(350.67deg, #f94d73 0%, #fd6461 92.95%);
		/* background: ${(props) => props.layoutTheme.buttonColor}; */
		color: ${(props) => props.layoutTheme.buttonTextColor};
		border: 0px;
		width: 100%;
		font-family: 'montserrat-medium';
		font-size: 14px;
		padding-block: 14px;
		padding-inline: 47px;
	}

	.themeCheckbox {
		border-radius: 2px !important;
		border: 2px solid rgba(0, 0, 0, 0.25) !important;
		float: unset !important;
		margin-left: unset !important;
		margin-top: unset !important;
		vertical-align: unset !important;
	}
	.themeCheckbox:checked {
		background-color: ${(props) =>
			props.layoutTheme.headingColor} !important;
		border: 0px !important;
		box-shadow: none !important;
		&:active,
		&:focus {
			border: 0px !important;
		}
	}
	.themeCheckbox:active,
	.themeCheckbox:focus {
		box-shadow: none !important;
		outline: none !important;
		border: 2px solid rgba(0, 0, 0, 0.25) !important;
	}
	.themeSwitch:focus {
		box-shadow: none !important;
		outline: none !important;
		border: 1px solid #00000040 !important;
		background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e');
	}
	.themeSwitch:checked {
		background-color: ${(props) =>
			props.layoutTheme.headingColor} !important;
		box-shadow: none;
		border: 0px !important;
		background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e') !important;
		&:focus {
			border: 0px !important;
		}
	}
	.themeSwitch:active {
		box-shadow: none !important;
		outline: none !important;
		border-color: #00000040 !important;
		background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e');
	}
	hr {
		border: 1px solid #eaeaea;
		opacity: 0.5 !important;
	}
	.noResize {
		resize: none;
	}
	input,
	textarea,
	select {
		&:focus {
			outline: none;
			box-shadow: none !important;
			border: 0px !important;
		}
		background-color: ${(props) =>
			props.layoutTheme.inputBackgroundColor} !important;
		color: ${(props) => props.layoutTheme.textColor} !important;
		border-radius: 8px !important;
	}
	.inputBox {
		background-color: ${(props) =>
			props.layoutTheme.inputBackgroundColor} !important;
		color: ${(props) => props.layoutTheme.textColor} !important;
		font-family: 'montserrat-medium' !important;
		border-radius: 6px !important;
		::placeholder {
			/* Chrome, Firefox, Opera, Safari 10.1+ */
			color: ${(props) => props.layoutTheme.placeHolderColor} !important;
			font-family: 'montserrat-medium' !important;
			font-weight: 500 !important;
			font-size: 12px !important;
			opacity: 1; /* Firefox */
		}
		&:focus {
			outline: none;
			box-shadow: none !important;
			border: 0px !important;
		}
	}
	.css-1dimb5e-singleValue {
		font-family: 'montserrat-medium' !important;
	}
	.todoForm {
		.form-label {
			margin-bottom: 0px !important;
		}
		.info-status-tag,
		.info-status-tag div {
			background: #e7f1ff;
			border-radius: 6px;
			color: #195dbc !important;
		}
		.warning-status-tag,
		.warning-status-tag div {
			background: #fff4e7;
			border-radius: 6px;
			color: #e58008 !important;
		}
		.success-status-tag,
		.success-status-tag div {
			background: #e7ffe9;
			border-radius: 6px;
			color: #43bc19 !important;
		}
		.statusTag {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0px 5px;
			div {
				font-size: 14px;
			}
		}
		.basic-single {
			width: 100%;
			padding: 0px;
		}
		.selectSearch__placeholder,
		.selectSearch__single-value {
			margin-left: 6px;
		}
		.selectSearch__control,
		.customSelect2__control {
			background-color: ${(props) =>
				props.layoutTheme.inputBackgroundColor} !important;
			color: ${(props) => props.layoutTheme.textColor} !important;
			border: 0px !important;
		}
		.todoDatePicker {
			::placeholder {
				/* Chrome, Firefox, Opera, Safari 10.1+ */
				color: ${(props) =>
					props.layoutTheme.placeHolderColor} !important;
			}
			::-webkit-datetime-edit-text,
			::-webkit-datetime-edit-month-field,
			::-webkit-datetime-edit-day-field,
			::-webkit-datetime-edit-date-field,
			::-webkit-datetime-edit-year-field {
				color: ${(props) =>
					props.layoutTheme.placeHolderColor} !important;
			}
		}
		/* regular text style */
		.date-input--has-value::-webkit-datetime-edit-text,
		.date-input--has-value::-webkit-datetime-edit-month-field,
		.date-input--has-value::-webkit-datetime-edit-day-field,
		.date-input--has-value::-webkit-datetime-edit-year-field {
			color: ${(props) => props.layoutTheme.textColor} !important;
		}
		.customSelect2__placeholder,
		.selectSearch__placeholder {
			color: ${(props) => props.layoutTheme.placeHolderColor} !important;
		}
		.customSelect2__single-value,
		.selectSearch__single-value {
			color: ${(props) => props.layoutTheme.textColor} !important;
			font-family: 'montserrat-medium';
			font-size: 16px;
		}
		.customSelect2__value-container,
		.selectSearch__value-container {
			height: 45px;
			overflow: auto;
		}
		.customSelect2__control {
			padding: 0px;
			justify-content: space-between;
		}

		.customSelect2__indicator {
			padding-top: 0px;
			padding-bottom: 0px;
			justify-content: center;
		}
		.selectSearch__control {
			padding: 0px;
		}
		.selectSearch__indicator {
			color: #000;
			padding-right: 4px;
			padding-top: 0px;
			padding-bottom: 0px;
			svg {
				height: 18px;
				width: 18px;
			}
		}
	}
	.mb-0 {
		margin-bottom: 0px !important;
	}
	.white-space-pre {
		white-space: pre !important;
	}
	.headingTextColor {
		color: ${(props) => props.layoutTheme.buttonColor3} !important;
	}
	.operationalHoursCard {
		border: 1px solid #eaeaea;
		border-radius: 6px;
	}
	.reactFormSelect .customSelect2__dropdown-indicator {
		flex: 1;
	}
	.reactFormSelect .customSelect2__placeholder {
		color: #bababa !important;
		font-weight: 600;
	}
	.orderCard {
		border: 1px solid #eaeaea;
		border-radius: 6px;
	}
	.border-radius-0 {
		border-radius: 0px !important;
	}
	.themeRadio {
		border: 2px solid rgba(0, 0, 0, 0.25) !important;
	}

	.themeRadio:checked {
		background-image: none !important;
		background-color: ${(props) =>
			props.layoutTheme.headingColor} !important;
		border: 0px !important;
		box-shadow: none !important;
		&:active,
		&:focus {
			border: 0px !important;
		}
	}
	.themeRadio:active,
	.themeCheckbox:focus {
		box-shadow: none !important;
		outline: none !important;
		border-color: #00000040 !important;
	}
	.react-datepicker__time-list-item {
		&.react-datepicker__time-list-item--selected {
			background-color: ${(props) =>
				props.layoutTheme.buttonColor3} !important;
		}
	}
	.qtyBox {
		width: 21px;
		height: 21px;
		background-color: #ffe7e7;
		border-radius: 6px;
		color: ${(props) => props.layoutTheme.buttonColor3} !important;
	}
	.orderHistoryModals {
		.inputBox {
			border-radius: 6px !important;
		}
	}
	.horizontalRule {
		border-top: 1px solid #dddddd !important;
	}
`;

export default ModalWrapper;
