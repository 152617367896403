const barActions = {
	BAR_ID_ACTION: 'BAR_ID_ACTION',
	SET_CURRENT_PRODUCT: 'SET_CURRENT_PRODUCT',

	setBarId: (data) => {
		return {
			type: barActions.BAR_ID_ACTION,
			bar_id: data
		};
	},
	setCurrentProduct: (data) => {
		return {
			type: barActions.SET_CURRENT_PRODUCT,
			currentProduct: data.currentProduct
		};
	}
};

export default barActions;
