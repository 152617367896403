import styled from 'styled-components';

export const NewFormSelectWrapper = styled.div`
	input {
		padding-inline: 16px !important;
		color: #313132eb !important;
		font-family: 'poppins-regular' !important;
		font-size: 24px;
		outline: none !important;
		border-radius: 0 !important;
		@media (max-width: 600px) {
			font-size: 12px !important;
		}

		@media only screen and (min-width: 600px) and (max-width: 960px) {
			font-size: 18px !important;
		}

		@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
			font-size: 13.5px !important;
		}
	}
	.css-lvhtuy-MenuList {
		/* Customize the scrollbar */
		scrollbar-width: thin; /* For Firefox */
		scrollbar-color: rgba(0, 0, 0, 0.2) #fff; /* For Firefox */
		/* Customize the scrollbar for Webkit browsers (Chrome, Safari, etc.) */
		::-webkit-scrollbar {
			width: 2px;
		}
		::-webkit-scrollbar-thumb {
			background-color: rgba(0, 0, 0, 0.2);
			border-radius: 3px !important;
		}
		::-webkit-scrollbar-track {
			background-color: #fff;
			border-radius: 18px !important;
		}
	}
`;
