import { Card } from 'reactstrap';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
	border-radius: 18px !important;
	border: ${(props) => (props.selected ? '2px solid #FF5F5F' : 'none')};
	height: 124px !important;
	img {
		height: 100% !important;
		object-fit: cover;
		border-radius: 16px;
	}
	.defaultBoxShadow {
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
	}
	.venueCardImgOverlay {
		background: linear-gradient(
			89.05deg,
			rgba(0, 0, 0, 0.69) 4.17%,
			rgba(0, 0, 0, 0) 49.48%,
			rgba(0, 0, 0, 0.69) 100%
		);
		border-radius: 18px !important;
	}
	.card-img-overlay {
		padding: 0;
	}
	.card-title {
		padding-bottom: 21px;
		padding-left: 21px;
	}
	.dotIconWrapper {
		padding-right: 18px;
		padding-top: 18px;
	}
`;
