import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Button } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import { ClockIcon } from '../../../Icons';
import { PageWrapper } from './index.style';

const CategoryItemSkeleton = () => {
	const uptoXL = useMediaQuery({ maxWidth: 1600 });
	return (
		<PageWrapper>
			<div className="gridContainer">
				{Array(9)
					?.fill('1')
					?.map((item, index) => {
						return (
							<div className="gridItem" key={index}>
								<div className="borderBox itemBox">
									<div className="pr-16">
										<p
											className={`${
												uptoXL ? 'fs-16' : 'fs-18'
											} medium-text productItemName`}
										>
											category name
										</p>
									</div>
									<Button className="themeButton ptb-12 plr-20 d-flex align-items-center gap-2">
										<ClockIcon
											width={`${
												uptoXL ? '20px' : '24px'
											}`}
											height={`${
												uptoXL ? '20px' : '24px'
											}`}
										/>
										Set Wait Times
									</Button>
								</div>
								<div className="cardSkeleton">
									<Skeleton
										height="100%"
										width={'100%'}
										borderRadius={'8px'}
									/>
								</div>
							</div>
						);
					})}
			</div>
		</PageWrapper>
	);
};

export default CategoryItemSkeleton;
