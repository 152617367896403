import { useEffect, useState } from 'react';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import Select from 'react-select';
import { useFormik } from 'formik';

import * as yup from 'yup';

import CustomModal from '../Common/Modal/CustomModal';
import CustomDatePicker from '../Common/DateRangePicker/CustomDatePicker';
import {
	CustomOption,
	priorityOptions,
	select2Style,
	select2StyleStatus,
	statusOptions,
	usersOptions,
	ValueTagContainer,
	DropdownIndicator,
	ValueContainer
} from './utils';

const TodoModal = ({ isOpen, handleModal, type, modalData }) => {
	const [menuIsOpen, setMenuIsOpen] = useState(false);

	const handleDateChange = (date) => {
		todoFormik.setFieldValue('due_date', date);
	};

	const validationSchema = yup.object().shape({});

	const handelOnsubmit = async () => {};

	const todoFormik = useFormik({
		initialValues: {
			task: '',
			status: '',
			priority: '',
			assigned_to: '',
			due_date: ''
		},
		validationSchema,
		onSubmit: handelOnsubmit
	});

	useEffect(() => {
		if (type === 'edit') {
			todoFormik.setFieldValue('task', modalData.task);
			todoFormik.setFieldValue('status', modalData.tag);
			todoFormik.setFieldValue('priority', modalData.priority);
			todoFormik.setFieldValue('assigned_to', modalData.assigned_to);
			todoFormik.setFieldValue('due_date', new Date(modalData.due_date));
		} else {
			todoFormik.setFieldValue('task', '');
			todoFormik.setFieldValue('status', '');
			todoFormik.setFieldValue('priority', '');
			todoFormik.setFieldValue('assigned_to', '');
			todoFormik.setFieldValue('due_date', '');
		}
	}, [type, modalData]);

	return (
		<CustomModal
			title={`${type === 'add' ? 'Add To-Do' : 'Edit To-Do'}`}
			size="md"
			isOpen={isOpen}
			handleModal={handleModal}
			autoHeightMin={420}
		>
			<form className="todoForm" onSubmit={todoFormik.handleSubmit}>
				<FormGroup>
					<Label className="fs-16 semi-bold-text themeText">
						Task
					</Label>
					<Input
						className={`inputBox no-border border-radius-0 fs-16 medium-text noResize`}
						type="textarea"
						name="task"
						placeholder="Please enter a task that needs to be completed"
						rows="5"
						value={todoFormik.values.task}
						onChange={todoFormik.handleChange}
					/>
				</FormGroup>
				<div className="">
					<FormGroup className="d-flex flex-1 justify-content-between">
						<Label className="fs-16 semi-bold-text themeText">
							Status
						</Label>
						<Select
							placeholder="Select Status"
							className="Select2CustomWidth justify-content-center"
							classNamePrefix="customSelect2"
							components={{
								Option: CustomOption,
								IndicatorSeparator: () => null,
								DropdownIndicator,
								ValueContainer: ValueTagContainer
							}}
							options={statusOptions}
							isSearchable={false}
							styles={select2StyleStatus}
							value={statusOptions.filter(
								(option) =>
									option.value === todoFormik.values.status
							)}
							onChange={(e) => {
								todoFormik.setFieldValue('status', e.value);
							}}
						/>
					</FormGroup>
					<FormGroup className="d-flex flex-1 justify-content-between">
						<Label className="fs-16 semi-bold-text themeText">
							Priority
						</Label>
						<Select
							placeholder="Select Priority"
							className="Select2CustomWidth justify-content-center"
							classNamePrefix="customSelect2"
							components={{
								IndicatorSeparator: () => null,
								DropdownIndicator,
								ValueContainer
							}}
							options={priorityOptions}
							isSearchable={false}
							styles={select2Style}
							menuPlacement="bottom"
							maxMenuHeight={150}
							value={priorityOptions.filter(
								(option) =>
									option.value === todoFormik.values.priority
							)}
							onChange={(e) => {
								todoFormik.setFieldValue('priority', e.value);
							}}
						/>
					</FormGroup>
				</div>
				<FormGroup className="d-flex align-items-center justify-content-between">
					<Label className="fs-16 semi-bold-text themeText white-space-pre flex-2">
						Assigned To
					</Label>
					<Select
						placeholder="Select User"
						components={{
							IndicatorSeparator: () => null,
							DropdownIndicator: () => null
						}}
						className="basic-single flex-4"
						classNamePrefix="selectSearch"
						options={usersOptions}
						isSearchable={true}
						styles={select2Style}
						onInputChange={(input) => {
							if (input) {
								setMenuIsOpen(true);
							} else {
								setMenuIsOpen(false);
							}
						}}
						menuIsOpen={menuIsOpen}
						isClearable
						menuPlacement="bottom"
						maxMenuHeight={150}
						value={
							todoFormik.values.assigned_to !== ''
								? usersOptions.filter(
										(option) =>
											option.label ===
											todoFormik.values.assigned_to
								  )
								: null
						}
						onChange={(e) =>
							e
								? todoFormik.setFieldValue(
										'assigned_to',
										e.label
								  )
								: todoFormik.setFieldValue('assigned_to', '')
						}
					/>
				</FormGroup>

				<FormGroup className="d-flex align-items-center justify-content-between mt-10 position-relative">
					<Label className="fs-16 semi-bold-text themeText white-space-pre pr-10 flex-2">
						Due Date
					</Label>
					<CustomDatePicker
						date={todoFormik.values.due_date}
						handleDateChange={handleDateChange}
						customClassName="min-height-38 plr-15"
						iconClassName="top-3"
					/>
				</FormGroup>
				<Button
					type="submit"
					className="fs-18 medium-text themeButtonFullWidth ptb-10 plr-60"
				>
					{`${type === 'add' ? 'Add New Task' : 'Update'}`}
				</Button>
			</form>
		</CustomModal>
	);
};

export default TodoModal;
