import React, { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useNavigate } from 'react-router-dom';

import TableStyle from '../Common/TableStyle';
import TableV6 from '../Common/TableV6';
import FormCheckBox from '../Form/FormCheckBox';
import CustomPopover from '../Common/Popover/CustomPopover';
import MarkCompleted from '../Todo/MarkCompleted';

const TodoPopOver = ({ todoPopover, todoPopoverToggle }) => {
	const columns = [
		{
			Header: '',
			accessor: 'id',
			className: 'text-center',
			filterable: false,
			sortable: false,
			width: 60,
			headerClassName: 'react-table-header-class fs-16 medium-text',
			Cell: ({ row }) => (
				<FormCheckBox
					name="status"
					checked={row.status && row.status}
					onChange={() => setMarkCompleted((prev) => !prev)}
					className="checkList-box"
				/>
			) // Custom cell components!
		},
		{
			Header: '',
			accessor: 'name',
			className: 'justify-content-start pl-10',
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-16 medium-text',
			Cell: ({ row }) => (
				<div className="d-flex flex-column gap-1">
					<p className="fs-15 regular-text">{row.name}</p>
					<span
						className={`${row._original.tag} p-1 fs-12 medium-text statusTag`}
					>
						Status Tag
					</span>
				</div>
			) // Custom cell components!
		}
	];

	const data = [
		{
			id: 1,
			status: true,
			tag: 'info-status-tag',
			name: 'Dummy Task 1'
		},
		{
			id: 2,
			status: true,
			tag: 'warning-status-tag',
			name: 'Dummy Task 2'
		},
		{
			id: 3,
			status: true,
			tag: 'success-status-tag',
			name: 'Dummy Task 3'
		},
		{
			id: 4,
			status: true,
			tag: 'info-status-tag',
			name: 'Dummy Task 4'
		},
		{
			id: 5,
			status: true,
			tag: 'info-status-tag',
			name: 'Dummy Task 5'
		},
		{
			id: 6,
			status: true,
			tag: 'info-status-tag',
			name: 'Dummy Task 6'
		},
		{
			id: 7,
			status: true,
			tag: 'info-status-tag',
			name: 'Dummy Task 7'
		}
	];

	const navigate = useNavigate();
	const [markCompleted, setMarkCompleted] = useState(false);
	const handleMarkCompletedModal = () => {
		setMarkCompleted((prev) => !prev);
	};

	return (
		<CustomPopover
			isOpen={todoPopover}
			target="todoPopover"
			handleToggle={todoPopoverToggle}
			placement="bottom-end"
			className="todoPopover"
		>
			<div className="h-100 p-3 todoPopoverBody">
				<div className="d-flex align-items-center justify-content-between">
					<p className="fs-20 semi-bold-text themeText">To Do List</p>
					<div
						className="fs-15 medium-text themeLinkText cursor-pointer text-decoration-none"
						onClick={() => {
							todoPopoverToggle();
							navigate('/admin/todo');
						}}
					>
						View All
					</div>
				</div>
				<Scrollbars style={{ height: '355px' }} autoHide>
					<TableStyle hideHeader className="pt-2">
						<TableV6
							columns={columns}
							data={data}
							handleSortBy={() => {}}
							key={'master-rep-table'}
						/>
					</TableStyle>
				</Scrollbars>
			</div>
			<MarkCompleted
				isOpen={markCompleted}
				handleModal={handleMarkCompletedModal}
			/>
		</CustomPopover>
	);
};

export default TodoPopOver;
