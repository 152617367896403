import { Button } from 'reactstrap';
import CustomModal from '../../Common/Modal/CustomModal';
import CustomButton from '../../Common/CustomButton';

const IntoxicatedModal = ({
	handleModal,
	isOpen,
	handleIntoxicatedOrder,
	intoxicatedOrderLoading
}) => {
	return (
		<CustomModal
			isOpen={isOpen}
			handleModal={handleModal}
			title="Customer too intoxicated?"
			size="md"
		>
			<div className="w-100">
				<p className="text-left fs-12 medium-text pa-b-18">
					The customer will be refunded and will not be able to place
					an order at your venue from their customer account until
					tomorrow. This action can not be undone.
				</p>
				<div className="d-flex ma-b-2" style={{ gap: '12px' }}>
					<div className="flex-1">
						<Button
							className="borderButtonFullWidth"
							onClick={handleModal}
						>
							No
						</Button>
					</div>
					<div className="flex-1">
						<CustomButton
							type="button"
							className="themeButtonFullWidth"
							onClick={handleIntoxicatedOrder}
							loading={intoxicatedOrderLoading}
						>
							Yes
						</CustomButton>
					</div>
				</div>
			</div>
		</CustomModal>
	);
};

export default IntoxicatedModal;
