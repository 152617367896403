import styled from 'styled-components';

const ModalWrapper = styled.div`
	color: ${(props) => props.layoutTheme.textColor};
	.phoneImage {
		width: 125px;
		height: auto;
	}
	.otpForm {
		width: 100%;
		input {
			width: 63px !important;
			height: 38px !important;
			margin: 0 9px;
			font-family: 'montserrat-medium';
			font-size: 26px;
			border-radius: 0px !important;
			border: 0px;
			border-bottom: 1px solid #a2a2a2 !important;
			outline: none;
			@media only screen and (max-width: 768px) {
				width: 43px !important;
				height: 38px !important;
				margin: 0 5px;
			}
			@media only screen and (max-width: 424px) {
				width: 28px !important;
				height: 28px !important;
				margin: 0 5px;
				font-size: 18px;
			}
		}
	}
`;

export default ModalWrapper;
