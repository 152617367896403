import { Fragment, useEffect, useState } from 'react';
import { Button, CardBody, Collapse } from 'reactstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import CustomModal from '../../Common/Modal/CustomModal';
import { PlusIcon } from '../../Icons';
import OperatingHoursCard from './OperatingHoursCard';
import { VenueApiRoutes } from '../../../Utils/routes';
import OpeningHoursSkeleton from '../Skeleton/OpeningHoursSkeleton';
import { OperatingHoursData } from '../utils';
import { useCachedData } from '../../../Hooks/useCachedData';
import { ModalWrapper } from './index.style';

const OperatingHoursModal = ({ isOpen, handleModal, subCategoryId, getProductListData = {} }) => {
	const authData = useSelector((state) => ({ ...state.auth }));
	const [operatingHoursData, setOperatingHoursData] = useState([]);
	const [collapse, setCollapse] = useState([]);
	const [loading, setLoading] = useState(false);
	const [firstTimeApiCall, setFirstTimeApiCall] = useState(true);
	const cachedData = useCachedData(
		'POST',
		VenueApiRoutes.getProductItemActiveHours,
		{
			sub_category_id: subCategoryId,
			bar_id: authData?.selectedVenue?.id
		}
	);
	const { data, isLoading, isError, error, refetch } = cachedData;
	const handleAddMoreTime = (index) => {
		setCollapse((prevData) =>
			prevData?.map((item, i) =>
				i === index ? (item ? !item : item) : item
			)
		);
		setOperatingHoursData((prevData) => {
			return prevData?.map((day) => {
				if (day?.weekDay === index) {
					let newObj = {
						id: -1,
						status: '0',
						activeHours: null,
						inActiveHours: null
					};
					return {
						...day,
						WeekDay_ItemActiveHours: [
							...day.WeekDay_ItemActiveHours,
							newObj
						]
					};
				} else {
					return day;
				}
			});
		});
	};
	const modalFooter = (
		<div className="w-100 pa-t-6 pr-8">
			<Button className="themeButtonFullWidth" onClick={handleModal}>
				Done
			</Button>
		</div>
	);
	useEffect(() => {
		if (data?.data) {
			let updatedData = [...OperatingHoursData];
			if (data?.data?.length > 0) {
				for (let item of data?.data) {
					updatedData[item?.weekDay] = item;
				}
			}
			setOperatingHoursData(updatedData);
			if (firstTimeApiCall) {
				setCollapse(updatedData?.map((day) => false));
				setFirstTimeApiCall(false);
			}
		}
		if (data?.status == 0) {
			toast.error(data?.message);
		}
	}, [data, data?.data]);
	useEffect(() => {
		setLoading(isLoading);
		if (isError) {
			toast.error(error);
		}
	}, [isLoading, isError, error]);
	useEffect(() => {
		if (refetch) refetch();
	}, [authData?.selectedVenue?.id]);
	return (
		<CustomModal
			title={'Set Opening Hours'}
			size="md"
			isOpen={isOpen}
			handleModal={handleModal}
			modalFooter={modalFooter}
			style={{ maxWidth: '630px', width: '100%' }}
		>
			<ModalWrapper>
				{loading ? (
					<OpeningHoursSkeleton />
				) : (
					<>
						{operatingHoursData?.length > 0 &&
							operatingHoursData?.map((day, index) => {
								return (
									<Fragment key={index}>
										<div
											className={`${index ===
													operatingHoursData?.length - 1
													? 'pa-b-12'
													: ''
												}`}
										>
											<Button
												onClick={() =>
													setCollapse((prevData) =>
														prevData?.map(
															(item, i) =>
																i === index
																	? !item
																	: item
														)
													)
												}
												className="w-100 d-flex justify-content-between align-items-center bg-white border-0 p-0"
											>
												<p className="fs-12 medium-text themeText">
													{moment()
														.day(index + 1)
														.format('dddd')}
												</p>
												<span
													onClick={() =>
														handleAddMoreTime(index)
													}
													className="no-border bg-white outline-none box-shadow-none"
												>
													<PlusIcon
														height={18}
														width={18}
													/>
												</span>
											</Button>
											<Collapse isOpen={collapse[index]}>
												<CardBody className="pb-10">
													<div
														className="d-flex justify-content-between ptb-18"
														style={{ gap: '12px' }}
													>
														<div className="d-flex align-items-center justify-content-center timeText"></div>
														<div className="d-flex align-items-center justify-content-start fs-11 medium-text timePickerContainerWidth">
															From Time
														</div>
														<div className="d-flex align-items-center justify-content-start fs-11 medium-text timePickerContainerWidth">
															To Time
														</div>
														<div className="d-flex align-items-center justify-content-center fs-11 medium-text switchContainer">
															Status
														</div>
														<div className="d-flex align-items-center justify-content-center saveContainer"></div>
														<div className="d-flex align-items-center justify-content-center deleteContainer"></div>
													</div>
													{day
														?.WeekDay_ItemActiveHours
														?.length > 0 &&
														day?.WeekDay_ItemActiveHours?.map(
															(item, i) => (
																<OperatingHoursCard
																	key={i}
																	id={i}
																	innerIndex={
																		i
																	}
																	innerLength={
																		day
																			?.WeekDay_ItemActiveHours
																			?.length
																	}
																	outerIndex={
																		index
																	}
																	weekDay={
																		day?.weekDay
																	}
																	data={item}
																	subCategoryId={
																		subCategoryId
																	}
																	refetch={
																		refetch
																	}
																	getProductListData={getProductListData}
																	operatingHoursData={
																		operatingHoursData
																	}
																	setOperatingHoursData={
																		setOperatingHoursData
																	}
																/>
															)
														)}
												</CardBody>
											</Collapse>
										</div>
										<div
											className={`horizontalRule ${index ===
													operatingHoursData?.length - 1
													? 'd-none'
													: ''
												} mtb-18`}
										/>
									</Fragment>
								);
							})}
					</>
				)}
			</ModalWrapper>
		</CustomModal>
	);
};

export default OperatingHoursModal;
