import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import TableStyle from '../../../Common/TableStyle';
import TableV6 from '../../../Common/TableV6';

const data = [
	{
		id: 1,
		name: 'Export Tax for State Transport',
		taxAmount: '45%'
	},
	{
		id: 2,
		name: 'TD Tax on Food Businesses',
		taxAmount: '80%'
	},
	{
		id: 1,
		name: 'Export Tax for State Transport',
		taxAmount: '45%'
	},
	{
		id: 2,
		name: 'TD Tax on Food Businesses',
		taxAmount: '80%'
	},
	{
		id: 1,
		name: 'Export Tax for State Transport',
		taxAmount: '45%'
	},
	{
		id: 2,
		name: 'TD Tax on Food Businesses',
		taxAmount: '80%'
	},
	{
		id: 1,
		name: 'Export Tax for State Transport',
		taxAmount: '45%'
	},
	{
		id: 2,
		name: 'TD Tax on Food Businesses',
		taxAmount: '80%'
	},
	{
		id: 1,
		name: 'Export Tax for State Transport',
		taxAmount: '45%'
	},
	{
		id: 2,
		name: 'TD Tax on Food Businesses',
		taxAmount: '80%'
	}
];

const tableColumns = [
	{
		Header: <span className="fs-12 medium-text">Name</span>,
		accessor: 'name',
		className: 'justify-content-start fs-12 regular-text',
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class justify-content-start',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Tax Amount (%)',
		accessor: 'taxAmount',
		className: 'fs-12 regular-text',
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Action',
		accessor: 'action',
		className: 'text-dark',
		filterable: false,
		sortable: false,
		minWidth: 50,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	}
];

const TableSkeleton = () => {
	return (
		<TableStyle version={6}>
			<TableV6 columns={tableColumns} data={data} />
		</TableStyle>
	);
};

export default TableSkeleton;
