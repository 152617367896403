import React, { useEffect, useRef, useState } from 'react';
import ImageUploadWrapper from './newImageUpload.style';

const NewImageUpload = ({
	name,
	className,
	value,
	error,
	errorMsg,
	setFieldValue,
	icon,
	handleReset,
	defaultImage,
	disabled,
	...props
}) => {
	const fileInput = useRef();
	const [file, setFile] = useState();
	const handleChange = (e) => {
		setFieldValue(name, e.target.files[0]);
	};
	const handleUpload = () => {
		fileInput.current.click();
	};
	useEffect(() => {
		if (value === null) {
			setFile(undefined);
		}

		if (value) {
			if (value instanceof File) {
				setFile(URL.createObjectURL(value));
			} else {
				setFile(value);
			}
		}
	}, [value]);

	return (
		<ImageUploadWrapper
			className={`form-group mb-0 ${className ? className : ''}`}
			disabled={disabled}
		>
			<input
				type="file"
				name={name}
				ref={fileInput}
				onChange={handleChange}
				accept="image/jpg,image/png,image/jpeg"
				disabled={disabled}
				hidden
			/>
			<div className="imageContainer" onClick={handleUpload}>
				{!file && (
					<div className="imageSelectionLabel">
						<p className="suggestionParagraph">
							Please upload a high quality photo of the front of
							your venue so customers can easily locate you.
						</p>
						<h5 className="sugesstion0">
							Click to select an image
						</h5>
						<p className="sugesstion1">PNG, JPG up to 5MB</p>

						<p className="suggestionParagraph pt-3">
							600 Width x 400 Height
						</p>
					</div>
				)}
				{file && (
					<img src={file ?? defaultImage} className="image" alt="" />
				)}

				{/* {file && (
					<span
						onClick={(e) => {
							e.stopPropagation();
							handleReset();
						}}
						className="icon"
					>
						{icon}
					</span>
				)} */}
			</div>
			{error && (
				<p className="fs-10 semi-bold-text headingTextColor pa-t-6">
					{errorMsg}
				</p>
			)}
		</ImageUploadWrapper>
	);
};

export default NewImageUpload;
