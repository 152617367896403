import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useFormik } from 'formik';

import * as yup from 'yup';

import CustomModal from '../Common/Modal/CustomModal';
import TableStyle from '../../Components/Common/TableStyle';
import TableV6 from '../../Components/Common/TableV6';
import FormInput from '../Form/FormInput';
import FormCheckBox from '../Form/FormCheckBox';

const RolesAndPermissionModal = ({ isOpen, handleModal, type, modalData }) => {
	const initialData = [
		{
			id: 1,
			name: 'User Management',
			add: false,
			view: false,
			edit: false,
			delete: false,
			status: false
		},
		{
			id: 2,
			name: 'Venue Management',
			add: false,
			view: false,
			edit: false,
			delete: false,
			status: false
		},
		{
			id: 3,
			name: 'Category Management',
			add: false,
			view: false,
			edit: false,
			delete: false,
			status: false
		},
		{
			id: 4,
			name: 'Promo Code Management',
			add: false,
			view: false,
			edit: false,
			delete: false,
			status: false
		},
		{
			id: 5,
			name: 'Template Management',
			add: false,
			view: false,
			edit: false,
			delete: false,
			status: false
		},
		{
			id: 6,
			name: 'Pages Management',
			add: false,
			view: false,
			edit: false,
			delete: false,
			status: false
		},
		{
			id: 7,
			name: 'Order Management',
			add: false,
			view: false,
			edit: false,
			delete: false,
			status: false
		},
		{
			id: 8,
			name: 'Transaction Management',
			add: false,
			view: false,
			edit: false,
			delete: false,
			status: false
		}
	];

	const [tableData, setTableData] = useState(initialData);

	const validationSchema = yup.object().shape({});

	const rolesAndPermissionFormik = useFormik({
		initialValues: {
			roleName: ''
		},
		validationSchema,
		onSubmit: () => {}
	});

	const columns = [
		{
			Header: 'Access',
			accessor: 'name',
			className: 'justify-content-start pl-10',
			filterable: false,
			sortable: false,
			minWidth: 200,
			headerClassName:
				'react-table-header-class fs-16 medium-text d-flex justify-content-start'
		},
		{
			Header: 'All',
			accessor: 'select_all',
			className: 'text-center',
			headerClassName: 'react-table-header-class fs-16 medium-text',
			sortable: false,
			filterable: false,
			width: 109,
			Cell: ({ row }) => (
				<FormCheckBox
					name="select_all"
					checked={row.view && row.edit && row.status ? true : false}
					onChange={(e) =>
						handleCheckBox(
							'select_all',
							row._original.id,
							e.target.checked
						)
					}
				/>
			)
		},
		{
			Header: 'View',
			accessor: 'view',
			className: 'text-center',
			filterable: false,
			sortable: false,
			width: 90,
			headerClassName: 'react-table-header-class fs-16 medium-text',
			Cell: ({ row }) => (
				<FormCheckBox
					name="view"
					checked={row.view && row.view}
					onChange={() => handleCheckBox('view', row._original.id)}
				/>
			)
		},
		{
			Header: 'Edit',
			accessor: 'edit',
			className: 'text-center',
			headerClassName: 'react-table-header-class fs-16 medium-text',
			sortable: false,
			filterable: false,
			width: 90,
			Cell: ({ row }) => (
				<FormCheckBox
					name="edit"
					checked={row.edit && row.edit}
					onChange={() => handleCheckBox('edit', row._original.id)}
				/>
			)
		},
		{
			Header: 'Active / Inactive',
			accessor: 'status',
			className: 'text-center',
			headerClassName: 'react-table-header-class fs-16 medium-text',
			sortable: false,
			filterable: false,
			width: 187,
			Cell: ({ row }) => (
				<FormCheckBox
					name="status"
					checked={row.status && row.status}
					onChange={() => handleCheckBox('status', row._original.id)}
				/>
			)
		}
	];

	const handleCheckBox = (permission, id, value) => {
		const tempPermissionData = tableData?.map((item) =>
			item.id === id
				? permission === 'select_all'
					? {
							...item,
							add: value,
							view: value,
							edit: value,
							delete: value,
							status: value
					  }
					: { ...item, [permission]: !item[permission] }
				: { ...item }
		);
		setTableData([...tempPermissionData]);
	};

	useEffect(() => {
		if (type === 'edit') {
			setTableData(modalData.permission);
			rolesAndPermissionFormik.setFieldValue('roleName', modalData.name);
		} else {
			setTableData(initialData);
			rolesAndPermissionFormik.setFieldValue('roleName', '');
		}
	}, [type, modalData]);

	return (
		<CustomModal
			title={`${
				type === 'add'
					? 'Add MyTab Staff Role'
					: 'Manage MyTab Staff Role'
			}`}
			size="xl"
			isOpen={isOpen}
			handleModal={handleModal}
		>
			<div>
				<form onSubmit={rolesAndPermissionFormik.handleSubmit}>
					<FormInput
						id="roleName"
						type="text"
						name="roleName"
						label="MyTab Staff Role Name"
						placeholder="MyTab Staff Role Name"
						value={rolesAndPermissionFormik.values.roleName}
						onChange={rolesAndPermissionFormik.handleChange}
						error={
							rolesAndPermissionFormik.touched.roleName &&
							!!rolesAndPermissionFormik.errors.roleName
						}
						errorMsg={rolesAndPermissionFormik.errors.roleName}
					/>
					<hr />
					<TableStyle version={6}>
						<TableV6
							columns={columns}
							data={tableData}
							handleSortBy={() => {}}
							key={'master-rep-table'}
						/>
					</TableStyle>
					<Row>
						<Col sm={12} className="text-center pt-3">
							<Button
								type="submit"
								className="fs-18 medium-text themeButton ptb-10 plr-60"
							>
								{`${
									type === 'add'
										? 'Add New Role'
										: 'Update Role'
								}`}
							</Button>
						</Col>
					</Row>
				</form>
			</div>
		</CustomModal>
	);
};

export default RolesAndPermissionModal;
