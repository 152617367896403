import React from 'react';
import NewVenueAccountForm from '../../../Components/UserProfile/ConnectVenue/NewVenueAccountForm';
import PageWrapper from '../../Authentication/register.style';
import createAccountImage from '../../../Assets/images/loginPageImg.png';
import Scrollbars from 'react-custom-scrollbars';
import NewVenuePortalStyle from './newVenuePortal';
import NewPortalProcess from '../../../Components/UserProfile/ConnectVenue/NewPortalProcess';

const CreateNewVenuePortal = () => {
	const newVenuePortalProcessList = [
		{
			pid: 1,
			title: `Create Your Account`
		},
		{
			pid: 2,
			title: `2F Authentication`
		},
		{
			pid: 3,
			title: ``
		},
		{
			pid: 4,
			title: ``
		},
		{
			pid: 5,
			title: ``
		},
		{
			pid: 6,
			title: ``
		},
		{
			pid: 7,
			title: ``
		}
	];

	return (
		<PageWrapper sectionImage={createAccountImage}>
			<div className="formWrapper pl-26">
				<Scrollbars autoHide>
					<div className="pr-46 pr-00">
						<NewVenueAccountForm />
					</div>
				</Scrollbars>
			</div>
			<div className="imageWrapper">
				<div className="backWrraper position-relative">
					<NewVenuePortalStyle>
						<div className="d-flex gap-1 position-absolute positionOfProcess parentWidth">
							{newVenuePortalProcessList.map((values, index) => (
								<NewPortalProcess
									hiddenLine={
										newVenuePortalProcessList.length - 1 ===
										index
									}
									current={values.pid === 1}
									checked={values.pid === 1}
									processName={values.title}
								/>
							))}
						</div>
					</NewVenuePortalStyle>
					<p className="text positionText">
						Manage your MyTab Venues in one place
					</p>
					<img
						src={createAccountImage}
						alt="side-img"
						className="image positionImage"
					/>
				</div>
			</div>
		</PageWrapper>
	);
};

export default CreateNewVenuePortal;
