import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import FormSwitch from '../../../Form/FormSwitch';
import TableStyle from '../../../Common/TableStyle';
import TableV6 from '../../../Common/TableV6';

const data = [
	{
		id: 1,
		printer_name: 'PNT-001 Mark',
		ip_address: '192.168.1.7',
		print_docket: <FormSwitch />,
		action: <span>Assign Subheading</span>
	},
	{
		id: 2,
		printer_name: 'TM-OP65',
		ip_address: '1925.168.1.7',
		print_docket: <FormSwitch />,
		action: 'Assign Subheading'
	},
	{
		id: 3,
		printer_name: 'TM-OP6-PP',
		ip_address: '192.168.2.1',
		print_docket: <FormSwitch />,
		action: 'Assign Subheading'
	}
];

const columns = [
	{
		Header: <span className="fs-14 medium-text pl-14">Printer Name</span>,
		accessor: 'printer_name',
		className: 'justify-content-start pl-24 fs-14 regular-text',
		minWidth: 150,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class justify-content-start',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'IP Address',
		accessor: 'ip_address',
		className: 'fs-14 regular-text',
		minWidth: 150,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-14 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Print Docket',
		accessor: 'print_docket',
		className: 'fs-14 regular-text',
		minWidth: 120,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-14 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Action',
		accessor: 'action',
		className: 'themeLinkText cursor-pointer fs-14 regular-text',
		minWidth: 120,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-14 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	}
];
const TableSkeleton = () => {
	return (
		<TableStyle version={6}>
			<TableV6 columns={columns} data={data} />
		</TableStyle>
	);
};

export default TableSkeleton;
