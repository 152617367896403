export const Filter = ({ fill, width, height, ...props }) => {
	return (
		<svg
			width={width ?? '24px'}
			height={height ?? '24px'}
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M8.99943 3.5H19.9994C20.2646 3.5 20.519 3.39464 20.7065 3.20711C20.8941 3.01957 20.9994 2.76522 20.9994 2.5C20.9994 2.23478 20.8941 1.98043 20.7065 1.79289C20.519 1.60536 20.2646 1.5 19.9994 1.5H8.99943C8.73421 1.5 8.47986 1.60536 8.29232 1.79289C8.10479 1.98043 7.99943 2.23478 7.99943 2.5C7.99943 2.76522 8.10479 3.01957 8.29232 3.20711C8.47986 3.39464 8.73421 3.5 8.99943 3.5ZM8.99943 8.5H15.9994C16.2646 8.5 16.519 8.39464 16.7065 8.20711C16.8941 8.01957 16.9994 7.76522 16.9994 7.5C16.9994 7.23478 16.8941 6.98043 16.7065 6.79289C16.519 6.60536 16.2646 6.5 15.9994 6.5H8.99943C8.73421 6.5 8.47986 6.60536 8.29232 6.79289C8.10479 6.98043 7.99943 7.23478 7.99943 7.5C7.99943 7.76522 8.10479 8.01957 8.29232 8.20711C8.47986 8.39464 8.73421 8.5 8.99943 8.5ZM8.99943 13.5H11.9994C12.2646 13.5 12.519 13.3946 12.7065 13.2071C12.8941 13.0196 12.9994 12.7652 12.9994 12.5C12.9994 12.2348 12.8941 11.9804 12.7065 11.7929C12.519 11.6054 12.2646 11.5 11.9994 11.5H8.99943C8.73421 11.5 8.47986 11.6054 8.29232 11.7929C8.10479 11.9804 7.99943 12.2348 7.99943 12.5C7.99943 12.7652 8.10479 13.0196 8.29232 13.2071C8.47986 13.3946 8.73421 13.5 8.99943 13.5ZM3.99943 0C3.73421 0 3.47986 0.105357 3.29232 0.292893C3.10479 0.48043 2.99943 0.734784 2.99943 1V16.586L1.70643 15.293C1.51783 15.1108 1.26523 15.01 1.00303 15.0123C0.740832 15.0146 0.49002 15.1198 0.304612 15.3052C0.119204 15.4906 0.014035 15.7414 0.0117566 16.0036C0.00947813 16.2658 0.110272 16.5184 0.292431 16.707L3.29243 19.707C3.38532 19.8001 3.49567 19.874 3.61716 19.9244C3.73865 19.9748 3.8689 20.0008 4.00043 20.0008C4.13197 20.0008 4.26221 19.9748 4.3837 19.9244C4.50519 19.874 4.61554 19.8001 4.70843 19.707L7.70843 16.707C7.89059 16.5184 7.99138 16.2658 7.9891 16.0036C7.98683 15.7414 7.88166 15.4906 7.69625 15.3052C7.51084 15.1198 7.26003 15.0146 6.99783 15.0123C6.73564 15.01 6.48303 15.1108 6.29443 15.293L4.99943 16.586V1C4.99943 0.734784 4.89407 0.48043 4.70654 0.292893C4.519 0.105357 4.26465 0 3.99943 0Z"
				fill={fill ?? '#FFF'}
			/>
		</svg>
	);
};
