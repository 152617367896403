import { FormGroup, Input, Label } from 'reactstrap';

const FormInput = ({
	id,
	label,
	error,
	errorMsg,
	standardVersion = false,
	className,
	formGroupClassName,
	disabled,
	style,
	minHeight = '40px',
	...rest
}) => {
	return (
		<FormGroup
			className={`mb-0 ${formGroupClassName ? formGroupClassName : ''}`}
		>
			{label && (
				<Label
					for={id}
					className="fs-12 medium-text themeText label-color ma-b-6"
				>
					{label}
				</Label>
			)}
			<Input
				{...rest}
				className={`${error ? 'is-invalid' : ''} inputBox ${
					minHeight === '40px' ? 'min-height-40' : ''
				} no-border fs-12 medium-text plr-12 ${
					className ? className : ''
				}`}
				disabled={disabled}
				style={{ ...style, minHeight: minHeight }}
			/>
			{standardVersion && error && <p className="error">{errorMsg}</p>}
			{!standardVersion && error && (
				<p className="fs-10 semi-bold-text headingTextColor pa-t-6">
					{errorMsg}
				</p>
			)}
		</FormGroup>
	);
};

export default FormInput;
