export const CameraIcon = ({ fill, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 18 17" fill="none">
			<path
				d="M5.0625 9.375C5.0625 7.23047 6.82031 5.4375 9 5.4375C11.1445 5.4375 12.9375 7.23047 12.9375 9.375C12.9375 11.5547 11.1445 13.3125 9 13.3125C6.82031 13.3125 5.0625 11.5547 5.0625 9.375ZM9 6.5625C7.41797 6.5625 6.1875 7.82812 6.1875 9.375C6.1875 10.957 7.41797 12.1875 9 12.1875C10.5469 12.1875 11.8125 10.957 11.8125 9.375C11.8125 7.82812 10.5469 6.5625 9 6.5625ZM12.7266 1.53516L13.1133 2.625H15.75C16.9805 2.625 18 3.64453 18 4.875V13.875C18 15.1406 16.9805 16.125 15.75 16.125H2.25C0.984375 16.125 0 15.1406 0 13.875V4.875C0 3.64453 0.984375 2.625 2.25 2.625H4.85156L5.23828 1.53516C5.44922 0.867188 6.08203 0.375 6.82031 0.375H11.1445C11.8828 0.375 12.5156 0.867188 12.7266 1.53516ZM2.25 3.75C1.61719 3.75 1.125 4.27734 1.125 4.875V13.875C1.125 14.5078 1.61719 15 2.25 15H15.75C16.3477 15 16.875 14.5078 16.875 13.875V4.875C16.875 4.27734 16.3477 3.75 15.75 3.75H12.3047L11.6719 1.88672C11.6016 1.67578 11.3906 1.5 11.1445 1.5H6.82031C6.57422 1.5 6.36328 1.67578 6.29297 1.88672L5.66016 3.75H2.25Z"
				fill={fill ?? 'white'}
			/>
		</svg>
	);
};
