import auth from './auth/reducer';
import ui from './ui/reducer';
import themeChanger from './themeChanger/reducer';
import themeSetting from './themeSettings/reducer';
import bar from './bar/reducers';

const reducers = {
	auth,
	ui,
	themeChanger,
	themeSetting,
	bar
};
export default reducers;
