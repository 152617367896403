import styled from 'styled-components';

const PageWrapper = styled.div`
	.customScrollableNavbar {
		overflow-x: scroll;
		scroll-behavior: smooth;
		margin: 0px;
		padding: 0px;
		&::-webkit-scrollbar {
			background: transparent; /* make scrollbar transparent */
			-webkit-appearance: none;
			width: 0;
			height: 0;
		}
		.rts___tabs {
			padding: 0px !important;
		}
		.rts___tabs___container {
			padding: 0px !important;
		}
		button {
			border: none;
			outline: none;
			background-color: #fff;
			box-shadow: none;
			border-radius: 0px !important;
			margin: 0;
			&:disabled {
				i {
					display: none;
				}
			}
		}
		.rts___left___nav___btn,
		.rts___right___nav___btn {
			border-bottom: 2px solid #ededed;
			max-width: 100%;
			padding: 12px 10px;
			border-radius: 0px !important;
		}
		.customScrollableNavItem {
			color: #bababa;
			padding: 8px 25px;
			white-space: nowrap;
			border-bottom: 2px solid #ededed;
			z-index: 2;
			cursor: pointer;
			&.active {
				color: ${(props) => props.layoutTheme.textColor};
				border-bottom: 2px solid
					${(props) => props.layoutTheme.headingColor};
			}
		}
		.borderBottom {
			border-bottom: 2px solid #ededed;
		}
	}
`;

export default PageWrapper;
