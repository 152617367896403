const convertNumToKM = (value) => {
	let convertedNum;
	if (value >= 1000 && value < 1000000) {
		convertedNum = (value / 1000)?.toFixed(2) + 'k';
	} else if (value >= 1000000) {
		convertedNum = (value / 1000000)?.toFixed(2) + 'm';
	} else {
		convertedNum = value;
	}
	return convertedNum;
};

export default convertNumToKM;
