import styled from 'styled-components';

const NewCustomModalWrapper = styled.div`
	.closeIcon {
		position: absolute;
		right: 14px;
		top: 5px;
		text-align: end;
	}

	.objectGif {
		object-fit: cover;
		margin-top: 30px;
		height: 300px;
		width: 200px;
	}
`;

export default NewCustomModalWrapper;
