import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import authActions from '../../Redux/auth/actions';
import CustomPopover2 from '../Common/Popover/CustomPopover2';
import { ThreeDotIcon } from '../Icons';

const RequiredOptionsSectionPopover = ({ name, setFieldValue, values }) => {
	const authData = useSelector((state) => ({ ...state.auth }));
	const dispatch = useDispatch();

	const [localValues, setLocalValues] = useState(
		JSON.parse(JSON.stringify(values[name])) // Deep copy values[name] when initializing
	);

	useEffect(() => {
		setLocalValues(JSON.parse(JSON.stringify(values[name]))); // Deep copy values[name] in useEffect
	}, [values, name]);

	return (
		<CustomPopover2
			button={
				<ThreeDotIcon
					height={18}
					width={18}
					fill="#242424"
					className="cursor-pointer"
				/>
			}
			placement="bottom-end"
		>
			<div className="d-flex flex-column pa-12">
				<div
					className="pa-b-12 themeText fs-12 medium-text cursor-pointer"
					onClick={() => {
						dispatch(
							authActions.copy_paste_required_options_section(
								JSON.parse(JSON.stringify(localValues)) // Deep copy before dispatch
							)
						);
					}}
				>
					Copy
				</div>
				<div
					className="themeText fs-12 medium-text cursor-pointer"
					onClick={() => {
						if (authData?.copiedRequiredOptionsSection !== null) {
							const updatedValues = [
								...localValues.map(item => JSON.parse(JSON.stringify(item))), // Deep copy local values
								...authData?.copiedRequiredOptionsSection.map(item => JSON.parse(JSON.stringify(item))) // Deep copy pasted values
							];

							setFieldValue(name, updatedValues); // Update form value
							setLocalValues(updatedValues); // Update local state
						}
					}}
				>
					Paste
				</div>
			</div>
		</CustomPopover2>
	);
};

export default RequiredOptionsSectionPopover;