import React, { useEffect, useState } from 'react';
import CustomPopover from '../../Common/Popover/CustomPopover';

const FilterPopover = ({
	handleToggle,
	isOpen,
	target,
	serviceType,
	setServiceType
}) => {
	const [selectedItem, setSelectedItem] = useState('All Menus');
	useEffect(() => {
		if (serviceType === 'TABLE') {
			setSelectedItem('Table Service Menu');
		} else if (serviceType === 'PICKUP') {
			setSelectedItem('Takeaway Menu');
		} else {
			setSelectedItem('All Menus');
		}
	}, [serviceType]);
	return (
		<CustomPopover
			handleToggle={handleToggle}
			isOpen={isOpen}
			target={target}
			placement="bottom-end"
		>
			<div className="pa-12">
				<div
					className={`pa-b-12 themeText fs-12 medium-text cursor-pointer ${
						selectedItem === 'Takeaway Menu'
							? 'activeColor'
							: 'themeText'
					}`}
					onClick={() => {
						setServiceType('PICKUP');
						handleToggle();
					}}
				>
					Takeaway Menu
				</div>
				<div
					className={`pa-b-12 themeText fs-12 medium-text cursor-pointer ${
						selectedItem === 'Table Service Menu'
							? 'activeColor'
							: 'themeText'
					}`}
					onClick={() => {
						setServiceType('TABLE');
						handleToggle();
					}}
				>
					Table Service Menu
				</div>
				<div
					className={`themeText fs-12 medium-text cursor-pointer ${
						selectedItem === 'All Menus'
							? 'activeColor'
							: 'themeText'
					}`}
					onClick={() => {
						setServiceType('BOTH');
						handleToggle();
					}}
				>
					All Menus
				</div>
			</div>
		</CustomPopover>
	);
};

export default FilterPopover;
