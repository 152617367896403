import Scrollbars from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';

import PageTitle from './PageTitle';
import PageWrapper from './page.style';

const PageStructure = ({
	children,
	pageText,
	buttonClassName,
	hideScroll,
	...rest
}) => {
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));

	return (
		<PageWrapper {...allThemeData}>
			<div className="page">
				<div className="page-header">
					<PageTitle buttonClassName={buttonClassName} {...rest} />
					{pageText && pageText}
				</div>
				<div className="page-body">
					{hideScroll && (
						<div className="pr-8 children-wrapper">{children}</div>
					)}
					{!hideScroll && (
						<Scrollbars
							renderTrackHorizontal={(props) => (
								<div {...props} className="track-horizontal" />
							)}
							renderThumbVertical={(props) => (
								<div {...props} className="thumb-horizontal" />
							)}
							renderTrackVertical={({ style, ...props }) => (
								<div
									{...props}
									style={{
										...style,
										zIndex: 5,
										position: 'absolute',
										width: '2px',
										right: '2px',
										bottom: '2px',
										top: '2px',
										borderRadius: '3px'
									}}
								/>
							)}
							autoHide
						>
							<div className="pr-8 children-wrapper">
								{children}
							</div>
						</Scrollbars>
					)}
				</div>
			</div>
		</PageWrapper>
	);
};

export default PageStructure;
