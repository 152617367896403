import { useSelector } from 'react-redux';
import CustomModal from '../../Common/Modal/CustomModal';

const QrCodeModal = ({ handleModal, isOpen }) => {
	const profileData = useSelector((state) => ({ ...state.auth }));

	return (
		<CustomModal
			isOpen={isOpen}
			handleModal={handleModal}
			title="Scan QR Code"
			size="sm"
		>
			<div className="d-flex justify-content-center">
				{profileData?.mfa_qr_code !== '' && (
					<img
						src={profileData?.mfa_qr_code}
						alt="MFA-QR COde"
						style={{
							height: 'auto',
							maxWidth: '180px',
							width: '180px'
						}}
					/>
				)}
			</div>
		</CustomModal>
	);
};

export default QrCodeModal;
