import { useQuery } from 'react-query';
import Api from '../Helper/Api';

export const useCachedData = (apiReqType, apiRoute, payload) => {
	const data = useQuery(apiRoute, async () => {
		const res = await Api(apiReqType, apiRoute, payload);
		return res.data;
	});
	return data;
};
