import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import LandingWrraper from './index.style';
import CustomButton from '../../Components/Common/CustomButton';
import { useNavigate } from 'react-router-dom/dist';
import { VenueProtectedRoutes } from '../../Utils/routes';

const Landing = () => {
	const allthemeData = useSelector((state) => ({ ...state.themeChanger }));
	const navigate = useNavigate();

	const preVenueProcess = useSelector(
		(state) => state?.auth?.preVenueProcess
	);

	// useEffect(() => {
	// 	if (preVenueProcess) {
	// 		navigate(VenueProtectedRoutes.connectVenue, { replace: true });
	// 	}
	// }, [navigate, preVenueProcess]);
	return (
		<LandingWrraper {...allthemeData}>
			<div className="d-flex h-100 MainDiv">
				<div className="flex-1 d-flex align-items-center">
					<div className="DetailSection">
						<h1 className="IntroHeading">
							Manage your venue anywhere, anytime.
						</h1>
						<p className="IntroParagraph">
							Meet MyTab’s management portal, where you can manage
							all of your MyTab Venues in one place.
						</p>
						<div className="ButtonStack">
							<CustomButton
								className={'LoginButton'}
								onClick={() => navigate('/login')}
							>
								Log In
							</CustomButton>
							<CustomButton
								className={'CreatePortalButton'}
								onClick={() => navigate('/register')}
							>
								Create Your Management Portal
							</CustomButton>
						</div>
					</div>
				</div>
				<div className="d-none  flex-1 d-md-none d-lg-flex d-xl-flex d-xxl-flex align-items-center justify-content-end">
					<div className="LandingHeroAssetView"></div>
				</div>
			</div>
		</LandingWrraper>
	);
};

export default Landing;
