import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import ModalWrapper from './modal.style';
import { CancelIcon } from '../../Icons';
import Scrollbars from 'react-custom-scrollbars';
import NewCustomModalWrapper from './newCustomModal.style';

const NewCustomModal = ({
	isOpen,
	handleModal,
	title,
	autoHeightMin,
	autoHeightMax,
	children,
	modalClassName,
	modalFooter,
	modalBodyClassName,
	hideCloseButton = false,
	onClosed,
	titleTextSize = 24,
	...rest
}) => {
	const allThemeData = useSelector((state) => ({
		...state.themeChanger
	}));

	return (
		<Modal
			autoFocus={false}
			isOpen={isOpen}
			toggle={() => handleModal(!isOpen)}
			className={`contentModal ${modalClassName ?? ''}`}
			centered={true}
			id="CustomModal"
			backdrop="static"
			onClosed={onClosed}
			{...rest}
		>
			<ModalBody
				className={`${modalBodyClassName} overflow-auto plr-50 pa-b-25 pa-t-25`}
				id="adfdaf"
			>
				<NewCustomModalWrapper>
					{!hideCloseButton && (
						<div
							className="closeIcon"
							onClick={() => handleModal(!isOpen)}
						>
							<CancelIcon
								className="cursor-pointer"
								height={24}
								width={24}
								roundBorder
							/>
						</div>
					)}

					<div
						className={`content-title d-block text-center themeText fs-${titleTextSize} bold-text`}
					>
						{title && title}
					</div>

					<div>
						<Scrollbars
							autoHide
							autoHeight
							autoHeightMin={autoHeightMin}
							autoHeightMax={autoHeightMax ?? 480}
							renderTrackVertical={({ style, ...props }) => (
								<div
									{...props}
									style={{
										...style,
										zIndex: 5,
										position: 'absolute',
										width: '2px',
										right: '2px',
										bottom: '2px',
										top: '2px',
										borderRadius: '3px'
									}}
								/>
							)}
							className="m-0"
						>
							<div className="w-100 h-100">{children}</div>
						</Scrollbars>

						<div className="d-flex align-items-center justify-content-center">
							{modalFooter}
						</div>
					</div>
				</NewCustomModalWrapper>
			</ModalBody>
		</Modal>
	);
};

export default NewCustomModal;
