import styled from 'styled-components';

export const HeaderWrapper = styled.div`
	color: ${(props) => props.layoutTheme.textColor} !important;
	.customScrollableNavbar {
		overflow-x: scroll;
		scroll-behavior: smooth;
		margin: 0px;
		padding: 0px;
		&::-webkit-scrollbar {
			background: transparent;
			-webkit-appearance: none;
			width: 0;
			height: 0;
		}
		.rts___tabs {
			padding: 0px !important;
		}
		.rts___tabs___container {
			padding: 0px !important;
		}
		button {
			border: none;
			outline: none;
			background-color: #fff;
			box-shadow: none;
			border-radius: 0px !important;
			margin: 0;
			&:disabled {
				i {
					display: none;
				}
			}
		}
		.rts___left___nav___btn,
		.rts___right___nav___btn {
			border-bottom: 2px solid #ededed;
			max-width: 100%;
			padding: 12px 10px;
			border-radius: 0px !important;
		}
		.customScrollableNavItem {
			color: #bababa;
			padding: 8px 25px;
			white-space: nowrap;
			border-bottom: 2px solid #ededed;
			z-index: 2;
			cursor: pointer;
			&.active {
				color: ${(props) => props.layoutTheme.textColor};
				border-bottom: 2px solid
					${(props) => props.layoutTheme.headingColor};
			}
		}
		.borderBottom {
			border-bottom: 2px solid #ededed;
		}
	}
	.customSelect2__dropdown-indicator {
		flex: 1;
	}
	.dashboard-dropdown {
		.activeColor {
			color: ${(props) => props.layoutTheme.buttonColor3} !important;
		}
		.dropdown-item,
		.dropdownToggle {
			outline: 0 !important;
			border: 0px !important;
			padding-left: 10px;
			padding-right: 10px;
			&.active {
				background-color: ${(props) =>
					props.layoutTheme.buttonColor3} !important;
			}
			@media only screen and (max-width: 575.98px) {
				padding-top: 5px;
				margin-top: 6px;
				margin-bottom: 6px;
			}
			.dropdown-name {
				padding-right: 5px;
				color: ${(props) => props.layoutTheme.textColor};
			}
			&:hover {
				outline: 0 !important;
				border: 0px !important;
			}
			&:active {
				outline: 0 !important;
				border: 0px !important;
				color: ${(props) => props.layoutTheme.textColor};
			}
		}
		.categoryDropdownToggle {
			background: transparent !important;
			border: 1px solid #f94d73 !important;
			border-radius: 6px !important;
			height: 40px !important;
			&:hover {
				outline: 0 !important;
				border: 1px solid #f94d73 !important;
			}
			&:active {
				outline: 0 !important;
				border: 1px solid #f94d73 !important;
				color: ${(props) => props.layoutTheme.textColor};
			}
			width: 198px !important;
			min-width: 198px !important;
			@media only screen and (max-width: 1099px) {
				width: 100% !important;
			}
		}
		.dropdownToggle {
			padding-left: 12px !important;
			padding-right: 12px !important;
		}
		.dropdown-menu {
			border: 1px solid #eaeaea !important;
			border-radius: 6px !important;
			@media only screen and (max-width: 360px) {
			}
		}
	}
	.categoryDropdown {
		@media only screen and (max-width: 1099px) {
			width: 100% !important;
		}
	}
	.dateDropdownBox {
	}
	.orderTypeDropdownBox {
	}
	.dropdown-item.dropdownCalender:hover {
		background-color: #fff;
	}
	.dropdownCalenderParent {
		border-right: 1px solid #eaeaea;
		margin-right: 15px;
		padding-right: 10px;
		@media only screen and (max-width: 575px) {
			border-right: 0px;
			margin-right: 0px;
			padding-right: 0px;
		}
	}
	.datePickerDropdown {
		max-height: 300px;
		overflow: auto;
		margin-top: 4px;
		z-index: 100 !important;
	}
	.calenderItem {
		font-family: 'montserrat-medium';
		font-size: 12px !important;
		border-radius: 6px !important;
		padding-top: 8px;
		padding-bottom: 8px;
		&:active {
			background-color: ${(props) =>
				props.layoutTheme.buttonColor3} !important;
			color: white !important;
		}
	}
	.categoryItem {
		font-family: 'montserrat-medium';
		font-size: 12px !important;
		padding: 7px 24px;
		&:active {
			background-color: ${(props) =>
				props.layoutTheme.buttonColor3} !important;
			color: white !important;
		}
	}
	.dateDropdown {
		@media only screen and (max-width: 1099px) {
			width: 100% !important;
		}
	}
	.dateDropdownToggle {
		background: #f9f9f9;
		border-radius: 6px;
		height: 40px !important;
		min-height: 40px !important;
		width: 198px !important;
		&:active {
			background-color: #f9f9f9 !important;
		}
		@media only screen and (max-width: 1099px) {
			width: 100% !important;
		}
	}
	.themeButton {
		padding-block: 0 !important;
		padding-inline: 0 !important;
		height: 40px !important;
		width: 198px !important;
		min-width: 198px !important;
		font-size: 11px !important;
		@media only screen and (max-width: 1099px) {
			width: 100% !important;
		}
	}
	.searchBoxWrapper {
		width: 198px !important;
		@media only screen and (max-width: 1099px) {
			width: 100% !important;
		}
	}
	.filterWrapper {
		display: flex;
		flex-direction: row !important;
		justify-content: space-between;
		align-items: center;
		gap: 24px !important;
		@media only screen and (max-width: 1099px) {
			flex-direction: column !important;
			align-items: unset !important;
			gap: 8px !important;
		}
	}
	.threeButtonWrapper {
		display: flex;
		flex-direction: row !important;
		align-items: center;
		gap: 24px !important;
		@media only screen and (max-width: 1099px) {
			flex-direction: column !important;
			align-items: unset !important;
			gap: 8px !important;
		}
	}
`;
