import styled from 'styled-components';

const ModalWrapper = styled.div`
	.draggableMenuCard {
		border: 1px solid #dddddd;
		border-radius: 6px;
	}
`;

export default ModalWrapper;
