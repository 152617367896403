import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import TableStyle from '../../../Common/TableStyle';
import TableV6 from '../../../Common/TableV6';

const data = [
	{
		id: 1,
		name: '50% Off on new orders',
		code: '50NEW',
		discount: '8',
		expiry_date: '2023/02/01',
		status: true
	},
	{
		id: 2,
		name: '20% Flat Off on Referrals',
		code: '20REFR',
		discount: '12',
		expiry_date: '2023/08/01',
		status: true
	},
	{
		id: 3,
		name: 'Flat 10& Cashback on Friend Share',
		code: 'FRND10',
		discount: '3',
		expiry_date: '2023/12/01',
		status: true
	},
	{
		id: 4,
		name: '50% Off on new orders',
		code: '50NEW',
		discount: '8',
		expiry_date: '2023/02/01',
		status: true
	},
	{
		id: 5,
		name: '20% Flat Off on Referrals',
		code: '20REFR',
		discount: '12',
		expiry_date: '2023/08/01',
		status: true
	},
	{
		id: 6,
		name: 'Flat 10& Cashback on Friend Share',
		code: 'FRND10',
		discount: '3',
		expiry_date: '2023/12/01',
		status: true
	},
	{
		id: 7,
		name: 'Flat 10& Cashback on Friend Share',
		code: 'FRND10',
		discount: '3',
		expiry_date: '2023/12/01',
		status: true
	},
	{
		id: 8,
		name: '50% Off on new orders',
		code: '50NEW',
		discount: '8',
		expiry_date: '2023/02/01',
		status: true
	}
];

const tableColumns = [
	{
		Header: <span className="fs-12 medium-text">Name</span>,
		accessor: 'name',
		className: 'justify-content-start fs-12 regular-text',
		filterable: false,
		sortable: false,
		minWidth: 250,
		headerClassName: 'react-table-header-class justify-content-start',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Code',
		accessor: 'code',
		className: 'fs-12 regular-text',
		filterable: false,
		sortable: false,
		minWidth: 100,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Discount Amount (%)',
		accessor: 'discount',
		className: 'fs-12 regular-text',
		filterable: false,
		sortable: false,
		minWidth: 180,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Expiry Date',
		accessor: 'expiry_date',
		className: 'fs-12 regular-text',
		filterable: false,
		sortable: false,
		minWidth: 130,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Status',
		accessor: 'status',
		className: 'text-dark',
		filterable: false,
		sortable: false,
		minWidth: 120,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Action',
		accessor: 'action',
		className: 'text-dark',
		filterable: false,
		sortable: false,
		minWidth: 140,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	}
];

const TableSkeleton = () => {
	return (
		<TableStyle version={6}>
			<TableV6 columns={tableColumns} data={data} />
		</TableStyle>
	);
};

export default TableSkeleton;
