import { useEffect, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Col, Row } from 'reactstrap';
import FormInput from '../../Form/FormInput';
import { CircleMinusIcon, HamburgerIcon } from '../../Icons';
import FormInputGroup from '../../Form/FormInputGroup';

const RequiredOptionInput = (props) => {
	const {
		isDragging,
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition
	} = useSortable({ id: props?.id });
	const style = {
		transform: CSS.Transform.toString(transform),
		transition: transition || undefined
	};
	const [itemName, setItemName] = useState('');
	const [price, setPrice] = useState('');
	const handleDeleteItem = () => {
		props.handleDeletedRequiredOptionsItem(
			props.values[props.name][props.outerIndex].data[props.innerIndex]
				?.itemId
		);
		let updatedRequiredOptions = [...props.values[props.name]];
		let innerData = [...updatedRequiredOptions[props.outerIndex]?.data];
		innerData.splice(props.innerIndex, 1);
		updatedRequiredOptions[props.outerIndex].data = innerData;
		if (updatedRequiredOptions?.[props?.outerIndex]?.data?.length > 0) {
			props.setFieldValue(props.name, updatedRequiredOptions);
		} else {
			props.handleDeletedRequiredOptions(
				updatedRequiredOptions?.[props?.outerIndex]
			);
			let items = updatedRequiredOptions;
			items.splice(props?.outerIndex, 1);
			props.setFieldValue(props.name, [...items]);
		}
	};
	useEffect(() => {
		if (props?.values?.[props.name]?.[props.outerIndex]?.data?.length > 0) {
			setItemName(
				props.values[props.name][props.outerIndex].data[
					props.innerIndex
				]?.itemName
			);
			setPrice(
				props.values[props.name][props.outerIndex].data[
					props.innerIndex
				]?.price
			);
		}
	}, [
		props.values,
		props?.values?.[props.name]?.[props.outerIndex]?.data?.length,
		props.values[props.name][props.outerIndex].data[props.innerIndex]
			?.itemName,
		props.values[props.name][props.outerIndex].data[props.innerIndex]?.price
	]);
	return (
		<div
			className="d-flex align-items-center bg-white pa-b-14"
			ref={setNodeRef}
			style={{ ...style, gap: '18px' }}
		>
			<Row className="flex-15" style={{ '--bs-gutter-x': '18px' }}>
				<Col xs={6}>
					<FormInput
						type="text"
						name={`${props.name}[${props.outerIndex}].data[${props.innerIndex}].itemName`}
						label="Extra Item"
						placeholder="Enter Extra Item"
						value={
							props.values[props.name][props.outerIndex].data[
								props.innerIndex
							]?.itemName
						}
						onChange={props.handleChange}
						error={
							props.touched?.[props.name]?.[props.outerIndex]
								?.data?.[props.innerIndex]?.itemName &&
							!!props.errors?.[props.name]?.[props.outerIndex]
								?.data?.[props.innerIndex]?.itemName
						}
						errorMsg={
							props.errors?.[props.name]?.[props.outerIndex]
								?.data?.[props.innerIndex]?.itemName
						}
						disabled={props.posStatus}
					/>
				</Col>
				<Col xs={6}>
					<FormInputGroup
						type="number"
						name={`${props.name}[${props.outerIndex}].data[${props.innerIndex}].price`}
						label="Price"
						placeholder="Enter Price"
						value={
							props.values[props.name][props.outerIndex].data[
								props.innerIndex
							]?.price
						}
						onChange={props.handleChange}
						error={
							props.touched?.[props.name]?.[props.outerIndex]
								?.data?.[props.innerIndex]?.price &&
							!!props.errors?.[props.name]?.[props.outerIndex]
								?.data?.[props.innerIndex]?.price
						}
						errorMsg={
							props.errors?.[props.name]?.[props.outerIndex]
								?.data?.[props.innerIndex]?.price
						}
						icon={<span className="fs-14">$</span>}
						iconPlacement="start"
						className={'pl-0'}
						iconBackgroundClass={'inputGroupBackground'}
						onBlur={() => {
							props.setFieldValue(
								`${props.name}[${props.outerIndex}].data[${props.innerIndex}].price`,
								Number(
									props.values[props.name][props.outerIndex]
										.data[props.innerIndex]?.price
								).toFixed(2)
							);
						}}
						onWheel={(e) => e.target.blur()}
						disabled={props.posStatus}
					/>
				</Col>
			</Row>
			<div className="flex-1 d-flex align-items-center justify-content-center">
				<CircleMinusIcon
					height={14}
					width={14}
					fill={'#F94D73'}
					className="mt-28 cursor-pointer"
					onClick={() => handleDeleteItem()}
				/>
			</div>
			<div className="flex-1 d-flex align-items-center justify-content-center">
				<HamburgerIcon
					height={18}
					width={18}
					fill={'#BABABA'}
					className="mt-28 cursor-pointer outline-none"
					{...attributes}
					{...listeners}
					style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
				/>
			</div>
		</div>
	);
};

export default RequiredOptionInput;
