import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import { FormSelectWrapper } from './formSelect.style';

const FormSelect = ({
	id,
	name,
	label,
	value,
	onChange,
	error,
	errorMsg,
	className,
	options,
	placeholder,
	maxMenuHeight,
	isLoading = false,
	isSearchable = false,
	disabled = false,
	...rest
}) => {
	return (
		<FormGroup className="mb-0">
			{label && (
				<Label
					for={id}
					className="fs-12 medium-text themeText label-color ma-b-6"
				>
					{label}
				</Label>
			)}
			<FormSelectWrapper>
				<Select
					name={name}
					value={value}
					options={options}
					onChange={onChange}
					placeholder={placeholder ?? 'Please Select'}
					isLoading={isLoading}
					className={`${error ? 'is-invalid' : ''} ${
						className ? className : ''
					} themeSelect min-height-40 inputBox no-border border-radius-0 fs-12 medium-text`}
					components={{
						IndicatorSeparator: () => null
					}}
					maxMenuHeight={maxMenuHeight ?? 150}
					styles={{
						control: (baseStyles, state) => ({
							...baseStyles,
							boxShadow: 'none !important',
							borderColor: '#FFF !important',
							minHeight: '40px',
							borderRadius: '6px',
							backgroundColor: '#F9F9F9 !important',
							color: state.isDisabled ? 'red' : 'white'
						}),
						valueContainer: (baseStyles, state) => ({
							...baseStyles,
							fontFamily: 'montserrat-medium',
							fontWeight: '500',
							fontSize: '12px'
						}),
						option: (baseStyles, state) => {
							return {
								...baseStyles,
								'&:hover': {
									background: state.isSelected
										? '#FF5F5F !important'
										: 'rgba(0,0,0,0.1) !important',
									color: state.isSelected
										? '#FFFFFF !important'
										: '#000000 !important'
								},
								background: state.isSelected
									? '#FF5F5F !important'
									: '#FFFFFF !important',
								color: state.isSelected
									? '#FFFFFF !important'
									: '#242424 !important',
								fontFamily: 'montserrat-medium',
								fontWeight: '500',
								fontSize: '12px',
								margin: '0px'
							};
						},
						input: (baseStyles, state) => ({
							...baseStyles,
							fontFamily: 'montserrat-medium',
							fontWeight: '500',
							fontSize: '12px'
						}),
						menu: (baseStyles, state) => ({
							...baseStyles,
							marginLeft: '1px',
							width: '98%'
						}),
						placeholder: (baseStyles) => ({
							...baseStyles,
							color: 'gray',
							opacity: '0.6',
							fontFamily: 'montserrat-medium',
							fontSize: '12px'
						}),
						singleValue: (baseStyles) => ({
							...baseStyles,
							fontFamily: 'montserrat-medium',
							fontWeight: '500',
							fontSize: '12px',
							color: '#242424'
						})
					}}
					menuShouldScrollIntoView={false}
					isSearchable={isSearchable}
					isDisabled={disabled}
				/>
			</FormSelectWrapper>
			{error && (
				<p className="fs-10 semi-bold-text headingTextColor pa-t-6">
					{errorMsg}
				</p>
			)}
		</FormGroup>
	);
};

export default FormSelect;
