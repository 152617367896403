import styled from 'styled-components';
import sectionImage from '../../Assets/images/section-common-back.png';

const PageWrapper = styled.div`
	width: 100%;
	height: 100%;
	/* new design styles */
	display: flex;
	.pageContainer {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 71px;
	}

	.formSideSpace {
		padding-right: 26px;
	}

	.positionImage {
		position: absolute;
		transform: translate(0%, 15%);
	}

	.imageWrapper {
		flex: 1;
		display: flex;

		background: #fff !important;

		.text {
			max-width: 614px;
			font-family: 'montserrat-bold';
			font-size: 55px;
			color: #fff;
			text-align: center;
		}
		.image {
			width: 558px;
			height: 528px;
		}
		@media only screen and (max-width: 1199px) {
			display: none !important;
		}
	}

	.backWrraper {
		height: 100%;
		width: 100%;
		display: flex;
		gap: 29px;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background: url(${sectionImage});

		background-size: 100% 100% !important;
		background-repeat: no-repeat;
	}
	.formCardContainer {
		display: flex;
		align-items: center;
		height: 100%;
		padding: 4px;
		overflow-y: scroll;
		&::-webkit-scrollbar {
			display: none;
		}
		@media only screen and (max-width: 556px) {
			padding: 12px;
		}
		.formCard {
			width: 516px !important;
			background: #ffffff;
			box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
			border-radius: 12px;
			padding: 30px 58px;
			.cmsLogoWrapper {
				width: 130px;
				.cmsLogo {
					width: 100%;
					height: 100%;
					display: block;
				}
			}
			@media only screen and (max-width: 556px) {
				width: 100% !important;
				padding: 30px 28px;
			}
			.formGroupIcon {
				.border-radius-0 {
					border-radius: 0 !important;
				}
				.inputBoxGroup {
					border-radius: 6px 0 0 6px !important;
				}
				.input-group-text {
					border-radius: 0 6px 6px 0 !important;
				}
			}
		}
		.horizontalRule {
			border: 1px solid #eaeaea;
		}
	}

	.positionText {
		position: absolute;
		top: 70px;
	}

	@media (max-width: 600px) {
		.imageWrapper {
			display: none;
		}
		.formWrapper {
			padding: 0 20px !important;
		}
		.formSideSpace {
			padding-right: 0px !important;
		}
	}

	@media only screen and (min-width: 600px) and (max-width: 960px) {
		.imageWrapper {
			display: none;
		}
		.formWrapper {
			padding: 0 50px !important;
		}
		.formSideSpace {
			padding-right: 0px !important;
		}
	}

	@media screen and (max-height: 933px) {
		.imageWrapper {
			.text {
				font-size: 30.9px;
				max-width: 359.4px;
			}
			.image {
				width: 313.8px;
				height: 297px;
			}
		}
	}

	@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 800px) {
		.imageWrapper {
			.text {
				// font-size: 30.9px;
				// max-width: 343.4px;
				font-size: 40px;
				max-width: 460.4px;
			}
			.image {
				// width: 313.8px;
				// height: 297px;
				width: 350.8px;
				height: 330px;
			}
		}
		.positionText {
			position: absolute;
			top: 54px;
		}

		.positionImage {
			position: absolute;
			transform: translate(0%, 14%);
		}
	}
`;

export default PageWrapper;
