export const timeBoxDetails = [
	{
		id: 1,
		time: '00:05',
		name: '5 mins'
	},
	{
		id: 2,
		time: '00:10',
		name: '10 mins'
	},
	{
		id: 3,
		time: '00:15',
		name: '15 mins'
	},
	{
		id: 4,
		time: '00:20',
		name: '20 mins'
	},
	{
		id: 5,
		time: '00:30',
		name: '30 mins'
	},
	{
		id: 6,
		time: '00:45',
		name: '45 mins'
	},
	{
		id: 7,
		time: '01:00',
		name: '1 hour'
	},
	{
		id: 8,
		time: '01:30',
		name: '1.5 hour'
	},
	{
		id: 9,
		time: '02:00',
		name: '2 hour'
	}
];
