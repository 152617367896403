import styled from 'styled-components';

const PopoverWrapper = styled.div`
	position: relative;
	.popover-content {
		border-radius: 4px;
		background-color: white;
		position: absolute;
		right: ${(props) => (props.placement === 'bottom-end' ? '4px' : '')};
		border: 1px solid #eaeaea !important;
		z-index: 10 !important;
		width: max-content !important;
	}
`;

export default PopoverWrapper;
