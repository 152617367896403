import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import CustomModal from '../../Common/Modal/CustomModal';
import FormInput from '../../Form/FormInput';
import * as validation from '../../../Helper/YupValidation';
import CustomButton from '../../Common/CustomButton';
import Api from '../../../Helper/Api';
import { VenueApiRoutes } from '../../../Utils/routes';

const PickupLocationModal = ({
	isOpen,
	handleModal,
	modalData,
	getPickupLocationList
}) => {
	const authData = useSelector((state) => ({ ...state.auth }));
	const [loading, setLoading] = useState(false);
	let isEdit = modalData?.type === 'edit';
	const validationSchema = yup.object().shape({
		name: validation.YUP_VALIDATION.PICKUP_LOCATION_NAME
	});
	const {
		handleSubmit,
		touched,
		errors,
		handleChange,
		values,
		resetForm,
		handleBlur,
		setFieldValue
	} = useFormik({
		initialValues: {
			name: ''
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			let url = !isEdit
				? VenueApiRoutes.addPickupLocation
				: VenueApiRoutes.editPickupLocation;
			let payload = !isEdit
				? {
						bar_id: authData?.selectedVenue?.id?.toString(),
						address: values?.name
				  }
				: {
						bar_id: authData?.selectedVenue?.id?.toString(),
						address: values?.name,
						id: modalData?.data?.id?.toString()
				  };
			try {
				setLoading(true);
				const res = await Api('POST', url, payload);
				setLoading(false);
				if (res?.data?.status) {
					resetForm();
					toast.success(res?.data?.message);
					handleModal();
					await getPickupLocationList();
				} else {
					toast.error(res?.data?.message);
				}
			} catch (err) {
				setLoading(false);
				if (err?.response?.data?.message) {
					toast.error(err?.response?.data?.message);
				}
			}
		}
	});
	useEffect(() => {
		if (modalData?.data?.address) {
			setFieldValue('name', modalData?.data?.address);
		} else {
			setFieldValue('name', '');
		}
	}, [modalData, modalData?.data, modalData?.data?.address]);
	return (
		<CustomModal
			title={`${
				modalData?.type === 'edit'
					? 'Edit Pick Up Location'
					: 'Add Pick Up Location'
			}`}
			size="md"
			isOpen={isOpen}
			handleModal={handleModal}
		>
			<form className="overflow-hidden" onSubmit={handleSubmit}>
				<FormInput
					type="text"
					name="name"
					label="Pick Up Location Name"
					placeholder="Enter Pick Up Location Name"
					value={values?.name}
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.name && !!errors.name}
					errorMsg={errors.name}
				/>
				<div className="pa-t-18 w-100">
					<CustomButton
						type="submit"
						className="themeButtonFullWidth"
						loading={loading}
					>
						Save
					</CustomButton>
				</div>
			</form>
		</CustomModal>
	);
};

export default PickupLocationModal;
