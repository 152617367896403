import styled from 'styled-components';

const PageWrapper = styled.div`
	.activeText {
		color: ${(props) => props.layoutTheme.buttonColor3} !important;
		text-decoration: underline;
	}
`;

export default PageWrapper;
