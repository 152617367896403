const Rearrange = ({ fill, stroke, width, height, onClick, ...props }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			onClick={onClick}
			{...props}
		>
			<rect width="18.1622" height="2.59459" fill={fill ?? 'white'} />
			<rect
				y="5.83789"
				width="18.1622"
				height="2.59459"
				fill={fill ?? 'white'}
			/>
			<rect
				y="11.6758"
				width="18.1622"
				height="2.59459"
				fill={fill ?? 'white'}
			/>
			<rect
				x="21.4055"
				width="2.59459"
				height="2.59459"
				fill={fill ?? 'white'}
			/>
			<rect
				x="21.4055"
				y="5.83789"
				width="2.59459"
				height="2.59459"
				fill={fill ?? 'white'}
			/>
			<rect
				x="21.4055"
				y="11.6758"
				width="2.59459"
				height="2.59459"
				fill={fill ?? 'white'}
			/>
		</svg>
	);
};

export default Rearrange;
