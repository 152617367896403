import styled from 'styled-components';

const PageWrapper = styled.div`
	background-color: #fffdfd;
	color: ${(props) => props.layoutTheme.textColor} !important;
	padding: 0px;
	@media only screen and (min-width: 576px) {
		padding: 16px;
		padding-top: 4px;
	}
	@media only screen and (min-width: 768px) {
		padding: 24px;
		padding-top: 12px;
	}
	.defaultBoxShadow {
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
	}
	@media only screen and (max-width: 767px) {
		padding: 12px;
		.border-radius-16 {
			border-radius: 0px !important;
		}
		.defaultBoxShadow {
			box-shadow: unset;
		}
	}
	.chartContainer {
		.row {
			--bs-gutter-x: 24px !important;
			--bs-gutter-y: 18px !important;
		}
	}
`;

export default PageWrapper;
