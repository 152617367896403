import React, { useEffect, useState } from 'react';
import createAccountImage from '../../Assets/images/powerd-by-stripe.png';
import PageWrapper from './createStripeAccount.style';
import Agreement from '../../Components/Authentication/Agreement';
import CustomButton from '../../Components/Common/CustomButton';
import StripeVerifyOtpModal from './StripeVerifyOtpModal';
import StripeVerifyProcessing from './StripeVerifyProcessing';
import Api from '../../Helper/Api';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import barActions from '../../Redux/bar/actions';
import { useSelector } from 'react-redux';
import { VenueProtectedRoutes } from '../../Utils/routes';
import authActions from '../../Redux/auth/actions';
import Scrollbars from 'react-custom-scrollbars';

const CreateStripeAccount = () => {
	const [loadingSetup, setLoadingSetUp] = useState(false);
	const [loading, setLoading] = useState(false);
	const [otpModalShow, setOtpModalShow] = useState(false);
	const [processingModalShow, setProcessingModalShow] = useState(false);
	const [processLoading, setProcessLoading] = useState(false);
	const { state } = useLocation();
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const preVenueProcess = useSelector(
		(state) => state?.auth?.preVenueProcess
	);
	const fetchBarId = useSelector((state) => state.bar?.bar_id);

	const emailVerification = async () => {
		try {
			setLoading(true);

			const res = await Api('POST', '/venue/bar/send-venue-otp', {
				bar_id: fetchBarId
			});

			if (res?.data?.status) {
				setLoading(false);
				toast.success(res?.data?.message);
			} else {
				toast.error(res?.data?.message || 'Something went wrong');
				setLoading(false);
			}
		} catch (err) {
			setLoading(false);
			toast.error(err?.message ?? 'Something went wrong');
		}
	};

	const connectingToBank = async () => {
		try {
			dispatch(barActions.setBarId(state?.data?.id));
			const res = await Api(
				'POST',
				'/venue/bar/check-stripe-integration',
				{
					bar_id: fetchBarId
				}
			);
			if (res?.data?.status) {
				if (res?.data?.data?.isRegister) {
					setProcessingModalShow(true);
					let popUpWindow = window.open(
						res?.data?.data?.url,
						'authWin',
						'width=768px,height=1024px'
					);
					var timer = setInterval(function () {
						if (popUpWindow.closed) {
							clearInterval(timer);
							setProcessingModalShow(false);
							navigate('/stripe-account-process', {
								replace: true
							});
							dispatch(
								authActions.prevenue_account_process_change(
									'/stripe-account-process'
								)
							);
						}
					}, 1000);
				}
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setProcessLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};

	useEffect(() => {
		if (!fetchBarId) {
			if (state !== null) {
				dispatch(barActions.setBarId(state?.data?.id));
			}
		}
	}, [fetchBarId, state]);

	const connectBankDetail = async () => {
		if (fetchBarId) {
			setOtpModalShow(true);
			await emailVerification();
		}
	};

	return (
		<PageWrapper>
			<div className="formWrapper pl-26 pr-85 pr-xl-[80px]">
				<Scrollbars autoHide>
					<div>
						<h1 className="headingStyle maxHeading stripeHeading">
							{loadingSetup
								? `Congratulations on your bank account has been set up
							with stripe`
								: `Create your Stripe account to start receiving payments`}
						</h1>
						<p className="smallInfo">
							After successfully setting up and connecting your
							Stripe standard account to MyTab, you will be able
							to view your MyTab earnings, balances and upcoming
							payouts from your mobile, tablet, or desktop by
							logging in to your Stripe dashboard. To enhance your
							experience, you have the option to customise your
							dashboard to display revenue data, export financial
							transactions and integrate your Stripe account with
							various accounting platforms for effortless
							reconciliation.
							<br />
							<br /> For a standard account, Stripe charges 1.7% +
							$0.30 per transaction for domestic cards. The
							transaction fee will be deducted automatically by
							Stripe from your account and it is your sole
							responsibility to cover all fees associated with
							your Stripe account. By creating an account, you
							agree to these terms and acknowledge that Stripe may
							update, change or modify these fees at any time. For
							current information on Stripe's pricing, please
							visit{' '}
							<span
								onClick={() =>
									window.open(
										'https://stripe.com/en-au/pricing',
										'_blank'
									)
								}
							>
								https://stripe.com/en-au/pricing
							</span>{' '}
							or reach out to Stripe support{' '}
							<span
								onClick={() =>
									window.open(
										'https://support.stripe.com/',
										'_blank'
									)
								}
							>
								here
							</span>
							.
						</p>
						<CustomButton
							onClick={connectBankDetail}
							type="submit"
							loading={loading}
							className="newThemeButtonFullWidth mt-0"
						>
							Add Your Banking Details
						</CustomButton>
					</div>
				</Scrollbars>
				<Agreement />
			</div>
			<div className="imageWrapper">
				<div className="backWrraper">
					<p className="text">
						MyTab partners with
						<br />
						Stripe for secure payments
					</p>
					<img
						src={createAccountImage}
						alt="side-img"
						className="image"
					/>
				</div>
			</div>
			<StripeVerifyOtpModal
				isOpen={otpModalShow}
				modalData={{ bar_id: state?.data?.id }}
				refetch={() => emailVerification()}
				connectingToBank={connectingToBank}
				handleModal={() => setOtpModalShow(!otpModalShow)}
			/>
			<StripeVerifyProcessing
				isOpen={processingModalShow}
				handleModal={() => setProcessingModalShow(!processingModalShow)}
			/>
		</PageWrapper>
	);
};

export default CreateStripeAccount;
