import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import TaxModal from './TaxModal';
import TaxesTable from './TaxesTable';
import { VenueApiRoutes } from '../../../Utils/routes';
import Api from '../../../Helper/Api';
import { formatTableData } from './utils';
import DeleteConfirmModal from './DeleteConfirmModal';
import { PlusIcon } from '../../Icons';

const Taxes = () => {
	const authDetails = useSelector((state) => ({ ...state.auth }));
	const [taxModal, setTaxModal] = useState(false);
	const [modalData, setModalData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
	const [deleteTaxId, setDeleteTaxId] = useState(null);
	const handleDeleteConfirmModal = () => {
		setDeleteConfirmModal((prev) => !prev);
	};
	const handleDeleteTax = async () => {
		try {
			setLoading(true);
			const res = await Api('DELETE', VenueApiRoutes.deleteTax, {
				bar_id: authDetails?.selectedVenue?.id?.toString(),
				id: '' + deleteTaxId
			});
			setLoading(false);
			if (res?.data?.status) {
				setDeleteConfirmModal(false);
				await getTableData();
				toast.success(res?.data?.message);
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	const columns = [
		{
			Header: <span className="fs-12 medium-text">Name</span>,
			accessor: 'name',
			className: 'justify-content-start fs-12 regular-text',
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class justify-content-start'
		},
		{
			Header: 'Tax Amount (%)',
			accessor: 'taxAmount',
			className: 'fs-12 regular-text',
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-12 medium-text'
		},
		{
			Header: 'Action',
			accessor: 'action',
			className: 'text-dark',
			filterable: false,
			sortable: false,
			minWidth: 50,
			headerClassName: 'react-table-header-class fs-12 medium-text',
			Cell: (data) => {
				let id = data?.original?.id;
				return (
					<div className="d-flex">
						<div
							className="boxBorderRight pr-8 fs-12 medium-text headingTextColor2 cursor-pointer"
							onClick={() => {
								setModalData({
									type: 'edit',
									data: data?.original
								});
								setTaxModal(true);
							}}
						>
							Edit
						</div>
						<div
							className="pl-8 fs-12 medium-text headingTextColor2 cursor-pointer"
							onClick={() => {
								setDeleteTaxId(id);
								setDeleteConfirmModal(true);
							}}
						>
							Delete
						</div>
					</div>
				);
			}
		}
	];
	const handleTaxModal = () => {
		setTaxModal((prev) => !prev);
	};
	const getTableData = async () => {
		try {
			setLoading(true);
			const res = await Api('POST', VenueApiRoutes.getTaxList, {
				bar_id: '' + authDetails?.selectedVenue?.id
			});
			setLoading(false);
			if (res?.data?.status) {
				const tableDetails = formatTableData(res?.data?.data);
				setTableData(tableDetails);
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	useEffect(() => {
		(async () => {
			await getTableData();
		})();
	}, [authDetails?.selectedVenue?.id]);
	return (
		<div className="d-flex flex-column">
			<p className="fs-12 regular-text">
				Create taxes to add surcharges to your menu items on set days
				during the week.
				<br />
				<span className="fs-12 medium-text">Examples:</span> Public
				holiday surcharge, Sunday surcharge etc.
			</p>
			<div className="ptb-24">
				<Button
					type="button"
					className="themeBorderButton"
					onClick={() => {
						setTaxModal(true);
						setModalData({ type: 'add', data: null });
					}}
				>
					<PlusIcon height={18} width={18} className="mr-6" /> Add New
					Tax
				</Button>
			</div>
			<div>
				<TaxesTable
					loading={loading}
					tableColumns={columns}
					tableData={tableData}
				/>
			</div>
			<TaxModal
				modalData={modalData}
				isOpen={taxModal}
				handleModal={handleTaxModal}
				getTableData={getTableData}
				setModalData={setModalData}
			/>
			<DeleteConfirmModal
				isOpen={deleteConfirmModal}
				handleModal={handleDeleteConfirmModal}
				deleteLoading={loading}
				handleDeleteTax={handleDeleteTax}
			/>
		</div>
	);
};

export default Taxes;
