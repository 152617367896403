import PageStructure from '../../../Components/Common/PageStructure';
import VenueAccountForm from '../../../Components/UserProfile/ConnectVenue/VenueAccountForm';

export const VenueAccount = () => {
	return (
		<PageStructure title="Create MyTab Venue Account">
			<VenueAccountForm />
		</PageStructure>
	);
};
