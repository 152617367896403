import styled from 'styled-components';
import LandingHeroImage from '../../Assets/images/landing-page-hero-image.png';

const LandingWrraper = styled.div`
	color: ${(props) => props.layoutTheme.textColor};
	width: 100%;
	height: 100%;

	.MainDiv {
		padding-inline: 157px;
	}

	.DetailSection {
		width: 100% !important;
		.IntroHeading {
			font-weight: 700;
			font-size: 60px;
			color: #ffffff;
			max-width: 543px;
			font-family: 'montserrat-bold';
		}

		.IntroParagraph {
			margin: 40px 0 70px;
			font-weight: 400;
			font-size: 27px;
			color: #ffffff;
			line-height: 40.5px;
			max-width: 561px;
			font-family: 'poppins-regular';
		}

		.ButtonStack {
			display: flex;
			flex-direction: column;
			gap: 40px;
		}

		@media only screen and (min-width: 600px) and (max-width: 960px) {
			.IntroHeading {
				font-size: 45px !important;
				max-width: 407.2px !important;
			}

			.IntroParagraph {
				margin: 20px 0 30px;
				font-size: 20.2px !important;
				line-height: 30.3px !important;
				max-width: 420.7px !important;
			}
		}

		@media (max-height: 900px) {
			.IntroHeading {
				font-size: 33.7px;
				max-width: 305.4px;
			}

			.ButtonStack {
				gap: 20px;
			}

			.IntroParagraph {
				margin: 20px 0 30px;
				font-size: 15.1px;
				line-height: 22.7px;
				max-width: 315.5px;
			}
		}

		@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
			.IntroHeading {
				font-size: 33.7px;
				// max-width: 305.4px;
				max-width: 346.4px;
			}

			.ButtonStack {
				gap: 20px;
			}

			.IntroParagraph {
				margin: 25px 0 35px;
				font-size: 15.1px;
				// line-height: 22.7px;
				line-height: 26.7px;
				max-width: 313.5px;
			}
		}

		@media (max-width: 600px) {
			.IntroHeading {
				font-size: 27.7px;
				max-width: 250.8px;
			}

			.ButtonStack {
				gap: 20px;
			}

			.IntroParagraph {
				padding: 0px;
				max-width: 280px;
				margin: 20px 0 40px;
				font-size: 14px;
				color: #ffffff;
				line-height: 20px;
			}
		}
	}

	.SectionMainRow {
		padding: 4rem 0;
	}

	.LoginButton {
		background-color: #ffffff;
		border: none;
		border-radius: 0;
		max-width: 439px;
		padding: 0;
		height: 95px;
		color: #f95c69;
		font-weight: 700;
		font-size: 22px;
		font-family: 'montserrat-bold';
	}

	.CreatePortalButton {
		background-color: #f95c69b2;
		border: none;
		border-radius: 0;
		max-width: 439px;
		padding: 0;
		height: 95px;
		color: #ffffff;
		font-weight: 700;
		font-size: 22px;
		font-family: 'montserrat-bold';
	}

	.LoginButton:hover {
		background-color: #ffffff;
		color: #f95c69;
	}
	.CreatePortalButton:hover {
		background-color: #f95c69b2;
		color: #ffffff;
	}

	.LandingHeroAssetView {
		background-image: url(${LandingHeroImage});
		background-size: contain;
		background-repeat: no-repeat;
		height: 727px;
		width: 727px;
	}

	@media only screen and (min-width: 600px) and (max-width: 960px) {
		.MainDiv {
			padding-inline: 117.7px !important;
		}

		.LandingHeroAssetView {
			height: 408.9px;
			width: 408.9px;
		}

		.LoginButton,
		.CreatePortalButton {
			max-width: 100% !important;
			height: 71.2px !important;
			font-weight: 700;
			font-size: 16.5px !important;
		}
	}

	@media only screen and (max-width: 1224px) {
		.MainDiv {
			padding-inline: 95px;
		}

		.LandingHeroAssetView {
			height: 408.9px;
			width: 408.9px;
		}

		.LoginButton,
		.CreatePortalButton {
			max-width: 246.9px;
			height: 53.4px;
			font-weight: 700;
			font-size: 12.3px;
		}
	}

	@media (max-height: 900px) {
		.MainDiv {
			padding-inline: 157px;
		}
		.LandingHeroAssetView {
			height: 408.9px;
			width: 408.9px;
		}
		.LoginButton,
		.CreatePortalButton {
			max-width: 246.9px;
			height: 53.4px;
			font-weight: 700;
			font-size: 12.3px;
		}
	}

	@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
		.MainDiv {
			padding-inline: 86px;
		}

		.LandingHeroAssetView {
			// height: 408.9px;
			// width: 408.9px;
			height: 505.9px;
			width: 500px;
		}

		.LoginButton,
		.CreatePortalButton {
			max-width: 246.9px;
			height: 53.4px;
			font-weight: 700;
			font-size: 12.3px;
		}
	}

	@media only screen and (max-width: 600px) {
		.MainDiv {
			padding: 0 40px;
		}

		.LoginButton,
		.CreatePortalButton {
			width: 100%;
			max-width: 100%;
			height: 43.8px;
			font-weight: 700;
			font-size: 12px;
		}
	}
`;

export default LandingWrraper;
