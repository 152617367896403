import { useState } from 'react';
import { Button } from 'reactstrap';

import PageStructure from '../../Components/Common/PageStructure';

import Api from '../../Helper/Api';
import TableStyle from '../../Components/Common/TableStyle';
import TableV6 from '../../Components/Common/TableV6';
import FormSwitch from '../../Components/Form/FormSwitch';
import { DeleteIcon, EditIcon, PlusIcon } from '../../Components/Icons';
import RolesAndPermissionModal from '../../Components/RolesAndPermission/RolesAndPermissionModal';
import { useEffect } from 'react';
import { roleAndPermissionData } from './utils';

export const RoleAndPermission = () => {
	const [roleAndPermissionModal, setRoleAndPermissionModal] = useState(false);
	const [modalData, setModalData] = useState({});
	const [type, setType] = useState('add');
	const [tableData, setTableData] = useState(roleAndPermissionData);

	const [params, setParams] = useState({
		page: 1,
		per_page: 50,
		order: 'ASC',
		sortBy: 'created'
	});

	// useEffect(() => {
	//   Api("POST","/role-permission/list",params)
	//   .then(res => {
	//     if (res.data.status) {
	//       setTableData(res.data.data)
	//     } else {
	//       console.log(res.data);
	//     }
	//   })
	//   .catch(err => {
	//     console.log(err.response);
	//   });
	// }, [params])

	const handleRoleAndPermissionModal = () => {
		setRoleAndPermissionModal(!roleAndPermissionModal);
	};

	const columns = [
		{
			Header: 'MyTab Staff Roles',
			accessor: 'name',
			className: 'justify-content-start pl-10',
			filterable: false,
			sortable: true,
			minWidth: 150,
			headerClassName:
				'react-table-header-class fs-16 medium-text justify-content-start'
		},
		{
			Header: 'Status',
			accessor: 'status',
			className: 'text-center',
			filterable: false,
			sortable: false,
			width: 164,
			headerClassName: 'react-table-header-class fs-16 medium-text',
			Cell: ({ row }) => (
				<FormSwitch
					name="status"
					id="status"
					label={`${row.status === true ? 'Active' : 'Inactive'}`}
					checked={row.status}
					onChange={() => handleStatus(row._original.id)}
				/>
			)
		},
		{
			Header: 'Manage',
			className: 'text-center',
			headerClassName: 'react-table-header-class fs-16 medium-text',
			sortable: false,
			filterable: false,
			width: 100,
			Cell: ({ row }) => (
				<div className="react-action-class">
					<Button
						className="background-transparent no-border shadow-none"
						onClick={() => {
							setType('edit');
							setModalData(row._original);
							setRoleAndPermissionModal(true);
						}}
					>
						<div className="fs-14 medium-text">
							<EditIcon height="28px" width="28px" />
						</div>
					</Button>
				</div>
			)
		}
	];

	const handleStatus = (id) => {
		const temp = tableData.map((item) =>
			item.id === id ? { ...item, status: !item.status } : { ...item }
		);
		setTableData([...temp]);
	};

	const handleSortBy = (sortBy) => {
		setParams((prev) => ({
			...prev,
			sort_by: sortBy[0]?.id ?? 'id',
			order: sortBy[0]?.desc ? 'DESC' : 'ASC'
		}));
	};

	return (
		<PageStructure
			title="MyTab Staff Roles and Permissions"
			buttonName="Add Role"
			buttonIcon={<PlusIcon height="18" width="18" className="mr-10" />}
			handleButtonClick={() => {
				setType('add');
				handleRoleAndPermissionModal(true);
			}}
			search={true}
		>
			<div className="mtb-15">
				<div className="roe-card-body">
					<TableStyle version={6}>
						<TableV6
							columns={columns}
							data={tableData}
							handleSortBy={handleSortBy}
							key={'master-rap-table'}
						/>
						{/* {data.length ? (
              <Pagination
                handlePageChange={handlePageChange}
                // pageSize={10}
                // total={data?.totalCount || 0}
                // currentPage={params.pageNo}
                pageSize={5}
                total={10}
                currentPage={1}
              />
            ) : null} */}
					</TableStyle>
				</div>
			</div>
			<RolesAndPermissionModal
				type={type}
				modalData={modalData}
				isOpen={roleAndPermissionModal}
				handleModal={handleRoleAndPermissionModal}
			/>
		</PageStructure>
	);
};
