const uiActions = {
	PAGE_LOADING: 'PAGE_LOADING',
	page_loading: (status) => {
		return {
			type: uiActions.PAGE_LOADING,
			pageLoading: status
		};
	}
};

export default uiActions;
