export const CircleMinusIcon = ({ fill, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 18 19" fill="none">
			<path
				d="M12.0938 8.40625C12.5508 8.40625 12.9375 8.79297 12.9375 9.25C12.9375 9.74219 12.5508 10.0938 12.0938 10.0938H5.90625C5.41406 10.0938 5.0625 9.74219 5.0625 9.25C5.0625 8.79297 5.41406 8.40625 5.90625 8.40625H12.0938ZM18 9.25C18 14.2422 13.957 18.25 9 18.25C4.00781 18.25 0 14.2422 0 9.25C0 4.29297 4.00781 0.25 9 0.25C13.957 0.25 18 4.29297 18 9.25ZM9 1.9375C4.95703 1.9375 1.6875 5.24219 1.6875 9.25C1.6875 13.293 4.95703 16.5625 9 16.5625C13.0078 16.5625 16.3125 13.293 16.3125 9.25C16.3125 5.24219 13.0078 1.9375 9 1.9375Z"
				fill={fill ?? 'white'}
			/>
		</svg>
	);
};
