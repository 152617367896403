import styled from 'styled-components';

const PageWrapper = styled.div`
	background-color: #ffffff;
	height: 100%;
	color: ${(props) => props.layoutTheme.textColor} !important;
	padding: 0px;
	@media only screen and (min-width: 576px) {
		padding: 16px;
		padding-top: 4px;
	}
	@media only screen and (min-width: 768px) {
		padding: 24px;
		padding-top: 12px;
	}
	.page {
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
		border-radius: 12px;
		padding: 18px 22px;
		height: 100%;
		display: flex;
		flex-direction: column;
		@media only screen and (max-width: 767px) {
			border-radius: 0px;
		}
	}
	.page-header {
		padding-bottom: 12px;
	}
	.page-body {
		flex: 1 1 auto;
	}
	.topBox {
		border: 1px solid #eaeaea;
		border-radius: 8px;
		padding: 28px 24px !important;
		display: flex;
		justify-content: space-between;
	}
	.cardsContainer {
		height: 100% !important;
		.newOrdersWrapper,
		.preparedOrdersWrapper {
			height: 500px !important;
		}
		.preparedOrdersWrapper {
			padding-top: 32px;
		}
		.customDivider {
			display: none;
		}
		@media only screen and (min-width: 1000px) {
			display: flex;
			.newOrdersWrapper,
			.preparedOrdersWrapper {
				height: unset !important;
				flex: 1 1 0 !important;
			}
			.preparedOrdersWrapper {
				padding-top: 0 !important;
			}
			.customDivider {
				display: block !important;
			}
		}
	}
	.customDivider {
		border-left: 1px solid #dddddd !important;
		margin-inline: 24px;
	}
	.orderCard {
		border: 1px solid #84d565;
		border-radius: 8px;
		&.newOrder {
			.card-header {
				border-radius: 7px 7px 0px 0px;
				background: #84d565;
				.orderWaitTime,
				.orderNo {
					color: #fff;
				}
			}
		}
		&.tableService {
			border: 1px solid #ff482f;
			.card-header {
				border-radius: 7px 7px 0px 0px;
				background: #ff482f;
				.orderWaitTime,
				.orderNo {
					color: #fff;
				}
			}
		}
	}
`;

export default PageWrapper;
