import React from 'react';
import { useSelector } from 'react-redux';
import PageStructure from '../PageStructure';
import PageWrapper from './index.style';
import { useEffect } from 'react';
import { VenueApiRoutes } from '../../../Utils/routes';
import Api from '../../../Helper/Api';

const ComingSoon = ({ pageTitle }) => {
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	// Just for auto Logout functionality purpose
	useEffect(() => {
		(async () => {
			try {
				const res = await Api('GET', VenueApiRoutes.getProfileDetails);
			} catch (err) {}
		})();
	});
	return (
		<PageStructure title={pageTitle}>
			<PageWrapper
				{...allThemeData}
				className="h-100 d-flex flex-column align-items-center justify-content-center"
			>
				<p className="fs-14 regular-text themeText text-center pa-b-12">
					We are currently working hard to finalise this feature, we
					thank you for your patience and will let you know as soon as
					it is available.
				</p>
				<a
					href="https://mytabinfo.com/pages/frequently-asked-questions"
					target={'_blank'}
					className="fs-14 semi-bold-text activeText"
					rel="noreferrer"
				>
					MyTab Venue Support Team
				</a>
			</PageWrapper>
		</PageStructure>
	);
};

export default ComingSoon;
