import styled from 'styled-components';

const PageWrapper = styled.div`
	color: ${(props) => props.layoutTheme.textColor} !important;
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	.headingTextColor {
		color: ${(props) => props.layoutTheme.headingColor};
	}
	.themeButton {
		background: linear-gradient(350.67deg, #f94d73 0%, #fd6461 92.95%);
		color: ${(props) => props.layoutTheme.buttonTextColor};
		border: 0px;
		padding-top: 10px;
		padding-bottom: 10px;
	}
`;

export default PageWrapper;
