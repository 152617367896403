import styled from 'styled-components';

export const DropDownWrapper = styled.div`
	.dashboard-dropdown {
		.activeColor {
			color: ${(props) => props.layoutTheme.buttonColor3} !important;
		}
		.dropdown-item,
		.dropdownToggle {
			outline: 0 !important;
			border: 0px !important;
			padding-left: 10px;
			padding-right: 10px;
			padding-top: 10px;
			&.active {
				background-color: ${(props) =>
					props.layoutTheme.buttonColor3} !important;
			}
			@media only screen and (max-width: 575.98px) {
				padding-top: 5px;
				margin-top: 6px;
				margin-bottom: 6px;
			}
			.dropdown-name {
				padding-right: 5px;
				color: ${(props) => props.layoutTheme.textColor};
			}
			&:hover {
				outline: 0 !important;
				border: 0px !important;
			}
			&:active {
				outline: 0 !important;
				border: 0px !important;
				background-color: #fff !important;
				color: ${(props) => props.layoutTheme.textColor};
			}
		}
		.dropdown-menu {
			border: 0px !important;
			@media only screen and (max-width: 360px) {
			}
		}
	}
	.dateDropdownBox {
		background: #f9f9f9;
		border-radius: 8px;
		min-height: 52px;
	}
	.dropdown-item.dropdownCalender:hover {
		background-color: #fff;
	}
	.dropdownCalenderParent {
		border-right: 1px solid #eaeaea;
		margin-right: 10px;
		padding-right: 15px;
		@media only screen and (max-width: 575px) {
			border-right: 0px;
			margin-right: 0px;
			padding-right: 0px;
		}
	}
	.datePickerDropdown {
		max-height: 300px;
		overflow: auto;
		margin-top: 15px;
	}
`;
