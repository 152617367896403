import styled from 'styled-components';

export const PageWrapper = styled.div`
	.itemBox {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 24px;
	}
	.productItemName {
		@media only screen and (min-width: 1217px) {
			width: 118px !important;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	.gridContainer {
		display: grid;
		grid-template-columns: auto auto auto;
		gap: 22px;
		@media only screen and (max-width: 1597px) {
			grid-template-columns: auto auto;
		}
		@media only screen and (max-width: 1216px) {
			grid-template-columns: auto;
		}
	}
	.gridItem {
		position: relative;
	}
	.cardSkeleton {
		position: absolute;
		top: -5px;
		left: 0;
		right: 0;
		bottom: 0;
	}
`;
