import React from 'react';
import TableStyle from '../../Common/TableStyle';
import TableV6 from '../../Common/TableV6';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const summaryReportData = [
	{
		id: 1,
		menu_item_names: 'jeet',
		category: 'Pizza',
		sold_quantity: 10
	},
	{
		id: 2,
		menu_item_names: 'Extra Cheese',
		category: 'Coffee',
		sold_quantity: 42
	},
	{
		id: 3,
		menu_item_names: 'Crispy Bacon',
		category: 'Extras',
		sold_quantity: 43
	},
	{
		id: 4,
		menu_item_names: 'Hard Poached Eggs',
		category: 'Hot Drinks',
		sold_quantity: 8
	},
	{
		id: 5,
		menu_item_names: 'Chips',
		category: 'Pizza',
		sold_quantity: 13
	},
	{
		id: 6,
		menu_item_names: 'Fried Beet Cruches',
		category: 'Hot Drinks',
		sold_quantity: 18
	},
	{
		id: 7,
		menu_item_names: 'Sweet Potato Lasagna',
		category: 'Extras',
		sold_quantity: 20
	},
	{
		id: 8,
		menu_item_names: 'Spinach Pury',
		category: 'Coffee',
		sold_quantity: 33
	},
	{
		id: 9,
		menu_item_names: 'Bean Curry',
		category: 'Pizza',
		sold_quantity: 27
	},
	{
		id: 10,
		menu_item_names: 'Dragon Fruit Crush',
		category: 'Coffee',
		sold_quantity: 17
	}
];

const tableColumns = [
	{
		Header: <span className="fs-12 medium-text">Menu Item Names</span>,
		accessor: 'menu_item_names',
		className: 'justify-content-start fs-12 regular-text',
		minWidth: 150,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class justify-content-start',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: <span className="fs-12 medium-text">Category</span>,
		accessor: 'category',
		className: 'justify-content-start fs-12 regular-text',
		minWidth: 150,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class justify-content-start',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Sold Quantity',
		accessor: 'sold_quantity',
		className: 'fs-12 regular-text',
		minWidth: 120,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	}
];

const TableSkeleton = () => {
	return (
		<TableStyle version={6}>
			<TableV6
				columns={tableColumns}
				data={summaryReportData}
				key={'master-todo-table'}
			/>
		</TableStyle>
	);
};

export default TableSkeleton;
