import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import * as validation from '../../../Helper/YupValidation';
import FormRadioButton from '../../Form/FormRadioButton';
import FormWrapper from './venueAccountForm.style';
import OpeningHoursModal from './OpeningHoursModal';
import { OpeningHoursModalDetails } from './utils';
import crossImg from '../../../Assets/images/cross.png';
import {
	CommonRoutes,
	VenueApiRoutes,
	VenuePanelRoutes,
	VenueProtectedRoutes
} from '../../../Utils/routes';
import Api from '../../../Helper/Api';
import CustomButton from '../../Common/CustomButton';
import MapModal from './MapModal';
import NewFormInput from '../../Form/NewFormInput';
import NewFormPasswordInput from '../../Form/NewFormPasswordInput';
import CustomLabel from '../../Form/CustomLabel';
import NewCheckBox from '../../Form/NewCheckBox';
import NewCheckBoxStyle from '../../Form/NewCheckBox/index.style';
import NewOpeningHoursCard from './NewOpeningHoursCard';
import NewFormMobileNoInput from '../../Form/newFormMobileNoInput';
import NewFormCheckbox from '../../Form/NewFormCheckbox/NewFormCheckbox';
import { useDispatch } from 'react-redux';
import NewVerifyPhoneOtpModal from './NewVerifyPhoneOtpModal';
import authActions from '../../../Redux/auth/actions';
import barActions from '../../../Redux/bar/actions';
import venueProtectedRoutes from '../../../Routes/venueProtectedRoutes';
import ImageUpload from '../../Form/ImageUpload';
import { EditIcon } from '../../Icons';
import NewImageUpload from '../../Form/NewImageUpload';

const NewVenueAccountForm = () => {
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isOpenVerifyModal, setIsOpenVerifyModal] = useState(false);
	const [disableVerifyModal, setDisableVerifyModal] = useState(false);
	const [verifyModalData, setVerifyModalData] = useState();
	const [openingHoursModalData, setOpeningHoursModalData] = useState(
		OpeningHoursModalDetails
	);
	const [passwordValid, setPasswordValid] = useState(null);
	const [abnAcnValid, setAbnAcnValid] = useState(null);
	const [isOpenMapModal, setIsOpenMapModal] = useState(false);
	const [isAlcohol, setIsAlcohol] = useState(null);
	const [isOpenOpeningHoursModal, setIsOpenOpeningHoursModal] =
		useState(false);
	const [loading, setLoading] = useState(false);
	const [modalData, setModalData] = useState();
	const { state } = useLocation();

	// useEffect(() => {
	// 	if (!state) {
	// 		navigate(VenueProtectedRoutes.connectVenue, { replace: true });
	// 	}
	// }, [state]);

	function validatePasswordInput(event) {
		if (event.target.value.length < 6) {
			setPasswordValid(false);
		} else {
			setPasswordValid(true);
		}
	}

	function validateAbnAcn(event) {
		const abn = event.target.value;
		if (isNaN(parseInt(abn))) {
			setAbnAcnValid(false);
		}

		const weighting = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
		const firstDigitProcessed = +abn[0] - 1;

		const weighted = [...abn.slice(1)].reduce(
			(sum, char, i) => sum + +char * weighting[i + 1],
			firstDigitProcessed * weighting[0]
		);

		setAbnAcnValid(weighted % 89 === 0);
	}

	const headings = [
		{
			text: 'Day'
		},
		{
			text: 'Opening Time'
		},
		{
			text: 'Closing Time'
		},
		{
			text: 'Status'
		}
	];
	useEffect(() => {
		setModalData(modalData);
	}, [modalData]);

	const handleModalData = (data) => {
		const updatedModalData = [...modalData];

		let index;
		if (updatedModalData?.length > 0) {
			index = updatedModalData?.findIndex(
				(item) => item.weekDay === data.weekDay
			);
			updatedModalData[index] = data;
			setModalData(updatedModalData);
		}
	};
	const validationSchema = yup.object().shape({
		image: validation.YUP_VALIDATION.IMAGE,
		venueName: validation.YUP_VALIDATION.VENUE_NAME,
		firstName: validation.YUP_VALIDATION.OWNER_MANAGER_FIRST_NAME,
		lastName: validation.YUP_VALIDATION.OWNER_MANAGER_LAST_NAME,
		ownerManagerName: validation.YUP_VALIDATION.OWNER_MANAGER_NAME,
		email: validation.YUP_VALIDATION.EMAIL,
		password: validation.YUP_VALIDATION.NORMAL_PASSWORD,
		countryCode: validation.YUP_VALIDATION.COUNTRY_CODE,
		mobileNumber: validation.YUP_VALIDATION.MOBILE_NUMBER,
		address: validation.YUP_VALIDATION.ADDRESS,
		abnAcn: validation.YUP_VALIDATION.ABN_ACN_NUMBER,
		isAlcohol: validation.YUP_VALIDATION.IS_ALCOHOL,
		licenseNumber:
			isAlcohol === 'Yes' && validation.YUP_VALIDATION.LICENSE_NUMBER,
		serviceType: validation.YUP_VALIDATION.SERVICE_TYPE,
		acceptTerms: validation.YUP_VALIDATION.ACCEPT_TERMS
	});
	const handleOpeningHoursModal = () => {
		setIsOpenOpeningHoursModal((prev) => !prev);
	};
	const handleMapModal = () => {
		setIsOpenMapModal((prev) => !prev);
	};
	const handleOpeningHoursModalData = (data) => {
		setOpeningHoursModalData(data);
	};

	const createVenueAccount = async () => {
		try {
			setLoading(true);

			const res = await Api(
				'POST',
				VenueApiRoutes.createVenue,
				modalData
			);
			if (res?.data?.status) {
				setLoading(false);
				toast.success(res?.data?.message);
				resetForm();
				// navigate(VenuePanelRoutes.connectVenue);
				navigate(VenueProtectedRoutes.createStripeAccount, {
					state: res?.data
				});

				// dispatch(authActions.venue_change_login(true));
			} else {
				toast.error(res?.data?.message || 'Something went wrong');
			}

			setLoading(false);
		} catch (err) {
			setLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};

	const emailVerification = async (formData, notreload = false) => {
		try {
			!notreload && setLoading(true);

			const res = await Api('POST', '/venue/bar/sent-otp-verify', {
				email: values?.email,
				manager_name: values?.firstName + ' ' + values?.lastName
			});

			if (res?.data?.status) {
				!notreload && setLoading(false);
				toast.success(res?.data?.message);
				setVerifyModalData(formData);
				setIsOpenVerifyModal(true);
			} else {
				!notreload && setLoading(false);
				toast.error(res?.data?.message || 'Something went wrong');
			}
		} catch (err) {
			!notreload && setLoading(false);
			toast.error(err?.message ?? 'Something went wrong');
		}
	};

	const submitFormHandler = async (values) => {
		const payload = {
			image: values?.image,
			venue_name: values?.venueName,
			manager_name: values?.firstName + ' ' + values?.lastName,
			email: values?.email,
			venue_address: values?.address,
			country_code: values?.countryCode,
			mobile: values?.mobileNumber,
			business_register_id: values?.abnAcn,
			is_venue_serve_alcohol: values?.isAlcohol,
			liquor_license_number: values?.licenseNumber,
			password: values?.password,
			service_type:
				values?.serviceType?.length === 2
					? ['BOTH']
					: values?.serviceType,
			latitude: values?.latitude,
			longitude: values?.longitude
		};
		if (isAlcohol === 'No') {
			delete payload.liquor_license_number;
		}
		let formData = new FormData();
		for (let key in payload) {
			formData.append(key, payload[key]);
		}
		for (let i = 0; i < modalData?.length; i++) {
			formData.append(
				`operating_hours[${i}][week_day]`,
				modalData[i]?.weekDay
			);
			formData.append(
				`operating_hours[${i}][opening_hours]`,
				modalData[i]?.activeHours
			);
			formData.append(
				`operating_hours[${i}][closing_hours]`,
				modalData[i]?.inActiveHours
			);
			formData.append(
				`operating_hours[${i}][is_closed]`,
				modalData[i]?.isClosed
			);
		}

		if (disableVerifyModal) {
			await createVenueAccount();
		} else {
			await emailVerification(formData);
		}
	};

	useEffect(() => {
		setModalData(openingHoursModalData);
	}, [openingHoursModalData]);

	const {
		handleChange,
		handleBlur,
		setFieldValue,
		handleSubmit,
		values,
		touched,
		errors,
		resetForm
	} = useFormik({
		initialValues: {
			image: null,
			firstName: '',
			lastName: '',
			venueName: '',
			ownerManagerName: '',
			email: '',
			password: '',
			countryCode: '+61',
			mobileNumber: '',
			address: '',
			abnAcn: '',
			isAlcohol: null,
			licenseNumber: '',
			serviceType: [],
			latitude: '',
			longitude: '',
			acceptTerms: true
		},
		validationSchema,
		onSubmit: submitFormHandler
	});

	useEffect(() => {
		setFieldValue(
			'ownerManagerName',
			values?.firstName + ' ' + values?.lastName
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values?.firstName, values?.lastName]);

	return (
		<>
			<FormWrapper {...allThemeData}>
				<form onSubmit={handleSubmit} fsdfsdfsd>
					<p className="headingStyle pt-0 pb-0">
						Let’s create your MyTab Venue
					</p>

					<p className={`helperLink`}>
						Already have a venue account? Click{' '}
						<span
							className="helperLinkSpan"
							onClick={() =>
								navigate(VenueProtectedRoutes.connectVenue)
							}
						>
							here
						</span>{' '}
						to go back.
					</p>

					<Row>
						<Col sm={12} className="pa-b-20">
							<CustomLabel
								id={'image'}
								label={'VENUE PROFILE PHOTO'}
								tootlTipMessage={`Please upload a photo of your venue, the customer will see this on  their home screen.`}
								showRequired
							/>
							<NewImageUpload
								name={'image'}
								icon={
									<></>
									// <img
									// 	src={crossImg}
									// 	alt="crossImg"
									// 	height={30}
									// 	width={30}
									// />
								}
								handleReset={() => setFieldValue('image', null)}
								value={values?.image}
								setFieldValue={setFieldValue}
								error={touched?.image && !!errors?.image}
								errorMsg={errors?.image}
							/>
						</Col>
						<Col sm={6} className="pa-b-20">
							<NewFormInput
								id={'firstname'}
								type="text"
								ownerManagerName
								name="firstName"
								label="FIRST NAME"
								placeholder="Enter First Name"
								value={values?.firstName}
								onChange={handleChange}
								onBlur={handleBlur}
								error={
									touched?.firstName && !!errors?.firstName
								}
								errorMsg={errors?.firstName}
							/>
						</Col>
						<Col sm={6} className="pa-b-20">
							<NewFormInput
								id={'lastname'}
								type="text"
								name="lastName"
								label="LAST NAME"
								placeholder="Enter Last Name"
								value={values?.lastName}
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched?.lastName && !!errors?.lastName}
								errorMsg={errors?.lastName}
							/>
						</Col>
					</Row>
					<Row>
						<Col sm={12} className="pa-b-20">
							<CustomLabel
								id={'mobileNumber'}
								label={'MOBILE NUMBER'}
								tootlTipMessage={`Please enter your mobile number for account verification.`}
								showRequired
							/>
							<NewFormMobileNoInput
								className={'gap-0'}
								name="mobileNumber"
								placeholder="Enter Mobile Number"
								mobileNoValue={values?.mobileNumber}
								onMobileNoChange={handleChange}
								onMobileNoBlur={handleBlur}
								countryCodeValue={values?.countryCode}
								onSelectCountry={(_, countryDetails) => {
									setFieldValue(
										'countryCode',
										countryDetails.dialCode
									);
								}}
								error={
									(touched?.mobileNumber &&
										!!errors?.mobileNumber) ||
									(touched?.countryCode &&
										!!errors?.countryCode)
								}
								errorMsg={
									errors?.mobileNumber || errors?.countryCode
								}
							/>
						</Col>
					</Row>

					<Row>
						<Col sm={12} className="pa-b-20">
							<NewFormInput
								id={'venuename'}
								tootlTipMessage="Please enter you venue name, this is what the customer will see."
								showRequired
								type="text"
								name="venueName"
								label="Venue Name"
								placeholder="Enter Venue Name"
								value={values?.venueName}
								onChange={handleChange}
								onBlur={handleBlur}
								error={
									touched?.venueName && !!errors?.venueName
								}
								errorMsg={errors?.venueName}
							/>
						</Col>
					</Row>
					<Row>
						<Col sm={12} className="pa-b-20">
							<NewFormInput
								id={'email'}
								tootlTipMessage="Please enter your venue's email address so your customers can contact you with any queries."
								showRequired
								type="email"
								name="email"
								label="VENUE EMAIL ADDRESS"
								placeholder="Enter Email Address"
								value={values?.email}
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched?.email && !!errors?.email}
								errorMsg={errors?.email}
							/>
						</Col>
					</Row>
					<Row>
						<Col className="pa-b-20">
							<NewFormPasswordInput
								suggestionMatcher
								passwordSuggestionMatched={passwordValid}
								tootlTipMessage="This password is for your MyTab Venue account and does not need to match the password chosen for your MyTab Management Portal."
								autoComplete="off"
								showRequired
								name="password"
								label="Password"
								placeholder="Password"
								value={values?.password}
								onChange={(e) => {
									handleChange(e);
									validatePasswordInput(e);
								}}
								onBlur={handleBlur}
								error={touched?.password && !!errors?.password}
								errorMsg={errors?.password}
							/>
						</Col>
					</Row>
					<Row>
						<Col className="pa-b-20">
							<NewFormInput
								id={'address'}
								tootlTipMessage="Please enter your venue's address, this will be used so customers can locate your venue."
								showRequired
								type="text"
								name="address"
								label="Venue Address"
								placeholder="Enter Venue Address"
								value={values?.address}
								// onChange={handleChange}
								onBlur={handleBlur}
								error={touched?.address && !!errors?.address}
								errorMsg={errors?.address}
								onClick={() => setIsOpenMapModal(true)}
								onTouchStart={() => setIsOpenMapModal(true)}
								onMouseDown={() => setIsOpenMapModal(true)}
							/>
						</Col>
					</Row>
					<Row>
						<Col className="pa-b-20">
							<NewFormInput
								id={'abnAcn'}
								tootlTipMessage="Please enter your venue's Australian Business Number or Australian Company Number so we can verify your business."
								showRequired
								suggestionMatcher
								inputSuggestionMatched={abnAcnValid}
								type="text"
								name="abnAcn"
								label="VENUE ABN/ACN DETAILS"
								placeholder="Enter ABN/ACN Details"
								value={values?.abnAcn}
								onChange={(e) => {
									handleChange(e);
									validateAbnAcn(e);
								}}
								onBlur={handleBlur}
								error={
									abnAcnValid !== null
										? !abnAcnValid
										: touched?.abnAcn && !!errors?.abnAcn
								}
								errorMsg={
									errors?.abnAcn ??
									(abnAcnValid !== null
										? !abnAcnValid && 'Invalid ABN/ACN'
										: '')
								}
							/>
						</Col>
					</Row>
					<Row className="gap-2 gap-md-0">
						<CustomLabel
							id="serviceType"
							label={'SELECT YOUR SERVICE TYPE/S'}
							tootlTipMessage={`Please select your venue's service type.`}
							showRequired
						/>
						<Col sm={6} md={6}>
							<NewCheckBox
								label={'Pick Up (Collect Order)'}
								checkBoxProps={{
									name: 'serviceType',
									value: 'PICKUP',
									onChange: handleChange,
									onBlur: handleBlur,
									checked:
										values?.serviceType?.includes('PICKUP')
								}}
							/>
						</Col>
						<Col sm={6} md={6}>
							<NewCheckBox
								label={'Table Service'}
								checkBoxProps={{
									name: 'serviceType',
									value: 'TABLE',
									onChange: handleChange,
									onBlur: handleBlur,
									checked:
										values?.serviceType?.includes('TABLE')
								}}
							/>
						</Col>
						<Col sm={12} className="pa-b-20">
							{touched?.serviceType && !!errors?.serviceType && (
								<p className="error">{errors?.serviceType}</p>
							)}
						</Col>
					</Row>
					<Row>
						<CustomLabel
							id={'servingAlcohol'}
							label={'WILL YOUR VENUE BE SERVING ALCOHOL?'}
							tootlTipMessage="This is a mandatory requirement, please answer accurately."
							showRequired
						/>
						<Col sm={6} className="">
							<NewCheckBox
								label={'Yes'}
								checkBoxProps={{
									name: 'alcohol',
									value: '',
									onChange: (e) => {
										setFieldValue(
											'isAlcohol',
											e.target.checked ? 'Yes' : null
										);
										if (e.target.checked) {
											setIsAlcohol('Yes');
										} else {
											setIsAlcohol(null);
										}
									},
									checked: values?.isAlcohol === 'Yes'
								}}
							/>
						</Col>
						<Col sm={6} className="">
							<NewCheckBox
								label={'No'}
								checkBoxProps={{
									name: 'alcohol',
									value: 'No',
									onChange: (e) => {
										setFieldValue(
											'isAlcohol',
											e.target.checked ? 'No' : null
										);
										if (e.target.checked) {
											setIsAlcohol('No');
										} else {
											setIsAlcohol(null);
										}
									},
									checked: values?.isAlcohol === 'No'
								}}
							/>
						</Col>
						<Col sm={12} className="pa-b-20">
							{touched?.isAlcohol && !!errors?.isAlcohol && (
								<p className="error">
									{errors?.isAlcohol
										? 'Please select does your venue serve alcohol?'
										: ''}
								</p>
							)}
						</Col>
					</Row>

					{isAlcohol === 'Yes' && (
						<Row>
							<Col className="pa-b-20">
								<NewFormInput
									id={'ak'}
									tootlTipMessage="This is a mandatory requirement, please answer accurately."
									showRequired
									type="text"
									name="licenseNumber"
									label="Liquor License Number"
									placeholder="Enter Liquor License Number"
									value={values?.licenseNumber}
									onChange={handleChange}
									onBlur={handleBlur}
									error={
										touched?.licenseNumber &&
										!!errors?.licenseNumber
									}
									errorMsg={errors?.licenseNumber}
								/>
							</Col>
						</Row>
					)}

					<Row>
						<Col sm={12}>
							<CustomLabel
								id={'openingHours'}
								label={'VENUE OPENING HOURS'}
								showRequired
								tootlTipMessage={`Please enter your venue's opening hours. These may be different to your kitchen opening hours.`}
							/>
						</Col>
						<Col sm={12} className="px-1 ">
							<div className="px-4 ">
								<div>
									<Row className="tableRows border-bottom-0">
										{headings.map((data, index) => (
											<Col
												sm={3}
												xs={3}
												className={'text-xl-center'}
											>
												<text
													className={`${
														index === 1 ||
														index === 2
															? `text-left`
															: ``
													} tableHeading`}
												>
													{data.text}
												</text>
											</Col>
										))}
									</Row>
								</div>
								<div>
									{modalData?.length > 0 &&
										modalData?.map((item, idx) => {
											return (
												<NewOpeningHoursCard
													hideBorder={
														!(
															idx ===
															modalData.length - 1
														)
													}
													key={item?.weekDay}
													weekDay={item?.weekDay}
													activeHours={
														item?.activeHours
													}
													inActiveHours={
														item?.inActiveHours
													}
													isClosed={item?.isClosed}
													id={item?.id}
													handleModalData={
														handleModalData
													}
												/>
											);
										})}
								</div>
							</div>
						</Col>
					</Row>
					<div className="flex gap-3 align-items-center mt-xxl-4 mt-xl-4 mt-lg-4 mb-4 mb-md-4">
						<NewFormCheckbox
							name="acceptTerms"
							onChange={handleChange}
							checked={values?.acceptTerms}
						/>
						<p className="agreement">
							I agree to MyTab’s{' '}
							<span
								className="agreementSpan"
								onClick={() => {
									window.open(
										'https://mytabinfo.com/policies/terms-of-service',
										'_blank'
									);
								}}
							>
								Terms and Conditions
							</span>{' '}
							and{' '}
							<span
								className="agreementSpan"
								onClick={() => {
									window.open(
										'https://mytabinfo.com/policies/privacy-policy',
										'_blank'
									);
								}}
							>
								Privacy Policy
							</span>
						</p>
					</div>
					<CustomButton
						type={'submit'}
						loading={loading}
						disabled={errors?.acceptTerms}
						className={'newThemeButtonFullWidth mb-3'}
					>
						Let’s Go!
					</CustomButton>
				</form>
			</FormWrapper>

			{isOpenOpeningHoursModal && (
				<OpeningHoursModal
					type="ADD"
					isOpen={isOpenOpeningHoursModal}
					handleModal={handleOpeningHoursModal}
					openingHoursModalData={openingHoursModalData}
					handleOpeningHoursModalData={handleOpeningHoursModalData}
				/>
			)}

			{isOpenMapModal && (
				<MapModal
					isOpen={isOpenMapModal}
					handleModal={handleMapModal}
					setFieldValue={setFieldValue}
				/>
			)}

			{!disableVerifyModal && (
				<NewVerifyPhoneOtpModal
					resend={async () =>
						await emailVerification(verifyModalData, true)
					}
					setDisable={setDisableVerifyModal}
					resetForm={resetForm}
					modalData={verifyModalData}
					isOpen={isOpenVerifyModal}
					handleModal={() => setIsOpenVerifyModal(!isOpenVerifyModal)}
				/>
			)}
		</>
	);
};

export default NewVenueAccountForm;
