import { useEffect, useState } from 'react';
import styled from 'styled-components';

import CustomModal from '../../Common/Modal/CustomModal';
import OpeningHoursCard from './OpeningHoursCard';
import Api from '../../../Helper/Api';
import { VenueApiRoutes } from '../../../Utils/routes';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import CustomButton from '../../Common/CustomButton';

const Div = styled.div`
	display: grid;
	grid-template-columns: 76px 128px 128px 38px;
	gap: 18px 12px;
`;

const OpeningHoursModal = ({
	type,
	isOpen,
	handleModal,
	openingHoursModalData,
	handleOpeningHoursModalData,
	email
}) => {
	const authData = useSelector((state) => ({ ...state.auth }));
	const [loading, setLoading] = useState(false);
	const [modalData, setModalData] = useState();

	const handleModalData = (data) => {
		const updatedModalData = [...modalData];

		let index;
		if (updatedModalData?.length > 0) {
			index = updatedModalData?.findIndex(
				(item) => item.weekDay === data.weekDay
			);
			updatedModalData[index] = data;
			setModalData(updatedModalData);
		}
	};
	const handleDoneClick = async () => {
		if (type === 'EDIT') {
			const payload = {
				bar_id: authData?.selectedVenue?.id,
				email: email
			};
			let formData = new FormData();
			formData.append('bar_id', payload.bar_id);
			formData.append('email', payload.email);
			for (let i = 0; i < modalData?.length; i++) {
				formData.append(`operating_hours[${i}][id]`, modalData[i]?.id);
				formData.append(
					`operating_hours[${i}][opening_hours]`,
					modalData[i]?.activeHours
				);
				formData.append(
					`operating_hours[${i}][closing_hours]`,
					modalData[i]?.inActiveHours
				);
				formData.append(
					`operating_hours[${i}][is_closed]`,
					modalData[i]?.isClosed
				);
			}
			try {
				setLoading(true);
				const res = await Api(
					'PUT',
					VenueApiRoutes.editVenue,
					formData
				);
				setLoading(false);
				if (res?.data?.status) {
					handleOpeningHoursModalData(modalData);
					handleModal();
				} else {
					toast.error(res?.data?.message);
				}
			} catch (err) {
				setLoading(false);
				if (err?.response?.data?.message) {
					toast.error(err?.response?.data?.message);
				}
			}
		} else {
			handleOpeningHoursModalData(modalData);
			handleModal();
		}
	};
	useEffect(() => {
		setModalData(openingHoursModalData);
	}, [openingHoursModalData]);
	return (
		<CustomModal
			title={'Venue Opening Hours'}
			size="md"
			autoHeightMin={492}
			isOpen={isOpen}
			handleModal={handleModal}
			style={{ maxWidth: '480px', width: '100%' }}
		>
			<Div>
				<div className="fs-11 medium-text">Day</div>
				<div className="fs-11 medium-text">From Time</div>
				<div className="fs-11 medium-text">To Time</div>
				<div className="fs-11 medium-text">Status</div>
				{modalData?.length > 0 &&
					modalData?.map((item) => {
						return (
							<OpeningHoursCard
								key={item?.weekDay}
								weekDay={item?.weekDay}
								activeHours={item?.activeHours}
								inActiveHours={item?.inActiveHours}
								isClosed={item?.isClosed}
								id={item?.id}
								handleModalData={handleModalData}
							/>
						);
					})}
			</Div>
			<div className="pa-t-18">
				<CustomButton
					type="submit"
					className="themeButtonFullWidth"
					onClick={handleDoneClick}
					loading={loading}
				>
					Done
				</CustomButton>
			</div>
		</CustomModal>
	);
};

export default OpeningHoursModal;
