export const AndroidIcon = ({ fill, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 27 17" fill="none">
			<path
				d="M19.6875 12.3262C18.8438 12.3262 18.2812 11.3887 18.7031 10.6387C19.1719 9.88867 20.25 9.88867 20.6719 10.6387C20.7656 10.8262 20.8125 11.0137 20.8125 11.2012C20.8125 11.8574 20.2969 12.3262 19.6875 12.3262ZM7.26562 12.3262C6.375 12.3262 5.85938 11.3887 6.28125 10.6387C6.70312 9.88867 7.78125 9.88867 8.25 10.6387C8.34375 10.8262 8.39062 11.0137 8.39062 11.2012C8.39062 11.8574 7.875 12.3262 7.26562 12.3262ZM20.1094 5.57617L22.3594 1.68555C22.5469 1.4043 22.3594 0.982422 21.9844 0.935547C21.7969 0.935547 21.6094 1.0293 21.5156 1.2168L19.2656 5.1543C15.5625 3.51367 11.3906 3.51367 7.6875 5.1543L5.4375 1.2168C5.25 0.888672 4.82812 0.841797 4.59375 1.16992C4.5 1.31055 4.5 1.54492 4.59375 1.68555L6.84375 5.57617C3 7.68555 0.375 11.5762 0 16.1699H27C26.5781 11.5762 23.9531 7.68555 20.1094 5.57617Z"
				fill={fill ?? '#FF5F5F'}
			/>
		</svg>
	);
};
