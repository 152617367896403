import TableV6 from '../../../Common/TableV6';
import TableStyle from '../../../Common/TableStyle';
import TableSkeleton from './TableSkeleton';

const PromoCodeTable = ({ loading, tableData, tableColumns }) => {
	return (
		<div>
			{loading ? (
				<>
					<TableSkeleton />
				</>
			) : (
				<TableStyle version={6}>
					<TableV6
						columns={tableColumns}
						data={tableData}
						NoDataText={'No data found'}
					/>
				</TableStyle>
			)}
		</div>
	);
};

export default PromoCodeTable;
