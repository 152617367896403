import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import CustomModal from '../../Common/Modal/CustomModal';
import RefundAmountModalSkeleton from './RefundAmountModalSkeleton';

const RefundAmountModal = ({ isOpen, handleModal, singleRowData }) => {
	const state = useSelector((state) => ({ ...state }));
	const authDetails = state.auth;
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const [totalRefundAmount, setTotalRefundAmount] = useState(null);
	const [isShowItems, setIsShowItems] = useState(false);
	const refundableTransactionFee = data?.refundTransactionFee === 'Yes';

	useEffect(() => {
		setData(singleRowData);
	}, [singleRowData]);
	useEffect(() => {
		let tRefAmt = 0.0;
		if (data?.order_items?.length > 0) {
			for (let item of data?.order_items) {
				if (data?.promocode_discount > 0) {
					let discountedAmount =
						(Number(data?.promocode_discount) / 100) *
						Number(item?.chargeAmount);
					let itemAmt =
						(Number(item?.chargeAmount) -
							Number(discountedAmount)) *
						Number(item?.refundedQuantity);
					tRefAmt = Number(tRefAmt) + itemAmt;
				} else {
					let itemAmt =
						Number(item?.chargeAmount) *
						Number(item?.refundedQuantity);
					tRefAmt = Number(tRefAmt) + itemAmt;
				}
			}
		}
		if (data?.order_refund_taxes?.length > 0) {
			for (let item of data?.order_refund_taxes) {
				tRefAmt = Number(tRefAmt) + Number(item?.amount);
			}
		}
		if (refundableTransactionFee) {
			tRefAmt = tRefAmt + Number(data?.transactionFee);
		}
		setTotalRefundAmount(Number(tRefAmt)?.toFixed(2)?.toString());
		let items = data?.order_items?.find(
			(item) => item?.refundedQuantity != 0
		);
		if (items) {
			setIsShowItems(true);
		} else {
			setIsShowItems(false);
		}
	}, [data]);
	return (
		<CustomModal
			title="Refunded Amount"
			size="lg"
			isOpen={isOpen}
			handleModal={handleModal}
			autoHeightMin={120}
		>
			{loading ? (
				<RefundAmountModalSkeleton />
			) : (
				<>
					{data?.refundStatus === 'No' ? (
						<div className="text-center pa-t-95 opacity-50">
							No Refund
						</div>
					) : (
						<>
							<div className="orderCard d-flex flex-column ptb-18">
								<div className="plr-18 d-flex justify-content-between align-items-center gap-3">
									<div>
										<p className="fs-12 medium-text themeText">
											{data?.orderServiceType === 'PICKUP'
												? data?.pickupCode
												: `Table ${data?.orderTableNumbers[0]?.tableCode}`}
										</p>
										<p className="fs-12 regular-text themeText pa-t-8">
											{moment(data?.orderDate).format(
												'DD/MM/YYYY'
											)}
										</p>
									</div>
									<p className="fs-12 medium-text themeText">
										Order #{data?.orderNo}
									</p>
								</div>
								<hr className="themeHr mtb-15" />
								<div className="d-flex flex-column flex-1 plr-18">
									{data?.order_items?.length > 0 &&
										data?.order_items?.map(
											(item, index) => {
												if (
													item?.refundedQuantity != 0
												) {
													return (
														<div
															className={`d-flex justify-content-between gap-1 ${
																index === 0
																	? ''
																	: 'pa-t-8'
															}`}
															key={index}
														>
															<div className="d-flex">
																<p className="fs-12 medium-text qtyBox d-flex align-items-center justify-content-center">
																	{
																		item?.refundedQuantity
																	}
																</p>
																<div className="pl-18">
																	<p className="fs-12 medium-text themeText">
																		{item
																			?.product
																			?.name +
																			' ' +
																			`($${Number(
																				item?.price
																			)?.toFixed(
																				2
																			)})`}
																	</p>
																	<div>
																		{item
																			?.order_product_variant_types
																			?.length >
																			0 &&
																			item?.order_product_variant_types?.map(
																				(
																					extraItem,
																					index
																				) => (
																					<p
																						key={
																							index
																						}
																						className="fs-12 medium-text opacity-50 pa-t-4"
																					>
																						{extraItem
																							?.product_variant_type
																							?.order_product_variant_sub_type
																							?.product_variant_sub_type
																							?.extraItem +
																							' ' +
																							`($${Number(
																								extraItem
																									?.product_variant_type
																									?.order_product_variant_sub_type
																									?.product_variant_sub_type
																									?.price
																							)?.toFixed(
																								2
																							)})`}
																					</p>
																				)
																			)}
																	</div>
																	<div>
																		{item
																			?.order_item_extras
																			?.length >
																			0 &&
																			item?.order_item_extras?.map(
																				(
																					extraItem,
																					index
																				) => (
																					<p
																						key={
																							index
																						}
																						className="fs-12 medium-text d-flex align-items-center opacity-50 pa-t-4"
																					>
																						{extraItem
																							?.product_extra
																							?.extraItem +
																							' ' +
																							`($${Number(
																								extraItem?.price
																							)?.toFixed(
																								2
																							)})`}
																					</p>
																				)
																			)}
																	</div>
																</div>
															</div>
															<p className="fs-12 medium-text themeText">
																${' '}
																{(
																	Number(
																		item?.chargeAmount
																	) *
																	Number(
																		item?.refundedQuantity
																	)
																)
																	?.toFixed(2)
																	?.toString()}
															</p>
														</div>
													);
												}
											}
										)}
								</div>
								{isShowItems && (
									<hr className="themeHr mtb-15" />
								)}
								{data?.order_refund_taxes?.length > 0 && (
									<>
										{data?.order_refund_taxes?.map(
											(item, index) => {
												return (
													<div
														key={index}
														className={`d-flex align-items-center justify-content-between plr-18 ${
															index === 0
																? ''
																: 'pa-t-8'
														}`}
													>
														<p className="fs-12 medium-text themeText">
															{item?.name}
														</p>
														<p className="fs-12 medium-text themeText">
															${' '}
															{Number(
																item?.amount
															)
																?.toFixed(2)
																?.toString()}
														</p>
													</div>
												);
											}
										)}
										<hr className="themeHr mtb-15" />
									</>
								)}
								{data?.coupon && (
									<>
										<div className="plr-18 d-flex justify-content-between gap-2">
											<p className="fs-16 regular-text themeText">
												{data?.coupon?.code}
											</p>
											<p className="fs-20 medium-text themeText">
												- ${' '}
												{Number(
													data?.promocode_amount
												)?.toFixed(2)}
											</p>
										</div>
										<hr className="themeHr mtb-15" />
									</>
								)}

								{refundableTransactionFee && (
									<div className="plr-18 d-flex justify-content-between gap-2">
										<p className="fs-12 regular-text themeText">
											MyTab Transaction Fee
										</p>
										<p className="fs-15 medium-text themeText">
											$ {data?.transactionFee}
										</p>
									</div>
								)}
								<hr className="themeHr mtb-15" />

								<div className="plr-18 d-flex justify-content-between gap-2">
									<p className="fs-12 regular-text themeText">
										Refunded Amount (inc. GST)
									</p>
									<p className="fs-15 medium-text themeText">
										$ {totalRefundAmount}
									</p>
								</div>
							</div>
						</>
					)}
				</>
			)}
		</CustomModal>
	);
};

export default RefundAmountModal;
