import { Button } from 'reactstrap';
import CustomModal from '../Common/Modal/CustomModal';

const MarkCompleted = ({ handleModal, isOpen }) => {
	return (
		<CustomModal
			isOpen={isOpen}
			handleModal={handleModal}
			title="Mark task as completed"
			size="md"
			autoHeightMin={120}
		>
			<div className="d-flex flex-column align-items-center justify-content-center">
				<p className="text-center pb-10 fs-16 medium-text">
					Is this task completed ? You can not recover tasks that have
					been marked as completed.
				</p>
				<div className="d-flex flex-column flex-md-row gap-2">
					<Button
						className="fs-18 medium-text borderButtonFullWidth ptb-10 plr-90"
						onClick={handleModal}
					>
						No
					</Button>
					<Button className="fs-18 medium-text themeButtonFullWidth ptb-10 plr-90">
						Yes
					</Button>
				</div>
			</div>
		</CustomModal>
	);
};

export default MarkCompleted;
