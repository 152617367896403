import styled from 'styled-components';

const PageWrapper = styled.div`
	color: ${(props) => props.layoutTheme.textColor} !important;
	.topButton {
		background: linear-gradient(
			-30deg,
			#ff9865 0%,
			#ff708d 92.95%
		) !important;
	}
	.themeButtonFullWidth2 {
		background: #fabbbb !important;
		color: ${(props) => props.layoutTheme.buttonTextColor} !important;
		border: 2px solid #f94d73 !important;
		width: 100%;
		margin-top: 30px;
		font-family: 'montserrat-medium';
		padding-block: 15px;
		font-size: 18px;
		@media only screen and (max-width: 1600px) {
			padding-block: 10px;
			font-size: 14px;
		}
	}
`;

export default PageWrapper;
