import { Button, Col, Row } from 'reactstrap';
import CustomModal from '../../../Common/Modal/CustomModal';
import FormCheckBox from '../../../Form/FormCheckBox';
import ReactFormSelect from '../../../Form/ReactFormSelect';
import WrapperDiv from './index.style';

const MenuSubHeadingsModal = ({ type, isOpen, handleModal }) => {
	const printerModalOptions = [
		{
			value: '1',
			label: 'Printer 1'
		},
		{
			value: '2',
			label: 'Printer 2'
		},
		{
			value: '3',
			label: 'Printer 3'
		}
	];

	return (
		<CustomModal
			title="Assign Your Menu Subheadings"
			size="lg"
			isOpen={isOpen}
			handleModal={handleModal}
			autoHeightMin={150}
		>
			<WrapperDiv>
				<div className="menuSubHeadingsModal">
					<form>
						<Row>
							<Col
								sm={5}
								className="d-flex flex-column justify-content-center align-items-start mb-3"
							>
								<div className="pl-32">
									<FormCheckBox name="status" label="Pizza" />
								</div>
							</Col>
							<Col sm={7}>
								<ReactFormSelect
									placeholder="Connected Printers"
									options={printerModalOptions}
									maxMenuHeight={150}
									isSearchable={false}
								/>
							</Col>
						</Row>
						<Row>
							<Col
								sm={5}
								className="d-flex flex-column justify-content-center align-items-start mb-3"
							>
								<div className="pl-32">
									<FormCheckBox
										name="status"
										label="Cold Drinks"
									/>
								</div>
							</Col>
							<Col sm={7}>
								<ReactFormSelect
									placeholder="Connected Printers"
									options={printerModalOptions}
									maxMenuHeight={150}
									isSearchable={false}
								/>
							</Col>
						</Row>
						<Row>
							<Col
								sm={5}
								className="d-flex flex-column justify-content-center align-items-start mb-3"
							>
								<div className="pl-32">
									<FormCheckBox
										name="status"
										label="Fast Food"
									/>
								</div>
							</Col>
							<Col sm={7}>
								<ReactFormSelect
									placeholder="Connected Printers"
									options={printerModalOptions}
									maxMenuHeight={150}
									isSearchable={false}
									menuPlacement="top"
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<Button
									type="button"
									className="fs-18 medium-text themeButtonFullWidth ptb-10 plr-60 mt-10 text-center mb-2"
								>
									Save
								</Button>
							</Col>
						</Row>
					</form>
				</div>
			</WrapperDiv>
		</CustomModal>
	);
};

export default MenuSubHeadingsModal;
