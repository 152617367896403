import { useState } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import 'react-tabs-scrollable/dist/rts.css';

import PageWrapper from './docketFeatures.style';
import rightImg from '../../../Assets/images/right.png';
import PageStructure from '../../../Components/Common/PageStructure';
import ActivateConfirmModal from '../../../Components/PremiumFeatures/DocketPrinting/ActivateConfirmModal';
import UnactivateConfirmModal from '../../../Components/PremiumFeatures/DocketPrinting/UnactivateConfirmModal';
import { VenueApiRoutes } from '../../../Utils/routes';
import Api from '../../../Helper/Api';
import authActions from '../../../Redux/auth/actions';
import { supportVenueEmail } from '../../../Helper/constant';

const FEATURES = [
	'Streamlined',
	'Automated docket printing',
	'7 day free trial',
	'No lock in contract'
];

export const DocketFeatures = () => {
	const dispatch = useDispatch();
	const state = useSelector((state) => ({ ...state }));
	const allThemeData = state.themeChanger;
	const authDetails = state.auth;
	const [activateLoading, setActivateLoading] = useState(false);
	const [activateConfirmModal, setActivateConfirmModal] = useState(false);
	const [unactivateConfirmModal, setUnactivateConfirmModal] = useState(false);
	const handleActivateConfirmModal = () => {
		setActivateConfirmModal((prev) => !prev);
	};
	const handleUnactivateConfirmModal = () => {
		setUnactivateConfirmModal((prev) => !prev);
	};
	const handleDocketPrintingFeatStatus = async () => {
		try {
			setActivateLoading(true);
			const res = await Api(
				'POST',
				VenueApiRoutes.docketPrintingFeatStatus,
				{
					bar_id: authDetails?.selectedVenue?.id
				}
			);
			if (res?.data?.status) {
				dispatch(
					authActions.set_docket_printing_feature({
						barId: authDetails?.selectedVenue.id,
						docketStatus: res?.data?.data?.currentStatus ? 1 : 0
					})
				);
				if (res?.data?.data?.currentStatus) {
					setActivateConfirmModal(false);
				} else {
					setUnactivateConfirmModal(false);
				}
				toast.success(res?.data?.message);
			} else {
				toast.error(res?.data?.message);
			}
			setActivateLoading(false);
		} catch (err) {
			setActivateLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	return (
		<>
			<PageStructure title="Welcome to MyTab's Premium+ Docket Printing Feature">
				<PageWrapper {...allThemeData}>
					{Number(authDetails?.selectedVenue?.docketStatus) ? (
						<>
							<div>
								<p className="fs-12 medium-text text-left pa-b-12">
									Congratulations, the Docket Printing Feature
									has been activated. You will now be able to
									connect the relevant Epson docket printers
									to your venue iPad(s) via the "Docket
									Printing" screen under account settings on
									your venue app. Once connected, please
									follow the prompts to assign the appropriate
									menu item subheadings to each connected
									printer. Please also ensure your iPad is
									connected to the same wifi as the Epson
									docket printers.
								</p>
								<p className="fs-12 medium-text text-left pa-b-12">
									Pricing for MyTab's Premium+ Docket Printing
									Fee is{' '}
									{(authDetails?.selectedVenue
										?.docketCommission
										? authDetails?.selectedVenue
												?.docketCommission
										: '1') + '%'}{' '}
									per transaction (automatically deducted by
									Stripe).
								</p>
								<p className="fs-12 medium-text text-left">
									If you require assistance, please contact us
									at{' '}
									<span
										className="fs-12 semi-bold-text headingTextColor text-decoration-underline cursor-pointer"
										onClick={() => {
											window.open(
												'mailto:' + supportVenueEmail,
												'_blank'
											);
										}}
									>
										{supportVenueEmail}
									</span>
									.
								</p>
							</div>
							<div className="pt-32">
								{authDetails?.selectedVenue?.docketStatus ? (
									<div className="pa-t-6">
										<Button
											className="themeButton"
											onClick={() =>
												setUnactivateConfirmModal(true)
											}
										>
											Deactivate Feature
										</Button>
									</div>
								) : (
									''
								)}
							</div>
						</>
					) : (
						''
					)}
					{!Number(authDetails?.selectedVenue?.docketStatus) ? (
						<>
							<div>
								<p className="fs-12 medium-text text-left pa-b-12">
									MyTab's docket printing feature allows a
									seamless, wireless connection to your
									venue's existing Epson POS docket printers *
								</p>
								<p className="fs-12 medium-text text-left pa-b-12">
									This feature enables all customer orders to
									automatically print to the specific printers
									designated for individual menu sub-headings,
									so you can easily separate your drink and
									food dockets for your chefs and staff.
								</p>
								<p className="fs-12 medium-text text-left">
									Pricing for MyTab's Premium+ Docket Printing
									Fee is{' '}
									{(authDetails?.selectedVenue
										?.docketCommission
										? authDetails?.selectedVenue
												?.docketCommission
										: '1') + '%'}{' '}
									per transaction (automatically deducted by
									Stripe).
								</p>
							</div>
							<div className="horizontalLine mtb-24" />
							<div>
								{FEATURES?.length > 0 &&
									FEATURES?.map((item, index) => {
										return (
											<div
												className={`d-flex align-items-center ${
													index === 0 ? '' : 'pa-t-13'
												}`}
												key={index}
											>
												<div>
													<img
														src={rightImg}
														alt="right_icon_img"
														className="rightImage"
													/>
												</div>
												<span className="fs-12 medium-text pl-9">
													{item}
												</span>
											</div>
										);
									})}
								<div className="pa-t-18">
									<Button
										className="themeButton"
										onClick={() =>
											setActivateConfirmModal(true)
										}
									>
										Activate Feature
									</Button>
								</div>
							</div>
						</>
					) : (
						''
					)}
					<div className="horizontalLine mtb-18" />
					<p className="fs-11 medium-text text-left">
						*MyTab's docket printing feature is not suitable for all
						printers. Please check compatibility via the FAQ page
						prior to subscribing, or alternatively contact us at
						{' ' + supportVenueEmail}. If your existing docket
						printers are not compatible, you will need to purchase
						compatible EPSON printers to utilise this feature.*
					</p>
				</PageWrapper>
			</PageStructure>
			<ActivateConfirmModal
				isOpen={activateConfirmModal}
				handleModal={handleActivateConfirmModal}
				activateLoading={activateLoading}
				handleDocketPrintingFeatStatus={handleDocketPrintingFeatStatus}
			/>
			<UnactivateConfirmModal
				isOpen={unactivateConfirmModal}
				handleModal={handleUnactivateConfirmModal}
				activateLoading={activateLoading}
				handleDocketPrintingFeatStatus={handleDocketPrintingFeatStatus}
			/>
		</>
	);
};
