import React from 'react';
import PageWrapper from './index.style';
import createAccountImage from '../../Assets/images/doshii-img.png';
import CustomButton from '../../Components/Common/CustomButton';
import OutlinedButton from '../../Components/Common/CustomButton/OutlinedButton';
import Agreement from '../../Components/Authentication/Agreement';
import AcceptIcon from '../../Assets/images/green-check.png';

const PosIntegration = () => {
	const checkedList = [
		{
			key: 0,
			text: 'Doshii integrates with the hospitality industry’s leading POS systems'
		},
		{
			key: 1,
			text: 'Sync your POS menu to MyTab'
		},
		{
			key: 2,
			text: 'Centralise your sales analytics'
		},
		{
			key: 3,
			text: 'No lock in contract'
		}
	];
	return (
		<PageWrapper>
			<div className="formWrapper pl-26 pr-45 pr-xl-[70px]">
				<div>
					<h1 className="headingStyle maxHeading">
						Get MyTab talking to your POS
					</h1>
					<p className="smallInfo">
						Send all MyTab customer orders directly to your POS to
						reduce double-handling and mistakes. Remove counter
						clutter with one central place to manage all of your
						customer orders. Running a business shouldn't be harder
						than it needs to be. Doshii makes managing your venue's
						technology simple so you can focus on doing what you do
						best.
						<br />
						<br />
						Pricing for POS Integration is $30/month <b>+1.5</b>
						% per transaction.
					</p>
					<div className="d-flex flex-column gap-3">
						{checkedList.map((list) => (
							<div
								key={list}
								className="d-flex  align-items-center gap-1"
							>
								<img
									src={AcceptIcon}
									alt="not-accept"
									className="checkIcon"
								/>
								<p className="smallInfo m-0">{list.text}</p>
							</div>
						))}
					</div>
					<div className="gap-2 d-flex flex-column mt-4">
						<CustomButton
							type="submit"
							className="newThemeButtonFullWidth"
						>
							Activate POS Integration
						</CustomButton>
						<OutlinedButton
							buttonTitle={
								'Continue with MyTab Venue Standard Version'
							}
						/>
					</div>
				</div>

				<Agreement />
			</div>
			<div className="imageWrapper">
				<div className="backWrraper">
					<p className="text">
						MyTab partners with Doshii for seamless POS Integration
					</p>
					<img
						src={createAccountImage}
						alt="side-img"
						className="image"
					/>
				</div>
			</div>
		</PageWrapper>
	);
};

export default PosIntegration;
