export const UserSlashIcon = ({ fill, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 26 21" fill="none">
			<path
				d="M25.1094 19.0293L14.2891 10.5137C16.4375 9.92773 18 8.01367 18 5.66992C17.9609 2.93555 15.7344 0.669922 12.9609 0.669922C10.2656 0.669922 8.03906 2.85742 8 5.5918L1.98438 0.904297C1.82812 0.748047 1.63281 0.669922 1.4375 0.669922C1.125 0.669922 0.851562 0.826172 0.695312 1.06055C0.34375 1.45117 0.421875 2.03711 0.851562 2.34961L23.9766 20.4746C24.3672 20.8262 24.9531 20.748 25.2656 20.3184C25.6172 19.9277 25.5391 19.3418 25.1094 19.0293ZM11.8281 8.59961L10.4609 7.50586C10.0703 6.99805 9.875 6.37305 9.875 5.66992C9.875 3.95117 11.2422 2.54492 13 2.54492C14.7188 2.54492 16.125 3.95117 16.125 5.66992C16.125 7.42773 14.7188 8.79492 13 8.79492C12.5703 8.79492 12.1797 8.7168 11.8281 8.59961ZM6.125 18.7949C6.4375 16.334 8.54688 14.4199 11.125 14.4199H13.1953L10.8125 12.584C7.14062 12.7012 4.25 15.748 4.25 19.4199C4.25 20.123 4.79688 20.6699 5.5 20.6699H20.5C20.6562 20.6699 20.8516 20.6309 21.0078 20.5527L18.7812 18.7949H6.125Z"
				fill={fill ?? '#FF5F5F'}
			/>
		</svg>
	);
};
