export const BellIcon2 = ({ fill, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 30 30" fill="none">
			<path
				d="M25.6614 23.6129C25.6647 23.7343 25.6304 23.7742 25.5052 23.7728C24.9765 23.7662 24.4478 23.77 23.9195 23.77H15.0042C11.5214 23.77 8.03907 23.7686 4.55628 23.7738C4.38835 23.7738 4.33379 23.7366 4.34272 23.563C4.35825 23.2705 4.35213 22.976 4.34413 22.6825C4.34131 22.5667 4.37894 22.4863 4.45985 22.4059C5.21389 21.6565 5.96276 20.902 6.7168 20.1527C6.8104 20.06 6.85556 19.9692 6.85509 19.8323C6.85039 17.5364 6.83298 15.2399 6.85744 12.9439C6.87579 11.1757 7.45249 9.58437 8.53063 8.18166C9.52692 6.88573 10.8012 5.96987 12.3446 5.4308C12.5544 5.35742 12.7684 5.29251 12.9848 5.24123C13.093 5.21489 13.1221 5.16926 13.1235 5.06248C13.1287 4.7111 13.0911 4.35831 13.1527 4.00833C13.2985 3.17856 14.0723 2.41652 15.158 2.47673C15.9892 2.5233 16.7573 3.24724 16.8603 4.11229C16.897 4.42322 16.8834 4.73368 16.882 5.04461C16.8815 5.16691 16.9173 5.21489 17.0382 5.24547C18.4959 5.61614 19.7556 6.34524 20.8022 7.41633C22.1344 8.78 22.911 10.417 23.0968 12.3235C23.1354 12.721 23.1533 13.1194 23.1533 13.5187C23.1533 15.6209 23.1547 17.7236 23.1504 19.8262C23.1504 19.9598 23.189 20.0544 23.284 20.1489C24.0376 20.8987 24.7846 21.6551 25.5367 22.4054C25.6219 22.4905 25.6652 22.5738 25.6619 22.6966C25.6534 23.0019 25.6539 23.3076 25.6619 23.6129H25.6614Z"
				fill={fill ?? '#F94D73'}
			/>
			<path
				d="M8.69737 4.55501C7.8803 5.15006 7.16624 5.85236 6.55379 6.65391C5.39663 8.16716 4.68492 9.86763 4.44361 11.7605C4.41868 11.9581 4.3914 12.1566 4.38622 12.3551C4.38293 12.4783 4.33354 12.4971 4.2277 12.4957C3.85797 12.4905 3.48824 12.4938 3.11804 12.4938C2.74784 12.4938 2.40163 12.4858 2.04413 12.4971C1.89737 12.5018 1.86821 12.451 1.8762 12.3174C1.94159 11.2115 2.14527 10.132 2.48772 9.07925C3.25681 6.71647 4.60448 4.73988 6.47994 3.11749C6.63517 2.98296 6.80263 2.86348 6.95927 2.73083C7.02136 2.67815 7.05899 2.6772 7.11779 2.73647C7.65969 3.28213 8.20205 3.82732 8.75053 4.36591C8.87001 4.48304 8.75852 4.5108 8.69737 4.55501Z"
				fill={fill ?? '#F94D73'}
			/>
			<path
				d="M28.0029 12.4956C27.6275 12.4904 27.2517 12.4932 26.8758 12.4937C26.5296 12.4937 26.1825 12.4791 25.8377 12.4993C25.6406 12.5106 25.6053 12.426 25.594 12.2623C25.5465 11.5703 25.4256 10.8897 25.2327 10.2241C24.561 7.90408 23.258 6.01639 21.328 4.56429C21.2123 4.4768 21.1756 4.43352 21.3017 4.31169C21.8252 3.80602 22.3375 3.28906 22.8493 2.77162C22.9288 2.69119 22.9749 2.67519 23.0741 2.75375C25.6523 4.80372 27.2667 7.44074 27.9093 10.6709C28.0198 11.2288 28.0706 11.7956 28.1224 12.362C28.1318 12.4617 28.1064 12.4965 28.0029 12.4956Z"
				fill={fill ?? '#F94D73'}
			/>
			<path
				d="M17.4993 25.1934C17.4316 26.541 16.2227 27.5745 14.8656 27.5246C13.7752 27.4846 12.5659 26.5326 12.5028 25.1849C12.4967 25.0565 12.5207 25.0259 12.6458 25.0264C13.4318 25.0315 14.2183 25.0292 15.0044 25.0292C15.7904 25.0292 16.5534 25.0311 17.3276 25.0268C17.4528 25.0264 17.5069 25.0381 17.4993 25.1934Z"
				fill={fill ?? '#F94D73'}
			/>
		</svg>
	);
};
