import styled from 'styled-components';

const ItemCard = styled.div`
	border: 1px solid #dddddd;
	border-radius: 6px;
	background-color: '#ffffff';
	padding: 18px;
	padding-left: ${(props) =>
		props.isPopularExist && props.menuItemId != 0
			? '24px !important'
			: !props.isPopularExist
			? '24px !important'
			: '18px !important'};
	opacity: ${(props) => (props.withOpacity ? '0.3' : '1')};
	transform-origin: '50% 50%';
	transform: ${(props) => (props.isDragging ? 'scale(1.05)' : 'scale(1)')};
	display: flex;
	gap: 22px;
	position: relative;
	cursor: pointer;
	.itemImageWrapper img {
		width: 90px;
		height: 96px;
		border-radius: 6px;
		object-fit: cover;
	}
	.itemBody {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.dragIconWrapper {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}
	.itemTitle {
		text-overflow: ellipsis;
		overflow: hidden;
		display: -webkit-box !important;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		white-space: normal;
	}
	.itemDescription {
		text-overflow: ellipsis;
		overflow: hidden;
		display: -webkit-box !important;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		white-space: normal;
	}
`;

export default ItemCard;
