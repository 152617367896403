const TotalVenueCard = () => {
	return (
		<div className="d-flex flex-column gap-3">
			<div className="fs-20 semi-bold-text">Total MyTab Venues</div>
			<div className="d-flex flex-column flex-lg-row justify-content-center gap-3 p-1 mb-6 totalTabVenue">
				<div className="totalCard d-flex flex-1 flex-row flex-lg-column align-items-center align-items-lg-start p-2">
					<div className="themeText d-flex align-items-center flex-1">
						<div className="totalCardDot"></div>
						<div className="fs-16 semi-bold-text">
							<span className="fs-30 semi-bold-text">25</span> k
						</div>
					</div>
					<div className="totalCardTitle headingTextColor flex-1 text-right">
						<p className="fs-14 medium-text">Active Venues</p>
					</div>
				</div>
				<div className="totalCard d-flex flex-1 flex-row flex-lg-column align-items-center align-items-lg-start p-2">
					<div className="themeText d-flex align-items-center flex-1">
						<div className="totalCardDot"></div>
						<div className="fs-16 semi-bold-text">
							<span className="fs-30 semi-bold-text">2.5</span> k
						</div>
					</div>
					<div className="totalCardTitle headingTextColor flex-1 text-right">
						<p className="fs-14 medium-text">
							Venues with Premium+ Features
						</p>
					</div>
				</div>
				<div className="totalCard d-flex flex-1 flex-row flex-lg-column align-items-center align-items-lg-start p-2">
					<div className="themeText d-flex align-items-center flex-1">
						<div className="totalCardDot"></div>
						<div className="fs-16 semi-bold-text">
							<span className="fs-30 semi-bold-text">1.1</span> k
						</div>
					</div>
					<div className="totalCardTitle headingTextColor flex-1 text-right">
						<p className="fs-14 medium-text">Deleted Accounts</p>
					</div>
				</div>
				<div className="totalCard d-flex flex-1 flex-row flex-lg-column align-items-center align-items-lg-start p-2">
					<div className="themeText d-flex align-items-center flex-1">
						<div className="totalCardDot"></div>
						<div className="fs-16 semi-bold-text">
							<span className="fs-30 semi-bold-text">2.5</span> k
						</div>
					</div>
					<div className="totalCardTitle headingTextColor flex-1 text-right">
						<p className="fs-14 medium-text">Products Listed</p>
					</div>
				</div>
				<div className="totalCard d-flex flex-1 flex-row flex-lg-column align-items-center align-items-lg-start p-2">
					<div className="themeText d-flex align-items-center flex-1">
						<div className="totalCardDot"></div>
						<div className="fs-16 semi-bold-text">
							<span className="fs-30 semi-bold-text">4</span> k
						</div>
					</div>
					<div className="totalCardTitle headingTextColor flex-1 text-right">
						<p className="fs-14 medium-text">
							Table Service Orders
						</p>
					</div>
				</div>
				<div className="totalCard d-flex flex-1 flex-row flex-lg-column align-items-center align-items-lg-start p-2">
					<div className="themeText d-flex align-items-center flex-1">
						<div className="totalCardDot"></div>
						<div className="fs-16 semi-bold-text">
							<span className="fs-30 semi-bold-text">3</span> k
						</div>
					</div>
					<div className="totalCardTitle headingTextColor flex-1 text-right">
						<p className="fs-14 medium-text">
							Pickup/Collect Orders
						</p>
					</div>
				</div>
				<div className="totalCard d-flex flex-1 flex-row flex-lg-column align-items-center align-items-lg-start p-2">
					<div className="themeText d-flex align-items-center flex-1">
						<div className="totalCardDot"></div>
						<div className="fs-16 semi-bold-text">
							<span className="fs-30 semi-bold-text">2.5</span> k
						</div>
					</div>
					<div className="totalCardTitle headingTextColor flex-1 text-right">
						<p className="fs-14 medium-text">Room Service Orders</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TotalVenueCard;
