import React from 'react';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null };
	}

	componentDidCatch(error, errorInfo) {
		// Catch errors in any components below and re-render with error message
		this.setState({
			error: error,
			errorInfo: errorInfo
		});
		// You can also log error messages to an error reporting service here
	}

	componentDidUpdate() {}

	render() {
		let { error, errorInfo } = this.state;
		if (process.env.REACT_APP_PRODUCTION_FLAG === 'true') {
			return (
				<div>
					<p>Something went wrong. Please refresh page!</p>
				</div>
			);
		} else if (errorInfo) {
			return (
				<div>
					<h3>{error?.message || 'Ohh no error occurred!'}</h3>
					<span>
						{error?.stack ||
							'No stack trace available, open console for more details.'}
					</span>
				</div>
			);
		}
		// Normally, just render children
		return this.props.children;
	}
}

export default ErrorBoundary;
