import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	Button,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle
} from 'reactstrap';
import { defineds } from '../../../Helper/DateHelper';
import CustomDateRangePicker from '../../../Components/Common/DateRangePicker/CustomDateRangePicker';
import FormInputGroup from '../../../Components/Form/FormInputGroup';
import {
	DownArrowIcon,
	ExportIcon,
	FilterIcon,
	MagnifyingGlassIcon,
	UpArrowIcon
} from '../../../Components/Icons';
import useDevice from '../../../Hooks/useDevice';
import { HeaderWrapper } from './index.style';
import { ordersTypeFilter } from '../utils';
import CustomButton from '../../Common/CustomButton';

const TableFilter = ({
	handleSearchInputChange,
	handleExport,
	exportLoading,
	handleDateRangeChange,
	handleOrderTypeChange
}) => {
	const state = useSelector((state) => ({ ...state }));
	const allThemeData = state.themeChanger;
	const [loading, setLoading] = useState(false);
	const [dateRangePickerLabel, setDateRangePickerLabel] = useState('Today');
	const [dateDropdown, setDateDropdown] = useState(false);
	const [orderTypeDropdown, setOrderTypeDropdown] = useState(false);
	const [orderTypeLabel, setOrderTypeLabel] = useState('All Orders');
	const [orderTypeValue, setOrderTypeValue] = useState('');
	const [dateRange, setDateRange] = useState([
		defineds.startOfToday,
		defineds.endOfToday
	]);
	const { isTablet } = useDevice();

	const handleDateRange = (ranges) => {
		if (ranges[0] && ranges[1]) {
			setDateDropdown((prev) => !prev);
			setDateRangePickerLabel(
				ranges[0].toLocaleDateString() +
					' - ' +
					ranges[1].toLocaleDateString()
			);
		}
		setDateRange(ranges);
	};
	const handleDateRangeLabel = (label) => {
		setDateRangePickerLabel(label);
		if (label === 'Today') {
			setDateRange([defineds.startOfToday, defineds.endOfToday]);
		} else if (label === 'Yesterday') {
			setDateRange([defineds.startOfYesterday, defineds.endOfYesterday]);
		} else if (label === 'This Week') {
			setDateRange([defineds.startOfWeek, defineds.endOfWeek]);
		} else if (label === 'This Month') {
			setDateRange([defineds.startOfMonth, defineds.endOfToday]);
		} else if (label === 'This Quarter') {
			setDateRange([defineds.startOfQuarter, defineds.endOfToday]);
		} else if (label === 'This Calendar Year') {
			setDateRange([defineds.startOfYear, defineds.endOfToday]);
		} else {
			setDateRange([null, null]);
		}
		setDateDropdown((prev) => !prev);
	};
	useEffect(() => {
		handleDateRangeChange(dateRange);
	}, [dateRange]);
	useEffect(() => {
		handleOrderTypeChange(orderTypeValue);
	}, [orderTypeValue]);
	return (
		<HeaderWrapper {...allThemeData}>
			<div className="filterWrapper pa-t-24">
				<div className="dateDropdownBox d-flex align-items-center">
					<Dropdown
						isOpen={dateDropdown}
						direction="down"
						className="dashboard-dropdown dateDropdown"
						toggle={() => setDateDropdown((prev) => !prev)}
					>
						<DropdownToggle
							color="#fff"
							className="dropdownToggle d-flex justify-content-between align-items-center dateDropdownToggle"
						>
							<span
								className={`dropdown-name fs-11 medium-text flex-1`}
							>
								{dateRangePickerLabel}
							</span>
							<span className="pl-10">
								{dateDropdown ? (
									<UpArrowIcon height={6} width={10} />
								) : (
									<DownArrowIcon height={6} width={10} />
								)}
							</span>
						</DropdownToggle>
						{/* Date Picker */}
						<DropdownMenu
							className={`datePickerDropdown ${
								isTablet ? '' : ''
							} pt-12 pl-4 pr-12`}
							// end={isTablet}
							style={{ maxHeight: '320px' }}
						>
							<div className="d-flex flex-column flex-sm-row">
								<div className="dropdownCalenderParent">
									<div className="p-0 dropdown-item dropdownCalender">
										<CustomDateRangePicker
											dateRange={dateRange}
											handleDateRange={handleDateRange}
										/>
									</div>
								</div>
								<div>
									<DropdownItem
										className={`${
											dateRangePickerLabel === 'Today'
												? 'active'
												: ''
										} calenderItem`}
										onClick={() =>
											handleDateRangeLabel('Today')
										}
									>
										Today
									</DropdownItem>
									<DropdownItem
										className={`${
											dateRangePickerLabel === 'Yesterday'
												? 'active'
												: ''
										} calenderItem`}
										onClick={() =>
											handleDateRangeLabel('Yesterday')
										}
									>
										Yesterday
									</DropdownItem>
									<DropdownItem
										className={`${
											dateRangePickerLabel === 'This Week'
												? 'active'
												: ''
										} calenderItem`}
										onClick={() =>
											handleDateRangeLabel('This Week')
										}
									>
										This Week
									</DropdownItem>
									<DropdownItem
										className={`${
											dateRangePickerLabel ===
											'This Month'
												? 'active'
												: ''
										} calenderItem`}
										onClick={() =>
											handleDateRangeLabel('This Month')
										}
									>
										This Month
									</DropdownItem>
									<DropdownItem
										className={`${
											dateRangePickerLabel ===
											'This Quarter'
												? 'active'
												: ''
										} calenderItem`}
										onClick={() =>
											handleDateRangeLabel('This Quarter')
										}
									>
										This Quarter
									</DropdownItem>
									<DropdownItem
										className={`${
											dateRangePickerLabel ===
											'This Calendar Year'
												? 'active'
												: ''
										} calenderItem`}
										onClick={() =>
											handleDateRangeLabel(
												'This Calendar Year'
											)
										}
									>
										This Calendar Year
									</DropdownItem>
									<DropdownItem
										className={`${
											dateRangePickerLabel === 'All Time'
												? 'active'
												: ''
										} calenderItem`}
										onClick={() =>
											handleDateRangeLabel('All Time')
										}
									>
										All Time
									</DropdownItem>
								</div>
							</div>
						</DropdownMenu>
					</Dropdown>
				</div>
				<div className="threeButtonWrapper">
					<div className="searchBoxWrapper">
						<FormInputGroup
							type="text"
							name="search"
							placeholder="Search Order ID"
							icon={
								<MagnifyingGlassIcon height={18} width={18} />
							}
							iconPlacement="start"
							onChange={(event) => {
								handleSearchInputChange(event?.target?.value);
							}}
						/>
					</div>
					<div className="orderTypeDropdownBox d-flex align-items-center">
						<Dropdown
							isOpen={orderTypeDropdown}
							toggle={() => setOrderTypeDropdown((prev) => !prev)}
							direction="down"
							className="dashboard-dropdown categoryDropdown"
						>
							<DropdownToggle
								color="#fff"
								className="dropdownToggle d-flex justify-content-between align-items-center categoryDropdownToggle"
							>
								<span
									className={`dropdown-name fs-11 medium-text flex-1 d-flex align-items-center categoryDropdownName`}
								>
									<span className="pr-8">
										<FilterIcon height={18} width={18} />{' '}
									</span>
									{orderTypeLabel}
								</span>
								<span className="">
									{orderTypeDropdown ? (
										<UpArrowIcon height={6} width={10} />
									) : (
										<DownArrowIcon height={6} width={10} />
									)}
								</span>
							</DropdownToggle>
							<DropdownMenu
								className={`${isTablet ? 'w-100' : ''} p-0`}
								end={isTablet}
								style={{ zIndex: '100' }}
							>
								<DropdownItem
									className={`${
										orderTypeLabel === 'All Orders'
											? 'active'
											: ''
									} categoryItem`}
									onClick={() => {
										setOrderTypeLabel('All Orders');
										setOrderTypeValue('');
									}}
									style={{ borderRadius: '6px 6px 0 0' }}
								>
									All Orders
								</DropdownItem>
								{ordersTypeFilter?.length > 0 &&
									ordersTypeFilter?.map((item, index) => {
										return (
											<DropdownItem
												key={index}
												className={`${
													orderTypeLabel ===
													item?.name
														? 'active'
														: ''
												} categoryItem`}
												onClick={() => {
													setOrderTypeLabel(
														item?.name
													);
													setOrderTypeValue(
														item?.value
													);
												}}
												style={{
													borderRadius:
														index ===
														ordersTypeFilter?.length -
															1
															? '0 0 6px 6px'
															: ''
												}}
											>
												{item?.name}
											</DropdownItem>
										);
									})}
							</DropdownMenu>
						</Dropdown>
					</div>
					<CustomButton
						type="button"
						className="themeButton"
						onClick={handleExport}
						loading={exportLoading}
					>
						<ExportIcon height={16} width={18} />
						<span className="pl-10 fs-11 medium-text">Export</span>
					</CustomButton>
				</div>
			</div>
		</HeaderWrapper>
	);
};

export default TableFilter;
