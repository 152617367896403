import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { Button, Card, CardBody, CardSubtitle } from 'reactstrap';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useState } from 'react';

import * as validation from '../../Helper/YupValidation';
import Api from '../../Helper/Api';
import FormInput from '../Form/FormInput';
import { BackIcon } from '../Icons';
import {
	AdminApiRoutes,
	CommonRoutes,
	VenueApiRoutes
} from '../../Utils/routes';
import CustomButton from '../Common/CustomButton';
import NewFormInput from '../Form/NewFormInput';
import Agreement from './Agreement';
import ForgetPasswordStyle from './forgetPasswordForm.style';

const ForgotPasswordForm = ({ type }) => {
	const navigate = useNavigate();
	const roleData = useSelector((state) => ({ ...state.auth }));
	const [loading, setLoading] = useState(false);

	const validationSchema = yup.object().shape({
		email: validation.YUP_VALIDATION.EMAIL
	});

	const handelOnsubmit = async () => {
		const payload = {
			email: forgotPasswordFormik?.values?.email
		};
		forgotPasswordFormik.resetForm();

		let api =
			roleData.login_type === 'venue'
				? VenueApiRoutes.forgotPassword
				: AdminApiRoutes.forgotPassword;
		try {
			setLoading(true);
			const res = await Api('POST', api, payload);
			setLoading(false);
			if (res?.data?.status) {
				forgotPasswordFormik.resetForm();
				toast.success(res?.data?.message);
				navigate(CommonRoutes.login);
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};

	const forgotPasswordFormik = useFormik({
		initialValues: {
			email: ''
		},
		validationSchema,
		onSubmit: handelOnsubmit
	});

	return (
		<ForgetPasswordStyle>
			<div className="formCard">
				<div>
					<div>
						<h1 className="headingStyle">Forgot your password?</h1>
						<p className="paragraph mt-1">
							No worries, enter the email you used to create your
							Management Portal and we will send you an email with
							instructions on how to reset your password.
						</p>
						<form
							className="mt-3"
							onSubmit={forgotPasswordFormik.handleSubmit}
						>
							<NewFormInput
								id="email"
								showRequired
								tootlTipMessage="Please enter the email used for your Management Portal."
								type="email"
								name="email"
								label="Email"
								placeholder="Email"
								error={
									forgotPasswordFormik?.touched?.email &&
									forgotPasswordFormik?.errors?.email
								}
								errorMsg={forgotPasswordFormik?.errors?.email}
								value={forgotPasswordFormik?.values?.email}
								onChange={forgotPasswordFormik.handleChange}
								onBlur={forgotPasswordFormik.handleBlur}
							/>

							<div className="pa-t-10">
								<CustomButton
									type="submit"
									className="newThemeButtonFullWidth"
									loading={loading}
								>
									Send email
								</CustomButton>
							</div>
							<p className="helperLink">
								Remember your password? Click{' '}
								<span
									className="helperLinkSpan"
									onClick={() =>
										navigate(
											`${
												type === 'venue'
													? '/login'
													: '/admin/login'
											}`
										)
									}
								>
									here
								</span>{' '}
								to go back.
							</p>
						</form>
					</div>
				</div>
			</div>
		</ForgetPasswordStyle>
	);
};

export default ForgotPasswordForm;
