import React from 'react';
import TableStyle from '../../Common/TableStyle';
import TableV6 from '../../Common/TableV6';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const data = [
	{
		id: 1,
		order_date: '05/05/2023',
		customer: 'Soham Sheth',
		order_no: 'VEN9871865',
		orderServiceType: 'PICKUP',
		pickupCode: 'DAS',
		tableCode: '34',
		order_status: 'New',
		payment_status: 'payment status',
		taxes: '21',
		taxes_percentage: '20',
		promo_code: '233',
		promo_code_number: '1',
		total_amount: '5000',
		mytab_fee: '100',
		stripe_transaction_fee: '1000',
		refund: 'refund',
		refund_amount: '1000',
		net_revenue: '50000'
	},
	{
		id: 1,
		order_date: '05/05/2023',
		customer: 'Soham Sheth',
		order_no: 'VEN9871865',
		orderServiceType: 'PICKUP',
		pickupCode: 'DAS',
		tableCode: '34',
		order_status: 'New',
		payment_status: 'payment status',
		taxes: '21',
		taxes_percentage: '20',
		promo_code: '233',
		promo_code_number: '1',
		total_amount: '5000',
		mytab_fee: '100',
		stripe_transaction_fee: '1000',
		refund: 'refund',
		refund_amount: '1000',
		net_revenue: '50000'
	},
	{
		id: 1,
		order_date: '05/05/2023',
		customer: 'Soham Sheth',
		order_no: 'VEN9871865',
		orderServiceType: 'PICKUP',
		pickupCode: 'DAS',
		tableCode: '34',
		order_status: 'New',
		payment_status: 'payment status',
		taxes: '21',
		taxes_percentage: '20',
		promo_code: '233',
		promo_code_number: '1',
		total_amount: '5000',
		mytab_fee: '100',
		stripe_transaction_fee: '1000',
		refund: 'refund',
		refund_amount: '1000',
		net_revenue: '50000'
	},
	{
		id: 1,
		order_date: '05/05/2023',
		customer: 'Soham Sheth',
		order_no: 'VEN9871865',
		orderServiceType: 'PICKUP',
		pickupCode: 'DAS',
		tableCode: '34',
		order_status: 'New',
		payment_status: 'payment status',
		taxes: '21',
		taxes_percentage: '20',
		promo_code: '233',
		promo_code_number: '1',
		total_amount: '5000',
		mytab_fee: '100',
		stripe_transaction_fee: '1000',
		refund: 'refund',
		refund_amount: '1000',
		net_revenue: '50000'
	},
	{
		id: 1,
		order_date: '05/05/2023',
		customer: 'Soham Sheth',
		order_no: 'VEN9871865',
		orderServiceType: 'PICKUP',
		pickupCode: 'DAS',
		tableCode: '34',
		order_status: 'New',
		payment_status: 'payment status',
		taxes: '21',
		taxes_percentage: '20',
		promo_code: '233',
		promo_code_number: '1',
		total_amount: '5000',
		mytab_fee: '100',
		stripe_transaction_fee: '1000',
		refund: 'refund',
		refund_amount: '1000',
		net_revenue: '50000'
	},
	{
		id: 1,
		order_date: '05/05/2023',
		customer: 'Soham Sheth',
		order_no: 'VEN9871865',
		orderServiceType: 'PICKUP',
		pickupCode: 'DAS',
		tableCode: '34',
		order_status: 'New',
		payment_status: 'payment status',
		taxes: '21',
		taxes_percentage: '20',
		promo_code: '233',
		promo_code_number: '1',
		total_amount: '5000',
		mytab_fee: '100',
		stripe_transaction_fee: '1000',
		refund: 'refund',
		refund_amount: '1000',
		net_revenue: '50000'
	},
	{
		id: 1,
		order_date: '05/05/2023',
		customer: 'Soham Sheth',
		order_no: 'VEN9871865',
		orderServiceType: 'PICKUP',
		pickupCode: 'DAS',
		tableCode: '34',
		order_status: 'New',
		payment_status: 'payment status',
		taxes: '21',
		taxes_percentage: '20',
		promo_code: '233',
		promo_code_number: '1',
		total_amount: '5000',
		mytab_fee: '100',
		stripe_transaction_fee: '1000',
		refund: 'refund',
		refund_amount: '1000',
		net_revenue: '50000'
	},
	{
		id: 1,
		order_date: '05/05/2023',
		customer: 'Soham Sheth',
		order_no: 'VEN9871865',
		orderServiceType: 'PICKUP',
		pickupCode: 'DAS',
		tableCode: '34',
		order_status: 'New',
		payment_status: 'payment status',
		taxes: '21',
		taxes_percentage: '20',
		promo_code: '233',
		promo_code_number: '1',
		total_amount: '5000',
		mytab_fee: '100',
		stripe_transaction_fee: '1000',
		refund: 'refund',
		refund_amount: '1000',
		net_revenue: '50000'
	},
	{
		id: 1,
		order_date: '05/05/2023',
		customer: 'Soham Sheth',
		order_no: 'VEN9871865',
		orderServiceType: 'PICKUP',
		pickupCode: 'DAS',
		tableCode: '34',
		order_status: 'New',
		payment_status: 'payment status',
		taxes: '21',
		taxes_percentage: '20',
		promo_code: '233',
		promo_code_number: '1',
		total_amount: '5000',
		mytab_fee: '100',
		stripe_transaction_fee: '1000',
		refund: 'refund',
		refund_amount: '1000',
		net_revenue: '50000'
	},
	{
		id: 1,
		order_date: '05/05/2023',
		customer: 'Soham Sheth',
		order_no: 'VEN9871865',
		orderServiceType: 'PICKUP',
		pickupCode: 'DAS',
		tableCode: '34',
		order_status: 'New',
		payment_status: 'payment status',
		taxes: '21',
		taxes_percentage: '20',
		promo_code: '233',
		promo_code_number: '1',
		total_amount: '5000',
		mytab_fee: '100',
		stripe_transaction_fee: '1000',
		refund: 'refund',
		refund_amount: '1000',
		net_revenue: '50000'
	}
];
const columns = [
	{
		Header: 'Order Date',
		accessor: 'order_date',
		className: 'fs-12 regular-text',
		minWidth: 124,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: <span className="fs-12 medium-text">Customer</span>,
		accessor: 'customer',
		className: 'fs-12 regular-text justify-content-start',
		minWidth: 220,
		filterable: false,
		sortable: false,
		headerClassName:
			'react-table-header-class fs-12 medium-text justify-content-start',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Order ID',
		accessor: 'order_no',
		className: 'fs-12 regular-text',
		minWidth: 128,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Service Type',
		accessor: 'orderServiceType',
		className: 'fs-12 regular-text',
		minWidth: 136,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Pick Up Code',
		accessor: 'pickupCode',
		className: 'fs-12 regular-text',
		minWidth: 142,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Table No.',
		accessor: 'tableCode',
		className: 'fs-12 regular-text',
		minWidth: 108,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Order Status',
		accessor: 'order_status',
		className: 'fs-12 regular-text',
		minWidth: 184,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Payment Status',
		accessor: 'payment_status',
		className: 'fs-12 regular-text',
		minWidth: 164,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Taxes',
		accessor: 'taxes',
		className: 'fs-12 regular-text',
		minWidth: 98,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	// {
	// 	Header: 'Taxes (%)',
	// 	accessor: 'taxes_percentage',
	// 	className: 'fs-12 regular-text',
	// 	filterable: false,
	// 	sortable: false,
	// 	headerClassName: 'react-table-header-class fs-12 medium-text'
	// },
	{
		Header: 'Promo Code',
		accessor: 'promo_code',
		className: 'fs-12 regular-text',
		minWidth: 134,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Promo Code Amount',
		accessor: 'promo_code_amount',
		className: 'fs-12 regular-text',
		minWidth: 200,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Promo Code (%)',
		accessor: 'promo_code_number',
		className: 'fs-12 regular-text',
		minWidth: 158,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	// {
	// 	Header: 'Promo Code Type',
	// 	accessor: 'promo_code_type',
	// 	className: 'fs-12 regular-text',
	// 	minWidth: 150,
	// 	filterable: false,
	// 	sortable: false,
	// 	headerClassName: 'react-table-header-class fs-12 medium-text'
	// },
	{
		Header: 'Total Paid By Customer',
		accessor: 'total_amount',
		className: 'fs-12 regular-text',
		minWidth: 224,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'MyTab Customer Fee',
		accessor: 'mytab_fee',
		className: 'fs-12 regular-text',
		minWidth: 216,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Stripe Transaction Fee',
		accessor: 'stripe_transaction_fee',
		className: 'fs-12 regular-text',
		minWidth: 216,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'POS Integration Fee (%)',
		accessor: 'pos_integration_fee',
		className: 'fs-12 regular-text',
		minWidth: 216,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Docket Printing Fee (%)',
		accessor: 'docket_printing_fee',
		className: 'fs-12 regular-text',
		minWidth: 216,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Refund',
		accessor: 'refund',
		className: 'fs-12 regular-text',
		minWidth: 164,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Refunded Amount',
		accessor: 'refund_amount',
		className: 'fs-12 regular-text',
		minWidth: 184,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Net Revenue (incl GST)',
		accessor: 'net_revenue',
		className: 'fs-12 regular-text',
		minWidth: 220,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-12 medium-text',
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	},
	{
		Header: 'Manage',
		accessor: 'manage',
		className: 'fs-12 regular-text text-center',
		headerClassName: 'react-table-header-class fs-12 medium-text',
		sortable: false,
		filterable: false,
		width: 272,
		Cell: () => {
			return (
				<div className="w-100">
					<Skeleton height="100%" width={'100%'} />
				</div>
			);
		}
	}
];

const TableSkeleton = () => {
	return (
		<TableStyle version={6}>
			<TableV6 columns={columns} data={data} key={'master-todo-table'} />
		</TableStyle>
	);
};

export default TableSkeleton;
