import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import PromoCodeModal from './PromoCodeModal';
import { PlusIcon } from '../../Icons';
import PromoCodeTable from './PromoCodeTable';
import Api from '../../../Helper/Api';
import { VenueApiRoutes } from '../../../Utils/routes';
import { formatTableData } from './utils';
import Switch from '../../Common/Switch';
import DeleteConfirmModal from './DeleteConfirmModal';

const PromoCode = () => {
	const authDetails = useSelector((state) => ({ ...state.auth }));
	const [promoCodeModal, setPromoCodeModal] = useState(false);
	const [modalData, setModalData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [tableDataCount, setTableDataCount] = useState(null);
	const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
	const [deletePromocodeId, setDeletePromocodeId] = useState(null);
	const handleDeleteConfirmModal = () => {
		setDeleteConfirmModal((prev) => !prev);
	};
	const handleDeletePromocode = async () => {
		try {
			setLoading(true);
			const res = await Api('DELETE', VenueApiRoutes.deletePromocode, {
				bar_id: authDetails?.selectedVenue?.id?.toString(),
				id: '' + deletePromocodeId
			});
			setLoading(false);
			if (res?.data?.status) {
				setDeleteConfirmModal(false);
				await getTableData();
				toast.success(res?.data?.message);
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	const handleStatusChange = async (data) => {
		try {
			setLoading(true);
			const res = await Api('PUT', VenueApiRoutes.editPromocode, {
				bar_id: authDetails?.selectedVenue?.id?.toString(),
				id: '' + data?.id,
				status: data?.status
			});
			setLoading(false);
			if (res?.data?.status) {
				await getTableData();
				toast.success(res?.data?.message);
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	const columns = [
		{
			Header: <span className="fs-12 medium-text">Name</span>,
			accessor: 'name',
			className: 'justify-content-start fs-12 regular-text',
			filterable: false,
			sortable: false,
			minWidth: 250,
			headerClassName: 'react-table-header-class justify-content-start'
		},
		{
			Header: 'Code',
			accessor: 'code',
			className: 'fs-12 regular-text',
			filterable: false,
			sortable: false,
			minWidth: 100,
			headerClassName: 'react-table-header-class fs-12 medium-text'
		},
		{
			Header: 'Discount Amount (%)',
			accessor: 'discount',
			className: 'fs-12 regular-text',
			filterable: false,
			sortable: false,
			minWidth: 180,
			headerClassName: 'react-table-header-class fs-12 medium-text'
		},
		{
			Header: 'Expiry Date',
			accessor: 'expiry_date',
			className: 'fs-12 regular-text',
			filterable: false,
			sortable: false,
			minWidth: 130,
			headerClassName: 'react-table-header-class fs-12 medium-text'
		},
		{
			Header: 'Status',
			accessor: 'status',
			className: 'text-dark',
			filterable: false,
			sortable: false,
			minWidth: 120,
			headerClassName: 'react-table-header-class fs-12 medium-text',
			Cell: (data) => {
				let status = data?.original?.status;
				let id = data?.original?.id;
				return (
					<Switch
						checked={status === 'Active' ? true : false}
						onChange={() =>
							handleStatusChange({
								id: id,
								status:
									status === 'Active' ? 'Inactive' : 'Active'
							})
						}
					/>
				);
			}
		},
		{
			Header: 'Action',
			accessor: 'action',
			className: 'text-dark',
			filterable: false,
			sortable: false,
			minWidth: 140,
			headerClassName: 'react-table-header-class fs-12 medium-text',
			Cell: (data) => {
				let id = data?.original?.id;
				return (
					<div className="d-flex">
						<div
							className="boxBorderRight pr-8 fs-12 medium-text headingTextColor2 cursor-pointer"
							onClick={() => {
								setModalData({
									type: 'edit',
									data: data?.original
								});
								setPromoCodeModal(true);
							}}
						>
							Edit
						</div>
						<div
							className="pl-8 fs-12 medium-text headingTextColor2 cursor-pointer"
							onClick={() => {
								setDeletePromocodeId(id);
								setDeleteConfirmModal(true);
							}}
						>
							Delete
						</div>
					</div>
				);
			}
		}
	];
	const handlePromoCodeModal = () => {
		setPromoCodeModal((prev) => !prev);
	};
	const getTableData = async (payload) => {
		try {
			setLoading(true);
			const res = await Api('POST', VenueApiRoutes.getPromocodeList, {
				bar_id: authDetails?.selectedVenue?.id?.toString()
			});
			setLoading(false);
			if (res?.data?.status) {
				const tableDetails = formatTableData(res?.data?.data);
				setTableData(tableDetails);
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	useEffect(() => {
		(async () => {
			await getTableData();
		})();
	}, [authDetails?.selectedVenue?.id]);
	return (
		<div className="d-flex flex-column">
			<p className="fs-12 regular-text">
				Add promo codes to provide specials and discounts on your menu
				items to encourage sales and increase customer satisfaction.
				<br />
				<span className="fs-12 medium-text">Examples:</span> WELCOME10,
				HOLIDAY5, FAMILY20, STAFF5 etc.
			</p>
			<div className="ptb-24">
				<Button
					type="button"
					className="themeBorderButton d-flex align-items-center"
					onClick={() => {
						setModalData({
							type: 'add',
							data: null
						});
						setPromoCodeModal(true);
					}}
				>
					<PlusIcon height={18} width={18} className="mr-6" /> Add
					Promo Code
				</Button>
			</div>
			<div>
				<PromoCodeTable
					loading={loading}
					tableColumns={columns}
					tableData={tableData}
				/>
			</div>
			{promoCodeModal && (
				<PromoCodeModal
					modalData={modalData}
					isOpen={promoCodeModal}
					handleModal={handlePromoCodeModal}
					getTableData={getTableData}
				/>
			)}
			<DeleteConfirmModal
				isOpen={deleteConfirmModal}
				handleModal={handleDeleteConfirmModal}
				deleteLoading={loading}
				handleDeletePromocode={handleDeletePromocode}
			/>
		</div>
	);
};

export default PromoCode;
