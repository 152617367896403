import React from 'react';
import { useSelector } from 'react-redux';
import { Popover, PopoverBody } from 'reactstrap';
import PopoverWrapper from './popover.style';

const CustomPopover = ({
	isOpen,
	handleToggle,
	target,
	placement,
	className,
	children,
	style
}) => {
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	return (
		<Popover
			className={`no-border ${className ?? ''}`}
			isOpen={isOpen}
			toggle={handleToggle}
			target={target}
			placement={placement}
			trigger="legacy"
			style={{ ...style }}
		>
			<PopoverBody className="p-0 no-border minWidth-300">
				<PopoverWrapper {...allThemeData}>{children}</PopoverWrapper>
			</PopoverBody>
		</Popover>
	);
};

export default CustomPopover;
