export const initialData = [
	{
		id: 1,
		permissions: 'User Management',
		view_only: false,
		edit: false,
		admin: false,
		edit2: false
	},
	{
		id: 2,
		permissions: 'Venue Management',
		view_only: false,
		edit: false,
		admin: false,
		edit2: false
	},
	{
		id: 3,
		permissions: 'Category Management',
		view_only: false,
		edit: false,
		admin: false,
		edit2: false
	},
	{
		id: 4,
		permissions: 'Promo Code Management',
		view_only: false,
		edit: false,
		admin: false,
		edit2: false
	},
	{
		id: 5,
		permissions: 'Template Management',
		view_only: false,
		edit: false,
		admin: false,
		edit2: false
	},
	{
		id: 6,
		permissions: 'Pages Management',
		view_only: false,
		edit: false,
		admin: false,
		edit2: false
	},
	{
		id: 7,
		permissions: 'Order Management',
		view_only: false,
		edit: false,
		admin: false,
		edit2: false
	},
	{
		id: 8,
		permissions: 'Transaction Management',
		view_only: false,
		edit: false,
		admin: false,
		edit2: false
	}
];
