import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
	Card,
	CardBody,
	CardSubtitle,
	CardTitle,
	Col,
	FormGroup,
	Input,
	Label,
	Row
} from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import * as validation from '../../Helper/YupValidation';
import Api from '../../Helper/Api';
import logo from '../../Assets/images/Logo.svg';
import FormInput from '../Form/FormInput';
import { CommonRoutes, VenueApiRoutes } from '../../Utils/routes';
import VerifyOtpModal from './VerifyOtpModal';
import CustomButton from '../Common/CustomButton';
import { EyeIcon, EyeSlashIcon } from '../Icons';
import FormInputGroup from '../Form/FormInputGroup';
import NewFormInput from '../Form/NewFormInput';
import NewFormPasswordInput from '../Form/NewFormPasswordInput';
import RegisterFormWrapper from './registerForm.style';
import { useDispatch } from 'react-redux';
import authActions from '../../Redux/auth/actions';
import NewFormCheckbox from '../Form/NewFormCheckbox/NewFormCheckbox';
import ConformationModal from './ConformationModal';

const RegisterForm = () => {
	const navigate = useNavigate();
	const [registerRole, setRegisterRole] = useState('venue');
	const [verifyOtpModal, setVerifyOtpModal] = useState(false);
	const [confirmModal, setConfirmModal] = useState(false);
	const [formValues, setFormValues] = useState(null);
	const [modalData, setModalData] = useState({});
	const [loading, setLoading] = useState(false);
	const [passwordValid, setPasswordValid] = useState(null);
	const [disabledButton, setDisabledButton] = useState(false);
	const [passwordView, setPasswordView] = useState(false);
	const [confirmPasswordView, setConfirmPasswordView] = useState(false);
	const { state } = useLocation();
	const handleVerifyOtpModal = () => {
		setVerifyOtpModal((prev) => !prev);
	};
	const validationSchema = yup.object().shape({
		firstName: validation.YUP_VALIDATION.FIRST_NAME,
		lastName: validation.YUP_VALIDATION.LAST_NAME,
		email: validation.YUP_VALIDATION.EMAIL,
		password: validation.YUP_VALIDATION.PASSWORD,
		acceptTerms: validation.YUP_VALIDATION.ACCEPT_TERMS
		// confirmPassword: validation.YUP_VALIDATION.LOGIN_CONFIRM_PASSWORD
	});
	const handleOnsubmit = async (values) => {
		setConfirmModal(true);
		setFormValues(values);
		// await formSubmition();
	};

	const formSubmition = async () => {
		if (state) {
			navigate(`/setup-2fa`, {
				state: state
			});
		} else {
			const payload = {
				first_name: registerFormik?.values?.firstName,
				last_name: registerFormik?.values?.lastName,
				email: registerFormik?.values?.email,
				password: registerFormik?.values?.password
			};
			let formData = new FormData();
			for (let key in payload) {
				formData.append(key, payload[key]);
			}
			try {
				setLoading(true);
				const res = await Api(
					'POST',
					VenueApiRoutes.register,
					formData
				);
				setLoading(false);
				if (res?.data?.status) {
					setConfirmModal(false);
					registerFormik.resetForm();
					toast.success(res?.data?.message);
					navigate(`/setup-2fa`, {
						state: {
							admin_id: res?.data?.data?.id,
							...formValues
						}
					});
					// setVerifyOtpModal(true);
					// setModalData({
					// 	admin_id: res?.data?.data?.id,
					// 	email: res?.data?.data?.email
					// });
				} else {
					setConfirmModal(false);
					toast.error(res?.data?.message);
				}
			} catch (err) {
				setLoading(false);
				setConfirmModal(false);
				if (err?.message) {
					toast.error(err?.message);
				}
			}
		}
	};

	const registerFormik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			password: '',
			confirmPassword: '',
			acceptTerms: true
		},
		validationSchema,
		onSubmit: handleOnsubmit
	});

	function validatePasswordInput(event) {
		const pattern =
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9~!@#$%^&*()_\-+=<>?]).{8,}$/;

		if (pattern.test(event.target.value)) {
			setPasswordValid(true);
		} else {
			setPasswordValid(false);
		}
	}
	useEffect(() => {
		if (state) {
			registerFormik.setValues({
				firstName: state.firstName ?? '',
				lastName: state.lastName ?? '',
				email: state.email ?? '',
				password: state.password ?? '',
				confirmPassword: state.confirmPassword ?? '',
				acceptTerms: state.acceptTerms ?? false
			});
		}
	}, [state]);
	return (
		<RegisterFormWrapper>
			<div className="formCard">
				<h1 className="headingStyle">
					Let’s create your Management Portal
				</h1>
				<p className={`helperLink`}>
					Already have a MyTab Management Portal?{' '}
					<span
						className="helperLinkSpan"
						onClick={() => navigate(CommonRoutes.login)}
					>
						Log In.
					</span>
				</p>
				<form onSubmit={registerFormik.handleSubmit}>
					<div className="d-flex pa-b-18" style={{ gap: '30px' }}>
						<div className="flex-1">
							<NewFormInput
								type="text"
								name="firstName"
								label="First Name"
								placeholder="Enter First Name"
								value={registerFormik?.values?.firstName}
								onChange={registerFormik.handleChange}
								onBlur={registerFormik.handleBlur}
								error={
									registerFormik?.touched?.firstName &&
									!!registerFormik?.errors?.firstName
								}
								errorMsg={registerFormik?.errors?.firstName}
								disabled={state ? true : false}
							/>
						</div>
						<div className="flex-1">
							<NewFormInput
								type="text"
								name="lastName"
								label="Last Name"
								placeholder="Enter Last Name"
								value={registerFormik?.values?.lastName}
								onChange={registerFormik.handleChange}
								onBlur={registerFormik.handleBlur}
								error={
									registerFormik?.touched?.lastName &&
									!!registerFormik?.errors?.lastName
								}
								errorMsg={registerFormik?.errors?.lastName}
								disabled={state ? true : false}
							/>
						</div>
					</div>
					<div className="pa-b-18">
						<NewFormInput
							showRequired
							type="email"
							name="email"
							label="Email"
							tootlTipMessage="This email is for your Management Portal and does not need to match the email chosen for your MyTab Venue account."
							placeholder="Email"
							value={registerFormik?.values?.email}
							onChange={registerFormik.handleChange}
							onBlur={registerFormik.handleBlur}
							error={
								registerFormik?.touched?.email &&
								!!registerFormik?.errors?.email
							}
							errorMsg={registerFormik?.errors?.email}
							disabled={state ? true : false}
						/>
					</div>
					<div className="pa-b-18">
						<NewFormPasswordInput
							showRequired
							tootlTipMessage="This password is for your Management Portal and does not need to match the password chosen for your MyTab Venue account."
							suggestionMatcher
							passwordSuggestionMatched={passwordValid}
							name="password"
							label="Password"
							placeholder="Password"
							value={registerFormik?.values?.password}
							onChange={(e) => {
								validatePasswordInput(e);
								registerFormik.handleChange(e);
							}}
							onBlur={registerFormik.handleBlur}
							error={
								registerFormik?.touched?.password &&
								!!registerFormik?.errors?.password
							}
							errorMsg={registerFormik?.errors?.password}
							disabled={state ? true : false}
						/>
						<Row>
							<Col
								lg={6}
								xl={6}
								xxl={6}
								md={6}
								xs={6}
								sm={6}
								className="password-suggestion"
							>
								<p>-8 characters minimum</p>
								<p>-One upper case character</p>
							</Col>
							<Col
								lg={6}
								xl={6}
								xxl={6}
								md={6}
								xs={6}
								sm={6}
								className="password-suggestion"
							>
								<p>-One lower case character</p>
								<p>-One number and/or special character</p>
							</Col>
						</Row>
					</div>

					<div className="flex gap-3 align-items-center mt-xxl-3">
						{/* <input
							name="acceptTerms"
							type="checkbox"
							onChange={registerFormik?.handleChange}
							checked={registerFormik?.values?.acceptTerms}
							disabled={state ? true : false}
						/> */}
						<NewFormCheckbox
							name="acceptTerms"
							onChange={registerFormik?.handleChange}
							checked={registerFormik?.values?.acceptTerms}
							disabled={state ? true : false}
						/>
						<p className="agreement">
							I agree to MyTab’s{' '}
							<span
								className="agreementSpan"
								onClick={() => {
									window.open(
										'https://mytabinfo.com/policies/terms-of-service',
										'_blank'
									);
								}}
							>
								Terms and Conditions
							</span>{' '}
							and{' '}
							<span
								className="agreementSpan"
								onClick={() => {
									window.open(
										'https://mytabinfo.com/policies/privacy-policy',
										'_blank'
									);
								}}
							>
								Privacy Policy
							</span>
						</p>
					</div>
					{registerFormik?.touched?.acceptTerms &&
						!!registerFormik?.errors?.acceptTerms && (
							<p className="customError pa-t-6">
								{registerFormik?.errors?.acceptTerms}
							</p>
						)}
					<div className="ptb-18 mb-1">
						<CustomButton
							type="submit"
							className="newThemeButtonFullWidth"
							// loading={loading}
						>
							{/* Create Account */}
							Let’s Go!
						</CustomButton>
					</div>
					<ConformationModal
						submitLoading={loading}
						isOpen={confirmModal}
						onConfirm={formSubmition}
						closeHandler={() => setConfirmModal(false)}
						handleModal={() => setConfirmModal(!confirmModal)}
					/>
				</form>
			</div>

			<VerifyOtpModal
				modalFor={'register'}
				handleModal={handleVerifyOtpModal}
				isOpen={verifyOtpModal}
				type={'venue'}
				modalData={modalData}
			/>
		</RegisterFormWrapper>
	);
};

export default RegisterForm;
