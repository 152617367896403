export const MagnifyingGlassIcon = ({ stroke, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 24 24" fill="none">
			<path
				d="M10.875 18.75C15.2242 18.75 18.75 15.2242 18.75 10.875C18.75 6.52576 15.2242 3 10.875 3C6.52576 3 3 6.52576 3 10.875C3 15.2242 6.52576 18.75 10.875 18.75Z"
				stroke={stroke ?? '#242424'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.4438 16.4438L21.0001 21.0001"
				stroke={stroke ?? '#242424'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
