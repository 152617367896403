import styled from 'styled-components';

const ConnectVenueWrraper = styled.div`
	.buttonHeadText {
		user-select: none;
		padding-top: 10px;
		font-size: 24px;
		font-weight: 400;
		color: #000000;
		font-family: 'poppins-regular';
		margin-bottom: 8px;
	}

	.buttonsGroup {
		display: flex;
		flex-direction: column;
		gap: 30px;
	}

	.headingCustomPadding {
		padding-bottom: 4%;
	}

	.hrLine {
		width: 100%;
		border: 1px solid #000000;
	}

	.optionalText {
		font-size: 18px;
		font-family: 'poppins-regular';
		color: #313132c4;
	}

	@media (max-width: 600px) {
		.buttonHeadText {
			font-size: 11.5px;
		}
		.buttonsGroup {
			gap: 17px;
		}
		.headingCustomPadding {
			padding-bottom: 1.3%;
		}
	}

	@media only screen and (min-width: 600px) and (max-width: 960px) {
		.buttonHeadText {
			font-size: 18px;
		}
		.buttonsGroup {
			gap: 27.7px;
		}
		.headingCustomPadding {
			padding-bottom: 1.3%;
		}
	}

	@media only screen and (min-width: 961px) and (max-width: 1824px) {
		.buttonHeadText {
			font-size: 13.5px;
		}
		.buttonsGroup {
			gap: 20.8px;
		}
		.headingCustomPadding {
			padding-bottom: 4%;
		}
		.hrLine {
			border: 0.5px solid #000000;
		}

		.optionalText {
			font-size: 10.1px;
		}
	}
`;

export default ConnectVenueWrraper;
