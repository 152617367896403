export const AppleIcon = ({ fill, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 19 22" fill="none">
			<path
				d="M15.4062 11.7793C15.4062 10.0449 16.2031 8.7793 17.75 7.79492C16.8594 6.5293 15.5469 5.87305 13.8125 5.73242C12.125 5.5918 10.2969 6.66992 9.64062 6.66992C8.9375 6.66992 7.34375 5.7793 6.07812 5.7793C3.45312 5.82617 0.6875 7.8418 0.6875 12.0137C0.6875 13.2324 0.875 14.498 1.34375 15.8105C1.95312 17.5449 4.10938 21.7637 6.35938 21.6699C7.53125 21.6699 8.375 20.8262 9.92188 20.8262C11.4219 20.8262 12.1719 21.6699 13.4844 21.6699C15.7812 21.6699 17.75 17.8262 18.3125 16.0918C15.2656 14.6387 15.4062 11.873 15.4062 11.7793ZM12.7812 4.0918C14.0469 2.5918 13.9062 1.18555 13.9062 0.669922C12.7812 0.763672 11.4688 1.4668 10.7188 2.31055C9.875 3.24805 9.40625 4.41992 9.5 5.68555C10.7188 5.7793 11.8438 5.16992 12.7812 4.0918Z"
				fill={fill ?? '#FF5F5F'}
			/>
		</svg>
	);
};
