import React from 'react';
import { Spinner } from 'reactstrap';

const Loader = ({ size = '48px', color = '#FF5F5F' }) => {
	return (
		<Spinner
			style={{
				width: size,
				height: size,
				color: color
			}}
		/>
	);
};

export default Loader;
