import { FormGroup, Input, Label } from 'reactstrap';

const NewFormCheckbox = ({
	id,
	label,
	error,
	errorMsg,
	className,
	labelClassName,
	checkboxPosition = 'start',
	...rest
}) => {
	return (
		<div>
			<div className="d-flex align-items-center" style={{ gap: '6px' }}>
				{checkboxPosition === 'start' && (
					<Input
						{...rest}
						id={id}
						type="checkbox"
						className={`${
							error ? 'is-invalid' : ''
						} newThemeCheckbox border-radius-0 fs-15 ma-t-0 medium-text ${
							className ? className : ''
						}`}
						style={{ marginBottom: '0px' }}
					/>
				)}
				{label && (
					<Label
						for={id}
						className={`${labelClassName} fs-12 regular-text themeText label-color mb-0 user-select-none`}
					>
						{label}
					</Label>
				)}
				{checkboxPosition === 'end' && (
					<Input
						{...rest}
						id={id}
						type="checkbox"
						className={`${
							error ? 'is-invalid' : ''
						} newThemeCheckbox border-radius-0 fs-15 ma-t-0 medium-text ${
							className ? className : ''
						}`}
						style={{ marginBottom: '0px' }}
					/>
				)}
			</div>
			{error && (
				<p className="fs-10 semi-bold-text headingTextColor pa-t-6">
					{errorMsg}
				</p>
			)}
		</div>
	);
};

export default NewFormCheckbox;
