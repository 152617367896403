import React from 'react';
import NewCheckBoxStyle from './index.style';
import NewFormCheckbox from '../NewFormCheckbox/NewFormCheckbox';

const NewCheckBox = ({ label, checkBoxProps }) => {
	return (
		<NewCheckBoxStyle>
			<NewFormCheckbox {...checkBoxProps} />
			<label>{label}</label>
		</NewCheckBoxStyle>
	);
};

export default NewCheckBox;
