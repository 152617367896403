import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Breadcrumb, BreadcrumbItem, Button } from 'reactstrap';
import FormInputGroup from '../Form/FormInputGroup';
import { MagnifyingGlassIcon } from '../Icons';

const PageTitle = ({
	title,
	sidebarTheme,
	className,
	breadCrumb,
	buttonIcon,
	buttonName,
	handleButtonClick,
	buttonClassName
}) => {
	return (
		<Fragment>
			<div
				className={classNames(
					'Page-title d-flex flex-column pb-0',
					className
				)}
			>
				<div className="title d-flex justify-content-between align-items-center w-100">
					<p className="themeText fs-15 medium-text d-flex align-items-start w-100 flex-1">
						{title}
					</p>
					{buttonName && (
						<Button
							className={`no-border pageButton d-flex justify-content-between align-items-center fs-14 medium-text ${buttonClassName}`}
							onClick={handleButtonClick}
						>
							{buttonIcon} {buttonName}
						</Button>
					)}
				</div>
			</div>
			{breadCrumb && (
				<div>
					<Breadcrumb className="custom-breadcumb">
						{breadCrumb?.length > 0 &&
							breadCrumb?.map((e, i) => {
								if (i === breadCrumb.length - 1) {
									return (
										<BreadcrumbItem key={i} active>
											{e.name}
										</BreadcrumbItem>
									);
								} else {
									return (
										<BreadcrumbItem
											className="breadcumb-color"
											key={i}
										>
											{e.name}
										</BreadcrumbItem>
									);
								}
							})}
					</Breadcrumb>
				</div>
			)}
		</Fragment>
	);
};

PageTitle.propTypes = {
	title: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
	return {
		...state.themeChanger
	};
};

export default connect(mapStateToProps, null)(PageTitle);
