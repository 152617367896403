import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { toast } from 'react-toastify';

import VerifyOtpModal from './VerifyOtpModal';
import FormMobileNoInput from '../../Form/FormMobileNoInput';
import * as validation from '../../../Helper/YupValidation';
import { supportVenueEmail } from '../../../Helper/constant';
import { auth } from '../../../firebase.config';
import { handleFirebaseError } from './utils';
import rightImg from '../../../Assets/images/right.png';
import Right from '../../Icons/Right';

const VenueChangeMobile = () => {
	const authData = useSelector((state) => ({ ...state.auth }));
	const [showTwoField, setShowTwoField] = useState(false);
	const [captchLoading, setCaptchLoading] = useState(false);
	const [verifyOtpModal, setVerifyOtpModal] = useState(false);
	const [confirmationResult, setConfirmationResult] = useState(null);
	const [currentMobileNoVerified, setCurrentMobileNoVerified] =
		useState(false);
	const handleVerifyOtpModal = () => {
		setVerifyOtpModal((prev) => !prev);
	};
	const validationSchema = showTwoField
		? yup.object().shape({
				currentMobileNumber:
					validation.YUP_VALIDATION.CURRENT_MOBILE_NUMBER,
				newMobileNumber: validation.YUP_VALIDATION.NEW_MOBILE_NUMBER
		  })
		: yup.object().shape({
				currentMobileNumber:
					validation.YUP_VALIDATION.CURRENT_MOBILE_NUMBER
		  });
	const handleCaptchVerify = async (formatedMobileNo) => {
		if (showTwoField) {
			document.getElementById('recaptcha-container-2').style.display =
				'block';
		} else {
			document.getElementById('recaptcha-container-1').style.display =
				'block';
		}
		setCaptchLoading(true);
		const recaptchaVerifier = new RecaptchaVerifier(
			auth,
			showTwoField ? 'recaptcha-container-2' : 'recaptcha-container-1',
			{
				size: 'normal',
				callback: (res) => {
					if (res) {
						signInWithPhoneNumber(
							auth,
							formatedMobileNo,
							recaptchaVerifier
						)
							.then((confirmationRes) => {
								setConfirmationResult(confirmationRes);
								setCaptchLoading(false);
								setVerifyOtpModal(true);
								toast.success('OTP sent successfully.');
								if (showTwoField) {
									document.getElementById(
										'recaptcha-container-2'
									).style.display = 'none';
								} else {
									document.getElementById(
										'recaptcha-container-1'
									).style.display = 'none';
								}
							})
							.catch((error) => {
								setCaptchLoading(false);
								handleFirebaseError(error);
								if (showTwoField) {
									document.getElementById(
										'recaptcha-container-2'
									).style.display = 'none';
								} else {
									document.getElementById(
										'recaptcha-container-1'
									).style.display = 'none';
								}
							});
					}
				},
				'expired-callback': () => {}
			}
		);
		recaptchaVerifier.render();
	};
	const submitFormHandler = async () => {
		if (showTwoField) {
			try {
				let formatedMobileNo =
					'+' + values?.newCountryCode + values?.newMobileNumber;
				await handleCaptchVerify(formatedMobileNo);
			} catch (error) {
				toast.error(error);
			}
		} else {
			try {
				let formatedMobileNo =
					'+' +
					values?.currentCountryCode +
					values?.currentMobileNumber;
				await handleCaptchVerify(formatedMobileNo);
			} catch (error) {
				toast.error(error);
			}
		}
	};
	const {
		errors,
		values,
		handleSubmit,
		handleChange,
		handleBlur,
		setFieldValue,
		touched
	} = useFormik({
		initialValues: {
			currentCountryCode: '',
			currentMobileNumber: '',
			newCountryCode: '',
			newMobileNumber: ''
		},
		validationSchema,
		onSubmit: submitFormHandler
	});
	useEffect(() => {
		setFieldValue(
			'currentCountryCode',
			authData?.selectedVenue?.countryCode
		);
		setFieldValue('currentMobileNumber', authData?.selectedVenue?.mobile);
	}, [
		authData?.selectedVenue?.countryCode,
		authData?.selectedVenue?.mobile,
		authData?.selectedVenue?.id
	]);
	useEffect(() => {
		document.getElementById('recaptcha-container-2').style.display = 'none';
		document.getElementById('recaptcha-container-1').style.display = 'none';
	}, []);
	return (
		<>
			{showTwoField && (
				<p className="fs-12 regular-text themeText pa-b-24">
					To update the mobile number linked to your MyTab Venue
					account, please complete the below fields. You will be
					required to verify this update via a 6 digit code sent to
					your exisiting mobile. If you have any issues, please feel
					free to email{' '}
					<a
						href={`mailto: ${supportVenueEmail}`}
						className="activeLink fs-12 medium-text"
					>
						{supportVenueEmail}
					</a>{' '}
					and one of our friendly venue representatives will be happy
					to assist.
				</p>
			)}
			<form onSubmit={handleSubmit}>
				<Row>
					<Col lg={6}>
						<div className="d-flex align-items-center gap-2">
							<div className="flex-1">
								<FormMobileNoInput
									name="currentMobileNumber"
									label="Current Mobile Number"
									placeholder="Enter Current Mobile No"
									mobileNoValue={values?.currentMobileNumber}
									onMobileNoChange={handleChange}
									onMobileNoBlur={handleBlur}
									countryCodeValue={
										values?.currentCountryCode
									}
									onSelectCountry={(_, countryDetails) => {
										setFieldValue(
											'currentCountryCode',
											countryDetails.dialCode
										);
									}}
									error={
										touched?.currentMobileNumber &&
										!!errors?.currentMobileNumber
									}
									errorMsg={errors?.currentMobileNumber}
									disabledCountry={true}
									disabled
								/>
							</div>
							{currentMobileNoVerified && (
								<div className="ma-t-26">
									<span className="tick">
										<Right
											width={'18px'}
											height={'18px'}
											fill={'#fff'}
										/>
									</span>
								</div>
							)}
						</div>
						{showTwoField && (
							<div className="pa-t-20 d-flex align-items-center gap-2">
								<div className="flex-1">
									<FormMobileNoInput
										name="newMobileNumber"
										label="New Mobile Number"
										placeholder="Enter New Mobile No"
										mobileNoValue={values?.newMobileNumber}
										onMobileNoChange={handleChange}
										onMobileNoBlur={handleBlur}
										countryCodeValue={
											values?.newCountryCode
										}
										onSelectCountry={(
											_,
											countryDetails
										) => {
											setFieldValue(
												'newCountryCode',
												countryDetails.dialCode
											);
										}}
										error={
											touched?.newMobileNumber &&
											!!errors?.newMobileNumber
										}
										errorMsg={errors?.newMobileNumber}
									/>
								</div>
								<div className="ma-t-26 invisible">
									<span className="tick">
										<Right
											width={'18px'}
											height={'18px'}
											fill={'#fff'}
										/>
									</span>
								</div>
							</div>
						)}
						<div
							id="recaptcha-container-1"
							className="ma-t-24"
						></div>
						<div
							id="recaptcha-container-2"
							className="ma-t-24"
						></div>
						<div className="pa-t-24">
							{showTwoField ? (
								<Button
									type="submit"
									className="themeButton"
									disabled={captchLoading}
								>
									Verify New Mobile Number
								</Button>
							) : (
								<Button
									type="submit"
									className="themeButton"
									disabled={captchLoading}
								>
									Update Mobile Number
								</Button>
							)}
						</div>
					</Col>
				</Row>
			</form>
			{verifyOtpModal && (
				<VerifyOtpModal
					handleModal={handleVerifyOtpModal}
					isOpen={verifyOtpModal}
					showTwoField={showTwoField}
					setShowTwoField={setShowTwoField}
					confirmationResult={confirmationResult}
					formValues={values}
					setCurrentMobileNoVerified={setCurrentMobileNoVerified}
				/>
			)}
		</>
	);
};

export default VenueChangeMobile;
