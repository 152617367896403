import React, { useState } from 'react';
import { Button, FormGroup, Label } from 'reactstrap';
import OtpInput from 'react-otp-input';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import * as yup from 'yup';
import * as validation from '../../../../Helper/YupValidation';
import CustomModal from '../../../Common/Modal/CustomModal';
import CustomButton from '../../../Common/CustomButton';
import ModalWrapper from './index.style';
import { VenueApiRoutes } from '../../../../Utils/routes';
import Api from '../../../../Helper/Api';
import authActions from '../../../../Redux/auth/actions';

const PasscodeModal = ({ isOpen, handleModal, passcodeStatus }) => {
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	const authData = useSelector((state) => ({ ...state.auth }));
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [passcodeLength, setPasscodeLength] = useState(4);
	const [modalWidth, setModalWidth] = useState();
	const validationSchema = passcodeStatus
		? yup.object().shape({
				existingPasscode:
					Number(authData?.selectedVenue?.passcodeLength) === 6
						? validation.YUP_VALIDATION.EXISTING_PASSCODE_6
						: validation.YUP_VALIDATION.EXISTING_PASSCODE_4,
				newPasscode:
					passcodeLength === 6
						? validation.YUP_VALIDATION.NEW_PASSCODE_6
						: validation.YUP_VALIDATION.NEW_PASSCODE_4,
				confirmPasscode: validation.YUP_VALIDATION.CONFIRM_PASSCODE
		  })
		: yup.object().shape({
				newPasscode:
					passcodeLength === 6
						? validation.YUP_VALIDATION.NEW_PASSCODE_6
						: validation.YUP_VALIDATION.NEW_PASSCODE_4,
				confirmPasscode: validation.YUP_VALIDATION.CONFIRM_PASSCODE
		  });
	const submitFormHandler = async () => {
		let payload = passcodeStatus
			? {
					bar_id: authData?.selectedVenue?.id,
					old_passcode: values?.existingPasscode,
					passcode: values?.newPasscode
			  }
			: {
					bar_id: authData?.selectedVenue?.id,
					passcode: values?.newPasscode
			  };
		let api = passcodeStatus
			? VenueApiRoutes.changePassCode
			: VenueApiRoutes.setPassCode;
		setLoading(true);
		try {
			const res = await Api('POST', api, payload);
			if (res?.data?.status) {
				resetForm();
				handleModal();
				toast.success(res?.data?.message);
				dispatch(
					authActions?.set_passcode_length({
						barId: authData?.selectedVenue?.id,
						passcodeLength: payload?.passcode?.length
					})
				);
			} else {
				toast.error(res?.data?.message);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	const initialValues = passcodeStatus
		? {
				existingPasscode: '',
				newPasscode: '',
				confirmPasscode: ''
		  }
		: {
				newPasscode: '',
				confirmPasscode: ''
		  };
	const {
		handleSubmit,
		values,
		handleChange,
		handleBlur,
		touched,
		errors,
		resetForm,
		setFieldValue
	} = useFormik({
		initialValues: initialValues,
		validationSchema,
		validateOnChange: false,
		validateOnBlur: false,
		onSubmit: submitFormHandler
	});
	useEffect(() => {
		if (authData?.selectedVenue?.passcodeStatus === 'Inactive') {
			if (passcodeLength == 4) {
				setModalWidth('430px');
			} else {
				setModalWidth('468px');
			}
		} else {
			if (
				authData?.selectedVenue?.passcodeLength == 4 &&
				passcodeLength == 4
			) {
				setModalWidth('430px');
			} else if (
				authData?.selectedVenue?.passcodeLength == 4 &&
				passcodeLength == 6
			) {
				setModalWidth('468px');
			} else if (
				authData?.selectedVenue?.passcodeLength == 6 &&
				passcodeLength == 6
			) {
				setModalWidth('468px');
			} else {
				setModalWidth('468px');
			}
		}
	}, [
		passcodeLength,
		authData?.selectedVenue?.passcodeLength,
		authData?.selectedVenue?.passcodeStatus
	]);
	return (
		<CustomModal
			title={passcodeStatus ? 'Change Passcode' : 'Set Passcode'}
			size="md"
			isOpen={isOpen}
			handleModal={handleModal}
			style={{
				maxWidth: modalWidth,
				width: '100%'
			}}
		>
			<ModalWrapper {...allThemeData}>
				<form className="otpForm text-center" onSubmit={handleSubmit}>
					{passcodeStatus && (
						<FormGroup className="mb-18">
							<Label className="fs-12 medium-text themeText label-color ma-b-6">
								Enter your existing passcode
							</Label>
							<OtpInput
								value={values?.existingPasscode}
								onChange={(otp) => {
									setFieldValue('existingPasscode', otp);
								}}
								numInputs={
									Number(
										authData?.selectedVenue?.passcodeLength
									) === 0
										? 6
										: Number(
												authData?.selectedVenue
													?.passcodeLength
										  )
								}
								containerStyle={{
									justifyContent: 'center'
								}}
								shouldAutoFocus={true}
							/>
							{errors?.existingPasscode && (
								<p className="fs-10 semi-bold-text headingTextColor pa-t-6">
									{errors?.existingPasscode}
								</p>
							)}
						</FormGroup>
					)}
					<FormGroup className="mb-18">
						<Label className="fs-12 medium-text themeText label-color ma-b-6">
							Enter new passcode
						</Label>
						<OtpInput
							value={values?.newPasscode}
							onChange={(otp) => {
								setFieldValue('newPasscode', otp);
							}}
							numInputs={passcodeLength}
							containerStyle={{
								justifyContent: 'center'
							}}
						/>
						{errors?.newPasscode && (
							<p className="fs-10 semi-bold-text headingTextColor pa-t-6">
								{errors?.newPasscode}
							</p>
						)}
					</FormGroup>
					<FormGroup className="mb-0">
						<Label className="fs-12 medium-text themeText label-color ma-b-6">
							Confirm new passcode
						</Label>
						<OtpInput
							value={values?.confirmPasscode}
							onChange={(otp) => {
								setFieldValue('confirmPasscode', otp);
							}}
							numInputs={passcodeLength}
							containerStyle={{
								justifyContent: 'center'
							}}
						/>
						{errors?.confirmPasscode && (
							<p className="fs-10 semi-bold-text headingTextColor pa-t-6">
								{errors?.confirmPasscode}
							</p>
						)}
					</FormGroup>
					<div
						className="pa-t-24 d-flex justify-content-center"
						style={{ gap: '18px' }}
					>
						<div>
							<Button
								type="button"
								className="themeBorderButton"
								onClick={handleModal}
							>
								Cancel
							</Button>
						</div>
						<div>
							<CustomButton
								type="submit"
								className="themeButton"
								loading={loading}
							>
								Continue
							</CustomButton>
						</div>
					</div>
					{passcodeLength == 4 && (
						<p
							className="pa-t-24 fs-12 semi-bold-text headingTextColor cursor-pointer"
							onClick={() => setPasscodeLength(6)}
						>
							Set 6-Digit Numeric Code
						</p>
					)}
					{passcodeLength == 6 && (
						<p
							className="pa-t-24 fs-12 semi-bold-text headingTextColor cursor-pointer"
							onClick={() => setPasscodeLength(4)}
						>
							Set 4-Digit Numeric Code
						</p>
					)}
				</form>
			</ModalWrapper>
		</CustomModal>
	);
};

export default PasscodeModal;
