import styled from 'styled-components';

const PageWrapper = styled.div`
	.qrCodeParent {
		background: #fff;
		width: 80px;
	}
	.twoFactorBox {
		label {
			margin-bottom: 0px;
		}
	}
	.horizontalRule {
		border-top: 1px solid #eaeaea !important;
	}
	.tagThisDevice {
		padding: 6px 12px;
		background-color: #fff0f2;
		border-radius: 32px;
	}
	.boxCard {
		border: 1px solid #eaeaea;
		border-radius: 6px;
	}
`;
export default PageWrapper;
