import React, { useEffect, useRef, useState } from 'react';
import ImageUploadWrapper from './imageUpload.style';

const ImageUpload = ({
	name,
	className,
	value,
	error,
	errorMsg,
	setFieldValue,
	icon,
	defaultImage,
	disabled,
	standardVersion,
	...props
}) => {
	const fileInput = useRef();
	const [file, setFile] = useState();
	const handleChange = (e) => {
		setFieldValue(name, e.target.files[0]);
	};
	const handleUpload = () => {
		fileInput.current.click();
	};
	useEffect(() => {
		if (value) {
			if (value instanceof File) {
				setFile(URL.createObjectURL(value));
			} else {
				setFile(value);
			}
		}
	}, [value]);
	return (
		<ImageUploadWrapper
			className={`form-group mb-0 ${className ? className : ''}`}
			disabled={disabled}
		>
			<input
				type="file"
				name={name}
				ref={fileInput}
				onChange={handleChange}
				accept="image/jpg,image/png,image/jpeg"
				disabled={disabled}
				hidden
			/>
			<div className="imageContainer">
				<img src={file ?? defaultImage} className="image" />
				<span onClick={handleUpload} className="icon">
					{icon}
				</span>
			</div>
			{error && (
				<p className="fs-10 semi-bold-text headingTextColor pa-t-6">
					{errorMsg}
				</p>
			)}
		</ImageUploadWrapper>
	);
};

export default ImageUpload;
