export const HomeIcon = ({ fill, width, height, ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 769.17 645.9"
			width={width}
			height={height}
		>
			<g id="Layer_2" data-name="Layer 2">
				<g id="Layer_1-2" data-name="Layer 1">
					<path
						className="cls-1"
						d="M656.65,474.51q0,76,0,152.07c0,7.95-2.71,14.08-10,17.72a15.08,15.08,0,0,1-6.84,1.6q-89.89,0-179.79,0a16.67,16.67,0,0,1-16.69-15,52.5,52.5,0,0,1-.21-5.61q0-74.91.06-149.83c0-3.07-.45-4.17-4-4.15q-64.05.21-128.1,0c-3.08,0-3.89.73-3.88,3.86q.15,76,.07,152.08c0,9.48-4.65,16-12.93,18.21a18.84,18.84,0,0,1-4.81.46q-89,0-177.91,0c-10.08,0-17.21-6.78-17.76-16.84,0-1,0-2,0-3q0-152.08,0-304.16c0-2.63,0-5.25,1-7.73a12.89,12.89,0,0,1,14-7.64c5.09.81,9.53,5.62,10.13,11.11a43.93,43.93,0,0,1,.12,4.87q0,146.83-.09,293.66c0,3.59.79,4.48,4.43,4.47q77.34-.21,154.69,0c3.39,0,4.06-.91,4.05-4.14-.11-50.56.11-101.13-.22-151.7-.07-11.28,8.68-18.95,19-18.91,49.44.23,98.88.11,148.33.11,12.11,0,19.06,6.85,19.07,19q0,75.66-.06,151.33c0,3.32.69,4.34,4.22,4.33q77.34-.2,154.69,0c3.36,0,4.3-.76,4.29-4.24q-.15-147.57-.12-295.16c0-4.88.9-9.14,5-12.24a12.43,12.43,0,0,1,19.93,8.24,40.12,40.12,0,0,1,.3,5.59Q656.66,398.66,656.65,474.51Z"
						fill={fill ?? '#242424'}
					/>
					<path
						className="cls-1"
						d="M384.08,0c4.75-.32,8.44,1.86,11.87,4.73L447.08,47.5q94.38,78.82,188.76,157.65,64.21,53.62,128.44,107.24c5.71,4.77,6.5,12.63,1.94,18-4.69,5.53-12.28,6.15-18,1.36q-78.59-65.61-157.15-131.25L415.79,54.12c-9.58-8-19.25-15.89-28.67-24.07-2.4-2.07-3.7-2-6.06,0q-71.15,59.63-142.43,119.1-108.45,90.6-216.9,181.19c-4.63,3.87-9.59,5.34-15.15,2.35C1.85,330.13-.23,325.9,0,320.59c.21-4.49,3-7.48,6.24-10.19Q32.14,288.84,58,267.23L233.24,120.85Q302.33,63.15,371.42,5.42C375.14,2.31,379-.35,384.08,0Z"
						fill={fill ?? '#242424'}
					/>
					<path
						className="cls-1"
						d="M625.8,85.11c0,17,.08,34,0,50.93-.06,9-6.16,14.43-14.65,13.29a12,12,0,0,1-10.33-10.72c-.16-1.61-.13-3.24-.13-4.86,0-29.33-.07-58.67.08-88,0-3.47-.6-4.57-4.37-4.55-30,.18-59.91.11-89.87.09-6.69,0-11.41-2.93-13.42-8.2A12.65,12.65,0,0,1,503,16.17a30.26,30.26,0,0,1,4.86-.21q49.62,0,99.23,0c11.59,0,18.66,6.93,18.72,18.59C625.86,51.4,625.8,68.26,625.8,85.11Z"
						fill={fill ?? '#242424'}
					/>
				</g>
			</g>
		</svg>
	);
};
