import React, { useState } from 'react';
import { ThreeDotIcon } from '../../Icons';
import PickupLocationPopOver from './PickupLocationPopOver';

const PickupLocationCard = ({
	cardData,
	setOpenModal,
	setModalData,
	deletePickupLocation,
	setDeleteConfirmModal,
	setDeletePickuplocationId,
	standardVersion
}) => {
	const [pickupLocationPopover, setPickupLocationPopover] = useState(false);
	const pickupLocationPopoverToggle = () => {
		setPickupLocationPopover((prev) => !prev);
	};
	return (
		<div style={{border:standardVersion && "1px solid #31313259"}}>
			<div className="borderBox pa-18 w-100 h-100 d-flex align-items-center justify-content-between gap-2">
				<p className="flex-1 fs-14 medium-text">{cardData?.address}</p>
				<ThreeDotIcon
					height={18}
					width={18}
					id={`pickupLocationPopover_${cardData?.id}`}
					className="cursor-pointer"
				/>
			</div>
			<PickupLocationPopOver
				popover={pickupLocationPopover}
				popoverToggle={pickupLocationPopoverToggle}
				popoverId={`pickupLocationPopover_${cardData?.id}`}
				setOpenModal={setOpenModal}
				setModalData={setModalData}
				cardData={cardData}
				deletePickupLocation={deletePickupLocation}
				setDeleteConfirmModal={setDeleteConfirmModal}
				setDeletePickuplocationId={setDeletePickuplocationId}
			/>
		</div>
	);
};

export default PickupLocationCard;
