import styled from 'styled-components';

const PageWrapper = styled.div`
	.hintColor {
		color: #4f4f4f !important;
	}
	.horizontalLine {
		border-top: 1px solid #dddddd;
	}
	.bottomButtonWrapper {
		display: flex !important;
		flex-direction: row;
		@media only screen and (max-width: 425px) {
			flex-direction: column;
		}
	}
	/* .css-2zjf3w-menu {
		background-color: lightblue !important;
		border: 1px solid red !important;
		right: 1px !important;
		left: 1px !important;
		width: 100% !important;
	} */
	.saveCancleBtn {
		@media only screen and (max-width: 1600px) {
			padding-inline: 65px !important;
		}
		@media only screen and (max-width: 425px) {
			width: 100%;
		}
	}
	.additionalItemsWrapper {
		display: flex;
		gap: 80px;
		@media only screen and (max-width: 1240px) {
			gap: 40px;
		}
		@media only screen and (max-width: 1130px) {
			flex-direction: column;
		}
	}
	.pleaseSelectCard {
		width: 100%;
		background: #fc6461;
		border-radius: 8px;
		height: 39px !important;
	}
	.addAdditionalCard {
		width: 100%;
		background: white !important;
		border-radius: 8px;
		border: 1px solid #fc6461;
		color: #fc6461;
		height: 39px !important;
	}
	.addOptionsButton {
		background: #fd7371 !important;
		border: 0px;
		width: 120px !important;
		height: 28px !important;
		border-radius: 6px !important;
		margin-top: 0 !important;
		padding-inline: 8px !important;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.serviceTypeButton {
		background: #fd7371 !important;
		border: 0px;
		width: 120px !important;
		height: 28px !important;
		border-radius: 6px !important;
		margin-top: 0 !important;
		padding-inline: 8px !important;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.dietaryRequirementsContainer {
		width: 621px;
		display: grid;
		grid-template-columns: auto auto auto;
		gap: 18px;
		@media only screen and (max-width: 660px) {
			width: 100%;
			grid-template-columns: auto auto;
		}
		@media only screen and (max-width: 460px) {
			width: 100%;
			grid-template-columns: auto;
		}
	}
	.serviceTypeContainer {
		display: flex;
		gap: 18px;
	}
`;

export default PageWrapper;
