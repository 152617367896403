import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ClockIcon } from '../../Icons';
import WaitTimeModal from './WaitTimeModal';
import { PageWrapper } from './index.style';
import Switch from '../../Common/Switch';
import Api from '../../../Helper/Api';
import { VenueApiRoutes } from '../../../Utils/routes';
import CategoryItemSkeleton from './CategoryItemSkeleton';
import EmptyData from '../../Common/EmptyData';

const WaitTime = () => {
	const state = useSelector((state) => ({ ...state }));
	const authData = state?.auth;
	const [waitTimeModal, setWaitTimeModal] = useState(false);
	const [waitTimeModalData, setWaitTimeModalData] = useState(null);
	const [subCategoryData, setSubCategoryData] = useState([]);
	const [subCategoryOptionsLoading, setSubCategoryOptionsLoading] =
		useState(false);
	const [pickup, setPickup] = useState(false);
	const [table, setTable] = useState(false);
	const [switchLoading, setSwitchLoading] = useState(false);
	const [waitTimeLoading, setWaitTimeLoading] = useState(-1);
	const [venueServiceType, setVenueServiceType] = useState('');

	const handleWaitTimeModal = () => {
		setWaitTimeModal(!waitTimeModal);
	};
	const handlePickupChange = async () => {
		let currentPickupValue = pickup;
		let updatedPickupValue = !pickup;
		let service_type = '';
		if (updatedPickupValue === true && table === true) {
			service_type = 'BOTH';
		} else if (updatedPickupValue === false && table === true) {
			service_type = 'TABLE';
		} else if (updatedPickupValue === true && table === false) {
			service_type = 'PICKUP';
		} else {
			service_type = '';
		}
		setSwitchLoading(true);
		try {
			const res = await Api(
				'POST',
				VenueApiRoutes.updateWaitTimeServiceType,
				{
					bar_id: authData?.selectedVenue?.id,
					waitTimeServiceType: service_type
				}
			);
			if (res?.data?.status) {
				setPickup(updatedPickupValue);
				toast.success(res?.data?.message);
			} else {
				setPickup(currentPickupValue);
				toast.error(res?.data?.message);
			}
			setSwitchLoading(false);
		} catch (err) {
			setPickup(currentPickupValue);
			setSwitchLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	const handleTableChange = async () => {
		let currentTableValue = table;
		let updatedTableValue = !table;
		let service_type = '';
		if (updatedTableValue === true && pickup === true) {
			service_type = 'BOTH';
		} else if (updatedTableValue === false && pickup === true) {
			service_type = 'PICKUP';
		} else if (updatedTableValue === true && pickup === false) {
			service_type = 'TABLE';
		} else {
			service_type = '';
		}
		setSwitchLoading(true);
		try {
			const res = await Api(
				'POST',
				VenueApiRoutes.updateWaitTimeServiceType,
				{
					bar_id: authData?.selectedVenue?.id,
					waitTimeServiceType: service_type
				}
			);
			if (res?.data?.status) {
				setTable(updatedTableValue);
				toast.success(res?.data?.message);
			} else {
				setTable(currentTableValue);
				toast.error(res?.data?.message);
			}
			setSwitchLoading(false);
		} catch (err) {
			setTable(currentTableValue);
			setSwitchLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	const getServiceType = async () => {
		setSwitchLoading(true);
		try {
			const res = await Api(
				'POST',
				VenueApiRoutes.getWaitTimeServiceType,
				{
					bar_id: authData?.selectedVenue?.id
				}
			);
			if (res?.data?.status) {
				setVenueServiceType(res?.data?.data?.serviceType);
				if (res?.data?.data?.waitTimeServiceType === 'BOTH') {
					setPickup(true);
					setTable(true);
				} else if (res?.data?.data?.waitTimeServiceType === 'TABLE') {
					setTable(true);
				} else if (res?.data?.data?.waitTimeServiceType === 'PICKUP') {
					setPickup(true);
				} else {
					setPickup(false);
					setTable(false);
				}
			} else {
				toast.error(res?.data?.message);
			}
			setSwitchLoading(false);
		} catch (err) {
			setSwitchLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	const getSubCategoryOptions = async () => {
		try {
			setSubCategoryOptionsLoading(true);
			const res = await Api('POST', VenueApiRoutes.getSubCategory, {
				bar_id: authData?.selectedVenue?.id
			});
			if (res?.data?.status) {
				setSubCategoryData(res?.data?.data);
			} else {
				toast.error(res?.data?.message);
			}
			setSubCategoryOptionsLoading(false);
		} catch (err) {
			setSubCategoryOptionsLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	const handleSetWaitTime = async (item) => {
		try {
			setWaitTimeLoading(item?.subCategoryID);
			const res = await Api('POST', VenueApiRoutes.getWaitTime, {
				bar_id: '' + authData?.selectedVenue?.id,
				sub_category_id: '' + item?.subCategoryID
			});
			if (res?.data?.status) {
				setWaitTimeModalData({
					subCategoryData: item,
					waitTimeData: res?.data?.data
				});
				setWaitTimeModal(true);
			} else {
				toast.error(res?.data?.message);
			}
			setWaitTimeLoading(false);
		} catch (err) {
			setWaitTimeLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	useEffect(() => {
		(async () => {
			await getServiceType();
			await getSubCategoryOptions();
		})();
	}, [authData?.selectedVenue?.id]);
	return (
		<PageWrapper>
			<p className={`fs-15 medium-text`}>Wait Time Automation</p>
			<p className={`fs-12 regular-text pa-t-24`}>
				You are required to enter an accurate wait time on each of your
				menu subheadings. These estimated wait times will be reflected
				on the customer app and will provide customers with a reasonable
				expectation of when their Takeaway/Table Service order will be
				ready. When creating your venue account, an automatic 10 minute
				wait time is set per subheading. You can update your subheading
				wait times at any time.
			</p>
			<p className={`fs-12 regular-text pa-t-24`}>
				Wait times can be scheduled for either "All Day" or "Per Hour"
				during your venue's opening hours as we understand that your
				venue's business will fluctuate.
			</p>
			<p className={`fs-12 regular-text pa-t-24`}>
				Additionally, you have the ability to enable the wait time
				automation feature where customers will automatically be
				notified via their app that their order (either in part or in
				full) is ready to be collected/served when the wait time is
				completed. For example, if wait time automation is selected for
				Takeaway orders and a customer places a takeaway coffee order at
				12:00pm with a wait time of 10 minutes, the customer will
				automatically be notified that their coffee is ready for
				collection at 12:10pm. You can enable the wait time automation
				feature specifically for each service type/s that your venue
				offers.
			</p>
			<p className={`fs-12 regular-text pa-t-24`}>
				If the wait time automation feature is not enabled, customers
				will still see the estimated time of when their order will be
				ready based on the allocated wait time, however, you will have
				to manually mark the order as ready for pick up/ready to be
				served via your iPad dashboard to notify the customer.
			</p>
			<div className={`horizontalLine mtb-24`} />
			<p className={'fs-15 medium-text pa-b-24'}>
				Enable Wait Time Automation:
			</p>
			<div className="borderBox serviceTypeBox">
				<p className={`fs-12 semi-bold-text`}>
					Select your service type
				</p>
				<div className="switchContainer">
					{(venueServiceType === 'BOTH' ||
						venueServiceType === 'PICKUP') && (
						<div className="d-flex align-items-center">
							<span className="fs-12 regular-text pr-18">
								Takeaway
							</span>
							<Switch
								name="pickup"
								checked={pickup}
								onChange={handlePickupChange}
								disabled={switchLoading}
							/>
						</div>
					)}
					{(venueServiceType === 'BOTH' ||
						venueServiceType === 'TABLE') && (
						<div className="d-flex align-items-center">
							<span className="fs-12 regular-text pr-18">
								Table Service
							</span>
							<Switch
								name="table"
								checked={table}
								onChange={handleTableChange}
								disabled={switchLoading}
							/>
						</div>
					)}
				</div>
			</div>
			<div className={`horizontalLine mtb-24`} />
			{subCategoryOptionsLoading ? (
				<CategoryItemSkeleton />
			) : (
				<>
					{subCategoryData?.length > 0 ? (
						<div className="gridContainer">
							{subCategoryData?.map((item, index) => {
								return (
									<div className="gridItem" key={index}>
										<div className="borderBox itemBox">
											<div className="pr-16">
												<p
													className={`fs-14 medium-text productItemName`}
												>
													{item?.name ?? ''}
												</p>
											</div>
											<Button
												className="themeButton setWaitTimeButton"
												onClick={() => {
													handleSetWaitTime(item);
												}}
												disabled={
													waitTimeLoading ===
													item?.subCategoryID
												}
											>
												<ClockIcon
													width={`18px`}
													height={`18px`}
												/>
												Set Wait Times
											</Button>
										</div>
									</div>
								);
							})}
						</div>
					) : (
						<EmptyData content="No category data found" />
					)}
				</>
			)}
			{waitTimeModal && waitTimeModalData && (
				<WaitTimeModal
					isOpen={waitTimeModal}
					handleModal={handleWaitTimeModal}
					modalData={waitTimeModalData}
				/>
			)}
		</PageWrapper>
	);
};

export default WaitTime;
