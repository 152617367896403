import { useState, useEffect } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import PageWrapper from './index.style';
import { PieChart } from '../../Components/Common/Charts/PieChart';
import {
	formatCustomerCountByTimeData,
	formatGrossSalesPerHourData,
	formatMostOrderedMenuItemsData,
	formatOrderBreakdownData,
	formatServiceTypeRevenueData
} from './utils';
import Api from '../../Helper/Api';
import { VenueApiRoutes } from '../../Utils/routes';
import StatisticsCardSkeleton from '../../Components/Home/StatisticsCardSkeleton';
import StatisticsCard from '../../Components/Home/StatisticsCard';
import convertNumToKM from '../../Helper/convertNumToKM';
import PopularTimesChart from '../../Components/Home/PopularTimesChart';
import GrossSalesPerHourChart from '../../Components/Home/GrossSalesPerHourChart';
import MostOrderedMenuItemsChart from '../../Components/Home/MostOrderedMenuItemsChart';
import defaultVenueCardImg from '../../Assets/images/default-venue-card-img.png';

export const Home = () => {
	const state = useSelector((state) => ({ ...state }));
	const allThemeData = state.themeChanger;
	const authData = state.auth;
	const selectedVenue = state.auth.selectedVenue;
	const [statisticsLoading, setStatisticsLoading] = useState(true);
	const [totalRevenueLoading, setTotalRevenueLoading] = useState(true);
	const [serviceTypePercentageLoading, setServiceTypePercentageLoading] =
		useState(true);
	const [ordersPerHoursLoading, setOrdersPerHoursLoading] = useState(true);
	const [serviceTypeRevenueLoading, setServiceTypeRevenueLoading] =
		useState(true);
	const [customerCountByTimeLoading, setCustomerCountByTimeLoading] =
		useState(true);
	const [mostOrderdItemsLoading, setMostOrderdItemsLoading] = useState(true);
	const [statisticsData, setStatisticsData] = useState(null);
	const [totalRevenueData, setTotalRevenueData] = useState(null);
	const [serviceTypePercentageData, setServiceTypePercentageData] =
		useState(null);
	const [ordersPerHoursData, setOrdersPerHoursData] = useState(null);
	const [serviceTypeRevenueData, setServiceTypeRevenueData] = useState(null);
	const [customerCountByTimeData, setCustomerCountByTimeData] =
		useState(null);
	const [mostOrderdItemsData, setMostOrderdItemsData] = useState(null);

	const getStatistics = async () => {
		try {
			setStatisticsLoading(true);
			const res = await Api('POST', VenueApiRoutes.getVenueStatistics, {
				bar_id: selectedVenue?.id
			});
			if (res?.data?.status) {
				setStatisticsData(res?.data?.data?.list[0]);
			} else {
				toast.error(res?.data?.message);
			}
			setStatisticsLoading(false);
		} catch (err) {
			setStatisticsLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	const getTotalRevenue = async () => {
		try {
			setTotalRevenueLoading(true);
			const res = await Api('POST', VenueApiRoutes.getTotalRevenue, {
				bar_id: selectedVenue?.id
			});
			if (res?.data?.status) {
				setTotalRevenueData(res?.data?.data[0]?.amount);
			} else {
				toast.error(res?.data?.message);
			}
			setTotalRevenueLoading(false);
		} catch (err) {
			setTotalRevenueLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	const getServiceTypePercentage = async () => {
		try {
			setServiceTypePercentageLoading(true);
			const res = await Api(
				'POST',
				VenueApiRoutes.serviceTypePercentage,
				{
					bar_id: selectedVenue?.id
				}
			);
			if (res?.data?.status) {
				const formatedData = formatOrderBreakdownData(
					res?.data?.data?.list
				);
				setServiceTypePercentageData(formatedData);
			} else {
				toast.error(res?.data?.message);
			}
			setServiceTypePercentageLoading(false);
		} catch (err) {
			setServiceTypePercentageLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	const getOrdersPerHours = async () => {
		try {
			setOrdersPerHoursLoading(true);
			const res = await Api('POST', VenueApiRoutes.ordersPerHours, {
				bar_id: selectedVenue?.id
			});
			if (res?.data?.status) {
				let formatedData = [];
				if (res?.data?.data?.list?.find((item) => item?.count !== 0)) {
					formatedData = formatGrossSalesPerHourData(
						res?.data?.data?.list
					);
				}
				setOrdersPerHoursData(formatedData);
			} else {
				toast.error(res?.data?.message);
			}
			setOrdersPerHoursLoading(false);
		} catch (err) {
			setOrdersPerHoursLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	const getServiceTypeRevenue = async () => {
		try {
			setServiceTypeRevenueLoading(true);
			const res = await Api('POST', VenueApiRoutes.serviceTypeRevenue, {
				bar_id: selectedVenue?.id
			});
			if (res?.data?.status) {
				const formatedData = formatServiceTypeRevenueData(
					res?.data?.data?.list
				);
				setServiceTypeRevenueData(formatedData);
			} else {
				toast.error(res?.data?.message);
			}
			setServiceTypeRevenueLoading(false);
		} catch (err) {
			setServiceTypeRevenueLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	const getCustomerCountByTime = async () => {
		try {
			setCustomerCountByTimeLoading(true);
			const res = await Api('POST', VenueApiRoutes.customerCountByTime, {
				bar_id: selectedVenue?.id
			});
			if (res?.data?.status) {
				let formatedData = [];
				if (res?.data?.data?.list?.find((item) => item?.count !== 0)) {
					formatedData = formatCustomerCountByTimeData(
						res?.data?.data?.list
					);
				}
				setCustomerCountByTimeData(formatedData);
			} else {
				toast.error(res?.data?.message);
			}
			setCustomerCountByTimeLoading(false);
		} catch (err) {
			setCustomerCountByTimeLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	const getMostOrderdItems = async () => {
		try {
			setMostOrderdItemsLoading(true);
			const res = await Api('POST', VenueApiRoutes.mostOrderdItems, {
				bar_id: selectedVenue?.id
			});
			if (res?.data?.status) {
				const formatedData = formatMostOrderedMenuItemsData(
					res?.data?.data?.list
				);
				setMostOrderdItemsData(formatedData);
			} else {
				toast.error(res?.data?.message);
			}
			setMostOrderdItemsLoading(false);
		} catch (err) {
			setMostOrderdItemsLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	useEffect(() => {
		if (selectedVenue?.id) {
			(async () => {
				await getStatistics();
				await getTotalRevenue();
				await getServiceTypePercentage();
				await getOrdersPerHours();
				await getServiceTypeRevenue();
				await getCustomerCountByTime();
				await getMostOrderdItems();
			})();
		}
	}, [selectedVenue?.id]);
	return (
		<Scrollbars
			renderTrackVertical={({ style, ...props }) => (
				<div
					{...props}
					style={{
						...style,
						zIndex: 5,
						position: 'absolute',
						width: '4px',
						right: '2px',
						bottom: '2px',
						top: '2px',
						borderRadius: '3px'
					}}
				/>
			)}
			autoHide
		>
			<PageWrapper {...allThemeData}>
				<div className="fs-24 medium-text pa-b-18">
					{statisticsLoading ? (
						<Skeleton height={'24px'} width={'300px'} />
					) : (
						`Welcome ${
							(authData?.first_name ? authData?.first_name : '') +
							' ' +
							(authData?.last_name ? authData?.last_name : '')
						},`
					)}
				</div>
				<div className="pa-b-24">
					{statisticsLoading ? (
						<StatisticsCardSkeleton />
					) : (
						<StatisticsCard
							venueImage={
								selectedVenue?.avatar
									? selectedVenue?.avatar
									: defaultVenueCardImg
							}
							venueName={
								selectedVenue?.restaurantName
									? selectedVenue?.restaurantName
									: ''
							}
							totalGrossSales={
								statisticsData?.amount
									? Number(
											Number(
												statisticsData?.amount
											)?.toFixed(2)
									  )?.toLocaleString()
									: 0
							}
							totalOrders={
								statisticsData?.total_order
									? statisticsData?.total_order
									: 0
							}
							totalCustomers={
								statisticsData?.total_user
									? statisticsData?.total_user
									: 0
							}
						/>
					)}
				</div>
				<p className="pa-b-8 fs-18 medium-text">
					{totalRevenueLoading ? (
						<Skeleton height={18} width={'296px'} />
					) : (
						`Today at ${
							selectedVenue?.restaurantName
								? selectedVenue?.restaurantName
								: ''
						}`
					)}
				</p>
				<p className="pa-b-18 fs-15 semi-bold-text headingTextColor">
					{totalRevenueLoading ? (
						<Skeleton height={15} width={'197px'} />
					) : (
						<>
							Revenue:{' '}
							<span className="fs-15 medium-text">
								$
								{totalRevenueData
									? Number(totalRevenueData).toFixed(2)
									: '0.00'}
							</span>
						</>
					)}
				</p>
				<div className="chartContainer">
					<Row>
						{/* Service Type Order Breakdown */}
						<Col xl={4}>
							{serviceTypePercentageLoading ? (
								<Skeleton
									height={328}
									width={'100%'}
									className="border-radius-12"
								/>
							) : (
								<PieChart
									heading={'Service Type Order Breakdown'}
									chartData={serviceTypePercentageData}
									labelFormatter={(value) => {
										return value + '%';
									}}
									tooltipValueFormatter={(value) => {
										return value + '%';
									}}
								/>
							)}
						</Col>
						{/* Gross Sales Per Hour */}
						<Col xl={8}>
							{ordersPerHoursLoading ? (
								<Skeleton
									height={328}
									width={'100%'}
									className="border-radius-12"
								/>
							) : (
								<GrossSalesPerHourChart
									data={ordersPerHoursData}
								/>
							)}
						</Col>
						{/* Service Type Revenue */}
						<Col xxl={4} xl={4}>
							{serviceTypeRevenueLoading ? (
								<Skeleton
									height={328}
									width={'100%'}
									className="border-radius-12"
								/>
							) : (
								<PieChart
									heading={'Service Type Revenue'}
									chartData={serviceTypeRevenueData}
									labelFormatter={(value) => {
										return '$' + convertNumToKM(value);
									}}
									tooltipValueFormatter={(value) => {
										return '$' + value;
									}}
								/>
							)}
						</Col>
						{/* Popular Times */}
						<Col xxl={4} xl={8}>
							{customerCountByTimeLoading ? (
								<Skeleton
									height={328}
									width={'100%'}
									className="border-radius-12"
								/>
							) : (
								<PopularTimesChart
									data={customerCountByTimeData}
								/>
							)}
						</Col>
						{/* Most Ordered Menu Items */}
						<Col xxl={4}>
							{mostOrderdItemsLoading ? (
								<Skeleton
									height={328}
									width={'100%'}
									className="border-radius-12"
								/>
							) : (
								<MostOrderedMenuItemsChart
									data={mostOrderdItemsData}
								/>
							)}
						</Col>
					</Row>
				</div>
			</PageWrapper>
		</Scrollbars>
	);
};
