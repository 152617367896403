import PageStructure from '../../../Components/Common/PageStructure';
import ManageAccountForm from '../../../Components/UserProfile/ManageAccountForm';

export const ManageAccount = () => {
	return (
		<PageStructure title="Manage Account">
			<ManageAccountForm />
		</PageStructure>
	);
};
