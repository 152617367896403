import styled from 'styled-components';

const NewVenuePortalStyle = styled.div`
	.processText {
		font-size: 20px;
		font-weight: 700;
		color: #ffffff;
	}

	.parentWidth {
		width: 94% !important;
	}

	.positionOfProcess {
		top: 16px;
		left: 23px;
	}

	.processIconWrraper {
		width: 39px;
		height: 37px;
	}

	.processHr {
		width: 100%;
		color: #ffffff;
		margin-right: 4px;
		border: 2px solid rgba(255, 255, 255, 1) !important;
		opacity: 1 !important;
	}

	.currentProcessHr {
		width: 50px;
	}

	@media (max-width: 600px) {
	}

	@media only screen and (min-width: 600px) and (max-width: 960px) {
	}

	@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
		.positionOfProcess {
			top: 9px;
			left: 18px;
		}
		.processText {
			// font-size: 11.25px;
			font-size: 13.25px;
		}
		.processIconWrraper {
			width: 21.9px;
			height: 20.8px;
			// width: 25.9px;
			// height: 25.9px;
		}

		.processHr {
			width: 100%;
			border: 1px solid rgba(255, 255, 255, 1) !important;
		}
		.currentProcessHr {
			width: 30px;
		}
	}
`;

export default NewVenuePortalStyle;
