const theme = {};

theme.palette = {
	primary: [
		'#6200ea', // default Active Blue
		'#241D3B'
	],
	secondary: [],
	color: [
		'#e91e63', // red color,
		'#8bc34a', // Green color
		'#03a9f4', // blue color
		'#00bcd4', // light blue
		'#f44336' // orange color
	],
	warning: [
		'#ffbf00' // 0: Warning
	],
	success: [
		'#00b16a' // 0: Success
	],
	error: [
		'#f64744', // 0: Error
		'#EC3D3A', // 1: Darken 4%
		'#FF5B58' // 2: Lighten 8%
	],
	grayscale: ['#9e9e9e'],
	text: [],
	border: [],

	calendar: []
};

export default theme;
