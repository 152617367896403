import { useState } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import * as validation from '../../Helper/YupValidation';
import Api from '../../Helper/Api';
import { VenueApiRoutes } from '../../Utils/routes';
import { useDispatch } from 'react-redux';
import NewCustomModal from '../../Components/Common/Modal/NewCustomModal';
import NewVerifyModalWrapper from '../../Components/Authentication/newVerifyOtp.style';
import NewFormInput from '../../Components/Form/NewFormInput';
import CustomButton from '../../Components/Common/CustomButton';

const StripeVerifyOtpModal = ({
	isOpen,
	handleModal,
	modalData,
	refetch,
	connectingToBank
}) => {
	const [loading, setLoading] = useState(false);

	const validationSchema = yup.object().shape({
		otp: validation.YUP_VALIDATION.OTP
	});

	const handelOnsubmit = async () => {
		const payload = {
			bar_id: modalData?.bar_id,
			otp: verifyOtpFormik?.values?.otp
		};

		try {
			setLoading(true);
			const res = await Api(
				'POST',
				'/venue/bar/venue-otp-verify-only',
				payload
			);
			if (res?.data?.status) {
				toast.success(res?.data?.message);
				await connectingToBank();
				handleModal();
				setLoading(false);
			} else {
				setLoading(false);
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};

	const verifyOtpFormik = useFormik({
		initialValues: {
			otp: ''
		},
		validationSchema,
		validateOnChange: false,
		validateOnBlur: false,
		onSubmit: handelOnsubmit
	});

	return (
		<NewCustomModal
			isOpen={isOpen}
			size="md"
			handleModal={handleModal}
			modalClassName="verifyOtp"
		>
			<NewVerifyModalWrapper>
				<p className="verifyOtpHeading">
					Your security is our priority
				</p>
				<p className="verifyOtpSubText">
					Please enter the 6 digit verification code on your{' '}
					<span className="verifyOtpSubText-auth">venue’s email</span>
				</p>
				<form onSubmit={verifyOtpFormik.handleSubmit}>
					<NewFormInput
						name="otp"
						placeholder="Verification code"
						type="text"
						maxlength="6"
						onBlur={verifyOtpFormik.handleBlur}
						value={verifyOtpFormik?.values?.otp}
						onChange={verifyOtpFormik?.handleChange}
						error={verifyOtpFormik?.errors?.otp}
						errorMsg={
							verifyOtpFormik?.touched?.otp &&
							verifyOtpFormik?.errors?.otp
						}
					/>

					<div className="pa-t-20">
						<CustomButton
							type="submit"
							className="newThemeButtonFullWidth"
							loading={loading}
						>
							Verify
						</CustomButton>
					</div>
					<p className="helperText mt-2 mb-4">
						Didn’t receive an email? Check your junk/spam or click{' '}
						<span className="spanLink" onClick={refetch}>
							here
						</span>{' '}
						to re-send.
					</p>
				</form>
			</NewVerifyModalWrapper>
		</NewCustomModal>
	);
};

export default StripeVerifyOtpModal;
