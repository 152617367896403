import { format } from 'd3-format';
import convertNumToKM from '../../Helper/convertNumToKM';
import convertTimeToAmPm from '../../Helper/convertTimeToAmPm';

export const formatOrderBreakdownData = (data) => {
	return data?.map((item, index) => {
		return {
			name: item?.name,
			value: Number(item?.value),
			fill: index === 0 ? '#F94D73' : '#FF9568'
		};
	});
};

export const formatServiceTypeRevenueData = (data) => {
	return data?.map((item, index) => {
		return {
			name: item?.name,
			value: Number(item?.value),
			fill: index === 0 ? '#F94D73' : '#FF9568'
		};
	});
};

export const formatGrossSalesPerHourData = (data) => {
	const chartConfig = [
		{
			name: 'Gross Sales',
			dataKey: 'grossSales',
			fill: '#FF5F5F'
		}
	];
	const chartData = data?.map((item) => {
		return {
			name: '' + format('02')(Number(item?.time)),
			grossSales: convertNumToKM(Number(item?.count))
		};
	});
	return {
		chartConfig: chartConfig,
		chartData: chartData
	};
};

export const formatCustomerCountByTimeData = (data) => {
	const chartConfig = [
		{
			name: 'Orders',
			dataKey: 'popularTimes',
			fill: '#FF5F5F'
		}
	];
	const chartData = data?.map((item) => {
		return {
			name: '' + format('02')(Number(item?.time)),
			popularTimes: Number(item?.count)
		};
	});
	return {
		chartConfig: chartConfig,
		chartData: chartData
	};
};

export const formatMostOrderedMenuItemsData = (data) => {
	const chartConfig = [
		{
			name: 'Ordered',
			dataKey: 'orderedMenu',
			fill: '#FF5F5F'
		}
	];
	const chartData = data?.map((item) => {
		return {
			name: item?.productName,
			orderedMenu: Number(item?.soldQunitity)
		};
	});
	return {
		chartConfig: chartConfig,
		chartData: chartData
	};
};
