import React from 'react';

const EmptyData = ({ content = 'No data found', className }) => {
	return (
		<p
			className={`fs-12 regular-text text-center opacity-50 ${
				className ? className : ''
			}`}
		>
			{content}
		</p>
	);
};

export default EmptyData;
