import React from 'react';
import NewCustomModal from '../Common/Modal/NewCustomModal';
import NewVerifyModalWrapper from './newVerifyOtp.style';
import CustomButton from '../Common/CustomButton';
import OutlinedButton from '../Common/CustomButton/OutlinedButton';

const ConformationModal = ({
	isOpen,
	handleModal,
	onConfirm,
	type,
	submitLoading,
	modalData,
	modalFor,
	closeHandler
}) => {
	return (
		<NewCustomModal
			onClosed={closeHandler}
			isOpen={isOpen}
			size="sm"
			modalBodyClassName={'confirmModalPLR'}
			handleModal={handleModal}
			modalClassName="confirmModal"
		>
			<NewVerifyModalWrapper>
				<p className="verifyOtpHeading">
					Confirm your Management Portal details
				</p>
				<p className="confirmOtpSubText">
					Please confirm all of your details are entered correctly as
					they will be used to verify your account.
				</p>
				<div className="d-flex mt-3">
					<CustomButton
						className={'cancelButtonFullWidth mr-5'}
						onClick={closeHandler}
					>
						Cancel
					</CustomButton>

					<CustomButton
						loading={submitLoading}
						className={'ml-5 newThemeButtonFullWidth'}
						onClick={onConfirm}
					>
						Confirm
					</CustomButton>
				</div>
			</NewVerifyModalWrapper>
		</NewCustomModal>
	);
};

export default ConformationModal;
