import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import CustomModal from '../../../Common/Modal/CustomModal';
import CustomButton from '../../../Common/CustomButton';

const ActivateConfirmModal = ({
	handleModal,
	isOpen,
	activateLoading,
	handleDocketPrintingFeatStatus
}) => {
	const state = useSelector((state) => ({ ...state }));
	const authDetails = state.auth;
	return (
		<CustomModal
			isOpen={isOpen}
			handleModal={handleModal}
			title="Authorisation"
			size="md"
			autoHeightMin={100}
		>
			<div className="w-100">
				<p className="fs-12 regular-text text-left pa-b-18">
					By activating the MyTab Premium+ Docket Printing feature,
					you agree to an additional{' '}
					{(authDetails?.selectedVenue?.docketCommission
						? authDetails?.selectedVenue?.docketCommission
						: '1') + '%'}{' '}
					commission fee per transaction, which will be automatically
					deducted through Stripe. You certify that you are either an
					account holder or an authorised signatory on the account
					listed.
				</p>
				<div className="d-flex" style={{ gap: '12px' }}>
					<div className="flex-1">
						<Button
							className="borderButtonFullWidth"
							onClick={handleModal}
						>
							No
						</Button>
					</div>
					<div className="flex-1">
						<CustomButton
							type="button"
							className="themeButtonFullWidth"
							loading={activateLoading}
							onClick={() => {
								handleDocketPrintingFeatStatus();
							}}
						>
							Yes
						</CustomButton>
					</div>
				</div>
			</div>
		</CustomModal>
	);
};

export default ActivateConfirmModal;
