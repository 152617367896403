export const ClockIcon = ({ width, height, fill, ...props }) => {
	return (
		<svg
			{...props}
			width={width ?? '24px'}
			height={height ?? '24px'}
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M12.0062 0C5.25632 0.0112479 0.141608 5.46456 0.00546908 11.668C-0.144633 18.5017 5.30596 24.0101 11.9997 24C18.6328 23.9903 24.0059 18.632 24 12.0489C23.9938 5.2109 18.5184 0.00620576 12.0062 0ZM11.995 21.5984C6.80815 21.6112 2.28841 17.4033 2.40438 11.725C2.50833 6.62193 6.76393 2.30815 12.1901 2.40085C17.2978 2.48773 21.6159 6.63124 21.6038 12.0908C21.5918 17.328 17.2443 21.6182 11.995 21.5984Z"
				fill={fill ?? 'white'}
			/>
			<path
				d="M17.9525 15.596C17.6856 16.0226 17.4231 16.4516 17.1651 16.8837C17.1151 16.9679 17.0802 16.9717 16.9991 16.9225C14.9691 15.6988 12.9379 14.4774 10.9047 13.2584C10.8197 13.2072 10.8027 13.1471 10.8031 13.0575C10.8054 11.9055 10.8046 10.7532 10.8046 9.60085C10.8046 8.44852 10.8066 7.31558 10.8015 6.17294C10.8011 6.03797 10.8302 5.98948 10.9749 5.99181C11.464 6.00112 11.9531 6.00112 12.4418 5.99181C12.5814 5.9891 12.6074 6.03564 12.607 6.16363C12.6035 8.15374 12.6058 10.1435 12.602 12.1336C12.6016 12.2561 12.6407 12.3201 12.7466 12.3826C14.4711 13.4003 16.1924 14.4239 17.916 15.4432C17.9948 15.4897 17.9998 15.52 17.9525 15.596Z"
				fill={fill ?? 'white'}
			/>
		</svg>
	);
};
