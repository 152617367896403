import { Button } from 'reactstrap';
import CustomModal from '../../../Common/Modal/CustomModal';
import CustomButton from '../../../Common/CustomButton';

const UnactivateConfirmModal = ({
	handleModal,
	isOpen,
	disablePosLoading,
	handleDisablePOS
}) => {
	return (
		<CustomModal
			isOpen={isOpen}
			handleModal={handleModal}
			title="Deactivate POS Integration"
			size="md"
		>
			<div className="w-100">
				<p className="fs-12 regular-text text-center pa-b-18">
					Are you sure you want to deactivate POS integration?
				</p>
				<div className="d-flex" style={{ gap: '12px' }}>
					<div className="flex-1">
						<Button
							className="borderButtonFullWidth"
							onClick={handleModal}
						>
							No
						</Button>
					</div>
					<div className="flex-1">
						<CustomButton
							type="button"
							className="themeButtonFullWidth"
							loading={disablePosLoading}
							onClick={() => {
								handleDisablePOS();
							}}
						>
							Yes
						</CustomButton>
					</div>
				</div>
			</div>
		</CustomModal>
	);
};

export default UnactivateConfirmModal;
