import styled from 'styled-components';

const NewVerifyModalWrapper = styled.div`
	.verifyOtpHeading {
		font-size: 23.625px;
		font-weight: 700;
		text-align: center;
		color: #313132eb;
		font-family: 'montserrat-bold';
	}

	.verifyOtpSubText,
	.confirmOtpSubText {
		font-size: 16px; //55.111111111
		font-weight: 400;
		padding-top: 6%;
		margin-bottom: 0.8%;
		font-family: 'poppins-regular';
		.verifyOtpSubText-auth {
			font-family: 'poppins-bold';
		}
	}

	.listItemWrraper {
		width: 100%;
		border: 1px solid grey;
	}

	.lineBold {
		background: black;
		padding: 0.5px;
		margin-bottom: 2px;
	}

	.locationText {
		cursor: pointer;
		font-size: 14px;
		user-select: none;
	}

	.confirmOtpSubText {
		padding-top: 3%;
	}

	.helperText {
		margin-top: 0.8rem;
		font-size: 15px;
		font-weight: 400;
		text-align: center;
		font-family: 'poppins-regular';
		.spanLink {
			font-weight: 600;
			color: #f95c69;
			cursor: pointer;
		}
	}

	@media (max-width: 600px) {
		.verifyOtpHeading {
			font-size: 18px;
		}

		.verifyOtpSubText {
			font-size: 11px;
		}

		.helperText {
			margin-top: 0.8rem;
			font-size: 11px;
			font-weight: 400;
			text-align: center;
			.spanLink {
				font-weight: 600;
				color: #f95c69;
				cursor: pointer;
			}
		}
	}
`;

export default NewVerifyModalWrapper;
