import React from 'react';
import {
	Card,
	CardBody,
	CardImg,
	CardImgOverlay,
	CardTitle,
	Col,
	Row
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { CircleDollarIcon, Customers, Orders } from '../../Icons';
import PageWrapper from './index.style';

const SubImageCard = ({ heading, count, icon, showDollar = false }) => {
	return (
		<div className="d-flex align-items-center medium-text subImageCard">
			<div className="pr-18">
				<div className="circleIcon">{icon}</div>
			</div>
			<div className="">
				<p className="">
					{showDollar && <span className="fs-14 medium-text">$</span>}
					<span className="fs-22 medium-text">{count}</span>
				</p>
				<p className="fs-11 medium-text headingTextColor pa-t-2">
					{heading}
				</p>
			</div>
		</div>
	);
};

const StatisticsCard = ({
	totalGrossSales,
	totalOrders,
	totalCustomers,
	venueImage,
	venueName
}) => {
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	return (
		<PageWrapper {...allThemeData}>
			<Card className="border-radius-16 border-none defaultBoxShadow bannerCard">
				<CardImg
					alt="Card image cap"
					src={venueImage}
					top
					width="100%"
				/>
				<CardImgOverlay className="bannerCardImgOverlay">
					<CardTitle tag="h5" className="fs-18 medium-text">
						{venueName}
					</CardTitle>
				</CardImgOverlay>
				<CardBody>
					<Row className="g-3">
						<Col sm={12} xl={4}>
							<SubImageCard
								count={totalGrossSales}
								heading={'Total Gross Sales'}
								icon={
									<CircleDollarIcon height={30} width={30} />
								}
								showDollar={true}
							/>
						</Col>
						<Col sm={12} xl={4}>
							<SubImageCard
								count={totalOrders}
								heading={'Total Orders'}
								icon={
									<Orders
										height={30}
										width={30}
										fill={'#FF5F5F'}
									/>
								}
							/>
						</Col>
						<Col sm={12} xl={4}>
							<SubImageCard
								count={totalCustomers}
								heading={'Total Customers'}
								icon={
									<Customers
										height={30}
										width={30}
										fill={'#FF5F5F'}
									/>
								}
							/>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</PageWrapper>
	);
};

export default StatisticsCard;
