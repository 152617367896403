import React from 'react';
import { Button, Spinner } from 'reactstrap';

const CustomButton = ({
	type,
	className,
	loading = false,
	children,
	loaderSize = 'sm',
	...props
}) => {
	return (
		<Button type={type} className={className} disabled={loading} {...props}>
			{children}
			{loading && (
				<Spinner size={loaderSize} className="ml-12">
					Loading...
				</Spinner>
			)}
		</Button>
	);
};

export default CustomButton;
