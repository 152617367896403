export const BellIcon = ({ fill, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 40 40" fill="none">
			<path
				d="M18.3203 7.375C18.3203 6.9375 18.7578 6.5 19.1953 6.5C19.6875 6.5 20.125 6.9375 20.125 7.375V8.30469C24.5 8.74219 28 12.4609 28 17V18.6406C28 20.9922 28.9297 23.2891 30.625 24.9844L30.7891 25.1484C31.2266 25.5859 31.5 26.1875 31.5 26.8438C31.5 28.2109 30.4062 29.25 29.0391 29.25H9.40625C8.03906 29.25 7 28.2109 7 26.8438C7 26.1875 7.21875 25.5859 7.65625 25.1484L7.82031 24.9844C9.51562 23.2891 10.5 20.9922 10.5 18.6406V17C10.5 12.4609 13.9453 8.74219 18.375 8.30469L18.3203 7.375ZM19.1953 10C15.3672 10 12.1953 13.1719 12.1953 17V18.6406C12.1953 21.4844 11.1016 24.2188 9.07812 26.2422L8.91406 26.3516C8.80469 26.5156 8.69531 26.6797 8.69531 26.8438C8.69531 27.2266 9.02344 27.5 9.40625 27.5H29.0391C29.4219 27.5 29.75 27.2266 29.75 26.8438C29.75 26.6797 29.6406 26.5156 29.5312 26.3516L29.3672 26.2422C27.3438 24.2188 26.25 21.4844 26.25 18.6406V17C26.25 13.1719 23.0781 10 19.1953 10ZM19.1953 32.75C19.9609 32.75 20.6172 32.3125 20.8906 31.6016C21.0547 31.1641 21.5469 30.8906 21.9844 31.0547C22.4219 31.2188 22.6953 31.7109 22.5312 32.2031C22.0391 33.5703 20.7266 34.5 19.1953 34.5C17.7188 34.5 16.4062 33.5703 15.9141 32.2031C15.75 31.7109 16.0234 31.2188 16.4609 31.0547C16.8984 30.8906 17.3906 31.1641 17.5547 31.6016C17.8281 32.3125 18.4844 32.75 19.1953 32.75Z"
				fill={fill ?? '#242424'}
			/>
		</svg>
	);
};
