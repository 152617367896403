import { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import CustomPopover2 from '../../Common/Popover/CustomPopover2';
import { ThreeDotIcon } from '../../Icons';

const PreparedCardPopover = ({
	iconColor,
	setRefundModal,
	setIntoxicatedModal,
	setNotPickedUpModal,
	setNotPickedUpModalData,
	setRefundModalData,
	data,
	item
}) => {
	const authData = useSelector((state) => ({ ...state.auth }));
	const [isOpenPopOver, setIsOpenPopOver] = useState(false);
	const togglePopOver = () => {
		setIsOpenPopOver((prev) => !prev);
	};
	const handleRefund = () => {
		togglePopOver();
		if (Number(data?.isPosOrder) === 0) {
			setRefundModalData({
				data: data,
				singleGroupData: item
			});
			setRefundModal(true);
		} else {
			toast.warning(
				'As you have POS integration enabled, all orders must be refunded through your POS system.'
			);
		}
	};
	return (
		<CustomPopover2
			button={
				<ThreeDotIcon
					height={24}
					width={24}
					className="cursor-pointer"
					fill={iconColor}
				/>
			}
			placement="bottom-end"
		>
			<div className="pa-12">
				<div
					className="pa-b-12 themeText fs-12 medium-text cursor-pointer"
					onClick={handleRefund}
				>
					Refund
				</div>
				<div
					className="pa-b-12 themeText fs-12 medium-text cursor-pointer"
					onClick={() => {
						togglePopOver();
						setNotPickedUpModalData(item);
						setNotPickedUpModal(true);
					}}
				>
					Not Picked Up
				</div>
				{Number(data?.isPosOrder) === 0 && (
					<div
						className="themeText fs-12 medium-text cursor-pointer"
						onClick={() => {
							togglePopOver();
							setIntoxicatedModal(true);
						}}
					>
						Too Intoxicated
					</div>
				)}
			</div>
		</CustomPopover2>
	);
};

export default PreparedCardPopover;
