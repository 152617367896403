import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import reducers from '../Redux/reducers';
import { LOGOUT } from '../Helper/AuthConstants';

const persistConfig = {
	key: 'root',
	storage: storage
};

const reducer = combineReducers({
	...reducers
});

const rootReducer = (state, action) => {
	if (action.type === LOGOUT) {
		localStorage.removeItem('persist:root');
		return reducer(undefined, action);
	}
	return reducer(state, action);
};

const initialState = {};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	preloadedState: initialState,
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false
		})
});

const persistor = persistStore(store);

export default store;

export { persistor };
