import React from 'react';
import FilledCheckedIcon from '../../../Assets/images/filledCheckedIcon.png';
import whiteCheckedIcon from '../../../Assets/images/whiteChecked.png';
const NewPortalProcess = ({
	checked,
	processName,
	current,
	hiddenLine = true
}) => {
	return (
		<div
			className={`d-flex align-items-center gap-1 ${
				hiddenLine === true
					? 'justify-content-start'
					: 'justify-content-center w-100'
			}`}
		>
			<div>
				<img
					className="processIconWrraper"
					src={checked ? FilledCheckedIcon : whiteCheckedIcon}
					alt="process-icon"
				/>
			</div>
			<div>
				{current && (
					<p className="text-nowrap processText">{processName}</p>
				)}
			</div>
			{!hiddenLine && (
				<div className="w-100">
					<hr
						className={`processHr ${
							current ? `currentProcessHr` : ``
						}`}
					/>
				</div>
			)}
		</div>
	);
};

export default NewPortalProcess;
