import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const RefundAmountModalSkeleton = () => {
	return (
		<>
			<div className="orderCard d-flex flex-column p-3">
				<div className="orderCardHeader d-flex p-1">
					<div className="d-flex align-items-center gap-3 flex-1">
						<div className="flex-1 d-flex flex-column flex-sm-row justify-content-between align-items-center gap-2">
							<div>
								<p className="fs-16 medium-text themeText">
									<Skeleton
										height="20px"
										width={'108px'}
										borderRadius={12}
									/>
								</p>
								<p className="fs-16 regular-text themeText pt-2">
									<Skeleton
										height="20px"
										width={'108px'}
										borderRadius={12}
									/>
								</p>
							</div>
							<p className="fs-16 medium-text themeText">
								<Skeleton
									height="20px"
									width={'185px'}
									borderRadius={12}
								/>
							</p>
						</div>
					</div>
				</div>
				<hr className="themeHr mt-2 mb-2" />
				<div className="orderCardBody p-1">
					<div className="d-flex flex-column flex-1 gap-1">
						{Array(2)
							.fill('1')
							.map((item, index) => {
								return (
									<div
										key={index}
										className="d-flex justify-content-between gap-1 pt-2"
									>
										<div className="d-flex gap-2">
											<p className="fs-16 medium-text qtyBox d-flex align-items-center justify-content-center">
												<Skeleton
													height="28px"
													width={'28px'}
													borderRadius={6}
												/>
											</p>
											<p className="fs-16 medium-text themeText d-flex align-items-center">
												<Skeleton
													height="20px"
													width={'233px'}
													borderRadius={12}
												/>
											</p>
										</div>
										<p className="fs-16 medium-text themeText">
											<Skeleton
												height="20px"
												width={'56px'}
												borderRadius={12}
											/>
										</p>
									</div>
								);
							})}
					</div>
				</div>
				<hr className="themeHr mt-2 mb-2" />
				<div className="orderCardTotal p-1">
					<div className="d-flex justify-content-between gap-2">
						<div className="d-flex flex-row align-items-center gap-1">
							<p className="fs-16 regular-text themeText">
								<Skeleton
									height="20px"
									width={'151px'}
									borderRadius={12}
								/>
							</p>
						</div>
						<div>
							<p className="fs-20 medium-text themeText">
								<Skeleton
									height="20px"
									width={'75px'}
									borderRadius={12}
								/>
							</p>
						</div>
					</div>
				</div>
				<hr className="themeHr mt-2 mb-2" />
				<div className="orderCardTotal p-1">
					<div className="d-flex justify-content-between gap-2">
						<div className="d-flex flex-row align-items-center gap-1">
							<p className="fs-16 regular-text themeText">
								<Skeleton
									height="20px"
									width={'151px'}
									borderRadius={12}
								/>
							</p>
						</div>
						<div>
							<p className="fs-20 medium-text themeText">
								<Skeleton
									height="20px"
									width={'75px'}
									borderRadius={12}
								/>
							</p>
						</div>
					</div>
				</div>
			</div>
			<Skeleton height="47px" width="100%" borderRadius={12} />
		</>
	);
};

export default RefundAmountModalSkeleton;
