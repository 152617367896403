import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import {
	DndContext,
	closestCenter,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors
} from '@dnd-kit/core';
import {
	arrayMove,
	SortableContext,
	rectSortingStrategy
} from '@dnd-kit/sortable';
import { toast } from 'react-toastify';

import Grid from './Grid';
import PageWrapper from './menuItem.style';
import SortableItem from './SortableItem';
import Api from '../../Helper/Api';
import { VenueApiRoutes } from '../../Utils/routes';
import uiActions from '../../Redux/ui/actions';
import authActions from '../../Redux/auth/actions';
import barActions from '../../Redux/bar/actions';

const Menuitems = ({
	isPopularExist,
	categoryData,
	refetch,
	menuItemId,
	forVenuSetup,
	isEditFromVenueSetup
}) => {
	const state = useSelector((state) => ({ ...state }));
	const allThemeData = state.themeChanger;
	const authData = state.auth;
	const dispatch = useDispatch();
	const isXL = useMediaQuery({ maxWidth: 1300, minWidth: 1200 });
	const isLG = useMediaQuery({ maxWidth: 1199, minWidth: 769 });
	const isMd = useMediaQuery({ maxWidth: 768 });
	const [items, setItems] = useState([]);
	const [productItems, setProductItems] = useState([]);
	const [activeId, setActiveId] = useState(null);
	const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

	const getProductItem = (productItems, id) => {
		if (productItems && productItems?.length !== 0) {
			return productItems?.find((pItem) => pItem?.id === id);
		}
		return null;
	};
	const updateProductSequence = async (data, currentItems) => {
		dispatch(uiActions?.page_loading(true));
		try {
			const res = await Api(
				'POST',
				VenueApiRoutes.updateProductSequence,
				{
					product_ids: data?.productIds,
					category_id: categoryData?.mainCategoryID,
					sub_category_id: categoryData?.categoryID,
					bar_id: authData?.selectedVenue?.id
				}
			);
			if (res?.data?.status) {
				if (refetch) refetch();
			} else {
				setItems(currentItems);
				toast.error(res?.data?.message);
			}
			// dispatch(uiActions?.page_loading(false));
		} catch (err) {
			// dispatch(uiActions?.page_loading(false));
			setItems(currentItems);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	const handleDragStart = useCallback((event) => {
		setActiveId(event?.active?.id);
	}, []);
	const handleDragEnd = useCallback(
		(event) => {
			const { active, over } = event;
			if (active?.id !== over?.id) {
				let currentItems = [...items];
				const oldIndex = items?.indexOf(active?.id);
				const newIndex = items?.indexOf(over?.id);
				let updatedItems = arrayMove(items, oldIndex, newIndex);
				setItems([...updatedItems]);
				updateProductSequence(
					{
						productIds: [...updatedItems]?.reverse(),
						categoryId: 2
					},
					currentItems
				);
			}
			setActiveId(null);
		},
		[items]
	);
	const handleDragCancel = useCallback(() => {
		setActiveId(null);
	}, []);
	useEffect(() => {
		let dndItems = [];
		if (
			categoryData?.categoryProduct &&
			categoryData?.categoryProduct?.length !== 0
		) {
			dndItems = categoryData?.categoryProduct?.map((item) => item?.id);
		}
		setProductItems(categoryData?.categoryProduct);
		setItems(dndItems);
	}, [categoryData]);
	return (
		<PageWrapper {...allThemeData}>
			{items?.length === 0 ? (
				<div className="w-100 h-100 d-flex justify-content-center align-items-center">
					<span className="opacity-50">No Data Found</span>
				</div>
			) : (
				<DndContext
					sensors={sensors}
					collisionDetection={closestCenter}
					onDragStart={handleDragStart}
					onDragEnd={handleDragEnd}
					onDragCancel={handleDragCancel}
				>
					<SortableContext
						items={items}
						strategy={rectSortingStrategy}
						handle
					>
						<Grid
							columns={isMd ? 1 : isXL ? 3 : isLG ? 2 : 3}
							className={`kevan mb-10 ${
								isPopularExist && menuItemId == 0 ? 'pt-0' : ''
							}`}
						>
							{items?.length > 0 &&
								items?.map((item, index) => {
									return (
										<SortableItem
											isEditFromVenueSetup={
												isEditFromVenueSetup
											}
											forVenuSetup={() => {
												dispatch(
													barActions.setCurrentProduct(
														{
															currentProduct: item
														}
													)
												);
											}}
											key={item}
											id={item}
											isPopularExist={isPopularExist}
											menuItemId={menuItemId}
											data={getProductItem(
												productItems,
												item
											)}
											refetch={refetch}
										/>
									);
								})}
						</Grid>
					</SortableContext>
				</DndContext>
			)}
		</PageWrapper>
	);
};

export default Menuitems;
