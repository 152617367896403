import React from 'react';
import CommonAgreementLine from './agreement.style';

const Agreement = () => {
	return (
		<CommonAgreementLine>
			By continuing you agree to our
			<span
				className="policyTextTermsConditions"
				onClick={() => {
					window.open(
						'https://mytabinfo.com/policies/terms-of-service',
						'_blank'
					);
				}}
			>
				{' '}
				Terms & Conditions{' '}
			</span>
			and
			<span
				className="policyTextPrivacy"
				onClick={() => {
					window.open(
						'https://mytabinfo.com/policies/privacy-policy',
						'_blank'
					);
				}}
			>
				{' '}
				Privacy Policy
			</span>
		</CommonAgreementLine>
	);
};

export default Agreement;
