import {
	addDays,
	endOfDay,
	startOfDay,
	startOfMonth,
	endOfMonth,
	startOfWeek,
	startOfQuarter,
	startOfYear,
	endOfWeek,
	isSameDay
} from 'date-fns';

const defineds = {
	startOfWeek: startOfWeek(new Date()),
	endOfWeek: endOfWeek(new Date()),
	startOfToday: startOfDay(new Date()),
	endOfToday: endOfDay(new Date()),
	startOfYesterday: startOfDay(addDays(new Date(), -1)),
	endOfYesterday: endOfDay(addDays(new Date(), -1)),
	startOfMonth: startOfMonth(new Date()),
	endOfMonth: endOfMonth(new Date()),
	startOfQuarter: startOfQuarter(new Date()),
	startOfYear: startOfYear(new Date())
};

function getAmPm(dateTimeString) {
	// Parse the date string using Date object
	const date = new Date(dateTimeString);

	// Get the hour (0-23)
	const hours = date.getHours();

	// Check if it's past noon (hours >= 12)
	return hours >= 12 ? 'PM' : 'AM';
}
export { defineds, isSameDay, getAmPm };
