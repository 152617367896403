import React from 'react';
import { Col, Row } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

const PickupLocationCardSkeleton = () => {
	return (
		<Row
			style={{
				'--bs-gutter-x': '24px',
				'--bs-gutter-y': '24px'
			}}
		>
			{Array(9)
				.fill('1')
				.map((_, index) => {
					return (
						<Col xl={4} lg={6} key={index}>
							<Skeleton
								height="59px"
								width="100%"
								borderRadius={6}
							/>
						</Col>
					);
				})}
		</Row>
	);
};

export default PickupLocationCardSkeleton;
