export const RollsIcon = ({ fill, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 32 32" fill="none">
			<path
				d="M12.75 10.75V7.75C12.75 6.53125 13.7344 5.5 15 5.5H18C19.2188 5.5 20.25 6.53125 20.25 7.75V10.75C20.25 12.0156 19.2188 13 18 13H17.25V15.25H24.75C25.9688 15.25 27 16.2812 27 17.5V19H27.75C28.9688 19 30 20.0312 30 21.25V24.25C30 25.5156 28.9688 26.5 27.75 26.5H24.75C23.4844 26.5 22.5 25.5156 22.5 24.25V21.25C22.5 20.0312 23.4844 19 24.75 19H25.5V17.5C25.5 17.125 25.125 16.75 24.75 16.75H17.25V19H18C19.2188 19 20.25 20.0312 20.25 21.25V24.25C20.25 25.5156 19.2188 26.5 18 26.5H15C13.7344 26.5 12.75 25.5156 12.75 24.25V21.25C12.75 20.0312 13.7344 19 15 19H15.75V16.75H8.25C7.82812 16.75 7.5 17.125 7.5 17.5V19H8.25C9.46875 19 10.5 20.0312 10.5 21.25V24.25C10.5 25.5156 9.46875 26.5 8.25 26.5H5.25C3.98438 26.5 3 25.5156 3 24.25V21.25C3 20.0312 3.98438 19 5.25 19H6V17.5C6 16.2812 6.98438 15.25 8.25 15.25H15.75V13H15C13.7344 13 12.75 12.0156 12.75 10.75ZM18 7H15C14.5781 7 14.25 7.375 14.25 7.75V10.75C14.25 11.1719 14.5781 11.5 15 11.5H18C18.375 11.5 18.75 11.1719 18.75 10.75V7.75C18.75 7.375 18.375 7 18 7ZM4.5 21.25V24.25C4.5 24.6719 4.82812 25 5.25 25H8.25C8.625 25 9 24.6719 9 24.25V21.25C9 20.875 8.625 20.5 8.25 20.5H5.25C4.82812 20.5 4.5 20.875 4.5 21.25ZM14.25 21.25V24.25C14.25 24.6719 14.5781 25 15 25H18C18.375 25 18.75 24.6719 18.75 24.25V21.25C18.75 20.875 18.375 20.5 18 20.5H15C14.5781 20.5 14.25 20.875 14.25 21.25ZM24 21.25V24.25C24 24.6719 24.3281 25 24.75 25H27.75C28.125 25 28.5 24.6719 28.5 24.25V21.25C28.5 20.875 28.125 20.5 27.75 20.5H24.75C24.3281 20.5 24 20.875 24 21.25Z"
				fill={fill ?? '#242424'}
			/>
		</svg>
	);
};
