// Browser Routes
export const AdminPanelRoutes = {
	live: '/admin/live',
	dashboard: '/admin/dashboard',
	editProfile: '/admin/edit-profile',
	security: '/admin/security',
	staticPage: '/admin/static-page',
	rolePermission: '/admin/role-permission',
	todo: '/admin/todo'
};

export const VenuePanelRoutes = {
	home: '/venue/home',
	subscription: '/venue/subscription',
	dashboard: '/venue/dashboard',
	manageAccount: '/venue/manage-account',
	venueProfile: '/venue/profile',
	connectVenue: '/venue/connect-venue',
	createVenueAccount: '/venue/venue-account/create',
	mytabStaff: '/venue/mytab-staff',
	addMytabStaff: '/venue/mytab-staff/add',
	security: '/venue/security',
	staticPage: '/venue/static-page',
	orderHistory: '/venue/order-history',
	financialReport: '/venue/reports/financial-report',
	summaryReport: '/venue/reports/summary-report',
	salesAnalytics: '/venue/reports/sales-analytics',
	performanceAnalytics: '/venue/reports/performance-analytics',
	customerAnalytics: '/venue/reports/customer-analytics',
	settings: '/venue/settings',
	calender: '/venue/calender',
	venueSecurity: '/venue/venue-security',
	legal: '/venue/legal',
	support: '/venue/support',
	docketFeatures: '/venue/premium-features/docket/features',
	posIntegration: '/venue/premium-features/pos',
	rewards: '/venue/rewards',
	advertising: '/venue/advertising',
	manageMenu: '/venue/manage-menu',
	addManageMenu: '/venue/manage-menu/add',
	editManageMenu: '/venue/manage-menu/edit',
	registerSubscription: '/venue/register-subscription'
};

export const CommonRoutes = {
	landingPage: '/',
	login: '/login',
	forgotPassword: '/forgot-password',
	resetPassword: '/reset-password/:loginRole/:otpToken',
	register: '/register',
	pageNotFound: '/404',
	setUp2Fa: '/setup-2fa'
};

export const VenueProtectedRoutes = {
	connectVenue: '/connect-venue',
	connectExistingVenue: '/connect-existing-venue',
	createVenuePortal: '/create-venue-portal',
	createStripeAccount: '/create-stripe-account',
	stripeAccountProcess: '/stripe-account-process',
	stripeAccountPageClose: '/stripe-account-process-closed',
	posIntegration: '/pos-integration',
	pageNotFound: '/404'
};

export const VenueSetupRoutes = {
	svManageMenu: '/venue-setup/manage-menu',
	svNewMenuItem: '/venue-setup/new-menu-item',
	svPickupLocation: '/venue-setup/pickup-location',
	svWaitTimes: '/venue-setup/wait-times',
	svAssignCategories: '/venue-setup/pickup-location',
	svDocketPrinting: '/venue-setup/docket-printing',
	pageNotFound: '/404'
};

export const NotRedirectAfterCreate = [
	'/connect-venue',
	'/connect-existing-venue',
	'/create-venue-portal'
];

// API Routes
export const VenueApiRoutes = {
	// Auth Api
	register: '/venue/auth/register',
	verifyOtp: '/venue/auth/verify-mfa',
	forgotPassword: '/venue/auth/forgot-password',
	resetPassword: '/venue/auth/reset-password',
	changePassword: '/venue/security/change-password',
	connectVenue: '/venue/bar/connectVenue',
	connectVenueOtpVerification: '/venue/bar/venue-otp-verify',
	subscription: '/venue/auth/save-subscription',
	createVenue: '/venue/bar/createVenue',
	editVenue: '/venue/bar/updateVenueDetails',
	deleteVenue: '/venue/bar/delete', // Delink Venue
	delete: '/venue/bar/deleteVenue', // Delete Venue
	getConnectedVenue: '/venue/auth/myVenueDetails',
	getSingleConnectedVenue: '/venue/bar/getvenuedetails',
	editProfile: '/venue/auth/edit-profile',
	getProfileDetails: '/venue/auth/get-profile-details',
	setUpGoogleAuthenticator: '/venue/auth/setup-mfa',
	getSummaryReportTableData: '/venue/report/itemsummaryreport',
	exportSummaryReportData: '/venue/report/downloaditemsummaryreport',
	getCategoryList: '/venue/report/getCategory',
	deleteAccount: 'venue/auth/delete',
	verifyPassword: '/venue/auth/verify-password',
	updateVenueMobileNo: '/venue/bar/updateVenueMobile',
	logout: '/venue/auth/logout',
	//Home Page Api
	getVenueStatistics: '/venue/dashboard/get-venue-statistics',
	getTotalRevenue: '/venue/dashboard/total-revenue',
	serviceTypePercentage: '/venue/dashboard/service-type-percentage',
	ordersPerHours: '/venue/dashboard/orders-per-hours',
	serviceTypeRevenue: '/venue/dashboard/service-type-revenue',
	customerCountByTime: '/venue/dashboard/customer-count-by-time',
	mostOrderdItems: '/venue/dashboard/most-orderd-items',
	// Order History Api
	getOrderHistoryTableData: '/venue/order/barOrderHistory',
	exportOrderHistoryTableData: '/venue/order/downloadOrderHistory',
	viewOrderDetails: '/venue/order/orderView',
	cancelOrder: '/venue/order/cancel',
	// Live Dashboard Api
	venueOrderList: '/venue/order/barOrderList',
	updateOrderItemWaitTime: '/venue/order/updateOrderItemWaitTime',
	updateOrderItemStatus: '/venue/order/updateOrderItemStatus',
	orderReadyForPickupAlert: '/venue/order/readyForPickupAlert',
	orderIntoxicated: '/venue/order/orderIntoxicated',
	// Manage Menu Api
	getFoodOptionList: '/venue/product/foodOptionList',
	getProductList: '/venue/product/getProductList',
	updateProductSequence: '/venue/product/productSequence',
	changeProductStatus: '/venue/product/change-status',
	deleteProduct: '/venue/product/delete',
	updateSubcategorySequence: '/venue/category/barSubCategorySequence',
	getSubCategory: '/venue/category/getSubCategory',
	getSubCategoryList: '/venue/category/getSubCategoryList',
	getPickupLocation: '/venue/product/pickuplocation-list',
	addProduct: '/venue/product/add',
	getSingleProduct: '/venue/product/getSingleProduct',
	editProduct: '/venue/product/edit',
	getProductItemActiveHours: '/venue/product/getItemActiveHours',
	addUpdateProductItemActiveHours: '/venue/product/addUpdateItemActiveHours',
	deleteProductItemActiveHours: '/venue/product/deleteActiveHours',
	// Docket Printing Api
	docketPrintingFeatStatus: '/venue/feature/change-status',
	// POS Api
	createSubscription: '/doshi/stripe/subscription',
	completeSubscription: '/doshi/stripe/subscription/complete',
	manualMenuSync: '/doshi/getMenuFromDoshii',
	enablePOS: '/doshi/pos/enable',
	disablePOS: '/doshi/pos/disable',
	// Account Settings Api
	//Service Type
	getServiceType: '/venue/bar/getServiceType',
	updateServiceType: '/venue/bar/updateServiceType',
	// Wait Time
	getWaitTimeServiceType: '/venue/bar/getWaitTimeServiceType',
	updateWaitTimeServiceType: '/venue/bar/updateWaitTimeServiceType',
	getWaitTime: '/venue/bar/getSubHeadingWaitTime',
	updateWaitTime: '/venue/bar/updateSubHeadingWaitTime',
	// Pickup Location
	getPickupLocationList: '/venue/pickup-location/list',
	addPickupLocation: '/venue/pickup-location/add',
	editPickupLocation: '/venue/pickup-location/edit',
	deletePickupLocation: '/venue/pickup-location/delete',
	// Promocode
	getPromocodeList: '/venue/promocode/list',
	addPromocode: '/venue/promocode/add',
	editPromocode: '/venue/promocode/edit',
	deletePromocode: '/venue/promocode/delete',
	// Taxes
	getTaxList: '/venue/tax/list',
	addTax: '/venue/tax/add',
	editTax: '/venue/tax/edit',
	deleteTax: '/venue/tax/delete',
	// Legal
	termsAndConditions: '/venue/page/terms',
	privacyPolicy: '/venue/page/barPrivacyPolicy',
	// Support
	faqs: '/venue/page/faq',
	supportContactUs: '/venue/bar/contactUs',
	// Passcode
	changePassCode: '/venue/bar/change-passCode',
	updatePassCodeStatus: '/venue/bar/updatePassCodeStatus',
	setPassCode: '/venue/bar/setPassCode',
	verifyPassCode: '/venue/bar/verifyPassCode',
	// Change Password
	changeVenuePassword: '/venue/bar/change-password',
	// Security Api
	securityVerifyOtp: '/venue/security/verify-otp'
};

export const AdminApiRoutes = {
	verifyOtp: '/cms/admin/verify-mfa',
	forgotPassword: '/cms/admin/forgot-password',
	resetPassword: '/cms/admin/reset-password',
	changePassword: '/cms/security/change-password',
	editProfile: '/cms/admin/edit-profile',
	getProfileDetails: '/cms/admin/get-profile-details'
};
