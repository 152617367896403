import styled from 'styled-components';

const PageWrapper = styled.div`
	.fc {
		border: 0px;
		.fc-header-toolbar.fc-toolbar-ltr {
			margin: 0px;
			.fc-toolbar-chunk:last-child {
				display: flex;
				align-items: center;
				justify-content: center;
				.fc-next-button,
				.fc-prev-button {
					background: transparent;
					border: 0px;
					box-shadow: none !important;
				}
				.fc-icon.fc-icon-chevron-right,
				.fc-icon.fc-icon-chevron-left {
					display: flex;
					align-items: center;
					margin: 0px;
				}
				.fc-icon.fc-icon-chevron-right::before,
				.fc-icon.fc-icon-chevron-left::before {
					color: #242424;
					font-size: 16px;
				}
				.fc-toolbar-title {
					font-size: 16px;
					margin: 0px;
					font-family: 'montserrat-medium';
				}
			}
		}
		.fc-col-header-cell.fc-day {
			padding: 5px;
			background: ${(props) => props.layoutTheme.buttonColor3};
			.fc-col-header-cell-cushion {
				color: #fff;
				text-decoration: none;
			}
			&.fc-day-sun {
				border-radius: 16px 0px 0px 0px;
			}
			&.fc-day-sat {
				border-radius: 0px 16px 0px 0px;
			}
		}

		.fc-scrollgrid.fc-scrollgrid-liquid {
			border-radius: 16px 16px 0px 0px;
		}
		.fc-scrollgrid-section.fc-scrollgrid-section-header {
			th {
				border: 0px;
			}
		}
		.fc-daygrid-day-top {
			flex-direction: row !important;
			a {
				text-decoration: none;
				color: #242424;
			}
		}
		.fc-col-header,
		.fc-scrollgrid-sync-table,
		.fc-daygrid-body.fc-daygrid-body-unbalanced {
			width: 100% !important;
		}
		.fc-daygrid-day.fc-day-today {
			background-color: #fff;
			border: 2px solid ${(props) => props.layoutTheme.buttonColor3} !important;
			.fc-daygrid-day-number {
				color: ${(props) => props.layoutTheme.buttonColor3} !important;
				font-size: 16px;
				font-family: 'montserrat-semi-bold';
			}
		}
		.fc-highlight {
			display: none;
		}
		.fc-event {
			cursor: pointer;
		}
		.fc-event-main {
			padding-left: 5px;
		}
		.fc-daygrid-event-harness {
			margin-bottom: 5px;
		}
	}
`;

export default PageWrapper;
