import styled from 'styled-components';

const PageWrapper = styled.div`
	color: #4f4f4f !important;
	.horizontalLine {
		border-top: 1px solid #eaeaea;
	}
	.restoreFeature {
		border-bottom: 1px solid #eaeaea;
	}
	.rightImage {
		width: 24px;
		height: 24px;
	}
`;

export default PageWrapper;
