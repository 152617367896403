import styled from 'styled-components';

const PageWrapper = styled.div`
	.timeBox {
		background: #ffffff;
		border: 1px solid #dddddd;
		border-radius: 6px;
		cursor: pointer;
		&.active {
			background: #ff5f5f;
			border: 1px solid #ff5f5f;
			color: #ffffff;
		}
	}
	.startTime {
		min-width: 50px;
	}
	.horizontalLine {
		border-top: 1px solid #eaeaea !important;
	}
`;

export default PageWrapper;
