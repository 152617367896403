import { Row, Col } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

const MenuItemFormSkeleton = () => {
	return (
		<form className="overflow-hidden">
			<Row className="ma-b-18">
				<Col sm={12}>
					<Skeleton height="75px" width={'75px'} borderRadius={100} />
				</Col>
			</Row>
			<Row className="ma-b-18">
				<Col md={6}>
					<Skeleton height="12px" width={'115px'} borderRadius={6} />
					<Skeleton height="40px" width={'100%'} borderRadius={6} />
				</Col>
				<Col md={6}>
					<Skeleton height="12px" width={'115px'} borderRadius={6} />
					<Skeleton height="40px" width={'100%'} borderRadius={6} />
				</Col>
			</Row>
			<Row className="ma-b-18">
				<Col md={6}>
					<Skeleton height="12px" width={'115px'} borderRadius={6} />
					<Skeleton height="40px" width={'100%'} borderRadius={6} />
				</Col>
				<Col md={6}>
					<Skeleton height="12px" width={'115px'} borderRadius={6} />
					<Skeleton height="40px" width={'100%'} borderRadius={6} />
				</Col>
			</Row>
			<Row className="ma-b-18">
				<Col md={12}>
					<Skeleton height="12px" width={'115px'} borderRadius={6} />
					<Skeleton height="88px" width={'100%'} borderRadius={6} />
				</Col>
			</Row>
			<Row>
				<Col sm={12}>
					<Skeleton
						height="14px"
						width={'115px'}
						borderRadius={6}
						className="ma-b-18"
					/>
					<div className="dietaryRequirementsContainer gap-1">
						{Array(6)
							.fill('1')
							?.map((_, index) => (
								<div className="d-flex gap-2" key={index}>
									<Skeleton
										height="16px"
										width={'16px'}
										borderRadius={2}
									/>
									<Skeleton
										height="12px"
										width={'133px'}
										borderRadius={6}
									/>
								</div>
							))}
					</div>
				</Col>
			</Row>
			<div className="horizontalLine ma-t-18 mb-4" />
			<div className="additionalItemsWrapper">
				<div className="flex-1">
					<div className="d-flex justify-content-between">
						<div>
							<Skeleton
								height="14px"
								width={'162px'}
								borderRadius={2}
							/>
							<Skeleton
								height="12px"
								width={100}
								borderRadius={2}
							/>
						</div>
						<div>
							<Skeleton
								height="30px"
								width={'30px'}
								borderRadius={6}
							/>
						</div>
					</div>
					<div className="w-100 ma-t-18">
						<Skeleton
							height="40px"
							width={'100%'}
							borderRadius={6}
						/>
					</div>
					<div className="d-flex gap-2 ma-t-18">
						<div className="flex-1">
							<Skeleton
								height="12px"
								width={'115px'}
								borderRadius={6}
							/>
							<Skeleton
								height="40px"
								width={'100%'}
								borderRadius={6}
							/>
						</div>
						<div className="flex-1">
							<Skeleton
								height="12px"
								width={'115px'}
								borderRadius={6}
							/>
							<Skeleton
								height="40px"
								width={'100%'}
								borderRadius={6}
							/>
						</div>
					</div>
				</div>
				<div className="flex-1">
					<div className="d-flex justify-content-between">
						<div>
							<Skeleton
								height="14px"
								width={'162px'}
								borderRadius={2}
							/>
							<Skeleton
								height="12px"
								width={100}
								borderRadius={2}
							/>
						</div>
						<div>
							<Skeleton
								height="30px"
								width={'30px'}
								borderRadius={6}
							/>
						</div>
					</div>
					<div className="w-100 ma-t-18">
						<Skeleton
							height="40px"
							width={'100%'}
							borderRadius={6}
						/>
					</div>
					<div className="d-flex gap-2 ma-t-18">
						<div className="flex-1">
							<Skeleton
								height="12px"
								width={'115px'}
								borderRadius={6}
							/>
							<Skeleton
								height="40px"
								width={'100%'}
								borderRadius={6}
							/>
						</div>
						<div className="flex-1">
							<Skeleton
								height="12px"
								width={'115px'}
								borderRadius={6}
							/>
							<Skeleton
								height="40px"
								width={'100%'}
								borderRadius={6}
							/>
						</div>
					</div>
				</div>
			</div>
			<Row className="pa-t-54">
				<Col
					sm={6}
					className="d-flex align-items-center justify-content-start gap-4 flex-column flex-sm-row"
				>
					<Skeleton height="45px" width={'148px'} borderRadius={6} />
					<Skeleton height="45px" width={'148px'} borderRadius={6} />
				</Col>
			</Row>
		</form>
	);
};

export default MenuItemFormSkeleton;
