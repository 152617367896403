import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import rightImg from '../../Assets/images/right.png';
import { Button } from 'reactstrap';

const SubscriptionCardWrapper = styled.div`
	height: 100%;
	background-color: transparent;
	border-radius: 16px;
	.mainContent {
		background-color: #ffffff;
		border: 1px solid #bababa;
		.topPart {
			height: 234px;
		}
		.horizontalLine {
			height: 1px;
			background-color: #bababa;
		}
	}
	.themeButtonFullWidth2 {
		background: #fabbbb !important;
		color: ${(props) => props.layoutTheme.buttonTextColor} !important;
		border: 2px solid #f94d73 !important;
		width: 100%;
		margin-top: 30px;
		font-family: 'montserrat-medium';
		padding-block: 15px;
		font-size: 18px;
		@media only screen and (max-width: 1600px) {
			padding-block: 10px;
			font-size: 14px;
		}
	}
	.topButton {
		background: linear-gradient(
			-30deg,
			#ff9865 0%,
			#ff708d 92.95%
		) !important;
	}
`;

const SubscriptionCard = ({
	title,
	description,
	amount,
	features,
	showTopButton = false,
	topButtonLabel,
	buttonLabel,
	handleButtonClick,
	activeButton = false
}) => {
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	return (
		<>
			<SubscriptionCardWrapper
				{...allThemeData}
				className={'d-flex flex-column'}
			>
				<div
					className={`pa-10 text-center text-white fs-20 semi-bold-text topButton ${
						showTopButton ? 'visible' : 'invisible'
					}`}
					style={{
						top: -50,
						borderRadius: '16px 16px 0 0'
					}}
				>
					{topButtonLabel ? topButtonLabel : 'Button Label'}
				</div>
				<div
					className={
						'pa-t-24 pa-b-16 d-flex flex-column mainContent flex-1'
					}
					style={{
						backgroundColor: showTopButton ? '#ffe8e7' : '#ffffff',
						borderRadius: showTopButton ? '0 0 16px 16px' : '16px'
					}}
				>
					<div>
						<div className="text-center d-flex flex-column justify-content-between topPart">
							<div>
								<h1 className="fs-36 bold-text">{title}</h1>
								<p className="pa-t-4 fs-15 medium-text lh-sm plr-10">
									{description}
								</p>
							</div>
							<div className="d-flex flex-column align-items-center">
								<div className="d-flex">
									<span className="fs-20 medium-text">
										AUD $
									</span>
									<span className="fs-56 bold-text pl-12">
										{amount}
									</span>
									<span className="fs-20 medium-text align-self-end pl-12">
										/mo
									</span>
								</div>
								<span className="pa-t-4 fs-14 medium-text">
									Billed Monthly
								</span>
							</div>
						</div>
						<div className="plr-40 pa-t-24">
							<div className="horizontalLine"></div>
						</div>
					</div>
					<div className="flex-1 bottomPart plr-40 pa-t-24 d-flex flex-column justify-content-between">
						<div>
							<p className="fs-20 medium-text">
								What’s included on {title}
							</p>
							{features?.length > 0 &&
								features?.map((item) => {
									return (
										<div
											className={`pa-t-16 d-flex align-items-center ${
												item?.color === '#FF5F5F'
													? 'cursor-pointer'
													: ''
											}`}
										>
											<div>
												<img
													src={rightImg}
													alt="right_icon_img"
												/>
											</div>
											<span
												className="fs-18 semi-bold-text pl-16 text-break"
												style={{
													color: item?.color
														? item.color
														: '#242424'
												}}
											>
												{item?.title}
											</span>
										</div>
									);
								})}
						</div>
						<div className={`pa-t-45`}>
							<Button
								className={`${
									activeButton
										? 'themeButtonFullWidth'
										: 'themeButtonFullWidth2'
								}`}
								onClick={handleButtonClick}
								disabled={!activeButton}
							>
								{buttonLabel}
							</Button>
						</div>
					</div>
				</div>
			</SubscriptionCardWrapper>
		</>
	);
};

export default SubscriptionCard;
