import styled from 'styled-components';
const CustomLableStyle = styled.div`
	.label {
		display: flex;
		align-items: center;
		gap: 2px;
		font-family: 'montserrat-bold';
		font-size: 20px;
		text-transform: uppercase;
		padding-bottom: 6px;
		color: #313132eb;
		.tooltip-style .tooltip .tooltip-inner {
			max-width: 20rem !important;
		}

		.requiredImg {
			width: 22px;
			height: 19px;
		}
	}

	@media (max-width: 600px) {
		.label {
			font-size: 12px;

			.requiredImg {
				object-fit: contain;
				width: 15px;
				height: 12px;
			}
		}
	}

	@media only screen and (min-width: 600px) and (max-width: 960px) {
		.label {
			font-size: 15px;
		}
	}

	@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
		.label {
			font-size: 11.2px;

			.requiredImg {
				width: 12.3px;
				height: 10.6px;
			}
		}
		.input {
			height: 46.3px;
			font-size: 13.5px;
		}
		.error {
			font-size: 10px;
		}
	}
`;

export default CustomLableStyle;
