import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Row, Col, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import * as validation from '../../../Helper/YupValidation';
import FormInput from '../../Form/FormInput';
import FormRadioButton from '../../Form/FormRadioButton';
import { EditIcon, PlusIcon } from '../../Icons';
import FormWrapper from './index.style';
import FormCheckBox from '../../Form/FormCheckBox';
import ImageUpload from '../../Form/ImageUpload';
import { VenueApiRoutes, VenuePanelRoutes } from '../../../Utils/routes';
import Api from '../../../Helper/Api';
import CustomButton from '../../Common/CustomButton';
import { OpeningHoursModalDetails } from '../../UserProfile/ConnectVenue/utils';
import OpeningHoursModal from '../../UserProfile/ConnectVenue/OpeningHoursModal';
import VenueProfileFormSkeleton from './VenueProfileFormSkeleton';
import authActions from '../../../Redux/auth/actions';
import MapModal from './MapModal';
import DeleteConfirmModal from './DeleteConfirmModal';

const VenueProfileForm = () => {
	const state = useSelector((state) => ({ ...state }));
	const allThemeData = state.themeChanger;
	const authData = state.auth;
	const bars = authData?.bars;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [openingHoursModalData, setOpeningHoursModalData] = useState(
		OpeningHoursModalDetails
	);
	const [isOpenMapModal, setIsOpenMapModal] = useState(false);
	const [isAlcohol, setIsAlcohol] = useState('No');
	const [isOpenOpeningHoursModal, setIsOpenOpeningHoursModal] =
		useState(false);
	const [loading, setLoading] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);

	const validationSchema = yup.object().shape({
		image: validation.YUP_VALIDATION.IMAGE,
		venueName: validation.YUP_VALIDATION.VENUE_NAME,
		ownerManagerName: validation.YUP_VALIDATION.OWNER_MANAGER_NAME,
		email: validation.YUP_VALIDATION.EMAIL,
		address: validation.YUP_VALIDATION.ADDRESS,
		abnAcn: validation.YUP_VALIDATION.ABN_ACN_NUMBER,
		isAlcohol: validation.YUP_VALIDATION.IS_ALCOHOL,
		licenseNumber:
			isAlcohol === 'Yes' && validation.YUP_VALIDATION.LICENSE_NUMBER
		// serviceType: validation.YUP_VALIDATION.SERVICE_TYPE
	});
	const handleOpeningHoursModal = () => {
		setIsOpenOpeningHoursModal((prev) => !prev);
	};
	const handleMapModal = () => {
		setIsOpenMapModal((prev) => !prev);
	};
	const handleOpeningHoursModalData = (data) => {
		setOpeningHoursModalData(data);
	};

	const handleDeleteConfirmModal = () => {
		setDeleteConfirmModal((prev) => !prev);
	};
	const handleVenueDelete = async () => {
		try {
			setLoading(true);
			let barId = authData?.selectedVenue?.id;
			const res = await Api('DELETE', VenueApiRoutes.delete, {
				bar_id: authData?.selectedVenue?.id?.toString()
			});
			setLoading(false);
			if (res?.data?.status) {
				setDeleteConfirmModal(false);
				navigate(VenuePanelRoutes.connectVenue);
				let updatedBars = [];
				if (bars?.length > 0) {
					bars?.forEach((item) => {
						if (item?.id != barId) {
							updatedBars?.push(item);
						}
					});
				}
				if (updatedBars?.length > 0) {
					dispatch(authActions.set_selected_venue(updatedBars[0]));
				} else {
					dispatch(authActions.set_selected_venue(null));
				}
				dispatch(authActions.update_venue_list(updatedBars));
				toast.success(res?.data?.message);
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	const getFormData = async (updateSelectedVenue = false) => {
		setLoading(true);
		try {
			const res = await Api(
				'POST',
				VenueApiRoutes.getSingleConnectedVenue,
				{
					bar_id: authData?.selectedVenue?.id
				}
			);
			setLoading(false);
			let venueData = res?.data?.data;
			if (res?.data?.status) {
				setValues({
					image: venueData?.avatar,
					venueName: venueData?.restaurantName,
					ownerManagerName: venueData?.managerName,
					email: venueData?.email,
					address: venueData?.address,
					abnAcn: venueData?.businessRegisterId,
					isAlcohol: venueData?.isVenueServeAlcohol,
					licenseNumber: venueData?.liquorLicenseNumber,
					// serviceType:
					// 	venueData?.serviceType === 'BOTH'
					// 		? ['PICKUP', 'TABLE']
					// 		: [`${venueData?.serviceType}`],
					latitude: venueData?.latitude,
					longitude: venueData?.longitude
				});
				let openingHoursData = venueData?.operating_hours?.map(
					(item) => {
						return {
							id: item?.id,
							weekDay: item?.weekDay,
							activeHours: item?.openingHours,
							inActiveHours: item?.closingHours,
							isClosed: item?.isClosed
						};
					}
				);
				setOpeningHoursModalData(openingHoursData);
				setIsAlcohol(venueData?.isVenueServeAlcohol);
				if (updateSelectedVenue) {
					dispatch(
						authActions.update_selected_venue({
							avatar: venueData?.avatar,
							restaurantName: venueData?.restaurantName,
							managerName: venueData?.managerName
						})
					);
				}
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	const submitFormHandler = async (values) => {
		const payload = {
			venue_name: values?.venueName,
			email: values?.email,
			manager_name: values?.ownerManagerName,
			venue_address: values?.address,
			business_register_id: values?.abnAcn,
			is_venue_serve_alcohol: values?.isAlcohol,
			liquor_license_number: values?.licenseNumber,
			image: values?.image,
			// service_type:
			// 	values?.serviceType?.length === 2
			// 		? ['BOTH']
			// 		: values?.serviceType,
			bar_id: authData?.selectedVenue?.id,
			latitude: values?.latitude,
			longitude: values?.longitude
		};
		if (isAlcohol === 'No') {
			delete payload.liquor_license_number;
		}
		let formData = new FormData();
		for (let key in payload) {
			formData.append(key, payload[key]);
		}
		for (let i = 0; i < openingHoursModalData?.length; i++) {
			formData.append(
				`operating_hours[${i}][id]`,
				openingHoursModalData[i]?.id
			);
			formData.append(
				`operating_hours[${i}][opening_hours]`,
				openingHoursModalData[i]?.activeHours
			);
			formData.append(
				`operating_hours[${i}][closing_hours]`,
				openingHoursModalData[i]?.inActiveHours
			);
			formData.append(
				`operating_hours[${i}][is_closed]`,
				openingHoursModalData[i]?.isClosed
			);
		}
		try {
			setSaveLoading(true);
			const res = await Api('PUT', VenueApiRoutes.editVenue, formData);
			setSaveLoading(false);
			if (res?.data?.status) {
				await getFormData(true);
				toast.success(res?.data?.message);
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setSaveLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	const {
		handleChange,
		handleBlur,
		setFieldValue,
		handleSubmit,
		values,
		touched,
		errors,
		setFieldTouched,
		resetForm,
		setValues
	} = useFormik({
		initialValues: {
			image: null,
			venueName: '',
			ownerManagerName: '',
			email: '',
			address: '',
			abnAcn: '',
			isAlcohol: 'No',
			licenseNumber: '',
			serviceType: [],
			latitude: '',
			longitude: ''
		},
		validationSchema,
		onSubmit: submitFormHandler
	});
	useEffect(() => {
		getFormData();
	}, [authData?.selectedVenue?.id]);
	return (
		<>
			{loading ? (
				<VenueProfileFormSkeleton />
			) : (
				<FormWrapper {...allThemeData}>
					<form onSubmit={handleSubmit} className="overflow-hidden">
						<Row>
							<Col sm={6} className="pa-b-20">
								<ImageUpload
									name={'image'}
									defaultImage={
										'https://via.placeholder.com/75x75?text=IMAGE'
									}
									icon={
										<EditIcon
											height={14}
											weight={14}
											stroke="#fff"
										/>
									}
									value={values?.image}
									setFieldValue={setFieldValue}
									error={touched?.image && !!errors?.image}
									errorMsg={errors?.image}
								/>
							</Col>
							<Col
								sm={6}
								className="pa-b-20 d-md-flex align-items-md-center justify-content-md-end"
							>
								<Button
									type="button"
									className="themeBorderButton plr-24"
									onClick={handleOpeningHoursModal}
								>
									<PlusIcon
										height="18px"
										width="18px"
										className="mr-6"
									/>{' '}
									Venue Opening Hours
								</Button>
							</Col>
						</Row>
						<Row style={{ '--bs-gutter-x': '18px' }}>
							<Col lg={6} className="pa-b-20">
								<FormInput
									type="text"
									name="venueName"
									label="Venue Name"
									placeholder="Enter Venue Name"
									value={values?.venueName}
									onChange={handleChange}
									onBlur={handleBlur}
									error={
										touched?.venueName &&
										!!errors?.venueName
									}
									errorMsg={errors?.venueName}
								/>
							</Col>
							<Col lg={6} className="pa-b-20">
								<FormInput
									type="text"
									name="ownerManagerName"
									label="Owner/Manager Name"
									placeholder="Enter Owner/Manager Name"
									value={values?.ownerManagerName}
									onChange={handleChange}
									onBlur={handleBlur}
									error={
										touched?.ownerManagerName &&
										!!errors?.ownerManagerName
									}
									errorMsg={errors?.ownerManagerName}
								/>
							</Col>
						</Row>
						<Row style={{ '--bs-gutter-x': '18px' }}>
							<Col lg={6} className="pa-b-20">
								<FormInput
									type="email"
									name="email"
									label="Email Address"
									placeholder="Enter Email Address"
									value={values?.email}
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched?.email && !!errors?.email}
									errorMsg={errors?.email}
									// disabled={true}
								/>
							</Col>
							<Col lg={6} className="pa-b-20">
								<FormInput
									type="text"
									name="address"
									label="Venue Address"
									placeholder="Enter Venue Address"
									value={values?.address}
									onChange={handleChange}
									onBlur={handleBlur}
									error={
										touched?.address && !!errors?.address
									}
									errorMsg={errors?.address}
									onClick={() => setIsOpenMapModal(true)}
								/>
							</Col>
						</Row>
						<Row style={{ '--bs-gutter-x': '18px' }}>
							<Col lg={6} className="pa-b-20">
								<FormInput
									type="text"
									name="abnAcn"
									label="ABN/ACN Details"
									placeholder="EnhandleOpeningHoursModal
handleOpeningHoursModal
handleOpeningHoursModalter ABN/ACN Details"
									value={values?.abnAcn}
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched?.abnAcn && !!errors?.abnAcn}
									errorMsg={errors?.abnAcn}
								/>
							</Col>
							{/* <Col lg={6} className="pa-b-20">
								<div className="serviceType">
									<p className="fs-14 medium-text themeText">
										Your MyTab Venue Service Type
									</p>
									<div
										className="d-flex align-items-center"
										style={{ gap: '18px' }}
									>
										<FormCheckBox
											name="serviceType"
											label="Takeaway"
											value="PICKUP"
											onChange={handleChange}
											onBlur={handleBlur}
											checked={values?.serviceType?.includes(
												'PICKUP'
											)}
										/>
										<FormCheckBox
											name="serviceType"
											label="Table Service"
											value="TABLE"
											onChange={handleChange}
											onBlur={handleBlur}
											checked={values?.serviceType?.includes(
												'TABLE'
											)}
										/>
									</div>
								</div>
								{touched?.serviceType &&
									!!errors?.serviceType && (
										<p className="fs-10 semi-bold-text headingTextColor pa-t-6">
											{errors?.serviceType}
										</p>
									)}
							</Col> */}
						</Row>
						<Row style={{ '--bs-gutter-x': '18px' }}>
							<Col lg={6} className="pa-b-20">
								<div className="isServeAlcohol">
									<p className="fs-14 medium-text themeText">
										Will your venue be serving alcohol?
									</p>
									<div
										className="d-flex align-items-center"
										style={{ gap: '18px' }}
									>
										<FormRadioButton
											label={'Yes'}
											name="isAlcohol"
											checked={
												values?.isAlcohol === 'Yes'
											}
											onChange={() => {
												setFieldValue(
													'isAlcohol',
													'Yes'
												);
												setIsAlcohol('Yes');
											}}
										/>
										<FormRadioButton
											label={'No'}
											name="isAlcohol"
											checked={values?.isAlcohol === 'No'}
											onChange={() => {
												setFieldValue(
													'isAlcohol',
													'No'
												);
												setIsAlcohol('No');
											}}
										/>
									</div>
								</div>
								{touched?.isAlcohol && !!errors?.isAlcohol && (
									<p className="fs-10 semi-bold-text headingTextColor pa-t-6">
										{errors?.isAlcohol}
									</p>
								)}
							</Col>
							{isAlcohol === 'Yes' && (
								<Col lg={6} className="pa-b-20">
									<FormInput
										type="text"
										name="licenseNumber"
										label="Liquor License Number"
										placeholder="Enter Liquor License Number"
										value={values?.licenseNumber}
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched?.licenseNumber &&
											!!errors?.licenseNumber
										}
										errorMsg={errors?.licenseNumber}
									/>
								</Col>
							)}
						</Row>
						<Row style={{ '--bs-gutter-x': '18px' }}>
							<Col lg={6} className="pa-b-20">
								<CustomButton
									type="submit"
									className="themeButton"
									loading={saveLoading}
								>
									Save
								</CustomButton>
							</Col>
							<Col
								lg={6}
								className="pa-b-20 d-md-flex align-items-md-center justify-content-center justify-content-md-end"
							>
								<CustomButton
									type="button"
									className="noBorderButton"
									onClick={() => {
										handleDeleteConfirmModal();
									}}
								>
									Delete Account
								</CustomButton>
							</Col>
						</Row>
					</form>
				</FormWrapper>
			)}
			{isOpenOpeningHoursModal && (
				<OpeningHoursModal
					type="EDIT"
					isOpen={isOpenOpeningHoursModal}
					handleModal={handleOpeningHoursModal}
					openingHoursModalData={openingHoursModalData}
					handleOpeningHoursModalData={handleOpeningHoursModalData}
					email={values?.email}
				/>
			)}

			<DeleteConfirmModal
				isOpen={deleteConfirmModal}
				handleModal={handleDeleteConfirmModal}
				deleteLoading={loading}
				handleVenueDelete={handleVenueDelete}
			/>
			{isOpenMapModal && (
				<MapModal
					isOpen={isOpenMapModal}
					handleModal={handleMapModal}
					setFieldValue={setFieldValue}
				/>
			)}
		</>
	);
};

export default VenueProfileForm;
