import React, { useState } from 'react';
import formRequiredImg from '../../Assets/images/formRequired.png';
import { Tooltip } from 'reactstrap';
import CustomLableStyle from './customLable.style';

const CustomLabel = ({
	id,
	label,
	showRequired,
	style,
	tootlTipMessage = 'Required'
}) => {
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const toggle = () => setTooltipOpen(!tooltipOpen);
	return (
		<CustomLableStyle>
			<span className="label" style={{ ...style }}>
				{label}&nbsp;
				{showRequired && (
					<>
						<img
							src={formRequiredImg}
							alt="form-required"
							className="requiredImg"
							id={id}
						/>
						<Tooltip
							className="tooltip-style"
							placement="right"
							isOpen={tooltipOpen}
							autohide={false}
							target={id}
							toggle={toggle}
						>
							{tootlTipMessage}
						</Tooltip>
					</>
				)}
			</span>
		</CustomLableStyle>
	);
};

export default CustomLabel;
