import styled from 'styled-components';

const PageWrapper = styled.div`
	.defaultBoxShadow {
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
	}
	.bannerCard {
		border-radius: 18px 18px 12px 12px !important;
		position: relative;
		.imgSkeleton {
			height: 170px !important;
			object-fit: cover;
			border-radius: 18px 18px 0 0;
			position: absolute;
			top: -6px;
			left: 0;
			right: 0;
		}
		img {
			height: 168px !important;
			object-fit: cover;
			border-radius: 18px 18px 0 0;
		}
		.bannerCardImgOverlay {
			color: white;
			border-radius: 18px 18px 0 0;
			background: linear-gradient(
				88.12deg,
				rgba(0, 0, 0, 0.69) 2.55%,
				rgba(0, 0, 0, 0) 61.3%
			);
			top: 0;
			left: 0;
			right: 0;
			height: 168px !important;
			display: flex;
			flex-direction: column;
			justify-content: end;
			padding: 0 !important;
		}
	}
	.card-title {
		margin-left: 21px !important;
		margin-bottom: 21px !important;
	}
	.subImageCard {
		border: 1px solid #f4f4f4;
		border-radius: 12px;
		height: 100%;
		padding: 18px;
		position: relative;
		.subImageCardSkeleton {
			position: absolute;
			top: -5px;
			left: 0;
			right: 0;
			bottom: 0;
		}
		.circleIcon {
			width: 30px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 100%;
		}
	}
	@media only screen and (max-width: 767px) {
		.defaultBoxShadow {
			box-shadow: unset;
		}
		.bannerCard {
			img {
				border-radius: 18px 18px 0 0;
			}
		}
	}
	.card-body {
		padding: 22px 24px !important;
	}
`;

export default PageWrapper;
