import React from 'react';
import { Fragment } from 'react';
import { Button } from 'reactstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useState } from 'react';
import Api from '../../../Helper/Api';
import { VenueApiRoutes } from '../../../Utils/routes';
import { BellIcon2, BellIcon3 } from '../../Icons';
import CustomButton from '../../Common/CustomButton';
import PreparedCardPopover from '../PreparedCardPopover';

const OrderItemGroup = ({
	item,
	index,
	data,
	authData,
	getVenueOrderList,
	setRefundModal,
	setIntoxicatedModal,
	setNotPickedUpModal,
	setNotPickedUpModalData,
	setRefundModalData,
	viewOnly
}) => {
	const [pickupLoading, setPickupLoading] = useState(false);
	const [pickupAlertLoading, setPickupAlertLoading] = useState(false);
	const [orderPopover, setOrderPopover] = useState(false);
	const orderPopoverToggle = () => {
		setOrderPopover(!orderPopover);
	};
	const handlePickedup = async (item) => {
		let itemsIds = item?.items?.map((value) => value?.id);
		let id = itemsIds?.join(',');
		try {
			setPickupLoading(true);
			const res = await Api(
				'POST',
				VenueApiRoutes.updateOrderItemStatus,
				{
					bar_id: '' + authData?.selectedVenue?.id,
					id: id,
					orderStatus: 'Pickedup'
				}
			);
			if (res?.data?.status) {
				toast.success(res?.data?.message);
				await getVenueOrderList();
			} else {
				toast.error(res?.data?.message);
			}
			setPickupLoading(false);
		} catch (err) {
			setPickupLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	const handlePickupAlert = async () => {
		try {
			setPickupAlertLoading(true);
			const res = await Api(
				'POST',
				VenueApiRoutes.orderReadyForPickupAlert,
				{
					bar_id: '' + authData?.selectedVenue?.id,
					id: data?.id
				}
			);
			if (res?.data?.status) {
				toast.success(res?.data?.message);
			} else {
				toast.error(res?.data?.message);
			}
			setPickupAlertLoading(false);
		} catch (err) {
			setPickupAlertLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	return (
		<Fragment>
			<div className="plr-18">
				{item?.items?.length > 0 &&
					item?.items?.map((subItem, subIndex) => {
						return (
							<div
								key={subIndex}
								className="d-flex justify-content-between align-items-center"
							>
								<p
									key={subIndex}
									className={`fs-12 medium-text themeText ${
										subIndex !== item?.items?.length - 1
											? 'pa-b-6'
											: ''
									} d-flex`}
								>
									{subItem?.quantity ?? ''}
									<span className="plr-10">-</span>
									<span className="">
										{subItem?.product?.name ?? ''}
										{subItem?.order_product_variant_types
											?.length > 0 &&
											subItem?.order_product_variant_types?.map(
												(extraItem, index) => (
													<span
														key={index}
														className="opacity-50"
													>
														{', ' +
															extraItem
																?.product_variant_type
																?.order_product_variant_sub_type
																?.product_variant_sub_type
																?.extraItem ??
															''}
													</span>
												)
											)}
										{subItem?.order_item_extras?.length >
											0 &&
											subItem?.order_item_extras?.map(
												(extraItem, index) => (
													<span
														key={index}
														className="opacity-50"
													>
														{', ' +
															extraItem
																?.product_extra
																?.extraItem ??
															''}
													</span>
												)
											)}
									</span>
								</p>
								{!viewOnly && (
									<span
										className={`threeDotContainer ${
											subIndex === 0 && index !== 0
												? 'visible'
												: 'invisible'
										}`}
									>
										<PreparedCardPopover
											setRefundModal={setRefundModal}
											setIntoxicatedModal={
												setIntoxicatedModal
											}
											setNotPickedUpModal={
												setNotPickedUpModal
											}
											setNotPickedUpModalData={
												setNotPickedUpModalData
											}
											setRefundModalData={
												setRefundModalData
											}
											item={item}
											data={data}
										/>
									</span>
								)}
							</div>
						);
					})}
			</div>
			<div className="plr-18">
				<div className="ptb-15">
					<div className="horizontalLine" />
				</div>
				<div
					className={`d-flex ${
						index === data?.order_items_group?.length - 1
							? 'justify-content-between'
							: 'justify-content-end'
					} align-items-center`}
				>
					{index === data?.order_items_group?.length - 1 && (
						<p className="fs-11 regular-text">
							{moment(data?.createdAt).fromNow()}
						</p>
					)}
					<div
						className={`d-flex gap-2 ${
							viewOnly ? 'invisible' : 'visible'
						}`}
					>
						<Button
							className={`${
								pickupAlertLoading
									? 'themeButton bellButton'
									: 'themeBorderButton'
							} ptb-4 plr-10`}
							onClick={handlePickupAlert}
						>
							{pickupAlertLoading ? (
								<BellIcon3
									height={24}
									width={24}
									fill={'#FFFFFF'}
								/>
							) : (
								<BellIcon2
									height={24}
									width={24}
									fill={'#F94D73'}
								/>
							)}
						</Button>
						<CustomButton
							className="themeBorderButton plr-24 ptb-8"
							loading={pickupLoading}
							onClick={() => handlePickedup(item)}
						>
							Picked Up
						</CustomButton>
					</div>
				</div>
			</div>
			{index !== data?.order_items_group?.length - 1 && (
				<div className="ptb-15">
					<div className="horizontalLine2" />
				</div>
			)}
		</Fragment>
	);
};

export default OrderItemGroup;
