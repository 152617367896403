import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Fragment } from 'react';
import CustomModal from '../../Common/Modal/CustomModal';
import FormCheckBox from '../../Form/FormCheckBox';
import FormInput from '../../Form/FormInput';
import useDevice from '../../../Hooks/useDevice';
import Api from '../../../Helper/Api';
import { VenueApiRoutes } from '../../../Utils/routes';
import RefundInitiateModalSkeleton from './RefundInitiateModalSkeleton';
import CustomButton from '../../Common/CustomButton';
import EmptyData from '../../Common/EmptyData';

const RefundModal = ({
	isOpen,
	handleModal,
	refundModalData,
	getVenueOrderList
}) => {
	const state = useSelector((state) => ({ ...state }));
	const authDetails = state.auth;
	const [loading, setLoading] = useState(false);
	const [refundLoading, setRefundLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [data, setData] = useState(null);
	const [refundedAmount, setRefundedAmount] = useState('0.00');
	const [checkedDisable, setTransactionFeeCheckedDisable] = useState(true);
	const refundableTransactionFee = data?.data?.refundTransactionFee === 'Yes';

	const { isMobile } = useDevice();
	const {
		handleChange,
		handleSubmit,
		setFieldValue,
		setValues,
		values,
		setFieldError
	} = useFormik({
		initialValues: {
			items: [
				{
					isRefunded: false,
					refundedQuantity: 0,
					inputRefundedQuantity: 0,
					totalQuantity: '',
					itemName: '',
					price: '',
					id: '',
					chargeAmount: '',
					orderItemExtras: null,
					orderProductVariantTypes: null
				}
			],
			taxDetails: [],
			promocodeAmount: '',
			promocodeDiscount: '',
			coupon: null
		}
	});
	const checkRefundInputError = (item) => {
		if (Number(item?.inputRefundedQuantity) < 0) {
			return 'Please enter number greater than 0.';
		} else if (!Number?.isInteger(Number(item?.inputRefundedQuantity))) {
			return 'Please enter whole number.';
		} else if (
			Number(item?.inputRefundedQuantity) >
			Number(item?.totalQuantity) - Number(item?.refundedQuantity)
		) {
			return `Please enter quantity less than or equal to 
				 ${Number(item?.totalQuantity) - Number(item?.refundedQuantity)}
				`;
		} else {
			return null;
		}
	};
	const [transactionsFees, setTransactionsFeeChecked] = useState(false);
	useEffect(() => {
		const unRefundedTax = values?.taxDetails.filter(
			(item) => item.taxAmount > 0
		);

		const unRefundedProducts = values?.items.filter(
			(item) => item.refundedQuantity !== item.totalQuantity
		);

		const getInputRemainingProductAndQty = values?.items?.filter(
			(item) =>
				item.totalQuantity > item.refundedQuantity &&
				item.isRefunded === true &&
				item.inputRefundedQuantity ===
					item.totalQuantity - item.refundedQuantity
		);

		const getRefundProductAndQty = values?.items?.filter(
			(item) => item.inputRefundedQuantity > 0 && item.isRefunded === true
		);

		const getInputRefundTaxes = values?.taxDetails.filter(
			(item) => item.isRefunded === true && item.taxAmount > 0
		);

		if (refundableTransactionFee) {
			setTransactionsFeeChecked(true);
			setTransactionFeeCheckedDisable(true);
		} else if (
			getInputRemainingProductAndQty?.length ===
				unRefundedProducts?.length &&
			(unRefundedTax?.length === 0 ||
				getInputRefundTaxes?.length === values?.taxDetails?.length)
		) {
			setTransactionsFeeChecked(true);
			setTransactionFeeCheckedDisable(true);
		} else if (
			getRefundProductAndQty?.length >= 1 ||
			getInputRefundTaxes?.length >= 1
		) {
			setTransactionFeeCheckedDisable(false);
		} else {
			setTransactionFeeCheckedDisable(true);
			setTransactionsFeeChecked(false);
		}
	}, [transactionsFees, values]);

	useEffect(() => {
		setData(refundModalData);
	}, [refundModalData]);
	useEffect(() => {
		let itemsData = [];
		let taxData = [];
		let taxDetails = [];
		if (data?.singleGroupData?.items?.length > 0) {
			for (let item of data?.singleGroupData?.items) {
				let orderItemExtras = [];
				if (item?.order_item_extras?.length > 0) {
					orderItemExtras = item?.order_item_extras?.map(
						(extraItem) => {
							return {
								name: extraItem?.product_extra?.extraItem,
								price: extraItem?.price
							};
						}
					);
				}
				let orderProductVariantTypes = [];
				if (item?.order_product_variant_types?.length > 0) {
					orderProductVariantTypes =
						item?.order_product_variant_types?.map((extraItem) => {
							return {
								name: extraItem?.product_variant_type
									?.order_product_variant_sub_type
									?.product_variant_sub_type?.extraItem,
								price: extraItem?.product_variant_type
									?.order_product_variant_sub_type
									?.product_variant_sub_type?.price
							};
						});
				}
				itemsData?.push({
					isRefunded: false,
					refundedQuantity: item?.refundedQuantity,
					inputRefundedQuantity: 0,
					totalQuantity: item?.quantity,
					itemName: item?.product?.name,
					price: item?.price,
					id: item?.id,
					chargeAmount: item?.chargeAmount,
					orderItemExtras: orderItemExtras,
					orderProductVariantTypes: orderProductVariantTypes
				});
			}
		}
		if (data?.data?.order_taxes?.length > 0) {
			taxData = data?.data?.order_taxes?.map((item) => {
				if (data?.data?.order_refund_taxes?.length > 0) {
					let refundTaxItem = data?.data?.order_refund_taxes?.find(
						(refundTax) => refundTax?.taxID == item?.taxID
					);
					if (refundTaxItem) {
						return {
							isRefunded: false,
							name: item?.name,
							amount: '0.00',
							percentage: item?.percentage,
							taxId: item?.taxID,
							taxAmount:
								Number(item?.amount) -
								Number(refundTaxItem?.amount)
						};
					}
				}
				return {
					isRefunded: false,
					name: item?.name,
					amount: '0.00',
					percentage: item?.percentage,
					taxId: item?.taxID,
					taxAmount: item?.amount
				};
			});
		}
		setValues({
			items: itemsData,
			taxDetails: taxData ?? [],
			promocodeAmount: data?.data?.promocode_amount ?? 0,
			promocodeDiscount: data?.data?.promocode_discount ?? 0,
			coupon: data?.data?.coupon
		});
	}, [data]);
	// handle refund method
	const handleRefund = async () => {
		let isNegativeNumber = values?.items?.find(
			(item) => Number(item?.inputRefundedQuantity) < 0
		);
		let isDecimalNumber = values?.items?.find(
			(item) =>
				Number?.isInteger(Number(item?.inputRefundedQuantity)) === false
		);
		let isMoreRefundedQuantity = values?.items?.find(
			(item) => item?.inputRefundedQuantity > item?.totalQuantity
		);
		if (
			!isNegativeNumber &&
			!isDecimalNumber &&
			!isMoreRefundedQuantity &&
			!isError
		) {
			let order_items = [];
			let tax_data = [];
			let isOneItemSelected = values?.items?.find(
				(item) => item?.isRefunded === true
			);
			let isOneTaxSelected = values?.taxDetails?.find(
				(item) => item?.isRefunded === true
			);
			if (isOneItemSelected && isOneTaxSelected) {
				for (let item of values?.items) {
					if (item?.isRefunded) {
						order_items?.push({
							id: item?.id,
							quantity: Number(item?.inputRefundedQuantity)
						});
					}
				}
				for (let item of values?.taxDetails) {
					if (item?.isRefunded) {
						tax_data?.push({
							amount: Number(item?.amount)?.toFixed(2),
							tax: item?.taxId
						});
					}
				}
			} else if (isOneItemSelected && !isOneTaxSelected) {
				for (let item of values?.items) {
					if (item?.isRefunded) {
						order_items?.push({
							id: item?.id,
							quantity: Number(item?.inputRefundedQuantity)
						});
					}
				}
			} else if (!isOneItemSelected && isOneTaxSelected) {
				for (let item of values?.taxDetails) {
					if (item?.isRefunded) {
						tax_data?.push({
							amount: Number(item?.taxAmount)?.toFixed(2),
							tax: item?.taxId
						});
					}
				}
			} else {
				for (let item of values?.items) {
					if (item?.isRefunded) {
						order_items?.push({
							id: item?.id,
							quantity: Number(item?.inputRefundedQuantity)
						});
					}
				}
				for (let item of values?.taxDetails) {
					if (item?.isRefunded) {
						tax_data?.push({
							amount: Number(item?.amount)?.toFixed(2),
							tax: item?.taxId
						});
					}
				}
			}
			let payload = {
				bar_id: authDetails?.selectedVenue?.id,
				id: data?.data?.id,
				order_items: order_items,
				tax_data: tax_data
			};

			if (!refundableTransactionFee && transactionsFees === true) {
				payload.refundTransactionFee = 'Yes';
			}
			try {
				setRefundLoading(true);
				const res = await Api(
					'POST',
					VenueApiRoutes.cancelOrder,
					payload
				);
				if (res?.data?.status) {
					toast.success(res?.data?.message);
					handleModal();
					await getVenueOrderList();
				} else {
					toast.error(res?.data?.message);
				}
				setRefundLoading(false);
			} catch (err) {
				setRefundLoading(false);
				if (err?.response?.data?.message) {
					toast.error(err?.response?.data?.message);
				}
			}
		}
	};
	useEffect(() => {
		let amount = 0;
		for (let item of values?.items) {
			if (
				item?.isRefunded &&
				item?.inputRefundedQuantity <= item?.totalQuantity
			) {
				if (values?.promocodeDiscount > 0) {
					let discountedAmount =
						(Number(values?.promocodeDiscount) / 100) *
						Number(item?.chargeAmount);
					amount =
						amount +
						Number(item?.chargeAmount - discountedAmount) *
							Number(item?.inputRefundedQuantity);
				} else {
					amount =
						amount +
						Number(item?.chargeAmount) *
							Number(item?.inputRefundedQuantity);
				}
			}
		}
		for (
			let outerIndex = 0;
			outerIndex < values?.taxDetails?.length;
			outerIndex++
		) {
			let taxAmount = 0;
			for (let item of values?.items) {
				if (
					item?.isRefunded &&
					item?.inputRefundedQuantity <= item?.totalQuantity
				) {
					let itemAmount =
						Number(item?.chargeAmount) *
						Number(item?.inputRefundedQuantity);
					let itemTaxAmount =
						(itemAmount *
							Number(
								values?.taxDetails?.[outerIndex]?.percentage
							)) /
						100;
					taxAmount = taxAmount + itemTaxAmount;
				}
			}
			if (Number(taxAmount) === 0) {
				taxAmount = Number(values?.taxDetails[outerIndex]?.taxAmount);
			}
			setFieldValue(
				`taxDetails.${outerIndex}.amount`,
				Number(taxAmount)?.toFixed(2)
			);
		}
		for (let item of values?.taxDetails) {
			if (item?.isRefunded) {
				amount = amount + Number(item?.amount);
			}
		}
		if (!refundableTransactionFee) {
			const neg = amount - Number(data?.data?.transactionFee);

			if (transactionsFees) {
				amount = amount + Number(data?.data?.transactionFee);
			} else if (transactionsFees === false && !checkedDisable) {
				if (!(Math.sign(neg) === -1)) {
					amount =
						amount -
						Number(data?.data?.transactionFee) +
						Number(data?.data?.transactionFee);
				}
			}
		}
		setRefundedAmount(amount?.toFixed(2)?.toString());
		let isOneItemSelected = values?.items?.find(
			(item) => item?.isRefunded === true
		);
		let isOneTaxSelected = values?.taxDetails?.find(
			(item) => item?.isRefunded === true
		);
		if (!isOneItemSelected && !isOneTaxSelected) {
			setIsError(true);
		} else if (isOneItemSelected && !isOneTaxSelected) {
			setIsError(false);
		} else if (!isOneItemSelected && isOneTaxSelected) {
			setIsError(false);
		} else {
			setIsError(false);
		}
	}, [values, transactionsFees]);

	return (
		<CustomModal
			title="Refund"
			size="lg"
			isOpen={isOpen}
			handleModal={handleModal}
			autoHeightMin={270}
			// autoHeightMax={520}
		>
			{loading ? (
				<RefundInitiateModalSkeleton />
			) : (
				<>
					{data?.data?.refundStatus === 'Refunded' ? (
						<div className="pa-t-95">
							<EmptyData content="Items Already Refunded" />
						</div>
					) : (
						<div className="orderHistoryModals">
							<form
								onSubmit={(e) => e.preventDefault()}
								autoComplete="off"
							>
								<div className="orderCard d-flex flex-column ptb-18">
									<div className="orderCardHeader d-flex plr-18">
										<div className="d-flex align-items-center gap-3 flex-1">
											<div className="flex-1 d-flex flex-column flex-sm-row justify-content-between align-items-center gap-2">
												<div>
													<p className="fs-12 medium-text themeText">
														{data?.data
															?.orderServiceType ===
														'PICKUP'
															? data?.data
																	?.pickupCode
															: `Table ${data?.data?.orderTableNumbers[0]?.tableCode}`}
													</p>
													<p className="fs-12 regular-text themeText pa-t-8">
														{moment(
															data?.data
																?.orderDate
														).format('DD/MM/YYYY')}
													</p>
												</div>
												<p className="fs-12 medium-text themeText">
													Order #{data?.data?.orderNo}
												</p>
											</div>
										</div>
									</div>
									<hr className="themeHr mtb-15" />
									<div className="orderCardBody plr-18">
										<div className="d-flex flex-column">
											<div className="d-flex justify-content-between pa-b-2">
												<p className="fs-12 medium-text themeText">
													Item(s)
												</p>
												<p className="fs-12 medium-text themeText">
													Qty
												</p>
											</div>
											{values?.items?.length > 0 &&
												values?.items?.map(
													(item, index) => {
														return (
															<Fragment
																key={index}
															>
																<div className="d-flex justify-content-between align-items-center gap-1 pa-t-8">
																	<div className="d-flex flex-1 align-items-center">
																		<p className="fs-12 medium-text qtyBox d-flex align-items-center justify-content-center">
																			{
																				item?.totalQuantity
																			}
																		</p>
																		<p className="fs-12 medium-text themeText pl-18">
																			{item?.itemName +
																				' ' +
																				`($${Number(
																					item?.price
																				)?.toFixed(
																					2
																				)})`}
																		</p>
																	</div>
																	<div className="d-flex flex-1 align-items-center justify-content-end">
																		<FormCheckBox
																			name={`items.${index}.isRefunded`}
																			checked={
																				values
																					?.items[
																					index
																				]
																					?.isRefunded ||
																				values
																					?.items[
																					index
																				]
																					?.refundedQuantity ==
																					values
																						?.items[
																						index
																					]
																						?.totalQuantity
																			}
																			onChange={
																				handleChange
																			}
																			disabled={
																				values
																					?.items[
																					index
																				]
																					?.inputRefundedQuantity ==
																					0 ||
																				values
																					?.items[
																					index
																				]
																					?.refundedQuantity ==
																					values
																						?.items[
																						index
																					]
																						?.totalQuantity
																			}
																		/>
																		<div className="plr-14">
																			<FormInput
																				type="number"
																				className="w-px-46 text-center"
																				minHeight="20px"
																				name={`items.${index}.inputRefundedQuantity`}
																				value={
																					values
																						?.items?.[
																						index
																					]
																						?.inputRefundedQuantity
																				}
																				onChange={
																					handleChange
																				}
																				disabled={
																					values
																						?.items[
																						index
																					]
																						?.refundedQuantity ==
																					values
																						?.items[
																						index
																					]
																						?.totalQuantity
																				}
																				min={
																					0
																				}
																				step={
																					0
																				}
																			/>
																		</div>
																		<p className="fs-12 regular-text themeText">
																			/
																			&nbsp;{' '}
																			{Number(
																				item?.totalQuantity
																			) -
																				Number(
																					item?.refundedQuantity
																				)}
																		</p>
																	</div>
																</div>
																<div>
																	{item
																		?.orderProductVariantTypes
																		?.length >
																		0 &&
																		item?.orderProductVariantTypes?.map(
																			(
																				extraItem,
																				index
																			) => (
																				<div
																					key={
																						index
																					}
																					className="d-flex flex-1 align-items-center"
																				>
																					<p className="fs-12 medium-text qtyBox d-flex align-items-center justify-content-center invisible">
																						1
																					</p>
																					<p className="fs-12 medium-text d-flex align-items-center opacity-50 pl-18">
																						{extraItem?.name +
																							' ' +
																							`($${Number(
																								extraItem?.price
																							)?.toFixed(
																								2
																							)})`}
																					</p>
																				</div>
																			)
																		)}
																</div>
																<div>
																	{item
																		?.orderItemExtras
																		?.length >
																		0 &&
																		item?.orderItemExtras?.map(
																			(
																				extraItem,
																				index
																			) => (
																				<div
																					key={
																						index
																					}
																					className="d-flex flex-1 align-items-center"
																				>
																					<p className="fs-12 medium-text qtyBox d-flex align-items-center justify-content-center invisible">
																						1
																					</p>
																					<p className="fs-12 medium-text d-flex align-items-center opacity-50 pl-18">
																						{extraItem?.name +
																							' ' +
																							`($${Number(
																								extraItem?.price
																							)?.toFixed(
																								2
																							)})`}
																					</p>
																				</div>
																			)
																		)}
																</div>

																{checkRefundInputError(
																	item
																) ? (
																	<p className="fs-10 semi-bold-text headingTextColor d-flex justify-content-end">
																		{checkRefundInputError(
																			item
																		)}
																	</p>
																) : (
																	''
																)}
															</Fragment>
														);
													}
												)}
										</div>
									</div>
									<hr className="themeHr mtb-15" />
									{values?.taxDetails?.length > 0 && (
										<>
											<div className="orderCardPickup plr-18">
												<p className="fs-12 medium-text themeText pa-b-4">
													Tax Details
												</p>
												{values?.taxDetails?.length >
													0 &&
													values?.taxDetails?.map(
														(item, index) => {
															return (
																<div
																	key={index}
																	className="d-flex align-items-center justify-content-between pa-t-8"
																>
																	<div className="d-flex align-items-center">
																		<FormCheckBox
																			name={`taxDetails.${index}.isRefunded`}
																			checked={
																				values
																					?.taxDetails[
																					index
																				]
																					?.isRefunded ||
																				Number(
																					item?.taxAmount
																				)?.toFixed(
																					2
																				) ==
																					0.0
																			}
																			onChange={
																				handleChange
																			}
																			disabled={
																				Number(
																					item?.taxAmount
																				)?.toFixed(
																					2
																				) ==
																				0.0
																			}
																		/>
																		<p className="fs-12 medium-text themeText pl-10">
																			{
																				item?.name
																			}
																		</p>
																	</div>
																	<div>
																		<p className="fs-12 medium-text themeText pl-10">
																			${' '}
																			{Number(
																				item?.taxAmount
																			)
																				?.toFixed(
																					2
																				)
																				?.toString()}
																		</p>
																	</div>
																</div>
															);
														}
													)}
											</div>
											<hr className="themeHr mtb-15" />
										</>
									)}
									{values?.coupon && (
										<>
											<div className="orderCardTotal plr-18">
												<div className="d-flex justify-content-between gap-2">
													<div className="d-flex flex-row align-items-center gap-1">
														<p className="fs-12 regular-text themeText">
															{
																values?.coupon
																	?.code
															}
														</p>
													</div>
													<div>
														<p className="fs-12 medium-text themeText">
															- ${' '}
															{Number(
																values?.promocodeAmount
															)?.toFixed(2)}
														</p>
													</div>
												</div>
											</div>
											<hr className="themeHr mtb-15" />
										</>
									)}
									<div className="orderCardTotal plr-18">
										<div className="d-flex align-items-center justify-content-between ">
											<div className="d-flex align-items-center">
												<FormCheckBox
													name={`my-tab-trans`}
													checked={transactionsFees}
													disabled={checkedDisable}
													onChange={(e) => {
														if (
															checkedDisable ===
															false
														) {
															setTransactionsFeeChecked(
																e.target.checked
															);
														}
													}}
												/>
												<p className="fs-12 medium-text themeText pl-10">
													MyTab Transaction fee
												</p>
											</div>
											<div>
												<p className="fs-12 medium-text themeText pl-10">
													$
													{refundableTransactionFee
														? `0.00`
														: data?.data
																?.transactionFee}
												</p>
											</div>
										</div>
									</div>
									<hr className="themeHr mtb-15" />
									<div className="orderCardTotal plr-18">
										<div className="d-flex justify-content-between gap-2">
											<div className="d-flex flex-row align-items-center gap-1">
												<p className="fs-12 regular-text themeText">
													Refunded Amount
												</p>
											</div>
											<div>
												<p className="fs-15 medium-text themeText">
													$ {refundedAmount}
												</p>
											</div>
										</div>
									</div>
								</div>
								{isError && (
									<p className="fs-10 semi-bold-text headingTextColor pa-t-6">
										Please select atleast one item and/or
										tax for refund.
									</p>
								)}
								<div className="pa-t-18">
									<CustomButton
										type="button"
										className="themeButtonFullWidth"
										onClick={handleRefund}
										loading={refundLoading}
									>
										Refund
									</CustomButton>
								</div>
								<div className="pa-t-18 pa-b-8">
									<Button
										type="button"
										className="borderButtonFullWidth"
										onClick={handleModal}
									>
										Cancel
									</Button>
								</div>
							</form>
						</div>
					)}
				</>
			)}
		</CustomModal>
	);
};

export default RefundModal;
