import { useState } from 'react';
import { Button } from 'reactstrap';
import CustomModal from '../../Common/Modal/CustomModal';
import GoogleMap from '../../Common/GoogleMap';

const MapModal = ({ isOpen, handleModal, setFieldValue }) => {
	const [selectedLocation, setSelectedLocation] = useState(null);
	const handleDone = () => {
		setFieldValue('latitude', selectedLocation?.lat);
		setFieldValue('longitude', selectedLocation?.lng);
		setFieldValue('address', selectedLocation?.description);
		handleModal();
	};
	return (
		<CustomModal isOpen={isOpen} size="lg" handleModal={handleModal}>
			<div>
				<GoogleMap
					getSelectedLocation={(value) => {
						setSelectedLocation(value);
					}}
				/>
			</div>
			<div className="pa-t-18">
				<Button
					type="button"
					className="newThemeButtonFullWidth"
					onClick={handleDone}
				>
					Done
				</Button>
			</div>
		</CustomModal>
	);
};

export default MapModal;
