import actions from './actions';

const initState = {
	bar_id: null,
	currentProduct: null
};

export default function rootReducer(state = initState, action) {
	switch (action.type) {
		case actions.BAR_ID_ACTION:
			return {
				...state,
				bar_id: action.bar_id
			};

		case actions.SET_CURRENT_PRODUCT:
			return {
				...state,
				currentProduct: action.currentProduct
			};

		default:
			return state;
	}
}
