export const EyeIcon = ({ stroke, ...props }) => {
	return (
		// <svg {...props} viewBox="0 0 24 24" fill="none">
		// 	<path
		// 		d="M12 5.25C4.5 5.25 1.5 12 1.5 12C1.5 12 4.5 18.75 12 18.75C19.5 18.75 22.5 12 22.5 12C22.5 12 19.5 5.25 12 5.25Z"
		// 		stroke={stroke ?? '#242424'}
		// 		strokeWidth="1.4"
		// 		strokeLinecap="round"
		// 		strokeLinejoin="round"
		// 	/>
		// 	<path
		// 		d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z"
		// 		stroke={stroke ?? '#242424'}
		// 		strokeWidth="1.4"
		// 		strokeLinecap="round"
		// 		strokeLinejoin="round"
		// 	/>
		// </svg>
		<svg
			{...props}
			viewBox="0 0 21 21"
			fill="none"
			className="eye-icon-size"
		>
			<path
				d="M4.17969 3.17194L16.6797 16.9219"
				stroke="grey"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M12.5314 12.3594C11.9579 12.8848 11.2076 13.1749 10.4298 13.1719C9.79911 13.1719 9.18316 12.9809 8.66297 12.6243C8.14278 12.2676 7.74269 11.7618 7.51531 11.1735C7.28793 10.5852 7.24389 9.94184 7.38899 9.32803C7.53409 8.71421 7.86154 8.15867 8.32828 7.73444"
				stroke="grey"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M6.21094 5.40631C3.02344 7.01569 1.67969 10.0469 1.67969 10.0469C1.67969 10.0469 4.17969 15.6719 10.4297 15.6719C11.8942 15.6839 13.3404 15.3464 14.6484 14.6876"
				stroke="grey"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M16.7266 13.2579C18.4297 11.7344 19.1797 10.0469 19.1797 10.0469C19.1797 10.0469 16.6797 4.42194 10.4297 4.42194C9.88786 4.42087 9.3469 4.4653 8.8125 4.55475"
				stroke="grey"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M11.0156 6.97662C11.6802 7.10254 12.2858 7.4412 12.741 7.9415C13.1962 8.4418 13.4763 9.07659 13.5391 9.75006"
				stroke="grey"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
