import { Button } from 'reactstrap';
import CustomModal from '../../Common/Modal/CustomModal';

const DeleteConfirmModal = ({ handleModal, isOpen, handleDelete }) => {
	return (
		<CustomModal
			isOpen={isOpen}
			handleModal={handleModal}
			title="Delete Venue"
			size="md"
		>
			<div className="w-100">
				<p className="pa-b-18 fs-12 medium-text text-center">
					Are you sure you want to delete this venue?
				</p>
				<div className="d-flex" style={{ gap: '18px' }}>
					<div className="flex-1">
						<Button
							className="borderButtonFullWidth"
							onClick={handleModal}
						>
							No
						</Button>
					</div>
					<div className="flex-1">
						<Button
							type="button"
							className="themeButtonFullWidth"
							onClick={handleDelete}
						>
							Yes
						</Button>
					</div>
				</div>
			</div>
		</CustomModal>
	);
};

export default DeleteConfirmModal;
