import styled from 'styled-components';

const ModuleWrapper = styled.div`
	.react-datepicker {
		border: 1px solid #eaeaea !important;
		background-color: #fff;
		border-radius: 6px !important;
	}
	.react-datepicker__month-container {
		border: 0px !important;
		.react-datepicker__header {
			padding-top: 0px;
			border: 0px !important;
			background-color: #fff;
		}
		.react-datepicker__day-names {
			padding-top: 5px;
		}
		.react-datepicker__day-name {
			font-weight: 600;
			color: ${(props) => props.layoutTheme.buttonColor3} !important;
		}
		.react-datepicker__current-month {
			display: none;
		}
		.react-datepicker__year-dropdown-container--scroll {
			margin: 0px 25px;
		}
		.react-datepicker__month-read-view--down-arrow,
		.react-datepicker__year-read-view--down-arrow {
			top: 3px !important;
			border-color: #000 !important;
			border-width: 2px 2px 0 0 !important;
		}
		.react-datepicker__day--keyboard-selected {
			color: ${(props) => props.layoutTheme.textColor};
			background-color: #fff;
		}
		.react-datepicker__month-read-view--selected-month,
		.react-datepicker__year-read-view--selected-year,
		.react-datepicker__month-option,
		.react-datepicker__year-option {
			color: ${(props) => props.layoutTheme.textColor};
			background-color: #fff;
			font-weight: 600;
		}
		.react-datepicker__year-select,
		.react-datepicker__month-select {
			color: ${(props) => props.layoutTheme.textColor};
			background-color: #fff;
			font-weight: 600;
			padding: 5px;
			border: 0px;
			outline: none;
			padding-top: 3px;
		}
		.react-datepicker__month-read-view,
		.react-datepicker__year-read-view {
			visibility: visible !important;
		}
		.react-datepicker__month-dropdown,
		.react-datepicker__year-dropdown {
			background-color: #fff;
		}
		.react-datepicker__day--selected,
		.react-datepicker__day--in-selecting-range,
		.react-datepicker__day--in-range {
			background-color: ${(props) =>
				props.layoutTheme.buttonColor3} !important;
			color: #fff !important;
			border-radius: 50%;
		}
		.react-datepicker__day {
			color: ${(props) => props.layoutTheme.textColor};
		}
	}
	.react-datepicker__navigation-icon--next::before,
	.react-datepicker__navigation-icon--previous::before {
		border-color: #000 !important;
		top: 18px;
	}
	.react-datepicker__current-month--hasMonthDropdown {
		display: none;
	}
	.react-datepicker__time-container
		.react-datepicker__time
		.react-datepicker__time-box
		ul.react-datepicker__time-list
		li.react-datepicker__time-list-item--selected {
		background-color: #f95c69 !important;
		color: #fff !important;
		font-weight: bold;
	}
	.react-datepicker__time-container
		.react-datepicker__time
		.react-datepicker__time-box {
		width: 130px !important;
	}
	.react-datepicker__time-container
		.react-datepicker__time
		.react-datepicker__time-box
		ul.react-datepicker__time-list
		li.react-datepicker__time-list-item {
		font-family: 'poppins-regular' !important;
		color: #313132eb;
	}
	.react-datepicker__time-container
		.react-datepicker__time
		.react-datepicker__time-box
		ul.react-datepicker__time-list
		li.react-datepicker__time-list-item {
		background: #fff;
	}
	.react-datepicker__time-container .react-datepicker__time {
		position: relative;
		left: -21px !important;
		background: white;
		border-bottom-right-radius: 0.3rem;
	}
	.react-datepicker-wrapper {
		.react-datepicker__input-container {
			input {
				text-align: center;
				height: 53px;
				width: 100% !important;
				background: white !important;
				color: #313132eb !important;
				border: 2px solid #31313259 !important;
				font-family: 'poppins-regular' !important;
				border-radius: 0px !important;
				font-size: 24px !important;
				::placeholder {
					/* Chrome, Firefox, Opera, Safari 10.1+ */
					color: ${(props) =>
						props.layoutTheme.placeHolderColor} !important;
					font-family: 'montserrat-medium' !important;
					font-weight: 500 !important;
					font-size: 12px !important;
					opacity: 1; /* Firefox */
				}
				&:focus {
					outline: none;
					box-shadow: none !important;
					border: 2px solid #31313259 !important;
				}
				@media (max-width: 600px) {
					font-size: 11px !important;
					height: 40px;
				}
				@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
					height: 29.8px !important;
					font-size: 13.5px !important;
					width: 192px;
				}
			}
		}
	}
	.react-datepicker__header--time {
		display: none;
	}
	.react-datepicker__triangle {
		display: none;
	}
	.react-datepicker-popper {
		padding-top: 0px;
	}
	.react-datepicker__time-list {
		/* Customize the scrollbar */
		scrollbar-width: thin; /* For Firefox */
		scrollbar-color: rgba(0, 0, 0, 0.2) #fff; /* For Firefox */
		/* Customize the scrollbar for Webkit browsers (Chrome, Safari, etc.) */
		::-webkit-scrollbar {
			width: 2px;
		}
		::-webkit-scrollbar-thumb {
			background-color: rgba(0, 0, 0, 0.2);
			border-radius: 3px !important;
		}
		::-webkit-scrollbar-track {
			background-color: #fff;
			border-radius: 18px !important;
		}
	}
`;

export default ModuleWrapper;
