import styled from 'styled-components';

const FooterWrapper = styled.div`
	.footerBack {
		background: ${(props) => props.footerTheme.backgroundColor};
		color: ${(props) => props.footerTheme.textColor};
		padding: 0px;
		height: 51px;
		width: 100%;
		/* margin-left: -${(props) =>
			props.themeSetting.footerAlignValue === 'above' && !props.mini
				? props.drawerWidth
				: props.themeSetting.footerAlignValue === 'above' && props.mini
				? props.miniDrawerWidth === props.drawerWidth
					? '270px'
					: '80px'
				: '80px'}; */
		@media only screen and (max-width: 767.98px) {
			margin-left: 0px !important;
		}
		.pipe {
			padding: 0px 5px;
		}
		.container {
			@media only screen and (max-width: 575.98px) {
				padding: 0px;
				flex-direction: column;
			}
			.list-inline {
				@media only screen and (max-width: 575.98px) {
					text-align: center;
				}
				@media only screen and (max-width: 320px) {
					text-align: center;
				}
			}
		}
	}
`;

export default FooterWrapper;
