import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import CustomModal from '../../Common/Modal/CustomModal';
import Api from '../../../Helper/Api';
import { VenueApiRoutes } from '../../../Utils/routes';
import CustomButton from '../../Common/CustomButton';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { HamburgerIcon } from '../../Icons';
import ModalWrapper from './index.style';

const RearrangeMenuModal2 = ({
	isOpen,
	handleModal,
	tabMenuData,
	setMenuItems,
	setTabId,
	activeTabMenu,
	isPopularExist,
	refetch
}) => {
	const authData = useSelector((state) => ({ ...state.auth }));
	const [updateLoading, setUpdateLoading] = useState(false);
	const [menuData, setMenuData] = useState([]);
	const [items, setItems] = useState([]);

	const handleUpdate = async () => {
		let payload = {};
		let subCategoryIDs = [];
		if (isPopularExist) {
			if (items && items?.length !== 0) {
				subCategoryIDs = [
					menuData[0].categoryID,
					...items?.map((item) => item?.data?.categoryID)
				];
				[
					menuData[0].categoryName,
					...items?.map((item) => item?.data?.categoryName)
				].forEach((item, index) => {
					if (activeTabMenu === item) {
						setTabId(index);
					}
				});
			}
		} else {
			if (items && items?.length !== 0) {
				subCategoryIDs = [
					...items?.map((item) => item?.data?.categoryID)
				];
				items.forEach((item, index) => {
					if (activeTabMenu === item?.data?.categoryName) {
						setTabId(index);
					}
				});
			}
		}
		payload = {
			bar_id: authData?.selectedVenue?.id,
			sub_category_ids: subCategoryIDs
		};
		setUpdateLoading(true);
		try {
			const res = await Api(
				'POST',
				VenueApiRoutes.updateSubcategorySequence,
				payload
			);
			if (res?.data?.status) {
				if (refetch) refetch();
				handleModal();
			} else {
				toast.error(res?.data?.message);
			}
			setUpdateLoading(false);
		} catch (err) {
			setUpdateLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	const modalFooter = (
		<div className="w-100 pa-t-18 pr-8">
			<CustomButton
				type="button"
				className="themeButtonFullWidth"
				onClick={handleUpdate}
				loading={updateLoading}
			>
				Update
			</CustomButton>
		</div>
	);
	useEffect(() => {
		setMenuData(tabMenuData);
		if (isPopularExist) {
			setItems(
				tabMenuData?.slice(1)?.map((item, index) => {
					return { id: index + 1, data: item };
				})
			);
		} else {
			setItems(
				tabMenuData?.map((item, index) => {
					return { id: index + 1, data: item };
				})
			);
		}
	}, [tabMenuData, isPopularExist]);
	const onModalClosed = () => {
		if (isPopularExist) {
			setItems(
				tabMenuData?.slice(1)?.map((item, index) => {
					return { id: index + 1, data: item };
				})
			);
		} else {
			setItems(
				tabMenuData?.map((item, index) => {
					return { id: index + 1, data: item };
				})
			);
		}
	};
	return (
		<CustomModal
			title={'Re-arrange Menu'}
			size="md"
			isOpen={isOpen}
			handleModal={handleModal}
			modalFooter={modalFooter}
			onClosed={onModalClosed}
		>
			<ModalWrapper>
				<DragDropContext
					onDragEnd={(param) => {
						const srcI = param?.source?.index;
						const desI = param?.destination?.index;
						if (desI || desI == 0) {
							items?.splice(desI, 0, items?.splice(srcI, 1)[0]);
							setItems([...items]);
						}
					}}
				>
					<Droppable droppableId="droppable-1">
						{(provided, _) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
							>
								{items?.length > 0 &&
									items?.map((item, i) => (
										<Draggable
											key={item?.id}
											draggableId={
												'draggable-' + item?.id
											}
											index={i}
										>
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													style={{
														...provided
															.draggableProps
															.style,
														boxShadow:
															snapshot.isDragging
																? '0px 4px 12px rgba(0, 0, 0, 0.08)'
																: 'none'
													}}
													className={`d-flex draggableMenuCard justify-content-between align-items-center gap-2 pa-8 ${
														i !== items?.length - 1
															? 'ma-b-12'
															: ''
													}`}
												>
													<div className="fs-14 regular-text">
														{
															item?.data
																?.categoryName
														}
													</div>
													<div
														{...provided.dragHandleProps}
													>
														<HamburgerIcon
															height={18}
															width={18}
														/>
													</div>
												</div>
											)}
										</Draggable>
									))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</ModalWrapper>
		</CustomModal>
	);
};

export default RearrangeMenuModal2;
