import React from 'react';
import Skeleton from 'react-loading-skeleton';

const TabsSkeleton = () => {
	return (
		<div className="d-flex gap-4">
			{Array(9)
				.fill('1')
				.map((_, index) => {
					return (
						<Skeleton
							height="18px"
							width="112px"
							borderRadius={4}
							key={index}
						/>
					);
				})}
		</div>
	);
};

export default TabsSkeleton;
