import React from 'react';
import ReactPaginate from 'react-paginate';

export const Pagination = ({
	total,
	pageSize = 10,
	currentPage,
	handlePageChange,
	...rest
}) => {
	return (
		<div className="d-flex justify-content-center customPagination">
			<ReactPaginate
				previousLabel={'< Prev'}
				nextLabel={' Next >'}
				pageClassName="page-item"
				pageLinkClassName="page-link"
				previousClassName="page-item"
				previousLinkClassName="page-link"
				nextClassName="page-item"
				nextLinkClassName="page-link"
				breakLabel="..."
				breakClassName="page-break"
				breakLinkClassName="page-link"
				pageCount={Math.ceil(total / pageSize)}
				marginPagesDisplayed={1}
				pageRangeDisplayed={2}
				onPageChange={handlePageChange}
				containerClassName="pagination"
				activeClassName="active"
				renderOnZeroPageCount={null}
				forcePage={currentPage - 1}
			/>
		</div>
	);
};
