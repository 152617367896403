import styled from 'styled-components';

const PopoverWrapper = styled.div`
	background-color: #fff !important;
	border: 1px solid #eaeaea !important;
	border-radius: 4px !important;
	.themeText {
		color: ${(props) => props.layoutTheme.textColor} !important;
	}
	.activeColor {
		color: #ff5f5f !important;
	}
`;

export default PopoverWrapper;
