import { Login, Register, ForgotPassword, ResetPassword } from '../Pages';
import { CommonRoutes } from '../Utils/routes';
import PageNotFound from '../Pages/PageNotFound';
import PublicRoute from '../Components/Common/RouteWrappers/PublicRoute';
import Landing from '../Pages/Landing';
import Setup2FA from '../Pages/Authentication/Setup2FA';
import VenueProtectedLayout from '../Layouts/VenueProtectedLayout';

const routes = [
	{
		path: CommonRoutes.landingPage,
		component: (
			<PublicRoute>
				<VenueProtectedLayout forPublic={true} />
				<Landing />
			</PublicRoute>
		)
	},
	{
		path: CommonRoutes.login,
		component: (
			<PublicRoute>
				<VenueProtectedLayout forPublic />
				<Login type="venue" />
			</PublicRoute>
		)
	},
	{
		path: CommonRoutes.forgotPassword,
		component: (
			<PublicRoute>
				<VenueProtectedLayout forPublic />
				<ForgotPassword type="venue" />
			</PublicRoute>
		)
	},
	{
		path: CommonRoutes.resetPassword,
		component: (
			<PublicRoute>
				<VenueProtectedLayout forPublic />
				<ResetPassword type="venue" />
			</PublicRoute>
		)
	},
	{
		path: CommonRoutes.register,
		component: (
			<PublicRoute>
				<VenueProtectedLayout forPublic />
				<Register type="venue" />
			</PublicRoute>
		)
	},
	{
		path: CommonRoutes.setUp2Fa,
		component: (
			<PublicRoute>
				<VenueProtectedLayout forPublic />
				<Setup2FA />
			</PublicRoute>
		)
	},
	{ path: CommonRoutes.pageNotFound, component: <PageNotFound /> }
];

export default routes;
