import styled from 'styled-components';

const PageWrapper = styled.div`
	color: #242424 !important; //#4f4f4f
	.borderButton {
		border: 1px solid ${(props) => props.layoutTheme.buttonColor2} !important;
		color: ${(props) => props.layoutTheme.buttonColor2} !important;
		background-color: #fff !important;
		margin-top: 10px;
		padding-top: 10px;
		padding-bottom: 10px;
	}
`;

export default PageWrapper;
