import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import ModuleWrapper from './customDateRangePicker.style';
import { range } from 'd3-array';
import { getMonth, getYear } from 'date-fns';
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle
} from 'reactstrap';
import { useState } from 'react';
import {
	DownArrowIcon,
	LeftArrowIcon,
	RightArrowIcon,
	UpArrowIcon
} from '../../Icons';
import useDevice from '../../../Hooks/useDevice';
import Scrollbars from 'react-custom-scrollbars';

const CustomDateRangePicker = ({ dateRange, handleDateRange }) => {
	const [startDate, endDate] = dateRange;
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	const [monthDropdown, setMonthDropdown] = useState(false);
	const [yearDropdown, setYearDropdown] = useState(false);
	const { isTablet } = useDevice();
	const years = range(2020, getYear(new Date()) + 1, 1).reverse();
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	];
	return (
		<ModuleWrapper {...allThemeData}>
			<DatePicker
				selected={startDate}
				onChange={handleDateRange}
				startDate={startDate}
				endDate={endDate}
				selectsRange
				// showMonthDropdown
				// showYearDropdown
				maxDate={new Date()}
				dropdownMode="select"
				inline
				renderCustomHeader={({
					date,
					changeYear,
					changeMonth,
					decreaseMonth,
					increaseMonth,
					prevMonthButtonDisabled,
					nextMonthButtonDisabled
				}) => (
					<div className="d-flex align-items-center">
						<span
							onClick={decreaseMonth}
							className={`cursor-pointer ${
								prevMonthButtonDisabled
									? 'invisible'
									: 'visible'
							} ml-12`}
						>
							<LeftArrowIcon
								width={16}
								height={16}
								color="#ff5f5f"
							/>
						</span>
						<div className="flex-1 d-flex justify-content-center gap-2">
							{/* Year Dropdown */}
							<Dropdown
								isOpen={yearDropdown}
								toggle={() => setYearDropdown((prev) => !prev)}
								direction="down"
								className="dashboard-dropdown yearDropdown"
							>
								<DropdownToggle
									color="#fff"
									className="ptb-0 yearDropdownToggle d-flex justify-content-center w-100 align-items-center"
								>
									<span className={`fs-11 medium-text`}>
										{getYear(date)}
									</span>
									<span className="pl-6">
										{yearDropdown ? (
											<UpArrowIcon height={8} width={8} />
										) : (
											<DownArrowIcon
												height={8}
												width={8}
											/>
										)}
									</span>
								</DropdownToggle>
								<DropdownMenu
									className={`datePickerDropdown ${
										isTablet ? 'w-100' : ''
									} monthMenu yearDropdownMenu`}
									end={isTablet}
								>
									{years?.length > 0 &&
										years?.map((year, index) => {
											return (
												<DropdownItem
													key={index}
													className={`${
														getYear(date) === year
															? 'active'
															: ''
													} fs-11 regular-text monthMenuItem`}
													onClick={() =>
														changeYear(year)
													}
												>
													{year}
												</DropdownItem>
											);
										})}
								</DropdownMenu>
							</Dropdown>
							{/* Month Dropdown */}
							<Dropdown
								isOpen={monthDropdown}
								toggle={() => setMonthDropdown((prev) => !prev)}
								direction="down"
								className="dashboard-dropdown monthDropdown"
							>
								<DropdownToggle
									color="#fff"
									className="ptb-0 monthDropdownToggle d-flex justify-content-between w-100 align-items-center monthLabel"
								>
									<span className={`fs-11 medium-text`}>
										{months[getMonth(date)]}
									</span>
									<span className="pl-6">
										{monthDropdown ? (
											<UpArrowIcon height={8} width={8} />
										) : (
											<DownArrowIcon
												height={8}
												width={8}
											/>
										)}
									</span>
								</DropdownToggle>
								<DropdownMenu
									className={`datePickerDropdown ${
										isTablet ? 'w-100' : ''
									} monthMenu`}
									end={isTablet}
								>
									{months?.length > 0 &&
										months?.map((month, index) => {
											return (
												<DropdownItem
													key={index}
													className={`${
														months[
															getMonth(date)
														] === month
															? 'active'
															: ''
													} fs-11 regular-text monthMenuItem`}
													onClick={() => {
														changeMonth(
															months.indexOf(
																month
															)
														);
													}}
												>
													{month}
												</DropdownItem>
											);
										})}
								</DropdownMenu>
							</Dropdown>
						</div>
						<span
							onClick={increaseMonth}
							className={`cursor-pointer ${
								nextMonthButtonDisabled
									? 'invisible'
									: 'visible'
							} mr-12`}
						>
							<RightArrowIcon
								width={16}
								height={16}
								color="#ff5f5f"
							/>
						</span>
					</div>
				)}
			/>
		</ModuleWrapper>
	);
};

export default CustomDateRangePicker;
