export const EyeSlashIcon = ({ stroke, ...props }) => {
	return (
		// <svg {...props} viewBox="0 0 32 32" fill="none">
		// 	<path
		// 		d="M6 5L26 27"
		// 		stroke={stroke ?? '#242424'}
		// 		strokeWidth="1.5"
		// 		strokeLinecap="round"
		// 		strokeLinejoin="round"
		// 	/>
		// 	<path
		// 		d="M19.3629 19.6998C18.4454 20.5404 17.2448 21.0045 16.0004 20.9998C14.9913 20.9997 14.0058 20.6942 13.1734 20.1235C12.3411 19.5528 11.701 18.7436 11.3372 17.8023C10.9734 16.861 10.9029 15.8317 11.1351 14.8495C11.3672 13.8674 11.8912 12.9786 12.6379 12.2998"
		// 		stroke={stroke ?? '#242424'}
		// 		strokeWidth="1.5"
		// 		strokeLinecap="round"
		// 		strokeLinejoin="round"
		// 	/>
		// 	<path
		// 		d="M9.25 8.5752C4.15 11.1502 2 16.0002 2 16.0002C2 16.0002 6 25.0002 16 25.0002C18.3432 25.0193 20.6572 24.4794 22.75 23.4252"
		// 		stroke={stroke ?? '#242424'}
		// 		strokeWidth="1.5"
		// 		strokeLinecap="round"
		// 		strokeLinejoin="round"
		// 	/>
		// 	<path
		// 		d="M26.0746 21.1375C28.7996 18.7 29.9996 16 29.9996 16C29.9996 16 25.9996 7.00003 15.9996 7.00003C15.1327 6.99832 14.2671 7.0694 13.4121 7.21253"
		// 		stroke={stroke ?? '#242424'}
		// 		strokeWidth="1.5"
		// 		strokeLinecap="round"
		// 		strokeLinejoin="round"
		// 	/>
		// 	<path
		// 		d="M16.9375 11.0874C18.0008 11.2889 18.9697 11.8307 19.6981 12.6312C20.4264 13.4317 20.8746 14.4473 20.975 15.5249"
		// 		stroke={stroke ?? '#242424'}
		// 		strokeWidth="1.5"
		// 		strokeLinecap="round"
		// 		strokeLinejoin="round"
		// 	/>
		// </svg>
		<svg
			{...props}
			viewBox="0 0 21 21"
			fill="none"
			className="eye-icon-size"
		>
			<path
				d="M10.4297 4.42194C4.17969 4.42194 1.67969 10.0469 1.67969 10.0469C1.67969 10.0469 4.17969 15.6719 10.4297 15.6719C16.6797 15.6719 19.1797 10.0469 19.1797 10.0469C19.1797 10.0469 16.6797 4.42194 10.4297 4.42194Z"
				stroke="grey"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10.4297 13.1719C12.1556 13.1719 13.5547 11.7728 13.5547 10.0469C13.5547 8.32105 12.1556 6.92194 10.4297 6.92194C8.7038 6.92194 7.30469 8.32105 7.30469 10.0469C7.30469 11.7728 8.7038 13.1719 10.4297 13.1719Z"
				stroke="grey"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
