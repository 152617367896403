import { useEffect, useState } from 'react';
import moment from 'moment';

import CustomTimePicker from '../../Common/DateRangePicker/CustomTimePicker';
import Switch from '../../Common/Switch';

const OpeningHoursCard = ({
	weekDay,
	activeHours,
	inActiveHours,
	isClosed,
	id,
	handleModalData
}) => {
	let today = new Date();
	let date =
		today.getFullYear() +
		'/' +
		parseInt(today.getMonth() + 1) +
		'/' +
		today.getDate();

	const [updateButton, setUpdateButton] = useState(false);
	const [data, setData] = useState({
		id: id,
		weekDay: weekDay,
		activeHours:
			activeHours !== null ? new Date(date + ' ' + activeHours) : null,
		inActiveHours:
			inActiveHours !== null
				? new Date(date + ' ' + inActiveHours)
				: null,
		isClosed: isClosed
	});
	const handleStartDateChange = (date) => {
		setUpdateButton(true);
		setData((prevData) => ({
			...prevData,
			activeHours: date
		}));
	};
	const handleEndDateChange = (date) => {
		setUpdateButton(true);
		setData((prevData) => ({
			...prevData,
			inActiveHours: date
		}));
	};
	const handleSwitchChange = (event) => {
		setUpdateButton(true);
		setData((prevData) => ({
			...prevData,
			isClosed: !prevData.isClosed
		}));
	};
	useEffect(() => {
		const updatedData = {
			id: data?.id,
			weekDay: data?.weekDay,
			activeHours: moment(data?.activeHours).format('HH:mm'),
			inActiveHours: moment(data?.inActiveHours).format('HH:mm'),
			isClosed: data?.isClosed
		};
		handleModalData(updatedData);
	}, [data]);
	return (
		<>
			<div className="fs-12 medium-text d-flex align-items-center">
				{moment()
					.day(weekDay + 1)
					.format('dddd')}
			</div>
			<div>
				<CustomTimePicker
					date={data?.activeHours}
					handleDateChange={handleStartDateChange}
					customClassName=""
					timeIntervals={30}
				/>
			</div>
			<div>
				<CustomTimePicker
					date={data?.inActiveHours}
					handleDateChange={handleEndDateChange}
					customClassName="placeholder-center w-100"
					timeIntervals={30}
				/>
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<Switch
					checked={!data?.isClosed}
					onChange={handleSwitchChange}
				/>
			</div>
		</>
	);
};

export default OpeningHoursCard;
