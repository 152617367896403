import { useSelector } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import { Modal, ModalBody } from 'reactstrap';

import ModalWrapper from './modal.style';
import { CancelIcon } from '../../Icons';

const CustomModal = ({
	isOpen,
	handleModal,
	title,
	autoHeightMin,
	autoHeightMax,
	children,
	modalClassName,
	modalFooter,
	hideCloseButton,
	forMap,
	onClosed,
	titleTextSize = 24,
	...rest
}) => {
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));

	return (
		<Modal
			autoFocus={false}
			isOpen={isOpen}
			toggle={() => handleModal(!isOpen)}
			className={`contentModal ${modalClassName ?? ''}`}
			// scrollable={true}
			centered={true}
			{...rest}
			id="CustomModal"
			backdrop="static"
			onClosed={onClosed}
		>
			<ModalBody
				className="overflow-auto plr-36 pa-b-36 pa-t-30"
				id="adfdaf"
			>
				<ModalWrapper {...allThemeData}>
					{!hideCloseButton && (
						<div
							className="cancleIconWrapper"
							onClick={() => handleModal(!isOpen)}
						>
							<CancelIcon
								className="cursor-pointer"
								height={24}
								width={24}
								roundBorder
							/>
						</div>
					)}
					<div
						className={`content-title d-block text-center themeText fs-${titleTextSize} bold-text pa-b-18`}
					>
						{title && title}
					</div>
					<div>
						<Scrollbars
							autoHide
							autoHeight
							autoHeightMin={autoHeightMin}
							autoHeightMax={autoHeightMax ?? forMap ?? 480}
							renderTrackVertical={({ style, ...props }) => (
								<div
									{...props}
									style={{
										...style,
										zIndex: 5,
										position: 'absolute',
										width: '2px',
										right: '2px',
										bottom: '2px',
										top: '2px',
										borderRadius: '3px'
									}}
								/>
							)}
							className="m-0"
						>
							<div className="w-100 h-100 pr-8">{children}</div>
						</Scrollbars>
						<div className="d-flex align-items-center justify-content-center">
							{modalFooter}
						</div>
					</div>
				</ModalWrapper>
			</ModalBody>
		</Modal>
	);
};

export default CustomModal;
