import { useCallback, useState } from 'react';
import { Button } from 'reactstrap';
import {
	DndContext,
	closestCenter,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors
} from '@dnd-kit/core';
import {
	arrayMove,
	SortableContext,
	rectSortingStrategy
} from '@dnd-kit/sortable';
import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import AdditionalExtrasPopover from '../AdditionalExtrasPopover';
import OptionInput from './OptionInput';
import { PlusIcon } from '../../Icons';

const AdditionalExtras = ({
	name,
	values,
	handleChange,
	setFieldValue,
	touched,
	errors,
	handleDeletedAdditionalExtras,
	posStatus
}) => {
	const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
	const [activeId, setActiveId] = useState(null);
	const [items, setItems] = useState([]);
	const [itemsIds, setItemsIds] = useState([]);
	const isXL = useMediaQuery({ maxWidth: 1199 });
	const getItem = (id) => {
		let item;
		if (items?.length > 0) {
			item = items?.find((subItem) => {
				return subItem?.id == id;
			});
		}
		return item;
	};
	const handleDragStart = useCallback((event) => {
		setActiveId(event.active.id);
	}, []);
	const handleDragEnd = useCallback(
		(event) => {
			const { active, over } = event;
			if (active?.id !== over?.id) {
				const oldIndex = itemsIds?.indexOf(active?.id);
				const newIndex = itemsIds?.indexOf(over?.id);
				const updatedItemsIds = arrayMove(itemsIds, oldIndex, newIndex);
				setItemsIds([...updatedItemsIds]);
				setFieldValue(
					name,
					updatedItemsIds?.map((id) => {
						let item = getItem(id);
						if (item?.itemId) {
							return {
								itemName: item?.itemName,
								price: item?.price,
								itemId: item?.itemId
							};
						}
						return {
							itemName: item?.itemName,
							price: item?.price
						};
					})
				);
			}
			setActiveId(null);
		},
		[items]
	);
	const handleDragCancel = useCallback(() => {
		setActiveId(null);
	}, []);
	useEffect(() => {
		if (values?.additionalExtras?.length > 0) {
			setItems(
				values?.additionalExtras?.map((item, index) => {
					if (item?.itemId) {
						return {
							id: index + 1,
							itemName: item?.itemName,
							price: item?.price,
							itemId: item?.itemId
						};
					}
					return {
						id: index + 1,
						itemName: item?.itemName,
						price: item?.price
					};
				})
			);
			setItemsIds(
				values?.additionalExtras?.map((item, index) => {
					return index + 1;
				})
			);
		} else {
			setItems([]);
			setItemsIds([]);
		}
	}, [values, values?.additionalExtras]);
	return (
		<>
			<div className="d-flex gap-3 justify-content-between align-items-center pa-b-18">
				<div>
					<p className="fs-14 medium-text themeText label-color pa-b-8">
						Additional Extras
					</p>
					<p className={`fs-12 medium-text hintColor h-px-36`}>
						(Customers can select as many of the below extras as
						they would like)
					</p>
				</div>
				<div
					className="d-flex align-items-center"
					style={{ gap: '12px' }}
				>
					{!posStatus && (
						<Button
							className="themeButton w-px-30 h-px-30 p-0 d-flex justify-content-center align-items-center"
							onClick={() => {
								setFieldValue(name, [
									...values.additionalExtras,
									{
										itemName: '',
										price: '0.00'
									}
								]);
							}}
						>
							<PlusIcon height={18} width={18} stroke={'#FFF'} />
						</Button>
					)}
					<AdditionalExtrasPopover
						additionalExtras={values?.additionalExtras}
						setFieldValue={setFieldValue}
						name={name}
						values={values}
						posStatus={posStatus}
					/>
				</div>
			</div>
			{values?.additionalExtras?.length > 0 && (
				<div className="addAdditionalCard d-flex justify-content-between plr-16 ptb-8 align-items-center">
					<div className="fs-14 medium-text">
						Add Additional Extras...
					</div>
				</div>
			)}
			<div className="pa-t-18">
				<DndContext
					sensors={sensors}
					collisionDetection={closestCenter}
					onDragStart={handleDragStart}
					onDragEnd={handleDragEnd}
					onDragCancel={handleDragCancel}
					modifiers={[restrictToParentElement]}
				>
					<SortableContext
						items={items}
						strategy={rectSortingStrategy}
						handle
					>
						{itemsIds?.length > 0 &&
							itemsIds?.map((item, index) => {
								return (
									<OptionInput
										id={item}
										key={index}
										index={index}
										name={name}
										option={getItem(item)}
										values={values}
										setFieldValue={setFieldValue}
										handleChange={handleChange}
										touched={touched}
										errors={errors}
										additionalOptions={true}
										handleDeletedAdditionalExtras={
											handleDeletedAdditionalExtras
										}
										posStatus={posStatus}
									/>
								);
							})}
					</SortableContext>
				</DndContext>
			</div>
		</>
	);
};

export default AdditionalExtras;
