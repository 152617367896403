import styled from 'styled-components';

const PageWrapper = styled.div`
	background-color: #ffffff;
	color: ${(props) => props.layoutTheme.textColor} !important;
	.defaultBoxShadow {
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
	}
	.performanceCard {
		padding: 24px;
		background-color: #ffffff;
		border-radius: 16px;
		.subPerformanceCard {
			height: 100%;
			border: 1px solid #f4f4f4;
			border-radius: 12px;
		}
	}
	@media only screen and (max-width: 767px) {
		padding: 12px;
		.border-radius-16 {
			border-radius: 0px !important;
		}
		.defaultBoxShadow {
			box-shadow: unset;
		}
	}
`;

export default PageWrapper;
