import React, { useState } from 'react';
import { Button, CardBody, Collapse } from 'reactstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import CustomModal from '../../../Common/Modal/CustomModal';
import FormRadioButton from '../../../Form/FormRadioButton';
import { DownArrowIcon, UpArrowIcon } from '../../../Icons';
import CustomWrapper from './index.style';
import { timeBoxDetails } from '../utils';
import Api from '../../../../Helper/Api';
import { VenueApiRoutes } from '../../../../Utils/routes';
import CustomButton from '../../../Common/CustomButton';

const WaitTimeModal = ({ isOpen, handleModal, modalData }) => {
	const state = useSelector((state) => ({ ...state }));
	const allThemeData = state.themeChanger;
	const authData = state.auth;
	const [timeBoxData, setTimeBoxData] = useState(timeBoxDetails);
	const [waitTimeData, setWaitTimeData] = useState(modalData?.waitTimeData);
	const [waitTimeType, setWaitTimeType] = useState(
		modalData?.waitTimeData[0]?.type
	);
	const [updateWaitTimeLoading, setUpdateWaitTimeLoading] = useState(false);
	// when any time box clicked, this function will be called
	const handleTimeChange = (dayId, dataId, updatedWaitTime) => {
		if (waitTimeType === '1') {
			setWaitTimeData((prevData) =>
				prevData?.map((data, index) =>
					data?.weekDay === dayId
						? {
								...data,
								type: waitTimeType,
								weekDay_WaitingTime:
									data?.weekDay_WaitingTime?.map(
										(item, index) =>
											item?.id === dataId
												? {
														...item,
														waitTime:
															updatedWaitTime
												  }
												: { ...item }
									)
						  }
						: { ...data, type: waitTimeType }
				)
			);
		} else {
			setWaitTimeData((prevData) =>
				prevData?.map((data, index) =>
					data?.weekDay === dayId
						? {
								...data,
								type: waitTimeType,
								weekDay_WaitingTime:
									data?.weekDay_WaitingTime?.map(
										(item, index) => ({
											...item,
											waitTime: updatedWaitTime
										})
									)
						  }
						: { ...data }
				)
			);
		}
	};
	const handleUpdateWaitTime = async () => {
		let payload = {
			bar_id: '' + authData?.selectedVenue?.id,
			type: '' + waitTimeType,
			sub_category_id: '' + modalData?.subCategoryData?.subCategoryID,
			data: waitTimeData?.map((item) => {
				return {
					weekDay: '' + item?.weekDay,
					weekDay_WaitingTime: item?.weekDay_WaitingTime?.map(
						(subItem) => {
							return {
								id: '' + subItem?.id,
								waitTime: subItem?.waitTime
							};
						}
					)
				};
			})
		};
		try {
			setUpdateWaitTimeLoading(true);
			const res = await Api(
				'POST',
				VenueApiRoutes.updateWaitTime,
				payload
			);
			if (res?.data?.status) {
				toast.success(res?.data?.message);
				handleModal();
			} else {
				toast.error(res?.data?.message);
			}
			setUpdateWaitTimeLoading(false);
		} catch (err) {
			setUpdateWaitTimeLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	return (
		<CustomModal
			title="Set Wait Times"
			size="lg"
			isOpen={isOpen}
			handleModal={handleModal}
			style={{
				maxWidth: '648px',
				width: '100%'
			}}
		>
			<CustomWrapper {...allThemeData}>
				<p className="fs-12 medium-text text-center">
					Customers will see the estimated wait times for each menu
					subheading
				</p>
				<p className="fs-12 medium-text pa-t-18">
					Selected:{' '}
					<span className="fs-12 regular-text">
						{modalData?.subCategoryData?.name ?? ''}
					</span>
				</p>
				<div className="horizontalLine mtb-18" />
				<div className="d-flex flex-column flex-sm-row justify-content-between gap-2">
					<p className="fs-12 medium-text">Wait Time Configuration</p>
					<div className="d-flex" style={{ gap: '18px' }}>
						<div className="d-flex align-items-center">
							<FormRadioButton
								name="waitTimeType"
								checked={waitTimeType === '1'}
								onChange={() => setWaitTimeType('1')}
							/>
							<span className="fs-12 regular-text pl-6">
								Per Hour
							</span>
						</div>
						<div className="d-flex align-items-center">
							<FormRadioButton
								name="waitTimeType"
								checked={waitTimeType === '2'}
								onChange={() => setWaitTimeType('2')}
							/>
							<span className="fs-12 regular-text pl-6">
								All Day
							</span>
						</div>
					</div>
				</div>
				<div className="horizontalLine mtb-18" />
				<div>
					{waitTimeData?.length > 0 &&
						waitTimeData?.map((data, index) => (
							<div key={index}>
								<Button
									className="w-100 d-flex justify-content-between align-items-center bg-white border-0 p-0"
									onClick={() =>
										setWaitTimeData((prevData) =>
											prevData?.map((item, i) =>
												i === index
													? {
															...item,
															status: !item?.status
													  }
													: item
											)
										)
									}
								>
									<p className="fs-12 medium-text themeText">
										{moment()
											.day(Number(data?.weekDay) + 1)
											.format('dddd')}
									</p>
									{waitTimeData?.[index]?.status ? (
										<UpArrowIcon
											height={6}
											width={16}
											stroke={'#F94D73'}
										/>
									) : (
										<DownArrowIcon
											height={6}
											width={16}
											stroke={'#F94D73'}
										/>
									)}
								</Button>
								<Collapse
									isOpen={waitTimeData?.[index]?.status}
								>
									<CardBody className="pa-t-18">
										{waitTimeType === '1' ? (
											<>
												{data?.weekDay_WaitingTime
													?.length > 0 &&
													data?.weekDay_WaitingTime?.map(
														(record, i) => (
															<div
																key={i}
																className={`d-flex flex-column flex-sm-row gap-2 ${
																	i ===
																	data
																		?.weekDay_WaitingTime
																		?.length -
																		1
																		? ''
																		: 'pa-b-18'
																}`}
															>
																<div className="fs-11 medium-text d-flex align-items-center startTime">
																	{
																		record?.startTime
																	}
																</div>
																<div className="flex-1 d-flex flex-column flex-sm-row justify-content-between">
																	{timeBoxData?.length >
																		0 &&
																		timeBoxData?.map(
																			(
																				item,
																				i
																			) => (
																				<div
																					key={
																						i
																					}
																					className={`timeBox fs-11 medium-text pa-6 ${
																						record?.waitTime ===
																						item?.time
																							? 'active'
																							: ''
																					}`}
																					onClick={() =>
																						handleTimeChange(
																							data?.weekDay,
																							record?.id,
																							item?.time
																						)
																					}
																				>
																					{
																						item?.name
																					}
																				</div>
																			)
																		)}
																</div>
															</div>
														)
													)}
											</>
										) : (
											<div className="d-flex flex-column flex-sm-row justify-content-between gap-1">
												{timeBoxData?.length > 0 &&
													timeBoxData?.map(
														(item, i) => (
															<div
																key={i}
																className={`timeBox fs-11 medium-text pa-6 ${
																	data
																		?.weekDay_WaitingTime[0]
																		?.waitTime ===
																	item?.time
																		? 'active'
																		: ''
																}`}
																onClick={() =>
																	handleTimeChange(
																		data?.weekDay,
																		data
																			?.weekDay_WaitingTime[0]
																			?.id,
																		item?.time
																	)
																}
															>
																{item?.name}
															</div>
														)
													)}
											</div>
										)}
									</CardBody>
								</Collapse>
								{index !== waitTimeData?.length - 1 && (
									<div className="horizontalLine mtb-18" />
								)}
							</div>
						))}
				</div>
				<div className="pa-t-18">
					<CustomButton
						type="button"
						className="themeButtonFullWidth"
						loading={updateWaitTimeLoading}
						onClick={handleUpdateWaitTime}
					>
						Done
					</CustomButton>
				</div>
			</CustomWrapper>
		</CustomModal>
	);
};

export default WaitTimeModal;
