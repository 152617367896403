import styled from 'styled-components';

const PageWrapper = styled.div`
	.info-status-tag {
		background: #e7f1ff;
		border-radius: 6px;
		color: #195dbc;
	}

	.warning-status-tag {
		background: #fff4e7;
		border-radius: 6px;
		color: #e58008;
	}

	.success-status-tag {
		background: #e7ffe9;
		border-radius: 6px;
		color: #43bc19;
	}

	.statusTag {
		width: 85px;
	}

	.taskNameTable {
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;

export default PageWrapper;
