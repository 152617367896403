import styled from 'styled-components';

const FormMobileNoInputWrapper = styled.div`
	display: flex;
	// gap: 6px !important;
	.form-control {
		padding-left: 10px;
	}
	.form-control:focus {
		box-shadow: none !important;
	}
	input {
		width: 100%;
		height: 65px;
		padding-top: 0px;
		padding-bottom: 0px;
		padding-inline: 16px;
		padding-left: 0px;
		color: rgba(49, 49, 50, 0.92) !important;
		font-family: 'poppins-regular';
		font-size: 24px;
		border: 2px solid #31313259 !important;
		border-left: 0px !important;
		outline: none;
		border-radius: 0 !important;
		::placeholder {
			color: grey !important;
		}
	}
	.libraryInputWrapper {
		height: auto !important;
		border: 2px solid #31313259 !important;
		border-right: 0px !important;
		color: rgba(49, 49, 50, 0.92) !important;
		font-family: 'montserrat-medium' !important;
	}
	.libraryInput {
		display: none !important;
	}
	.intl-tel-input {
		width: 73px !important;
		height: 61px !important;
		@media (max-width: 600px) {
			width: 51px !important;
			height: 41px !important;
		}

		@media only screen and (min-width: 600px) and (max-width: 960px) {
			width: 51px !important;
			height: 41px !important;
		}
		@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
			width: 52.9px !important;
			height: 41.5px !important;
		}
	}
	.iti-flag {
		display: none;
	}

	.intl-tel-input .flag-container .arrow.down:after {
		content: '|';
		margin-left: 10px;
	}
	.intl-tel-input .flag-container .arrow.up:after {
		content: '|';
		margin-left: 10px;
	}

	.flag-container {
		position: unset !important;
		height: 100% !important;
		background-color: transparent !important;
	}
	.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
		width: 100%;
		background-color: transparent !important;
		padding-inline: 12px !important;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		.selected-dial-code {
			color: #313132eb !important;
			font-family: 'poppins-regular' !important;
			font-size: 24px;
			display: block;
			vertical-align: unset;
			padding-left: 6px !important;
			pointer-events: none;

			@media (max-width: 600px) {
				font-size: 12px;
			}

			@media only screen and (min-width: 600px) and (max-width: 960px) {
				font-size: 12px;
			}
			@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
				font-size: 24px;
				font-size: 12px;
			}
		}
	}
	.intl-tel-input .flag-container .arrow {
		font-size: 16px !important;
		margin-left: 0 !important;
		flex: 1;
		display: flex;
		justify-content: flex-end;
		pointer-events: none;
	}
	.country-list {
		.country-name,
		.dial-code {
			font-family: 'montserrat-medium' !important;
			font-size: 12px !important;
		}
		border: 1px solid #eaeaea !important;
		box-shadow: unset !important;
		border-radius: 6px !important;
		margin-top: 4px !important;
		/* Customize the scrollbar */
		scrollbar-width: thin; /* For Firefox */
		scrollbar-color: rgba(0, 0, 0, 0.2) #fff; /* For Firefox */
		/* Customize the scrollbar for Webkit browsers (Chrome, Safari, etc.) */
		::-webkit-scrollbar {
			width: 2px;
		}
		::-webkit-scrollbar-thumb {
			background-color: rgba(0, 0, 0, 0.2);
			border-radius: 3px !important;
		}
		::-webkit-scrollbar-track {
			background-color: #fff;
			border-radius: 18px !important;
		}
	}

	@media (max-width: 600px) {
		input {
			height: 45px;
			font-size: 12px;
		}
	}

	@media only screen and (min-width: 600px) and (max-width: 960px) {
		input {
			height: 57.7px;
			font-size: 18px;
		}
	}

	@media only screen and (max-width: 1224px) {
	}

	@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
		input {
			height: 46.3px;
			font-size: 13.5px;
		}
	}
`;

export default FormMobileNoInputWrapper;
