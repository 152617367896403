import { useState } from 'react';
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle
} from 'reactstrap';
import Select from 'react-select';

import { DownArrowIcon, MagnifyingGlassIcon, UpArrowIcon } from '../Icons';
import CustomDateRangePicker from '../Common/DateRangePicker/CustomDateRangePicker';
import { defineds } from '../../Helper/DateHelper';
import FormInputGroup from '../Form/FormInputGroup';
import useDevice from '../../Hooks/useDevice';
import ReactFormSelect from '../Form/ReactFormSelect';
import FormSelect from '../Form/FormSelect';

const Analytics = ({
	cityDropdown,
	setCityDropdown,
	dateDropdown,
	setDateDropdown
}) => {
	const [dateRangePickerLabel, setDateRangePickerLabel] = useState('Today');
	const { isMobile, isBigScreenHeight } = useDevice();

	const [dateRange, setDateRange] = useState([null, null]);

	const handleDateRange = (ranges) => {
		if (ranges[0] && ranges[1]) {
			setDateDropdown(!dateDropdown);
			setDateRangePickerLabel(
				ranges[0].toLocaleDateString() +
					' to ' +
					ranges[1].toLocaleDateString()
			);
		}
		setDateRange(ranges);
	};

	const handleDateRangeLabel = (label) => {
		setDateRangePickerLabel(label);
		if (label === 'Today') {
			setDateRange([defineds.startOfToday, defineds.endOfToday]);
		} else if (label === 'Yesterday') {
			setDateRange([defineds.startOfYesterday, defineds.endOfYesterday]);
		} else if (label === 'This Week') {
			setDateRange([defineds.startOfWeek, defineds.endOfWeek]);
		} else if (label === 'This Month') {
			setDateRange([defineds.startOfMonth, defineds.endOfToday]);
		} else {
			setDateRange([null, null]);
		}
	};

	const options = [
		{ value: 'Queensland', label: 'Queensland' },
		{ value: 'New South Wales', label: 'New South Wales' },
		{ value: 'Tasmania', label: 'Tasmania' }
	];

	return (
		<div
			className={`analytics-card border-radius-16 p-3 d-flex flex-2 flex-column min-height-100`}
		>
			<div className="d-flex flex-column flex-sm-row justify-content-between gap-3">
				<div className="fs-20 semi-bold-text">Analytics</div>
				<div className="d-flex justify-content-evenly">
					<Dropdown
						isOpen={cityDropdown}
						toggle={() => setCityDropdown((prev) => !prev)}
						className="dashboard-dropdown dropdownCity"
					>
						<DropdownToggle
							color="#fff"
							className="ptb-0 dropdownToggle"
						>
							<span
								className={`dropdown-name fs-12 medium-text ${
									cityDropdown ? 'activeColor' : null
								}`}
							>
								Worldwide
							</span>
							<span>
								{cityDropdown ? (
									<UpArrowIcon height={7} width={12} />
								) : (
									<DownArrowIcon height={7} width={12} />
								)}
							</span>
						</DropdownToggle>
						<DropdownMenu>
							<div className="p-2 dropdown-item plr-20">
								<FormInputGroup
									id="search"
									type="text"
									name="search"
									placeholder="Search"
									icon={
										<MagnifyingGlassIcon
											height={24}
											width={24}
										/>
									}
									iconPlacement="start"
									formGroupClassName="w-100 mb-0"
								/>
								<DropdownItem className="fs-16 medium-text themeText">
									Worldwide
								</DropdownItem>
								<div>
									<hr className="p-0 m-1" />
								</div>
								<DropdownItem className="fs-16 medium-text themeText">
									Australia
								</DropdownItem>
								<div className="fs-16 medium-text themeText p-2 pt-3">
									<FormSelect
										options={options}
										placeholder="Select State, Territory"
									/>
								</div>
							</div>
						</DropdownMenu>
					</Dropdown>
					<Dropdown
						isOpen={dateDropdown}
						toggle={() => setDateDropdown((prev) => !prev)}
						direction="down"
						className="dashboard-dropdown"
					>
						<DropdownToggle
							color="#fff"
							className="ptb-0 dropdownToggle"
						>
							<span
								className={`dropdown-name fs-12 medium-text ${
									dateRangePickerLabel.includes('/')
										? 'activeColor'
										: dateDropdown
										? 'activeColor'
										: ''
								}`}
							>
								{dateRangePickerLabel}
							</span>
							<span>
								{dateDropdown ? (
									<UpArrowIcon height={7} width={12} />
								) : (
									<DownArrowIcon height={7} width={12} />
								)}
							</span>
						</DropdownToggle>
						<DropdownMenu className="p-2 datePickerDropdown">
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'Today'
										? 'active'
										: ''
								}`}
								onClick={() => handleDateRangeLabel('Today')}
							>
								Today
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'Yesterday'
										? 'active'
										: ''
								}`}
								onClick={() =>
									handleDateRangeLabel('Yesterday')
								}
							>
								Yesterday
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'This Week'
										? 'active'
										: ''
								}`}
								onClick={() =>
									handleDateRangeLabel('This Week')
								}
							>
								This Week
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'This Month'
										? 'active'
										: ''
								}`}
								onClick={() =>
									handleDateRangeLabel('This Month')
								}
							>
								This Month
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'All'
										? 'active'
										: ''
								}`}
								onClick={() => handleDateRangeLabel('All')}
							>
								All
							</DropdownItem>
							<hr />
							<div className="p-0 dropdown-item dropdownCalender">
								<CustomDateRangePicker
									dateRange={dateRange}
									handleDateRange={handleDateRange}
								/>
							</div>
						</DropdownMenu>
					</Dropdown>
				</div>
			</div>
			<div
				className={`d-flex flex-column mt-4 mt-xl-4 justify-content-between gap-2 ${
					!!isMobile ? 'h-100' : ''
				}`}
			>
				<div className="d-flex flex-column dashboard-card-parent">
					<div className="dashboard-card">
						<div className="flex-4">
							<p className="fs-16 medium-text">
								Total Live Customers
							</p>
						</div>
						<div className="flex-1 align-items-center justify-content-end d-flex">
							<p className="fs-20 semi-bold-text">550</p>
						</div>
					</div>
					<div className="dashboard-card">
						<div className="flex-4">
							<p className="fs-16 medium-text">
								Total Live Venues
							</p>
						</div>
						<div className="flex-1 align-items-center justify-content-end d-flex">
							<p className="fs-20 semi-bold-text">550</p>
						</div>
					</div>
					<div className="dashboard-card">
						<div className="flex-4">
							<p className="fs-16 medium-text">
								Total Live Orders
							</p>
						</div>
						<div className="flex-1 align-items-center justify-content-end d-flex">
							<p className="fs-20 semi-bold-text">100</p>
						</div>
					</div>
					<div className="dashboard-card">
						<div className="flex-4">
							<p className="fs-16 medium-text">
								Total Live Order Sales
							</p>
						</div>
						<div className="flex-1 align-items-center justify-content-end d-flex">
							<p className="fs-20 semi-bold-text">100</p>
						</div>
					</div>
					<div className="dashboard-card">
						<div className="flex-4">
							<p className="fs-16 medium-text">
								MyTab Customer Transaction Fee Revenue
							</p>
						</div>
						<div className="flex-1 align-items-center justify-content-end d-flex">
							<p className="fs-20 semi-bold-text">$ 5,030</p>
						</div>
					</div>
					<div className="dashboard-card">
						<div className="flex-4">
							<p className="fs-16 medium-text">
								MyTab Live Premium + Feature Revenue
							</p>
						</div>
						<div className="flex-1 align-items-center justify-content-end d-flex">
							<p className="fs-20 semi-bold-text">$ 5,030</p>
						</div>
					</div>
				</div>
				<div className="d-flex justify-content-between align-items-center dashboard-total-card">
					<p className="fs-18 medium-text">
						Total Live MyTab Revenue
					</p>
					<p className="fs-26 semi-bold-text count">$ 5,000</p>
				</div>
			</div>
		</div>
	);
};

export default Analytics;
