import styled from 'styled-components';
import sectionImage from '../../Assets/images/section-common-back.png';

const PageWrapper = styled.div`
	width: 100%;
	height: 100%;
	/* new design styles */
	display: flex;
	.pageContainer {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 71px;
	}
	.iconImage {
		width: 51px;
		height: 44px;
	}
	.formSideSpace {
		padding-right: 26px;
	}
	.approvedStatus {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #ffffff;
		height: 54px;
		width: 100%;
		font-size: 17px;
		font-weight: 700;
		background: #ff5454;
		border-radius: 10px;
	}
	.approvedStatusSuccess {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #ffffff;
		height: 54px;
		width: 100%;
		font-weight: 700;
		font-size: 17px;
		background: #86de42;
		border-radius: 10px;
	}

	.smallInfo {
		margin: 15px 0 25px;
		font-family: 'poppins-regular';
		font-size: 21px;
		color: #000000;
		span {
			cursor: pointer;
			color: #f95c69;
		}
	}

	.imageWrapper {
		flex: 1;
		display: flex;

		background: #fff !important;

		.text {
			font-family: 'montserrat-bold';
			font-size: 50px;
			color: #fff;
			text-align: center;
		}
		.image {
			// max-width: 750px;
			object-fit: contain;
			// width: 938px;
			width: 78%;
			height: 18%;
			margin-top: 9rem;
		}
		@media only screen and (max-width: 1199px) {
			display: none !important;
		}
	}
	.backWrraper {
		height: 100%;
		width: 100%;
		display: flex;
		gap: 29px;
		flex-direction: column;
		align-items: center;
		background: url(${sectionImage});
		padding-top: 70px;
		background-size: 100% 100% !important;
		background-repeat: no-repeat;
	}

	.followSteps {
		display: flex;
		margin-bottom: 23px;
		font-family: 'poppins-regular';
		p {
			font-size: 21px;
			font-family: 'poppins-regular';
		}
		.numberCircle {
			color: #ffffff;
			font-size: 20px;
			font-weight: 800;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 100%;
			height: 51px;
			width: 51px;
			background: #f95c69;
		}
	}

	.verifyOtpSubText {
		font-family: 'poppins-regular';
		font-size: 22px;
		margin-bottom: 0.5%;
	}

	.resendMail {
		font-family: 'poppins-regular';
		color: #000000;
		font-size: 21px;
		text-align: center;
		.spanLink {
			font-family: 'montserrat-light';
			color: #f95c69;
			cursor: pointer;
		}
	}

	@media (max-width: 600px) {
		.approvedStatusSuccess,
		.approvedStatus {
			height: 33.2px;
			width: 237px;
			font-size: 10.1px;
		}
		.headingStyle {
			padding-top: 3%;
			font-size: 22px;
			padding-bottom: 5%;
		}

		.iconImage {
			width: 23.5px;
			height: 20.3px;
		}
		.imageWrapper {
			display: none;
		}

		.followSteps {
			p {
				font-size: 9.7px;
				max-width: 618px;
			}
			.numberCircle {
				font-size: 10px;
				font-weight: 800;

				height: 20px;
				width: 20px;
			}
		}
		.resendMail {
			font-size: 9.7px;
		}

		.verifyOtpSubText {
			font-size: 10.1px;
		}

		.smallInfo {
			font-size: 9.7px !important;
		}
		.formWrapper {
			padding: 0 20px !important;
		}
		.formSideSpace {
			padding-right: 0px !important;
		}
	}

	@media only screen and (min-width: 600px) and (max-width: 960px) {
		.approvedStatusSuccess,
		.approvedStatus {
			height: 54px;
			width: 384.7px;
			font-size: 16.5px;
		}

		.headingStyle {
			font-size: 31.5px;
			padding-bottom: 7%;
		}

		.iconImage {
			width: 38.2px;
			height: 33px;
		}

		.followSteps {
			p {
				font-size: 15.7px;
				max-width: 618px;
			}
			.numberCircle {
				font-size: 12px;
				font-weight: 800;

				height: 33px;
				width: 33px;
			}
		}

		.smallInfo {
			font-size: 15.7px !important;
		}
		.verifyOtpSubText {
			font-size: 16.5px;
		}
		.resendMail {
			font-size: 15.7px !important;
		}

		.imageWrapper {
			display: none;
		}
		.formWrapper {
			padding: 0 50px !important;
		}
		.formSideSpace {
			padding-right: 0px !important;
		}
	}

	@media only screen and (max-width: 1240px) {
		.smallInfo {
			font-size: 15px !important;
		}
		.stripeHeading {
			font-size: 22px !important;
		}
	}
	@media only screen and (max-width: 1440px) {
		.smallInfo {
			font-size: 19.5px;
		}
		.imageWrapper {
			.text {
				font-size: 43px;
			}
			.image {
				width: 78% !important;
				height: 167.6px;
				margin-top: 7rem;
			}
		}
	}

	@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 800px) {
		.iconImage {
			width: 28.6px;
			height: 24.7px;
		}

		.approvedStatusSuccess,
		.approvedStatus {
			height: 40.5px;

			font-size: 12.3px;
		}

		.imageWrapper {
			.text {
				font-size: 35.9px;
			}
			.image {
				margin-top: 4rem;
				width: 527.6px;
				height: 167.6px;
			}
		}
		.headingStyle {
			padding-top: 30px;
			// font-size: 23.6px;
			font-size: 28.6px;
			max-width: 37rem;
		}

		.followSteps {
			margin-bottom: 12.9px;
			p {
				font-size: 11.8px;
				font-family: 'poppins-regular';
				max-width: 560.5px;
			}
			.numberCircle {
				font-size: 12px;
				font-weight: 800;
				border-radius: 100%;
				height: 30.3px;
				width: 30.3px;
			}
		}

		.smallInfo {
			min-width: 471px;
			max-width: 100%;
			// font-size: 11.8px;
			font-size: 15.8px;
		}
		.verifyOtpSubText {
			font-size: 12.3px;
		}
		.resendMail {
			font-size: 11.8px;
		}
	}
`;

export default PageWrapper;
