export const formatTableData = (data) => {
	let tableData = [];
	if (data && data?.length > 0) {
		tableData = data?.map((item) => {
			return {
				id: item?.id,
				name: item?.name,
				code: item?.code,
				discount: item?.discount_amount,
				start_date: item?.startsAt,
				expiry_date:
					item?.expiresAt === '0000-00-00' ? null : item?.expiresAt,
				status: item?.status,
				description: item?.description
			};
		});
	}
	return tableData;
};
