import styled from 'styled-components';

const RegisterFormWrapper = styled.div`
	padding-right: 45px !important;
	/* height: 80vh;
	overflow-y: auto; */
	.headingStyle {
		padding-top: 30px;
		font-size: 42px;
		font-weight: 700;
		font-family: 'montserrat-bold' !important;
		color: #313132eb;
		padding-bottom: 1%;
	}

	.password-suggestion {
		p {
			font-family: 'poppins-regular';
			color: #313132eb;
		}
		font-size: 20px;
		margin-top: 1%;
		font-family: 'poppins-regular';
	}

	.helperLink {
		user-select: none;
		padding-bottom: 14px;
		font-size: 24px;
		font-weight: 400;
		color: #000000;
		font-family: 'poppins-regular';
		.helperLinkSpan {
			font-family: 'poppins-bold';
			color: #f95c69;
			cursor: pointer;
		}
	}

	.agreement {
		font-family: 'poppins-medium';
		color: #000000;
		font-size: 24px;
		.agreementSpan {
			cursor: pointer;
			font-family: 'montserrat-semi-bold';
			color: #f95c69;
		}
	}

	input[type='checkbox'] {
		width: 24px;
		height: 24px;
		accent-color: #f95c69;
	}

	input[type='checkbox']:focus-visible {
		outline: none;
	}
	.customError {
		padding-top: 6px;
		font-family: 'montserrat-semi-bold';
		font-size: 18px;
		color: #ff5f5f;
	}
	@media (max-width: 600px) {
		padding-right: 0px !important;
		.headingStyle {
			padding-top: 4%;
			font-size: 22px;
			padding-bottom: 2%;
		}

		.helperLink {
			font-size: 11.5px;
		}

		.password-suggestion {
			font-size: 9.2px;
		}
		.agreement {
			font-size: 11px;
		}

		input[type='checkbox'] {
			width: 18px;
			height: 18px;
		}
		.customError {
			font-size: 10px;
		}
	}

	@media only screen and (min-width: 600px) and (max-width: 960px) {
		padding-right: 0px !important;
		.headingStyle {
			font-size: 31.5px;
			padding-bottom: 2%;
		}
		.helperLink {
			padding-bottom: 4%;
			font-size: 18px;
		}
		.password-suggestion {
			font-size: 14px;
			margin-bottom: 4%;
		}
		.agreement {
			font-size: 18px;
		}
		input[type='checkbox'] {
			width: 22px;
			height: 22px;
		}
		.customError {
			font-size: 12px;
		}
	}

	@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
		.headingStyle {
			padding-top: 30px;
			font-size: 23.6px;
		}

		.helperLink {
			font-size: 13.5px;
		}

		.password-suggestion {
			font-size: 11.25px;
		}
		.agreement {
			font-size: 13.5px;
		}
		input[type='checkbox'] {
			height: 20px;
			width: 20px;
		}
		.customError {
			font-size: 10px;
		}
	}
`;

export default RegisterFormWrapper;
