import { FormGroup, Input, Label } from 'reactstrap';

const FormCheckBox = ({
	id,
	label,
	error,
	errorMsg,
	className,
	checkboxPosition = 'start',
	...rest
}) => {
	return (
		<FormGroup className="mb-0">
			<div className="d-flex align-items-center" style={{ gap: '6px' }}>
				{checkboxPosition === 'start' && (
					<Input
						{...rest}
						type="checkbox"
						className={`${
							error ? 'is-invalid' : ''
						} themeCheckbox border-radius-0 fs-15 ma-t-0 medium-text ${
							className ? className : ''
						}`}
					/>
				)}
				{label && (
					<Label
						for={id}
						className="fs-12 regular-text themeText label-color mb-0"
					>
						{label}
					</Label>
				)}
				{checkboxPosition === 'end' && (
					<Input
						{...rest}
						type="checkbox"
						className={`${
							error ? 'is-invalid' : ''
						} themeCheckbox border-radius-0 fs-15 ma-t-0 medium-text ${
							className ? className : ''
						}`}
					/>
				)}
			</div>
			{error && (
				<p className="fs-10 semi-bold-text headingTextColor pa-t-6">
					{errorMsg}
				</p>
			)}
		</FormGroup>
	);
};

export default FormCheckBox;
