import React from 'react';
import Skeleton from 'react-loading-skeleton';

const OpeningHoursSkeleton = () => {
	return (
		<div>
			<Skeleton
				height="42px"
				width="100"
				borderRadius={4}
				className="ma-b-4"
			/>
			<Skeleton
				height="42px"
				width="100"
				borderRadius={4}
				className="ma-b-4"
			/>
			<Skeleton
				height="42px"
				width="100"
				borderRadius={4}
				className="ma-b-4"
			/>
			<Skeleton
				height="42px"
				width="100"
				borderRadius={4}
				className="ma-b-4"
			/>
			<Skeleton
				height="42px"
				width="100"
				borderRadius={4}
				className="ma-b-4"
			/>
			<Skeleton
				height="42px"
				width="100"
				borderRadius={4}
				className="ma-b-4"
			/>
			<Skeleton
				height="42px"
				width="100"
				borderRadius={4}
				className="ma-b-4"
			/>
		</div>
	);
};

export default OpeningHoursSkeleton;
