import styled from 'styled-components';

const StyleWrapper = styled.div`
	color: #313132 !important;
	.label {
		display: flex;
		align-items: center;
		gap: 2px;
		font-family: 'montserrat-bold';
		font-size: 20px;
		padding-bottom: 6px;
		text-transform: uppercase;
		.requiredImg {
			width: 22px;
			height: 19px;
			object-fit: cover;
		}
	}
	.inputWrapper {
		display: flex;
		align-items: center;
		height: 65px;
		.input {
			width: 100%;
			height: 100%;
			padding-left: 16px;
			color: #313132eb !important;
			font-family: 'poppins-regular';
			font-size: 24px;
			border: 2px solid #31313259;
			border-right: none;
			outline: none;
			border-radius: none;
		}
		.iconWrapper {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 2px solid #31313259;
			border-left: none;
			padding-inline: 8px;
			cursor: pointer;
		}
		.iconWrapper-suggestor {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			border-block: 2px solid #31313259;
			border-right: none;
			padding-inline: 8px;
			cursor: pointer;
		}
	}
	.error {
		padding-top: 6px;
		font-family: 'montserrat-semi-bold';
		font-size: 18px;
		color: #ff5f5f;
	}

	@media (max-width: 600px) {
		.label {
			font-size: 12px;
			.requiredImg {
				width: 22px;
				height: 19px;
			}
		}
		.inputWrapper {
			height: 45px;
			.input {
				font-size: 12px;
			}
		}
		.error {
			font-size: 10px;
		}
	}

	@media only screen and (min-width: 600px) and (max-width: 960px) {
		.label {
			font-size: 15px;
			.requiredImg {
				width: 22px;
				height: 19px;
			}
		}
		.inputWrapper {
			height: 57.7px;
			.input {
				font-size: 18px;
			}
		}
		.error {
			font-size: 12px;
		}
	}

	@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
		.label {
			font-size: 11.2px;
			.requiredImg {
				width: 12.3px;
				height: 10.6px;
			}
		}
		.inputWrapper {
			height: 53.4px;
			.input {
				font-size: 13.5px;
			}
		}
		.error {
			font-size: 10px;
		}
	}
`;

export default StyleWrapper;
