import { Button } from 'reactstrap';
import CustomModal from '../../Common/Modal/CustomModal';
import CustomButton from '../../Common/CustomButton';

const ServiceTypeConfirmModal = ({
	handleModal,
	isOpen,
	switchLoading,
	handleConfirm,
	modalData
}) => {
	const handleYes = async () => {
		await handleConfirm(1);
		handleModal();
	};
	return (
		<CustomModal
			isOpen={isOpen}
			handleModal={handleModal}
			size="md"
			autoHeightMin={100}
		>
			<div className="d-flex flex-column justify-content-center w-100">
				<p className="text-left pb-10 fs-14 medium-text">
					{modalData?.content}
				</p>
				<p className="text-left pb-10 fs-14 medium-text">
					Are you sure you want to update service type?
				</p>
				<div className="d-flex flex-column flex-md-row gap-2 w-100">
					<div className="flex-1">
						<Button
							className="fs-16 medium-text borderButtonFullWidth ptb-10 h-px-50 w-100"
							onClick={handleModal}
						>
							No
						</Button>
					</div>
					<div className="flex-1">
						<CustomButton
							type="button"
							className="fs-16 medium-text themeButtonFullWidth ptb-10 h-px-50 w-100"
							loading={switchLoading}
							onClick={handleYes}
						>
							Yes
						</CustomButton>
					</div>
				</div>
			</div>
		</CustomModal>
	);
};

export default ServiceTypeConfirmModal;
