import { CommonRoutes, VenueProtectedRoutes } from '../Utils/routes';
import PageNotFound from '../Pages/PageNotFound';
import PublicRoute from '../Components/Common/RouteWrappers/PublicRoute';
import NewConnectVenue from '../Pages/UserProfile/ConnectVenue/NewConnectVenue';
import VenuePortalLogin from '../Pages/UserProfile/ConnectVenue/VenuePortalLogin';
import CreateStripeAccount from '../Pages/Stripe/CreateStripeAccount';
import StripeAccountProcess from '../Pages/Stripe/StripeAccountProcess';
import StripeAccountProcessClose from '../Pages/Stripe/StripeAccountProcessClose';
import PosIntegration from '../Pages/POS';
import CreateNewVenuePortal from '../Pages/UserProfile/ConnectVenue/CreateNewVenuePortal';
import VenueProtectedLayout from '../Layouts/VenueProtectedLayout';

const venueProtectedRoutes = [
	{
		path: VenueProtectedRoutes.connectVenue,
		component: (
			<PublicRoute>
				<VenueProtectedLayout forPublic={false} />
				<NewConnectVenue />
			</PublicRoute>
		)
	},
	{
		path: VenueProtectedRoutes.connectExistingVenue,
		component: (
			<PublicRoute>
				<VenueProtectedLayout forPublic={false} />
				<VenuePortalLogin />
			</PublicRoute>
		)
	},
	{
		path: VenueProtectedRoutes.createVenuePortal,
		component: (
			<PublicRoute>
				<VenueProtectedLayout forPublic={false} />
				<CreateNewVenuePortal />
			</PublicRoute>
		)
	},
	{
		path: VenueProtectedRoutes.createStripeAccount,
		component: (
			<PublicRoute>
				<VenueProtectedLayout forPublic={false} />
				<CreateStripeAccount />
			</PublicRoute>
		)
	},
	{
		path: VenueProtectedRoutes.stripeAccountProcess,
		component: (
			<PublicRoute>
				<VenueProtectedLayout forPublic={false} />
				<StripeAccountProcess />
			</PublicRoute>
		)
	},
	{
		path: VenueProtectedRoutes.stripeAccountPageClose,
		component: (
			<PublicRoute>
				<VenueProtectedLayout forPublic={false} />
				<StripeAccountProcessClose />
			</PublicRoute>
		)
	},
	{
		path: VenueProtectedRoutes.posIntegration,
		component: (
			<PublicRoute>
				<VenueProtectedLayout forPublic={false} />
				<PosIntegration />
			</PublicRoute>
		)
	},
	{ path: CommonRoutes.pageNotFound, component: <PageNotFound /> }
];

export default venueProtectedRoutes;
