import uiAction from './actions';

const initState = {
	pageLoading: false
};

export default function rootReducer(state = initState, action) {
	switch (action.type) {
		case uiAction.PAGE_LOADING:
			return {
				...state,
				pageLoading: action.pageLoading
			};

		default:
			return state;
	}
}
