export const ManageMenuIcon = ({ fill, width, height, ...props }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 508.48 726.67"
			width={width}
			height={height}
		>
			<g id="Layer_2" data-name="Layer 2">
				<g id="Layer_1-2" data-name="Layer 1">
					<path
						className="cls-1"
						fill={fill ?? '#242424'}
						d="M84.68,301q0-47,0-94c0-4.06,1-7.65,4.2-10.37,6-5.19,14.26-3.79,18.53,3.26C115.26,213,123,226,130.84,239c.68,1.14,1.07,2.55,2.55,3.34l25.13-41.91c3.36-5.61,8.46-7.95,14.12-6.47,5.18,1.36,8.92,6.28,8.93,12.42q.1,47.73,0,95.46c0,7.57-5.3,12.86-12.28,12.78s-11.85-5.18-11.92-13.06c-.14-15.48,0-30.95-.05-46.42v-4.11c-1.58,0-1.64,1.2-2.09,1.94-3.68,6.07-7.27,12.19-11,18.26-5.47,9-16.79,9.09-22.25.15-4.15-6.8-8.18-13.67-12.28-20.5,0-.09-.16-.13-.39-.3-.75,1-.38,2.11-.39,3.13,0,16,.06,31.94,0,47.92-.06,9.34-7.64,15-16.1,12.29-4.32-1.4-6.73-4.54-7.76-8.81A17.41,17.41,0,0,1,84.68,301Z"
					/>
					<path
						className="cls-1"
						fill={fill ?? '#242424'}
						d="M193.69,301.69c-.06-16,0-31.95,0-47.92q0-23.22,0-46.43c0-8.91,4.63-13.7,13.49-13.76,11.61-.08,23.21,0,34.82,0A11.62,11.62,0,0,1,253.17,201c2,4.49,1.16,8.79-1.91,12.61-2.64,3.28-6.29,4.3-10.31,4.32-6.61,0-13.23.13-19.84-.05-2.54-.06-3.46.54-3.36,3.25.22,5.86.19,11.73,0,17.59-.08,2.55.55,3.46,3.26,3.38,7-.19,14-.15,21,0a11.73,11.73,0,0,1,11.15,7.44c2.09,4.7,1.1,9-2.12,12.88-2.43,2.92-5.73,3.84-9.36,3.85-6.86,0-13.73.14-20.58,0-2.69-.07-3.37.85-3.3,3.41.16,6.11.14,12.23,0,18.34-.05,2.16.66,2.73,2.75,2.7,6.86-.12,13.73-.08,20.59,0,7.54,0,13,5.05,13.12,12s-5.33,12-12.95,12.11c-11.6.1-23.21.12-34.81,0C198.52,314.56,193.72,309.64,193.69,301.69Z"
					/>
					<path
						className="cls-1"
						fill={fill ?? '#242424'}
						d="M266.38,302.36c-.15-10.72,0-21.46,0-32.19q0-31.63,0-63.27c0-7.05,3.29-11.54,9.43-13,5.86-1.35,10.93,1.2,14,7.3Q301,223.58,312.13,246a12.92,12.92,0,0,0,2.72,3.9v-3c0-13.85-.05-27.7.07-41.55A11.76,11.76,0,0,1,324,193.92a12.12,12.12,0,0,1,13.4,5.89,13.4,13.4,0,0,1,1.61,6.83q0,47.73,0,95.46c0,6.07-3.7,11-9,12.35-5.78,1.48-11.26-1.06-14.22-6.92-7.74-15.32-15.36-30.71-23.06-46-.53-1-.67-2.39-2.24-3.09,0,14.49,0,28.78,0,43.07,0,6.25-2.9,10.69-7.77,12.4C274.45,316.74,266.51,311.26,266.38,302.36Z"
					/>
					<path
						className="cls-1"
						fill={fill ?? '#242424'}
						d="M351.18,279.36c-.25-24.47-.16-49-.07-73.42A12,12,0,0,1,363,193.63a11.64,11.64,0,0,1,12.11,11.64c.35,9.1.16,18.22.17,27.34,0,14.73,0,29.46,0,44.19,0,6.66,3.09,11.46,8.05,13,8.21,2.57,15.79-2.74,16.16-11.49.21-5,.05-10,.05-15,0-19.1,0-38.2,0-57.3,0-6.52,4.33-11.53,10.28-12.27,7.58-.94,13.68,4.31,13.77,12.17.13,12.48,0,25,0,37.45,0,11.86.22,23.73-.07,35.58-.35,14.48-7.2,25.43-20,31.83-13.12,6.54-26.23,5.36-38.07-3.48C356.32,300.43,351.3,290.88,351.18,279.36Z"
					/>
					<path
						className="cls-1"
						fill={fill ?? '#242424'}
						d="M459.86,579.42a11.83,11.83,0,0,1-10.5,13.75c-1.36.12-2.74.07-4.11.07h-382c-2.86,0-5.72.16-8.4-1.23a12.4,12.4,0,0,1-6.36-12.87,12.05,12.05,0,0,1,11.1-9.93c1.49-.08,3-.06,4.49-.06h382C454.05,569.15,458.7,572.64,459.86,579.42Z"
					/>
					<path
						className="cls-1"
						fill={fill ?? '#242424'}
						d="M50.55,442.79c-2.6-4-3-8.12-.77-12.39a11.3,11.3,0,0,1,10.12-6.55c1.24-.05,2.49,0,3.74,0H444.85c2.24,0,4.49-.23,6.69.47a12.21,12.21,0,0,1,8.4,13c-.9,6.31-5.29,10.46-11.6,10.54-10,.13-20,0-30,.05h-164q-96,0-192.1,0C57.48,448,53.32,447,50.55,442.79Z"
					/>
					<path
						className="cls-1"
						fill={fill ?? '#242424'}
						d="M460.06,509.33a11.78,11.78,0,0,1-11.43,11.21c-1.37.07-2.74,0-4.12,0H254.26q-95.86,0-191.74,0c-3.16,0-6.24-.1-9-2A12.27,12.27,0,0,1,49,505a11.69,11.69,0,0,1,11-8.5c1.12,0,2.25,0,3.37,0H445a28.24,28.24,0,0,1,6,.31A12.09,12.09,0,0,1,460.06,509.33Z"
					/>
					<path
						className="cls-1"
						fill={fill ?? '#242424'}
						d="M508.48,135.38c0-8.95-5.24-14.79-14.7-14.6-18,.36-36,0-53.93.19-3.35,0-4.12-.88-4.1-4.16.12-34.2.08-68.41.07-102.62,0-11.21-7-16.43-17.71-13.28L12.58,120.33C2.18,123.39.1,126.18.1,137.14V424.05q0,144-.1,288c0,10.67,6.43,14.58,14.27,14.58q239.9-.12,479.78,0c8.7,0,14.43-5.12,14.43-14.48Q508.23,423.78,508.48,135.38ZM411.67,28.18V31.8c0,28.47,0,56.94.06,85.4,0,2.77-.48,3.76-3.57,3.76q-153-.13-306-.08H99.21c-.06-.28-.11-.56-.17-.84Zm68.51,674.14q-225.82-.14-451.66,0c-3.66,0-4.43-.93-4.43-4.49q.16-137.08.08-274.16t-.09-274.15c0-3.7,1-4.37,4.48-4.37q225.84.14,451.66,0c3.44,0,4.19.89,4.19,4.24q-.12,274.34,0,548.68C484.42,701.5,483.56,702.32,480.18,702.32Z"
					/>
					<path
						className="cls-2"
						fill={fill ?? '#242424'}
						d="M84.68,301q0-47,0-94c0-4.06,1-7.65,4.2-10.37,6-5.19,14.26-3.79,18.53,3.26C115.26,213,123,226,130.84,239c.68,1.14,1.07,2.55,2.55,3.34l25.13-41.91c3.36-5.61,8.46-7.95,14.12-6.47,5.18,1.36,8.92,6.28,8.93,12.42q.1,47.73,0,95.46c0,7.57-5.3,12.86-12.28,12.78s-11.85-5.18-11.92-13.06c-.14-15.48,0-30.95-.05-46.42v-4.11c-1.58,0-1.64,1.2-2.09,1.94-3.68,6.07-7.27,12.19-11,18.26-5.47,9-16.79,9.09-22.25.15-4.15-6.8-8.18-13.67-12.28-20.5,0-.09-.16-.13-.39-.3-.75,1-.38,2.11-.39,3.13,0,16,.06,31.94,0,47.92-.06,9.34-7.64,15-16.1,12.29-4.32-1.4-6.73-4.54-7.76-8.81A17.41,17.41,0,0,1,84.68,301Z"
					/>
					<path
						className="cls-2"
						fill={fill ?? '#242424'}
						d="M193.69,301.69c-.06-16,0-31.95,0-47.92q0-23.22,0-46.43c0-8.91,4.63-13.7,13.49-13.76,11.61-.08,23.21,0,34.82,0A11.62,11.62,0,0,1,253.17,201c2,4.49,1.16,8.79-1.91,12.61-2.64,3.28-6.29,4.3-10.31,4.32-6.61,0-13.23.13-19.84-.05-2.54-.06-3.46.54-3.36,3.25.22,5.86.19,11.73,0,17.59-.08,2.55.55,3.46,3.26,3.38,7-.19,14-.15,21,0a11.73,11.73,0,0,1,11.15,7.44c2.09,4.7,1.1,9-2.12,12.88-2.43,2.92-5.73,3.84-9.36,3.85-6.86,0-13.73.14-20.58,0-2.69-.07-3.37.85-3.3,3.41.16,6.11.14,12.23,0,18.34-.05,2.16.66,2.73,2.75,2.7,6.86-.12,13.73-.08,20.59,0,7.54,0,13,5.05,13.12,12s-5.33,12-12.95,12.11c-11.6.1-23.21.12-34.81,0C198.52,314.56,193.72,309.64,193.69,301.69Z"
					/>
					<path
						className="cls-2"
						fill={fill ?? '#242424'}
						d="M266.38,302.36c-.15-10.72,0-21.46,0-32.19q0-31.63,0-63.27c0-7.05,3.29-11.54,9.43-13,5.86-1.35,10.93,1.2,14,7.3Q301,223.58,312.13,246a12.92,12.92,0,0,0,2.72,3.9v-3c0-13.85-.05-27.7.07-41.55A11.76,11.76,0,0,1,324,193.92a12.12,12.12,0,0,1,13.4,5.89,13.4,13.4,0,0,1,1.61,6.83q0,47.73,0,95.46c0,6.07-3.7,11-9,12.35-5.78,1.48-11.26-1.06-14.22-6.92-7.74-15.32-15.36-30.71-23.06-46-.53-1-.67-2.39-2.24-3.09,0,14.49,0,28.78,0,43.07,0,6.25-2.9,10.69-7.77,12.4C274.45,316.74,266.51,311.26,266.38,302.36Z"
					/>
					<path
						className="cls-2"
						fill={fill ?? '#242424'}
						d="M351.18,279.36c-.25-24.47-.16-49-.07-73.42A12,12,0,0,1,363,193.63a11.64,11.64,0,0,1,12.11,11.64c.35,9.1.16,18.22.17,27.34,0,14.73,0,29.46,0,44.19,0,6.66,3.09,11.46,8.05,13,8.21,2.57,15.79-2.74,16.16-11.49.21-5,.05-10,.05-15,0-19.1,0-38.2,0-57.3,0-6.52,4.33-11.53,10.28-12.27,7.58-.94,13.68,4.31,13.77,12.17.13,12.48,0,25,0,37.45,0,11.86.22,23.73-.07,35.58-.35,14.48-7.2,25.43-20,31.83-13.12,6.54-26.23,5.36-38.07-3.48C356.32,300.43,351.3,290.88,351.18,279.36Z"
					/>
					<path
						className="cls-2"
						fill={fill ?? '#242424'}
						d="M459.86,579.42a11.83,11.83,0,0,1-10.5,13.75c-1.36.12-2.74.07-4.11.07h-382c-2.86,0-5.72.16-8.4-1.23a12.4,12.4,0,0,1-6.36-12.87,12.05,12.05,0,0,1,11.1-9.93c1.49-.08,3-.06,4.49-.06h382C454.05,569.15,458.7,572.64,459.86,579.42Z"
					/>
					<path
						className="cls-2"
						fill={fill ?? '#242424'}
						d="M50.55,442.79c-2.6-4-3-8.12-.77-12.39a11.3,11.3,0,0,1,10.12-6.55c1.24-.05,2.49,0,3.74,0H444.85c2.24,0,4.49-.23,6.69.47a12.21,12.21,0,0,1,8.4,13c-.9,6.31-5.29,10.46-11.6,10.54-10,.13-20,0-30,.05h-164q-96,0-192.1,0C57.48,448,53.32,447,50.55,442.79Z"
					/>
					<path
						className="cls-2"
						fill={fill ?? '#242424'}
						d="M460.06,509.33a11.78,11.78,0,0,1-11.43,11.21c-1.37.07-2.74,0-4.12,0H254.26q-95.86,0-191.74,0c-3.16,0-6.24-.1-9-2A12.27,12.27,0,0,1,49,505a11.69,11.69,0,0,1,11-8.5c1.12,0,2.25,0,3.37,0H445a28.24,28.24,0,0,1,6,.31A12.09,12.09,0,0,1,460.06,509.33Z"
					/>
					<path
						className="cls-1"
						fill={fill ?? '#242424'}
						d="M459.94,437.32c-.9,6.31-5.29,10.46-11.6,10.54-10,.13-20,0-30,.05h-164q-96,0-192.1,0c-4.78,0-8.94-.94-11.71-5.15-2.6-4-3-8.12-.77-12.39a11.3,11.3,0,0,1,10.12-6.55c1.24-.05,2.49,0,3.74,0H444.85c2.24,0,4.49-.23,6.69.47A12.21,12.21,0,0,1,459.94,437.32Z"
					/>
					<path
						className="cls-1"
						fill={fill ?? '#242424'}
						d="M460.06,509.33a11.78,11.78,0,0,1-11.43,11.21c-1.37.07-2.74,0-4.12,0H254.26q-95.86,0-191.74,0c-3.16,0-6.24-.1-9-2A12.27,12.27,0,0,1,49,505a11.69,11.69,0,0,1,11-8.5c1.12,0,2.25,0,3.37,0H445a28.24,28.24,0,0,1,6,.31A12.09,12.09,0,0,1,460.06,509.33Z"
					/>
					<path
						className="cls-1"
						fill={fill ?? '#242424'}
						d="M449.36,593.17c-1.36.12-2.74.07-4.11.07h-382c-2.86,0-5.72.16-8.4-1.23a12.4,12.4,0,0,1-6.36-12.87,12.05,12.05,0,0,1,11.1-9.93c1.49-.08,3-.06,4.49-.06h382c8,0,12.62,3.49,13.78,10.27A11.83,11.83,0,0,1,449.36,593.17Z"
					/>
					<path
						className="cls-1"
						fill={fill ?? '#242424'}
						d="M181.57,301.86c0,7.57-5.3,12.86-12.28,12.78s-11.85-5.18-11.92-13.06c-.14-15.48,0-30.95-.05-46.42v-4.11c-1.58,0-1.64,1.2-2.09,1.94-3.68,6.07-7.27,12.19-11,18.26-5.47,9-16.79,9.09-22.25.15-4.15-6.8-8.18-13.67-12.28-20.5,0-.09-.16-.13-.39-.3-.75,1-.38,2.11-.39,3.13,0,16,.06,31.94,0,47.92-.06,9.34-7.64,15-16.1,12.29-4.32-1.4-6.73-4.54-7.76-8.81a17.41,17.41,0,0,1-.37-4.09q0-47,0-94c0-4.06,1-7.65,4.2-10.37,6-5.19,14.26-3.79,18.53,3.26C115.26,213,123,226,130.84,239c.68,1.14,1.07,2.55,2.55,3.34l25.13-41.91c3.36-5.61,8.46-7.95,14.12-6.47,5.18,1.36,8.92,6.28,8.93,12.42Q181.68,254.13,181.57,301.86Z"
					/>
					<path
						className="cls-1"
						fill={fill ?? '#242424'}
						d="M339,206.64q0,47.73,0,95.46c0,6.07-3.7,11-9,12.35-5.78,1.48-11.26-1.06-14.22-6.92-7.74-15.32-15.36-30.71-23.06-46-.53-1-.67-2.39-2.24-3.09,0,14.49,0,28.78,0,43.07,0,6.25-2.9,10.69-7.77,12.4-8.26,2.88-16.2-2.6-16.33-11.5-.15-10.72,0-21.46,0-32.19q0-31.63,0-63.27c0-7.05,3.29-11.54,9.43-13,5.86-1.35,10.93,1.2,14,7.3Q301,223.58,312.13,246a12.92,12.92,0,0,0,2.72,3.9v-3c0-13.85-.05-27.7.07-41.55A11.76,11.76,0,0,1,324,193.92a12.12,12.12,0,0,1,13.4,5.89A13.4,13.4,0,0,1,339,206.64Z"
					/>
					<path
						className="cls-1"
						fill={fill ?? '#242424'}
						d="M423.64,279c-.35,14.48-7.2,25.43-20,31.83-13.12,6.54-26.23,5.36-38.07-3.48-9.21-6.88-14.23-16.43-14.35-27.95-.25-24.47-.16-49-.07-73.42A12,12,0,0,1,363,193.63a11.64,11.64,0,0,1,12.11,11.64c.35,9.1.16,18.22.17,27.34,0,14.73,0,29.46,0,44.19,0,6.66,3.09,11.46,8.05,13,8.21,2.57,15.79-2.74,16.16-11.49.21-5,.05-10,.05-15,0-19.1,0-38.2,0-57.3,0-6.52,4.33-11.53,10.28-12.27,7.58-.94,13.68,4.31,13.77,12.17.13,12.48,0,25,0,37.45C423.7,255.24,423.93,267.11,423.64,279Z"
					/>
					<path
						className="cls-1"
						fill={fill ?? '#242424'}
						d="M254.23,302.54c.12,6.82-5.33,12-12.95,12.11-11.6.1-23.21.12-34.81,0-7.95-.08-12.75-5-12.78-12.95-.06-16,0-31.95,0-47.92q0-23.22,0-46.43c0-8.91,4.63-13.7,13.49-13.76,11.61-.08,23.21,0,34.82,0A11.62,11.62,0,0,1,253.17,201c2,4.49,1.16,8.79-1.91,12.61-2.64,3.28-6.29,4.3-10.31,4.32-6.61,0-13.23.13-19.84-.05-2.54-.06-3.46.54-3.36,3.25.22,5.86.19,11.73,0,17.59-.08,2.55.55,3.46,3.26,3.38,7-.19,14-.15,21,0a11.73,11.73,0,0,1,11.15,7.44c2.09,4.7,1.1,9-2.12,12.88-2.43,2.92-5.73,3.84-9.36,3.85-6.86,0-13.73.14-20.58,0-2.69-.07-3.37.85-3.3,3.41.16,6.11.14,12.23,0,18.34-.05,2.16.66,2.73,2.75,2.7,6.86-.12,13.73-.08,20.59,0C248.65,290.63,254.12,295.64,254.23,302.54Z"
					/>
				</g>
			</g>
		</svg>
	);
};
