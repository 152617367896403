import {
	DashboardIcon,
	RollsIcon,
	LiveIcon,
	TodoCheckIcon,
	HomeIcon,
	RewardsIcon,
	InAppAdvertisingIcon,
	ManageMenuIcon,
	OrderHistoryIcon,
	SettingIcon
} from '../../Components/Icons';
import PremiumFeaturesIcon from '../../Components/Icons/SidebarIcons/PremiumFeaturesIcon';
import ReportsIcon from '../../Components/Icons/SidebarIcons/ReportsIcon';
import { AdminPanelRoutes, VenuePanelRoutes } from '../routes';

export const sidebarAdminData = [
	{
		name: 'Live',
		icon: <LiveIcon height={32} width={32} />,
		routePath: AdminPanelRoutes.live
	},
	{
		name: 'Dashboard',
		iconClass: 'fas fa-tachometer-alt',
		icon: <DashboardIcon height={32} width={32} />,
		routePath: AdminPanelRoutes.dashboard
	},
	{
		name: 'To Do List',
		icon: <TodoCheckIcon height={32} width={32} />,
		routePath: AdminPanelRoutes.todo
	},
	{
		name: 'Roles & Permissions',
		iconClass: 'fa fa-users',
		icon: <RollsIcon height={32} width={32} />,
		routePath: AdminPanelRoutes.rolePermission
	}
];

export const sidebarVenueData = [
	{
		name: 'Home',
		icon: (
			<span className="pa-2">
				<HomeIcon height={24} width={24} />
			</span>
		),
		routePath: VenuePanelRoutes.home
	},
	{
		name: 'Venue Live Dashboard',
		icon: <DashboardIcon height={28} width={28} />,
		routePath: VenuePanelRoutes.dashboard
	},
	{
		name: 'Manage Menu',
		icon: <ManageMenuIcon height={28} width={28} />,
		routePath: VenuePanelRoutes.manageMenu
	},
	{
		name: 'Order History',
		icon: <OrderHistoryIcon height={28} width={28} />,
		routePath: VenuePanelRoutes.orderHistory
	},
	{
		name: 'Reports',
		icon: <ReportsIcon height={28} width={28} />,
		routePath: VenuePanelRoutes.financialReport,
		child: [
			{
				name: 'Stripe Financial Report',
				routePath: VenuePanelRoutes.financialReport
			},
			{
				name: 'Item Summary Report',
				routePath: VenuePanelRoutes.summaryReport
			},
			{
				name: 'MyTab Venue Sales Analytics',
				routePath: VenuePanelRoutes.salesAnalytics
			},
			{
				name: 'MyTab Venue Performance Analytics',
				routePath: VenuePanelRoutes.performanceAnalytics
			},
			{
				name: 'MyTab Customer Analytics',
				routePath: VenuePanelRoutes.customerAnalytics
			}
		]
	},
	// {
	// 	name: 'In App Advertising',
	// 	icon: <InAppAdvertisingIcon height={28} width={28} />,
	// 	routePath: '/venue/advertising'
	// },
	{
		name: 'Rewards',
		icon: <RewardsIcon height={28} width={28} />,
		routePath: '/venue/rewards'
	},
	{
		name: 'MyTab Premium+ Features',
		icon: <PremiumFeaturesIcon height={28} width={28} />,
		routePath: '/venue/premium-features/docket',
		child: [
			{
				name: 'Docket Printing',
				routePath: '/venue/premium-features/docket/features'
			},
			{
				name: 'POS Integration',
				routePath: '/venue/premium-features/pos'
			}
		]
	},
	{
		name: 'Account Settings',
		icon: <SettingIcon height={28} width={28} />,
		routePath: VenuePanelRoutes.settings,
		child: [
			{
				name: 'Venue Profile',
				routePath: VenuePanelRoutes.venueProfile
			},
			{
				name: 'Venue Settings',
				routePath: VenuePanelRoutes.settings
			},
			{
				name: 'Calender',
				routePath: VenuePanelRoutes.calender
			},
			{
				name: 'Security',
				routePath: VenuePanelRoutes.venueSecurity
			},
			{
				name: 'Legal',
				routePath: VenuePanelRoutes.legal
			}
		]
	}
];
