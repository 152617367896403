import styled from 'styled-components';
import { backgroundImage } from '../Helper/constant';
import LandingBackground from '../Assets/images/landing-background.png';
import LandingBackgroundMobile from '../Assets/images/mobile-landing-background.png';

const GlobalWrapper = styled.div`
	background-image: url(${LandingBackground});
	background-size: 100% 100% !important;

	.main-panel {
		background-color: #fffdfd;
	}
	.main-body {
		height: calc(100vh - 51px);
		background-color: #fffdfd;
		.main-body-overlay {
			display: ${(props) => (props.mini == true ? 'none' : 'block')};
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background-color: ${(props) =>
				props.mini == true ? '' : 'rgba(0, 0, 0, 0.4)'};
			z-index: 109;
		}
	}
	.layout-footer {
		height: 51px;
		z-index: 99;
	}
	.home-layout {
		.header {
			padding-inline: 40px;
			// padding-block: 8px;
			padding-block: 26px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 30px;
			background-color: #fff;

			@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
				padding-inline: 24px;
			}
		}
		.logo {
			user-select: none;
			width: 211px;
			height: 68px;
			cursor: pointer;
			// width: 118.6875px !important;
			// height: 38.25px !important;
		}
		.supportBtn {
			// width: 98.4375px;
			width: 175px;
			height: 56px;
			font-family: 'montserrat-medium';
			// font-size: 10.125px;
			font-size: 18px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			user-select: none;
		}
		.advertisingBtn {
			// width: 98.4375px;
			width: 175px;
			// height: 31.5px;
			height: 56px;
			font-family: 'montserrat-medium';
			// font-size: 10.125px;
			font-size: 18px;
			border: 1px solid #313132;
			border-radius: 2px;
			display: flex;
			user-select: none;
			cursor: pointer;
			justify-content: center;
			align-items: center;
		}
		@media only screen and (min-width: 1600.98px) and (max-width: 2025.98px) {
			.container {
				min-width: 1450px !important;
			}
		}
		@media only screen and (min-width: 961px) and (max-width: 1824px) {
			.header {
				padding-block: 1px;
			}
			.logo {
				width: 118.6875px !important;
				height: 38.25px !important;
			}
			.supportBtn {
				width: 98.4375px;
				font-size: 10.125px;
			}
			.advertisingBtn {
				width: 98.4375px;
				height: 35.5px;
				font-size: 10.125px;
			}
		}

		@media only screen and (max-width: 600px) {
			.logo {
				width: 95px;
				height: auto;
			}
			.header {
				padding-inline: 20px;
				padding-block: 10px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 10px;
				background-color: #fff;
			}
			.supportBtn,
			.advertisingBtn {
				height: 29px;
				width: 100px;
				font-size: 12px;
			}
		}
	}
	@media only screen and (max-width: 600px) {
		background: url(${LandingBackgroundMobile});
		background-size: 100% 100% !important;
		background-repeat: no-repeat;
		background-position-y: 0px !important;
	}

	@media only screen and (min-width: 600px) and (max-width: 960px) {
		background: url(${LandingBackgroundMobile});
		background-size: cover !important;
		background-repeat: no-repeat;
		background-position-y: 0px !important;

		.logo {
			width: 120px !important;
			height: auto !important;
		}

		.header {
			padding-inline: 30px !important;
			padding-block: 10px !important;
		}
	}

	@media only screen and (min-width: 961px) and (max-width: 1824px) {
		background-position-y: -20px;
		background-size: 100% 103% !important;
	}

	.react-strap-doc {
		table,
		.form-control-plaintext {
			color: ${(props) => props.layoutTheme.textColor};
		}
		.badges-heading-block {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				color: ${(props) => props.layoutTheme.headingColor} !important;
			}
		}
		.form-control-plaintext {
			color: ${(props) => props.layoutTheme.headingColor} !important;
		}
		legend,
		.doc-title {
			color: ${(props) => props.layoutTheme.headingColor};
		}

		.doc-title {
			font-size: 16px;
			font-weight: 600;
			margin-bottom: 10px;
		}

		.doc-description {
			font-size: 14px;
			font-weight: 500;
			color: ${(props) => props.layoutTheme.textColor};
		}
	}

	.theme-color {
		color: ${(props) => props.layoutTheme.textColor};
	}

	.intro-detail {
		color: ${(props) => props.layoutTheme.textColor};
	}

	.sub-heading {
		color: ${(props) => props.layoutTheme.headingColor};
	}

	.mini-text {
		font-size: 12px !important;
		font-weight: 400;
		margin-top: 10px;
		color: ${(props) => props.layoutTheme.textColor};
	}

	.global-hash-title {
		color: ${(props) => props.layoutTheme.headingColor};
	}

	.checkbox-text {
		label {
			color: ${(props) => props.layoutTheme.textColor};
		}
	}

	.custom-react-table-theme-class,
	.calender-back-class {
		background-color: ${(props) => props.layoutTheme.cardBackground};
		color: ${(props) => props.layoutTheme.textColor};
		.pagination-bottom {
			button {
				color: ${(props) => props.layoutTheme.textColor};
			}
		}
	}

	.Page-title {
		.title {
			color: ${(props) =>
				(props.sidebarTheme.themeName === 'themedefault' ||
					props.sidebarTheme.themeName === 'theme2' ||
					props.sidebarTheme.themeName === 'theme4') &&
				props.layoutTheme.themeName === 'theme6' &&
				props.layoutTheme.headingColor} !important;
		}
	}

	.Profile-component {
		// color: ${(props) => props.layoutTheme.textColor};
		.profile-header-panel {
			color: ${(props) => props.layoutTheme.headingColor};
		}
		.profile-right-shade {
			background-color: ${(props) => props.layoutTheme.cardBackground};
			.feed-card,
			.work-body {
				background-color: white;
			}
		}
	}

	.route-height {
		// min-height: calc(100vh - 116px);
		height: ${(props) =>
			props.themeSetting.toolbarDisplayValue === 'hide' &&
			props.themeSetting.footerDisplayValue === 'hide'
				? '100vh'
				: props.themeSetting.toolbarDisplayValue === 'hide'
				? 'calc(100vh - 51px) !important'
				: props.themeSetting.footerDisplayValue === 'hide'
				? 'calc(100vh - 65px) !important'
				: 'calc(100vh - 130px) !important'};
		/* padding: ${(props) =>
			props.fullpage ? '0px !important' : '24px'}; */
		@media only screen and (max-width: 575.98px) {
			height: calc(100vh - 115px) !important;
			/* padding: ${(props) =>
				props.themeSetting.toolbarDisplayValue !== 'show'
					? '0'
					: '0'}; */
		}
	}

	.map-block {
		height: calc(100vh - 300px);
		width: 100%;
	}

	.map-marker-icon {
		font-size: 40px;
		position: absolute;
		color: #161b1d;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}

	.border-top-radius {
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
	}

	.border-bottom-radius {
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
	}

	.roe-box-shadow {
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
		border-radius: 6px;
	}

	.roe-card-style {
		// box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
		background-color: ${(props) => props.layoutTheme.cardBackground};
		border-radius: 6px;
		label {
			color: ${(props) => props.layoutTheme.textColor};
		}

		.roe-card-header {
			padding: 15px 24px;
			font-weight: 600;
			font-size: 18px;
			color: ${(props) => props.layoutTheme.headingColor};
			.hash {
				color: ${(props) => props.sidebarTheme.activeColor};
			}
		}

		.roe-card-body {
			padding: 0 24px 24px;
			.prism-code {
				margin-bottom: 0px;
			}

			.roe-card-description {
				font-size: 15px;
				margin-bottom: 15px;
				font-weight: 500;
				color: ${(props) => props.layoutTheme.textColor};
			}
		}
	}

	.react-strap-doc {
		.custom-breadcumb {
			.breadcrumb {
				padding-left: 0px;
				font-weight: 600;
				background: transparent;
				margin-bottom: 0;
				font-size: 14px;
				text-transform: capitalize;
				padding-top: 5px;
				span {
					font-family: 'montserrat-semi-bold';
					letter-spacing: 2px;
					font-size: 15px;
				}
			}

			.breadcrumb-item + .breadcrumb-item::before {
				color: ${(props) =>
					(props.sidebarTheme.themeName === 'themedefault' ||
						props.sidebarTheme.themeName === 'theme2' ||
						props.sidebarTheme.themeName === 'theme7') &&
					props.layoutTheme.themeName === 'theme6'
						? props.layoutTheme.headingColor
						: props.sidebarTheme.activeColor} !important;
			}

			.breadcumb-color {
				color: ${(props) =>
					(props.sidebarTheme.themeName === 'themedefault' ||
						props.sidebarTheme.themeName === 'theme2' ||
						props.sidebarTheme.themeName === 'theme7') &&
					props.layoutTheme.themeName === 'theme6'
						? props.layoutTheme.headingColor
						: props.sidebarTheme.activeColor} !important;
			}
		}
	}

	.theme-choose-side-block {
		height: 180px;
		width: 80px;
		margin: 10px;
	}

	.theme-choose-header-block {
		height: 30px;
		margin: 10px;
	}

	.theme-choose-layout-block {
		height: 100px;
		margin: 10px;
	}

	.theme-choose-footer-block {
		height: 30px;
		margin: 10px;
	}

	.form-group {
		margin-bottom: 1rem;
	}

	label {
		margin-bottom: 0.5rem;
	}

	.theme-button {
		background: ${(props) => props.layoutTheme.buttonColor};
		border: 0px !important;
	}

	.headingTextColor {
		color: ${(props) => props.layoutTheme.headingColor};
	}

	.headingTextColor2 {
		color: ${(props) => props.layoutTheme.buttonColor2};
	}

	.borderButton {
		border: 1px solid ${(props) => props.layoutTheme.buttonColor2} !important;
		color: ${(props) => props.layoutTheme.buttonColor2} !important;
		background-color: #fff !important;
		font-family: 'montserrat-medium';
		font-size: 14px;
		padding-block: 14px;
		padding-inline: 47px;
	}

	.borderButtonFullWidth {
		border: 1px solid ${(props) => props.layoutTheme.buttonColor2} !important;
		color: ${(props) => props.layoutTheme.buttonColor2} !important;
		background-color: #fff !important;
		width: 100%;
		font-family: 'montserrat-medium';
		font-size: 14px;
		padding-block: 14px;
		padding-inline: 47px;
	}

	.themeButton {
		background: linear-gradient(350.67deg, #f94d73 0%, #fd6461 92.95%);
		/* background: ${(props) => props.layoutTheme.buttonColor}; */
		color: ${(props) => props.layoutTheme.buttonTextColor};
		border: 0px;
		font-family: 'montserrat-medium';
		font-size: 14px;
		padding-block: 14px;
		padding-inline: 47px;
	}

	.themeBorderButton {
		border: 1px solid ${(props) => props.layoutTheme.buttonColor2} !important;
		color: ${(props) => props.layoutTheme.buttonColor2} !important;
		background-color: #fff !important;
		font-family: 'montserrat-medium';
		font-size: 14px;
		padding-block: 14px;
		padding-inline: 47px;
	}

	.noBorderButton {
		border: 0px solid ${(props) => props.layoutTheme.buttonColor2} !important;
		color: ${(props) => props.layoutTheme.buttonColor2} !important;
		background-color: #fff !important;
		font-family: 'montserrat-medium';
		font-size: 14px;
		padding-block: 14px;
		padding-inline: 47px;
	}

	.themeButtonFullWidth {
		background: linear-gradient(350.67deg, #f94d73 0%, #fd6461 92.95%);
		/* background: ${(props) => props.layoutTheme.buttonColor}; */
		color: ${(props) => props.layoutTheme.buttonTextColor};
		border: 0px;
		width: 100%;
		font-family: 'montserrat-medium';
		font-size: 14px;
		padding-block: 14px;
		padding-inline: 47px;
	}

	.inputBox {
		background-color: ${(props) =>
			props.layoutTheme.inputBackgroundColor} !important;
		color: ${(props) => props.layoutTheme.textColor} !important;
		font-family: 'montserrat-medium' !important;
		border-radius: 6px !important;
		::placeholder {
			/* Chrome, Firefox, Opera, Safari 10.1+ */
			color: ${(props) => props.layoutTheme.placeHolderColor} !important;
			font-family: 'montserrat-medium' !important;
			font-weight: 500 !important;
			font-size: 12px !important;
			opacity: 1; /* Firefox */
		}
		&:focus {
			outline: none;
			box-shadow: none !important;
			border: 0px !important;
		}
	}

	.css-1dimb5e-singleValue {
		font-family: 'montserrat-medium' !important;
	}

	.helperText {
		color: ${(props) => props.layoutTheme.placeHolderColor} !important;
	}

	.themeText {
		color: ${(props) => props.layoutTheme.textColor} !important;
	}

	.themeRadio {
		border: 2px solid rgba(0, 0, 0, 0.25) !important;
	}

	.themeRadio:checked {
		background-image: none !important;
		background-color: ${(props) =>
			props.layoutTheme.headingColor} !important;
		border: 0px !important;
		box-shadow: none !important;
		&:active,
		&:focus {
			border: 0px !important;
		}
	}
	.themeCheckbox {
		border-radius: 2px !important;
		border: 2px solid rgba(0, 0, 0, 0.25) !important;
		float: unset !important;
		margin-left: unset !important;
		margin-top: unset !important;
		vertical-align: unset !important;
	}
	.themeRadio:active,
	.themeCheckbox:focus {
		box-shadow: none !important;
		outline: none !important;
		border-color: #00000040 !important;
	}
	.themeCheckbox:checked {
		background-color: ${(props) =>
			props.layoutTheme.headingColor} !important;
		border: 0px !important;
		box-shadow: none !important;
		&:active,
		&:focus {
			border: 0px !important;
		}
	}
	.themeCheckbox:active,
	.themeCheckbox:focus {
		box-shadow: none !important;
		outline: none !important;
		border-color: #00000040 !important;
	}

	.themeSwitch:checked {
		background-color: ${(props) =>
			props.layoutTheme.headingColor} !important;
		box-shadow: none;
		border: 0px;
		background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e') !important;
	}
	.themeSwitch:active {
		box-shadow: none !important;
		outline: none !important;
		border-color: #00000040 !important;
		background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e');
	}
	.themeSwitch:focus {
		box-shadow: none !important;
		outline: none !important;
		border-color: #00000040 !important;
		background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e');
	}
	.mb-0 {
		margin-bottom: 0px !important;
	}
	.noResize {
		resize: none;
	}
	.formGroupIcon {
		.inputGroup {
			border-radius: 6px 0px 0px 6px !important;
			background-color: #f9f9f9 !important;
		}
		.inputBoxGroup {
			border-radius: 0px 6px 6px 0px !important;
		}
		.inputGroupBackground {
			background-color: #f9f9f9 !important;
		}
	}
	.recharts-default-legend {
		margin-left: 30px !important;
	}
	.recharts-legend-item {
		font-size: 14px !important;
		font-family: 'montserrat-medium' !important;
		margin-bottom: 20px !important;
	}
	.recharts-text {
		color: #615e83 !important;
		fill: #615e83 !important;
		font-size: 14px !important;
	}
	.recharts-cartesian-grid-horizontal {
		line {
			stroke: #e5e5ef !important;
		}
	}
	hr {
		border: 1px solid #eaeaea;
		@media only screen and (max-width: 767px) {
			display: none;
		}
	}
	.horizontalRule {
		border-top: 1px solid #dddddd !important;
	}
	.activeColor {
		color: '#FF5F5F' !important;
	}
`;

export default GlobalWrapper;
