import { FormGroup, Input, Label } from 'reactstrap';

const FormRadioButton = ({
	id,
	label,
	error,
	errorMsg,
	className,
	labelClassName,
	radioPosition = 'start',
	...rest
}) => {
	return (
		<FormGroup className="mb-0">
			<div className="d-flex align-items-center" style={{ gap: '10px' }}>
				{radioPosition === 'start' && (
					<Input
						type="radio"
						className={`${error ? 'is-invalid' : ''} ${
							className ? className : ''
						} themeRadio fs-15 medium-text themeText ma-t-0`}
						{...rest}
					/>
				)}
				{label && (
					<Label
						for={id}
						className={`fs-12 regular-text themeText label-color mb-0 ${labelClassName}`}
					>
						{label}
					</Label>
				)}
				{radioPosition === 'end' && (
					<Input
						type="radio"
						className={`${error ? 'is-invalid' : ''} ${
							className ? className : ''
						} themeRadio fs-15 medium-text themeText ma-t-0`}
						{...rest}
					/>
				)}
			</div>
			{error && (
				<p className="fs-10 semi-bold-text headingTextColor pa-t-6">
					{errorMsg}
				</p>
			)}
		</FormGroup>
	);
};

export default FormRadioButton;
