import styled from 'styled-components';

const FormMobileNoInputWrapper = styled.div`
	display: flex;
	gap: 6px !important;
	.libraryInputWrapper {
		height: 100% !important;
		min-height: 40px !important;
		background-color: #f9f9f9 !important;
		color: #242424 !important;
		font-family: 'montserrat-medium' !important;
		border-radius: 6px !important;
	}
	.libraryInput {
		display: none !important;
	}
	.intl-tel-input {
		width: 102px !important;
		height: 40px !important;
	}
	.flag-container {
		position: unset !important;
		height: 100% !important;
		background-color: transparent !important;
	}
	.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
		width: 100%;
		background-color: transparent !important;
		padding-inline: 12px !important;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		.selected-dial-code {
			color: #242424 !important;
			font-family: 'montserrat-medium' !important;
			font-size: 12px;
			display: block;
			vertical-align: unset;
			padding-left: 6px !important;
			pointer-events: none;
		}
	}
	.intl-tel-input .flag-container .arrow {
		font-size: 16px !important;
		margin-left: 0 !important;
		flex: 1;
		display: flex;
		justify-content: flex-end;
		pointer-events: none;
	}
	.country-list {
		.country-name,
		.dial-code {
			font-family: 'montserrat-medium' !important;
			font-size: 12px !important;
		}
		border: 1px solid #eaeaea !important;
		box-shadow: unset !important;
		border-radius: 6px !important;
		margin-top: 4px !important;
		/* Customize the scrollbar */
		scrollbar-width: thin; /* For Firefox */
		scrollbar-color: rgba(0, 0, 0, 0.2) #fff; /* For Firefox */
		/* Customize the scrollbar for Webkit browsers (Chrome, Safari, etc.) */
		::-webkit-scrollbar {
			width: 2px;
		}
		::-webkit-scrollbar-thumb {
			background-color: rgba(0, 0, 0, 0.2);
			border-radius: 3px !important;
		}
		::-webkit-scrollbar-track {
			background-color: #fff;
			border-radius: 18px !important;
		}
	}
`;

export default FormMobileNoInputWrapper;
