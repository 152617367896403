import styled from 'styled-components';

const PageWrapper = styled.div`
	color: ${(props) => props.layoutTheme.textColor};
	@media only screen and (min-height: 900px) {
		height: 100% !important;
	}
	@media only screen and (max-width: 767px) {
		padding: 0px;
		.border-radius-16 {
			border-radius: 0px !important;
		}
	}
	.dot {
		width: 14px;
		height: 14px;
		background: #242424;
	}
	.defaultBoxShadow {
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
	}
	.buttonGroup {
		margin: 20px;
		background: #ffefef;
		padding: 5px;
		border-radius: 25px;
		width: 421px;
		cursor: pointer;
		color: ${(props) => props.layoutTheme.textColor};
		.buttonType {
			margin: 2px;
			padding: 5px;
			text-align: center;
			span {
				@media only screen and (max-width: 575.98px) {
					font-size: 10px !important;
				}
			}
		}
		.active {
			transition: all 500ms ease-in-out;
			color: #ffffff;
			z-index: 2;
			svg path {
				fill: #fff;
				transition: all 500ms ease-in-out;
			}
		}
		.activeBackground {
			background: #ff6460;
			border-radius: 25px;
			width: 50%;
			height: 90%;
			margin-left: 2px;
			z-index: 1;
			transform: translateX(0%);
			transition: all 500ms ease-in-out;
			&.active {
				transform: translateX(100%);
				margin-left: -0px;
				margin-left: -2px;
			}
		}
	}
	.dashboard-dropdown {
		.activeColor {
			color: ${(props) => props.layoutTheme.buttonColor3} !important;
		}
		.dropdown-item,
		.dropdownToggle {
			outline: 0 !important;
			border: 0px !important;
			padding-left: 10px;
			padding-right: 10px;
			padding-top: 10px;
			&.active {
				background-color: ${(props) =>
					props.layoutTheme.buttonColor3} !important;
			}
			@media only screen and (max-width: 575.98px) {
				padding-top: 5px;
				margin-top: 6px;
				margin-bottom: 6px;
			}
			.dropdown-name {
				padding-right: 5px;
				color: ${(props) => props.layoutTheme.textColor};
				@media only screen and (max-width: 575.98px) {
					font-size: 12px !important;
				}
			}
			&:hover {
				outline: 0 !important;
				border: 0px !important;
			}
			&:active {
				outline: 0 !important;
				border: 0px !important;
				background-color: #fff !important;
				color: ${(props) => props.layoutTheme.textColor};
			}
		}
		.dropdown-menu {
			border: 0px !important;
			@media only screen and (max-width: 360px) {
			}
		}
	}
	.dashboard-card {
		border: 1px solid #f4f4f4;
		border-radius: 12px;
		padding: 14px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.dashboard-total-card {
		border: 1px solid #ffdcd8;
		border-radius: 12px;
		padding: 10px;
		background-color: #ffdcd8;
		.count {
			color: #ff6460;
		}
	}
	.analytics-card {
		background: #ffffff;
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
		border-radius: 16px;
	}
	.analytics-card .col-md-6,
	.analytics-card .col-md-12 {
		padding: 0px 5px;
		@media only screen and (max-width: 575.98px) {
			padding: 5px;
		}
	}
	hr {
		color: #eaeaea;
	}
	.dropdown-menu {
		background: #ffffff;
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
		border-radius: 8px;
	}
	.dropdownCity {
		.dropdown-menu {
			width: 300px;
			left: -200px !important;
			@media only screen and (max-width: 575.98px) {
				width: 300px;
				left: -10px !important;
			}
		}
		.dropdown-item {
			background-color: #fff !important;
		}
	}
	.css-1xc3v61-indicatorContainer {
		&:hover,
		&:focus,
		&:active {
			color: #000 !important;
		}
		color: #000 !important;
	}

	.dashboard-card {
		.flex-1 {
			white-space: pre;
		}
	}
	.dashboard-total-card {
		p {
			&:last-child {
				white-space: pre;
			}
		}
	}
	.globParent {
		position: relative;
		z-index: 1;
	}
	.globePopup {
		position: absolute;
		z-index: 200;
		background: rgb(255, 255, 255, 1);
		bottom: calc(50% - 40px);
		right: calc(50% - 80px);
		border-radius: 16px;
		border: 1px solid #f87171;
	}
	.globePopupBody {
		z-index: 20;
		color: #f87171;
	}
	.themeBorderBottom {
		border-bottom: 1px solid #f4f4f4;
	}
	.activity-card {
		border: 1px solid #f4f4f4;
		border-radius: 12px;
		padding: 10px;
		min-height: 100px;
	}
	.totalCard {
		border: 1px solid #f4f4f4;
		border-radius: 12px;
		padding: 0px 20px;
		.totalCardIcon {
			height: 48px;
			width: 48px;
			background-color: #feeded;
			border-radius: 50%;
		}
	}
	.totalCardDot {
		width: 10px;
		height: 10px;
		background: #ff9568;
		border-radius: 50%;
		margin-right: 10px;
	}
	.totalTabVenue {
		.totalCardTitle {
			display: flex;
			justify-content: end;
		}
	}
`;

export default PageWrapper;
