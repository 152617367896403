import React, { useEffect, useRef, useState } from 'react';
import Globe from 'react-globe.gl';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

import GlobeImage from '../../Assets/images/globe2.png';
import { places } from './places';
export const GlobeComponent = ({ height, width }) => {
	const globeEl = useRef(null);
	const [data, setData] = useState(null);
	const [view, setView] = useState(false);

	useEffect(() => {
		const globe = globeEl.current;
		globe.pointOfView({ lat: -25.274398, lng: 133.775136 }, 100);
		globe.controls().object.zoom = 1.4;
		globe.controls().waitForGlobeReady = true;
		globe.controls().enableZoom = false;
		// globe.controls().autoRotate = true;
		// globe.controls().autoRotateSpeed = 2;
	}, []);

	const markerSvg = `
    <svg viewBox="-4 0 36 36">
      <path fill="currentColor" d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z" />
      <circle fill="white" cx="14" cy="14" r="7" />
    </svg>
  `;

	// simple component usage
	return (
		<div className="globParent">
			{!!view && <ViewDetails data={data} handleView={setView} />}
			<Globe
				width={width}
				height={height}
				backgroundColor={'#FFF'}
				showAtmosphere={false}
				animateIn={true}
				waitForGlobeReady={true}
				ref={globeEl}
				globeImageUrl={GlobeImage}
				htmlElementsData={places}
				htmlElement={(d) => {
					const el = document.createElement('div');
					el.innerHTML = markerSvg;
					el.style.color = d.color;
					el.style.width = `${d.size}px`;
					el.style.zIndex = `20`;
					el.style['pointer-events'] = 'auto';
					el.style.cursor = 'pointer';
					el.onclick = () => {
						setData(d);
						setView(true);
					};
					return el;
				}}
				// htmlLat={(d) => d.lat}
				// htmlLng={(d) => d.lng}
				// htmlTransitionDuration={1000}
				// labelLat={(d) => d.lat}
				// labelLng={(d) => d.lng}
				// labelsData={places}
				// labelText={(d) => d.name}
				// labelSize={(d) => 0.2 + d.size}
				// labelDotRadius={(d) => 0.5 + d.size}
				// labelColor={() => "#f87171"} //"rgba(255, 165, 0, 0.75)"}
				// labelResolution={2}
				// pointLabel={"city"}
				// enablePointerInteraction={true}
				// pointsMerge={false}
				// onLabelClick={(data) => {
				//   setData(data);
				//   setView(true);
				// }}
				// onGlobeClick={(data) => {
				//   setData(null);
				//   setView(false);
				// }}
				// atmosphereColor="lightskyblue"
			/>
		</div>
	);
};

const ViewDetails = ({ data, handleView }) => {
	return (
		<div className="globePopup" onClick={() => handleView(false)}>
			<div className="globePopupBody plr-10">
				<p>Name: {data?.name}</p>
				<p>Latitude: {data?.lat.toFixed(2)}</p>
				<p>Longitude: {data?.lng.toFixed(2)}</p>
			</div>
		</div>
	);
};
