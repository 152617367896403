import styled from 'styled-components';

const CommonAgreementLine = styled.div`
	text-align: center;
	padding-bottom: 10px;
	font-size: 20px;
	font-family: 'montserrat-semi-bold' !important;
	color: #000000;
	.policyTextTermsConditions,
	.policyTextPrivacy {
		cursor: pointer;
		color: #f95c69;
		font-family: 'montserrat-semi-bold' !important;
	}

	@media (max-width: 600px) {
		font-size: 11.5px;
	}

	@media only screen and (min-width: 600px) and (max-width: 960px) {
		font-size: 15px;
	}

	@media screen and (max-height: 933px) {
		font-size: 11.25px;
	}
	@media only screen and (min-width: 961px) and (max-width: 1824px) {
		font-size: 11.25px;
	}
`;

export default CommonAgreementLine;
