import { useSelector } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';

import PageWrapper from './register.style';
import RegisterForm from '../../Components/Authentication/RegisterForm';
import createAccountImage from '../../Assets/images/create-account-side-image.png';

export const Register = (props) => {
	const allthemeData = useSelector((state) => ({ ...state.themeChanger }));

	return (
		<PageWrapper sectionImage={createAccountImage}>
			<div className="formWrapper pl-26">
				<Scrollbars autoHide>
					<RegisterForm {...props} />
				</Scrollbars>
			</div>
			<div className="imageWrapper">
				<div className="backWrraper">
					<p className="text">
						Managing made <br />
						easy
					</p>
					<img
						src={createAccountImage}
						alt="side-img"
						className="image"
					/>
				</div>
			</div>
		</PageWrapper>
	);
};
