import { useEffect, useState } from 'react';
import moment from 'moment';

import NewSwitch from '../../Common/Switch/NewSwitch';
import { Col, Row } from 'reactstrap';
import NewCustomTimePicker from '../../Common/DateRangePicker/NewCustomTimePicker';

const NewOpeningHoursCard = ({
	weekDay,
	activeHours,
	inActiveHours,
	isClosed,
	id,
	handleModalData,
	hideBorder
}) => {
	let today = new Date();
	let date =
		today.getFullYear() +
		'/' +
		parseInt(today.getMonth() + 1) +
		'/' +
		today.getDate();

	const [updateButton, setUpdateButton] = useState(false);
	const [data, setData] = useState({
		id: id,
		weekDay: weekDay,
		activeHours:
			activeHours !== null ? new Date(date + ' ' + activeHours) : null,
		inActiveHours:
			inActiveHours !== null
				? new Date(date + ' ' + inActiveHours)
				: null,
		isClosed: isClosed
	});
	const handleStartDateChange = (date) => {
		setUpdateButton(true);
		setData((prevData) => ({
			...prevData,
			activeHours: date
		}));
	};
	const handleEndDateChange = (date) => {
		setUpdateButton(true);
		setData((prevData) => ({
			...prevData,
			inActiveHours: date
		}));
	};
	const handleSwitchChange = (event) => {
		setUpdateButton(true);
		setData((prevData) => ({
			...prevData,
			isClosed: !prevData.isClosed
		}));
	};

	useEffect(() => {
		const updatedData = {
			id: data?.id,
			weekDay: data?.weekDay,
			activeHours: moment(data?.activeHours).format('HH:mm'),
			inActiveHours: moment(data?.inActiveHours).format('HH:mm'),
			isClosed: data?.isClosed
		};
		handleModalData(updatedData);
	}, [data]);

	return (
		<Row
			className={`tableRows ${
				hideBorder ? `border-bottom-0` : ``
			} align-items-center `}
		>
			<Col sm={3} xs={12} xl={3}>
				<text className="dayText">
					{moment()
						.day(weekDay + 1)
						.format('dddd')}
				</text>
			</Col>
			<Col sm={3} xs={4} xl={3}>
				<NewCustomTimePicker
					date={data?.activeHours}
					handleDateChange={handleStartDateChange}
					customClassName=""
					timeIntervals={30}
				/>
			</Col>
			<Col sm={3} xs={4} xl={3}>
				<NewCustomTimePicker
					date={data?.inActiveHours}
					handleDateChange={handleEndDateChange}
					customClassName="placeholder-center w-100"
					timeIntervals={30}
				/>
			</Col>

			<Col
				sm={3}
				xs={4}
				xl={3}
				lg={2}
				className="d-flex justify-content-center"
			>
				<NewSwitch
					checked={!data?.isClosed}
					onChange={handleSwitchChange}
				/>
			</Col>
		</Row>
	);
};

export default NewOpeningHoursCard;
