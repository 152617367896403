import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';

import PageWrapper from './index.style';
import PageStructure from '../../../Components/Common/PageStructure';

export const FinancialReport = () => {
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));

	return (
		<PageStructure title="Stripe Financial Report">
			<PageWrapper {...allThemeData}>
				<div>
					<p className={`fs-12 regular-text text-left pa-b-12`}>
						MyTab partners with Stripe for secure payments.
					</p>
					<p className={`fs-12 regular-text text-left pa-b-12`}>
						Login to your Stripe account via your mobile, tablet or
						desktop to view your financial transactions, balances
						and upcoming payouts easily on your Stripe connect
						dashboard. You can customise your dashboard to see
						strategic comparable data, export your financial
						transactions and integrate your Stripe account with
						available accounting platforms (please contact Stripe to
						see the current accounting integrations available to
						your account).
					</p>
					<p className={`fs-12 regular-text text-left pa-b-12`}>
						Additionally, you can control your payout timing via
						your Stripe settings to be paid daily, weekly, monthly
						or manually.
					</p>
					<p className={`fs-12 regular-text text-left`}>
						If you have any questions in relation to your Stripe
						account, please contact Stripe{' '}
						<span
							className={`fs-12 medium-text headingTextColor text-decoration-underline cursor-pointer`}
							onClick={() => {
								window.open(
									'https://support.stripe.com/',
									'_blank'
								);
							}}
						>
							here
						</span>
						. Thank you.
					</p>
				</div>
				<div className="pa-t-24">
					<Button
						className={`themeButton`}
						type="button"
						onClick={() => {
							window.open(
								'https://dashboard.stripe.com/login',
								'_blank'
							);
						}}
					>
						Login to Stripe Account
					</Button>
				</div>
				<p className="pa-t-24 fs-11 regular-text text-left">
					Tap the button above or visit{' '}
					<a
						href="https://dashboard.stripe.com/login"
						className="headingTextColor fs-11 medium-text"
						target="_blank"
					>
						https://dashboard.stripe.com/login
					</a>{' '}
					to access your Stripe account.
				</p>
			</PageWrapper>
		</PageStructure>
	);
};
