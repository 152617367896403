import * as yup from 'yup';
import { PASSWORD } from './constant';

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
const FILE_SIZE = 20 * 1024 * 1024;

export const YUP_VALIDATION = {
	EMAIL: yup
		.string()
		.email('Invalid email.')
		.required('Please enter your email.'),

	IMAGE: yup
		.mixed()
		.nullable()
		// .required('Please upload your image.')
		.test(
			'FILE_FORMAT',
			'Image must be in jpg, jpeg or png format.',
			(value) => {
				if (typeof value === 'string') {
					return true;
				}
				return (
					!value || (value && SUPPORTED_FORMATS.includes(value.type))
				);
			}
		)
		.test('FILE_SIZE', 'Image size must be less than 20Mb.', (value) => {
			if (typeof value === 'string') {
				return true;
			}
			return !value || (value && value.size <= FILE_SIZE);
		}),

	OLD_PASSWORD: yup.string().required('Please enter your password.'),
	PASSWORD: yup
		.string()
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9~!@#$%^&*()_\-+=<>?]).{8,}$/,
			'Invalid Password'
		)
		.min(
			PASSWORD.MIN,
			`Password should be minimum ${PASSWORD.MIN} characters.`
		)
		// .max(
		// 	PASSWORD.MAX,
		// 	`Password should not be more than ${PASSWORD.MAX} characters.`
		// )
		.required('Please enter your password.'),

	NORMAL_PASSWORD: yup
		.string()
		.min(
			PASSWORD.MIN,
			`Password should be minimum ${PASSWORD.MIN} characters.`
		)
		// .max(
		// 	PASSWORD.MAX,
		// 	`Password should not be more than ${PASSWORD.MAX} characters.`
		// )
		.required('Please enter your password.'),

	CURRENT_PASSWORD: yup
		.string()
		.min(
			PASSWORD.MIN,
			`Password should be minimum ${PASSWORD.MIN} characters.`
		)
		.max(
			PASSWORD.MAX,
			`Password should not be more than ${PASSWORD.MAX} characters.`
		)
		.required('Please enter your current password.'),
	NEW_PASSWORD: yup
		.string()
		.min(
			PASSWORD.MIN,
			`Password should be minimum ${PASSWORD.MIN} characters.`
		)
		.max(
			PASSWORD.MAX,
			`Password should not be more than ${PASSWORD.MAX} characters.`
		)
		.required('Please enter your new password.'),
	RE_ENTER_NEW_PASSWORD: yup
		.string()
		.oneOf([yup.ref('newPassword'), null], 'Password must be same.')
		.required('Please re-enter your new password.'),

	LOGIN_CONFIRM_PASSWORD: yup
		.string()
		.oneOf([yup.ref('password'), null], 'Confirm password must be same.')
		.required('Please confirm your password.'),

	OTP: yup
		.string()
		.trim()
		.required('Please enter your OTP.')
		.matches(/^[0-9]+$/, 'OTP must be only digits.')
		.min(6, 'OTP must be exactly 6 digits.')
		.max(6, 'OTP must be exactly 6 digits.'),

	CONFIRM_PASSWORD: yup
		.string()
		.oneOf([yup.ref('newPassword'), null], 'Confirm password must be same.')
		.required('Please confirm your password.'),

	RESTAURANT_NAME: yup.string().required('Please enter venue name.'),
	VENUE_NAME: yup
		.string()
		.min(4, 'Venue name should be minimum 4 characters.')
		.max(50, 'Venue name should not be more than 50 characters.')
		.required('Please enter venue name.'),
	OWNER_MANAGER_FIRST_NAME: yup.string().required('Please enter first name.'),
	OWNER_MANAGER_LAST_NAME: yup.string().required('Please enter last name.'),
	OWNER_MANAGER_NAME: yup
		.string()
		.required('Please enter Owner/Manager name.'),
	RESTAURANT_NUMBER: yup.string().required('Please enter ABN/ACN number.'),
	ABN_ACN_NUMBER: yup
		.number()
		.typeError('Please enter a numerical value for your ABN/ACN.')
		.required('Please enter ABN/ACN number.'),
	IS_ALCOHOL: yup.string().required('Please select any one of above option.'),
	LICENSE_NUMBER: yup
		.string()
		.typeError('License number must be in string.')
		.required('Please enter license number.'),
	SERVICE_TYPE: yup.array().min(1, 'Please select service type.'),
	FULL_NAME: yup
		.string()
		.min(4, 'Full name should be minimum 4 characters.')
		.max(50, 'Full name should not be more than 50 characters.')
		.matches(/^[aA-zZ\s]+$/, 'Only alphabetic values are allowed.')
		.required('Please enter your full name.'),

	NAME: yup
		.string()
		.matches(/^[aA-zZ\s]+$/, 'Only alphabetic values are allowed.')
		.required('Please enter your name.'),

	MANAGER_NAME: yup
		.string()
		.matches(/^[aA-zZ\s]+$/, 'Only alphabetic values are allowed.')
		.required('Please enter manger name.'),

	ACCEPT_TERMS: yup
		.bool()
		.oneOf([true], 'Please agree to terms & conditions and privacy policy'),

	FIRST_NAME: yup
		.string()
		.matches(/^[aA-zZ\s]+$/, 'Only alphabetic values are allowed.')
		.required('Please enter your first name.')
		.typeError('First name must be string.'),

	LAST_NAME: yup
		.string()
		.matches(/^[aA-zZ\s]+$/, 'Only alphabetic values are allowed.')
		.required('Please enter your last name.')
		.typeError('Last name must be string.'),

	MOBILE_NUMBER: yup
		.number()
		.required('Please enter your mobile number.')
		.min(1111111, 'Mobile number must be between 7-15 digits.')
		.max(999999999999999, 'Mobile number must be between 7-15 digits.')
		.typeError('Mobile number must be in number only.'),

	CURRENT_MOBILE_NUMBER: yup
		.number()
		.required('Please enter your current mobile number.')
		.min(1111111, 'Mobile number must be between 7-15 digits.')
		.max(999999999999999, 'Mobile number must be between 7-15 digits.')
		.typeError('Mobile number must be in number only.'),

	NEW_MOBILE_NUMBER: yup
		.number()
		.required('Please enter your new mobile number.')
		.min(1111111, 'Mobile number must be between 7-15 digits.')
		.max(999999999999999, 'Mobile number must be between 7-15 digits.')
		.typeError('Mobile number must be in number only.'),

	COUNTRY_CODE: yup.string().required('Please select country.'),

	DATE: {
		DOB: yup.date().required('Please enter your Date of birth.')
	},

	ADDRESS: yup.string().required('Please enter your address.'),

	MESSAGE: yup.string().required('Please enter your message.'),

	DESCRIPTION: yup.string().required('Please enter your description.'),

	TNC: yup.bool().oneOf([true], 'You must accept the terms and conditions.'),

	ROLE_NAME: yup.string().required('Please enter your role name.'),

	PRONOUNS: yup.string().typeError('Pronouns must be in string.'),

	BUSINESS_REGISTER_ID: yup
		.string()
		.required('Please enter your ABN/ACN number.'),
	CATEGORY: yup.string().required('Please Select Category.'),
	ITEM_NAME: yup.string().required('Please Enter Item Name.'),
	CALORIE: yup
		.number()
		.typeError('Calorie amount must be in number.')
		.positive('Calorie amount must be more than 0.')
		.required('Please enter Calorie amount.'),
	BASE_PRICE: yup
		.number()
		.typeError('Base price must be in number.')
		.positive('Base price must be more than 0.')
		.required('Please enter base price.'),
	PICKUP_LOCATION: yup.string().required('Please Select Pickup Location.'),
	STOCK_QUANTITY: yup
		.number()
		.integer('Stock Quantity can not be decimal value.')
		.typeError('Stock Quantity must be in number.')
		.min(0, 'Stock Quantity must be greater than or equal to 0.'),
	ADD_PRODUCT_SERVICE_TYPE: yup.array().min(1, 'Please select service type.'),
	REQUIRED_OPTIONS: yup.array().of(
		yup.object().shape({
			data: yup.array().of(
				yup.object().shape({
					itemName: yup
						.string()
						.required('Please enter extra item name.'),
					price: yup
						.number()
						.typeError('Price must be in number.')
						.min(0, 'Price must be more than or equal to 0.')
						.required('Please enter price.')
				})
			)
		})
	),
	ADDITIONAL_EXTRAS: yup.array().of(
		yup.object().shape({
			itemName: yup.string().required('Please enter extra item name.'),
			price: yup
				.number()
				.typeError('Price must be in number.')
				.min(0, 'Price must be more than or equal to 0.')
				.required('Please enter price.')
		})
	),
	PICKUP_LOCATION_NAME: yup
		.string()
		.required('Please enter pickup location.'),
	PROMO_CODE_NAME: yup.string().required('Please enter promo code name.'),
	PROMO_CODE: yup.string().required('Please enter promo code.'),
	PROMO_CODE_DISCOUNTS: yup
		.number()
		.max(99, 'Discount amount must be less than or equal to 99.')
		.required('Please enter discount amount.'),
	PROMO_CODE_START_DATE: yup.string().required('Please select start date.'),
	PROMO_CODE_DESCRIPTION: yup.string().required('Please enter description.'),
	TAX_NAME: yup.string().required('Please enter tax name.'),
	TAX_PERCENTAGE: yup
		.number()
		.positive('Tax percentage must be more than 0.')
		.required('Please enter tax percentage.'),
	EXISTING_PASSCODE_4: yup
		.string()
		.required('Please enter existing passcode.')
		.matches(/^[0-9]+$/, 'Passcode must be only digits.')
		.min(4, 'Passcode must be exactly 4 digits.')
		.max(4, 'Passcode must be exactly 4 digits.'),
	EXISTING_PASSCODE_6: yup
		.string()
		.required('Please enter existing passcode.')
		.matches(/^[0-9]+$/, 'Passcode must be only digits.')
		.min(6, 'Passcode must be exactly 6 digits.')
		.max(6, 'Passcode must be exactly 6 digits.'),
	NEW_PASSCODE_4: yup
		.string()
		.required('Please enter new passcode.')
		.matches(/^[0-9]+$/, 'Passcode must be only digits.')
		.min(4, 'Passcode must be exactly 4 digits.')
		.max(4, 'Passcode must be exactly 4 digits.'),
	NEW_PASSCODE_6: yup
		.string()
		.required('Please enter new passcode.')
		.matches(/^[0-9]+$/, 'Passcode must be only digits.')
		.min(6, 'Passcode must be exactly 6 digits.')
		.max(6, 'Passcode must be exactly 6 digits.'),
	CONFIRM_PASSCODE: yup
		.string()
		.oneOf([yup.ref('newPasscode'), null], 'Confirm passcode must be same.')
		.required('Please confirm your passcode.'),
	PASSCODE_4: yup
		.string()
		.required('Please enter passcode.')
		.matches(/^[0-9]+$/, 'Passcode must be only digits.')
		.min(4, 'Passcode must be exactly 4 digits.')
		.max(4, 'Passcode must be exactly 4 digits.'),
	PASSCODE_6: yup
		.string()
		.required('Please enter passcode.')
		.matches(/^[0-9]+$/, 'Passcode must be only digits.')
		.min(6, 'Passcode must be exactly 6 digits.')
		.max(6, 'Passcode must be exactly 6 digits.')
};
