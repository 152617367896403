import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import moment from 'moment';
import * as yup from 'yup';
import CustomModal from '../../../Common/Modal/CustomModal';
import FormDatePicker from '../../../Form/FormDatePicker';
import FormInput from '../../../Form/FormInput';
import * as validation from '../../../../Helper/YupValidation';
import { VenueApiRoutes } from '../../../../Utils/routes';
import Api from '../../../../Helper/Api';
import CustomButton from '../../../Common/CustomButton';
import { addDays } from 'date-fns';

const PromoCodeModal = ({ isOpen, handleModal, modalData, getTableData }) => {
	const authDetails = useSelector((state) => ({ ...state.auth }));
	const [loading, setLoading] = useState(false);
	let isEdit = modalData?.type === 'edit';
	const validationSchema = yup.object().shape({
		name: validation.YUP_VALIDATION.PROMO_CODE_NAME,
		code: validation.YUP_VALIDATION.PROMO_CODE,
		discountAmount: validation.YUP_VALIDATION.PROMO_CODE_DISCOUNTS,
		startDate: validation.YUP_VALIDATION.PROMO_CODE_START_DATE,
		description: validation.YUP_VALIDATION.PROMO_CODE_DESCRIPTION
	});

	const submitFormHandler = async () => {
		let payload = {};
		if (!isEdit) {
			payload = {
				bar_id: authDetails?.selectedVenue?.id?.toString(),
				name: values?.name,
				code: values?.code,
				description: values?.description,
				starts_at: moment(values?.startDate).format('yyyy-MM-DD'),
				expires_at: moment(values?.endDate).format('yyyy-MM-DD'),
				discount_amount: values?.discountAmount
			};
		} else {
			payload = {
				bar_id: authDetails?.selectedVenue?.id?.toString(),
				id: '' + modalData?.data?.id,
				name: values?.name,
				code: values?.code,
				description: values?.description,
				starts_at: moment(values?.startDate).format('yyyy-MM-DD'),
				discount_amount: '' + values?.discountAmount
			};
			if (values?.endDate) {
				payload.expires_at = moment(values?.endDate).format(
					'yyyy-MM-DD'
				);
			}
		}
		try {
			setLoading(true);
			const res = await Api(
				!isEdit ? 'POST' : 'PUT',
				!isEdit
					? VenueApiRoutes?.addPromocode
					: VenueApiRoutes?.editPromocode,
				payload
			);
			if (res?.data?.status) {
				handleModal();
				toast.success(res?.data?.message);
				await getTableData();
			} else {
				toast.error(res?.data?.message);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	const {
		values,
		handleChange,
		handleSubmit,
		handleBlur,
		touched,
		errors,
		setFieldValue,
		setValues
	} = useFormik({
		initialValues: {
			name: '',
			code: '',
			discountAmount: '',
			startDate: '',
			endDate: '',
			description: ''
		},
		validationSchema,
		onSubmit: submitFormHandler
	});
	const handleStartDateChange = (value) => {
		setFieldValue('startDate', value);
		if (values?.endDate && Date.parse(value) > Date.parse(values.endDate)) {
			setFieldValue('endDate', addDays(new Date(value), 1));
		}
	};
	useEffect(() => {
		if (modalData?.type === 'edit' && modalData?.data) {
			setValues({
				name: modalData?.data?.name,
				code: modalData?.data?.code,
				discountAmount: modalData?.data?.discount,
				startDate: moment(modalData?.data?.start_date).toDate(),
				endDate: modalData?.data?.expiry_date
					? moment(modalData?.data?.expiry_date).toDate()
					: '',
				description: modalData?.data?.description
			});
		} else {
			setValues({
				name: '',
				code: '',
				discountAmount: '',
				startDate: '',
				endDate: '',
				description: ''
			});
		}
	}, [modalData]);
	return (
		<CustomModal
			title={`${
				modalData?.type === 'add' ? 'Add Promo Code' : 'Edit Promo Code'
			}`}
			size="xl"
			isOpen={isOpen}
			handleModal={handleModal}
		>
			<form
				className="overflow-hidden"
				onSubmit={handleSubmit}
				autoComplete="off"
			>
				<Row>
					<Col sm={12} className="pa-b-14">
						<FormInput
							type="text"
							name="name"
							label="Promo Name (This is what customer will see on their reciept)"
							placeholder="Enter Promo Name"
							value={values.name}
							onChange={handleChange}
							onBlur={handleBlur}
							error={touched.name && !!errors.name}
							errorMsg={touched.name ? errors.name : null}
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={6} className="pa-b-14">
						<FormInput
							type="text"
							name="code"
							label="Promo Code"
							placeholder="Enter Promo Code"
							value={values.code}
							onChange={handleChange}
							onBlur={handleBlur}
							error={touched.code && !!errors.code}
							errorMsg={touched.code ? errors.code : null}
						/>
					</Col>
					<Col sm={6} className="pa-b-14">
						<FormInput
							type="number"
							name="discountAmount"
							label="Discount Amount (%)"
							placeholder="Enter Discount Amount"
							value={values.discountAmount}
							onChange={handleChange}
							onBlur={handleBlur}
							error={
								touched.discountAmount &&
								!!errors.discountAmount
							}
							errorMsg={
								touched.discountAmount
									? errors.discountAmount
									: null
							}
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={6} className="pa-b-14">
						<FormDatePicker
							name="startDate"
							label="Start Date"
							value={values.startDate}
							onChange={(value) => {
								handleStartDateChange(value);
							}}
							onBlur={handleBlur}
							error={touched.startDate && !!errors.startDate}
							errorMsg={
								touched.startDate ? errors.startDate : null
							}
						/>
					</Col>
					<Col sm={6} className="pa-b-14">
						<FormDatePicker
							name="endDate"
							label="End Date (Optional)"
							value={values.endDate}
							onChange={(value) => {
								setFieldValue('endDate', value);
							}}
							onBlur={handleBlur}
							error={touched.endDate && !!errors.endDate}
							errorMsg={touched.endDate ? errors.endDate : null}
							minDate={
								values?.startDate
									? moment(values?.startDate).toDate()
									: ''
							}
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<FormInput
							type="text"
							name="description"
							label="Description"
							placeholder="Enter Description"
							value={values.description}
							onChange={handleChange}
							onBlur={handleBlur}
							error={touched.description && !!errors.description}
							errorMsg={
								touched.description ? errors.description : null
							}
						/>
					</Col>
				</Row>
				<div className="pa-t-18 d-flex justify-content-center">
					<CustomButton
						type="submit"
						className="themeButton"
						loading={loading}
					>
						{!isEdit ? 'Add Promo Code' : 'Edit Promo Code'}
					</CustomButton>
				</div>
			</form>
		</CustomModal>
	);
};

export default PromoCodeModal;
