import React from 'react';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
	Legend
} from 'recharts';

const VerticleBarChart = ({
	heading,
	data,
	yAxisCount = 5,
	showLegend = false,
	labelFormatterXAxis,
	labelFormatterYAxis,
	yAxisWidth,
	xAxisHeight = 22,
	xAxisLabelAngle = 0,
	yAxisLabelAngle = 0,
	xAxisLabelFontSize = 8,
	yAxisLabelFontSize = 11,
	textAnchor,
	yAxisTextAnchor = 'end',
	dy,
	yAxisDy = 2,
	dx = 0,
	yAxisDx = 0,
	tooltipFormatter,
	yAxisLabels,
	allowDecimalsYaxis,
	tooltipLabelFormatter
}) => {
	return (
		<div
			className={`pa-24 border-radius-12 bg-white d-flex justify-content-between w-100 h-100 flex-column defaultBoxShadow ${
				!data?.chartData || data?.chartData?.length === 0
					? 'position-relative'
					: ''
			}`}
		>
			{heading && (
				<div className="pa-b-24">
					<div className="fs-15 medium-text">{heading}</div>
				</div>
			)}
			{(!data?.chartData || data?.chartData?.length === 0) && (
				<div className="position-absolute top-50 start-50 translate-middle">
					<span className="fs-15 opacity-50 d-flex justify-content-center text-center">
						No Chart Data Available
					</span>
				</div>
			)}
			<ResponsiveContainer
				width="100%"
				height={234}
				className={`${
					!data?.chartData || data?.chartData?.length === 0
						? 'invisible'
						: ''
				}`}
			>
				<BarChart
					width={'100%'}
					height={'100%'}
					data={data?.chartData}
					margin={{
						left: -28,
						right: 5
					}}
				>
					<XAxis
						dataKey="name"
						axisLine={false}
						tickLine={false}
						height={xAxisHeight}
						interval={0}
						tick={(data) => {
							return (
								<g transform={`translate(${data.x},${data.y})`}>
									<text
										x={0}
										y={0}
										fontSize={xAxisLabelFontSize}
										dy={dy}
										textAnchor={textAnchor}
										transform={`rotate(${xAxisLabelAngle})`}
										dx={dx}
									>
										{labelFormatterXAxis
											? labelFormatterXAxis(
													data?.payload?.value
											  )
											: data?.payload?.value}
									</text>
								</g>
							);
						}}
					/>
					<YAxis
						type="number"
						axisLine={false}
						tickLine={false}
						tickCount={yAxisCount}
						ticks={yAxisLabels}
						tick={(data) => {
							return (
								<g transform={`translate(${data.x},${data.y})`}>
									<text
										x={0}
										y={0}
										fontSize={yAxisLabelFontSize}
										dy={yAxisDy}
										textAnchor={yAxisTextAnchor}
										transform={`rotate(${yAxisLabelAngle})`}
										dx={yAxisDx}
									>
										{labelFormatterYAxis
											? labelFormatterYAxis(
													data?.payload?.value
											  )
											: data?.payload?.value}
									</text>
								</g>
							);
						}}
						width={yAxisWidth}
						allowDecimals={allowDecimalsYaxis}
					/>
					<CartesianGrid horizontal vertical={false} />
					<Tooltip
						formatter={(value) =>
							tooltipFormatter ? tooltipFormatter(value) : value
						}
						labelStyle={{ fontSize: '12px' }}
						itemStyle={{ fontSize: '12px' }}
						labelFormatter={(value) =>
							tooltipLabelFormatter
								? tooltipLabelFormatter(value)
								: value
						}
					/>
					{showLegend && (
						<Legend
							verticalAlign="top"
							align="left"
							iconType="circle"
							iconSize={14}
							formatter={(value) => (
								<span
									style={{
										color: '#242424',
										fontSize: '14px'
									}}
								>
									{value}
								</span>
							)}
						/>
					)}
					{data?.chartConfig?.length > 0 &&
						data?.chartConfig?.map((item, index) => {
							return (
								<Bar
									key={index}
									dataKey={item?.dataKey}
									name={item?.name}
									fill={item?.fill}
									dot={false}
									radius={[7, 7, 0, 0]}
									maxBarSize={34}
								/>
							);
						})}
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
};

export default VerticleBarChart;
