import styled from 'styled-components';

export const PageWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(1, 1, 1, 0.1);
	z-index: 1000;
	width: 100vw;
	height: 100vh;
	.loaderContainer {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.loader {
		/* background-color: #ffffff;
		padding: 16px;
		border-radius: 8px; */
	}
`;
