import { useSelector } from 'react-redux';
import { useState } from 'react';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';
import { useEffect } from 'react';
import moment from 'moment';

import { PageWrapper } from './index.style';
import SummaryReportTable from '../../../Components/Reports/SummaryReport/SummaryReportTable';
import {
	formatResponseData,
	tableColumns
} from '../../../Components/Reports/SummaryReport/utils';
import PageStructure from '../../../Components/Common/PageStructure';
import Api from '../../../Helper/Api';
import { VenueApiRoutes } from '../../../Utils/routes';
import { useDebounce } from '../../../Hooks/useDebounce';
import TableFilter from '../../../Components/Reports/SummaryReport/TableFilter';
import { defineds } from '../../../Helper/DateHelper';

export const SummaryReport = () => {
	const state = useSelector((state) => ({ ...state }));
	const allThemeData = state.themeChanger;
	const authDetails = state.auth;
	const [loading, setLoading] = useState(false);
	const [exportLoading, setExportLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [tableData, setTableData] = useState([]);
	const [tableDataCount, setTableDataCount] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');
	const debounceSearchTerm = useDebounce(searchTerm, 500);
	const [dateRange, setDateRange] = useState([
		moment(defineds.startOfToday).format('YYYY-MM-DD'),
		moment(defineds.endOfToday).format('YYYY-MM-DD')
	]);
	const [selectedCategoryId, setSelectedCategoryId] = useState('');

	const getTableData = async (payload) => {
		try {
			setLoading(true);
			const res = await Api(
				'POST',
				VenueApiRoutes.getSummaryReportTableData,
				payload
			);
			setLoading(false);
			if (res?.data?.status) {
				const tableDetails = formatResponseData(res?.data?.data?.rows);
				setTableData(tableDetails);
				setTableDataCount(res?.data?.data?.count);
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	const handleExport = async () => {
		try {
			setExportLoading(true);
			const res = await Api(
				'POST',
				VenueApiRoutes.exportSummaryReportData,
				{
					bar_id: authDetails?.selectedVenue?.id,
					search: debounceSearchTerm,
					start_date: dateRange[0],
					end_date: dateRange[1],
					id: selectedCategoryId
				}
			);
			setExportLoading(false);
			if (tableData?.length !== 0) {
				fileDownload(res?.data, 'Item_Summary_Report.csv');
				toast.success('Report exported successfully.');
			} else {
				toast.error('No data available for export.');
			}
		} catch (err) {
			setExportLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	const handlePageChange = ({ selected }) => {
		let pageNo = selected + 1;
		setCurrentPage(pageNo);
	};
	const handleSearchInputChange = async (value) => {
		setSearchTerm(value);
		setCurrentPage(1);
	};
	const handleDateRangeChange = (data) => {
		let date1 = moment(data[0]).format('YYYY-MM-DD');
		let date2 = moment(data[1]).format('YYYY-MM-DD');
		if (date1 === 'Invalid date') {
			date1 = '';
		}
		if (date2 === 'Invalid date') {
			date2 = '';
		}
		setDateRange([date1, date2]);
	};
	const handleCategoryChange = (categoryId) => {
		setSelectedCategoryId(categoryId);
	};
	useEffect(() => {
		(async () => {
			await getTableData({
				page: currentPage,
				bar_id: authDetails?.selectedVenue?.id,
				search: debounceSearchTerm,
				id: selectedCategoryId,
				start_date: dateRange[0],
				end_date: dateRange[1]
			});
		})();
	}, [
		currentPage,
		debounceSearchTerm,
		selectedCategoryId,
		dateRange[0],
		dateRange[1],
		authDetails?.selectedVenue?.id
	]);
	return (
		<PageStructure
			title="Item Summary Report"
			pageText={
				<TableFilter
					handleSearchInputChange={handleSearchInputChange}
					handleExport={handleExport}
					exportLoading={exportLoading}
					handleDateRangeChange={handleDateRangeChange}
					handleCategoryChange={handleCategoryChange}
				/>
			}
		>
			<PageWrapper {...allThemeData}>
				<SummaryReportTable
					loading={loading}
					tableColumns={tableColumns}
					tableData={tableData}
					handlePageChange={handlePageChange}
					totalRows={tableDataCount}
					currentPage={currentPage}
				/>
			</PageWrapper>
		</PageStructure>
	);
};
