import { useEffect, useState, Fragment } from 'react';
import moment from 'moment';
import CustomModal from '../../Common/Modal/CustomModal';
import OrderViewModalSkeleton from './OrderViewModalSkeleton';

const OrderViewModal = ({ isOpen, handleModal, singleRowData }) => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	useEffect(() => {
		setData(singleRowData);
	}, [singleRowData]);
	return (
		<CustomModal
			title="Order Details"
			size="lg"
			isOpen={isOpen}
			handleModal={handleModal}
			autoHeightMin={120}
		>
			{loading ? (
				<OrderViewModalSkeleton />
			) : (
				<>
					<div className="orderCard d-flex flex-column ptb-18">
						<div className="plr-18 d-flex justify-content-between align-items-center gap-3">
							<div>
								<p className="fs-12 medium-text themeText">
									{data?.orderServiceType === 'PICKUP'
										? data?.pickupCode
										: `Table ${data?.orderTableNumbers[0]?.tableCode}`}
								</p>
								<p className="fs-12 regular-text themeText pa-t-8">
									{moment(data?.orderDate).format(
										'DD/MM/YYYY'
									)}
								</p>
							</div>
							<p className="fs-12 medium-text themeText">
								Order #{data?.orderNo}
							</p>
						</div>
						<hr className="themeHr mtb-15" />
						<div className="d-flex flex-column flex-1">
							{data?.order_items_group?.length > 0 &&
								data?.order_items_group?.map((item, index) => {
									return (
										<Fragment key={index}>
											<div className="plr-18">
												{item?.items?.length > 0 &&
													item?.items?.map(
														(subItem, subIndex) => {
															return (
																<div
																	key={
																		subItem?.id
																	}
																	className={`d-flex justify-content-between gap-1 ${
																		subIndex ===
																		0
																			? ''
																			: 'pa-t-8'
																	}`}
																>
																	<div className="d-flex">
																		<p className="fs-12 medium-text qtyBox d-flex align-items-center justify-content-center">
																			{
																				subItem?.quantity
																			}
																		</p>
																		<div className="pl-18">
																			<p className="fs-12 medium-text themeText d-flex align-items-center">
																				{subItem
																					?.product
																					?.name +
																					' ' +
																					`($${subItem?.price
																						?.toFixed(
																							2
																						)
																						?.toString()})`}
																			</p>

																			{subItem
																				?.order_product_variant_types
																				?.length >
																				0 &&
																				subItem?.order_product_variant_types?.map(
																					(
																						extraItem,
																						index
																					) => (
																						<p
																							key={
																								index
																							}
																							className="fs-12 medium-text d-flex align-items-center opacity-50 pa-t-4"
																						>
																							{extraItem
																								?.product_variant_type
																								?.order_product_variant_sub_type
																								?.product_variant_sub_type
																								?.extraItem +
																								' ' +
																								`($${Number(
																									extraItem
																										?.product_variant_type
																										?.order_product_variant_sub_type
																										?.product_variant_sub_type
																										?.price
																								)?.toFixed(
																									2
																								)})`}
																						</p>
																					)
																				)}
																			{subItem
																				?.order_item_extras
																				?.length >
																				0 &&
																				subItem?.order_item_extras?.map(
																					(
																						extraItem,
																						index
																					) => (
																						<p
																							key={
																								index
																							}
																							className="fs-12 medium-text d-flex align-items-center opacity-50 pa-t-4"
																						>
																							{extraItem
																								?.product_extra
																								?.extraItem +
																								' ' +
																								`($${Number(
																									extraItem?.price
																								)?.toFixed(
																									2
																								)})`}
																						</p>
																					)
																				)}
																		</div>
																	</div>
																	<p className="fs-12 medium-text themeText">
																		${' '}
																		{(
																			Number(
																				subItem?.chargeAmount
																			) *
																			Number(
																				subItem?.quantity
																			)
																		)
																			?.toFixed(
																				2
																			)
																			?.toString()}
																	</p>
																</div>
															);
														}
													)}
												<div className="d-flex justify-content-between align-items-center gap-2 pa-t-8">
													<p className="fs-12 regular-text themeText">
														Pick Up Location
													</p>
													<p className="fs-12 medium-text themeText">
														{item?.pickup_location}
													</p>
												</div>
											</div>
											<hr className="themeHr mtb-15" />
										</Fragment>
									);
								})}
						</div>
						{data?.order_taxes?.length > 0 &&
							data?.order_taxes?.map((item) => {
								return (
									<Fragment key={item?.taxID}>
										<div className="plr-18 d-flex justify-content-between gap-2">
											<p className="fs-12 regular-text themeText">
												{item?.name}
											</p>
											<p className="fs-12 medium-text themeText">
												${' '}
												{Number(item?.amount)
													?.toFixed(2)
													?.toString()}
											</p>
										</div>
										<hr className="themeHr mtb-15" />
									</Fragment>
								);
							})}
						{data?.coupon ? (
							<>
								<div className="plr-18 d-flex justify-content-between gap-2">
									<p className="fs-12 regular-text themeText">
										{data?.coupon?.name}
									</p>
									<p className="fs-12 medium-text themeText">
										- ${' '}
										{Number(data?.promocode_amount)
											?.toFixed(2)
											?.toString()}
									</p>
								</div>
								<hr className="themeHr mtb-15" />
							</>
						) : (
							<></>
						)}
						<div className="plr-18 d-flex justify-content-between gap-2">
							<p className="fs-12 regular-text themeText">
								Total Amount (inc. GST)
							</p>
							<p className="fs-15 medium-text themeText">
								$ {Number(data?.total)?.toFixed(2)?.toString()}
							</p>
						</div>
					</div>
				</>
			)}
		</CustomModal>
	);
};

export default OrderViewModal;
