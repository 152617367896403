import React, { useEffect, useState } from 'react';
import setup2faImg from '../../Assets/images/setup-2fa-image.png';
import PageWrapper from './setup2FA.style';
import Agreement from '../../Components/Authentication/Agreement';
import NewFormInput from '../../Components/Form/NewFormInput';
import CustomButton from '../../Components/Common/CustomButton';
import { Col, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as validation from '../../Helper/YupValidation';
import {
	AdminApiRoutes,
	AdminPanelRoutes,
	CommonRoutes,
	VenueApiRoutes,
	VenuePanelRoutes,
	VenueProtectedRoutes
} from '../../Utils/routes';
import { somethingWentWrongError } from '../../Helper/somethingWentWrongError';
import { toast } from 'react-toastify';
import authActions from '../../Redux/auth/actions';
import Api from '../../Helper/Api';
import { deviceDetect, isMobile } from 'react-device-detect';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useQuery from '../../Hooks/useQuery';
import oneNumIcon from '../../Assets/images/one.png';
import twoNumIcon from '../../Assets/images/two.png';
import threeNumIcon from '../../Assets/images/three.png';

const Setup2FA = () => {
	const [userLocationData, setUserLocationData] = useState({});
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { state } = useLocation();
	const type = 'venue';

	useEffect(() => {
		if (!state) {
			navigate(CommonRoutes.login);
		}
	}, [navigate, state]);

	useEffect(() => {
		(async () => {
			try {
				const response = axios.get('https://ipapi.co/json/');
				let data = response?.data;
				setUserLocationData(data);
			} catch (err) {
				console.log(err);
			}
		})();
	}, []);

	const stepsData = [
		{
			id: 1,
			text: 'An email from venue@mytabinfo.com has been sent to you, please open this email on your desktop so you can use your mobile to scan the QR code. If the email does not appear immediately, please check your junk/spam folder.'
		},
		{
			id: 2,
			text: 'Download an authenticator app on your mobile, or if you have an existing authenticator app, open your authenticator app on your mobile and use it to scan the QR code sent to your email.'
		},
		{
			id: 3,
			text: 'Enter the 6-digit code provided by your authenticator app to log in to your MyTab Management Portal.'
		}
	];

	const validationSchema = yup.object().shape({
		otp: validation.YUP_VALIDATION.OTP
	});

	const handleSetupAuthenticator = async () => {
		try {
			const res = await Api(
				'POST',
				VenueApiRoutes.setUpGoogleAuthenticator,
				{ email: state?.email }
			);
			if (res?.data?.status) {
				return toast.success(res?.data?.message);
			} else {
				return toast.error(res?.data?.message);
			}
		} catch (err) {
			somethingWentWrongError();
		}
	};

	const handelOnsubmit = async () => {
		let deviceData = deviceDetect();

		const payloadVenue = {
			user_id: state?.admin_id,
			email: state?.email,
			code: verifyOtpFormik.values.otp,
			device_type: 'web',
			device_token: '23',
			device_name: isMobile
				? (deviceData?.model ?? '') +
				  ' ' +
				  (deviceData?.os ?? '') +
				  ' ' +
				  (deviceData?.osVersion ?? '')
				: (deviceData?.browserName ?? '') +
				  ' ' +
				  (deviceData?.osName ?? '') +
				  ' ' +
				  (deviceData?.osVersion ?? ''),
			device_location:
				(userLocationData?.city ? userLocationData?.city + ',' : '') +
				' ' +
				(userLocationData?.region
					? userLocationData?.region + ','
					: '') +
				' ' +
				(userLocationData?.country_name
					? userLocationData?.country_name
					: '')
		};

		const payloadAdmin = {
			admin_id: state?.admin_id,
			email: state?.email,
			code: verifyOtpFormik.values.otp,
			device_type: !isMobile ? '1' : '2',
			device_token: null,
			device_name: isMobile
				? (deviceData?.model ?? '') +
				  ' ' +
				  (deviceData?.os ?? '') +
				  ' ' +
				  (deviceData?.osVersion ?? '')
				: (deviceData?.browserName ?? '') +
				  ' ' +
				  (deviceData?.osName ?? '') +
				  ' ' +
				  (deviceData?.osVersion ?? ''),
			device_location:
				(userLocationData?.city ? userLocationData?.city + ',' : '') +
				' ' +
				(userLocationData?.region
					? userLocationData?.region + ','
					: '') +
				' ' +
				(userLocationData?.country_name
					? userLocationData?.country_name
					: '')
		};

		let api =
			type === 'venue'
				? VenueApiRoutes.verifyOtp
				: AdminApiRoutes.verifyOtp;

		let payload = type === 'venue' ? payloadVenue : payloadAdmin;
		setLoading(true);
		try {
			const res = await Api('POST', api, payload);
			setLoading(false);
			if (res?.data?.status) {
				verifyOtpFormik.resetForm();
				dispatch(authActions.venue_login(res?.data?.data));
				if (type === 'venue') {
					if (res?.data?.data?.bars?.length === 0) {
						dispatch(authActions.prevenue_process(true));
						navigate(VenueProtectedRoutes.connectVenue, {
							state: { authData: res?.data?.data }
						});
					} else {
						// dispatch(authActions.venue_login(res?.data?.data));
						dispatch(authActions.venue_change_login(true));
						navigate(VenuePanelRoutes.home);
					}
				} else {
					navigate(AdminPanelRoutes.live);
				}
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setLoading(false);
			somethingWentWrongError();
		}
	};

	const verifyOtpFormik = useFormik({
		initialValues: {
			admin_id: state?.admin_id,
			email: state?.email,
			otp: '',
			type: type === 'venue' ? 'venue' : 'admin'
		},
		validationSchema,
		validateOnChange: true,
		validateOnBlur: false,
		onSubmit: handelOnsubmit
	});

	return (
		<PageWrapper>
			<div className="formWrapper pl-26 pr-45">
				<div>
					<p className="headingStyle">
						Set up 2FA for your Management Portal
					</p>
					{stepsData?.map((data, index) => (
						<Row className="followSteps">
							<Col
								xs={2}
								sm={2}
								lg={1}
								md={1}
								xl={1}
								xxl={1}
								className="pr-0"
							>
								{/* <img
									src={data.image}
									alt="icon"
									className="iconImage"
								/> */}
								<div className="bulletPointOuter">
									<span className="bulletPointSpan">
										{data.id}
									</span>
								</div>
							</Col>
							<Col
								xs={10}
								sm={10}
								lg={11}
								md={11}
								xl={11}
								xxl={11}
								className="p-0 d-flex align-items-center"
							>
								<p>{data.text}</p>
							</Col>
						</Row>
					))}
					<div className="smallInfoWrraper">
						<p className="smallInfo">
							To ensure the highest security for your Management
							Portal, 2FA will be required each time you sign in.
						</p>
					</div>
					<form
						className="mt-4 mb-5"
						onSubmit={verifyOtpFormik.handleSubmit}
					>
						<p className="verifyOtpSubText">
							Please enter your 6 digit verification code
						</p>
						<NewFormInput
							name="otp"
							placeholder="Verification code"
							type="text"
							maxlength="6"
							onBlur={verifyOtpFormik.handleBlur}
							value={verifyOtpFormik?.values?.otp}
							onChange={verifyOtpFormik?.handleChange}
							error={verifyOtpFormik?.errors?.otp}
							errorMsg={
								verifyOtpFormik?.touched?.otp &&
								verifyOtpFormik?.errors?.otp
							}
						/>
						<div className="pt-2">
							<CustomButton
								type="submit"
								className="newThemeButtonFullWidth"
								loading={loading}
							>
								Verify
							</CustomButton>
						</div>
						<p className="text-center mt-2 resendMail">
							Didn’t receive an email? Check your junk/spam, press{' '}
							<span
								className="spanLink"
								onClick={() =>
									navigate('/register', {
										state: state
									})
								}
							>
								back
							</span>{' '}
							to confirm your email was entered correctly or click{' '}
							<span
								className="spanLink"
								onClick={handleSetupAuthenticator}
							>
								here
							</span>{' '}
							to re-send.
						</p>
					</form>
				</div>
				<Agreement />
			</div>
			<div className="imageWrapper">
				<div className="backWrraper">
					<p className="text">Your security is our priority</p>
					<img src={setup2faImg} alt="side-img" className="image" />
				</div>
			</div>
		</PageWrapper>
	);
};

export default Setup2FA;
