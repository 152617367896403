import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { Button, Card, CardBody, CardSubtitle, Col, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import * as validation from '../../Helper/YupValidation';
import Api from '../../Helper/Api';
import FormInputGroup from '../Form/FormInputGroup';
import { BackIcon, EyeIcon, EyeSlashIcon } from '../Icons';
import {
	AdminApiRoutes,
	CommonRoutes,
	VenueApiRoutes
} from '../../Utils/routes';
import NewFormPasswordInput from '../Form/NewFormPasswordInput';
import CustomButton from '../Common/CustomButton';

const ResetPasswordForm = ({ type }) => {
	const navigate = useNavigate();
	const { loginRole, otpToken } = useParams();
	const [passwordValid, setPasswordValid] = useState(null);
	const [passwordValidC, setPasswordValidC] = useState(null);
	const [passwordView, setPasswordView] = useState(false);
	const [confirmPasswordView, setConfirmPasswordView] = useState(false);
	const [loading, setLoading] = useState(false);

	const validationSchema = yup.object().shape({
		newPassword: validation.YUP_VALIDATION.PASSWORD,
		confirmPassword: validation.YUP_VALIDATION.CONFIRM_PASSWORD
	});
	const handelOnsubmit = async () => {
		const payload = {
			password: resetPasswordFormik?.values?.newPassword,
			otp_token: otpToken?.split('~')[1]
		};
		let api =
			loginRole === 'venue'
				? VenueApiRoutes.resetPassword
				: AdminApiRoutes.resetPassword;
		try {
			setLoading(true);
			const res = await Api('POST', api, payload);
			setLoading(false);
			if (res?.data?.status) {
				resetPasswordFormik.resetForm();
				toast.success(res?.data?.message);
				navigate(CommonRoutes.login);
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};

	const resetPasswordFormik = useFormik({
		initialValues: {
			newPassword: '',
			confirmPassword: ''
		},
		validationSchema,
		onSubmit: handelOnsubmit
	});

	const passwordRegex =
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9~!@#$%^&*()_\-+=<>?]).{8,}$/;
	useEffect(() => {
		if (resetPasswordFormik?.values?.newPassword) {
			if (passwordRegex.test(resetPasswordFormik?.values?.newPassword)) {
				setPasswordValid(true);
			} else {
				setPasswordValid(false);
			}
		} else {
			setPasswordValid(null);
		}
	}, [resetPasswordFormik?.values?.newPassword]);
	useEffect(() => {
		if (resetPasswordFormik?.values?.confirmPassword) {
			if (
				passwordRegex.test(
					resetPasswordFormik?.values?.confirmPassword
				) &&
				resetPasswordFormik?.values?.newPassword ===
					resetPasswordFormik?.values?.confirmPassword
			) {
				setPasswordValidC(true);
			} else {
				setPasswordValidC(false);
			}
		} else {
			setPasswordValidC(null);
		}
	}, [
		resetPasswordFormik?.values?.newPassword,
		resetPasswordFormik?.values?.confirmPassword
	]);
	return (
		<>
			<div className="formCard">
				<div>
					<h1 className="headingStyle maxHeading">
						Create a new password for your Management Portal
					</h1>
					<p className="fs-12 medium-text themeText pa-b-26">
						Please enter your new password below and do not share it
						with anyone. Your new password must be different from
						previous used passwords.
					</p>
				</div>
				<form onSubmit={resetPasswordFormik.handleSubmit}>
					<div className="inputContainWrraper">
						<NewFormPasswordInput
							showRequired
							suggestionMatcher
							passwordSuggestionMatched={passwordValid}
							tootlTipMessage="This password is for your Management Portal and does not need to match the password chosen for your MyTab Venue account."
							error={
								resetPasswordFormik?.touched?.newPassword &&
								resetPasswordFormik?.errors?.newPassword
							}
							errorMsg={resetPasswordFormik?.errors?.newPassword}
							name="newPassword"
							label="New Password"
							placeholder="New Password"
							onChange={resetPasswordFormik.handleChange}
							value={resetPasswordFormik?.values?.newPassword}
						/>
						<Row>
							<Col
								lg={6}
								xl={6}
								xxl={6}
								md={6}
								xs={6}
								sm={6}
								className="password-suggestion"
							>
								<p>-8 characters minimum</p>
								<p>-One upper case character</p>
							</Col>
							<Col
								lg={6}
								xl={6}
								xxl={6}
								md={6}
								xs={6}
								sm={6}
								className="password-suggestion"
							>
								<p>-One lower case character</p>
								<p>-One number and/or special character</p>
							</Col>
						</Row>
					</div>
					<div className="inputContainWrraper">
						<NewFormPasswordInput
							showRequired
							suggestionMatcher
							passwordSuggestionMatched={passwordValidC}
							tootlTipMessage="Both passwords must match."
							error={
								resetPasswordFormik?.touched?.confirmPassword &&
								resetPasswordFormik?.errors?.confirmPassword
							}
							errorMsg={
								resetPasswordFormik?.errors?.confirmPassword
							}
							name="confirmPassword"
							label="Confirm Password"
							placeholder="Confirm Password"
							value={resetPasswordFormik?.values?.confirmPassword}
							onChange={resetPasswordFormik.handleChange}
						/>
						<Row>
							<Col
								lg={6}
								xl={6}
								xxl={6}
								md={6}
								xs={6}
								sm={6}
								className="password-suggestion"
							>
								<p>-Both passwords must match</p>
							</Col>
						</Row>
					</div>

					<CustomButton
						type="submit"
						className="newThemeButtonFullWidth"
						disabled={loading}
					>
						Reset Password
					</CustomButton>
				</form>
			</div>
			{/* <Card
				className={`resetPasswordFormCard ${
					type === 'venue' ? 'venue-card' : 'admin-card'
				}`}
			>
				<CardBody className="position-relative">
					<CardSubtitle
						className="fs-32 bold-text d-flex justify-content-center align-items-center position-relative pt-0"
						tag="h4"
					>
						<BackIcon
							className="backIcon position-absolute cursor-pointer"
							style={{ left: 0 }}
							onClick={() => navigate(`/login`)}
						/>
						Create new password
					</CardSubtitle>
					<p className="fs-14 medium-text text-center themeText mtb-20 p-1 pageContent">
						Please enter your new password below. Remember, use a
						mix of letters, numbers and symbols to increase the
						strength of your password and do not share it with
						anyone. Your new password must be different from
						previous used passwords.
					</p>
					<form onSubmit={resetPasswordFormik.handleSubmit}>
						<FormInputGroup
							className={'min-height-52'}
							id="newPassword"
							type={passwordView ? 'text' : 'password'}
							name="newPassword"
							label="New Password"
							placeholder="New Password"
							value={resetPasswordFormik?.values?.newPassword}
							onChange={resetPasswordFormik.handleChange}
							onBlur={resetPasswordFormik.handleBlur}
							icon={
								!passwordView ? (
									<EyeSlashIcon
										height={24}
										width={24}
										className="cursor-pointer"
										onClick={() =>
											setPasswordView(!passwordView)
										}
									/>
								) : (
									<EyeIcon
										height={24}
										width={24}
										className="cursor-pointer"
										onClick={() =>
											setPasswordView(!passwordView)
										}
									/>
								)
							}
							iconPlacement="end"
							iconBackgroundClass={'inputGroupBackground'}
							helperText="Must be at least 8 characters."
						/>
						<p
							className={`fs-12 semi-bold-text fw-bold customErrorMsg ${
								resetPasswordFormik?.touched?.newPassword &&
								!!resetPasswordFormik?.errors?.newPassword
									? 'visible'
									: 'invisible'
							}`}
						>
							<span className="headingTextColor">
								{resetPasswordFormik?.errors?.newPassword
									? resetPasswordFormik?.errors?.newPassword
									: 'dummytext'}
							</span>
						</p>
						<FormInputGroup
							className={'min-height-52'}
							id="confirmPassword"
							type={confirmPasswordView ? 'text' : 'password'}
							name="confirmPassword"
							label="Confirm Password"
							placeholder="Confirm Password"
							value={resetPasswordFormik?.values?.confirmPassword}
							onChange={resetPasswordFormik.handleChange}
							onBlur={resetPasswordFormik.handleBlur}
							icon={
								!confirmPasswordView ? (
									<EyeSlashIcon
										height={24}
										width={24}
										className="cursor-pointer"
										onClick={() =>
											setConfirmPasswordView(
												!confirmPasswordView
											)
										}
									/>
								) : (
									<EyeIcon
										height={24}
										width={24}
										className="cursor-pointer"
										onClick={() =>
											setConfirmPasswordView(
												!confirmPasswordView
											)
										}
									/>
								)
							}
							iconPlacement="end"
							iconBackgroundClass={'inputGroupBackground'}
							helperText="Both passwords must match."
						/>
						<p
							className={`fs-12 semi-bold-text fw-bold customErrorMsg ${
								resetPasswordFormik?.touched?.confirmPassword &&
								!!resetPasswordFormik?.errors?.confirmPassword
									? 'visible'
									: 'invisible'
							}`}
						>
							<span className="headingTextColor">
								{resetPasswordFormik?.errors?.confirmPassword
									? resetPasswordFormik?.errors
											?.confirmPassword
									: 'dummytext'}
							</span>
						</p>
						<Button
							type="submit"
							className="themeButtonFullWidth"
							disabled={loading}
						>
							Reset Password
						</Button>
					</form>
				</CardBody>
			</Card> */}
		</>
	);
};

export default ResetPasswordForm;
