import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle
} from 'reactstrap';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

import { defineds } from '../../../Helper/DateHelper';
import CustomDateRangePicker from '../../Common/DateRangePicker/CustomDateRangePicker';
import FormInputGroup from '../../Form/FormInputGroup';
import {
	DownArrowIcon,
	ExportIcon,
	FilterIcon,
	MagnifyingGlassIcon,
	UpArrowIcon
} from '../../Icons';
import { HeaderWrapper } from './tableFilter.style';
import useDevice from '../../../Hooks/useDevice';
import { VenueApiRoutes } from '../../../Utils/routes';
import Api from '../../../Helper/Api';
import CustomButton from '../../Common/CustomButton';

const TableFilter = ({
	handleSearchInputChange,
	handleExport,
	exportLoading,
	handleDateRangeChange,
	handleCategoryChange
}) => {
	const state = useSelector((state) => ({ ...state }));
	const allThemeData = state.themeChanger;
	const authData = state.auth;
	const dateDropdownRef = useRef(null);
	const categoryDropdownRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const [dateRangePickerLabel, setDateRangePickerLabel] = useState('Today');
	const [dateDropdown, setDateDropdown] = useState(false);
	const [orderTypeDropdown, setOrderTypeDropdown] = useState(false);
	const [orderTypeLabel, setOrderTypeLabel] = useState('All Categories');
	const [orderTypeId, setOrderTypeId] = useState('');
	const [dateRange, setDateRange] = useState([
		defineds.startOfToday,
		defineds.endOfToday
	]);
	const [categoryList, setCategoryList] = useState([]);
	const { isTablet } = useDevice();

	const handleDateRange = (ranges) => {
		if (ranges[0] && ranges[1]) {
			setDateDropdown((prev) => !prev);
			setDateRangePickerLabel(
				ranges[0].toLocaleDateString() +
					' - ' +
					ranges[1].toLocaleDateString()
			);
		}
		setDateRange(ranges);
	};
	const handleDateRangeLabel = (label) => {
		setDateRangePickerLabel(label);
		if (label === 'Today') {
			setDateRange([defineds.startOfToday, defineds.endOfToday]);
		} else if (label === 'Yesterday') {
			setDateRange([defineds.startOfYesterday, defineds.endOfYesterday]);
		} else if (label === 'This Week') {
			setDateRange([defineds.startOfWeek, defineds.endOfWeek]);
		} else if (label === 'This Month') {
			setDateRange([defineds.startOfMonth, defineds.endOfToday]);
		} else if (label === 'This Quarter') {
			setDateRange([defineds.startOfQuarter, defineds.endOfToday]);
		} else if (label === 'This Calendar Year') {
			setDateRange([defineds.startOfYear, defineds.endOfToday]);
		} else {
			setDateRange([null, null]);
		}
		setDateDropdown(false);
	};
	const getCategoryList = async () => {
		try {
			setLoading(true);
			const res = await Api('POST', VenueApiRoutes.getCategoryList, {
				bar_id: authData?.selectedVenue?.id
			});
			setLoading(false);
			if (res?.data?.status) {
				setCategoryList(res?.data?.data?.list);
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	useEffect(() => {
		handleDateRangeChange(dateRange);
	}, [dateRange]);
	useEffect(() => {
		handleCategoryChange(orderTypeId);
	}, [orderTypeId]);
	useEffect(() => {
		(async () => {
			await getCategoryList();
		})();
	}, []);
	return (
		<HeaderWrapper {...allThemeData}>
			<p className="pa-t-24 fs-12 regular-text">
				Your item summary report will show all menu items sold within
				your selected time period. You can download this report to your
				desktop at any time by pressing the export button.
			</p>
			<div className="filterWrapper pa-t-24">
				<div className="dateDropdownBox d-flex align-items-center">
					<Dropdown
						isOpen={dateDropdown}
						direction="down"
						className="dashboard-dropdown dateDropdown"
						toggle={() => setDateDropdown((prev) => !prev)}
					>
						<DropdownToggle
							color="#fff"
							className="dropdownToggle d-flex justify-content-between align-items-center dateDropdownToggle"
							innerRef={dateDropdownRef}
						>
							<span
								className={`dropdown-name fs-11 medium-text flex-1`}
							>
								{dateRangePickerLabel}
							</span>
							<span className="pl-10">
								{dateDropdown ? (
									<UpArrowIcon height={6} width={10} />
								) : (
									<DownArrowIcon height={6} width={10} />
								)}
							</span>
						</DropdownToggle>
						{/* Date Picker */}
						<DropdownMenu
							className={`datePickerDropdown ${
								isTablet ? '' : ''
							} pt-12 pl-4 pr-12`}
							// end={isTablet}
							style={{ maxHeight: '320px' }}
						>
							<div className="d-flex flex-column flex-sm-row">
								<div className="dropdownCalenderParent">
									<div className="p-0 dropdown-item dropdownCalender">
										<CustomDateRangePicker
											dateRange={dateRange}
											handleDateRange={handleDateRange}
										/>
									</div>
								</div>
								<div>
									<DropdownItem
										className={`${
											dateRangePickerLabel === 'Today'
												? 'active'
												: ''
										} calenderItem`}
										onClick={() =>
											handleDateRangeLabel('Today')
										}
									>
										Today
									</DropdownItem>
									<DropdownItem
										className={`${
											dateRangePickerLabel === 'Yesterday'
												? 'active'
												: ''
										} calenderItem`}
										onClick={() =>
											handleDateRangeLabel('Yesterday')
										}
									>
										Yesterday
									</DropdownItem>
									<DropdownItem
										className={`${
											dateRangePickerLabel === 'This Week'
												? 'active'
												: ''
										} calenderItem`}
										onClick={() =>
											handleDateRangeLabel('This Week')
										}
									>
										This Week
									</DropdownItem>
									<DropdownItem
										className={`${
											dateRangePickerLabel ===
											'This Month'
												? 'active'
												: ''
										} calenderItem`}
										onClick={() =>
											handleDateRangeLabel('This Month')
										}
									>
										This Month
									</DropdownItem>
									<DropdownItem
										className={`${
											dateRangePickerLabel ===
											'This Quarter'
												? 'active'
												: ''
										} calenderItem`}
										onClick={() =>
											handleDateRangeLabel('This Quarter')
										}
									>
										This Quarter
									</DropdownItem>
									<DropdownItem
										className={`${
											dateRangePickerLabel ===
											'This Calendar Year'
												? 'active'
												: ''
										} calenderItem`}
										onClick={() =>
											handleDateRangeLabel(
												'This Calendar Year'
											)
										}
									>
										This Calendar Year
									</DropdownItem>
									<DropdownItem
										className={`${
											dateRangePickerLabel === 'All Time'
												? 'active'
												: ''
										} calenderItem`}
										onClick={() =>
											handleDateRangeLabel('All Time')
										}
									>
										All Time
									</DropdownItem>
								</div>
							</div>
						</DropdownMenu>
					</Dropdown>
				</div>
				<div className="threeButtonWrapper">
					<div className="searchBoxWrapper">
						<FormInputGroup
							type="text"
							name="search"
							placeholder="Search Menu Item"
							icon={
								<MagnifyingGlassIcon height={18} width={18} />
							}
							iconPlacement="start"
							onChange={(event) => {
								handleSearchInputChange(event?.target?.value);
							}}
						/>
					</div>
					<div className="orderTypeDropdownBox d-flex align-items-center">
						<Dropdown
							isOpen={orderTypeDropdown}
							direction="down"
							className="dashboard-dropdown categoryDropdown"
							toggle={() => setOrderTypeDropdown((prev) => !prev)}
						>
							<DropdownToggle
								color="#fff"
								className="dropdownToggle d-flex justify-content-between align-items-center categoryDropdownToggle"
								innerRef={categoryDropdownRef}
							>
								<span
									className={`dropdown-name fs-11 medium-text flex-1 d-flex align-items-center categoryDropdownName`}
								>
									<span className="pr-8">
										<FilterIcon height={18} width={18} />{' '}
									</span>
									{orderTypeLabel}
								</span>
								<span className="">
									{orderTypeDropdown ? (
										<UpArrowIcon height={6} width={10} />
									) : (
										<DownArrowIcon height={6} width={10} />
									)}
								</span>
							</DropdownToggle>
							<DropdownMenu
								className={`${isTablet ? 'w-100' : ''} p-0`}
								end={isTablet}
								style={{
									zIndex: '100',
									maxHeight: '320px',
									overflowY: 'scroll'
								}}
							>
								<DropdownItem
									className={`${
										orderTypeLabel === 'All Categories'
											? 'active'
											: ''
									} categoryItem`}
									onClick={() => {
										setOrderTypeLabel('All Categories');
										setOrderTypeId('');
										setOrderTypeDropdown(false);
									}}
									style={{ borderRadius: '6px 6px 0 0' }}
								>
									All Categories
								</DropdownItem>
								{categoryList?.length > 0 &&
									categoryList?.map((item, index) => {
										return (
											<DropdownItem
												key={index}
												className={`${
													orderTypeLabel ===
													item?.name
														? 'active'
														: ''
												} categoryItem`}
												onClick={() => {
													setOrderTypeLabel(
														item?.name
													);
													setOrderTypeId(
														item?.subCategoryID
													);
													setOrderTypeDropdown(false);
												}}
												style={{
													borderRadius:
														index ===
														categoryList?.length - 1
															? '0 0 6px 6px'
															: ''
												}}
											>
												{item?.name}
											</DropdownItem>
										);
									})}
							</DropdownMenu>
						</Dropdown>
					</div>
					<CustomButton
						type="button"
						className="themeButton"
						onClick={handleExport}
						loading={exportLoading}
					>
						<ExportIcon height={16} width={18} />
						<span className="pl-10 fs-11 medium-text">Export</span>
					</CustomButton>
				</div>
			</div>
		</HeaderWrapper>
	);
};

export default TableFilter;
