import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import App from './App';
import './index.css';
import { store } from './Redux/store';
import ErrorBoundary from './ErrorBoundary';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<CookiesProvider>
			<ErrorBoundary>
				<App />
				<ToastContainer
					bodyClassName="custom-toast"
					toastClassName={'toast-glass-effect'}
					position="top-right"
					autoClose={5000}
					hideProgressBar={true}
					newestOnTop
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					theme="light"
					limit={2}
				/>
			</ErrorBoundary>
		</CookiesProvider>
	</Provider>
);
reportWebVitals();
