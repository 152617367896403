import React from 'react';

import VerticleBarChart from '../../Common/Charts/VerticleBarChart';
import convertNumToKM from '../../../Helper/convertNumToKM';

const PopularTimesChart = ({ data }) => {
	return (
		<VerticleBarChart
			heading={'Popular Times'}
			data={data}
			yAxisWidth={68}
			xAxisLabelAngle={0}
			textAnchor={'middle'}
			dy={12}
			allowDecimalsYaxis={false}
			labelFormatterYAxis={(value) => {
				return convertNumToKM(Number(value));
			}}
			tooltipLabelFormatter={(value) => value + ':00'}
			labelFormatterXAxis={(value) => value + ':00'}
		/>
	);
};

export default PopularTimesChart;
