import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import newDesignLogo from '../Assets/images/new-design-logo.svg';
// import Scrollbars from 'react-custom-scrollbars'

import Footer from '../Components/Footer/Footer';
import GlobalWrapper from './global.style';
import { CommonRoutes } from '../Utils/routes';
import { toast } from 'react-toastify';
const HomeLayout = ({ children }) => {
	const navigate = useNavigate();
	const allthemedata = useSelector((state) => ({
		...state.themeChanger,
		themeSetting: { ...state.themeSetting, toolbarDisplayValue: 'hide' },
		fullpage: true
	}));
	const isLogin = useSelector((state) => state?.auth?.isLogin);
	const loginType = useSelector((state) => state?.auth?.login_type);
	const location = useLocation();

	const preVenueProcess = useSelector(
		(state) => state?.auth?.preVenueProcess
	);

	const preAccountProcess = useSelector(
		(state) => state?.auth?.preAccountProcess
	);

	const accountProcesses = useSelector(
		(state) => state?.auth?.accountProcesses
	);

	const onClickToLogo = () => {
		if (!(accountProcesses && preVenueProcess && preAccountProcess)) {
			navigate(CommonRoutes.landingPage);
		}
	};

	return (
		<GlobalWrapper {...allthemedata}>
			<div className="home-layout">
				{/* <Scrollbars style={{ height: "calc(100vh - 51px)" }} autoHide className="container bg-info"> */}
				{/* Route Layout Start*/}
				<div
					style={{ height: 'calc(100vh - 51px)' }}
					className="d-flex flex-column"
				>
					<div className="header">
						<div onClick={onClickToLogo}>
							<img
								src={newDesignLogo}
								alt="new-design-logo"
								className="logo"
							/>
						</div>
						<div className="d-flex gap-4 align-items-center">
							<div
								className="supportBtn"
								onClick={() =>
									window.open(
										'https://mytabinfo.com/pages/contact',
										'_blank',
										'noreferrer'
									)
								}
							>
								Support
							</div>
							<div
								className="advertisingBtn"
								onClick={() =>
									toast.success(
										'This feature is coming soon.',
										{ theme: 'colored' }
									)
								}
							>
								Advertising
							</div>
						</div>
					</div>
					<div className="flex-1">{children}</div>
				</div>
				{/* Route Layout Finish*/}
				{/* </Scrollbars> */}
				<Footer {...allthemedata} />
			</div>
		</GlobalWrapper>
	);
};

export default HomeLayout;
