export const EditIcon = ({ stroke, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 28 28" fill="none">
			<path
				d="M14 17.5H10.5V14L21 3.5L24.5 7L14 17.5Z"
				stroke={stroke ?? '#F94D73'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.375 6.125L21.875 9.625"
				stroke={stroke ?? '#F94D73'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M23.625 13.125V22.75C23.625 22.9821 23.5328 23.2046 23.3687 23.3687C23.2046 23.5328 22.9821 23.625 22.75 23.625H5.25C5.01794 23.625 4.79538 23.5328 4.63128 23.3687C4.46719 23.2046 4.375 22.9821 4.375 22.75V5.25C4.375 5.01794 4.46719 4.79538 4.63128 4.63128C4.79538 4.46719 5.01794 4.375 5.25 4.375H14.875"
				stroke={stroke ?? '#F94D73'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
