import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Row, Col, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import * as validation from '../../../Helper/YupValidation';
import Api from '../../../Helper/Api';
import authActions from '../../../Redux/auth/actions';
import defaultProfilePic from '../../../Assets/images/phone2.png';
import { EditIcon } from '../../Icons';
import FormInput from '../../Form/FormInput';
import ImageUpload from '../../Form/ImageUpload';
import { VenueApiRoutes } from '../../../Utils/routes';
import CustomButton from '../../Common/CustomButton';
import FormMobileNoInput from '../../Form/FormMobileNoInput';
import { somethingWentWrongError } from '../../../Helper/somethingWentWrongError';
import PageWrapper from './index.style';
import VerifyOtpModal from './VerifyOtpModal';

const ManageAccountForm = () => {
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	const profileData = useSelector((state) => ({ ...state.auth }));
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [editMode, setEditMode] = useState(false);

	const handleModal = () => {
		setOpenModal((prev) => !prev);
	};
	const validationSchema = yup.object().shape({
		// image: validation.YUP_VALIDATION.IMAGE,
		firstname: validation.YUP_VALIDATION.FIRST_NAME,
		lastname: validation.YUP_VALIDATION.LAST_NAME,
		pronouns: validation.YUP_VALIDATION.PRONOUNS,
		email: validation.YUP_VALIDATION.EMAIL,
		mobile: validation.YUP_VALIDATION.MOBILE_NUMBER
	});
	const submitFormHandler = async () => {
		const payload = {
			image: editProfileFormik.values.image,
			first_name: editProfileFormik.values.firstname,
			last_name: editProfileFormik.values.lastname,
			email: editProfileFormik.values.email,
			country_code: editProfileFormik.values.countryCode,
			mobile: editProfileFormik.values.mobile
		};
		let formData = new FormData();
		for (let key in payload) {
			formData.append(key, payload[key]);
		}
		try {
			setLoading(true);
			const res = await Api('PUT', VenueApiRoutes.editProfile, formData);
			setLoading(false);
			if (res?.data?.status) {
				toast.success(res?.data?.message);
				await dispatch(
					authActions.update_profile({
						...profileData,
						...res?.data?.data
					})
				);
				setEditMode(false);
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setLoading(false);
			somethingWentWrongError();
		}
	};
	const editProfileFormik = useFormik({
		initialValues: {
			image: '',
			firstname: '',
			lastname: '',
			email: '',
			mobile: '',
			countryCode: ''
		},
		validationSchema,
		onSubmit: submitFormHandler
	});
	useEffect(() => {
		if (profileData) {
			editProfileFormik.setValues({
				image: profileData.profile_image,
				firstname: profileData.first_name,
				lastname: profileData.last_name,
				email: profileData.email,
				mobile: profileData.mobile ?? '',
				countryCode: profileData.country_code ?? '61'
			});
		}
	}, []);
	return (
		<PageWrapper {...allThemeData}>
			<form onSubmit={editProfileFormik.handleSubmit}>
				<Row>
					<Col sm={12} className="pa-b-20">
						<ImageUpload
							name={'image'}
							defaultImage={defaultProfilePic}
							icon={
								<EditIcon
									height={14}
									weight={14}
									stroke="#fff"
								/>
							}
							value={editProfileFormik.values.image}
							setFieldValue={editProfileFormik.setFieldValue}
							error={
								editProfileFormik.touched.image &&
								!!editProfileFormik.errors.image
							}
							errorMsg={editProfileFormik.errors.image}
							disabled={!editMode}
						/>
					</Col>
				</Row>
				<Row style={{ '--bs-gutter-x': '18px' }}>
					<Col md={6} className="pa-b-20">
						<FormInput
							type="text"
							name="firstname"
							label="First Name"
							placeholder="Enter First Name"
							value={editProfileFormik.values.firstname}
							onChange={editProfileFormik.handleChange}
							onBlur={editProfileFormik.handleBlur}
							error={
								editProfileFormik.touched.firstname &&
								!!editProfileFormik.errors.firstname
							}
							errorMsg={
								editProfileFormik.touched.firstname
									? editProfileFormik.errors.firstname
									: null
							}
							disabled={!editMode}
						/>
					</Col>
					<Col md={6} className="pa-b-20">
						<FormInput
							type="text"
							name="lastname"
							label="Last Name"
							placeholder="Enter Last Name"
							value={editProfileFormik.values.lastname}
							onChange={editProfileFormik.handleChange}
							onBlur={editProfileFormik.handleBlur}
							error={
								editProfileFormik.touched.lastname &&
								!!editProfileFormik.errors.lastname
							}
							errorMsg={
								editProfileFormik.touched.lastname
									? editProfileFormik.errors.lastname
									: null
							}
							disabled={!editMode}
						/>
					</Col>
				</Row>
				<Row style={{ '--bs-gutter-x': '18px' }}>
					<Col md={6} className="pa-b-20">
						<FormInput
							type="email"
							name="email"
							label="Email"
							placeholder="Enter Email"
							value={editProfileFormik.values.email}
							onChange={editProfileFormik.handleChange}
							onBlur={editProfileFormik.handleBlur}
							error={
								editProfileFormik.touched.email &&
								!!editProfileFormik.errors.email
							}
							errorMsg={
								editProfileFormik.touched.email
									? editProfileFormik.errors.email
									: null
							}
							disabled={!editMode}
						/>
					</Col>
					<Col md={6} className="pa-b-20">
						<FormMobileNoInput
							name="mobile"
							label="Mobile Number"
							placeholder="Enter Mobile Number"
							mobileNoValue={editProfileFormik.values.mobile}
							onMobileNoChange={editProfileFormik.handleChange}
							onMobileNoBlur={editProfileFormik.handleBlur}
							countryCodeValue={
								editProfileFormik.values.countryCode
							}
							onSelectCountry={(_, countryDetails) => {
								editProfileFormik.setFieldValue(
									'countryCode',
									countryDetails.dialCode
								);
							}}
							error={
								editProfileFormik.touched.mobile &&
								!!editProfileFormik.errors.mobile
							}
							errorMsg={editProfileFormik.errors.mobile}
							disabled={!editMode}
							disabledCountry={!editMode}
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={6} className="pa-t-4">
						{!editMode ? (
							<Button
								type="button"
								className="themeButton"
								onClick={() => setOpenModal(true)}
							>
								Edit Account
							</Button>
						) : (
							<CustomButton
								type="submit"
								className="themeButton"
								loading={loading}
							>
								Save Changes
							</CustomButton>
						)}
					</Col>
				</Row>
			</form>
			{openModal && (
				<VerifyOtpModal
					isOpen={openModal}
					handleModal={handleModal}
					setEditMode={setEditMode}
				/>
			)}
		</PageWrapper>
	);
};

export default ManageAccountForm;
