import styled from 'styled-components';

const NewCheckBoxStyle = styled.div`
	width: 100%;
	display: flex;
	height: 65px;
	gap: 18px;
	align-items: center;
	padding-inline: 16px;
	padding-left: 11px;
	color: #313132 !important;
	font-family: 'montserrat-regular';
	font-size: 24px;
	border: 2px solid #31313259 !important;
	outline: none;
	border-radius: 0 !important;

	label {
		margin: 0 !important;
		font-size: 24px;
		font-family: 'poppins-regular';
	}
	input[type='checkbox'] {
		width: 42px;
		height: 42px;
		accent-color: #f95c69;
		margin-bottom: 0px !important;
	}

	input[type='checkbox']:focus-visible {
		outline: none;
	}

	@media (max-width: 600px) {
		height: 45px;
		font-size: 12px;

		input[type='checkbox'] {
			width: 19.4px;
			height: 19.4px;
		}

		label {
			font-size: 11px;
		}
	}

	@media only screen and (min-width: 600px) and (max-width: 960px) {
		height: 57.7px;
		font-size: 18px;

		input[type='checkbox'] {
			width: 22px;
			height: 22px;
		}
		label {
			font-size: 18px;
		}
	}

	@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
		height: 46.3px;
		font-size: 13.5px;

		label {
			font-weight: 400;
			font-size: 13.5px;
		}

		input[type='checkbox'] {
			height: 20.7px;
			width: 20.7px;
		}
	}
`;

export default NewCheckBoxStyle;
