import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Row, Col, Button, FormGroup, Label } from 'reactstrap';
import * as yup from 'yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as validation from '../../../Helper/YupValidation';
import FormInput from '../../Form/FormInput';
import { CameraIcon } from '../../Icons';
import FormSelect from '../../Form/FormSelect';
import FormCheckBox from '../../Form/FormCheckBox';
import PageWrapper from './index.style';
import ImageUpload from '../../Form/ImageUpload';
import { VenueApiRoutes, VenuePanelRoutes } from '../../../Utils/routes';
import AdditionalExtras from './AdditionalExtras';
import RequiredOptions from './RequiredOptions';
import Api from '../../../Helper/Api';
import {
	createFormData,
	formatFoodOptionListData,
	formatPickupLocationOption,
	formatSingleProductDetails,
	formatSubCategoryOption
} from '../utils';
import CustomButton from '../../Common/CustomButton';
import MenuItemFormSkeleton from '../Skeleton/MenuItemFormSkeleton';
import FormInputGroup from '../../Form/FormInputGroup';
import Switch from '../../Common/Switch';
import EmptyData from '../../Common/EmptyData';

const ProductForm = () => {
	const state = useSelector((state) => ({ ...state }));
	const allThemeData = state?.themeChanger;
	const authData = state?.auth;
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const params = useParams();
	const [subCategoryOptionsLoading, setSubCategoryOptionsLoading] =
		useState(false);
	const [pickupLocationLoading, setPickupLocationLoading] = useState(false);
	const [submitFormLoading, setSubmitFormLoading] = useState(false);
	const [getProductLoading, setGetProductLoading] = useState(false);
	const [subCategoryData, setSubCategoryData] = useState([]);
	const [categoryName, setCategoryName] = useState();
	const [pickupLocationData, setPickupLocationData] = useState([]);
	const [dietaryRequirements, setDietaryRequirements] = useState([]);
	const [
		initialSelectedDietaryRequirements,
		setInitialSelectedDietaryRequirements
	] = useState([]);
	const [initialFormData, setInitialFormData] = useState(null);
	const [deletedRequiredOptions, setDeletedRequiredOptions] = useState([]);
	const [deletedRequiredOptionsItems, setDeletedRequiredOptionsItems] =
		useState([]);
	const [deletedAdditionalExtras, setDeletedAdditionalExtras] = useState([]);
	const [requiredOptionPopover, setRequiredOptionPopover] = useState(false);
	const [additionalExtrasPopover, setAdditionalExtrasPopover] =
		useState(false);
	const [isError, setIsError] = useState(false);
	const [posStatus, setPosStatus] = useState(false);
	const isEdit = pathname.includes('/edit');
	const validationSchemaProduct = yup.object().shape({
		image: validation.YUP_VALIDATION.IMAGE,
		category: validation.YUP_VALIDATION.CATEGORY,
		itemName: validation.YUP_VALIDATION.ITEM_NAME,
		basePrice: validation.YUP_VALIDATION.BASE_PRICE,
		pickupLocation: validation.YUP_VALIDATION.PICKUP_LOCATION,
		description: validation.YUP_VALIDATION.DESCRIPTION,
		serviceType: validation.YUP_VALIDATION.ADD_PRODUCT_SERVICE_TYPE,
		stockQuantity: validation.YUP_VALIDATION.STOCK_QUANTITY,
		requiredOptions: validation.YUP_VALIDATION.REQUIRED_OPTIONS,
		additionalExtras: validation.YUP_VALIDATION.ADDITIONAL_EXTRAS
	});
	const requiredOptionPopoverToggle = () => {
		setRequiredOptionPopover(!requiredOptionPopover);
	};
	const additionalExtrasPopoverToggle = () => {
		setAdditionalExtrasPopover(!additionalExtrasPopover);
	};
	const handleDeletedRequiredOptions = (data) => {
		let deletedItems = [];
		if (data?.data?.length > 0) {
			deletedItems = data?.data?.map((item) => {
				if (item?.itemId) {
					return item?.itemId;
				}
				return;
			});
		}
		setDeletedRequiredOptions((prev) => [...prev, data?.id]);
		setDeletedRequiredOptionsItems((prev) => [...prev, ...deletedItems]);
	};
	const handleDeletedRequiredOptionsItem = (itemId) => {
		if (itemId) {
			setDeletedRequiredOptionsItems((prev) => [...prev, itemId]);
		}
	};
	const handleDeletedAdditionalExtras = (itemId) => {
		if (itemId) {
			setDeletedAdditionalExtras((prev) => [...prev, itemId]);
		}
	};
	const handleStockLevelChange = () => {
		setFieldValue('stockLevel', !values?.stockLevel);
	};
	const handleStockLimitChange = () => {
		setFieldValue('stockLimit', !values?.stockLimit);
	};
	const getDietaryRequirements = async () => {
		try {
			const res = await Api('POST', VenueApiRoutes.getFoodOptionList);
			if (res?.data?.status) {
				let formatedData = formatFoodOptionListData(res?.data?.data);
				setDietaryRequirements(formatedData);
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	const getSubCategoryOptions = async () => {
		try {
			setSubCategoryOptionsLoading(true);
			const res = await Api('POST', VenueApiRoutes.getSubCategoryList, {
				bar_id: authData?.selectedVenue?.id
			});
			if (res?.data?.status) {
				let formatedData = formatSubCategoryOption(res?.data?.data);
				setSubCategoryData(
					formatedData?.sort((item1, item2) =>
						item1?.label?.localeCompare(item2?.label)
					)
				);
			} else {
				toast.error(res?.data?.message);
			}
			setSubCategoryOptionsLoading(false);
		} catch (err) {
			setSubCategoryOptionsLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	const getPickupLocation = async () => {
		try {
			setSubCategoryOptionsLoading(true);
			const res = await Api('POST', VenueApiRoutes.getPickupLocation, {
				bar_id: authData?.selectedVenue?.id
			});
			if (res?.data?.status) {
				let formatedData = formatPickupLocationOption(res?.data?.data);
				setPickupLocationData(
					formatedData?.sort((item1, item2) =>
						item1?.label?.localeCompare(item2?.label)
					)
				);
			} else {
				toast.error(res?.data?.message);
			}
			setSubCategoryOptionsLoading(false);
		} catch (err) {
			setSubCategoryOptionsLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	const getProductDetails = async () => {
		try {
			setGetProductLoading(true);
			const res = await Api('POST', VenueApiRoutes.getSingleProduct, {
				id: params?.id,
				bar_id: authData?.selectedVenue?.id
			});
			if (res?.data?.status) {
				let formatedData = formatSingleProductDetails(res?.data?.data);
				setValues(formatedData);
				setInitialSelectedDietaryRequirements(
					formatedData?.dietaryRequirements
				);
				setInitialFormData(formatedData);
				setCategoryName(res?.data?.data?.sub_category?.name);
			} else {
				toast.error(res?.data?.message);
			}
			setGetProductLoading(false);
		} catch (err) {
			setGetProductLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	const submitFormHandler = async (values) => {
		let formData = createFormData({
			values: values,
			barId: authData?.selectedVenue?.id,
			productId: params?.id,
			initialSelectedDietaryRequirements:
				initialSelectedDietaryRequirements,
			deletedRequiredOptions: deletedRequiredOptions,
			deletedRequiredOptionsItems: deletedRequiredOptionsItems,
			deletedAdditionalExtras: deletedAdditionalExtras,
			isEdit: isEdit,
			initialFormData: initialFormData
		});
		try {
			setSubmitFormLoading(true);
			const res = await Api(
				'POST',
				!isEdit
					? VenueApiRoutes.addProduct
					: VenueApiRoutes.editProduct,
				formData
			);
			if (res?.data?.status) {
				toast.success(res?.data?.message);
				navigate(VenuePanelRoutes.manageMenu, { state: { categoryName } });
			} else {
				toast.error(res?.data?.message);
			}
			setSubmitFormLoading(false);
		} catch (err) {
			setSubmitFormLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	const {
		values,
		setFieldValue,
		touched,
		errors,
		handleSubmit,
		handleChange,
		setValues,
		isValid,
		isValidating,
		validateForm,
		isSubmitting
	} = useFormik({
		initialValues: {
			image: null,
			mainCategoryId: '',
			category: '',
			itemName: '',
			basePrice: '',
			pickupLocation: '',
			description: '',
			serviceType: [], //["PICKUP","TABLE"]
			dietaryRequirements: [],
			stockLevel: false,
			stockLimit: false,
			stockQuantity: 0,
			stockRemaining: 0,
			requiredOptions: [], //{ serviceType: null || "PICKUP" || "TABLE" || "BOTH", data: [] }
			additionalExtras: []
		},
		validationSchema: validationSchemaProduct,
		onSubmit: submitFormHandler
	});
	useEffect(() => {
		getSubCategoryOptions();
		getPickupLocation();
		getDietaryRequirements();
		if (isEdit) {
			getProductDetails();
		}
	}, [authData?.selectedVenue?.id]);
	const handleSave = async () => {
		let res = await validateForm(values);
		if (Object.keys(res).length === 0) {
			setIsError(false);
		} else {
			setIsError(true);
		}
		handleSubmit();
	};
	useEffect(() => {
		if (authData?.selectedVenue?.posStatus === '1') {
			setPosStatus(true);
		} else {
			setPosStatus(false);
		}
	}, [authData?.selectedVenue?.posStatus]);
	return (
		<PageWrapper {...allThemeData}>
			{getProductLoading ? (
				<MenuItemFormSkeleton />
			) : (
				<form className="overflow-hidden">
					<Row>
						<Col sm={12} className="pa-b-20">
							<ImageUpload
								name={'image'}
								defaultImage={authData?.selectedVenue?.avatar}
								icon={<CameraIcon height={14} weight={14} />}
								value={values.image}
								setFieldValue={setFieldValue}
								error={touched.image && !!errors.image}
								errorMsg={errors.image}
							/>
						</Col>
					</Row>
					<Row style={{ '--bs-gutter-x': '18px' }}>
						<Col md={6} className="pa-b-20">
							<FormSelect
								name="category"
								label="Category"
								placeholder="Select Category"
								options={subCategoryData}
								value={subCategoryData?.find(
									(item) => item?.value == values.category
								)}
								onChange={(item) => {
									setFieldValue('category', item?.value);
									setFieldValue(
										'mainCategoryId',
										item?.categoryId
									);
									setCategoryName(item?.label);
								}}
								error={touched.category && !!errors.category}
								errorMsg={errors.category}
								isLoading={subCategoryOptionsLoading}
								isSearchable={true}
								disabled={posStatus}
							/>
						</Col>
						<Col md={6} className="pa-b-20">
							<FormInput
								type="text"
								name="itemName"
								label="Item Name"
								placeholder="Enter Item Name"
								value={values.itemName}
								onChange={handleChange}
								error={touched.itemName && !!errors.itemName}
								errorMsg={errors.itemName}
								disabled={posStatus}
							/>
						</Col>
					</Row>
					<Row style={{ '--bs-gutter-x': '18px' }}>
						<Col md={6} className="pa-b-20">
							<FormInputGroup
								type="number"
								name="basePrice"
								label="Base Price"
								placeholder="Enter Base Price"
								value={values?.basePrice}
								onChange={handleChange}
								error={touched.basePrice && !!errors.basePrice}
								errorMsg={errors.basePrice}
								icon={<span className="fs-14">$</span>}
								iconPlacement="start"
								className={'pl-0'}
								iconBackgroundClass={'inputGroupBackground'}
								onBlur={() => {
									values?.basePrice &&
										setFieldValue(
											'basePrice',
											Number(values?.basePrice).toFixed(2)
										);
								}}
								onWheel={(e) => e.target.blur()}
								disabled={posStatus}
							/>
						</Col>
						<Col md={6} className="pa-b-20">
							<FormSelect
								name="pickupLocation"
								label="Pickup Location"
								placeholder="Select Pickup Location"
								options={pickupLocationData}
								value={pickupLocationData?.find(
									(item) =>
										item?.value === values.pickupLocation
								)}
								onChange={(item) => {
									setFieldValue(
										'pickupLocation',
										item?.value
									);
								}}
								error={
									touched.pickupLocation &&
									!!errors.pickupLocation
								}
								errorMsg={errors.pickupLocation}
								isLoading={pickupLocationLoading}
								isSearchable={true}
							/>
						</Col>
					</Row>
					<Row>
						<Col md={12} className="pa-b-20">
							<FormInput
								type="textarea"
								name="description"
								label="Description"
								rows={3}
								placeholder="Enter Description"
								value={values.description}
								onChange={handleChange}
								error={
									touched.description && !!errors.description
								}
								errorMsg={errors.description}
								disabled={posStatus}
							/>
						</Col>
					</Row>
					<div className="pa-b-24">
						<FormGroup className="mb-0">
							<Label
								for={'serviceType'}
								className="fs-14 medium-text themeText label-color mb-0"
							>
								Service Type
							</Label>
							<div className="serviceTypeContainer pa-t-18">
								<div>
									<FormCheckBox
										label={'Takeaway'}
										name="serviceType"
										value={'PICKUP'}
										checked={values?.serviceType?.includes(
											'PICKUP'
										)}
										onChange={handleChange}
									/>
								</div>
								<div>
									<FormCheckBox
										label={'Table Service'}
										name="serviceType"
										value={'TABLE'}
										checked={values?.serviceType?.includes(
											'TABLE'
										)}
										onChange={handleChange}
									/>
								</div>
							</div>
							{touched.serviceType && !!errors.serviceType && (
								<p className="fs-10 semi-bold-text headingTextColor pa-t-6">
									{errors?.serviceType}
								</p>
							)}
						</FormGroup>
					</div>
					<FormGroup className="mb-0">
						<Label
							for={'dietaryRequirements'}
							className="fs-14 medium-text themeText label-color mb-0"
						>
							Dietary Requirements
						</Label>
						<div className="pa-t-18">
							{dietaryRequirements?.length > 0 ? (
								<div className="dietaryRequirementsContainer">
									{dietaryRequirements?.map((item, index) => {
										return (
											<div
												className="dietaryRequirementItem"
												key={index}
											>
												<FormCheckBox
													label={item?.label}
													name="dietaryRequirements"
													value={item?.value}
													checked={values?.dietaryRequirements?.includes(
														item?.value?.toString()
													)}
													onChange={handleChange}
												/>
											</div>
										);
									})}
								</div>
							) : (
								<EmptyData content="No Dietary Requirements Options" />
							)}
						</div>
					</FormGroup>
					<div className="horizontalLine mtb-24" />
					{/* Stock Option */}
					<div className="pa-b-24">
						<div className="">
							<div className="d-flex align-items-center">
								<span className="fs-14 medium-text themeText label-color pr-18">
									Stock Level
								</span>
								<Switch
									name="stockLevel"
									checked={values.stockLevel}
									onChange={() => handleStockLevelChange()}
								/>
							</div>
							<p className={`fs-12 medium-text hintColor pa-t-8`}>
								This is an optional feature available so you can
								track your product limit. You can on/off the
								stock quantity limit you have set at any time by
								swiping the button above.
							</p>
						</div>
						{values?.stockLevel && (
							<>
								<div className="pa-t-18">
									<span className="fs-14 medium-text themeText label-color">
										Total Stock Quantity
									</span>
									<p
										className={`fs-12 medium-text hintColor pa-t-8`}
									>
										Enter your available stock level, each
										time a customer purchases this menu
										item, the stock count will be reduced.
										This item will be marked as unavailable
										when the number reaches 0.
									</p>
									<Row className="pa-t-18">
										<Col md={6}>
											<FormInput
												type="number"
												name="stockQuantity"
												placeholder="Enter quantity here"
												value={values.stockQuantity}
												onChange={(e) => {
													setFieldValue(
														'stockQuantity',
														e?.target?.value
													);
													setFieldValue(
														'stockRemaining',
														e?.target?.value
													);
												}}
												onWheel={(e) => e.target.blur()}
												error={
													touched.stockQuantity &&
													!!errors.stockQuantity
												}
												errorMsg={errors.stockQuantity}
											/>
										</Col>
										<Col md={6}>
											<FormInput
												type="number"
												name="stockRemaining"
												placeholder="Stock Remaining"
												value={values.stockRemaining}
												onChange={handleChange}
												disabled
											/>
											{values.stockRemaining == 0 && (
												<p className="fs-10 semi-bold-text headingTextColor pa-t-6">
													*currently marked as
													unavailable on customer app
												</p>
											)}
										</Col>
									</Row>
								</div>
								<div className="pa-t-18">
									<div className="d-flex align-items-center">
										<span className="fs-14 medium-text themeText label-color pr-18">
											Daily stock limit
										</span>
										<Switch
											name="stockLimit"
											checked={values?.stockLimit}
											onChange={() =>
												handleStockLimitChange()
											}
										/>
									</div>
									<p
										className={`fs-12 medium-text hintColor pa-t-8`}
									>
										Enable this so the stock quantity level
										automatically refreshes each day.
									</p>
								</div>
							</>
						)}
					</div>
					<div className="additionalItemsWrapper">
						<div className="flex-1">
							<RequiredOptions
								name="requiredOptions"
								values={values}
								handleChange={handleChange}
								setFieldValue={setFieldValue}
								touched={touched}
								errors={errors}
								requiredOptionPopover={requiredOptionPopover}
								requiredOptionPopoverToggle={
									requiredOptionPopoverToggle
								}
								handleDeletedRequiredOptions={
									handleDeletedRequiredOptions
								}
								handleDeletedRequiredOptionsItem={
									handleDeletedRequiredOptionsItem
								}
								posStatus={posStatus}
							/>
						</div>
						<div className="flex-1">
							<AdditionalExtras
								name="additionalExtras"
								values={values}
								handleChange={handleChange}
								setFieldValue={setFieldValue}
								touched={touched}
								errors={errors}
								additionalExtrasPopover={
									additionalExtrasPopover
								}
								additionalExtrasPopoverToggle={
									additionalExtrasPopoverToggle
								}
								handleDeletedAdditionalExtras={
									handleDeletedAdditionalExtras
								}
								posStatus={posStatus}
							/>
						</div>
					</div>
					<div className="pa-t-54 d-flex" style={{ gap: '18px' }}>
						<div>
							<CustomButton
								type="button"
								className="themeButton"
								loading={submitFormLoading}
								onClick={handleSave}
							>
								Save
							</CustomButton>
						</div>
						<div>
							<Button
								type="button"
								className="themeBorderButton"
								onClick={() => {
									navigate(VenuePanelRoutes.manageMenu, { state: { categoryName : initialFormData?.categoryName  } })
								}
								}
							>
								Cancel
							</Button>
						</div>
					</div>
					<p
						className={`fs-10 semi-bold-text pa-t-6 headingTextColor ${isError ? 'visible' : 'invisible'
							}`}
					>
						Please fill in all required fields.
					</p>
				</form>
			)}
		</PageWrapper>
	);
};

export default ProductForm;
