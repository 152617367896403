export const BellIcon3 = ({
	width = '24px',
	height = '24px',
	fill = '#FFFFFF',
	...props
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 28 29"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clip-path="url(#clip0_7264_19948)">
				<path
					d="M16.7218 24.3216L4.03738 16.9982C3.7974 16.8597 3.67103 16.571 3.75665 16.3074C3.84143 16.0464 4.0798 15.8782 4.35202 15.903C4.42211 15.8994 4.96092 15.8526 5.81405 15.2624C7.38083 14.1786 8.74182 12.1634 9.60766 10.6637C11.1699 7.95786 14.6422 7.0275 17.3481 8.58973C20.0475 10.1483 20.9801 13.6076 19.4334 16.3107C19.4299 16.3173 19.4263 16.3238 19.4225 16.3303C18.5567 17.83 17.492 20.0163 17.3368 21.915C17.2522 22.949 17.4811 23.439 17.513 23.5015C17.6706 23.7248 17.6441 24.0154 17.4605 24.2193C17.275 24.4252 16.9618 24.4602 16.7218 24.3216Z"
					fill={fill}
				/>
				<circle
					cx="17.6774"
					cy="8.1676"
					r="1.5"
					transform="rotate(30 17.6774 8.1676)"
					stroke={fill}
				/>
				<circle
					cx="10.6762"
					cy="20.2914"
					r="3"
					transform="rotate(30 10.6762 20.2914)"
					fill={fill}
				/>
			</g>
			<defs>
				<clipPath id="clip0_7264_19948">
					<rect
						width="20"
						height="20"
						fill={fill}
						transform="translate(10 0.785156) rotate(30)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
