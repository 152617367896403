import { useSelector } from 'react-redux';
import { Tab, Tabs } from 'react-tabs-scrollable';
import { useEffect, useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { toast } from 'react-toastify';

import ContactUs from '../../Components/Support/ContactUs';
import PageStructure from '../../Components/Common/PageStructure';
import { TabsWrapper, PageWrapper } from './index.style';
import Api from '../../Helper/Api';
import { VenueApiRoutes } from '../../Utils/routes';
import Loader from '../../Components/Common/Loader';

export const Support = () => {
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	const [tabId, setTabId] = useState(0);
	const [faqsLoading, setFaqsLoading] = useState(false);
	const [faqsData, setFaqsData] = useState(null);
	const [supportTabData, setSupportTabData] = useState([
		{ id: 1, name: 'FAQS' },
		{ id: 2, name: 'Contact Us' }
	]);
	const onTabClick = (e, index) => {
		setTabId(index);
	};
	const getFaqs = async () => {
		setFaqsLoading(true);
		try {
			const res = await Api('GET', VenueApiRoutes.faqs);
			if (res?.data?.status) {
				setFaqsData(res?.data?.data?.content);
			} else {
				toast.error(res?.data?.message);
			}
			setFaqsLoading(false);
		} catch (err) {
			setFaqsLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	useEffect(() => {
		(async () => {
			await getFaqs();
		})();
	}, []);
	return (
		<PageStructure
			title="Support"
			pageText={
				<TabsWrapper {...allThemeData}>
					<div className="customScrollableNavbar position-relative pa-t-24">
						<Tabs
							activeTab={tabId}
							onTabClick={onTabClick}
							leftBtnIcon={<i className="fa fa-angle-left"></i>}
							rightBtnIcon={<i className="fa fa-angle-right"></i>}
							navBtnsIconColor={'#4F4F4F'}
							className="customScrollableNavbar"
						>
							{supportTabData?.map((nav, i) => (
								<Tab
									key={i}
									className={`customScrollableNavItem fs-12 regular-text ${
										tabId === i ? 'active' : ''
									}`}
								>
									{nav.name}
								</Tab>
							))}
						</Tabs>
						<div className="borderBottom position-absolute bottom-0 w-100 zIndex-1" />
					</div>
				</TabsWrapper>
			}
		>
			<PageWrapper {...allThemeData}>
				<TabContent activeTab={tabId + 1} className="overflow-hidden">
					<TabPane tabId={1}>
						{faqsLoading ? (
							<span className="loaderContainer">
								<Loader />
							</span>
						) : (
							<div
								dangerouslySetInnerHTML={{
									__html: faqsData
								}}
								className="fs-12"
							/>
						)}
					</TabPane>
					<TabPane tabId={2}>
						<ContactUs />
					</TabPane>
				</TabContent>
			</PageWrapper>
		</PageStructure>
	);
};
