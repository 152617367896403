import NewCustomModal from '../../Components/Common/Modal/NewCustomModal';
import NewVerifyModalWrapper from '../../Components/Authentication/newVerifyOtp.style';
// import loadingVideo from '../../Assets/images/MyTab_Loading_Light.gif';
import loadingVideo from '../../Assets/images/stripe-loading.gif';
import { useNavigate } from 'react-router-dom';
import { VenueProtectedRoutes } from '../../Utils/routes';

const StripeVerifyProcessing = ({ isOpen, handleModal }) => {
	const navigate = useNavigate();
	return (
		<NewCustomModal
			isOpen={isOpen}
			size="sm"
			handleModal={handleModal}
			modalClassName="verifyOtp"
			hideCloseButton
		>
			<NewVerifyModalWrapper>
				<p className="verifyOtpHeading stipeOtpHeading">
					Please open the Stripe pop-up window and follow the prompts
					to complete setting up your Stripe account
				</p>
				<div className="d-flex justify-content-center">
					<img
						src={loadingVideo}
						alt="side-img"
						className="image"
						height={220}
						width={286}
					/>
				</div>
				<p className="helperText mt-4 text-black">
					Have you already completed the Stripe set up? Click{' '}
					<span
						className="spanLink"
						onClick={() =>
							navigate(VenueProtectedRoutes.stripeAccountProcess)
						}
					>
						here
					</span>{' '}
					to refresh.
				</p>
			</NewVerifyModalWrapper>
		</NewCustomModal>
	);
};

export default StripeVerifyProcessing;
