import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import Avatar from 'react-avatar';
import Radium from 'radium';
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle
} from 'reactstrap';

import SidebarWrapper from './sidebar.style';
import { sidebarAdminData, sidebarVenueData } from '../../Utils/data/sidebar';
import NavList from './NavList';
import appNameLogo from '../../Assets/images/Logo.svg';
import {
	BrowsersIcon,
	Cross2,
	DownArrowIcon,
	HamburgerIcon,
	UpArrowIcon
} from '../Icons';
import authActions from '../../Redux/auth/actions';
import { VenuePanelRoutes } from '../../Utils/routes';

const Sidebar = (props) => {
	let sidebar;
	let sideScrollStyle;
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const authData = useSelector((state) => state?.auth);
	const connectedVenueList = authData?.bars;
	const selectedVenue = authData?.selectedVenue;
	const dispatch = useDispatch();
	const location = useLocation();
	const _767PX = useMediaQuery({ maxWidth: 767 });

	const {
		mini,
		drawerWidth,
		miniDrawerWidth,
		onMouseEnter,
		onMouseLeave,
		sidebarTheme,
		closeDrawer,
		themeSetting,
		drawerMiniMethod
	} = props;

	if (
		themeSetting.toolbarAlignValue === '    ' &&
		themeSetting.footerAlignValue === 'above'
	) {
		sideScrollStyle = {
			height: 'calc(100vh - 190px)'
		};
	} else if (themeSetting.toolbarAlignValue === 'above') {
		sideScrollStyle = {
			height: 'calc(100vh - 145px)'
		};
	} else {
		sideScrollStyle = {
			height: 'calc(100vh - 51px)'
		};
	}

	if (themeSetting.sidebarTransParentValue === 'on') {
		sidebar = {
			backgroundImage: `linear-gradient(0deg,rgba(0, 0, 0, 0.8),rgba(0, 0, 0, 0.9)),url(${themeSetting.transparentImage})`,
			backgroundRepeat: 'no-repeat, repeat',
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			width: mini ? miniDrawerWidth : drawerWidth,
			'@media (max-width: 767.98px)': {
				width: mini ? 0 : drawerWidth
			},
			transition: 'width 0.7s linear'
		};
	} else {
		sidebar = {
			width: mini ? miniDrawerWidth : drawerWidth,
			background: sidebarTheme.backgroundColor,
			'@media (max-width: 767.98px)': {
				width: mini ? 0 : drawerWidth
			},
			transition: 'width 0.7s linear'
		};
	}
	const listNameStyle = mini
		? {
				opacity: miniDrawerWidth === drawerWidth ? 1 : 0,
				transform:
					miniDrawerWidth === drawerWidth
						? 'translateZ(0)'
						: 'translate3d(-25px,0,0)',
				display: 'inline',
				whiteSpace: 'break-spaces',
				flex: 1
		  }
		: {
				opacity: !mini ? 1 : 0,
				transform: !mini ? 'translateZ(0)' : 'translate3d(-25px,0,0)',
				display: 'inline',
				whiteSpace: 'break-spaces',
				flex: 1
		  };

	const closeIcon = {
		'@media (max-width: 767.98px)': {
			display: 'block'
		}
	};
	const sidebarData = location.pathname.includes('admin')
		? sidebarAdminData
		: sidebarVenueData;

	const handleSelectVenue = (data) => {
		if (data) {
			dispatch(
				authActions.set_selected_venue({
					...data
				})
			);
		}
	};
	return (
		<SidebarWrapper
			themeSetting={themeSetting}
			sidebarTheme={sidebarTheme}
			mini={mini}
			miniDrawerWidth={miniDrawerWidth}
			drawerWidth={drawerWidth}
			noOfConnectedVenue={connectedVenueList?.length}
			dropdownOpen={dropdownOpen}
		>
			<div
				id="sidebar"
				className="sidebar sideBack"
				style={{
					...sidebar,
					...sideScrollStyle,
					boxShadow: '0px 4px 12px rgb(0 0 0 / 8%)'
				}}
				onMouseEnter={onMouseEnter}
				onMouseLeave={() => {
					onMouseLeave();
					setDropdownOpen(false);
				}}
			>
				<div className="sidebar-header d-flex justify-content-start align-items-center">
					{mini ? (
						<div className={`logo-img ${!_767PX && 'ml-14'}`}>
							<HamburgerIcon
								width={28}
								height={28}
								onClick={drawerMiniMethod}
								className="cursor-pointer"
							/>
						</div>
					) : (
						<div className={`logo-img ${!_767PX && 'ml-14'}`}>
							<Cross2
								width={28}
								height={28}
								onClick={closeDrawer}
								className="cursor-pointer"
							/>
						</div>
					)}
					<NavLink
						to={`${
							location.pathname.includes('admin')
								? '/admin/live'
								: VenuePanelRoutes.home
						}`}
						className="logo-mini"
					>
						<div className="full-name-logo simple-text p-0">
							<img src={appNameLogo} alt="app-name" />
						</div>
					</NavLink>
				</div>
				{/* <div
					className="close-drawer-icon"
					// style={closeIcon}
					onClick={closeDrawer}
				>
					<i className="fas fa-times-circle" />
				</div> */}
				{connectedVenueList?.length !== 0 && (
					<Scrollbars
						autoHide
						renderThumbVertical={({ style, ...props }) => (
							<div
								{...props}
								className="sidebar-scrollbar-style"
							/>
						)}
						renderThumbHorizontal={({ style, ...props }) => (
							<div {...props} />
						)}
						renderTrackVertical={({ style, ...props }) => (
							<div
								{...props}
								style={{
									...style,
									zIndex: 5,
									position: 'absolute',
									width: '2px',
									right: '2px',
									bottom: '2px',
									top: '2px',
									borderRadius: '3px'
								}}
							/>
						)}
					>
						<div className="sidebar-wrapper">
							<ul className="nav">
								{miniDrawerWidth !== drawerWidth && mini ? (
									location.pathname.includes('admin') ? (
										<li className="activeRoleMenuNavLi d-flex align-items-center">
											<a
												className="nav-link main-list fs-14 bold-text w-100"
												href=" "
												alt="123"
											>
												<BrowsersIcon
													height={32}
													width={32}
												/>
											</a>
										</li>
									) : (
										<></>
									)
								) : (
									// <StoreFrontIcon height={32} width={32} />
									location.pathname.includes('admin') && (
										<li className="activeRoleMenuNavLi d-flex align-items-center mb-10">
											<a
												className="nav-link main-list activeRoleMenu fs-14 w-100 bold-text"
												href=" "
												alt="dummy-menu"
											>
												<p> MyTab Staff CMS </p>
											</a>
										</li>
									)
								)}
								{location.pathname.includes('venue') && (
									<>
										<li className="mb-3 venueNameListItem pr-24">
											<div className="list-inline-item w-100 border-radius-0 venue-name-wrapper">
												<Dropdown
													isOpen={dropdownOpen}
													toggle={() => {
														setDropdownOpen(
															(prev) => !prev
														);
													}}
													className="venue-dropdown w-100"
												>
													{connectedVenueList?.length >
														0 && (
														<>
															<DropdownToggle
																color="#fff"
																className="venue w-100"
															>
																<div
																	className={`d-flex w-100 align-items-center justify-content-start gap-5 venueSelectedDropdown ${
																		mini &&
																		miniDrawerWidth !==
																			drawerWidth
																			? 'pl-0 rounded-circle'
																			: 'pl-5'
																	}`}
																>
																	<div
																		className={`d-flex align-items-center flex-1 ${
																			mini &&
																			miniDrawerWidth !==
																				drawerWidth &&
																			'justify-content-center'
																		}`}
																	>
																		<Avatar
																			name={
																				selectedVenue?.restaurantName
																			}
																			size={
																				28
																			}
																			color="#FF5F5F"
																			round={
																				true
																			}
																			src={
																				selectedVenue?.avatar
																			}
																		/>
																		{(!mini ||
																			miniDrawerWidth ===
																				drawerWidth) && (
																			<span
																				className={`venue-name fs-14 medium-text overflow-hidden text-nowrap themeText ${
																					dropdownOpen
																						? 'activeColor'
																						: ''
																				}`}
																			>
																				{
																					selectedVenue?.restaurantName
																				}
																			</span>
																		)}
																	</div>
																	{connectedVenueList?.length >
																		1 && (
																		<>
																			{(!mini ||
																				miniDrawerWidth ===
																					drawerWidth) && (
																				<span className="mr-23">
																					{dropdownOpen ? (
																						<UpArrowIcon
																							height={
																								6
																							}
																							width={
																								8
																							}
																						/>
																					) : (
																						<DownArrowIcon
																							height={
																								6
																							}
																							width={
																								8
																							}
																						/>
																					)}
																				</span>
																			)}
																		</>
																	)}
																</div>
															</DropdownToggle>
															<DropdownMenu className="venueDropdownMenu">
																{connectedVenueList?.length >
																	0 &&
																	connectedVenueList?.map(
																		(
																			item,
																			index
																		) => {
																			return (
																				<Fragment
																					key={
																						index
																					}
																				>
																					{item?.id !==
																					selectedVenue?.id ? (
																						<DropdownItem
																							onClick={() =>
																								handleSelectVenue(
																									item
																								)
																							}
																							className="venueDropdownItem"
																						>
																							<div
																								className={`d-flex w-100 align-items-center justify-content-start gap-5 venueSelectedDropdown ${
																									mini &&
																									miniDrawerWidth !==
																										drawerWidth
																										? 'pl-0 rounded-circle'
																										: 'pl-5'
																								}`}
																							>
																								<div
																									className={`d-flex align-items-center flex-1 ${
																										mini &&
																										miniDrawerWidth !==
																											drawerWidth &&
																										'justify-content-center'
																									}`}
																								>
																									<Avatar
																										name={
																											item?.restaurantName
																										}
																										size={
																											28
																										}
																										color="#FF5F5F"
																										round={
																											true
																										}
																										src={
																											item?.avatar
																										}
																									/>
																									{(!mini ||
																										miniDrawerWidth ===
																											drawerWidth) && (
																										<span
																											className={`venue-name fs-14 medium-text overflow-hidden text-nowrap themeText ${
																												item?.id ===
																												selectedVenue?.id
																													? 'activeColor'
																													: ''
																											}`}
																										>
																											{
																												item?.restaurantName
																											}
																										</span>
																									)}
																								</div>
																							</div>
																						</DropdownItem>
																					) : (
																						<>

																						</>
																					)}
																				</Fragment>
																			);
																		}
																	)}
															</DropdownMenu>
														</>
													)}
												</Dropdown>
											</div>
										</li>
									</>
								)}
								{sidebarData?.length > 0 &&
									sidebarData?.map((list, i) => {
										return (
											<Fragment key={i}>
												{list.type &&
												list.type === 'heading' ? (
													(!mini ||
														miniDrawerWidth ===
															drawerWidth) && (
														<div className="sidelist-header-name">
															<Link
																to={
																	list.routepath
																}
															>
																<i
																	className={
																		list.iconClass
																	}
																/>
															</Link>
														</div>
													)
												) : (
													<NavList
														listNameStyle={
															listNameStyle
														}
														list={list}
														mini={mini}
														miniDrawerWidth={
															miniDrawerWidth
														}
														drawerWidth={
															drawerWidth
														}
													/>
												)}
											</Fragment>
										);
									})}
							</ul>
						</div>
					</Scrollbars>
				)}
			</div>
		</SidebarWrapper>
	);
};

export default Radium(Sidebar);
