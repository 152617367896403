import styled from 'styled-components';

const FormWrapper = styled.div`
	padding-bottom: 2px;
	.borderBox {
		border: 1px solid #eaeaea;
		border-radius: 8px;
	}

	input[type='checkbox'] {
		width: 42px;
		height: 42px;
		accent-color: #f95c69;
	}
	.isServeAlcohol {
		border: 1px solid #eaeaea;
		border-radius: 6px;
		padding: 25px 18px;
		display: flex;
		justify-content: space-between;
		gap: 24px;
		@media only screen and (min-width: 992px) and (max-width: 1020px) {
			flex-direction: column;
			gap: 8px;
			padding: 14px 18px;
		}
	}
	.serviceType {
		border: 1px solid #eaeaea;
		border-radius: 6px;
		padding: 25px 18px;
		display: flex;
		justify-content: space-between;
		gap: 24px;
		@media only screen and (min-width: 992px) and (max-width: 1165px) {
			flex-direction: column;
			gap: 8px;
			padding: 14px 18px;
		}
	}

	.helperLink {
		user-select: none;
		font-size: 24px;
		font-weight: 400;
		color: #000000;
		font-family: 'poppins-regular';
		margin-bottom: 24px;
		.helperLinkSpan {
			color: #f95c69;
			cursor: pointer;
		}
	}
	.tableWrraper {
		border-bottom: 2px solid #31313259;
	}

	.agreement {
		font-family: 'poppins-medium';
		color: #000000;
		font-size: 24px;
		.agreementSpan {
			cursor: pointer;
			font-family: 'poppins-medium';
			color: #f95c69;
		}
	}

	.tableHeading {
		font-size: 22px;
		font-family: 'montserrat-bold';
		text-transform: uppercase;
		text-align: center;
	}

	.dayText {
		font-size: 24px;
		font-family: 'poppins-regular';
	}

	.tableRows {
		border: 2px solid #31313259;
		padding: 12px;
	}

	@media (max-width: 600px) {
		input[type='checkbox'] {
			width: 19.4px;
			height: 19.4px;
		}
		.tableHeading {
			font-size: 11px;
		}
		.dayText {
			font-size: 11px;
		}
		.agreement {
			font-size: 11px;
		}
		.helperLink {
			font-size: 11.5px;
		}
	}

	@media only screen and (min-width: 600px) and (max-width: 960px) {
		input[type='checkbox'] {
			width: 22px;
			height: 22px;
		}
		.agreement {
			font-size: 18px;
		}
		.helperLink {
			font-size: 18px;
		}
	}

	@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 933px) {
		input[type='checkbox'] {
			height: 20.7px;
			width: 20.7px;
		}
		.tableHeading {
			font-size: 12.3px;
		}
		.dayText {
			font-size: 13.5px;
		}
		.agreement {
			font-size: 13.5px;
		}
		.helperLink {
			font-size: 13.5px;
		}
		.tableRows {
			padding: 8px;
		}
	}
`;

export default FormWrapper;
