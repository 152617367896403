import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { VenuePanelRoutes } from '../../Utils/routes';
import CustomPopover2 from '../Common/Popover/CustomPopover2';
import { UserCircleIcon } from '../Icons';

const ProfilePopOver = ({ profileData, setOpenLogoutModal }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const authData = useSelector((state) => state.auth);

	return (
		<CustomPopover2
			button={
				<div className="top-header-profile-class">
					<div className="d-flex align-items-center">
						{profileData.profile_image ? (
							<img
								src={profileData.profile_image}
								alt="profile-image"
								className="h-px-29 w-px-29 rounded"
							/>
						) : (
							<div className="userCircleIconWrapper">
								<UserCircleIcon width={22} height={22} />
							</div>
						)}
						<span className="pl-10 fs-14 medium-text themeText d-none d-md-block">
							{profileData.login_type === 'venue'
								? (profileData.first_name
										? profileData.first_name
										: '') +
								  ' ' +
								  (profileData.last_name
										? profileData.last_name
										: '')
								: (profileData.first_name
										? profileData.first_name
										: '') +
								  ' ' +
								  (profileData.last_name
										? profileData.last_name
										: '')}
						</span>
					</div>
				</div>
			}
			placement="bottom-end"
		>
			<div className="pa-12">
				{authData?.bars?.length !== 0 && (
					<div
						className="pa-b-12 themeText fs-12 medium-text cursor-pointer"
						onClick={() => {
							navigate(
								`${
									location.pathname.includes('admin')
										? '/admin/edit-profile'
										: VenuePanelRoutes.manageAccount
								}`
							);
						}}
					>
						Manage Account
					</div>
				)}
				<div
					className="pa-b-12 themeText fs-12 medium-text cursor-pointer"
					onClick={() => {
						navigate(
							`${
								location.pathname.includes('admin')
									? '/admin/connect-venue'
									: VenuePanelRoutes.connectVenue
							}`
						);
					}}
				>
					Connect Your Venue
				</div>
				{/* {authData?.bars?.length !== 0 && (
					<ul
						className="list-inline language-list-hover ma-0"
						onClick={() => {
							navigate('/venue/subscription');
						}}
					>
						<li className="list-inline-item pa-b-12 themeText fs-12 medium-text cursor-pointer">
							Manage Subscription
						</li>
					</ul>
				)} */}
				{authData?.bars?.length !== 0 && (
					<div
						className="pa-b-12 themeText fs-12 medium-text cursor-pointer"
						onClick={() => {
							navigate(VenuePanelRoutes.mytabStaff);
						}}
					>
						MyTab Staff
					</div>
				)}
				{authData?.bars?.length !== 0 && (
					<div
						className="pa-b-12 themeText fs-12 medium-text cursor-pointer"
						onClick={() => {
							navigate(
								`${
									location.pathname.includes('admin')
										? '/admin/security'
										: VenuePanelRoutes.security
								}`
							);
						}}
					>
						Security
					</div>
				)}
				<div
					className="themeText fs-12 medium-text cursor-pointer"
					onClick={() => setOpenLogoutModal(true)}
				>
					Logout
				</div>
			</div>
		</CustomPopover2>
	);
};

export default ProfilePopOver;
