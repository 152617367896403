export const Cross2 = ({ width, height, fill, ...props }) => {
	return (
		<svg
			width={width ?? '24px'}
			height={height ?? '24px'}
			viewBox="0 0 27 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M21.0938 6.40625L5.90625 21.5938"
				stroke="black"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21.0938 21.5938L5.90625 6.40625"
				stroke="black"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
