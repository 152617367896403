import React from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { PageWrapper } from './index.style';

const PageLoader = ({ loading = false }) => {
	if (loading) {
		return (
			<PageWrapper>
				<div className="loaderContainer">
					<div className="loader">
						<RotatingLines
							strokeColor="#FF5F5F"
							strokeWidth="4"
							animationDuration="1.5"
							width="66"
							visible={true}
						/>
					</div>
				</div>
			</PageWrapper>
		);
	}
	return;
};

export default PageLoader;
