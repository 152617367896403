import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PickupLocationModal from './PickupLocationModal';
import { PlusIcon } from '../../Icons';
import PickupLocationCard from './PickupLocationCard';
import Api from '../../../Helper/Api';
import { VenueApiRoutes } from '../../../Utils/routes';
import PickupLocationCardSkeleton from './Skeleton/PickupLocationCardSkeleton';
import DeleteConfirmModal from './DeleteConfirmModal';
import EmptyData from '../../Common/EmptyData';

const PickupLocation = () => {
	const authData = useSelector((state) => ({ ...state.auth }));
	const [openModal, setOpenModal] = useState(false);
	const [modalData, setModalData] = useState({
		type: '',
		data: ''
	});
	const [pickupLocationLoading, setPickupLocationLoading] = useState(false);
	const [pickupLocationList, setPickupLocationList] = useState([]);
	const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
	const [deletePickuplocationId, setDeletePickuplocationId] = useState(null);
	const handleDeleteConfirmModal = () => {
		setDeleteConfirmModal((prev) => !prev);
	};
	const handleModal = () => {
		setOpenModal((prev) => !prev);
	};
	const getPickupLocationList = async () => {
		setPickupLocationLoading(true);
		try {
			const res = await Api(
				'POST',
				VenueApiRoutes.getPickupLocationList,
				{
					bar_id: authData?.selectedVenue?.id?.toString()
				}
			);
			if (res?.data?.status) {
				setPickupLocationList(res?.data?.data);
			} else {
				toast.error(res?.data?.message);
			}
			setPickupLocationLoading(false);
		} catch (err) {
			setPickupLocationLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	const deletePickupLocation = async () => {
		setPickupLocationLoading(true);
		try {
			const res = await Api(
				'DELETE',
				VenueApiRoutes.deletePickupLocation,
				{
					bar_id: authData?.selectedVenue?.id?.toString(),
					id: '' + deletePickuplocationId
				}
			);
			if (res?.data?.status) {
				toast.success(res?.data?.message);
				await getPickupLocationList();
				setDeleteConfirmModal(false);
			} else {
				toast.error(res?.data?.message);
			}
			setPickupLocationLoading(false);
		} catch (err) {
			setPickupLocationLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	useEffect(() => {
		getPickupLocationList();
	}, [authData?.selectedVenue?.id]);
	return (
		<div className="d-flex flex-column">
			<p className="fs-12 regular-text">
				Add your venue's pick up locations to let customers know where
				to collect menu items on their order.
				<br />
				<span className="fs-12 medium-text">Examples:</span> Collect at
				counter, collect at rooftop bar, collect inside cafe etc.
			</p>
			<div className="ptb-24">
				<Button
					type="button"
					className="themeBorderButton d-flex align-items-center"
					onClick={() => {
						setOpenModal(true);
						setModalData({
							type: 'add',
							data: null
						});
					}}
				>
					<PlusIcon height={18} width={18} className="mr-6" /> Add
					Pick Up Location
				</Button>
			</div>
			{pickupLocationLoading ? (
				<PickupLocationCardSkeleton />
			) : (
				<>
					<Row
						style={{
							'--bs-gutter-x': '24px',
							'--bs-gutter-y': '24px'
						}}
					>
						{pickupLocationList?.length > 0 ? (
							pickupLocationList?.map((item, index) => (
								<Col xl={4} lg={6} key={index}>
									<PickupLocationCard
										key={item?.id}
										cardData={item}
										setOpenModal={setOpenModal}
										setModalData={setModalData}
										deletePickupLocation={
											deletePickupLocation
										}
										setDeleteConfirmModal={
											setDeleteConfirmModal
										}
										setDeletePickuplocationId={
											setDeletePickuplocationId
										}
									/>
								</Col>
							))
						) : (
							<div className="pa-t-100">
								<EmptyData content="No pick up locations found" />
							</div>
						)}
					</Row>
				</>
			)}
			<PickupLocationModal
				isOpen={openModal}
				handleModal={handleModal}
				modalData={modalData}
				getPickupLocationList={getPickupLocationList}
			/>
			<DeleteConfirmModal
				isOpen={deleteConfirmModal}
				handleModal={handleDeleteConfirmModal}
				deleteLoading={pickupLocationLoading}
				handleDeletePickuplocation={deletePickupLocation}
			/>
		</div>
	);
};

export default PickupLocation;
