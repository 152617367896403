export const formatTableData = (data) => {
	let tableData = [];
	if (data?.length > 0) {
		tableData = data?.map((item) => {
			return {
				id: item?.id,
				order_date: item?.orderDate,
				customer: item?.user?.fullName ?? 'MyTab User',
				order_no: item?.orderNo,
				orderServiceType:
					item?.orderServiceType === 'PICKUP'
						? 'Takeaway'
						: item?.orderServiceType === 'TABLE'
						? 'Table Service'
						: item?.orderServiceType === 'BOTH'
						? 'Both'
						: '-',
				pickupCode: item?.pickupCode ? item?.pickupCode : '-',
				tableCode: item?.orderTableNumbers[0]?.tableCode ?? '-',
				order_status:
					item?.orderStatus?.toUpperCase() === 'PICKEDUP'
						? item?.orderServiceType === 'TABLE'
							? 'SERVED'
							: 'PICKED UP'
						: item?.orderStatus?.toUpperCase(),
				payment_status: item?.paymentStatus,
				taxes:
					'$ ' + Number(item?.totalOrderTax)?.toFixed(2)?.toString(),
				// taxes_percentage: item?.order_taxes[0]?.percentage,
				promo_code: item?.coupon?.code ?? '-',
				promo_code_amount:
					'$ ' +
						Number(item?.promocode_amount)
							?.toFixed(2)
							?.toString() ?? '',
				promo_code_number: item?.promocode_discount ?? '-',
				// promo_code_type:
				// 	item?.is_fixed === 'Yes' ? 'Fixed' : 'Percentage',
				total_amount:
					'$ ' + Number(item?.total)?.toFixed(2)?.toString(),
				mytab_fee:
					'$ ' + Number(item?.transactionFee)?.toFixed(2)?.toString(),
				stripe_transaction_fee:
					'$ ' + Number(item?.stripeFee)?.toFixed(2)?.toString(),
				pos_integration_fee: '$ ' + item?.posOrderFee,
				docket_printing_fee: '$ ' + item?.docketOrderFee,
				refund:
					item?.refundStatus === 'PartialRefunded'
						? 'Partial Refunded'
						: item?.refundStatus,
				refund_amount:
					'$ ' + Number(item?.refundAmount)?.toFixed(2)?.toString(),
				net_revenue:
					'$ ' + Number(item?.net_revenue)?.toFixed(2)?.toString(),
				rowData: item
			};
		});
	}
	return tableData;
};

export const ordersTypeFilter = [
	{ name: 'Current Orders', value: 'current' },
	{ name: 'Past Orders', value: 'past' },
	{ name: 'Refunded Orders', value: 'refund' },
	{ name: 'Not Picked Up', value: 'cancel' },
	{ name: 'Too Intoxicated', value: 'Intoxicated' },
	{ name: 'Promo Code Orders', value: 'promo_code' }
];
