import authAction from './actions';

const initState = {
	isLogin: false,
	accessToken: null,
	first_name: null,
	last_name: null,
	manager_name: null,
	email: null,
	key: null,
	profile_image: null,
	modules: null,
	admin_role: null,
	mfa_qr_code: null,
	address: null,
	bars: [],
	mobile: null,
	pronouns: null,
	login_type: 'venue',
	selectedVenue: null,
	unAuthorizeMessage: '',
	copiedRequiredOptionsSection: null,
	copiedRequiredOptions: null,
	copiedMenuItemOptions: null,
	copiedAdditionalExtras: null,
	preVenueProcess: null,
	preAccountProcess: null,
	accountProcesses: null
};

export default function rootReducer(state = initState, action) {
	switch (action.type) {
		case authAction.CHANGE_LOGIN_TYPE:
			return {
				...state,
				login_type: action.login_type
			};
		case authAction.LOGIN:
			return {
				...state,
				isLogin: action.isLogin,
				accessToken: action.accessToken,
				first_name: action.first_name,
				last_name: action.last_name,
				email: action.email,
				key: action.key,
				id: action.id,
				profile_image: action.profile_image,
				modules: action.modules,
				admin_role: action.admin_role,
				mfa_qr_code: action.mfa_qr_code,
				pronouns: action.pronouns
			};
		case authAction.VENUE_LOGIN:
			return {
				...state,
				isLogin: action.isLogin,
				accessToken: action.accessToken,
				email: action.email,
				first_name: action.first_name,
				last_name: action.last_name,
				key: action.key,
				id: action.id,
				profile_image: action.profile_image,
				modules: action.modules,
				admin_role: action.admin_role,
				manager_name: action.manager_name,
				mobile: action.mobile,
				country_code: action.country_code,
				address: action.address,
				bars:
					action.bars?.length > 0
						? action.bars?.map((item) => {
								return { ...item, dashboardStatus: 'VIEW' };
						  })
						: [],
				selectedVenue:
					action.bars?.length > 0
						? { ...action.bars[0], dashboardStatus: 'VIEW' }
						: null,
				mfa_qr_code: action.mfa_qr_code,
				pronouns: action.pronouns,
				subscriptions: action.subscriptions
			};

		case authAction.VENUE_LOGIN_CHANGE:
			return {
				...state,
				isLogin: action.isLogin
			};

		case authAction.SET_PREVENUE_PROCESS:
			return {
				...state,
				preVenueProcess: action.preVenueProcess
			};
		case authAction.SET_PRE_ACCOUNT_PROCESS:
			return {
				...state,
				preAccountProcess: action.preAccountProcess
			};
		case authAction.SET_ACCOUNT_PROCESS_CHANGE:
			return {
				...state,
				accountProcesses: action.accountProcesses
			};
		case authAction.LOGOUT:
			return {
				...state,
				isLogin: action.isLogin,
				accessToken: null
			};
		case authAction.ADMIN:
			return {
				...state,
				firstName: action.firstName,
				lastName: action.lastName,
				email: action.email,
				profile_image: action.profile_image
			};
		case authAction.UPDATE_PROFILE:
			return {
				...state,
				first_name: action.first_name,
				last_name: action.last_name,
				dob: action.dob,
				manager_name: action.manager_name,
				email: action.email,
				profile_image: action.profile_image,
				address: action.address,
				mobile: action.mobile,
				country_code: action.country_code,
				pronouns: action.pronouns
			};
		case authAction.UPDATE_VENUE_LIST:
			return {
				...state,
				bars: action?.bars?.map((item) => {
					return {
						...item,
						dashboardStatus: state?.bars?.find(
							(value) => value?.id == item?.id
						)?.dashboardStatus
					};
				})
			};
		case authAction.UPDATE_SUBSCRIPTION:
			return {
				...state,
				subscriptions: [action.subscriptions]
			};
		case authAction.SET_SELECTED_VENUE:
			return {
				...state,
				selectedVenue: action.selectedVenue
			};
		case authAction.UPDATE_SELECTED_VENUE:
			return {
				...state,
				selectedVenue: {
					...state.selectedVenue,
					...action.data
				},
				bars: state?.bars?.map((item) => {
					if (item?.id == state?.selectedVenue?.id) {
						return { ...item, ...action.data };
					}
					return item;
				})
			};
		case authAction.SET_UNAUTHORIZE_MESSAGE:
			return {
				...state,
				unAuthorizeMessage: action.unAuthorizeMessage
			};
		case authAction.SET_DOCKET_PRINTING_FEATURE:
			return {
				...state,
				selectedVenue: {
					...state.selectedVenue,
					docketStatus: action?.docketStatus
				},
				bars: state?.bars?.map((item) => {
					if (item?.id === action?.barId) {
						return {
							...item,
							docketStatus: action?.docketStatus
						};
					}
					return item;
				})
			};
		case authAction.SET_POS_FEATURE:
			return {
				...state,
				selectedVenue: {
					...state.selectedVenue,
					posStatus: action?.posStatus,
					posReferralLink: action?.posReferralLink
				},
				bars: state?.bars?.map((item) => {
					if (item?.id === action?.barId) {
						return {
							...item,
							posStatus: action?.posStatus,
							posReferralLink: action?.posReferralLink
						};
					}
					return item;
				})
			};
		// Copy/Paste Functions
		case authAction.COPY_PASTE_REQUIRED_OPTIONS_SECTION:
			const newActionData = action?.data?.map(
				(eachValue) => {
					if (eachValue?.id) {
						delete eachValue.id;
					}
					eachValue.data.map(
						(eachItem) => {
							if(eachItem?.itemId){
								delete eachItem.itemId;
							}
						}
					)
					return eachValue;
				}
			)
			return {
				...state,
				copiedRequiredOptionsSection: newActionData
			};
		case authAction.COPY_PASTE_REQUIRED_OPTIONS:
			const copiedRequiredOptionsData = action?.data?.map(
				(eachValue) => {
					if (eachValue?.itemId) {
						delete eachValue.itemId;
					}
					return eachValue;
				}
			)			
			return {
				...state,
				copiedRequiredOptions: copiedRequiredOptionsData,
				copiedMenuItemOptions: copiedRequiredOptionsData
			};
		case authAction.COPY_PASTE_ADDITIONAL_EXTRAS:
			const copiedAdditionalExtrasData = action?.data?.map(
				(eachValue) => {
					if (eachValue?.itemId) {
						delete eachValue.itemId;
					}
					return eachValue;
				}
			)
			return {
				...state,
				copiedAdditionalExtras: copiedAdditionalExtrasData,
				copiedMenuItemOptions: copiedAdditionalExtrasData
			};
		case authAction.SET_PASSCODE_STATUS:
			return {
				...state,
				selectedVenue: {
					...state.selectedVenue,
					passcodeStatus: action?.passcodeStatus
				},
				bars: state?.bars?.map((item) => {
					if (item?.id === action?.barId) {
						return {
							...item,
							passcodeStatus: action?.passcodeStatus
						};
					}
					return item;
				})
			};
		case authAction.SET_DASHBOARD_STATUS:
			return {
				...state,
				selectedVenue: {
					...state.selectedVenue,
					dashboardStatus: action?.dashboardStatus
				},
				bars: state?.bars?.map((item) => {
					if (item?.id === action?.barId) {
						return {
							...item,
							dashboardStatus: action?.dashboardStatus
						};
					}
					return item;
				})
			};
		case authAction.SET_PASSCODE_LENGTH:
			return {
				...state,
				selectedVenue: {
					...state.selectedVenue,
					passcodeLength: action?.passcodeLength
				},
				bars: state?.bars?.map((item) => {
					if (item?.id === action?.barId) {
						return {
							...item,
							passcodeLength: action?.passcodeLength
						};
					}
					return item;
				})
			};

		case authAction.SET_CURRENT_PRODUCT:
			return {
				...state,
				currentProduct: action?.currentProduct
			};
		default:
			return state;
	}
}
