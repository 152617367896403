import styled from 'styled-components';
import { palette } from 'styled-theme';

const SidebarWrapper = styled.div`
	background-color: ${(props) => props.sidebarTheme.backgroundColor};
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
	.close-drawer-icon {
		display: ${(props) =>
			props?.mini === true ? 'none' : 'block'} !important;
		position: absolute;
		right: 15px;
		top: 15px;
		font-size: 20px;
		z-index: 5;
		cursor: pointer;
		color: ${(props) =>
			props.themeSetting.sidebarTransParentValue === 'on'
				? 'white'
				: props.sidebarTheme.textColor} !important;
	}

	.sidebar-scrollbar-style {
		position: relative;
		display: block;
		width: 100%;
		cursor: pointer;
		border-radius: inherit;
		background-color: rgba(0, 0, 0, 0.2);
		/* height: 206px; */
		transform: translateY(0px);
		background-color: ${(props) =>
			props.themeSetting.sidebarTransParentValue === 'on'
				? 'white'
				: props.sidebarTheme.textColor} !important;
		opacity: 0.4;
	}

	.sidelist-header-name {
		text-transform: uppercase !important;
		font-weight: 700;
		display: block;
		width: 100%;
		padding: 0.5rem 1rem;
		margin: 5px 10px 0;
		color: ${(props) =>
			props.themeSetting.sidebarTransParentValue === 'on'
				? 'white'
				: props.sidebarTheme.textColor} !important;
		opacity: 0.4;
		font-size: 13px;
		white-space: nowrap;
	}

	.second-child-list-icon {
		line-height: 25px !important;
	}

	.sideBack {
		background: ${palette('primary', 1)};
		overflow: hidden;
		/* margin-bottom: ${(props) =>
			props.themeSetting.footerAlignValue === 'above' ? '50px' : '0px'};
		margin-top: ${(props) =>
			props.themeSetting.toolbarAlignValue === 'above'
				? '65px'
				: '0px'}; */
		display: flex;
		flex-direction: column;
		z-index: 110;
	}

	.sidebar-wrapper {
		z-index: 999999;
		.nav-link {
			color: ${(props) =>
				props.themeSetting.sidebarTransParentValue === 'on'
					? 'white'
					: props.sidebarTheme.textColor} !important;
			border-radius: 0px !important;
			padding-left: 24px !important;
		}

		.arrow-sidebar {
			color: ${(props) =>
				props.themeSetting.sidebarTransParentValue === 'on'
					? 'white'
					: props.sidebarTheme.textColor} !important;
			margin-top: 6px !important;
		}

		.main-list,
		.child-list {
			line-height: 20px;
			font-size: 16px;
			white-space: nowrap;
			position: relative;
			text-transform: capitalize;
			-webkit-transform: translateZ(0);
			-ms-transform: translateZ(0);
			transform: translateZ(0);
			display: block;
			height: auto;
			opacity: 1;
			transition: all 0.15s linear;
			&:hover {
				// style1
				/* font-weight: 600; */

				// style5
				border-bottom-right-radius: ${(props) =>
					props.themeSetting.activeLinkStyle === 'style5'
						? '30px !important'
						: '4px'};
				border-top-right-radius: ${(props) =>
					props.themeSetting.activeLinkStyle === 'style5'
						? '30px !important'
						: '4px'};
				border-right: ${(props) =>
					props.themeSetting.activeLinkStyle === 'style5'
						? props.themeSetting.sidebarTransParentValue === 'on'
							? '6px solid white !important'
							: '6px solid ' +
							  props.sidebarTheme.textColor +
							  '!important'
						: ''};

				// style2
				border: ${(props) =>
					props.themeSetting.activeLinkStyle === 'style2'
						? props.themeSetting.sidebarTransParentValue === 'on'
							? '2px solid ' +
							  props.themeSetting.sidebarTransParentActiveBack
							: '2px solid ' + props.sidebarTheme.activeColor
						: ''};
				margin: ${(props) =>
					props.themeSetting.activeLinkStyle === 'style2'
						? '5px 8px 0 !important'
						: ''};

				//  style3
				border-left: ${(props) =>
					props.themeSetting.activeLinkStyle === 'style3'
						? props.themeSetting.sidebarTransParentValue === 'on'
							? '4px solid ' +
							  props.themeSetting.sidebarTransParentActiveBack
							: '4px solid ' + props.sidebarTheme.activeColor
						: ''};
				border-radius: ${(props) =>
					props.themeSetting.activeLinkStyle === 'style3'
						? '0 !important'
						: ''};

				// style4
				/* color: ${(props) =>
					props.themeSetting.activeLinkStyle === 'style4'
						? props.themeSetting.sidebarTransParentValue === 'on'
							? props.themeSetting.sidebarTransParentActiveColor +
							  '!important'
							: props.sidebarTheme.activeTextColor + '!important'
						: props.sidebarTheme.activeTextColor + '!important'}; */

				color: ${(props) => props.sidebarTheme.activeColor} !important;

				svg path {
					fill: ${(props) =>
						props.sidebarTheme.activeColor} !important;
				}
			}
			svg {
				margin-left: 8px;
			}
		}
		.venue_selected_img,
		.venue_img {
			border-radius: 50%;
			margin-left: 11px;
			@media only screen and (max-width: 767.98px) {
				margin-left: 0px;
			}
		}
		.venue-dropdown {
			.dropdown-menu {
				&.show {
					.venueDropdown {
						padding: 0px;
						margin-bottom: 10px;
					}
					margin-left: 13px;
					margin-top: -10px;
					width: 244px;
					border: 0px;
					border-radius: 0px 0px 5px 5px;
					@media only screen and (max-width: 767.98px) {
						margin-left: 0px !important;
						width: 270px;
					}
				}
			}
			.venue {
				padding-right: 0 !important;
				padding-left: 0 !important;
				background-color: #f9f9f9;
				border-radius: ${(props) =>
					props.dropdownOpen ? '24px 24px 0 0' : '48px'};
				padding-block: 6px !important;
				&:active {
					background-color: #f9f9f9 !important;
				}
			}
		}
		.child-list {
			margin: 0;
			line-height: 20px;
			font-size: 12px;
			font-weight: 400;
			white-space: nowrap;
			position: relative;
			color: #fff;
			-webkit-transform: translateZ(0);
			-ms-transform: translateZ(0);
			transform: translateZ(0);
			display: block;
			height: auto;
			opacity: 1;
			transition: all 0.15s linear;
		}

		.active-sidebar-type-class {
			border-left: 3px solid
				${(props) =>
					props.themeSetting.sidebarTransParentValue === 'on'
						? props.themeSetting.sidebarTransParentActiveColor
						: props.sidebarTheme.activeTextColor} !important;
			.child-list {
				margin: 5px 7px 0 !important;
			}
		}

		.active {
			// style1
			/* font-weight: 600; */

			// style5
			border-bottom-right-radius: ${(props) =>
				props.themeSetting.activeLinkStyle === 'style5'
					? '30px !important'
					: '4px'};
			border-top-right-radius: ${(props) =>
				props.themeSetting.activeLinkStyle === 'style5'
					? '30px !important'
					: '4px'};
			border-right: ${(props) =>
				props.themeSetting.activeLinkStyle === 'style5'
					? props.themeSetting.sidebarTransParentValue === 'on'
						? '6px solid white !important'
						: '6px solid ' +
						  props.sidebarTheme.textColor +
						  '!important'
					: ''};

			// style2
			border: ${(props) =>
				props.themeSetting.activeLinkStyle === 'style2'
					? props.themeSetting.sidebarTransParentValue === 'on'
						? '2px solid ' +
						  props.themeSetting.sidebarTransParentActiveBack
						: '2px solid ' + props.sidebarTheme.activeColor
					: ''};
			margin: ${(props) =>
				props.themeSetting.activeLinkStyle === 'style2'
					? '5px 8px 0 !important'
					: ''};

			//  style3
			border-left: ${(props) =>
				props.themeSetting.activeLinkStyle === 'style3'
					? props.themeSetting.sidebarTransParentValue === 'on'
						? '4px solid ' +
						  props.themeSetting.sidebarTransParentActiveBack
						: '4px solid ' + props.sidebarTheme.activeColor
					: ''};
			border-radius: ${(props) =>
				props.themeSetting.activeLinkStyle === 'style3'
					? '0 !important'
					: ''};

			// style4
			/* color: ${(props) =>
				props.themeSetting.activeLinkStyle === 'style4'
					? props.themeSetting.sidebarTransParentValue === 'on'
						? props.themeSetting.sidebarTransParentActiveColor +
						  '!important'
						: props.sidebarTheme.activeTextColor + '!important'
					: props.sidebarTheme.activeTextColor + '!important'}; */

			color: ${(props) => props.sidebarTheme.activeColor} !important;

			/* svg path{
                stroke: ${(props) => props.sidebarTheme.activeColor} !important;
            } */
			svg path {
				fill: ${(props) => props.sidebarTheme.activeColor} !important;
			}
		}
	}

	.sidebar-header {
		visibility: hidden !important;
		@media only screen and (max-width: 767.98px) {
			visibility: visible !important;
		}
		cursor: pointer;
		position: relative;
		z-index: 111;
		padding: 8px 0.7rem;
		min-height: 59px;
		background-color: ${(props) =>
			props.themeSetting.sidebarTransParentValue !== 'on' &&
			props.sidebarTheme.header &&
			props.sidebarTheme.header};
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
		@media only screen and (max-width: 767.98px) {
			padding: 12px 0.7rem;
		}
		.simple-text {
			text-transform: uppercase;
			white-space: nowrap;
			font-size: 1rem;
			color: rgb(255, 255, 255);
			font-weight: 400;
			line-height: 30px;
			text-decoration: none;
			overflow: hidden;
		}
		.logo-mini {
			opacity: 1;
			text-align: center;
			margin-left: 12px;
			padding: 6px 0px 5px;
			img {
				height: 30px;
				@media only screen and (max-width: 767.98px) {
					height: 30px;
				}
			}
			.full-name-logo {
				display: ${(props) =>
					!props.mini
						? 'block'
						: props.miniDrawerWidth === props.drawerWidth
						? 'block'
						: 'none'};
				margin-left: 10px;
			}
		}
		.logo-text {
			display: block;
			opacity: 1;
			transform: translateZ(0px);
			padding: 11px 0px 5px;
			/* color: ${(props) =>
				props.themeSetting.sidebarTransParentValue === 'on'
					? 'white'
					: props.sidebarTheme.textColor} !important; */
			font-weight: 600;
		}
	}
	@media only screen and (max-width: 767.98px) {
		.sidebar-wrapper ul a {
			padding: 12px !important;
			svg {
				margin-left: 0px !important;
			}
		}
	}
	.sidebar-wrapper .main-list.activeRoleMenu {
		background: linear-gradient(91.43deg, #fd6461 0%, #ff9568 100%);
		color: #fff !important;
		padding: 8px 16px;
		&:hover {
			.stroke path {
				stroke: #ffffff !important;
			}
			.fill path {
				fill: #ffffff !important;
			}
		}
		p {
			padding: 1px 0px;
			@media only screen and (max-width: 767.98px) {
				margin-left: 0px;
			}
		}
	}
	.sidebarCustomDivider {
		border-bottom: 1px solid #eaeaea;
	}
	.sidebar .nav i {
		line-height: 15px !important;
		@media only screen and (max-width: 767.98px) {
			line-height: 20px !important;
		}
	}
	.sidebar .nav li .card .card-body a {
		margin: 0px !important;
		&:first-child {
			margin-top: 15px !important;
		}
	}
	.sidebarCustomNavItem {
		margin-bottom: 10px;
	}
	.sidebar .nav li {
		min-height: 48px;
	}

	.venue-dropdown {
		.activeColor {
			color: ${(props) => props.sidebarTheme.activeColor} !important;
		}
		button {
			outline: 0 !important;
			border: 0px !important;
			@media only screen and (max-width: 575.98px) {
				padding-left: 10px;
				padding-right: 10px;
				padding-top: 5px;
				margin-top: 6px;
				margin-bottom: 6px;
				img {
					height: 24px;
					width: 24px;
				}
			}
			@media only screen and (max-width: 380px) {
				padding-left: 5px;
				padding-right: 5px;
			}
			.venue-name {
				padding-left: 16px;
				padding-right: 16px;
				color: ${(props) => props.sidebarTheme.textColor};
				@media only screen and (max-width: 575.98px) {
					padding-left: 8px;
					padding-right: 8px;
					font-size: 12px !important;
				}
			}
			&:hover {
				outline: 0 !important;
				border: 0px !important;
			}
			&:active {
				outline: 0 !important;
				border: 0px !important;
				background-color: #fff !important;
				color: ${(props) => props.sidebarTheme.textColor};
			}
		}
		.dropdown-menu {
			border: 0px !important;
		}
	}
	.activeRoleMenuNavLi {
		background: linear-gradient(91.43deg, #fd6461 0%, #ff9568 100%);
	}
	.venueNameListItem {
		border-bottom: 1px solid #eaeaea;
		padding-left: 24px !important;
	}
	.venue-name-wrapper {
		padding-block: 24px;
	}
	.sidebar-wrapper .main-list svg {
		margin-left: 0;
	}
	.venueDropdownMenu {
		background-color: #f9f9f9 !important;
		margin-left: 0 !important;
		width: 100% !important;
		border-radius: 0 0 24px 24px !important;
		padding-block: 0 !important;
		margin-top: 0 !important;
		.venueDropdownItem {
			padding: 0 !important;
			padding-block: 6px !important;
			background-color: transparent !important;
			&:active {
				background-color: #f9f9f9 !important;
			}
		}
	}
	.itemHeight {
		display: block !important;
		height: 20px !important;
		max-height: 20px !important;
		overflow: hidden;
	}
`;

export default SidebarWrapper;
