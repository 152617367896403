import { useRef, useState } from 'react';
import { useFormik } from 'formik';
import { Row, Col, Button } from 'reactstrap';

import * as yup from 'yup';
import * as validation from '../../Helper/YupValidation';
import TableStyle from '../../Components/Common/TableStyle';
import TableV6 from '../../Components/Common/TableV6';

import { ImageResolution } from '../../Helper/constant';
import FormInput from '../Form/FormInput';
import ReactFormSelect from '../../Components/Form/FormSelect';
import FormCheckBox from '../Form/FormCheckBox';
import { initialData } from './utils';

const AddStaffForm = (props) => {
	const profilePic = useRef(null);
	const [tableData, setTableData] = useState(initialData);
	const validationSchema = yup.object().shape({
		firstname: validation.YUP_VALIDATION.FIRST_NAME,
		lastname: validation.YUP_VALIDATION.LAST_NAME,
		email: validation.YUP_VALIDATION.EMAIL,
		mobileNo: validation.YUP_VALIDATION.MOBILE_NUMBER,
		password: validation.YUP_VALIDATION.PASSWORD,
		confirmPassword: validation.YUP_VALIDATION.CONFIRM_PASSWORD
	});

	const [uploadImage, setUploadImage] = useState(null);
	const [imageObj, setImageObj] = useState(null);

	const formik = useFormik({
		initialValues: {
			firstname: '',
			lastname: '',
			email: '',
			staffRole: ''
		},
		validationSchema,
		onSubmit: (values) => {}
	});

	const handleImage = (e, setValue) => {
		if (e.target.files[0]) {
			let fileObj = e.target.files[0];
			setImageObj(fileObj);
			if (fileObj) {
				const img = new Image();

				img.src = window.URL.createObjectURL(fileObj);

				img.onload = function () {
					const width = img.naturalWidth,
						height = img.naturalHeight;

					window.URL.revokeObjectURL(img.src);
					setValue('width', width);
					setValue('height', height);
					formik.setFieldTouched('image', true, true);
					if (
						width >= ImageResolution.WIDTH &&
						height >= ImageResolution.HEIGHT
					) {
						setValue('image', URL.createObjectURL(fileObj));
						setUploadImage(URL.createObjectURL(fileObj));
					}
				};
			} else {
				//No file was input or browser doesn't support client side reading
				// form.submit();
			}
		} else {
			setUploadImage(null);
		}
	};

	const handleDateChange = (date) => {
		formik.setFieldValue('dob', date);
	};

	const columns = [
		{
			Header: 'Permissions',
			accessor: 'permissions',
			className: 'justify-content-start pl-10',
			filterable: false,
			sortable: false,
			minWidth: 200,
			headerClassName:
				'react-table-header-class fs-16 medium-text d-flex justify-content-start'
		},
		{
			Header: 'View Only',
			accessor: 'view_only',
			className: 'text-center',
			headerClassName: 'react-table-header-class fs-16 medium-text',
			sortable: false,
			filterable: false,
			width: 109,
			Cell: ({ row }) => (
				<FormCheckBox
					name="view_only"
					checked={row.view && row.edit && row.status ? true : false}
					onChange={(e) =>
						handleCheckBox(
							'view_only',
							row._original.id,
							e.target.checked
						)
					}
				/>
			)
		},
		{
			Header: 'Edit',
			accessor: 'Edit',
			className: 'text-center',
			filterable: false,
			sortable: false,
			width: 90,
			headerClassName: 'react-table-header-class fs-16 medium-text',
			Cell: ({ row }) => (
				<FormCheckBox
					name="edit"
					checked={row.view && row.view}
					onChange={() => handleCheckBox('edit', row._original.id)}
				/>
			)
		},
		{
			Header: 'Admin',
			accessor: 'admin',
			className: 'text-center',
			headerClassName: 'react-table-header-class fs-16 medium-text',
			sortable: false,
			filterable: false,
			width: 90,
			Cell: ({ row }) => (
				<FormCheckBox
					name="admin"
					checked={row.edit && row.edit}
					onChange={() => handleCheckBox('admin', row._original.id)}
				/>
			)
		},
		{
			Header: 'Edit',
			accessor: 'edit2',
			className: 'text-center',
			headerClassName: 'react-table-header-class fs-16 medium-text',
			sortable: false,
			filterable: false,
			width: 90,
			Cell: ({ row }) => (
				<FormCheckBox
					name="edit2"
					checked={row.status && row.status}
					onChange={() => handleCheckBox('edit2', row._original.id)}
				/>
			)
		}
	];
	const handleCheckBox = (permission, id, value) => {
		const tempPermissionData =
			tableData?.length > 0 &&
			tableData?.map((item) =>
				item.id === id
					? permission === 'select_all'
						? {
								...item,
								add: value,
								view: value,
								edit: value,
								delete: value,
								status: value
						  }
						: { ...item, [permission]: !item[permission] }
					: { ...item }
			);
		setTableData([...tempPermissionData]);
	};
	return (
		<div>
			<form onSubmit={formik.handleSubmit}>
				<Row>
					<Col md={6}>
						<FormInput
							id="firstname"
							type="text"
							name="firstname"
							label="First Name"
							placeholder="Enter First Name"
							value={formik.values.firstname}
							onChange={formik.handleChange}
							error={
								formik.touched.firstname &&
								!!formik.errors.firstname
							}
							errorMsg={formik.errors.firstname}
						/>
					</Col>
					<Col md={6}>
						<FormInput
							id="lastname"
							type="text"
							name="lastname"
							label="Last Name"
							placeholder="Enter Last Name"
							value={formik.values.lastname}
							onChange={formik.handleChange}
							error={
								formik.touched.lastname &&
								!!formik.errors.lastname
							}
							errorMsg={formik.errors.lastname}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<FormInput
							id="email"
							type="email"
							name="email"
							label="Email"
							placeholder="Enter Email"
							value={formik.values.email}
							onChange={formik.handleChange}
							error={
								formik.touched.email && !!formik.errors.email
							}
							errorMsg={formik.errors.email}
						/>
					</Col>
					<Col md="6">
						<ReactFormSelect
							label={'Staff Role (Optional)'}
							placeholder="Select Role"
							options={[]}
							maxMenuHeight={150}
							isSearchable={false}
						/>
					</Col>
				</Row>
				<div>
					<TableStyle version={6}>
						<TableV6
							columns={columns}
							data={tableData}
							handleSortBy={() => {}}
							key={'master-rep-table'}
						/>
					</TableStyle>
				</div>
				<Row>
					<Col lg={6}>
						<Button
							type="submit"
							className="fs-18 medium-text themeButtonFullWidth ptb-10 plr-60"
						>
							Send email invite
						</Button>
					</Col>
				</Row>
			</form>
		</div>
	);
};

export default AddStaffForm;
