import React, { useState } from 'react';
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle
} from 'reactstrap';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer
} from 'recharts';
import { DownArrowIcon, UpArrowIcon } from '../Icons';

const data = [
	{
		name: 'JAN',
		uv: 8,
		pv: 6
	},
	{
		name: 'FEB',
		uv: 10,
		pv: 13
	},
	{
		name: 'MAR',
		uv: 20,
		pv: 28
	},
	{
		name: 'APR',
		uv: 27,
		pv: 19
	},
	{
		name: 'MAY',
		uv: 18,
		pv: 8
	},
	{
		name: 'JUN',
		uv: 25,
		pv: 18
	},
	{
		name: 'JUL',
		uv: 10,
		pv: 3
	},
	{
		name: 'AUG',
		uv: 18,
		pv: 30
	},
	{
		name: 'SEPT',
		uv: 23,
		pv: 18
	},
	{
		name: 'OCT',
		uv: 14,
		pv: 23
	},
	{
		name: 'NOV',
		uv: 17,
		pv: 18
	},
	{
		name: 'DEC',
		uv: 14,
		pv: 23
	}
];

const RevenueChart = () => {
	const [dateRangePickerLabel, setDateRangePickerLabel] =
		useState('This Month');
	const [dateDropdown, setDateDropdown] = useState(false);

	const handleDateRangeLabel = (label) => {
		setDateRangePickerLabel(label);
	};

	return (
		<div className="d-flex w-100 h-100 flex-column gap-3">
			<div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-3">
				<div className="fs-20 semi-bold-text">
					Customer Growth & Venue Growth
				</div>
				<div>
					<Dropdown
						isOpen={dateDropdown}
						toggle={() => setDateDropdown(!dateDropdown)}
						direction="down"
						className="dashboard-dropdown"
					>
						<DropdownToggle
							color="#fff"
							className="ptb-0 dropdownToggle"
						>
							<span
								className={`dropdown-name fs-12 medium-text ${
									dateDropdown ? 'activeColor' : ''
								}`}
							>
								{dateRangePickerLabel}
							</span>
							<span>
								{dateDropdown ? (
									<UpArrowIcon height={7} width={12} />
								) : (
									<DownArrowIcon height={7} width={12} />
								)}
							</span>
						</DropdownToggle>
						<DropdownMenu className="datePickerDropdown">
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'Today'
										? 'active'
										: ''
								}`}
								onClick={() => handleDateRangeLabel('Today')}
							>
								Today
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'Yesterday'
										? 'active'
										: ''
								}`}
								onClick={() =>
									handleDateRangeLabel('Yesterday')
								}
							>
								Yesterday
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'Last Week'
										? 'active'
										: ''
								}`}
								onClick={() =>
									handleDateRangeLabel('Last Week')
								}
							>
								Last Week
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'This Month'
										? 'active'
										: ''
								}`}
								onClick={() =>
									handleDateRangeLabel('This Month')
								}
							>
								This Month
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'Last Month'
										? 'active'
										: ''
								}`}
								onClick={() =>
									handleDateRangeLabel('Last Month')
								}
							>
								Last Month
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'Last Year'
										? 'active'
										: ''
								}`}
								onClick={() =>
									handleDateRangeLabel('Last Year')
								}
							>
								Last Year
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'Current Year'
										? 'active'
										: ''
								}`}
								onClick={() =>
									handleDateRangeLabel('Current Year')
								}
							>
								Current Year
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'All'
										? 'active'
										: ''
								}`}
								onClick={() => handleDateRangeLabel('All')}
							>
								All
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</div>
			</div>
			<ResponsiveContainer width="99%" height={300}>
				<BarChart
					width={'100%'}
					height={300}
					data={data}
					margin={{
						left: -30,
						right: 10
					}}
					barCategoryGap={10}
				>
					<XAxis dataKey="name" axisLine={false} tickLine={false} />
					<YAxis axisLine={false} tickLine={false} />
					<CartesianGrid horizontal vertical={false} />
					<Tooltip />
					<Legend
						verticalAlign="top"
						align="left"
						iconType="circle"
					/>
					<Bar
						type="monotone"
						dataKey="uv"
						name="Premium+"
						fill="#FF5F5F"
						dot={false}
						radius={[10, 10, 0, 0]}
						maxBarSize={12}
					/>
					<Bar
						type="monotone"
						dataKey="pv"
						name="Customer Sale"
						fill="#FF9568"
						dot={false}
						radius={[10, 10, 0, 0]}
						maxBarSize={12}
					/>
				</BarChart>
			</ResponsiveContainer>
			<div className="d-flex flex-column flex-md-row gap-2 p-3">
				<div className="d-flex flex-column flex-1 align-items-start justify-content-center themeText">
					<p className="fs-14 medium-text headingTextColor">
						This Calender Year
					</p>
					<p className="fs-16 semi-bold-text">
						$ <span className="fs-30 semi-bold-text"> 3.5 </span> M
					</p>
				</div>
				<div className="d-flex flex-column flex-1 align-items-start justify-content-center themeText">
					<p className="fs-14 medium-text headingTextColor">
						MyTab Venue
					</p>
					<p className="fs-16 semi-bold-text">
						$ <span className="fs-30 semi-bold-text"> 3.5 </span> M
					</p>
				</div>
				<div className="d-flex flex-column flex-1 align-items-start justify-content-center themeText">
					<p className="fs-14 medium-text headingTextColor">
						MyTab Customer
					</p>
					<p className="fs-16 semi-bold-text">
						$ <span className="fs-30 semi-bold-text"> 2.5 </span> M
					</p>
				</div>
			</div>
		</div>
	);
};

export default RevenueChart;
