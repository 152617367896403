import { useState } from 'react';
import { useSelector } from 'react-redux';
import { TabContent, TabPane } from 'reactstrap';
import { Tabs, Tab } from 'react-tabs-scrollable';

import VenueChangePassword from '../../Components/Settings/Security/VenueChangePassword';
import VenueChangeMobile from '../../Components/Settings/Security/VenueChangeMobile';
import PageStructure from '../../Components/Common/PageStructure';
import { PageWrapper, TabsWrapper } from './VenueSecurity.style';
import VenueChangePasscode from '../../Components/Settings/VenueSecurity/VenueChangePasscode';

export const VenueSecurity = () => {
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	const [tabId, setTabId] = useState(0);
	const onTabClick = (e, index) => {
		setTabId(index);
	};
	return (
		<>
			<PageStructure
				title="Security"
				pageText={
					<TabsWrapper {...allThemeData}>
						<div className="customScrollableNavbar position-relative pa-t-24">
							<Tabs
								activeTab={tabId}
								onTabClick={onTabClick}
								leftBtnIcon={
									<i className="fa fa-angle-left"></i>
								}
								rightBtnIcon={
									<i className="fa fa-angle-right"></i>
								}
								navBtnsIconColor={'#4F4F4F'}
								className="customScrollableNavbar"
							>
								<Tab
									key={0}
									className={`customScrollableNavItem fs-12 regular-text ${
										tabId === 0 ? 'active' : ''
									}`}
								>
									Change Password
								</Tab>
								<Tab
									key={1}
									className={`customScrollableNavItem fs-12 regular-text ${
										tabId === 1 ? 'active' : ''
									}`}
								>
									iPad Access Passcode
								</Tab>
								<Tab
									key={2}
									className={`customScrollableNavItem fs-12 regular-text ${
										tabId === 2 ? 'active' : ''
									}`}
								>
									Change Mobile
								</Tab>
							</Tabs>
							<div className="borderBottom position-absolute bottom-0 w-100 zIndex-1" />
						</div>
					</TabsWrapper>
				}
			>
				<PageWrapper {...allThemeData}>
					<TabContent activeTab={tabId} className="overflow-hidden">
						<TabPane tabId={0}>
							{tabId === 0 && <VenueChangePassword />}
						</TabPane>
						<TabPane tabId={1}>
							{tabId === 1 && <VenueChangePasscode />}
						</TabPane>
						<TabPane tabId={2}>
							{tabId === 2 && <VenueChangeMobile />}
						</TabPane>
					</TabContent>
				</PageWrapper>
			</PageStructure>
		</>
	);
};
