import store from '../../Redux/store';

export const formatFoodOptionListData = (data) => {
	if (data?.length > 0) {
		let formatedData = data?.map((item) => {
			return {
				label: item?.initials + ' ' + `(${item?.name})`,
				value: item?.foodOptionID
			};
		});
		return formatedData;
	}
	return [];
};

export const formatSubCategoryOption = (data) => {
	if (data?.length > 0) {
		let formatedData = data?.map((item) => {
			return {
				subCategoryID: item?.subCategoryID,
				label: item?.name,
				value: item?.id,
				categoryId: item?.categoryID,
				pickupLocationId:
					item?.pickup_location_sub_category?.pickupLocationId
			};
		});
		return formatedData;
	}
	return [];
};

export const formatPickupLocationOption = (data) => {
	if (data?.length > 0) {
		let formatedData = data?.map((item) => {
			return {
				label: item?.address,
				value: item?.id
			};
		});
		return formatedData;
	}
	return [];
};

export const formatSingleProductDetails = (data) => {
	let formValues = {
		image: data?.avatar
			? data?.avatar
			: store?.getState()?.auth?.selectedVenue?.avatar,
		mainCategoryId: data?.categoryID,
		category: data?.subCategoryID,
		categoryName: data?.sub_category?.name,
		itemName: data?.name,
		// calorie: data?.calorie,
		// fat: data?.fat,
		// carbohydrates: data?.carbohydrates,
		// protein: data?.protein,
		basePrice: Number(data?.price)?.toFixed(2),
		pickupLocation: data?.pickupLocationID,
		description: data?.description,
		// serviceType: data?.serviceType ? data?.serviceType,
		dietaryRequirements: data?.productFoodOptions?.map((item) => {
			return item?.foodOptionID?.toString();
		}),
		stockLevel: data?.isStockLimit === 'Yes' ? true : false,
		stockQuantity: Number(data?.dailyStockRenewal),
		stockRemaining: Number(data?.stock),
		stockLimit: data?.isDailyStockRenewal === 'Yes' ? true : false,
		requiredOptions: data?.product_variant_types?.map((item) => {
			return {
				id: item?.id,
				data: item?.productVariantSubTypes?.map((subItem) => {
					return {
						itemId: subItem?.id,
						itemName: subItem?.extraItem,
						price: Number(subItem?.price)?.toFixed(2)
					};
				}),
				serviceType: item?.serviceType
			};
		}),
		additionalExtras: data?.product_extras?.map((item) => {
			return {
				itemId: item?.id,
				itemName: item?.extraItem,
				price: Number(item?.price)?.toFixed(2)
			};
		})
	};
	// Service Type Field
	let serviceType = [];
	if (data?.serviceType) {
		if (data?.serviceType === 'BOTH') {
			serviceType = ['PICKUP', 'TABLE'];
		} else {
			serviceType = [data?.serviceType];
		}
	}
	formValues.serviceType = serviceType;
	return formValues;
};

export const createFormData = ({
	values,
	barId,
	productId,
	initialSelectedDietaryRequirements,
	deletedRequiredOptions,
	deletedRequiredOptionsItems,
	deletedAdditionalExtras,
	isEdit,
	initialFormData
}) => {
	let payload = {
		bar_id: barId,
		category_id: values?.mainCategoryId,
		// calorie: values?.calorie,
		// fat: values?.fat,
		// carbohydrates: values?.carbohydrates,
		// protein: values?.protein,
		subcategory_id: values?.category,
		name: values?.itemName,
		price: values?.basePrice,
		pickuplocation_id: values?.pickupLocation,
		description: values?.description,
		isStockLimit: values?.stockLevel ? 'Yes' : 'No',
		isDailyStockRenewal: values?.stockLimit ? 'Yes' : 'No'
	};
	if (isEdit) {
		payload.id = productId;
	}
	if (typeof values?.image !== 'string') {
		payload.product_image = values?.image;
	}
	if (values?.serviceType?.length > 0) {
		if (values?.serviceType?.length === 1) {
			payload.serviceType = values?.serviceType[0];
		} else {
			payload.serviceType = 'BOTH';
		}
	}
	let newDietaryRequirements = values?.dietaryRequirements?.filter((item) => {
		if (initialSelectedDietaryRequirements?.includes(item)) {
			return;
		}
		return item;
	});
	let deletedDietaryRequirements = initialSelectedDietaryRequirements?.filter(
		(item) => {
			if (values?.dietaryRequirements?.includes(item)) {
				return;
			}
			return item;
		}
	);
	if (!isEdit) {
		payload.dailyStockRenewal = values?.stockQuantity;
		payload.stock = values?.stockRemaining;
	} else {
		if (
			!(
				initialFormData?.stockQuantity == values?.stockQuantity &&
				initialFormData?.stockRemaining == values?.stockRemaining
			)
		) {
			payload.dailyStockRenewal = values?.stockQuantity;
			payload.stock = values?.stockQuantity;
		}
	}
	let formData = new FormData();
	for (let key in payload) {
		formData.append(key, payload[key]);
	}
	// formData for Dietary Requirements
	if (!isEdit) {
		for (let index in values?.dietaryRequirements) {
			formData.append(
				`food_options[${index}]`,
				values?.dietaryRequirements[index]
			);
		}
	} else {
		for (let index in newDietaryRequirements) {
			formData.append(
				`food_options[${index}]`,
				newDietaryRequirements[index]
			);
		}
		for (let index in deletedDietaryRequirements) {
			formData.append(
				`delete_food_option[${index}]`,
				deletedDietaryRequirements[index]
			);
		}
	}
	// formData for Required Options
	if (values?.requiredOptions?.length > 0) {
		for (let index in values?.requiredOptions) {
			if (values?.requiredOptions[index]?.data?.length > 0) {
				formData.append(
					`product_variant_types[${index}][label]`,
					'Please select...'
				);
				if (values?.requiredOptions?.[index]?.serviceType) {
					formData.append(
						`product_variant_types[${index}][serviceType]`,
						values?.requiredOptions?.[index]?.serviceType
					);
				} else {
					if (values?.serviceType?.length === 1) {
						formData.append(
							`product_variant_types[${index}][serviceType]`,
							values?.serviceType[0]
						);
					} else {
						formData.append(
							`product_variant_types[${index}][serviceType]`,
							'BOTH'
						);
					}
				}
				if (values?.requiredOptions[index]?.id) {
					formData.append(
						`product_variant_types[${index}][id]`,
						values?.requiredOptions[index]?.id
					);
				}
				for (let subIndex in values?.requiredOptions[index]?.data) {
					if (
						isEdit &&
						values?.requiredOptions?.[index]?.data?.[subIndex]
							?.itemId
					) {
						formData.append(
							`product_variant_types[${index}][product_variant_sub_types][${subIndex}][id]`,
							values?.requiredOptions?.[index]?.data?.[subIndex]
								?.itemId
						);
					}
					formData.append(
						`product_variant_types[${index}][product_variant_sub_types][${subIndex}][extra_item]`,
						values?.requiredOptions?.[index]?.data?.[subIndex]
							?.itemName
					);
					formData.append(
						`product_variant_types[${index}][product_variant_sub_types][${subIndex}][price]`,
						values?.requiredOptions?.[index]?.data?.[subIndex]
							?.price
					);
				}
			}
		}
	}
	if (isEdit && deletedRequiredOptions?.length > 0) {
		for (let index in deletedRequiredOptions) {
			formData.append(
				`deleted_product_variant_types_ids[${index}]`,
				deletedRequiredOptions[index]
			);
		}
	}
	if (isEdit && deletedRequiredOptionsItems?.length > 0) {
		for (let index in deletedRequiredOptionsItems) {
			formData.append(
				`deleted_product_variant_sub_types_ids[${index}]`,
				deletedRequiredOptionsItems[index]
			);
		}
	}
	// formData for Additional Extras
	if (values?.additionalExtras?.length > 0) {
		for (let index in values?.additionalExtras) {
			if (isEdit && values?.additionalExtras[index]?.itemId) {
				formData.append(
					`extra_items[${index}][id]`,
					values?.additionalExtras[index]?.itemId
				);
			}
			formData.append(
				`extra_items[${index}][extra_item]`,
				values?.additionalExtras[index]?.itemName
			);
			formData.append(
				`extra_items[${index}][price]`,
				values?.additionalExtras[index]?.price
			);
		}
	}
	if (isEdit && deletedAdditionalExtras?.length > 0) {
		for (let index in deletedAdditionalExtras) {
			formData.append(
				`delete_product_extra[${index}]`,
				deletedAdditionalExtras[index]
			);
		}
	}
	return formData;
};

export const OperatingHoursData = [
	{
		weekDay: 0,
		WeekDay_ItemActiveHours: [
			{
				id: -1,
				status: '0',
				activeHours: null,
				inActiveHours: null
			}
		]
	},
	{
		weekDay: 1,
		WeekDay_ItemActiveHours: [
			{
				id: -1,
				status: '0',
				activeHours: null,
				inActiveHours: null
			}
		]
	},
	{
		weekDay: 2,
		WeekDay_ItemActiveHours: [
			{
				id: -1,
				status: '0',
				activeHours: null,
				inActiveHours: null
			}
		]
	},
	{
		weekDay: 3,
		WeekDay_ItemActiveHours: [
			{
				id: -1,
				status: '0',
				activeHours: null,
				inActiveHours: null
			}
		]
	},
	{
		weekDay: 4,
		WeekDay_ItemActiveHours: [
			{
				id: -1,
				status: '0',
				activeHours: null,
				inActiveHours: null
			}
		]
	},
	{
		weekDay: 5,
		WeekDay_ItemActiveHours: [
			{
				id: -1,
				status: '0',
				activeHours: null,
				inActiveHours: null
			}
		]
	},
	{
		weekDay: 6,
		WeekDay_ItemActiveHours: [
			{
				id: -1,
				status: '0',
				activeHours: null,
				inActiveHours: null
			}
		]
	}
];
