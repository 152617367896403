import { useSelector } from 'react-redux';
import 'react-tabs-scrollable/dist/rts.css';

import PageWrapper from './addStaff.style';
import PageStructure from '../../Components/Common/PageStructure';
import AddStaffForm from '../../Components/MyTabStaff/AddStaffForm';
import MytabStaffTable from '../../Components/MyTabStaff/MytabStaffTable';

export const AddStaff = () => {
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));

	return (
		<PageStructure title="Add Staff">
			<PageWrapper {...allThemeData}>
				<div className="plr-15 mt-15 mb-6">
					<div className="roe-card-body">
						<AddStaffForm />
					</div>
				</div>
			</PageWrapper>
		</PageStructure>
	);
};
