export const HamburgerIcon = ({ stroke, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 32 32" fill="none">
			<path
				d="M5 16H27"
				stroke={stroke ?? '#242424'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5 8H27"
				stroke={stroke ?? '#242424'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5 24H27"
				stroke={stroke ?? '#242424'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
