import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';

import PageStructure from '../../../Components/Common/PageStructure';
import ChangePasswordModal from '../../../Components/UserProfile/Security/ChangePasswordModal';

import PageWrapper from './index.style';
import SecurityVerifyOtpModal from '../../../Components/UserProfile/Security/SecurityVerifyOtpModal';
import Api from '../../../Helper/Api';
import { ComputerIcon } from '../../../Components/Icons';
import CustomButton from '../../../Components/Common/CustomButton';
import moment from 'moment';
import QrCodeModal from '../../../Components/UserProfile/Security/QrCodeModal';
import { VenueApiRoutes } from '../../../Utils/routes';
import DeleteConfirmModal from '../../../Components/UserProfile/Security/DeleteConfirmModal';

export const Security = () => {
	// const [qrCode, setQrCode] = useState('');
	const [changePasswordModal, setChangePasswordModal] = useState(false);
	const [qrCodeModal, setQrCodeModal] = useState(false);
	const [verifyOtpModal, setVerifyOtpModal] = useState(false);
	const [deleteAccountModal, setDeleteAccountModal] = useState(false);
	const [changePasswordDate, setChangePasswordDate] = useState('');
	const [deviceList, setDeviceList] = useState('');
	const [updateFlag, setUpdateFlag] = useState(false);
	const [loading, setLoading] = useState(-1);
	const [twoFactorAuth, setTwoFactorAuth] = useState(true);
	const [modalFor, setModalFor] = useState('CHANGE_PASSWORD');

	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	const profileData = useSelector((state) => ({ ...state.auth }));

	const handleVerifyOtpModal = () => {
		setVerifyOtpModal((prev) => !prev);
	};

	const handleChangePasswordModal = () => {
		setChangePasswordModal((prev) => !prev);
	};

	const handleQrCodeModal = () => {
		setQrCodeModal((prev) => !prev);
	};

	const handleDeleteAccountModal = () => {
		setDeleteAccountModal((prev) => !prev);
	};

	const handleLogout = async (id) => {
		let api_type = profileData.login_type === 'venue' ? '/venue' : '/cms';
		let payload = {
			id: id
		};
		setLoading(id);
		try {
			const res = await Api(
				'POST',
				api_type + '/security/logout-device',
				payload
			);
			setLoading(-1);
			if (res?.data?.status) {
				toast.success(res?.data?.message);
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setLoading(-1);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
		setUpdateFlag(true);
	};

	const handleDeleteAccount = async () => {
		try {
			const res = await Api('POST', VenueApiRoutes.deleteAccount);
			if (res?.data?.status) {
				toast.success(res?.data?.message);
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};

	useEffect(() => {
		let api_type = profileData.login_type === 'venue' ? '/venue' : '/cms';

		(async () => {
			// GET CHANGE PASSWORD DATE
			try {
				const res = await Api(
					'GET',
					api_type + '/security/change-password-date'
				);
				if (res?.data?.status) {
					setChangePasswordDate(res?.data?.data);
				} else {
					setChangePasswordDate('');
				}
			} catch (err) {
				if (err?.message) {
					toast.error(err?.message);
				}
			}

			// GET DEVICE LIST
			try {
				const res = await Api(
					'GET',
					api_type + '/security/device-list'
				);
				if (res?.data?.status) {
					setDeviceList(res?.data?.data);
				} else {
					setDeviceList({});
				}
			} catch (err) {
				if (err?.message) {
					toast.error(err?.message);
				}
			}
			setUpdateFlag(false);
		})();
	}, [updateFlag]);

	return (
		<PageStructure title="Security">
			<PageWrapper {...allThemeData}>
				<div
					className="w-100 d-flex flex-column pa-b-2"
					style={{ gap: '18px' }}
				>
					{/* Password */}
					<div className="d-flex flex-column flex-lg-row w-100 gap-3">
						<div className="fs-15 medium-text flex-3">Password</div>
						<div className={`boxCard w-100 flex-7 pa-18`}>
							{changePasswordDate !== '' && (
								<p className="fs-14 medium-text">
									Your last changed password{' '}
									{changePasswordDate}.
								</p>
							)}
							<div className="pa-t-18">
								<Button
									className="themeButton"
									onClick={() => {
										setModalFor('CHANGE_PASSWORD');
										setVerifyOtpModal(true);
									}}
								>
									Change Password
								</Button>
							</div>
						</div>
					</div>
					{/* Two Step Authentication */}
					<div className="d-flex flex-column flex-lg-row w-100 gap-3">
						<div className={`fs-15 medium-text flex-3`}>
							Two-step Authentication
							<p className={`fs-12 regular-text`}>
								Know more about{' '}
								<span className="headingTextColor2">
									two-step authentication
								</span>
							</p>
						</div>
						<div
							className={`boxCard w-100 flex-7 d-flex flex-column pa-18
							}`}
						>
							<p className={`fs-15 medium-text pr-20`}>
								Authentication Methods
							</p>
							<div className={`fs-14 medium-text pa-t-18`}>
								After entering your password, verify your
								identity with an authentication method.
							</div>
							<div className="pa-t-18">
								<Button
									className="themeButton"
									onClick={() => {
										setModalFor('QR_CODE');
										setVerifyOtpModal(true);
									}}
								>
									Show QR Code
								</Button>
							</div>
						</div>
					</div>
					{/* Delete Account */}
					<div className="d-flex flex-column flex-lg-row w-100 gap-3">
						<div className={`fs-15 medium-text flex-3`}>
							Delete Account
						</div>
						<div className={`boxCard w-100 flex-7 pa-18`}>
							{changePasswordDate !== '' && (
								<p className={`fs-14 medium-text pa-b-18`}>
									Deleting your account will delete all your
									data. Once you delete your account, you
									cannot undo this.
								</p>
							)}
							<Button
								className="themeButton"
								onClick={() => setDeleteAccountModal(true)}
							>
								Delete Account
							</Button>
						</div>
					</div>
					{/* Devices */}
					<div className="d-flex flex-column flex-lg-row w-100 gap-3">
						<div className={`fs-15 medium-text flex-3`}>
							Devices
							<p className={`fs-12 regular-text`}>
								You are currently logged in to your MyTab CMS
								account on these devices. If you don’t recognize
								a device, log out to keep your account secure.
							</p>
						</div>
						<div className={`boxCard w-100 flex-7 pa-18`}>
							<p className={`fs-15 medium-text`}>Logged in</p>
							<div className="horizontalRule mtb-18" />
							{deviceList?.currentDevice?.length > 0 &&
								deviceList?.currentDevice?.map(
									(device, index) => (
										<div key={index}>
											<div
												className={`horizontalRule mtb-15 ${
													index === 0 ? 'd-none' : ''
												}`}
											/>
											<div className="d-flex justify-content-between flex-column flex-md-row gap-2 gap-md-1">
												<div>
													<div className="d-flex align-items-center pa-b-6">
														<ComputerIcon
															height={28}
															width={28}
														/>
														<p
															className={`fs-12 medium-text pl-16`}
														>
															{device?.device_name ??
																''}
															<span
																className={`tagThisDevice fs-12 medium-text ml-18`}
															>
																This device
															</span>
														</p>
													</div>
													{device?.createdAt && (
														<div
															className={`fs-12 regular-text`}
														>
															{moment(
																device?.createdAt
															).format(
																'MMM Do YYYY, h:mm a'
															)}
														</div>
													)}
													{device?.device_location && (
														<div
															className={`fs-12 regular-text pa-t-4`}
														>
															{device?.device_location ??
																''}
														</div>
													)}
												</div>
											</div>
											{/* { index === } */}
										</div>
									)
								)}
							{deviceList?.otherDevice?.length > 0 &&
								deviceList?.otherDevice?.map(
									(device, index) => (
										<div key={index}>
											<div
												className={`horizontalRule mtb-15`}
											/>
											<div className="d-flex justify-content-between flex-column flex-md-row gap-2 gap-md-1">
												<div>
													<div className="d-flex align-items-center pa-b-6">
														<ComputerIcon
															height={28}
															width={28}
														/>
														<p
															className={`fs-12 medium-text pl-16`}
														>
															{device?.device_name ??
																''}
														</p>
													</div>
													{device.createdAt && (
														<div
															className={`fs-12 regular-text`}
														>
															{moment(
																device?.createdAt
															).format(
																'MMM Do YYYY, h:mm a'
															)}
														</div>
													)}
													{device?.device_location && (
														<div
															className={`fs-12 regular-text pa-t-4`}
														>
															{device?.device_location ??
																''}
														</div>
													)}
												</div>
												<div>
													<CustomButton
														className="borderButton"
														onClick={() =>
															handleLogout(
																device?.id
															)
														}
														loading={
															loading ===
															device?.id
														}
													>
														Logout
													</CustomButton>
												</div>
											</div>
										</div>
									)
								)}
						</div>
					</div>
				</div>
			</PageWrapper>
			{verifyOtpModal && (
				<SecurityVerifyOtpModal
					handleModal={handleVerifyOtpModal}
					handleVerification={
						modalFor === 'QR_CODE'
							? handleQrCodeModal
							: handleChangePasswordModal
					}
					isOpen={verifyOtpModal}
				/>
			)}
			{changePasswordModal && (
				<ChangePasswordModal
					handleModal={handleChangePasswordModal}
					isOpen={changePasswordModal}
					setUpdateFlag={setUpdateFlag}
				/>
			)}
			<QrCodeModal handleModal={handleQrCodeModal} isOpen={qrCodeModal} />
			<DeleteConfirmModal
				isOpen={deleteAccountModal}
				handleModal={handleDeleteAccountModal}
			/>
		</PageStructure>
	);
};
