import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Switch from '../../Common/Switch';
import Api from '../../../Helper/Api';
import { VenueApiRoutes } from '../../../Utils/routes';
import ServiceTypeConfirmModal from './ServiceTypeConfirmModal';

const ServiceType = () => {
	const authData = useSelector((state) => ({ ...state.auth }));
	const [pickup, setPickup] = useState(false);
	const [table, setTable] = useState(false);
	const [switchLoading, setSwitchLoading] = useState(false);
	const [serviceTypeConfirmModal, setServiceTypeConfirmModal] =
		useState(false);
	const [serviceTypeModalData, setServiceTypeModalData] = useState({
		type: '',
		content: ''
	});
	const handleServiceTypeConfirmModal = () => {
		setServiceTypeConfirmModal((prev) => !prev);
	};
	const handlePickupChange = async (confirmChangeFlag) => {
		let currentPickupValue = pickup;
		let updatedPickupValue = !pickup;
		let service_type = '';
		if (updatedPickupValue === true && table === true) {
			service_type = 'BOTH';
		} else if (updatedPickupValue === false && table === true) {
			service_type = 'TABLE';
		} else if (updatedPickupValue === true && table === false) {
			service_type = 'PICKUP';
		} else {
			service_type = '';
		}
		setSwitchLoading(true);
		try {
			const res = await Api('POST', VenueApiRoutes.updateServiceType, {
				bar_id: authData?.selectedVenue?.id,
				service_type: service_type,
				confirm_change_flag: confirmChangeFlag
			});
			if (res?.data?.status) {
				if (res?.data?.data?.popUpFlag === 1) {
					setServiceTypeModalData({
						type: 'PICKUP',
						content: res?.data?.message
					});
					setServiceTypeConfirmModal(true);
				} else {
					setPickup(updatedPickupValue);
				}
			} else {
				setPickup(currentPickupValue);
				toast.error(res?.data?.data?.message);
			}
			setSwitchLoading(false);
		} catch (err) {
			setPickup(currentPickupValue);
			setSwitchLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	const handleTableChange = async (confirmChangeFlag) => {
		let currentTableValue = table;
		let updatedTableValue = !table;
		let service_type = '';
		if (updatedTableValue === true && pickup === true) {
			service_type = 'BOTH';
		} else if (updatedTableValue === false && pickup === true) {
			service_type = 'PICKUP';
		} else if (updatedTableValue === true && pickup === false) {
			service_type = 'TABLE';
		} else {
			service_type = '';
		}
		setSwitchLoading(true);
		try {
			const res = await Api('POST', VenueApiRoutes.updateServiceType, {
				bar_id: authData?.selectedVenue?.id,
				service_type: service_type,
				confirm_change_flag: confirmChangeFlag
			});
			if (res?.data?.status) {
				if (res?.data?.data?.popUpFlag === 1) {
					setServiceTypeModalData({
						type: 'TABLE',
						content: res?.data?.message
					});
					setServiceTypeConfirmModal(true);
				} else {
					setTable(updatedTableValue);
				}
			} else {
				setTable(currentTableValue);
				toast.error(res?.data?.data?.message);
			}
			setSwitchLoading(false);
		} catch (err) {
			setTable(currentTableValue);
			setSwitchLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	const getServiceType = async () => {
		setSwitchLoading(true);
		try {
			const res = await Api('POST', VenueApiRoutes.getServiceType, {
				bar_id: authData?.selectedVenue?.id
			});
			if (res?.data?.status) {
				if (res?.data?.data?.serviceType === 'BOTH') {
					setPickup(true);
					setTable(true);
				} else if (res?.data?.data?.serviceType === 'TABLE') {
					setTable(true);
				} else {
					setPickup(true);
				}
			} else {
				toast.error(res?.data?.message);
			}
			setSwitchLoading(false);
		} catch (err) {
			setSwitchLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	useEffect(() => {
		getServiceType();
	}, [authData?.selectedVenue?.id]);
	return (
		<>
			<p className="fs-12 regular-text">
				Please select your venue's service type to allow your customers
				to choose whether they would like to collect their order or have
				their order delivered to their table.
			</p>
			<p className="fs-12 regular-text pa-t-18">
				Your venue's service type can only be updated outside of your
				venue's opening hours.
			</p>
			<div
				className="d-flex align-items-center serviceTypeSwitch pa-t-24"
				style={{ gap: '42px' }}
			>
				<div className="d-flex align-items-center">
					<span className="fs-14 regular-text pr-18">Takeaway</span>
					<Switch
						name="pickup"
						checked={pickup}
						onChange={() => handlePickupChange(0)}
						disabled={switchLoading}
					/>
				</div>
				<div className="d-flex align-items-center">
					<span className="fs-14 regular-text pr-18">
						Table Service
					</span>
					<Switch
						name="table"
						checked={table}
						onChange={() => handleTableChange(0)}
						disabled={switchLoading}
					/>
				</div>
			</div>
			<ServiceTypeConfirmModal
				isOpen={serviceTypeConfirmModal}
				handleModal={handleServiceTypeConfirmModal}
				switchLoading={switchLoading}
				handleConfirm={
					serviceTypeModalData.type === 'PICKUP'
						? handlePickupChange
						: handleTableChange
				}
				modalData={serviceTypeModalData}
			/>
		</>
	);
};

export default ServiceType;
