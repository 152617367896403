import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Item from './Item';

const SortableItem = (props) => {
	const {
		isDragging,
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition
	} = useSortable({ id: props.id });
	const style = {
		transform: CSS.Transform.toString(transform),
		transition: transition || undefined
	};
	return (
		<Item
			isEditFromVenueSetup={props.isEditFromVenueSetup}
			forVenuSetup={props.forVenuSetup}
			ref={setNodeRef}
			style={style}
			withOpacity={isDragging}
			{...props}
			attributes={attributes}
			listeners={listeners}
		/>
	);
};

SortableItem.defaultProps = {
	isEditFromVenueSetup: false
};

export default SortableItem;
