import StyleWrapper from './index.style';
import formRequiredImg from '../../../Assets/images/formRequired.png';
import { EyeIcon, EyeSlashIcon } from '../../Icons';
import { useState } from 'react';
import AcceptIcon from '../../../Assets/images/accept.svg';
import NotAcceptIcon from '../../../Assets/images/not-accept.svg';
import { Tooltip } from 'reactstrap';

const NewFormPasswordInput = ({
	id,
	label,
	error,
	errorMsg,
	disabled,
	style,
	showRequired,
	suggestionMatcher = false,
	passwordSuggestionMatched,
	tootlTipMessage = 'Required',
	...rest
}) => {
	const [showPassword, setShowPassword] = useState(false);
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const toggle = () => setTooltipOpen(!tooltipOpen);

	const passwordSuggestor = (passwordSuggestionMatched = null) => {
		if (passwordSuggestionMatched === null) {
			return <></>;
		} else if (passwordSuggestionMatched) {
			return (
				<img
					src={AcceptIcon}
					height={18}
					width={18}
					alt="not-accept"
					className="valid-icon-size"
				/>
			);
		} else if (!passwordSuggestionMatched) {
			return (
				<img
					src={NotAcceptIcon}
					height={18}
					width={18}
					alt="matched"
					className="valid-icon-size"
				/>
			);
		} else {
			return <></>;
		}
	};

	return (
		<StyleWrapper>
			{label && (
				<span className="label">
					{label}&nbsp;
					{showRequired && (
						<>
							<img
								src={formRequiredImg}
								alt="form-required"
								className="requiredImg"
								id={
									'tooltiptarget' +
									label
										.split('')
										.filter((s) => s !== ' ')
										.join('')
								}
							/>
							<Tooltip
								className="tooltip-style"
								placement="right-start"
								isOpen={tooltipOpen}
								autohide={false}
								target={
									'tooltiptarget' +
									label
										.split('')
										.filter((s) => s !== ' ')
										.join('')
								}
								id="tooltiptarget"
								toggle={toggle}
							>
								{tootlTipMessage}
							</Tooltip>
						</>
					)}
				</span>
			)}
			<div className="inputWrapper">
				<input
					type={showPassword ? 'text' : 'password'}
					className="input"
					disabled={disabled}
					style={style}
					{...rest}
				/>
				{suggestionMatcher && (
					<div className="iconWrapper-suggestor">
						{passwordSuggestor(passwordSuggestionMatched)}
					</div>
				)}
				<div
					className="iconWrapper"
					onClick={() => setShowPassword((prev) => !prev)}
				>
					{showPassword ? (
						<EyeSlashIcon width={18} height={18} />
					) : (
						<EyeIcon width={18} height={18} />
					)}
				</div>
			</div>
			{error && <p className="error">{errorMsg}</p>}
		</StyleWrapper>
	);
};

export default NewFormPasswordInput;
