import styled from 'styled-components';

const ImageUploadWrapper = styled.div`
	.imageContainer {
		width: 75px;
		height: 75px;
		position: relative;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		.image {
			width: 100%;
			height: 100%;
			border-radius: 100%;
		}
		.icon {
			position: absolute;
			width: 27px;
			height: 26px;
			background-color: #ff5f5f;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 100%;
			bottom: 0;
			right: 0;
			cursor: ${(props) => (props.disabled ? '' : 'pointer')};
		}
	}
`;

export default ImageUploadWrapper;
