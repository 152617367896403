import styled from 'styled-components';

const PageWrapper = styled.div`
	background-color: #ffffff;
	height: 100%;
	color: ${(props) => props.layoutTheme.textColor} !important;
	.page {
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
		border-radius: 16px;
		padding: 20px;
		height: 100%;
		display: flex;
		flex-direction: column;
		@media only screen and (max-width: 575.98px) {
			border-radius: 0px;
		}
	}
	.page-body {
		flex: 1 1 auto;
	}
	@media only screen and (max-width: 767px) {
		padding: 0px;
	}
`;

export default PageWrapper;
