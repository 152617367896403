import { useState, useEffect } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { useSelector } from 'react-redux';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import FormMobileNoInputWrapper from './newformMobileNoInput.style';

const NewFormMobileNoInput = ({
	name,
	label,
	placeholder,
	className,
	formGroupClassName,
	error,
	errorMsg,
	countryCodeValue,
	mobileNoValue,
	onSelectCountry,
	onMobileNoChange,
	onMobileNoBlur,
	disabledCountry,
	disabled
}) => {
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	const [fullMobileNoValue, setFullMobileNoValue] = useState('');
	useEffect(() => {
		if (countryCodeValue) {
			setFullMobileNoValue(`+${countryCodeValue}${mobileNoValue}`);
		}
	}, [countryCodeValue]);
	return (
		<FormGroup
			className={`mb-0 ${formGroupClassName ? formGroupClassName : ''}`}
		>
			{label && (
				<Label className="fs-12 medium-text themeText label-color ma-b-6">
					{label}
				</Label>
			)}
			<FormMobileNoInputWrapper {...allThemeData}>
				{/* Country Code Dropdown */}
				<div className="libraryInputWrapper">
					<IntlTelInput
						inputClassName={`libraryInput`}
						preferredCountries={[]}
						defaultCountry={'au'}
						separateDialCode={true}
						value={fullMobileNoValue}
						onSelectFlag={onSelectCountry}
						disabled={disabledCountry}
					/>
				</div>
				{/* Mobile No Input */}
				<Input
					name={name}
					placeholder={placeholder}
					value={mobileNoValue}
					onChange={onMobileNoChange}
					onBlur={onMobileNoBlur}
					disabled={disabled}
				/>
			</FormMobileNoInputWrapper>
			{error && <p className="error">{errorMsg}</p>}
		</FormGroup>
	);
};

export default NewFormMobileNoInput;
