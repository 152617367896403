import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Agreement from '../../Components/Authentication/Agreement';
import PageWrapper from './stripeAccountProcess.style';
import CustomButton from '../../Components/Common/CustomButton';
import createAccountImage from '../../Assets/images/powerd-by-stripe.png';
import Api from '../../Helper/Api';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { VenueProtectedRoutes } from '../../Utils/routes';
import { useDispatch } from 'react-redux';
import authActions from '../../Redux/auth/actions';
import Scrollbars from 'react-custom-scrollbars';

const StripeAccountProcess = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [processCompletion, setLoadingSetUp] = useState(0);
	const [processLoading, setProcessLoading] = useState(false);
	const fetchBarId = useSelector((state) => state.bar?.bar_id);

	const checkBankConnectivity = async () => {
		try {
			setProcessLoading(true);
			const res = await Api(
				'POST',
				'/venue/bar/check-stripe-integration',
				{
					bar_id: fetchBarId
				}
			);
			setProcessLoading(false);

			if (res?.data?.status) {
				if (
					res?.data?.data?.stripeData?.details_submitted &&
					res?.data?.data?.stripeData?.charges_enabled
				) {
					setLoadingSetUp(1);
				} else {
					setLoadingSetUp(0);
				}
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setProcessLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};

	const handleNextStep = () => {
		dispatch(authActions.prevenue_process(false));
		dispatch(authActions.prevenue_account_process_change(null));
		dispatch(authActions.venue_change_login(true));
	};

	useEffect(() => {
		checkBankConnectivity();
	}, []);

	return (
		<PageWrapper>
			<div className="formWrapper pl-26 pr-45 pr-xl-[70px]">
				<Scrollbars autoHide>
					{!processLoading ? (
						<>
							<div>
								<h1 className="headingStyle maxHeading stripeHeading">
									{processCompletion === 1
										? `Congratulations, your Stripe account
					has been created.`
										: processCompletion === 0
										? `Looks like something has been missed! Stripe requires a few more details.`
										: ''}
								</h1>
								<div className="d-flex justify-content-center py-3">
									{processCompletion === 1 ? (
										<div className="approvedStatusSuccess">
											<p>STRIPE ACCOUNT APPROVED</p>
										</div>
									) : processCompletion === 0 ? (
										<div className="approvedStatus">
											<p>STRIPE ACCOUNT RESTRICTED</p>
										</div>
									) : (
										<></>
									)}
								</div>
								<p className="smallInfo">
									{processCompletion === 1 ? (
										<>
											Congratulations on successfully
											creating your Stripe account and
											filling out all the necessary
											details. Stripe has confirmed that
											your account is now approved and
											ready to receive payments to your
											nominated bank account.
											<br />
											<br />
											It is important to keep your account
											information up to date and accurate,
											which you can easily do through the
											settings of your Stripe account.
											Should you require any assistance or
											have any questions regarding your
											Stripe account, please click{' '}
											<span
												className={`helperLinkSpan`}
												onClick={() => {
													window.open(
														'https://support.stripe.com/',
														'_blank'
													);
												}}
											>
												here
											</span>{' '}
											to contact Stripe support.
										</>
									) : (
										<>
											We have received a notification from
											Stripe indicating that there are
											incomplete details in your account
											setup. In order for your account to
											be approved and begin receiving
											payments, it is crucial that all
											Stripe fields are filled out.
											<br />
											<br /> To address the missing
											information, please use the button
											below to log in to your Stripe
											account and complete the necessary
											details that Stripe has outlined.
											<br />
											<br />
											If you require assistance from
											Stripe in finalising your account,
											please click{' '}
											<span
												className="spanLink"
												onClick={() =>
													window.open(
														'https://dashboard.stripe.com/login',
														'_blank'
													)
												}
											>
												here
											</span>
											. Alternatively, please email us at{' '}
											<span
												className="spanLink"
												onClick={() =>
													window.open(
														'mailto:venue@mytabinfo.com'
													)
												}
											>
												venue@mytabinfo.com
											</span>{' '}
											and one of our venue support members
											will happily assist you.
										</>
									)}
								</p>
								{processCompletion < 2 && (
									<CustomButton
										onClick={() => {
											if (!(processCompletion === 1)) {
												window.open(
													'https://dashboard.stripe.com/login',
													'_blank'
												);
											} else {
												handleNextStep();
												// dispatch(
												// 	authActions.prevenue_account_process_change(
												// 		'/pos-integration'
												// 	)
												// );
												// navigate(
												// 	VenueProtectedRoutes.posIntegration
												// );
											}
										}}
										disabled={!processCompletion === 1}
										type="submit"
										className="newThemeButtonFullWidth"
									>
										{processCompletion === 1
											? `Next step!`
											: `
                            Finish setting up your Stripe account
                            `}
									</CustomButton>
								)}
								{!processCompletion && (
									<p className="smallInfo text-center">
										Stripe says my account is complete,
										click{' '}
										<span onClick={handleNextStep}>
											here
										</span>{' '}
										to move to the next step.
									</p>
								)}
							</div>
						</>
					) : (
						<h1 className="headingStyle maxHeading">Loading...</h1>
					)}
				</Scrollbars>
				<Agreement />
			</div>
			<div className="imageWrapper">
				<div className="backWrraper">
					<p className="text">
						MyTab partners with
						<br />
						Stripe for secure payments
					</p>
					<img
						src={createAccountImage}
						alt="side-img"
						className="image"
					/>
				</div>
			</div>
		</PageWrapper>
	);
};

export default StripeAccountProcess;
