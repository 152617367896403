import React from 'react';
import styled from 'styled-components';

const StylesV6 = styled.div`
	width: 100%;
	border: none;
	.ReactTable {
		border: none;
		height: 100%;
		width: 100%;
		overflow: hidden;
		.rt-table {
			overflow: auto;
			::-webkit-scrollbar {
				width: 5px;
				height: 5px;
			}
			::-webkit-scrollbar-track {
				border-radius: 50px;
			}
			::-webkit-scrollbar-thumb {
				border-radius: 50px;
			}
		}
		.rt-th,
		.rt-td {
			white-space: pre-wrap !important;
			&.react-table-header-class {
				&.justify-content-start {
					/* padding-left: 10px !important; */
					div {
						justify-content: start !important;
					}
				}
				div {
					font-family: 'montserrat-medium';
				}
			}
		}
		.rt-thead.-header {
			background-color: #fff;
			box-shadow: none !important;
			position: sticky;
			top: 0;
			display: ${(props) => (props.hideHeader ? 'none' : '')};
		}
		.rt-thead .rt-th,
		.rt-thead .rt-td {
			padding: 12px 18px !important;
			text-align: start;
			color: #fff;
			/* color: #616161; */
			font-weight: 500;
			text-transform: capitalize;
			font-size: 16px;
			/* background-color: #F5F5F5; */
			background-color: #fc6461;
			border: 1px solid #eaeaea;
			&:first-child {
				border-radius: 8px 0px 0px 0px;
			}
			&:last-child {
				border-radius: 0px 8px 0px 0px;
			}
			div {
				display: flex;
				align-items: center;
				justify-content: center;
			}
			&.-cursor-pointer {
				box-shadow: none !important;
				display: flex;
				justify-content: center;
				align-items: center;
				&:before {
					content: ' ';
					position: absolute;
					right: 15px;
					font-size: 4rem !important;
					font-family: 'montserrat-semi-bold';
					color: #fd6461 !important;
					background-color: transparent !important;
					background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0IDIyTDEwIDI2TDYgMjIiIHN0cm9rZT0iI0ZENjQ2MSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTEwIDZWMjYiIHN0cm9rZT0iI0ZENjQ2MSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTE4IDEwTDIyIDZMMjYgMTAiIHN0cm9rZT0iI0ZENjQ2MSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTIyIDI2VjYiIHN0cm9rZT0iI0ZENjQ2MSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
					background-repeat: no-repeat;
					background-size: 100% 100%;
				}
			}
			&.-sort-desc.-cursor-pointer {
				&:before {
					content: ' ' !important;
					font-size: 4rem !important;
					font-family: 'montserrat-regular';
					color: #fd6461 !important;
					background-color: transparent !important;
					background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0IDIyTDEwIDI2TDYgMjIiIHN0cm9rZT0iI0ZENjQ2MSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTEwIDZWMjYiIHN0cm9rZT0iI0ZENjQ2MSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTE4IDEwTDIyIDZMMjYgMTAiIHN0cm9rZT0iIzYxNjE2MSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTIyIDI2VjYiIHN0cm9rZT0iIzYxNjE2MSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
					background-repeat: no-repeat;
					background-size: 100% 100%;
				}
			}

			&.-sort-asc.-cursor-pointer {
				&:before {
					content: ' ' !important;
					font-size: 4rem !important;
					font-family: 'montserrat-regular';
					color: #fd6461 !important;
					background-color: transparent !important;
					background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0IDIyTDEwIDI2TDYgMjIiIHN0cm9rZT0iIzYxNjE2MSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTEwIDZWMjYiIHN0cm9rZT0iIzYxNjE2MSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTE4IDEwTDIyIDZMMjYgMTAiIHN0cm9rZT0iI0ZENjQ2MSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTIyIDI2VjYiIHN0cm9rZT0iI0ZENjQ2MSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
					background-repeat: no-repeat;
					background-size: 100% 100%;
				}
			}
		}
		.rt-tbody {
			overflow-y: initial;
			overflow-x: hidden;
			border: 1px solid #eaeaea;
			border-top: none;
			border-radius: 0 0 8px 8px;
			::-webkit-scrollbar {
				width: 5px;
				height: 5px;
			}
			::-webkit-scrollbar-track {
				border-radius: 50px;
				margin-bottom: 10px;
			}
			::-webkit-scrollbar-thumb {
				border-radius: 50px;
			}
			.rt-td {
				border: 1px solid #eaeaea;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 6px 18px !important;
			}
			.rt-tr-group {
				box-shadow: 0px 5px 15px 10px rgba(187, 193, 200, 0.05);
				border: none;
				font-size: 14px;
				font-weight: 500;
				text-transform: capitalize;
				flex: none;
				color: #000;
				&:first-child .rt-tr .rt-td:first-child {
					border-radius: ${(props) =>
						props.hideHeader ? '8px 0px 0px 0px' : '0px'};
				}
				&:first-child .rt-tr .rt-td:last-child {
					border-radius: ${(props) =>
						props.hideHeader ? '0px 8px 0px 0px' : '0px'};
				}
				&:last-child .rt-tr .rt-td:first-child {
					border-radius: 0px 0px 0px 8px;
				}
				&:last-child .rt-tr .rt-td:last-child {
					border-radius: 0px 0px 8px 0px;
				}
			}
			.rt-tr {
				/* align-items: center;
				justify-content: space-evenly; */
			}
		}
	}
	ul {
		list-style: none;
		display: flex;
		justify-content: center;
		align-items: center;
		li {
			margin: 0 5px;
			font-size: 12px;
			border-radius: 5px;
			padding: 0;
			font-weight: 500;
			height: 30px;
			text-align: center;
			&.page-break {
				a {
					color: #fd6461 !important;
				}
			}
			a {
				display: inline-block;
				padding: 6px 10px;
				border: none;
				outline: none;
				svg: {
					padding: 0;
				}
			}
			&.page-item {
				transition: all 0.25s ease-in-out;
				a {
					color: #fd6461 !important;
					outline: none !important;
					&:active {
						outline: none !important;
					}
					&:focus {
						box-shadow: none !important;
					}
				}
			}
			&.page-item.disabled {
				/* visibility: hidden; */
				cursor: not-allowed;
			}
			&.page-item.active {
				a {
					background-color: #fd6461 !important;
					color: #fff !important;
				}
			}
			:first-child {
				a {
					padding: 7px 5px;
					svg {
						font-size: 14px !important;
					}
				}
			}
			:last-child {
				a {
					padding: 7px 5px;
					svg {
						font-size: 14px !important;
					}
				}
			}
		}
	}
	.customPagination {
		.page-link {
			font-family: 'montserrat-medium';
			font-size: 12px !important;
		}
		.page-item {
			margin: 0 2px !important;
		}
		.pagination {
			margin-bottom: 0 !important;
		}
	}
	.boxBorderRight {
		border-right: 1px solid #bababa;
	}
	.rtNoDataFound {
		text-align: center;
		padding: 28px;
		font-size: 12px;
		color: rgb(1, 1, 1);
		opacity: 0.5;
		border: 2px solid #eaeaea;
		border-top: none;
		border-radius: 0 0 8px 8px;
	}
`;

const TableStyle = ({ children, ...props }) => {
	return <StylesV6 {...props}>{children}</StylesV6>;
};

export default TableStyle;
