import {
	AndroidIcon,
	AppleIcon,
	CircleCheckIcon,
	CircleDollarIcon,
	UserCheckIcon,
	UserSlashIcon
} from '../Icons';

const TotalCustomerCard = () => {
	return (
		<>
			<div className="d-flex flex-column flex-5 p-3 defaultBoxShadow border-radius-16 bg-white">
				<div className="d-flex flex-column gap-3">
					<div className="fs-20 semi-bold-text">
						Total MyTab Customers
					</div>
					<div className="d-flex flex-1 flex-column flex-sm-row justify-content-between gap-2 gap-lg-4">
						<div className="totalCard d-flex flex-1 flex-row justify-content-between flex-sm-column gap-2 ptb-10">
							<div className="totalCardIcon d-flex align-items-center justify-content-center">
								<UserCheckIcon height={20} width={20} />
							</div>
							<div className="totalCardTitle headingTextColor pt-2">
								<p className="fs-14 medium-text">
									Active Customers
								</p>
							</div>
							<div className="themeText">
								<p className="fs-16 semi-bold-text">
									<span className="fs-30 semi-bold-text">
										45
									</span>{' '}
									k
								</p>
							</div>
						</div>
						<div className="totalCard d-flex flex-1 flex-row justify-content-between flex-sm-column gap-2 ptb-10">
							<div className="totalCardIcon d-flex align-items-center justify-content-center">
								<AppleIcon height={24} width={18} />
							</div>
							<div className="totalCardTitle headingTextColor pt-2">
								<p className="fs-14 medium-text">
									iOS Customers
								</p>
							</div>
							<div className="themeText">
								<p className="fs-16 semi-bold-text">
									<span className="fs-30 semi-bold-text">
										12.5
									</span>{' '}
									k
								</p>
							</div>
						</div>
						<div className="totalCard d-flex flex-1 flex-row justify-content-between flex-sm-column gap-2 ptb-10">
							<div className="totalCardIcon d-flex align-items-center justify-content-center">
								<AndroidIcon height={24} width={27} />
							</div>
							<div className="totalCardTitle headingTextColor pt-2">
								<p className="fs-14 medium-text">
									Android Customers
								</p>
							</div>
							<div className="themeText">
								<p className="fs-16 semi-bold-text">
									<span className="fs-30 semi-bold-text">
										12.5
									</span>{' '}
									k
								</p>
							</div>
						</div>
						<div className="totalCard d-flex flex-1 flex-row justify-content-between flex-sm-column gap-2 ptb-10">
							<div className="totalCardIcon d-flex align-items-center justify-content-center">
								<UserSlashIcon height={20} width={25} />
							</div>
							<div className="totalCardTitle headingTextColor pt-2">
								<p className="fs-14 medium-text">
									Deleted Accounts
								</p>
							</div>
							<div className="themeText">
								<p className="fs-16 semi-bold-text">
									<span className="fs-30 semi-bold-text">
										560
									</span>{' '}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="d-flex flex-2 p-3 defaultBoxShadow border-radius-16 bg-white">
				<div className="d-flex flex-1 flex-row justify-content-between gap-3">
					<div className="totalCard d-flex flex-1 flex-column justify-content-between p-3">
						<div className="totalCardIcon d-flex align-items-center justify-content-center">
							<CircleDollarIcon height={32} width={32} />
						</div>
						<div className="totalCardTitle headingTextColor">
							<p className="fs-16 semi-bold-text themeText">
								${' '}
								<span className="fs-30 semi-bold-text">
									2.5
								</span>{' '}
								k
							</p>
							<p className="fs-14 medium-text pt-2">
								Average Customer Spend
							</p>
						</div>
					</div>
					<div className="totalCard d-flex flex-1 flex-column justify-content-between p-3">
						<div className="totalCardIcon d-flex align-items-center justify-content-center">
							<CircleCheckIcon height={32} width={32} />
						</div>
						<div className="totalCardTitle headingTextColor">
							<p className="fs-16 semi-bold-text themeText">
								<span className="fs-30 semi-bold-text">
									2.5
								</span>{' '}
								k
							</p>
							<p className="fs-14 medium-text pt-2">
								Successful Payments
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default TotalCustomerCard;
