import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as validation from '../../Helper/YupValidation';
import FormInput from '../Form/FormInput';
import Api from '../../Helper/Api';
import { VenueApiRoutes } from '../../Utils/routes';
import CustomButton from '../Common/CustomButton';

const ContactUs = () => {
	const authData = useSelector((state) => ({ ...state.auth }));
	const [loading, setLoading] = useState(false);
	const validationSchema = yup.object().shape({
		name: validation.YUP_VALIDATION.NAME,
		email: validation.YUP_VALIDATION.EMAIL,
		message: validation.YUP_VALIDATION.MESSAGE
	});
	const handleOnSubmit = async () => {
		let payload = {
			name: values?.name,
			email: values?.email,
			message: values?.message,
			bar_id: authData?.selectedVenue?.id,
			mobile: authData?.mobile
		};
		setLoading(true);
		try {
			const res = await Api(
				'POST',
				VenueApiRoutes.supportContactUs,
				payload
			);
			if (res?.data?.status) {
				resetForm();
				toast.success(res?.data?.message);
			} else {
				toast.error(res?.data?.message);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	const {
		handleSubmit,
		values,
		handleChange,
		handleBlur,
		touched,
		errors,
		resetForm
	} = useFormik({
		initialValues: {
			name: '',
			email: authData?.email ?? '',
			message: ''
		},
		validationSchema,
		onSubmit: handleOnSubmit
	});
	return (
		<>
			<p className="fs-12 medium-text" style={{ color: '#4F4F4F' }}>
				For all venue questions or enquiries, please email us below and
				one of our venue support representatives will be in touch with
				you shortly. Thank you.
			</p>
			<form onSubmit={handleSubmit} className="pa-t-24">
				<Row style={{ '--bs-gutter-x': '18px' }}>
					<Col lg={6} className="pa-b-20">
						<FormInput
							type="text"
							name="name"
							label="Name"
							placeholder="Enter Name"
							value={values.name}
							onChange={handleChange}
							onBlur={handleBlur}
							error={touched.name && !!errors.name}
							errorMsg={errors.name}
						/>
					</Col>
					<Col lg={6} className="pa-b-20">
						<FormInput
							id="email"
							type="email"
							name="email"
							label="Email"
							placeholder="Enter Email"
							value={values.email}
							onChange={handleChange}
							onBlur={handleBlur}
							error={touched.email && !!errors.email}
							errorMsg={errors.email}
							disabled
						/>
					</Col>
					<Col md={12} className="pa-b-20">
						<FormInput
							id="message"
							type="textarea"
							name="message"
							label="Message"
							placeholder="Enter Message"
							value={values.message}
							onChange={handleChange}
							onBlur={handleBlur}
							error={touched.message && !!errors.message}
							errorMsg={errors.message}
							rows={3}
						/>
					</Col>
					<Col lg={6}>
						<CustomButton
							type="submit"
							className="themeButton"
							loading={loading}
						>
							Send Message
						</CustomButton>
					</Col>
				</Row>
			</form>
		</>
	);
};

export default ContactUs;
