import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import {
	DndContext,
	closestCenter,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors
} from '@dnd-kit/core';
import {
	arrayMove,
	SortableContext,
	rectSortingStrategy
} from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { CircleMinusIcon, PlusIcon } from '../../Icons';
import RequiredOptionInput from './RequiredOptionInput';
import CustomPopover2 from '../../Common/Popover/CustomPopover2';
import RequiredOptionsPopover from '../RequiredOptionsPopover';

const RequiredOptionsItem = ({
	index,
	name,
	values,
	setFieldValue,
	handleChange,
	touched,
	errors,
	handleDeletedRequiredOptions,
	handleDeletedRequiredOptionsItem,
	posStatus
}) => {
	const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
	const [activeId, setActiveId] = useState(null);
	const [items, setItems] = useState([]);
	const [itemsIds, setItemsIds] = useState([]);
	const [requiredOptionsItemsData, setRequiredOptionsItemsData] = useState(
		[]
	);
	const [serviceType, setServiceType] = useState(null);
	const getItem = (id) => {
		let item;
		if (items?.length > 0) {
			item = items?.find((subItem) => {
				return subItem?.id == id;
			});
		}
		return item;
	};
	const handleServiceType = (value) => {
		let requiredOptions = [...values?.requiredOptions];
		requiredOptions[index].serviceType = value;
		setFieldValue(name, [...requiredOptions]);
	};
	const handleDragStart = useCallback((event) => {
		setActiveId(event.active.id);
	}, []);
	const handleDragEnd = useCallback(
		(event) => {
			const { active, over } = event;
			if (active?.id !== over?.id) {
				const oldIndex = itemsIds?.indexOf(active?.id);
				const newIndex = itemsIds?.indexOf(over?.id);
				const updatedItemsIds = arrayMove(itemsIds, oldIndex, newIndex);
				let updatedRequiredOptions = values?.requiredOptions;
				updatedRequiredOptions[index].data = updatedItemsIds?.map(
					(id) => {
						let item = getItem(id);
						if (item?.itemId) {
							return {
								itemName: item?.itemName,
								price: item?.price,
								itemId: item?.itemId
							};
						}
						return {
							itemName: item?.itemName,
							price: item?.price
						};
					}
				);
				setItemsIds([...updatedItemsIds]);
				setFieldValue(name, updatedRequiredOptions);
			}
			setActiveId(null);
		},
		[items, values]
	);
	const handleDragCancel = useCallback(() => {
		setActiveId(null);
	}, []);
	useEffect(() => {
		if (values?.requiredOptions?.[index]?.data?.length > 0) {
			setItems(
				values?.requiredOptions[index]?.data?.map((item, i) => {
					if (item?.itemId) {
						return {
							id: i + 1,
							itemName: item?.itemName,
							price: item?.price,
							itemId: item?.itemId
						};
					}
					return {
						id: i + 1,
						itemName: item?.itemName,
						price: item?.price
					};
				})
			);
			setItemsIds(
				values?.requiredOptions[index]?.data?.map((item, i) => {
					return i + 1;
				})
			);
			setRequiredOptionsItemsData(values?.requiredOptions[index]?.data);
		} else {
			setItems([]);
			setItemsIds([]);
		}
		if (values?.requiredOptions?.[index]?.serviceType) {
			if (values?.requiredOptions?.[index]?.serviceType === 'PICKUP') {
				setServiceType('Takeaway');
			} else if (
				values?.requiredOptions?.[index]?.serviceType === 'TABLE'
			) {
				setServiceType('Table Service');
			} else if (
				values?.requiredOptions?.[index]?.serviceType === 'BOTH'
			) {
				setServiceType('Both');
			} else {
				setServiceType(null);
			}
		}
	}, [
		values,
		values?.requiredOptions,
		values?.requiredOptions?.[index]?.data,
		values?.requiredOptions?.[index]?.data?.length
	]);
	return (
		<div className="requiredOptionsGroup d-flex flex-column pa-t-18">
			<div className="pleaseSelectCard d-flex justify-content-between plr-16 ptb-8 align-items-center">
				<div className="fs-14 medium-text text-white">
					Please select...
				</div>
				<div className="d-flex gap-2 align-items-center">
					<CustomPopover2
						button={
							<Button type="button" className="serviceTypeButton">
								<span className="fs-13 medium-text">
									{serviceType ? serviceType : 'Service Type'}
								</span>
							</Button>
						}
						placement="bottom-end"
					>
						<div className="d-flex flex-column pa-12">
							<div
								className="pa-b-12 themeText fs-12 medium-text cursor-pointer"
								onClick={() => {
									setServiceType('Takeaway');
									handleServiceType('PICKUP');
								}}
							>
								Takeaway
							</div>
							<div
								className="pa-b-12 themeText fs-12 medium-text cursor-pointer"
								onClick={() => {
									setServiceType('Table Service');
									handleServiceType('TABLE');
								}}
							>
								Table Service
							</div>
							<div
								className="themeText fs-12 medium-text cursor-pointer"
								onClick={() => {
									setServiceType('Both');
									handleServiceType('BOTH');
								}}
							>
								Both
							</div>
						</div>
					</CustomPopover2>
					{!posStatus && (
						<Button
							className="addOptionsButton"
							onClick={() => {
								let updatedRequiredOptions =
									values.requiredOptions;
								updatedRequiredOptions[index]?.data?.push({
									itemName: '',
									price: '0.00'
								});
								setFieldValue(name, [
									...updatedRequiredOptions
								]);
							}}
						>
							<PlusIcon height={16} width={16} stroke={'#FFF'} />
							<span className="fs-13 medium-text pl-6">
								Add Option
							</span>
						</Button>
					)}
					<div className="pl-12">
						<CircleMinusIcon
							height={14}
							width={14}
							className="cursor-pointer"
							onClick={() => {
								handleDeletedRequiredOptions(
									values?.requiredOptions[index]
								);
								let items = values.requiredOptions;
								items.splice(index, 1);
								setFieldValue(name, [...items]);
							}}
						/>
					</div>
					<div className="pl-4">
						<RequiredOptionsPopover
							name={name}
							setFieldValue={setFieldValue}
							values={values}
							index={index}
						/>
					</div>
				</div>
			</div>
			<div className="pa-t-18">
				<DndContext
					sensors={sensors}
					collisionDetection={closestCenter}
					onDragStart={handleDragStart}
					onDragEnd={handleDragEnd}
					onDragCancel={handleDragCancel}
					modifiers={[restrictToParentElement]}
				>
					<SortableContext
						items={items}
						strategy={rectSortingStrategy}
						handle
					>
						{itemsIds?.length > 0 &&
							itemsIds?.map((id, subIndex) => (
								<RequiredOptionInput
									key={subIndex}
									id={id}
									innerIndex={subIndex}
									outerIndex={index}
									name={name}
									option={getItem(id)}
									values={values}
									setFieldValue={setFieldValue}
									handleChange={handleChange}
									touched={touched}
									errors={errors}
									handleDeletedRequiredOptionsItem={
										handleDeletedRequiredOptionsItem
									}
									handleDeletedRequiredOptions={
										handleDeletedRequiredOptions
									}
									posStatus={posStatus}
								/>
							))}
					</SortableContext>
				</DndContext>
			</div>
		</div>
	);
};

export default RequiredOptionsItem;
