import styled from 'styled-components';

export const PageWrapper = styled.div`
	.borderBox {
		border: 1px solid #eaeaea;
		border-radius: 6px;
	}
	.horizontalLine {
		border-top: 1px solid #eaeaea !important;
	}
	.serviceTypeBox {
		padding: 24px 18px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		@media only screen and (max-width: 640px) {
			flex-direction: column;
			align-items: flex-start;
			justify-content: unset;
			gap: 18px;
		}
		.switchContainer {
			display: flex;
			gap: 42px;
		}
	}
	.itemBox {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 18px;
	}
	.productItemName {
		@media only screen and (min-width: 1217px) {
			width: 148px !important;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	.gridContainer {
		display: grid;
		grid-template-columns: auto auto auto;
		gap: 24px;
		@media only screen and (max-width: 1313px) {
			grid-template-columns: auto auto;
		}
		@media only screen and (max-width: 971px) {
			grid-template-columns: auto;
		}
	}
	.setWaitTimeButton {
		padding-block: 8px !important;
		padding-inline: 15px !important;
		display: flex;
		align-items: center;
		gap: 6px;
	}
`;
