import React, { Fragment, useState } from 'react';
import { Collapse } from 'reactstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import { ClockIcon } from '../../Icons';
import CustomButton from '../../Common/CustomButton';
import Api from '../../../Helper/Api';
import { VenueApiRoutes } from '../../../Utils/routes';

const OrderItemGroup = ({
	item,
	index,
	collapse,
	data,
	authData,
	getVenueOrderList,
	viewOnly
}) => {
	const [markForPickupLoading, setMarkForPickupLoading] = useState(false);
	const handleMarkForPickup = async (item) => {
		let itemsIds = item?.items?.map((value) => value?.id);
		let id = itemsIds?.join(',');
		try {
			setMarkForPickupLoading(true);
			const res = await Api(
				'POST',
				VenueApiRoutes.updateOrderItemStatus,
				{
					bar_id: '' + authData?.selectedVenue?.id,
					id: id,
					orderStatus: 'Pickup'
				}
			);
			if (res?.data?.status) {
				toast.success(res?.data?.message);
				await getVenueOrderList();
			} else {
				toast.error(res?.data?.message);
			}
			setMarkForPickupLoading(false);
		} catch (err) {
			setMarkForPickupLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	return (
		<Fragment>
			<div className="plr-18">
				{item?.items?.length > 0 &&
					item?.items?.map((subItem, subIndex) => {
						return (
							<div
								key={subIndex}
								className={`d-flex justify-content-between ${
									item?.pickup_location
										? 'cursor-pointer'
										: ''
								}`}
							>
								<p
									key={subIndex}
									className={`fs-12 medium-text themeText ${
										subIndex !== item?.items?.length - 1
											? 'pa-b-6'
											: ''
									} d-flex`}
								>
									{subItem?.quantity ?? ''}
									<span className="plr-10">-</span>
									<span className="">
										{subItem?.product?.name ?? ''}
										{subItem?.order_product_variant_types
											?.length > 0 &&
											subItem?.order_product_variant_types?.map(
												(extraItem, index) => (
													<span
														key={index}
														className="opacity-50"
													>
														{', ' +
															extraItem
																?.product_variant_type
																?.order_product_variant_sub_type
																?.product_variant_sub_type
																?.extraItem ??
															''}
													</span>
												)
											)}
										{subItem?.order_item_extras?.length >
											0 &&
											subItem?.order_item_extras?.map(
												(extraItem, index) => (
													<span
														key={index}
														className="opacity-50"
													>
														{', ' +
															extraItem
																?.product_extra
																?.extraItem ??
															''}
													</span>
												)
											)}
									</span>
								</p>
								{index !== 0 && subIndex === 0 && (
									<div className="d-flex align-items-center gap-1 clockIconContainer">
										<ClockIcon
											height={14}
											width={14}
											fill={'#242424'}
										/>
										<p className="fs-14 medium-text themeText">
											{new Date(
												item?.wait_time
											).toLocaleString('en-US', {
												hour: 'numeric',
												minute: 'numeric',
												hour12: true
											})}
										</p>
									</div>
								)}
							</div>
						);
					})}
				{item?.pickup_location && (
					<Collapse isOpen={collapse[index]}>
						<div className="ptb-15">
							<div className="horizontalLine" />
						</div>
						<div className="d-flex justify-content-between gap-4">
							<p className="fs-12 medium-text themeText pickupLocationLabel">
								Pickup Location
							</p>
							<p className="fs-12 medium-text themeText pickupLocationValue opacity-50 d-flex justify-content-end">
								{item?.pickup_location ?? ''}
							</p>
						</div>
					</Collapse>
				)}
			</div>
			<div className="plr-18">
				<div className="ptb-15">
					<div className="horizontalLine" />
				</div>
				<div
					className={`d-flex ${
						index === data?.order_items_group?.length - 1
							? 'justify-content-between'
							: 'justify-content-end'
					} align-items-center`}
				>
					{index === data?.order_items_group?.length - 1 && (
						<p className="fs-11 regular-text">
							{moment(data?.createdAt).fromNow()}
						</p>
					)}
					<div className={`${viewOnly ? 'invisible' : 'visible'}`}>
						<CustomButton
							className="themeBorderButton plr-24 ptb-8"
							loading={markForPickupLoading}
							onClick={(e) => {
								e.stopPropagation();
								handleMarkForPickup(item);
							}}
						>
							Mark for Pick Up
						</CustomButton>
					</div>
				</div>
			</div>
			{index !== data?.order_items_group?.length - 1 && (
				<div className="ptb-15">
					<div className="horizontalLine2" />
				</div>
			)}
		</Fragment>
	);
};

export default OrderItemGroup;
