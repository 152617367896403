import SwitchWrapper from './newSwitch.style';
import { useRef } from 'react';

const NewSwitch = ({
	id,
	name,
	value,
	label,
	className,
	checked,
	onClick,
	onChange,
	disabled = false,
	...rest
}) => {
	const inputRef = useRef();
	const handleClick = (event) => {
		if (onClick) {
			onClick(event);
		}
		inputRef.current.click();
	};
	return (
		<>
			<SwitchWrapper>
				<span
					className="custom-control material-switch"
					onClick={handleClick}
				>
					<input
						type="checkbox"
						name={name}
						value={value}
						ref={inputRef}
						className="material-switch-control-input"
						checked={checked}
						onChange={onChange}
						disabled={disabled}
						{...rest}
						hidden
					/>
					<span className="material-switch-control-indicator d-flex align-items-center">
						&nbsp;&nbsp;
						<text
							className={
								checked ? 'openSwitchText' : 'closeSwitchText'
							}
						>
							{checked ? 'OPEN' : 'CLOSED'}
						</text>
					</span>
				</span>
			</SwitchWrapper>
		</>
	);
};

export default NewSwitch;
