const authActions = {
	LOGIN: 'LOGIN',
	VENUE_LOGIN: 'VENUE_LOGIN',
	VENUE_LOGIN_CHANGE: 'VENUE_LOGIN_CHANGE',
	LOGOUT: 'LOGOUT',
	ADMIN: 'ADMIN',
	CHANGE_LOGIN_TYPE: 'CHANGE_LOGIN_TYPE',
	UPDATE_PROFILE: 'UPDATE_PROFILE',
	UPDATE_VENUE_LIST: 'UPDATE_VENUE_LIST',
	UPDATE_SUBSCRIPTION: 'UPDATE_SUBSCRIPTION',
	SET_SELECTED_VENUE: 'SET_SELECTED_VENUE',
	UPDATE_SELECTED_VENUE: 'UPDATE_SELECTED_VENUE',
	SET_UNAUTHORIZE_MESSAGE: 'SET_UNAUTHORIZE_MESSAGE',
	COPY_PASTE_REQUIRED_OPTIONS_SECTION: 'COPY_REQUIRED_OPTIONS_SECTION',
	COPY_PASTE_REQUIRED_OPTIONS: 'COPY_REQUIRED_OPTIONS',
	COPY_PASTE_ADDITIONAL_EXTRAS: 'COPY_ADDITIONAL_EXTRAS',
	SET_DOCKET_PRINTING_FEATURE: 'SET_DOCKET_PRINTING_FEATURE',
	SET_POS_FEATURE: 'SET_POS_FEATURE',
	SET_PASSCODE_STATUS: 'SET_PASSCODE_STATUS',
	SET_DASHBOARD_STATUS: 'SET_DASHBOARD_STATUS',
	SET_PASSCODE_LENGTH: 'SET_PASSCODE_LENGTH',
	SET_PREVENUE_PROCESS: 'SET_PREVENUE_PROCESS',
	SET_PRE_ACCOUNT_PROCESS: 'SET_PRE_ACCOUNT_PROCESS',
	SET_ACCOUNT_PROCESS_CHANGE: 'SET_ACCOUNT_PROCESS_CHANGE',

	changeLoginType: (data) => {
		return {
			type: authActions.CHANGE_LOGIN_TYPE,
			login_type: data.login_type
		};
	},

	login: (data) => {
		return {
			type: authActions.LOGIN,
			isLogin: true,
			accessToken: data.token,
			first_name: data.first_name,
			last_name: data.last_name,
			email: data.email,
			id: data.id,
			profile_image: data.profile_image,
			modules: data.modules,
			admin_role: data.admin_role,
			mfa_qr_code: data.mfa_qr_code
		};
	},

	venue_login: (data) => {
		return {
			type: authActions.VENUE_LOGIN,
			isLogin: false,
			id: data?.id,
			email: data?.email,
			first_name: data?.first_name,
			last_name: data?.last_name,
			manager_name: data?.manager_name,
			profile_image: data?.profile_image,
			mobile: data?.mobile,
			country_code: data?.country_code,
			pronouns: data?.pronouns,
			address: data?.address,
			bars: data?.bars,
			mfa_qr_code: data?.mfa_qr_code,
			accessToken: data?.token,
			subscriptions: data?.subscriptions
		};
	},

	venue_change_login: (data) => {
		return {
			type: authActions.VENUE_LOGIN_CHANGE,
			isLogin: data
		};
	},

	prevenue_process: (data) => {
		return {
			type: authActions.SET_PREVENUE_PROCESS,
			preVenueProcess: data
		};
	},

	prevenue_account_process: (data) => {
		return {
			type: authActions.SET_PRE_ACCOUNT_PROCESS,
			preAccountProcess: data
		};
	},

	prevenue_account_process_change: (data) => {
		return {
			type: authActions.SET_ACCOUNT_PROCESS_CHANGE,
			accountProcesses: data
		};
	},

	logout: () => {
		return {
			type: authActions.LOGOUT,
			isLogin: false,
			accessToken: null,
			login_type: 'venue'
		};
	},

	admin: (data) => {
		return {
			type: authActions.ADMIN,
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
			profile_image: data.profile_image
		};
	},

	update_profile: (data) => {
		return {
			type: authActions.UPDATE_PROFILE,
			isLogin: true,
			id: data.id,
			email: data.email,
			first_name: data.first_name,
			last_name: data.last_name,
			dob: data.dob,
			manager_name: data.manager_name,
			profile_image: data.profile_image,
			mobile: data.mobile,
			country_code: data.country_code,
			pronouns: data.pronouns,
			address: data.address,
			bars: data.bars,
			mfa_qr_code: data.mfa_qr_code,
			accessToken: data.token
		};
	},

	update_venue_list: (data) => {
		return {
			type: authActions.UPDATE_VENUE_LIST,
			bars: data
		};
	},

	update_subscription: (data) => {
		return {
			type: authActions.UPDATE_SUBSCRIPTION,
			subscriptions: data
		};
	},

	set_selected_venue: (data) => {
		return {
			type: authActions.SET_SELECTED_VENUE,
			selectedVenue: data
		};
	},

	update_selected_venue: (data) => {
		return {
			type: authActions.UPDATE_SELECTED_VENUE,
			data: data
		};
	},

	set_unauthorize_message: (message) => {
		return {
			type: authActions.SET_UNAUTHORIZE_MESSAGE,
			unAuthorizeMessage: message
		};
	},

	copy_paste_required_options_section: (data) => {
		return {
			type: authActions.COPY_PASTE_REQUIRED_OPTIONS_SECTION,
			data: data
		};
	},

	copy_paste_required_options: (data) => {
		return {
			type: authActions.COPY_PASTE_REQUIRED_OPTIONS,
			data: data
		};
	},

	copy_paste_additional_extras: (data) => {
		return {
			type: authActions.COPY_PASTE_ADDITIONAL_EXTRAS,
			data: data
		};
	},

	set_docket_printing_feature: (data) => {
		return {
			type: authActions.SET_DOCKET_PRINTING_FEATURE,
			barId: data?.barId,
			docketStatus: data?.docketStatus
		};
	},
	set_pos_feature: (data) => {
		return {
			type: authActions.SET_POS_FEATURE,
			barId: data?.barId,
			posStatus: data?.posStatus,
			posReferralLink: data?.posReferralLink
		};
	},
	set_passcode_status: (data) => {
		return {
			type: authActions.SET_PASSCODE_STATUS,
			barId: data?.barId,
			passcodeStatus: data?.passcodeStatus
		};
	},
	set_dashboard_status: (data) => {
		return {
			type: authActions.SET_DASHBOARD_STATUS,
			barId: data?.barId,
			dashboardStatus: data?.dashboardStatus
		};
	},
	set_passcode_length: (data) => {
		return {
			type: authActions.SET_PASSCODE_LENGTH,
			barId: data?.barId,
			passcodeLength: data?.passcodeLength
		};
	}
};

export default authActions;
