import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TabContent, TabPane } from 'reactstrap';
import { Tabs, Tab } from 'react-tabs-scrollable';
import Scrollbars from 'react-custom-scrollbars';
import 'react-tabs-scrollable/dist/rts.css';
import { toast } from 'react-toastify';

import { TabsWrapper, PageWrapper } from './legal.style';
import PageStructure from '../../Components/Common/PageStructure';
import Api from '../../Helper/Api';
import { VenueApiRoutes } from '../../Utils/routes';
import Loader from '../../Components/Common/Loader';

export const Legal = () => {
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	const [tabId, setTabId] = useState(0);
	const [termsLoading, setTermsLoading] = useState(false);
	const [privacyLoading, setPrivacyLoading] = useState(false);
	const [termsData, setTermsData] = useState(null);
	const [privacyData, setPrivacyData] = useState(null);
	const onTabClick = (e, index) => {
		setTabId(index);
	};
	const getTermsAndConditions = async () => {
		setTermsLoading(true);
		try {
			const res = await Api('GET', VenueApiRoutes.termsAndConditions);
			if (res?.data?.status) {
				setTermsData(res?.data?.data?.content);
			} else {
				toast.error(res?.data?.message);
			}
			setTermsLoading(false);
		} catch (err) {
			setTermsLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	const getPrivacyPolicy = async () => {
		setPrivacyLoading(true);
		try {
			const res = await Api('GET', VenueApiRoutes.privacyPolicy);
			if (res?.data?.status) {
				setPrivacyData(res?.data?.data?.content);
			} else {
				toast.error(res?.data?.message);
			}
			setPrivacyLoading(false);
		} catch (err) {
			setPrivacyLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	useEffect(() => {
		(async () => {
			await getTermsAndConditions();
			await getPrivacyPolicy();
		})();
	}, []);
	return (
		<PageStructure
			title="Legal"
			pageText={
				<TabsWrapper {...allThemeData}>
					<div className="customScrollableNavbar position-relative pa-t-24">
						<Tabs
							activeTab={tabId}
							onTabClick={onTabClick}
							leftBtnIcon={<i className="fa fa-angle-left"></i>}
							rightBtnIcon={<i className="fa fa-angle-right"></i>}
							navBtnsIconColor={'#4F4F4F'}
							className="customScrollableNavbar"
						>
							<Tab
								key={0}
								className={`customScrollableNavItem fs-12 regular-text ${
									tabId === 0 ? 'active' : ''
								}`}
							>
								Terms & Conditions
							</Tab>
							<Tab
								key={1}
								className={`customScrollableNavItem fs-12 regular-text ${
									tabId === 1 ? 'active' : ''
								}`}
							>
								Privacy Policy
							</Tab>
						</Tabs>
						<div className="borderBottom position-absolute bottom-0 w-100 zIndex-1" />
					</div>
				</TabsWrapper>
			}
		>
			<PageWrapper {...allThemeData}>
				<TabContent activeTab={tabId} className="overflow-hidden">
					{/* Terms & Conditions Tab */}
					<TabPane tabId={0}>
						{termsLoading ? (
							<span className="loaderContainer">
								<Loader />
							</span>
						) : (
							<div
								dangerouslySetInnerHTML={{
									__html: termsData
								}}
								className="fs-12"
							/>
						)}
					</TabPane>
					{/* Privacy Policy Tab */}
					<TabPane tabId={1}>
						{privacyLoading ? (
							<span className="loaderContainer">
								<Loader />
							</span>
						) : (
							<div
								dangerouslySetInnerHTML={{
									__html: privacyData
								}}
								className="fs-12"
							/>
						)}
					</TabPane>
				</TabContent>
			</PageWrapper>
		</PageStructure>
	);
};
