import { Pagination } from '../../../Common/Pagination';
import TableStyle from '../../../Common/TableStyle';
import TableV6 from '../../../Common/TableV6';
import TableSkeleton from './TableSkeleton';

const DocketPrintersTable = ({
	columns,
	data,
	loading,
	handlePageChange,
	totalRows,
	currentPage
}) => {
	return (
		<>
			{loading ? (
				<TableSkeleton />
			) : (
				<TableStyle version={6}>
					<TableV6
						columns={columns}
						data={data}
						NoDataText={'No data found'}
					/>
					{data?.length !== 0 && (
						<Pagination
							handlePageChange={handlePageChange}
							total={totalRows}
							currentPage={currentPage}
						/>
					)}
				</TableStyle>
			)}
		</>
	);
};

export default DocketPrintersTable;
