export const DashboardIcon = ({ fill, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 32 32" fill="none">
			<path
				d="M11.75 15.25V21.25C11.75 21.6719 11.375 22 11 22C10.5781 22 10.25 21.6719 10.25 21.25V15.25C10.25 14.875 10.5781 14.5 11 14.5C11.375 14.5 11.75 14.875 11.75 15.25ZM14.75 10.75C14.75 10.375 15.0781 10 15.5 10C15.875 10 16.25 10.375 16.25 10.75V21.25C16.25 21.6719 15.875 22 15.5 22C15.0781 22 14.75 21.6719 14.75 21.25V10.75ZM20.75 18.25V21.25C20.75 21.6719 20.375 22 20 22C19.5781 22 19.25 21.6719 19.25 21.25V18.25C19.25 17.875 19.5781 17.5 20 17.5C20.375 17.5 20.75 17.875 20.75 18.25ZM5 8.5C5 6.85938 6.3125 5.5 8 5.5H23C24.6406 5.5 26 6.85938 26 8.5V23.5C26 25.1875 24.6406 26.5 23 26.5H8C6.3125 26.5 5 25.1875 5 23.5V8.5ZM6.5 8.5V23.5C6.5 24.3438 7.15625 25 8 25H23C23.7969 25 24.5 24.3438 24.5 23.5V8.5C24.5 7.70312 23.7969 7 23 7H8C7.15625 7 6.5 7.70312 6.5 8.5Z"
				fill={fill ?? '#242424'}
			/>
		</svg>
	);
};
