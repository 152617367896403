import { useMediaQuery } from 'react-responsive';

const useDevice = () => {
	const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });
	const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
	const isBigScreenHeight = useMediaQuery({ query: '(min-height: 900px)' });
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
	const isTablet = useMediaQuery({ query: '(max-width: 768px)' });
	const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
	const isMobile = useMediaQuery({ query: '(min-width: 575.98px)' });

	return {
		isDesktopOrLaptop,
		isBigScreen,
		isBigScreenHeight,
		isTablet,
		isTabletOrMobile,
		isPortrait,
		isMobile
	};
};

export default useDevice;
