import { useState } from 'react';
import {
	Col,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Row
} from 'reactstrap';
import { DownArrowIcon, UpArrowIcon } from '../Icons';

const ActivityCard = () => {
	const [dateRangePickerLabel, setDateRangePickerLabel] =
		useState('This Month');
	const [dateDropdown, setDateDropdown] = useState(false);

	const handleDateRangeLabel = (label) => {
		setDateRangePickerLabel(label);
	};

	return (
		<div className="d-flex w-100 h-100 flex-column gap-3 justify-content-between">
			<div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-3">
				<div className="fs-20 semi-bold-text">Activity</div>
				<div>
					<Dropdown
						isOpen={dateDropdown}
						toggle={() => setDateDropdown(!dateDropdown)}
						direction="down"
						className="dashboard-dropdown"
					>
						<DropdownToggle
							color="#fff"
							className="ptb-0 dropdownToggle"
						>
							<span
								className={`dropdown-name fs-12 medium-text ${
									dateDropdown ? 'activeColor' : ''
								}`}
							>
								{dateRangePickerLabel}
							</span>
							<span>
								{dateDropdown ? (
									<UpArrowIcon height={7} width={12} />
								) : (
									<DownArrowIcon height={7} width={12} />
								)}
							</span>
						</DropdownToggle>
						<DropdownMenu className="datePickerDropdown">
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'Today'
										? 'active'
										: ''
								}`}
								onClick={() => handleDateRangeLabel('Today')}
							>
								Today
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'Yesterday'
										? 'active'
										: ''
								}`}
								onClick={() =>
									handleDateRangeLabel('Yesterday')
								}
							>
								Yesterday
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'Last Week'
										? 'active'
										: ''
								}`}
								onClick={() =>
									handleDateRangeLabel('Last Week')
								}
							>
								Last Week
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'This Month'
										? 'active'
										: ''
								}`}
								onClick={() =>
									handleDateRangeLabel('This Month')
								}
							>
								This Month
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'Last Month'
										? 'active'
										: ''
								}`}
								onClick={() =>
									handleDateRangeLabel('Last Month')
								}
							>
								Last Month
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'Last Year'
										? 'active'
										: ''
								}`}
								onClick={() =>
									handleDateRangeLabel('Last Year')
								}
							>
								Last Year
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'Current Year'
										? 'active'
										: ''
								}`}
								onClick={() =>
									handleDateRangeLabel('Current Year')
								}
							>
								Current Year
							</DropdownItem>
							<DropdownItem
								className={`${
									dateRangePickerLabel === 'All'
										? 'active'
										: ''
								}`}
								onClick={() => handleDateRangeLabel('All')}
							>
								All
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</div>
			</div>
			<div className="d-flex flex-column ptb-2">
				<Row>
					<Col sm={12} className="themeBorderBottom">
						<p className="fs-14 medium-text headingTextColor">
							Most Popular Venue
						</p>
						<p className="fs-20 semi-bold-text pb-2">
							The Good Egg Cafe
						</p>
					</Col>
				</Row>
				<Row className="ptb-10">
					<Col sm={6}>
						<p className="fs-14 medium-text headingTextColor">
							Most Popular City
						</p>
						<p className="fs-20 semi-bold-text pb-2">Bussleton</p>
					</Col>
					<Col sm={6}>
						<p className="fs-14 medium-text headingTextColor">
							Most Popular Product
						</p>
						<p className="fs-20 semi-bold-text pb-2">Latte</p>
					</Col>
				</Row>
				<div className="p-1">
					<div className="d-flex align-items-center justify-content-center ptb-10 gap-2">
						<div className="d-flex flex-1 gap-2 flex-column justify-content-between activity-card">
							<p className="fs-14 medium-text headingTextColor">
								Highest Returning Customer Order Value
							</p>
							<p className="fs-20 semi-bold-text">5,000</p>
						</div>
						<div className="d-flex flex-1 gap-2 flex-column justify-content-between activity-card">
							<p className="fs-14 medium-text headingTextColor">
								Highest Customer Order Value
							</p>
							<p className="fs-20 semi-bold-text">5,000</p>
						</div>
					</div>
					<div className="d-flex align-items-center justify-content-center pt-10 gap-2">
						<div className="d-flex flex-1 gap-2 flex-column justify-content-between activity-card">
							<p className="fs-14 medium-text headingTextColor">
								New MyTab Venues
							</p>
							<p className="fs-20 semi-bold-text">5,000</p>
						</div>
						<div className="d-flex flex-1 gap-2 flex-column justify-content-between activity-card">
							<p className="fs-14 medium-text headingTextColor">
								New MyTab Customers
							</p>
							<p className="fs-20 semi-bold-text">5,000</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ActivityCard;
