import { useState } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import * as yup from 'yup';

import * as validation from '../../../Helper/YupValidation';
import CustomModal from '../../Common/Modal/CustomModal';
import Api from '../../../Helper/Api';
import CustomButton from '../../Common/CustomButton';
import { VenueApiRoutes } from '../../../Utils/routes';
import authActions from '../../../Redux/auth/actions';
import FormInput from '../../Form/FormInput';

const VerifyPasswordModal = ({ isOpen, handleModal }) => {
	const authData = useSelector((state) => ({ ...state.auth }));
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const validationSchema = yup.object().shape({
		password: validation.YUP_VALIDATION.PASSWORD
	});
	const handelOnsubmit = async () => {
		const payload = {
			password: values?.password
		};
		setLoading(true);
		try {
			const res = await Api(
				'POST',
				VenueApiRoutes?.verifyPassword,
				payload
			);
			setLoading(false);
			if (res?.data?.status) {
				handleModal();
				dispatch(
					authActions?.set_dashboard_status({
						barId: authData?.selectedVenue?.id,
						dashboardStatus: 'EDIT'
					})
				);
				resetForm();
				toast.success(res?.data?.message);
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	const {
		handleChange,
		handleSubmit,
		errors,
		touched,
		values,
		resetForm,
		handleBlur
	} = useFormik({
		initialValues: {
			password: ''
		},
		validationSchema,
		onSubmit: handelOnsubmit
	});
	return (
		<CustomModal
			isOpen={isOpen}
			size="md"
			handleModal={handleModal}
			title="Password Verification"
		>
			<form className="overflow-hidden p-1" onSubmit={handleSubmit}>
				<Row>
					<Col sm={12}>
						<FormInput
							type="password"
							name="password"
							label="Password"
							placeholder="Enter Password"
							value={values?.password}
							onChange={handleChange}
							onBlur={handleBlur}
							error={touched?.password && !!errors?.password}
							errorMsg={errors?.password}
						/>
					</Col>
					<Col
						sm={12}
						className="d-flex align-items-center justify-content-center pa-t-18"
					>
						<CustomButton
							type="submit"
							className="themeButtonFullWidth"
							loading={loading}
						>
							Verify
						</CustomButton>
					</Col>
				</Row>
			</form>
		</CustomModal>
	);
};

export default VerifyPasswordModal;
