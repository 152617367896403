import { useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import moment from 'moment';
import momentTZ from 'moment-timezone';

import { PageWrapper } from './index.style';
import PageStructure from '../../Components/Common/PageStructure';
import TableFilter from '../../Components/OrderHistory/TableFilter';
import OrdersHistoryTable from '../../Components/OrderHistory/OrdersHistoryTable';
import OrderViewModal from '../../Components/OrderHistory/OrderViewModal';
import RefundInitiateModal from '../../Components/OrderHistory/RefundInitiateModal';
import RefundAmountModal from '../../Components/OrderHistory/RefundAmountModal';
import { formatTableData } from '../../Components/OrderHistory/utils';
import Api from '../../Helper/Api';
import { VenueApiRoutes } from '../../Utils/routes';
import { useDebounce } from '../../Hooks/useDebounce';
import { somethingWentWrongError } from '../../Helper/somethingWentWrongError';

export const OrderHistory = () => {
	const state = useSelector((state) => ({ ...state }));
	const allThemeData = state.themeChanger;
	const authDetails = state.auth;
	const [loading, setLoading] = useState(false);
	const [exportLoading, setExportLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [tableData, setTableData] = useState([]);
	const [tableDataCount, setTableDataCount] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');
	const debounceSearchTerm = useDebounce(searchTerm, 500);
	const [dateRange, setDateRange] = useState([
		moment(new Date()).format('YYYY-MM-DD'),
		moment(new Date()).format('YYYY-MM-DD')
	]);
	const [selectedOrderTypeValue, setSelectedOrderTypeValue] = useState('');
	const [orderViewModal, setOrderViewModal] = useState(false);
	const [refundInitiateModal, setRefundInitiateModal] = useState(false);
	const [refundModal, setRefundModal] = useState(false);
	const [singleRowData, setSingleRowData] = useState(null);
	const handleRefundModal = () => {
		setRefundModal((prev) => !prev);
	};
	const handleRefundInitiateModal = () => {
		setRefundInitiateModal((prev) => !prev);
	};
	const handleOrderViewModal = () => {
		setOrderViewModal((prev) => !prev);
	};
	const handleViewClick = (rowData) => {
		setSingleRowData(rowData);
		setOrderViewModal(true);
	};
	const handleRefundClick = (rowData) => {
		if (Number(rowData?.isPosOrder) === 0) {
			if (rowData?.refundStatus === 'Refunded') {
				toast.error('Items already refunded.');
			} else {
				setSingleRowData(rowData);
				setRefundInitiateModal(true);
			}
		} else {
			toast.warning(
				'As you have POS integration enabled, all orders must be refunded through your POS system.'
			);
		}
	};
	const handleRefundedAmountClick = (rowData) => {
		if (rowData?.refundStatus === 'No') {
			toast.error('No items refunded');
		} else {
			setSingleRowData(rowData);
			setRefundModal(true);
		}
	};
	const tableColumns = [
		{
			Header: 'Order Date',
			accessor: 'order_date',
			className: 'fs-12 regular-text',
			minWidth: 124,
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-12 medium-text',
			// Cell: ({ row }) => format(new Date(row.order_date), 'dd/MM/Y'),
			Cell: ({ row }) => {
				if (row?._original?.rowData?.createdAt) {
					return momentTZ(row?._original?.rowData?.createdAt)
						.tz('Australia/Perth')
						.format('DD/MM/YYYY');
				}
				return '-';
			}
		},
		{
			Header: <span className="fs-12 medium-text">Customer</span>,
			accessor: 'customer',
			className: 'fs-12 regular-text justify-content-start',
			minWidth: 220,
			filterable: false,
			sortable: false,
			headerClassName:
				'react-table-header-class fs-12 medium-text justify-content-start'
		},
		{
			Header: 'Order ID',
			accessor: 'order_no',
			className: 'fs-12 regular-text',
			minWidth: 128,
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-12 medium-text'
		},
		{
			Header: 'Service Type',
			accessor: 'orderServiceType',
			className: 'fs-12 regular-text',
			minWidth: 136,
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-12 medium-text'
		},
		{
			Header: 'Pick Up Code',
			accessor: 'pickupCode',
			className: 'fs-12 regular-text',
			minWidth: 142,
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-12 medium-text'
		},
		{
			Header: 'Table No.',
			accessor: 'tableCode',
			className: 'fs-12 regular-text',
			minWidth: 108,
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-12 medium-text'
		},
		{
			Header: 'Order Status',
			accessor: 'order_status',
			className: 'fs-12 regular-text',
			minWidth: 184,
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-12 medium-text'
		},
		{
			Header: 'Payment Status',
			accessor: 'payment_status',
			className: 'fs-12 regular-text',
			minWidth: 164,
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-12 medium-text'
		},
		{
			Header: 'Taxes',
			accessor: 'taxes',
			className: 'fs-12 regular-text',
			minWidth: 98,
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-12 medium-text'
		},
		// {
		// 	Header: 'Taxes (%)',
		// 	accessor: 'taxes_percentage',
		// 	className: 'fs-12 regular-text',
		// 	filterable: false,
		// 	sortable: false,
		// 	headerClassName: 'react-table-header-class fs-12 medium-text'
		// },
		{
			Header: 'Promo Code',
			accessor: 'promo_code',
			className: 'fs-12 regular-text',
			minWidth: 134,
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-12 medium-text'
		},
		{
			Header: 'Promo Code Amount',
			accessor: 'promo_code_amount',
			className: 'fs-12 regular-text',
			minWidth: 200,
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-12 medium-text'
		},
		{
			Header: 'Promo Code (%)',
			accessor: 'promo_code_number',
			className: 'fs-12 regular-text',
			minWidth: 158,
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-12 medium-text'
		},
		// {
		// 	Header: 'Promo Code Type',
		// 	accessor: 'promo_code_type',
		// 	className: 'fs-12 regular-text',
		// 	minWidth: 150,
		// 	filterable: false,
		// 	sortable: false,
		// 	headerClassName: 'react-table-header-class fs-12 medium-text'
		// },
		{
			Header: 'Total Paid By Customer',
			accessor: 'total_amount',
			className: 'fs-12 regular-text',
			minWidth: 224,
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-12 medium-text'
		},
		{
			Header: 'MyTab Customer Fee',
			accessor: 'mytab_fee',
			className: 'fs-12 regular-text',
			minWidth: 216,
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-12 medium-text'
		},
		{
			Header: 'Stripe Transaction Fee',
			accessor: 'stripe_transaction_fee',
			className: 'fs-12 regular-text',
			minWidth: 216,
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-12 medium-text'
		},
		{
			Header: 'POS Integration Fee',
			accessor: 'pos_integration_fee',
			className: 'fs-12 regular-text',
			minWidth: 216,
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-12 medium-text'
		},
		{
			Header: 'Docket Printing Fee',
			accessor: 'docket_printing_fee',
			className: 'fs-12 regular-text',
			minWidth: 216,
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-12 medium-text'
		},
		{
			Header: 'Refund',
			accessor: 'refund',
			className: 'fs-12 regular-text',
			minWidth: 164,
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-12 medium-text'
		},
		{
			Header: 'Refunded Amount',
			accessor: 'refund_amount',
			className: 'fs-12 regular-text',
			minWidth: 184,
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-12 medium-text'
		},
		{
			Header: 'Net Revenue (incl GST)',
			accessor: 'net_revenue',
			className: 'fs-12 regular-text',
			minWidth: 220,
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-12 medium-text'
		},
		{
			Header: 'Manage',
			accessor: 'manage',
			className: 'fs-12 regular-text text-center',
			headerClassName: 'react-table-header-class fs-12 medium-text',
			sortable: false,
			filterable: false,
			width: 272,
			Cell: (data) => {
				let { original } = data;
				return (
					<div className="react-action-class">
						<div className="d-flex">
							<div
								className="boxBorderRight pr-8 fs-12 medium-text headingTextColor2 cursor-pointer"
								onClick={() => {
									handleViewClick(original?.rowData);
								}}
							>
								View
							</div>
							<div
								className="boxBorderRight plr-8 fs-12 medium-text headingTextColor2 cursor-pointer"
								onClick={() => {
									handleRefundClick(original?.rowData);
								}}
							>
								Refund
							</div>
							<div
								className="headingTextColor2 pl-8 fs-12 medium-text cursor-pointer"
								onClick={() => {
									handleRefundedAmountClick(
										original?.rowData
									);
								}}
							>
								Refunded Amount
							</div>
						</div>
					</div>
				);
			}
		}
	];
	const getTableData = async (payload) => {
		try {
			setLoading(true);
			const res = await Api(
				'POST',
				VenueApiRoutes.getOrderHistoryTableData,
				payload
			);
			if (res?.data?.status) {
				const tableDetails = formatTableData(res?.data?.data?.rows);
				setTableData(tableDetails);
				setTableDataCount(res?.data?.data?.count);
			} else {
				toast.error(res?.data?.message);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			toast.error(err?.response?.data?.message);
		}
	};
	const handleExport = async () => {
		if (tableData?.length > 0) {
			try {
				setExportLoading(true);
				const res = await Api(
					'POST',
					VenueApiRoutes.exportOrderHistoryTableData,
					{
						bar_id: authDetails?.selectedVenue?.id,
						orderType: selectedOrderTypeValue,
						search: debounceSearchTerm,
						startDate: dateRange[0],
						endDate: dateRange[1]
					}
				);
				if (res?.data?.status) {
					const blob = new Blob([res?.data?.data], {
						type: 'text/plain;charset=utf-8'
					});
					fileDownload(
						blob,
						'MyTab_Customer_Transaction_Order_History.csv'
					);
					toast.success(res?.data?.message);
				} else {
					toast.error(res?.data?.message);
				}
				setExportLoading(false);
			} catch (err) {
				setExportLoading(false);
				somethingWentWrongError();
			}
		} else {
			toast.error('No data available for export.');
		}
	};
	const handlePageChange = ({ selected }) => {
		let pageNo = selected + 1;
		setCurrentPage(pageNo);
	};
	const handleSearchInputChange = async (value) => {
		setSearchTerm(value);
		setCurrentPage(1);
	};
	const handleDateRangeChange = (data) => {
		let date1 = moment(data[0]).format('YYYY-MM-DD');
		let date2 = moment(data[1]).format('YYYY-MM-DD');
		if (date1 === 'Invalid date') {
			date1 = '';
		}
		if (date2 === 'Invalid date') {
			date2 = '';
		}
		setDateRange([date1, date2]);
	};
	const handleOrderTypeChange = (orderTypeValue) => {
		setSelectedOrderTypeValue(orderTypeValue);
	};
	useEffect(() => {
		(async () => {
			await getTableData({
				page: currentPage,
				bar_id: authDetails?.selectedVenue?.id,
				orderType: selectedOrderTypeValue,
				search: debounceSearchTerm,
				startDate: dateRange[0],
				endDate: dateRange[1]
			});
		})();
	}, [
		currentPage,
		selectedOrderTypeValue,
		debounceSearchTerm,
		dateRange[0],
		dateRange[1],
		authDetails?.selectedVenue?.id
	]);
	return (
		<>
			<PageStructure
				title="Customer Transaction Order History"
				pageText={
					<TableFilter
						handleSearchInputChange={handleSearchInputChange}
						handleExport={handleExport}
						exportLoading={exportLoading}
						handleDateRangeChange={handleDateRangeChange}
						handleOrderTypeChange={handleOrderTypeChange}
					/>
				}
			>
				<PageWrapper {...allThemeData}>
					<OrdersHistoryTable
						loading={loading}
						tableColumns={tableColumns}
						tableData={tableData}
						handlePageChange={handlePageChange}
						totalRows={tableDataCount}
						currentPage={currentPage}
					/>
				</PageWrapper>
			</PageStructure>
			{singleRowData && orderViewModal && (
				<OrderViewModal
					isOpen={orderViewModal}
					handleModal={handleOrderViewModal}
					singleRowData={singleRowData}
				/>
			)}
			{singleRowData && refundInitiateModal && (
				<RefundInitiateModal
					isOpen={refundInitiateModal}
					handleModal={handleRefundInitiateModal}
					singleRowData={singleRowData}
					getTableData={() => {
						getTableData({
							page: currentPage,
							bar_id: authDetails?.selectedVenue?.id,
							orderType: selectedOrderTypeValue,
							search: debounceSearchTerm,
							startDate: dateRange[0],
							endDate: dateRange[1]
						});
					}}
				/>
			)}
			{singleRowData && refundModal && (
				<RefundAmountModal
					isOpen={refundModal}
					handleModal={handleRefundModal}
					singleRowData={singleRowData}
				/>
			)}
		</>
	);
};
