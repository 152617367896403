import { useFormik } from 'formik';
import { Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useState } from 'react';
import * as yup from 'yup';

import CustomModal from '../../Common/Modal/CustomModal';
import FormInput from '../../Form/FormInput';
import * as validation from '../../../Helper/YupValidation';
import VerifyOtpModal from './VerifyOtpModal';
import { VenueApiRoutes } from '../../../Utils/routes';
import Api from '../../../Helper/Api';
import CustomButton from '../../Common/CustomButton';

const VenueAccountVerificationModal = ({
	isOpen,
	handleModal,
	navigateTo,
	refetch
}) => {
	const [isOpenVerifyOtpModal, setIsOpenVerifyOtpModal] = useState(false);
	const [modalData, setModalData] = useState(false);
	const [loading, setLoading] = useState(false);
	const handleVerifyOtpModal = () => {
		setIsOpenVerifyOtpModal((prev) => !prev);
	};
	const initialValues = {
		email: '',
		password: ''
	};
	const validationSchema = yup.object().shape({
		email: validation.YUP_VALIDATION.EMAIL,
		password: validation.YUP_VALIDATION.PASSWORD
	});
	const {
		handleSubmit,
		touched,
		errors,
		handleChange,
		values,
		resetForm,
		handleBlur
	} = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			try {
				setLoading(true);
				const res = await Api('POST', VenueApiRoutes.connectVenue, {
					...values
				});
				setLoading(false);
				if (res?.data?.status) {
					resetForm();
					toast.success(res?.data?.message);
					setModalData({
						email: values?.email
					});
					handleModal();
					setIsOpenVerifyOtpModal(true);
				} else {
					toast.error(res?.data?.message);
				}
			} catch (err) {
				setLoading(false);
				if (err?.message) {
					toast.error(err?.message);
				}
			}
		}
	});
	return (
		<>
			<CustomModal
				title={'Connect Your MyTab Venue To Your Account'}
				size="lg"
				isOpen={isOpen}
				handleModal={handleModal}
				style={{ maxWidth: '650px', width: '100%' }}
			>
				<form className="overflow-hidden" onSubmit={handleSubmit}>
					<Row>
						<Col sm={12} className="pa-b-20">
							<FormInput
								type="email"
								name="email"
								label="MyTab Venue Email"
								placeholder="Enter MyTab Venue Email"
								value={values?.email}
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched?.email && !!errors?.email}
								errorMsg={errors?.email}
							/>
						</Col>
						<Col sm={12} className="pa-b-24">
							<FormInput
								type="password"
								name="password"
								label="MyTab Venue Password"
								placeholder="Enter MyTab Venue Password"
								value={values?.password}
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched?.password && !!errors?.password}
								errorMsg={errors?.password}
							/>
						</Col>
						<Col sm={12}>
							<CustomButton
								type="submit"
								className="themeButtonFullWidth"
								loading={loading}
							>
								Request Verification
							</CustomButton>
						</Col>
					</Row>
				</form>
			</CustomModal>
			<VerifyOtpModal
				isOpen={isOpenVerifyOtpModal}
				handleModal={handleVerifyOtpModal}
				navigateTo={navigateTo}
				modalData={modalData}
				refetch={refetch}
			/>
		</>
	);
};

export default VenueAccountVerificationModal;
