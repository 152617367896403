import styled from 'styled-components';

const PageWrapper = styled.div`
	color: #4f4f4f !important;
	.editProfileButton {
		border-radius: 50% !important;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: ${(props) => props.layoutTheme.buttonColor2};
		height: 32px;
		width: 32px;
	}
	.profilePicBox {
		.rounded {
			border-radius: 50% !important;
		}
	}
`;

export default PageWrapper;
