export const LiveIcon = ({ fill, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 32 32" fill="none">
			<path
				d="M15.5 13C13.8125 13 12.5 14.3594 12.5 16C12.5 17.5938 13.8125 18.9531 15.5 18.9531C17.1406 18.9531 18.5 17.5938 18.5 16C18.5 14.3594 17.1406 13 15.5 13ZM15.5 17.5C14.6562 17.5 14 16.8438 14 16.0469C14 15.2969 14.6562 14.5938 15.5 14.5938C16.2969 14.5938 17 15.2969 17 16.0469C17 16.8438 16.2969 17.5 15.5 17.5ZM3.5 16C3.5 13.0938 4.625 10.375 6.6875 8.3125C6.96875 8.03125 6.96875 7.51562 6.6875 7.23438C6.35938 6.95312 5.89062 6.95312 5.60938 7.23438C3.26562 9.57812 2 12.7188 2 16C2 19.3281 3.26562 22.4688 5.60938 24.8125C5.75 24.9531 5.9375 25 6.125 25C6.3125 25 6.5 24.9531 6.6875 24.8125C6.96875 24.5312 6.96875 24.0156 6.6875 23.7344C4.625 21.6719 3.5 18.9531 3.5 16ZM25.3438 7.23438C25.0625 6.95312 24.5938 6.95312 24.2656 7.23438C23.9844 7.51562 23.9844 8.03125 24.2656 8.3125C26.3281 10.375 27.5 13.0938 27.5 16C27.5 18.9531 26.3281 21.6719 24.2656 23.7344C23.9844 24.0156 23.9844 24.5312 24.2656 24.8125C24.4531 24.9531 24.6406 25 24.8281 25C25.0156 25 25.2031 24.9531 25.3438 24.8125C27.6875 22.4688 29 19.3281 29 16C29 12.7188 27.6875 9.57812 25.3438 7.23438ZM9.82812 10.2344C9.54688 9.95312 9.03125 9.95312 8.75 10.2812C7.29688 11.8281 6.5 13.8906 6.5 16C6.5 18.1562 7.29688 20.2188 8.75 21.7656C8.9375 21.9062 9.125 21.9531 9.3125 21.9531C9.5 21.9531 9.6875 21.8594 9.82812 21.7188C10.1094 21.4375 10.1562 20.9688 9.875 20.6875C8.65625 19.4688 8 17.7812 8 16C8 14.2656 8.65625 12.5781 9.875 11.3125C10.1562 10.9844 10.1094 10.5156 9.82812 10.2344ZM22.2031 10.2344C21.9219 9.95312 21.4062 9.95312 21.125 10.2344C20.8438 10.5156 20.7969 10.9844 21.0781 11.2656C22.2969 12.5781 23 14.2656 23 16C23 17.7812 22.2969 19.4688 21.0781 20.7344C20.7969 21.0156 20.8438 21.4844 21.125 21.7656C21.2656 21.9062 21.4531 22 21.6406 22C21.8281 22 22.0156 21.9062 22.2031 21.7656C23.6562 20.2188 24.5 18.1562 24.5 16C24.5 13.8906 23.6562 11.8281 22.2031 10.2344Z"
				fill={fill ?? '#242424'}
			/>
		</svg>
	);
};
