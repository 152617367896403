export const AppName = 'MyTab';
export const drawerWidth = '334px';
export const miniDrawerWidth = '80px';
export const themeSettingDrawerWidth = '300px';
export const chatDrawerWidth = '260px';
export const chatMiniDrawerWidth = '0px';
export const baseUrl = process.env.REACT_APP_BASE_URL;
export const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const supportDoshiiEmail = 'support@doshii.com';
export const supportVenueEmail = 'venue@mytabinfo.com';
export const backgroundImage = require('../Assets/images/background.png');
export const brownShoe = require('../Assets/images/brown-shoe.jpeg');
export const speaker = require('../Assets/images/speaker.jpeg');
export const loginBack = require('../Assets/images/loginback.jpg');
export const iconDemo = require('../Assets/images/dashboard-logo.png');
export const ForgotIcon = require('../Assets/images/forgotpassword.svg');
export const locakscreenBack = require('../Assets/images/lockscreen.jpg');
export const ProfileLockScreen = require('../Assets/images/profile.jpg');
export const errorback = require('../Assets/images/errorback.jpg');
export const feed1 = require('../Assets/images/feed1.jpg');
export const feed2 = require('../Assets/images/feed2.jpg');
export const friend1 = require('../Assets/images/friend1.jpg');
export const friend2 = require('../Assets/images/friend2.jpg');
export const friend3 = require('../Assets/images/friend3.jpg');
export const friend4 = require('../Assets/images/friend4.jpg');
export const friend5 = require('../Assets/images/friend5.jpg');
export const friend6 = require('../Assets/images/friend6.jpg');
export const media1 = require('../Assets/images/media1.jpeg');
export const media2 = require('../Assets/images/media2.jpeg');
export const media3 = require('../Assets/images/media3.jpeg');
export const media4 = require('../Assets/images/media4.jpeg');
export const media5 = require('../Assets/images/media5.jpeg');
export const media6 = require('../Assets/images/media6.jpeg');
export const media7 = require('../Assets/images/media7.jpeg');
export const media8 = require('../Assets/images/media8.jpeg');
export const media9 = require('../Assets/images/media9.jpeg');
export const sidebar1 = require('../Assets/images/sidebar1.jpg');
export const sidebar2 = require('../Assets/images/sidebar2.jpg');
export const sidebar3 = require('../Assets/images/sidebar3.jpg');
export const sidebar4 = require('../Assets/images/sidebar4.jpg');
export const sidebar5 = require('../Assets/images/sidebar5.jpg');
export const sidebar6 = require('../Assets/images/sidebar6.jpg');
export const sidebar7 = require('../Assets/images/sidebar7.jpg');
export const sidebar8 = require('../Assets/images/sidebar8.jpg');
export const Img6464 = require('../Assets/images/6464Img.svg');

export const USERTYPE = {
	BUYER: 'buyer',
	MERCHANT: 'merchant'
};

export const SORT = {
	ASC: 'ASC',
	DESC: 'DESC'
};

export const TABLE = {
	LIMIT: 10
};

export const DEFAULT = {
	FIELD: 'createdAt',
	SEARCH: '',
	PAGE: 1,
	SORT: SORT.DESC,
	USERTYPE: USERTYPE.BUYER,
	ADDRESS_ID: 0
};

export const ImageResolution = {
	HEIGHT: 100,
	WIDTH: 100
};

export const WEEKDAYS = [
	{
		id: 1,
		name: 'Monday'
	},
	{
		id: 2,
		name: 'Tuesday'
	},
	{
		id: 3,
		name: 'Wednesday'
	},
	{
		id: 4,
		name: 'Thursday'
	},
	{
		id: 5,
		name: 'Friday'
	},
	{
		id: 6,
		name: 'Saturday'
	},
	{
		id: 7,
		name: 'Sunday'
	}
];
export const DAYS = {
	MONDAY: WEEKDAYS[0],
	TUESDAY: WEEKDAYS[1],
	WEDNESDAY: WEEKDAYS[2],
	THURSDAY: WEEKDAYS[3],
	FRIDAY: WEEKDAYS[4],
	SATURDAY: WEEKDAYS[5],
	SUNDAY: WEEKDAYS[6]
};

export const getDays = (day_number) => {
	switch (day_number) {
		case DAYS.MONDAY.id:
			return DAYS.MONDAY.name;
		case DAYS.TUESDAY.id:
			return DAYS.TUESDAY.name;
		case DAYS.WEDNESDAY.id:
			return DAYS.WEDNESDAY.name;
		case DAYS.THURSDAY.id:
			return DAYS.THURSDAY.name;
		case DAYS.FRIDAY.id:
			return DAYS.FRIDAY.name;
		case DAYS.SATURDAY.id:
			return DAYS.SATURDAY.name;
		case DAYS.SUNDAY.id:
			return DAYS.SUNDAY.name;
		default:
			return '';
	}
};

export const substractArrays = (oldArray, newArray) => {
	let array = [];

	oldArray &&
		oldArray.forEach((oldElement) => {
			let flag = true;
			newArray &&
				newArray.forEach((newElement) => {
					if (oldElement === newElement) {
						flag = false;
					}
				});
			if (flag) array.push(oldElement);
		});
	return array;
};

// export const format24to12 = time => {
//   const value = time.split("")[0];
//   const hh = value[0];
//   const mm = value[1];
//   const ss = value[2];

//   if (hh <= 12) {
//     if (hh === 12) {
//       if (mm > 0) {
//         return;
//       }
//     }
//   } else {
//     return `${hh}:${mm} PM`;
//   }
// };

export const PASSWORD = {
	MIN: 6,
	MAX: 12
};

export const LAYOUT = {
	home: 'HomeLayout',
	dashboard: 'DashboardLayout'
};

export const GOOGLE_MAP_KEY = 'AIzaSyDMzZVUTtiaFGtG8OTC9VgkOY8ywaW6A9g';
