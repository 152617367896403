import styled from 'styled-components';

const PageWrapper = styled.div`
	background-color: #ffffff;
	height: 100%;
	color: ${(props) => props.layoutTheme.textColor} !important;
	padding: 0px;
	@media only screen and (min-width: 576px) {
		padding: 16px;
		padding-top: 4px;
	}
	@media only screen and (min-width: 768px) {
		padding: 24px;
		padding-top: 12px;
	}
	.page {
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
		border-radius: 12px;
		padding: 18px 22px;
		height: 100%;
		display: flex;
		flex-direction: column;
		@media only screen and (max-width: 767px) {
			border-radius: 0px;
		}
	}
	.page-header {
		padding-bottom: 24px;
	}
	.page-body {
		flex: 1 1 auto;
	}
	.children-wrapper {
		width: 100%;
		height: 100%;
	}
	@media only screen and (max-width: 767px) {
		padding: 0px;
	}
	.pageButton {
		background-color: #fffdfd !important;
		color: ${(props) => props.layoutTheme.buttonColor2} !important;
	}
	.boxCard {
		border: 1px solid #eaeaea;
		border-radius: 8px;
	}
	.thumb-horizontal {
		cursor: pointer;
		z-index: 3;
		position: relative;
		display: block;
		width: 100%;
		height: 321px;
		transform: translateY(0px);
		cursor: pointer;
		border-radius: inherit;
		background-color: rgba(0, 0, 0, 0.2);
	}
`;
export default PageWrapper;
