import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as yup from 'yup';

import * as validation from '../../../Helper/YupValidation';
import Api from '../../../Helper/Api';
import CustomModal from '../../Common/Modal/CustomModal';
import FormInput from '../../Form/FormInput';
import { AdminApiRoutes, VenueApiRoutes } from '../../../Utils/routes';
import CustomButton from '../../Common/CustomButton';

const ChangePasswordModal = ({ handleModal, isOpen, setUpdateFlag }) => {
	const profileData = useSelector((state) => ({ ...state.auth }));
	const [loading, setLoading] = useState(false);

	const validationSchema = yup.object().shape({
		currentPassword: validation.YUP_VALIDATION.CURRENT_PASSWORD,
		newPassword: validation.YUP_VALIDATION.NEW_PASSWORD,
		confirmPassword: validation.YUP_VALIDATION.RE_ENTER_NEW_PASSWORD
	});

	const submitFormHandler = async () => {
		changePasswordFormik.resetForm();
		const payload = {
			old_password: changePasswordFormik?.values?.currentPassword,
			new_password: changePasswordFormik?.values?.newPassword
		};

		let api =
			profileData.login_type === 'venue'
				? VenueApiRoutes.changePassword
				: AdminApiRoutes.changePassword;
		setLoading(true);
		try {
			const res = await Api('POST', api, payload);
			setLoading(false);
			if (res?.data?.status) {
				toast.success(res?.data?.message);
				changePasswordFormik.resetForm();
				if (setUpdateFlag) setUpdateFlag(true);
				handleModal();
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};

	const changePasswordFormik = useFormik({
		initialValues: {
			currentPassword: '',
			newPassword: '',
			confirmPassword: ''
		},
		validationSchema,
		onSubmit: submitFormHandler
	});

	return (
		<CustomModal
			isOpen={isOpen}
			handleModal={handleModal}
			title="Change Password"
			size="md"
		>
			<form onSubmit={changePasswordFormik.handleSubmit}>
				<div className="pa-b-20">
					<FormInput
						type="password"
						name="currentPassword"
						label="Current Password"
						placeholder="Enter Current Password"
						value={changePasswordFormik?.values?.currentPassword}
						onChange={changePasswordFormik.handleChange}
						onBlur={changePasswordFormik.handleBlur}
						error={
							changePasswordFormik?.touched?.currentPassword &&
							!!changePasswordFormik?.errors?.currentPassword
						}
						errorMsg={changePasswordFormik?.errors?.currentPassword}
					/>
				</div>
				<div className="pa-b-20">
					<FormInput
						type="password"
						name="newPassword"
						label="New Password"
						placeholder="Enter New Password"
						value={changePasswordFormik?.values?.newPassword}
						onChange={changePasswordFormik.handleChange}
						onBlur={changePasswordFormik.handleBlur}
						error={
							changePasswordFormik?.touched?.newPassword &&
							!!changePasswordFormik?.errors?.newPassword
						}
						errorMsg={changePasswordFormik?.errors?.newPassword}
					/>
				</div>
				<div className="pa-b-24">
					<FormInput
						type="password"
						name="confirmPassword"
						label="Re-enter New Password"
						placeholder="Re-enter New Password"
						value={changePasswordFormik?.values?.confirmPassword}
						onChange={changePasswordFormik.handleChange}
						onBlur={changePasswordFormik.handleBlur}
						error={
							changePasswordFormik?.touched?.confirmPassword &&
							!!changePasswordFormik?.errors?.confirmPassword
						}
						errorMsg={changePasswordFormik?.errors?.confirmPassword}
					/>
				</div>
				<CustomButton
					type="submit"
					className="themeButtonFullWidth"
					loading={loading}
				>
					Change Password
				</CustomButton>
			</form>
		</CustomModal>
	);
};

export default ChangePasswordModal;
