export const OpeningHoursModalDetails = [
	{
		id: 1,
		weekDay: 0,
		activeHours: '07:00',
		inActiveHours: '17:00',
		isClosed: false
	},
	{
		id: 2,
		weekDay: 1,
		activeHours: '07:00',
		inActiveHours: '17:00',
		isClosed: false
	},
	{
		id: 3,
		weekDay: 2,
		activeHours: '07:00',
		inActiveHours: '17:00',
		isClosed: false
	},
	{
		id: 4,
		weekDay: 3,
		activeHours: '07:00',
		inActiveHours: '17:00',
		isClosed: false
	},
	{
		id: 5,
		weekDay: 4,
		activeHours: '07:00',
		inActiveHours: '17:00',
		isClosed: false
	},
	{
		id: 6,
		weekDay: 5,
		activeHours: '07:00',
		inActiveHours: '17:00',
		isClosed: false
	},
	{
		id: 7,
		weekDay: 6,
		activeHours: '07:00',
		inActiveHours: '17:00',
		isClosed: false
	}
];
