import React, { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import VerticleBarChart from '../../Common/Charts/VerticleBarChart';
import convertNumToKM from '../../../Helper/convertNumToKM';

const MostOrderedMenuItemsChart = ({ data }) => {
	const isXXL = useMediaQuery({ minWidth: 1400 });
	const isXL = useMediaQuery({ maxWidth: 1399, minWidth: 1200 });
	const _768 = useMediaQuery({ minWidth: 768, maxWidth: 1199 });
	const xAxisLabelFormatter = useCallback(
		(value) => {
			if (isXL) {
				return value?.length > 42
					? `${value.substring(0, 42)}...`
					: value;
			}
			if (_768) {
				return value?.length > 24
					? `${value.substring(0, 24)}...`
					: value;
			}
			// device greater then or equal to 1400px
			return value?.length > 10 ? `${value.substring(0, 10)}...` : value;
		},
		[isXXL, isXL, _768]
	);
	return (
		<VerticleBarChart
			heading={'Most Ordered Menu Items'}
			data={data}
			yAxisWidth={68}
			textAnchor={'middle'}
			dy={12}
			labelFormatterXAxis={(value) => xAxisLabelFormatter(value)}
			allowDecimalsYaxis={false}
			labelFormatterYAxis={(value) => {
				return convertNumToKM(Number(value));
			}}
		/>
	);
};

export default MostOrderedMenuItemsChart;
