import React from 'react';
import { useMediaQuery } from 'react-responsive';

import VerticleBarChart from '../../Common/Charts/VerticleBarChart';
import convertTimeToAmPm from '../../../Helper/convertTimeToAmPm';

const GrossSalesPerHourChart = ({ data }) => {
	const isTab = useMediaQuery({ maxWidth: 768 });
	return (
		<VerticleBarChart
			heading={'Gross Sales Per Hour'}
			data={data}
			yAxisWidth={64}
			xAxisLabelAngle={0}
			textAnchor={'middle'}
			dy={12}
			labelFormatterYAxis={(value) => {
				return '$' + value;
			}}
			tooltipFormatter={(value) => '$' + value}
			tooltipLabelFormatter={(value) => value + ':00'}
			labelFormatterXAxis={(value) => value + ':00'}
		/>
	);
};

export default GrossSalesPerHourChart;
