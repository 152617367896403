import React from 'react';
import PageWrapper from './index.style';
import {
	Card,
	CardBody,
	CardImg,
	CardImgOverlay,
	CardTitle,
	Col,
	Row
} from 'reactstrap';
import { CircleDollarIcon, Customers, Orders } from '../../Icons';
import theGoodFood from '../../../Assets/images/the-good-food.png';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SubImageCard = ({ heading, count, icon, showDollar = false }) => {
	return (
		<div className="d-flex align-items-center medium-text pa-24 subImageCard">
			<div className="subImageCardSkeleton">
				<Skeleton height="100%" width={'100%'} borderRadius={12} />
			</div>
			<div className="pr-24 invisible">
				<div className="circleIcon">{icon}</div>
			</div>
			<div className="invisible">
				<p className="">
					{showDollar && <span className="fs-16 medium-text">$</span>}
					<span className="fs-28 medium-text">{count}</span>
				</p>
				<p className="mt-8 fs-14 medium-text headingTextColor">
					{heading}
				</p>
			</div>
		</div>
	);
};

const StatisticsCardSkeleton = () => {
	return (
		<PageWrapper>
			<Card className="border-radius-16 border-none defaultBoxShadow bannerCard">
				<div className="imgSkeleton">
					<Skeleton
						height="100%"
						width={'100%'}
						borderRadius={'18px 18px 0 0'}
					/>
				</div>
				<CardImg
					alt="Card image cap"
					src={theGoodFood}
					top
					width="100%"
				/>
				<CardImgOverlay className="bannerCardImgOverlay">
					<CardTitle tag="h5" className="fs-18 medium-text">
						The Good Food
					</CardTitle>
				</CardImgOverlay>
				<CardBody>
					<Row className="g-3">
						<Col sm={12} xl={4}>
							<SubImageCard
								count={'742,560.89'}
								heading={'Total Gross Sales'}
								icon={
									<CircleDollarIcon height={30} width={30} />
								}
								showDollar={true}
							/>
						</Col>
						<Col sm={12} xl={4}>
							<SubImageCard
								count={'2,543'}
								heading={'Total Orders'}
								icon={
									<Orders
										height={30}
										width={30}
										fill={'#FF5F5F'}
									/>
								}
							/>
						</Col>
						<Col sm={12} xl={4}>
							<SubImageCard
								count={'2,543'}
								heading={'Total Customers'}
								icon={
									<Customers
										height={30}
										width={30}
										fill={'#FF5F5F'}
									/>
								}
							/>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</PageWrapper>
	);
};

export default StatisticsCardSkeleton;
