export const todoData = [
	{
		id: 1,
		status: false,
		tag: 'info-status-tag',
		priority: 'High',
		task: 'lorem shdshd ahg fjhadg fgadhfg adgfh dgafhg hafafadh...',
		assigned_to: 'Savannah Nguyen',
		due_date: '2022-01-01'
	},
	{
		id: 2,
		status: false,
		tag: 'warning-status-tag',
		priority: 'Medium',
		task: 'lorem shdshd ahg fjhadg fgadhfg adgfh dgafhg hafafadh...',
		assigned_to: 'Ralph Edwards',
		due_date: '2022-01-01'
	},
	{
		id: 3,
		status: false,
		priority: 'Medium',
		tag: 'success-status-tag',
		task: 'lorem shdshd ahg fjhadg fgadhfg adgfh dgafhg hafafadh...',
		assigned_to: 'Savannah Nguyen',
		due_date: '2022-01-01'
	},
	{
		id: 4,
		status: false,
		tag: 'info-status-tag',
		priority: 'Low',
		task: 'lorem shdshd ahg fjhadg fgadhfg adgfh dgafhg hafafadh...',
		assigned_to: 'Jane Cooper',
		due_date: '2022-01-01'
	},
	{
		id: 5,
		status: false,
		priority: 'Medium',
		tag: 'info-status-tag',
		task: 'lorem shdshd ahg fjhadg fgadhfg adgfh dgafhg hafafadh...',
		assigned_to: 'Marvin McKinney',
		due_date: '2022-01-01'
	}
];
