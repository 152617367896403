export const FilterIcon = ({ fill, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 24 18" fill="none">
			<path
				d="M15.1875 0C15.8906 0 16.5 0.609375 16.5 1.3125C16.5 1.64062 16.3594 1.92188 16.1719 2.15625L11.25 7.78125V16.875C11.25 17.5312 10.7344 18 10.0781 18C9.84375 18 9.60938 17.9531 9.375 17.8125L5.8125 15.0938C5.4375 14.8125 5.25 14.3906 5.25 13.875V7.78125L0.28125 2.15625C0.09375 1.92188 0 1.64062 0 1.3125C0 0.609375 0.5625 0 1.26562 0H15.1875ZM6.75 7.5V13.875L9.75 16.1719V7.5C9.75 7.35938 9.79688 7.17188 9.89062 7.03125L14.7188 1.5H1.73438L6.5625 7.03125C6.65625 7.17188 6.70312 7.35938 6.70312 7.5H6.75ZM23.25 15.75C23.625 15.75 24 16.125 24 16.5C24 16.9219 23.625 17.25 23.25 17.25H15.75C15.3281 17.25 15 16.9219 15 16.5C15 16.125 15.3281 15.75 15.75 15.75H23.25ZM15 9C15 8.625 15.3281 8.25 15.75 8.25H23.25C23.625 8.25 24 8.625 24 9C24 9.42188 23.625 9.75 23.25 9.75H15.75C15.3281 9.75 15 9.42188 15 9ZM23.25 0.75C23.625 0.75 24 1.125 24 1.5C24 1.92188 23.625 2.25 23.25 2.25H18.75C18.3281 2.25 18 1.92188 18 1.5C18 1.125 18.3281 0.75 18.75 0.75H23.25Z"
				fill="#FF5F5F"
			/>
		</svg>
	);
};
