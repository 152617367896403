import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomPopover2 from '../Common/Popover/CustomPopover2';
import { ThreeDotIcon } from '../Icons';
import authActions from '../../Redux/auth/actions';

const RequiredOptionsPopover = ({ name, setFieldValue, values, index }) => {
	const authData = useSelector((state) => ({ ...state.auth }));
	const dispatch = useDispatch();
	
		const [localRequiredOptions, setLocalRequiredOptions] = useState(values?.[name]);

		useEffect(() => {
			setLocalRequiredOptions(values?.[name]);
		}, [values, name]);

	return (
		<CustomPopover2
			button={
				<ThreeDotIcon
					height={18}
					width={18}
					fill="#fff"
					className="cursor-pointer"
				/>
			}
			placement="bottom-end"
		>
			<div className="d-flex flex-column pa-12">
				<div
					className="pa-b-12 themeText fs-12 medium-text cursor-pointer"
					onClick={() => {
						dispatch(
							authActions.copy_paste_required_options(
								JSON.parse(JSON.stringify(localRequiredOptions?.[index]?.data)) // Deep copy before dispatching
							)
						);
					}}
				>
					Copy
				</div>
				<div
					className="themeText fs-12 medium-text cursor-pointer"
					// onClick={() => {
					// 	if (authData?.copiedMenuItemOptions !== null) {
					// 		let requiredOptions = [...values?.requiredOptions];

					// 		requiredOptions[index].data = [
					// 			...requiredOptions[index].data,
					// 			...authData?.copiedMenuItemOptions,
					// 		];

					// 		setFieldValue(name, [...requiredOptions]);
					// 	}
					// }}
					onClick={() => {
						if (authData?.copiedMenuItemOptions !== null) {
							const updatedOptions = JSON.parse(JSON.stringify(localRequiredOptions)); // Deep copy local values

							updatedOptions[index].data = [
								...updatedOptions[index].data,
								...authData?.copiedMenuItemOptions.map(option => ({ ...option })) // Deep copy copied options
							];

							setFieldValue(name, updatedOptions); // Update form value
							setLocalRequiredOptions(updatedOptions); // Update local state
						}
					}}
				>
					Paste
				</div>
			</div>
		</CustomPopover2>
	);
};

export default RequiredOptionsPopover;
