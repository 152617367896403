import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';

import PageWrapper from './index.style';
import rightImg from '../../../Assets/images/right.png';
import PageStructure from '../../../Components/Common/PageStructure';
import PosCard from '../../../Components/PremiumFeatures/PosIntegration/PosCard';
import Api from '../../../Helper/Api';
import { VenueApiRoutes } from '../../../Utils/routes';
import authActions from '../../../Redux/auth/actions';
import {
	supportDoshiiEmail,
	supportVenueEmail
} from '../../../Helper/constant';
import ActivateConfirmModal from '../../../Components/PremiumFeatures/PosIntegration/ActivateConfirmModal';
import UnactivateConfirmModal from '../../../Components/PremiumFeatures/PosIntegration/UnactivateConfirmModal';
import MenuSyncConfirmModal from '../../../Components/PremiumFeatures/PosIntegration/MenuSyncConfirmModal';
import CustomButton from '../../../Components/Common/CustomButton';

const FEATURES = [
	"Doshii integrates with the hospitality industry's leading POS systems",
	'Sync your POS menu to MyTab',
	'Centralise your sales analytics',
	'No lock in contract'
];

export const PosIntegration = () => {
	const dispatch = useDispatch();
	const state = useSelector((state) => ({ ...state }));
	const allThemeData = state.themeChanger;
	const authDetails = state.auth;
	const [activateConfirmModal, setActivateConfirmModal] = useState(false);
	const [unactivateConfirmModal, setUnactivateConfirmModal] = useState(false);
	const [enablePosLoading, setEnablePosLoading] = useState(false);
	const [disablePosLoading, setDisablePosLoading] = useState(false);
	const [activateLoading, setActivateLoading] = useState(false);
	const [manualMenuSyncLoading, setManualMenuSyncLoading] = useState(false);
	const [menuSyncConfirmModal, setMenuSyncConfirmModal] = useState(false);

	const handleActivateConfirmModal = () => {
		setActivateConfirmModal((prev) => !prev);
	};
	const handleUnactivateConfirmModal = () => {
		setUnactivateConfirmModal((prev) => !prev);
	};
	const handleEnablePOS = async () => {
		try {
			setEnablePosLoading(true);
			const res = await Api('POST', VenueApiRoutes.enablePOS, {
				bar_id: authDetails?.selectedVenue?.id
			});
			if (res?.data?.status) {
				toast.success(res?.data?.message);
				setActivateConfirmModal(false);
				window.open(res?.data?.data?.link, '_blank', 'noreferrer');
				dispatch(
					authActions.set_pos_feature({
						barId: authDetails?.selectedVenue?.id,
						posStatus: '0'
					})
				);
			} else {
				toast.error(res?.data?.message);
			}
			setEnablePosLoading(false);
		} catch (err) {
			setEnablePosLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	const handleDisablePOS = async () => {
		try {
			setDisablePosLoading(true);
			const res = await Api('POST', VenueApiRoutes.disablePOS, {
				bar_id: authDetails?.selectedVenue?.id
			});
			if (res?.data?.status) {
				setUnactivateConfirmModal(false);
				toast.success(res?.data?.message);
				dispatch(
					authActions.set_pos_feature({
						barId: authDetails?.selectedVenue?.id,
						posStatus: '-1'
					})
				);
			} else {
				toast.error(res?.data?.message);
			}
			setDisablePosLoading(false);
		} catch (err) {
			setDisablePosLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	const handleMenuSyncConfirmModal = () => {
		setMenuSyncConfirmModal((prev) => !prev);
	};
	const handleManualMenuSync = async () => {
		setManualMenuSyncLoading(true);
		toast.warning(
			'Your menu sync is in process. Please do not close this page until this sync is completed.'
		);
		try {
			const res = await Api('POST', VenueApiRoutes.manualMenuSync, {
				bar_id: authDetails?.selectedVenue?.id
			});
			if (res?.data?.status) {
				toast.success(res?.data?.message);
				setMenuSyncConfirmModal(false);
			} else {
				toast.error(res?.data?.message);
			}
			setManualMenuSyncLoading(false);
		} catch (err) {
			setManualMenuSyncLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	useEffect(() => {
		let setIntervalTimer;
		setIntervalTimer = setInterval(() => {
			(async () => {
				const res = await Api(
					'POST',
					VenueApiRoutes.getSingleConnectedVenue,
					{
						bar_id: authDetails?.selectedVenue?.id
					}
				);
				if (res?.status) {
					setActivateLoading(false);
					dispatch(
						authActions.set_pos_feature({
							barId: authDetails?.selectedVenue?.id,
							posStatus: res?.data?.data?.posStatus
								? res?.data?.data?.posStatus
								: '-1',
							posReferralLink: res?.data?.data?.posReferralLink
						})
					);
				}
			})();
		}, 3000);
		return () => {
			clearInterval(setIntervalTimer);
		};
	}, [authDetails?.selectedVenue?.id]);
	return (
		<>
			<PageStructure title="Welcome to MyTab's Premium+ POS Integration Feature">
				<PageWrapper {...allThemeData}>
					{Number(authDetails?.selectedVenue?.posStatus === '1') ? (
						<>
							<p className="fs-12 medium-text text-left pa-b-12">
								Congratulations, the POS Feature has been
								activated.
							</p>
							<p className="fs-12 medium-text text-left pa-b-12">
								Click the button below to log in to your Doshii
								account, where you can update your MyTab menu
								and manage your POS integration.
							</p>
							<p className="fs-12 medium-text text-left pa-b-12">
								For technical assistance and support in regards
								to your Doshii account, please email{' '}
								<span
									className="fs-12 semi-bold-text headingTextColor text-decoration-underline cursor-pointer"
									onClick={() => {
										window.open(
											'mailto:' + supportDoshiiEmail,
											'_blank'
										);
									}}
								>
									{supportDoshiiEmail}
								</span>
								.
							</p>
							<p className="fs-12 medium-text text-left pa-b-12">
								MyTab has partnered with{' '}
								<span
									className="fs-12 semi-bold-text headingTextColor text-decoration-underline cursor-pointer"
									onClick={() => {
										window.open(
											'https://doshii.com/',
											'_blank'
										);
									}}
								>
									Doshii
								</span>{' '}
								to enable seamless POS integration. Send all
								MyTab customer orders directly to your POS to
								reduce double-handling and mistakes. Remove
								counter clutter with one central place to manage
								all of your customer orders. Running a business
								shouldn't be harder than it needs to be. Doshii
								makes managing your venue's technology simple so
								you can focus on doing what you do best.
							</p>
							<p className="fs-12 medium-text text-left pa-b-12">
								Pricing for POS Integration is $30/month +{' '}
								{(authDetails?.selectedVenue?.posFee
									? authDetails?.selectedVenue?.posFee
									: ' 1.5 ') + ' %'}{' '}
								per transaction.
							</p>
							<p className="fs-12 medium-text text-left pa-b-12">
								If you have created your filtered menu for MyTab
								within your Doshii account and would like to
								re-sync your filtered POS menu, please press the{' '}
								<span className="fs-12 semi-bold-text headingTextColor">
									manual menu sync button
								</span>{' '}
								below. Please note, this will update your
								current menu to the filtered menu listed inside
								your Doshii account and any changes or updates
								you have completed inside your MyTab Venue menu
								(images, pick up locations etc) will be removed
								and will need to be updated again after the
								manual sync is complete.
							</p>
							<p className="fs-12 medium-text text-left pa-b-12">
								If you require assistance, please contact us at{' '}
								<span
									className="fs-12 semi-bold-text headingTextColor text-decoration-underline cursor-pointer"
									onClick={() => {
										window.open(
											'mailto:' + supportVenueEmail,
											'_blank'
										);
									}}
								>
									{supportVenueEmail}
								</span>
								.
							</p>
							{/* <div className="pt-32 pb-16">
							{authDetails?.selectedVenue?.docketStatus ? (
								<div className="pa-t-6">
									<CustomButton
										className="no-border d-flex justify-content-center align-items-center themeButton mt-0 fs-18 medium-text plr-40"
										// onClick={() => handleDoshiiStatus()}
										// loading={activateLoading}
										type="button"
									>
										Deactivate Feature
									</CustomButton>
								</div>
							) : (
								''
							)}
						</div> */}
							<div
								className="pa-t-24 d-flex flex-wrap"
								style={{ gap: '12px' }}
							>
								<CustomButton
									className="themeButton"
									onClick={() =>
										setUnactivateConfirmModal(true)
									}
									type="button"
								>
									Deactivate Integration
								</CustomButton>
								<CustomButton
									type="button"
									className="themeButton"
									onClick={() => {
										window.open(
											'https://connect.doshii.com/',
											'_blank'
										);
									}}
								>
									Log in to Doshii
								</CustomButton>
								<CustomButton
									type="button"
									className="themeBorderButton"
									onClick={() => {
										setMenuSyncConfirmModal(true);
									}}
								>
									Manual Menu Sync
								</CustomButton>
							</div>
						</>
					) : (
						<>
							<div>
								<p className="fs-12 medium-text text-left pa-b-12">
									MyTab has partnered with{' '}
									<span
										className="fs-12 semi-bold-text headingTextColor text-decoration-underline cursor-pointer"
										onClick={() => {
											window.open(
												'https://doshii.com/',
												'_blank'
											);
										}}
									>
										Doshii
									</span>{' '}
									to enable seamless POS integration.
								</p>
								<p className="fs-12 medium-text text-left pa-b-12">
									Send all MyTab customer orders directly to
									your POS to reduce double-handling and
									mistakes. Remove counter clutter with one
									central place to manage all of your customer
									orders. Running a business shouldn't be
									harder than it needs to be. Doshii makes
									managing your venue's technology simple so
									you can focus on doing what you do best.
								</p>
								<p className="fs-12 medium-text text-left">
									Pricing for POS Integration is $30/month +{' '}
									{(authDetails?.selectedVenue?.posFee
										? authDetails?.selectedVenue?.posFee
										: ' 1.5 ') + ' %'}{' '}
									per transaction.
								</p>
							</div>
							<div className="horizontalLine mtb-24" />
							<div className="featuresList">
								{FEATURES?.length > 0 &&
									FEATURES?.map((item, index) => {
										return (
											<div
												className={`d-flex align-items-center ${
													index === 0 ? '' : 'pa-t-13'
												}`}
												key={index}
											>
												<div>
													<img
														src={rightImg}
														alt="right_icon_img"
														className="rightImage"
													/>
												</div>
												<span className="pl-9 fs-12 medium-text">
													{item}
												</span>
											</div>
										);
									})}
								{Number(
									authDetails?.selectedVenue?.posStatus ===
										'-1'
								) ? (
									<div className="pa-t-18">
										<CustomButton
											className="themeButton"
											onClick={() =>
												setActivateConfirmModal(true)
											}
											loading={activateLoading}
											type="button"
										>
											Activate Integration
										</CustomButton>
									</div>
								) : (
									<>
										<div className="pa-t-18">
											<CustomButton
												className="themeButton"
												loading={true}
												type="button"
											>
												Pending Approval
											</CustomButton>
										</div>
										<div className="pendingText fs-11 medium-text text-left pa-t-18">
											If you did not receive an email from
											Doshii (please check your junk
											folder) you can click{' '}
											<span
												className="fs-11 semi-bold-text headingTextColor text-decoration-underline cursor-pointer"
												onClick={() => {
													window.open(
														authDetails
															?.selectedVenue
															?.posReferralLink
															? authDetails
																	?.selectedVenue
																	?.posReferralLink
															: 'https://connect.doshii.com/',
														'_blank',
														'noreferrer'
													);
												}}
											>
												here
											</span>{' '}
											to complete your POS Integration
											request.
										</div>
									</>
								)}
							</div>
						</>
					)}
					<div className="horizontalLine mtb-24" />
					<div className="">
						<PosCard />
					</div>
				</PageWrapper>
			</PageStructure>
			<MenuSyncConfirmModal
				isOpen={menuSyncConfirmModal}
				handleModal={handleMenuSyncConfirmModal}
				handleManualMenuSync={handleManualMenuSync}
				manualMenuSyncLoading={manualMenuSyncLoading}
			/>
			<ActivateConfirmModal
				isOpen={activateConfirmModal}
				handleModal={handleActivateConfirmModal}
				enablePosLoading={enablePosLoading}
				handleEnablePOS={handleEnablePOS}
			/>
			<UnactivateConfirmModal
				isOpen={unactivateConfirmModal}
				handleModal={handleUnactivateConfirmModal}
				disablePosLoading={disablePosLoading}
				handleDisablePOS={handleDisablePOS}
			/>
		</>
	);
};
