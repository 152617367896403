import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
	apiKey: 'AIzaSyA0pAcYAi6LXpWVqKDViNyAOd7sAjtKX0E',
	authDomain: 'my-tab-eceb7.firebaseapp.com',
	databaseURL: 'https://my-tab-eceb7.firebaseio.com',
	projectId: 'my-tab-eceb7',
	storageBucket: 'my-tab-eceb7.appspot.com',
	messagingSenderId: '621309588716',
	appId: '1:621309588716:web:819d73198c56f0240821c9',
	measurementId: 'G-4E39H87JDM'
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
