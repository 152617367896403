import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Col, Row } from 'reactstrap';
import FormInput from '../../Form/FormInput';
import { CircleMinusIcon, HamburgerIcon } from '../../Icons';
import { useEffect, useState } from 'react';
import FormInputGroup from '../../Form/FormInputGroup';

const OptionInput = (props) => {
	const {
		isDragging,
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition
	} = useSortable({ id: props?.id });
	const style = {
		transform: CSS.Transform.toString(transform),
		transition: transition || undefined
	};
	const [itemName, setItemName] = useState('');
	const [price, setPrice] = useState(null);
	useEffect(() => {
		if (props?.values?.[props.name]?.[props.index]) {
			setItemName(props.values[props.name][props.index]?.itemName);
			setPrice(props.values[props.name][props.index]?.price);
		}
	}, [
		props.values,
		props?.values?.[props.name]?.length,
		props.values[props.name][props.index]?.itemName,
		props.values[props.name][props.index]?.price
	]);
	return (
		<div
			className="d-flex gap-4 align-items-center bg-white pa-b-14"
			ref={setNodeRef}
			style={style}
		>
			<Row className="flex-15" style={{ '--bs-gutter-x': '18px' }}>
				<Col xs={6}>
					<FormInput
						type="text"
						name={`${props.name}.${props.index}.itemName`}
						label="Extra Item"
						placeholder="Enter Extra Item"
						value={props.values[props.name][props.index]?.itemName}
						onChange={props.handleChange}
						error={
							props.touched?.[props.name]?.[props.index]
								?.itemName &&
							!!props.errors?.[props.name]?.[props.index]
								?.itemName
						}
						errorMsg={
							props.errors?.[props.name]?.[props.index]?.itemName
						}
						disabled={props.posStatus}
					/>
				</Col>
				<Col xs={6}>
					<FormInputGroup
						type="number"
						name={`${props.name}.${props.index}.price`}
						label="Price"
						placeholder="Enter Price"
						value={props.values[props.name][props.index]?.price}
						onChange={props.handleChange}
						error={
							props.touched?.[props.name]?.[props.index]?.price &&
							!!props.errors?.[props.name]?.[props.index]?.price
						}
						errorMsg={
							props.errors?.[props.name]?.[props.index]?.price
						}
						icon={<span className="fs-14">$</span>}
						iconPlacement="start"
						className={'pl-0'}
						iconBackgroundClass={'inputGroupBackground'}
						onBlur={() => {
							props.setFieldValue(
								`${props.name}.${props.index}.price`,
								Number(
									props.values[props.name][props.index]?.price
								).toFixed(2)
							);
						}}
						onWheel={(e) => e.target.blur()}
						disabled={props.posStatus}
					/>
				</Col>
			</Row>
			<div className="flex-1 d-flex align-items-center justify-content-center">
				<CircleMinusIcon
					height={14}
					width={14}
					fill={'#F94D73'}
					className="mt-28 cursor-pointer"
					onClick={() => {
						props.handleDeletedAdditionalExtras(
							props.values[props.name][props.index]?.itemId
						);
						let items = props.values[props.name];
						items.splice(props.index, 1);
						props.setFieldValue(props.name, [...items]);
					}}
				/>
			</div>
			<div className="flex-1 d-flex align-items-center justify-content-center">
				<HamburgerIcon
					height={18}
					width={18}
					fill={'#BABABA'}
					className="mt-28 cursor-pointer outline-none"
					{...attributes}
					{...listeners}
					style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
				/>
			</div>
		</div>
	);
};

export default OptionInput;
