import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { deviceDetect, isMobile } from 'react-device-detect';
import axios from 'axios';
import * as yup from 'yup';

import * as validation from '../../Helper/YupValidation';
import Api from '../../Helper/Api';

import { useNavigate } from 'react-router-dom';
import authActions from '../../Redux/auth/actions';
import {
	AdminApiRoutes,
	AdminPanelRoutes,
	VenueApiRoutes,
	VenuePanelRoutes,
	VenueProtectedRoutes
} from '../../Utils/routes';
import VenueAccountVerificationModal from '../UserProfile/ConnectVenue/VenueAccountVerificationModal';
import CustomButton from '../Common/CustomButton';
import { somethingWentWrongError } from '../../Helper/somethingWentWrongError';
import NewFormInput from '../Form/NewFormInput';
import NewCustomModal from '../Common/Modal/NewCustomModal';
import NewVerifyModalWrapper from './newVerifyOtp.style';

const VerifyOtpModal = ({
	isOpen,
	handleModal,
	type,
	modalData,
	modalFor,
	closeHandler
}) => {
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [autoFocus, setAutoFocus] = useState(false);
	const [otp, setOtp] = useState('');
	const [userLocationData, setUserLocationData] = useState({});
	const [isOpenAccountVerificationModal, setIsOpenAccountVerificationModal] =
		useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (isOpen) {
			setTimeout(() => {
				const inputElement = document.getElementById('otp-input');
				if (inputElement) {
					inputElement.focus();
				}
			}, 100);
		}
	}, [isOpen]);

	const handleAccountVerificationModal = () => {
		setIsOpenAccountVerificationModal(!isOpenAccountVerificationModal);
	};

	const handleNewOTPChange = (event) => {
		setOtp(event.target.value);
		return verifyOtpFormik.setFieldValue('otp', event.target.value);
	};

	const handleOTPChange = (otp) => {
		setOtp(otp);
		verifyOtpFormik.setFieldValue('otp', otp);
	};

	const validationSchema = yup.object().shape({
		otp: validation.YUP_VALIDATION.OTP
	});

	useEffect(() => {
		(async () => {
			try {
				const response = axios.get('https://ipapi.co/json/');
				let data = response?.data;
				setUserLocationData(data);
			} catch (err) {
				console.log(err);
			}
		})();
	}, []);
	const handleSetupAuthenticator = async () => {
		try {
			const res = await Api(
				'POST',
				VenueApiRoutes.setUpGoogleAuthenticator,
				{ email: modalData.email }
			);
			toast.success(res?.data?.message);
		} catch (err) {
			somethingWentWrongError();
		}
	};
	// const logindispatch(authActions.venue_login(res.data.data));
	const handelOnsubmit = async () => {
		let deviceData = deviceDetect();

		const payloadVenue = {
			user_id: modalData.admin_id,
			email: modalData.email,
			code: verifyOtpFormik.values.otp,
			device_type: 'web',
			device_token: '23',
			device_name: isMobile
				? (deviceData?.model ?? '') +
				  ' ' +
				  (deviceData?.os ?? '') +
				  ' ' +
				  (deviceData?.osVersion ?? '')
				: (deviceData?.browserName ?? '') +
				  ' ' +
				  (deviceData?.osName ?? '') +
				  ' ' +
				  (deviceData?.osVersion ?? ''),
			device_location:
				(userLocationData?.city ? userLocationData?.city + ',' : '') +
				' ' +
				(userLocationData?.region
					? userLocationData?.region + ','
					: '') +
				' ' +
				(userLocationData?.country_name
					? userLocationData?.country_name
					: '')
		};
		const payloadAdmin = {
			admin_id: modalData.admin_id,
			email: modalData.email,
			code: verifyOtpFormik.values.otp,
			device_type: !isMobile ? '1' : '2',
			device_token: null,
			device_name: isMobile
				? (deviceData?.model ?? '') +
				  ' ' +
				  (deviceData?.os ?? '') +
				  ' ' +
				  (deviceData?.osVersion ?? '')
				: (deviceData?.browserName ?? '') +
				  ' ' +
				  (deviceData?.osName ?? '') +
				  ' ' +
				  (deviceData?.osVersion ?? ''),
			device_location:
				(userLocationData?.city ? userLocationData?.city + ',' : '') +
				' ' +
				(userLocationData?.region
					? userLocationData?.region + ','
					: '') +
				' ' +
				(userLocationData?.country_name
					? userLocationData?.country_name
					: '')
		};

		let api =
			type === 'venue'
				? VenueApiRoutes.verifyOtp
				: AdminApiRoutes.verifyOtp;
		let payload = type === 'venue' ? payloadVenue : payloadAdmin;
		setLoading(true);
		try {
			const res = await Api('POST', api, payload);
			setLoading(false);
			if (res?.data?.status) {
				verifyOtpFormik.resetForm();
				dispatch(authActions.venue_login(res?.data?.data));
				if (type === 'venue') {
					// if (res?.data?.data?.subscriptions?.length === 0) {
					// 	handleModal();
					// 	navigate(VenuePanelRoutes.registerSubscription);
					// }
					if (res?.data?.data?.bars?.length === 0) {
						handleModal();
						dispatch(authActions.prevenue_process(true));
						navigate(VenueProtectedRoutes.connectVenue, {
							state: { authData: res?.data?.data }
						});
					} else {
						handleModal();
						// dispatch(authActions.venue_login(res?.data?.data));
						dispatch(authActions.venue_change_login(true));
						navigate(VenuePanelRoutes.home);
					}
				} else {
					navigate(AdminPanelRoutes.live);
				}
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setLoading(false);
			somethingWentWrongError();
		}
	};
	const verifyOtpFormik = useFormik({
		initialValues: {
			admin_id: modalData?.admin_id,
			email: modalData?.email,
			otp: '',
			type: type === 'venue' ? 'venue' : 'admin'
		},
		validationSchema,
		validateOnChange: true,
		validateOnBlur: false,
		onSubmit: handelOnsubmit
	});
	useEffect(() => {
		setAutoFocus(true);
	}, []);
	return (
		<>
			<NewCustomModal
				onClosed={closeHandler}
				isOpen={isOpen}
				size="md"
				handleModal={handleModal}
				modalClassName="verifyOtp"
			>
				<NewVerifyModalWrapper>
					{/* <ModalWrapper {...allThemeData}> */}
					{/* <div className="pa-b-30 d-flex justify-content-center">
						<img
							src={passwordImage}
							alt="password-icon"
							className="phoneImage"
						/>
					</div>*/}
					<p className="verifyOtpHeading">
						Your security is our priority
					</p>
					<p className="verifyOtpSubText">
						Please enter the 6 digit verification code on your{' '}
						<span className="verifyOtpSubText-auth">
							authenticator app
						</span>
					</p>
					<form
						// className="otpForm text-center"
						onSubmit={verifyOtpFormik.handleSubmit}
					>
						<NewFormInput
							id="otp-input"
							name="otp"
							placeholder="Verification code"
							type="text"
							maxlength="6"
							onBlur={verifyOtpFormik.handleBlur}
							value={verifyOtpFormik?.values?.otp}
							onChange={verifyOtpFormik?.handleChange}
							error={verifyOtpFormik?.errors?.otp}
							errorMsg={
								verifyOtpFormik?.touched?.otp &&
								verifyOtpFormik?.errors?.otp
							}
						/>
						{/* <OtpInput
								value={verifyOtpFormik?.values?.otp}
								onChange={handleOTPChange}
								numInputs={6}
								containerStyle={{
									justifyContent: 'center'
								}}
								shouldAutoFocus={true}
							/>
							{verifyOtpFormik?.errors?.otp && (
								<p className="fs-10 semi-bold-text headingTextColor pa-t-6">
									{verifyOtpFormik?.errors?.otp}
								</p>
							)} */}
						<div className="pt-24">
							<CustomButton
								type="submit"
								className="newThemeButtonFullWidth"
								loading={loading}
							>
								Verify
							</CustomButton>
						</div>

						<p className="helperText">Having trouble?</p>
						<p className="helperText mt-0">
							Click{' '}
							<span
								className="spanLink"
								onClick={handleSetupAuthenticator}
							>
								here
							</span>{' '}
							and we will send you an mail to reset your 2 factor
							authentication.
						</p>
					</form>
				</NewVerifyModalWrapper>

				{/* </ModalWrapper> */}
			</NewCustomModal>
			<VenueAccountVerificationModal
				isOpen={isOpenAccountVerificationModal}
				handleModal={handleAccountVerificationModal}
				navigateTo={() => navigate(VenuePanelRoutes.dashboard)}
			/>
		</>
	);
};

export default VerifyOtpModal;
