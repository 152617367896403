import styled from 'styled-components';

export const TabsWrapper = styled.div`
	.customScrollableNavbar {
		overflow-x: scroll;
		scroll-behavior: smooth;
		margin: 0px;
		padding: 0px;
		&::-webkit-scrollbar {
			background: transparent; /* make scrollbar transparent */
			-webkit-appearance: none;
			width: 0;
			height: 0;
		}
		.rts___tabs {
			padding: 0px !important;
		}
		.rts___tabs___container {
			padding: 0px !important;
		}
		button {
			border: none;
			outline: none;
			background-color: #fff;
			box-shadow: none;
			border-radius: 0px !important;
			margin: 0;
			&:disabled {
				i {
					display: none;
				}
			}
		}
		.rts___left___nav___btn,
		.rts___right___nav___btn {
			border-bottom: 2px solid #ededed;
			max-width: 100%;
			padding: 12px 10px;
			border-radius: 0px !important;
			display: flex !important;
		}
		.rts___right___nav___btn {
			margin-right: 30px;
		}
		.rts___nav___btn___container {
			display: flex !important;
		}
		.customScrollableNavItem {
			color: #bababa;
			padding: 0px 15px 8px;
			white-space: nowrap;
			border-bottom: 2px solid #ededed;
			z-index: 2;
			cursor: pointer;
			position: relative;
			&.active {
				color: ${(props) => props.layoutTheme.textColor};
			}
			&.active::after {
				content: '';
				display: block;
				width: 100%;
				height: 2px;
				background-color: #ff5f5f;
				border-radius: 90px;
				position: absolute;
				top: 100%;
				left: 0;
				right: 0;
			}
		}
		.borderBottom {
			border-bottom: 2px solid #ededed;
		}
	}
	.rearrangeIcon {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		display: flex;
		gap: 18px;
		align-items: center;
	}
`;

export const PageWrapper = styled.div`
	color: ${(props) => props.layoutTheme.textColor} !important;
	height: 100%;
	.pageButton {
		background-color: #fffdfd !important;
		color: ${(props) => props.layoutTheme.buttonColor2} !important;
	}
	.min-height-100 {
		min-height: 100%;
	}
	.rounded {
		border-radius: 50% !important;
	}
	.right-0 {
		right: 0px;
	}
	.bank-card {
		border: 1px solid #eaeaea;
		border-radius: 8px;
		.city {
			color: #bababa;
		}
	}
	.permissionCard {
		min-height: 100px;
		border: 1px solid #eaeaea;
		border-radius: 8px;
		margin-bottom: 20px;
	}
	.staticText {
		p {
			font-size: 16px;
			font-family: 'montserrat-medium';
		}
	}
	.customSelect2__dropdown-indicator {
		flex: 1;
	}
	.orderCard {
		border: 1px solid #eaeaea;
		border-radius: 8px;
		margin: 15px 0px;
	}
	.themeHr {
		margin: 0px !important;
		border: 1px solid #eaeaea;
	}
	.dashboard-dropdown {
		.activeColor {
			color: ${(props) => props.layoutTheme.buttonColor3} !important;
		}
		.dropdown-item,
		.dropdownToggle {
			outline: 0 !important;
			border: 0px !important;
			padding-left: 10px;
			padding-right: 10px;
			padding-top: 10px;
			&.active {
				background-color: ${(props) =>
					props.layoutTheme.buttonColor3} !important;
			}
			@media only screen and (max-width: 575.98px) {
				padding-top: 5px;
				margin-top: 6px;
				margin-bottom: 6px;
			}
			.dropdown-name {
				padding-right: 5px;
				color: ${(props) => props.layoutTheme.textColor};
			}
			&:hover {
				outline: 0 !important;
				border: 0px !important;
			}
			&:active {
				outline: 0 !important;
				border: 0px !important;
				background-color: #fff !important;
				color: ${(props) => props.layoutTheme.textColor};
			}
		}
		.dropdown-menu {
			border: 0px !important;
			@media only screen and (max-width: 360px) {
			}
		}
	}
	.dateDropdownBox {
		background: #f9f9f9;
		border-radius: 8px;
		min-height: 52px;
	}
	.orderTypeDropdownBox {
		background: transparent;
		border: 1px solid #f94d73;
		border-radius: 8px;
		min-height: 52px;
	}
	.dropdown-item.dropdownCalender:hover {
		background-color: #fff;
	}
	.dropdownCalenderParent {
		border-right: 1px solid #eaeaea;
		margin-right: 10px;
		padding-right: 15px;
		@media only screen and (max-width: 575px) {
			border-right: 0px;
			margin-right: 0px;
			padding-right: 0px;
		}
	}
	.datePickerDropdown {
		max-height: 300px;
		overflow: auto;
		margin-top: 15px;
	}
	.openingHrBtn {
		width: 300px !important;
		height: 60px !important;
		@media only screen and (max-width: 1600px) {
			width: 198px !important;
			height: 45px !important;
			font-size: 14px !important;
		}
	}
`;
