import styled from 'styled-components';
import sectionImage from '../../Assets/images/section-common-back.png';

const PageWrapper = styled.div`
	width: 100%;
	height: 100%;
	/* new design styles */
	display: flex;
	.formWrapper {
		flex: 1;
		background-color: #fff;
		color: #313132;
	}
	.imageWrapper {
		flex: 1;
		display: flex;

		background: #fff !important;

		.text {
			max-width: 639px;
			font-family: 'montserrat-bold';
			font-size: 55px;
			color: #fff;
			text-align: center;
		}
		.image {
			width: 558px;
			height: 528px;
		}
	}

	.backWrraper {
		height: 100%;
		width: 100%;
		display: flex;
		gap: 29px;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background: url(${sectionImage});

		background-size: 100% 100% !important;
		background-repeat: no-repeat;
	}
	.highlightColor {
		color: #f95c69;
	}

	@media (max-width: 600px) {
		.imageWrapper {
			display: none;
		}
		.formWrapper {
			padding: 0 20px !important;
		}
	}

	@media only screen and (min-width: 600px) and (max-width: 960px) {
		.imageWrapper {
			display: none;
		}
		.formWrapper {
			padding: 0 50px !important;
		}
	}

	@media screen and (max-height: 933px) {
		.imageWrapper {
			.text {
				font-size: 30.9px;
				max-width: 359.4px;
			}
			.image {
				width: 313.8px;
				height: 297px;
			}
		}
	}

	@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 800px) {
		.imageWrapper {
			.text {
				font-size: 30.9px;
				max-width: 359.4px;
			}
			.image {
				width: 313.8px;
				height: 297px;
			}
		}
	}

	/* .pageContainer {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 71px;
	}
	.imageContainer {
		display: block;
		.pageImageWrapper {
			width: 493px;
			height: auto;
			.pageImage {
				width: 100%;
				height: 100%;
				display: block;
			}
		}
		@media only screen and (max-width: 1199px) {
			display: none !important;
		}
	}
	.formCardContainer {
		padding: 4px;
		@media only screen and (max-width: 556px) {
			padding: 12px;
		}
		.formCard {
			width: 516px !important;
			background: #ffffff;
			box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
			border-radius: 12px;
			padding: 30px 58px;
			.cmsLogoWrapper {
				width: 130px;
				.cmsLogo {
					width: 100%;
					height: 100%;
					display: block;
				}
			}
			.loginRoleGroup {
				background: #ffefef;
				height: 38px;
				border-radius: 19px;
				cursor: pointer;
				display: flex;
				align-items: center;
				.loginRole {
					flex: 1;
					text-align: center;
				}
				position: relative;
				.active {
					color: #ffffff;
					transition: all 500ms ease-in-out;
					z-index: 2;
				}
			}
			.activeBackground {
				position: absolute;
				background: #ff6460;
				border-radius: 19px;
				width: 50%;
				height: 32px;
				margin-left: 3px;
				z-index: 1;
				transform: translateX(0%);
				transition: all 500ms ease-in-out;
				&.active {
					transform: translateX(100%);
					margin-left: -0px;
					margin-left: -3px;
				}
			}
			@media only screen and (max-width: 556px) {
				width: 100% !important;
				padding: 30px 28px;
			}
		}
		.horizontalRule {
			border: 1px solid #eaeaea;
		}
	} */
`;

export default PageWrapper;
