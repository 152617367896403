import { forwardRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';

import { TrashBinIcon, VerticalSixDotIcon } from '../../Icons';
import { VenueApiRoutes, VenuePanelRoutes } from '../../../Utils/routes';
import Switch from '../../Common/Switch';
import Api from '../../../Helper/Api';
import ItemCard from './index.style';
import DeleteConfirmModal from '../DeleteConfirmModal';
import uiActions from '../../../Redux/ui/actions';

const Item = forwardRef(
	(
		{
			id,
			withOpacity,
			isDragging,
			style,
			attributes,
			listeners,
			menuItemId,
			data,
			isPopularExist,
			refetch,
			isEditFromVenueSetup = false,
			forVenuSetup,
			...props
		},
		ref
	) => {
		const authData = useSelector((state) => ({ ...state.auth }));
		const dispatch = useDispatch();
		const navigate = useNavigate();
		const [switchLoading, setSwitchLoading] = useState(false);
		const [deleteProductLoading, setDeleteProductLoading] = useState(false);
		const [switchStatus, setSwitchStatus] = useState(false);
		const [itemData, setItemData] = useState(null);
		const [deleteModal, setDeleteModal] = useState(false);
		const [avatar, setAvatar] = useState(null);
		const handleDeleteModal = () => {
			setDeleteModal((prev) => !prev);
		};
		const handleSwitchChange = async () => {
			dispatch(uiActions?.page_loading(true));
			setSwitchLoading(true);
			try {
				setSwitchStatus(itemData?.status === 'Active' ? false : true);
				const res = await Api(
					'PUT',
					VenueApiRoutes.changeProductStatus,
					{
						status:
							itemData?.status === 'Active'
								? 'Inactive'
								: 'Active',
						id: itemData?.id,
						bar_id: authData?.selectedVenue?.id
					}
				);
				if (res?.data?.status) {
					if (refetch) refetch();
				} else {
					setSwitchStatus(
						itemData?.status === 'Active' ? true : false
					);
					toast.error(res?.data?.message);
				}
				setSwitchLoading(false);
				dispatch(uiActions?.page_loading(false));
			} catch (err) {
				setSwitchStatus(itemData?.status === 'Active' ? true : false);
				setSwitchLoading(false);
				dispatch(uiActions?.page_loading(false));
				if (err?.message) {
					toast.error(err?.message);
				}
			}
		};
		const handleDeleteProduct = async () => {
			setDeleteProductLoading(true);
			dispatch(uiActions?.page_loading(true));
			try {
				const res = await Api('DELETE', VenueApiRoutes.deleteProduct, {
					sub_category_id: itemData?.subCategoryID,
					id: itemData?.id,
					bar_id: authData?.selectedVenue?.id
				});
				if (res?.data?.status) {
					setDeleteModal(false);
					if (refetch) refetch();
					toast.success(res?.data?.message);
				} else {
					toast.error(res?.data?.message);
				}
				setDeleteProductLoading(false);
				dispatch(uiActions?.page_loading(false));
			} catch (err) {
				setDeleteProductLoading(false);
				dispatch(uiActions?.page_loading(false));
				if (err?.message) {
					toast.error(err?.message);
				}
			}
		};
		useEffect(() => {
			if (data?.avatar) {
				setAvatar(data?.avatar);
			} else {
				setAvatar(authData?.selectedVenue?.avatar);
			}
			setItemData(data);
			setSwitchStatus(data?.status === 'Active' ? true : false);
		}, [data]);

		return (
			<ItemCard
				ref={ref}
				style={{ ...style }}
				onClick={() => {
					isEditFromVenueSetup === true
						? forVenuSetup()
						: navigate(
								VenuePanelRoutes.editManageMenu +
									`/${itemData?.id}`
						  );
				}}
				withOpacity={withOpacity}
				isDragging={isDragging}
				isPopularExist={isPopularExist}
				menuItemId={menuItemId}
				{...props}
			>
				{isPopularExist && menuItemId !== 0 && (
					<div
						className="dragIconWrapper"
						{...attributes}
						{...listeners}
						style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
					>
						<VerticalSixDotIcon height={24} width={24} />
					</div>
				)}
				{!isPopularExist && (
					<div
						className="dragIconWrapper"
						{...attributes}
						{...listeners}
						style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
					>
						<VerticalSixDotIcon height={24} width={24} />
					</div>
				)}
				<div className="itemImageWrapper">
					<img
						src={
							avatar
								? avatar
								: 'https://via.placeholder.com/100x100?text=IMAGE'
						}
						alt="item-img"
					/>
				</div>
				<div className="itemBody">
					<div>
						<div className="d-flex justify-content-between align-items-center gap-3">
							<p className="fs-14 medium-text itemTitle">
								{itemData?.name}
							</p>
							{isPopularExist && menuItemId !== 0 && (
								<span>
									<TrashBinIcon
										height={18}
										width={18}
										className="cursor-pointer zIndex-2"
										onClick={(event) => {
											event.stopPropagation();
											setDeleteModal(true);
										}}
									/>
								</span>
							)}
							{!isPopularExist && (
								<span>
									<TrashBinIcon
										height={18}
										width={18}
										className="cursor-pointer zIndex-2"
										onClick={(event) => {
											event.stopPropagation();
											setDeleteModal(true);
										}}
									/>
								</span>
							)}
						</div>
						<div className="itemDescription fs-12 regular-text pa-t-4">
							{itemData?.description}
						</div>
					</div>
					<div className="d-flex align-items-center justify-content-between gap-1">
						<p className="fs-14 medium-text">${itemData?.price}</p>
						{isPopularExist && menuItemId !== 0 && (
							<Switch
								checked={switchStatus}
								onChange={() => {
									handleSwitchChange();
								}}
								onClick={(event) => event.stopPropagation()}
								disabled={switchLoading}
							/>
						)}
						{!isPopularExist && (
							<Switch
								checked={switchStatus}
								onChange={() => {
									handleSwitchChange();
								}}
								onClick={(event) => event.stopPropagation()}
								disabled={switchLoading}
							/>
						)}
					</div>
				</div>
				<DeleteConfirmModal
					isOpen={deleteModal}
					handleModal={handleDeleteModal}
					handleDeleteProduct={handleDeleteProduct}
					loading={deleteProductLoading}
				/>
			</ItemCard>
		);
	}
);

export default Item;
