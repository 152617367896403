import { useState } from 'react';
import { useFormik } from 'formik';
import { Row, Col, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import * as validation from '../../../Helper/YupValidation';
import defaultProfilePic from '../../../Assets/images/phone2.png';
import FormRadioButton from '../../Form/FormRadioButton';
import { EditIcon, PlusIcon } from '../../Icons';
import FormWrapper from './venueAccountForm.style';
import FormCheckBox from '../../Form/FormCheckBox';
import OpeningHoursModal from './OpeningHoursModal';
import ImageUpload from '../../Form/ImageUpload';
import { OpeningHoursModalDetails } from './utils';
import FormMobileNoInput from '../../Form/FormMobileNoInput';
import { VenueApiRoutes, VenuePanelRoutes } from '../../../Utils/routes';
import Api from '../../../Helper/Api';
import CustomButton from '../../Common/CustomButton';
import MapModal from './MapModal';
import FormInput from '../../Form/FormInput';

const VenueAccountForm = () => {
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	const navigate = useNavigate();
	const [openingHoursModalData, setOpeningHoursModalData] = useState(
		OpeningHoursModalDetails
	);
	const [isOpenMapModal, setIsOpenMapModal] = useState(false);
	const [isAlcohol, setIsAlcohol] = useState('No');
	const [isOpenOpeningHoursModal, setIsOpenOpeningHoursModal] =
		useState(false);
	const [loading, setLoading] = useState(false);

	const validationSchema = yup.object().shape({
		image: validation.YUP_VALIDATION.IMAGE,
		venueName: validation.YUP_VALIDATION.VENUE_NAME,
		ownerManagerName: validation.YUP_VALIDATION.OWNER_MANAGER_NAME,
		email: validation.YUP_VALIDATION.EMAIL,
		password: validation.YUP_VALIDATION.PASSWORD,
		// countryCode: validation.YUP_VALIDATION.COUNTRY_CODE,
		mobileNumber: validation.YUP_VALIDATION.MOBILE_NUMBER,
		address: validation.YUP_VALIDATION.ADDRESS,
		abnAcn: validation.YUP_VALIDATION.ABN_ACN_NUMBER,
		isAlcohol: validation.YUP_VALIDATION.IS_ALCOHOL,
		licenseNumber:
			isAlcohol === 'Yes' && validation.YUP_VALIDATION.LICENSE_NUMBER,
		serviceType: validation.YUP_VALIDATION.SERVICE_TYPE
	});
	const handleOpeningHoursModal = () => {
		setIsOpenOpeningHoursModal((prev) => !prev);
	};
	const handleMapModal = () => {
		setIsOpenMapModal((prev) => !prev);
	};
	const handleOpeningHoursModalData = (data) => {
		setOpeningHoursModalData(data);
	};
	const submitFormHandler = async (values) => {
		const payload = {
			venue_name: values?.venueName,
			manager_name: values?.ownerManagerName,
			email: values?.email,
			venue_address: values?.address,
			country_code: values?.countryCode,
			mobile: values?.mobileNumber,
			business_register_id: values?.abnAcn,
			is_venue_serve_alcohol: values?.isAlcohol,
			liquor_license_number: values?.licenseNumber,
			image: values?.image,
			password: values?.password,
			service_type:
				values?.serviceType?.length === 2
					? ['BOTH']
					: values?.serviceType,
			latitude: values?.latitude,
			longitude: values?.longitude
		};
		if (isAlcohol === 'No') {
			delete payload.liquor_license_number;
		}
		let formData = new FormData();
		for (let key in payload) {
			formData.append(key, payload[key]);
		}
		for (let i = 0; i < openingHoursModalData?.length; i++) {
			formData.append(
				`operating_hours[${i}][week_day]`,
				openingHoursModalData[i]?.weekDay
			);
			formData.append(
				`operating_hours[${i}][opening_hours]`,
				openingHoursModalData[i]?.activeHours
			);
			formData.append(
				`operating_hours[${i}][closing_hours]`,
				openingHoursModalData[i]?.inActiveHours
			);
			formData.append(
				`operating_hours[${i}][is_closed]`,
				openingHoursModalData[i]?.isClosed
			);
		}
		try {
			setLoading(true);
			const res = await Api('POST', VenueApiRoutes.createVenue, formData);
			setLoading(false);
			if (res?.data?.status) {
				toast.success(res?.data?.message);
				resetForm();
				navigate(VenuePanelRoutes.connectVenue);
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};
	const {
		handleChange,
		handleBlur,
		setFieldValue,
		handleSubmit,
		values,
		touched,
		errors,
		setFieldTouched,
		resetForm,
		setValues
	} = useFormik({
		initialValues: {
			image: null,
			venueName: '',
			ownerManagerName: '',
			email: '',
			password: '',
			countryCode: '',
			mobileNumber: '',
			address: '',
			abnAcn: '',
			isAlcohol: 'No',
			licenseNumber: '',
			serviceType: [],
			latitude: '',
			longitude: ''
		},
		validationSchema,
		onSubmit: submitFormHandler
	});
	return (
		<>
			<FormWrapper {...allThemeData}>
				<form
					onSubmit={handleSubmit}
					className="overflow-hidden"
					autoComplete="off"
				>
					<Row>
						<Col sm={12} className="pa-b-20">
							<ImageUpload
								name={'image'}
								defaultImage={defaultProfilePic}
								icon={
									<EditIcon
										height={14}
										weight={14}
										stroke="#fff"
									/>
								}
								value={values?.image}
								setFieldValue={setFieldValue}
								error={touched?.image && !!errors?.image}
								errorMsg={errors?.image}
							/>
						</Col>
					</Row>
					<Row style={{ '--bs-gutter-x': '18px' }}>
						<Col lg={6} className="pa-b-20">
							<FormInput
								type="text"
								name="venueName"
								label="Venue Name"
								placeholder="Enter Venue Name"
								value={values?.venueName}
								onChange={handleChange}
								onBlur={handleBlur}
								error={
									touched?.venueName && !!errors?.venueName
								}
								errorMsg={errors?.venueName}
							/>
						</Col>
						<Col lg={6} className="pa-b-20">
							<FormInput
								type="text"
								name="ownerManagerName"
								label="Owner/Manager Name"
								placeholder="Enter Owner/Manager Name"
								value={values?.ownerManagerName}
								onChange={handleChange}
								onBlur={handleBlur}
								error={
									touched?.ownerManagerName &&
									!!errors?.ownerManagerName
								}
								errorMsg={errors?.ownerManagerName}
							/>
						</Col>
					</Row>
					<Row style={{ '--bs-gutter-x': '18px' }}>
						<Col lg={6} className="pa-b-20">
							<FormInput
								showRequired
								type="email"
								name="email"
								label="Email Address"
								placeholder="Enter Email Address"
								value={values?.email}
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched?.email && !!errors?.email}
								errorMsg={errors?.email}
							/>
						</Col>
						<Col lg={6} className="pa-b-20">
							<FormInput
								showRequired
								type="password"
								name="password"
								label="Password"
								placeholder="Enter Venue Password"
								value={values?.password}
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched?.password && !!errors?.password}
								errorMsg={errors?.password}
							/>
						</Col>
					</Row>
					<Row style={{ '--bs-gutter-x': '18px' }}>
						<Col lg={6} className="pa-b-20">
							<FormMobileNoInput
								name="mobileNumber"
								label="Mobile Number"
								placeholder="Enter Mobile No"
								mobileNoValue={values?.mobileNumber}
								onMobileNoChange={handleChange}
								onMobileNoBlur={handleBlur}
								countryCodeValue={values?.countryCode}
								onSelectCountry={(_, countryDetails) => {
									setFieldValue(
										'countryCode',
										countryDetails.dialCode
									);
								}}
								error={
									touched?.mobileNumber &&
									!!errors?.mobileNumber
								}
								errorMsg={errors?.mobileNumber}
							/>
						</Col>
						<Col lg={6} className="pa-b-20">
							<FormInput
								showRequired
								type="text"
								name="abnAcn"
								label="ABN/ACN Details"
								placeholder="Enter ABN/ACN Details"
								value={values?.abnAcn}
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched?.abnAcn && !!errors?.abnAcn}
								errorMsg={errors?.abnAcn}
							/>
						</Col>
					</Row>
					<Row style={{ '--bs-gutter-x': '18px' }}>
						<Col lg={6} className="pa-b-20">
							<FormInput
								showRequired
								type="text"
								name="address"
								label="Venue Address"
								placeholder="Enter Venue Address"
								value={values?.address}
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched?.address && !!errors?.address}
								errorMsg={errors?.address}
								onClick={() => setIsOpenMapModal(true)}
							/>
						</Col>
						<Col lg={6} className="pa-b-20">
							<div className="serviceType">
								<p className="fs-14 medium-text themeText">
									Your MyTab Venue Service Type
								</p>
								<div
									className="d-flex align-items-center"
									style={{ gap: '18px' }}
								>
									<FormCheckBox
										name="serviceType"
										label="Takeaway"
										value="PICKUP"
										onChange={handleChange}
										onBlur={handleBlur}
										checked={values?.serviceType?.includes(
											'PICKUP'
										)}
									/>
									<FormCheckBox
										name="serviceType"
										label="Table Service"
										value="TABLE"
										onChange={handleChange}
										onBlur={handleBlur}
										checked={values?.serviceType?.includes(
											'TABLE'
										)}
									/>
								</div>
							</div>
							{touched?.serviceType && !!errors?.serviceType && (
								<p className="fs-10 semi-bold-text headingTextColor pa-t-6">
									{errors?.serviceType}
								</p>
							)}
						</Col>
					</Row>
					<Row style={{ '--bs-gutter-x': '18px' }}>
						<Col lg={6} className="pa-b-20">
							<div className="isServeAlcohol">
								<p className="fs-14 medium-text themeText">
									Will your venue be serving alcohol?
								</p>
								<div
									className="d-flex align-items-center"
									style={{ gap: '18px' }}
								>
									<FormRadioButton
										label={'Yes'}
										name="isAlcohol"
										checked={values?.isAlcohol === 'Yes'}
										onChange={() => {
											setFieldValue('isAlcohol', 'Yes');
											setIsAlcohol('Yes');
										}}
									/>
									<FormRadioButton
										label={'No'}
										name="isAlcohol"
										checked={values?.isAlcohol === 'No'}
										onChange={() => {
											setFieldValue('isAlcohol', 'No');
											setIsAlcohol('No');
										}}
									/>
								</div>
							</div>
							{touched?.isAlcohol && !!errors?.isAlcohol && (
								<p className="fs-10 semi-bold-text headingTextColor pa-t-6">
									{errors?.isAlcohol}
								</p>
							)}
						</Col>
						{isAlcohol === 'Yes' && (
							<Col lg={6} className="pa-b-20">
								<FormInput
									type="text"
									name="licenseNumber"
									label="Liquor License Number"
									placeholder="Enter Liquor License Number"
									value={values?.licenseNumber}
									onChange={handleChange}
									onBlur={handleBlur}
									error={
										touched?.licenseNumber &&
										!!errors?.licenseNumber
									}
									errorMsg={errors?.licenseNumber}
								/>
							</Col>
						)}
					</Row>
					<div className="pa-b-20">
						<Button
							type="button"
							className="themeBorderButton plr-24"
							onClick={handleOpeningHoursModal}
						>
							<PlusIcon
								height="18px"
								width="18px"
								className="mr-6"
							/>{' '}
							Venue Opening Hours
						</Button>
					</div>
					<div className="d-flex" style={{ gap: '18px' }}>
						<Button
							type="button"
							className="themeBorderButton"
							onClick={() => navigate(-1)}
						>
							Cancel
						</Button>
						<CustomButton
							type="submit"
							className="themeButton"
							loading={loading}
						>
							Save
						</CustomButton>
					</div>
				</form>
			</FormWrapper>
			{isOpenOpeningHoursModal && (
				<OpeningHoursModal
					type="ADD"
					isOpen={isOpenOpeningHoursModal}
					handleModal={handleOpeningHoursModal}
					openingHoursModalData={openingHoursModalData}
					handleOpeningHoursModalData={handleOpeningHoursModalData}
				/>
			)}
			{isOpenMapModal && (
				<MapModal
					isOpen={isOpenMapModal}
					handleModal={handleMapModal}
					setFieldValue={setFieldValue}
				/>
			)}
		</>
	);
};

export default VenueAccountForm;
