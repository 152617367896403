import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const OrdersSkeleton = () => {
	return (
		<div className="w-100 d-flex flex-column gap-2">
			<Skeleton height="160px" width={'100%'} borderRadius={8} />
			<Skeleton height="160px" width={'100%'} borderRadius={8} />
			<Skeleton height="160px" width={'100%'} borderRadius={8} />
		</div>
	);
};

export default OrdersSkeleton;
