export const CalendarIcon = ({ fill, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 32 32" fill="none">
			<path
				d="M10.25 4C10.625 4 11 4.375 11 4.75V7H20V4.75C20 4.375 20.3281 4 20.75 4C21.125 4 21.5 4.375 21.5 4.75V7H23C24.6406 7 26 8.35938 26 10V25C26 26.6875 24.6406 28 23 28H8C6.3125 28 5 26.6875 5 25V10C5 8.35938 6.3125 7 8 7H9.5V4.75C9.5 4.375 9.82812 4 10.25 4ZM24.5 13H6.5V25C6.5 25.8438 7.15625 26.5 8 26.5H23C23.7969 26.5 24.5 25.8438 24.5 25V13ZM23 8.5H8C7.15625 8.5 6.5 9.20312 6.5 10V11.5H24.5V10C24.5 9.20312 23.7969 8.5 23 8.5Z"
				fill={fill ?? '#242424'}
			/>
		</svg>
	);
};
