import styled from 'styled-components';

const ForgetPasswordStyle = styled.div`
	.paragraph {
		font-size: 24px;
		font-family: 'poppins-regular';
		/* max-width:683px; */
	}

	.helperLink {
		user-select: none;
		padding-top: 10px;
		text-align: center;
		font-size: 24px;
		font-weight: 400;
		color: #000000;
		font-family: 'poppins-regular';

		.helperLinkSpan {
			color: #f95c69;
			cursor: pointer;
		}
	}

	@media (max-width: 600px) {
		.helperLink,
		.paragraph {
			font-size: 11.5px;
		}
	}

	@media only screen and (min-width: 600px) and (max-width: 960px) {
		.helperLink,
		.paragraph {
			font-size: 18px;
		}
	}

	@media screen and (max-height: 933px) {
		.helperLink,
		.paragraph {
			font-size: 13.5px;
		}
	}
	@media only screen and (min-width: 961px) and (max-width: 1824px) {
		.helperLink,
		.paragraph {
			font-size: 13.5px;
		}
		.paragraph {
		}
	}
`;

export default ForgetPasswordStyle;
