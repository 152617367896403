import React, { useState, useRef, useEffect } from 'react';
import PopoverWrapper from './popover2.style';

const CustomPopover2 = ({
	placement,
	children,
	button,
	closeOnPopoverClick = true
}) => {
	const [isOpenPopOver, setIsOpenPopOver] = useState(false);
	const togglePopOver = () => {
		setIsOpenPopOver((prev) => !prev);
	};
	const popOverRef = useRef(null);
	const handlePopoverClick = () => {
		if (closeOnPopoverClick) {
			setIsOpenPopOver(false);
		}
	};
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				popOverRef.current &&
				!popOverRef.current.contains(event.target)
			) {
				if (isOpenPopOver) {
					togglePopOver();
				}
			}
		};
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, [togglePopOver]);
	return (
		<>
			<PopoverWrapper ref={popOverRef} placement={placement}>
				<span onClick={() => togglePopOver()}>{button}</span>
				{isOpenPopOver && (
					<div
						className="popover-content"
						onClick={() => handlePopoverClick()}
					>
						{children}
					</div>
				)}
			</PopoverWrapper>
		</>
	);
};

export default CustomPopover2;
