import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import rightImg from '../../Assets/images/right.png';
import { Button } from 'reactstrap';
import SubscriptionModal from './SubscriptionModal';
import {
	MODAL_TYPE,
	behaviouralAnalyticsData,
	salesPerformanceAnalyticsData
} from './utils';

const SubscriptionCardWrapper = styled.div`
	height: 100%;
	background-color: #ffffff;
	.mainContent {
		border: 1px solid #bababa;
		.topPart {
			height: 234px;
		}
		.horizontalLine {
			height: 1px;
			background-color: #bababa;
		}
	}
`;

const SubscriptionCard = ({
	title,
	description,
	amount,
	features,
	isSubscribed,
	handleUpgradeClick,
	activeButton = false,
	buttonLabel
}) => {
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalType, setModalType] = useState();
	const handleSubscriptionModal = () => {
		setIsModalOpen((prev) => !prev);
	};
	return (
		<>
			<SubscriptionCardWrapper
				{...allThemeData}
				className={'d-flex flex-column'}
			>
				<div
					className={`pa-10 text-center text-white fs-20 semi-bold-text topButton ${
						isSubscribed ? 'visible' : 'invisible'
					}`}
					style={{
						backgroundColor: '#FC6461',
						top: -50,
						borderRadius: '16px 16px 0 0'
					}}
				>
					Your Current Subscription
				</div>
				<div
					className={
						'pa-t-24 pa-b-16 d-flex flex-column mainContent flex-1'
					}
					style={{
						backgroundColor: isSubscribed ? '#ffe8e7' : '#ffffff',
						borderRadius: isSubscribed ? '0 0 16px 16px' : '16px'
					}}
				>
					<div>
						<div className="text-center d-flex flex-column justify-content-between topPart">
							<div>
								<h1 className="fs-36 bold-text">{title}</h1>
								<p className="pa-t-4 fs-15 medium-text lh-sm plr-10">
									{description}
								</p>
							</div>
							<div className="d-flex flex-column align-items-center">
								<div className="d-flex">
									<span className="fs-20 medium-text">
										AUD $
									</span>
									<span className="fs-56 bold-text pl-12">
										{amount}
									</span>
									<span className="fs-20 medium-text align-self-end pl-12">
										/mo
									</span>
								</div>
								<span className="pa-t-4 fs-14 medium-text">
									Billed Monthly
								</span>
							</div>
						</div>
						<div className="plr-40 pa-t-24">
							<div className="horizontalLine"></div>
						</div>
					</div>
					<div className="flex-1 bottomPart plr-40 pa-t-24 d-flex flex-column justify-content-between">
						<div>
							<p className="fs-20 medium-text">
								What’s included on {title}
							</p>
							{features?.length > 0 &&
								features?.map((item, index) => {
									return (
										<div
											key={index}
											className={`pa-t-16 d-flex align-items-center ${
												item?.color === '#FF5F5F'
													? 'cursor-pointer'
													: ''
											}`}
											onClick={() => {
												item?.title?.includes(
													'Sales/Performance Analytics'
												) &&
													setModalType(
														MODAL_TYPE.SALES_PERFORMANCE_ANALYTICS
													);
												item?.title?.includes(
													'Behavioural Analytics'
												) &&
													setModalType(
														MODAL_TYPE.BEHAVIOURAL_ANALYTICS
													);
												item &&
													item.color === '#FF5F5F' &&
													setIsModalOpen(true);
											}}
										>
											<div>
												<img
													src={
														item?.listIcon ??
														rightImg
													}
													alt="right_icon_img"
												/>
											</div>
											<span
												className={`fs-18 semi-bold-text pl-16 text-break ${
													item?.color === '#FF5F5F'
														? 'text-decoration-underline'
														: ''
												}`}
												style={{
													color: item?.color
														? item.color
														: '#242424'
												}}
											>
												{item.title}
											</span>
										</div>
									);
								})}
						</div>
						<div
							className={`pa-t-45 ${
								isSubscribed ? 'invisible' : 'visible'
							}`}
						>
							<Button
								className={`${
									activeButton
										? 'themeButtonFullWidth'
										: 'themeButtonFullWidth2'
								}`}
								onClick={handleUpgradeClick}
								disabled={!activeButton}
							>
								{buttonLabel}
							</Button>
						</div>
					</div>
				</div>
			</SubscriptionCardWrapper>
			<SubscriptionModal
				handleModal={handleSubscriptionModal}
				isOpen={isModalOpen}
				modalData={
					modalType === MODAL_TYPE.SALES_PERFORMANCE_ANALYTICS
						? salesPerformanceAnalyticsData
						: behaviouralAnalyticsData
				}
				modalTitle={
					modalType === MODAL_TYPE.BEHAVIOURAL_ANALYTICS
						? 'Customer Behavioural Analytics'
						: ''
				}
				modalType={modalType}
			/>
		</>
	);
};

export default SubscriptionCard;
