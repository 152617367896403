import Scrollbars from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import ActivityCard from '../../Components/Dashboard/ActivityCard';
import GrowthChart from '../../Components/Dashboard/GrowthChart';
import IncomeGrowthChart from '../../Components/Dashboard/IncomeGrowthChart';
import RevenueChart from '../../Components/Dashboard/RevenueChart';
import TotalCustomerCard from '../../Components/Dashboard/TotalCustomerCard';
import TotalVenueCard from '../../Components/Dashboard/TotalVenueCard';

import useDevice from '../../Hooks/useDevice';
import PageWrapper from './index.style';

export const Dashboard = () => {
	const { isMobile } = useDevice();
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));

	return (
		<Scrollbars
			style={{
				height: `${
					isMobile ? 'calc(100vh - 145px)' : 'calc(100vh - 111px)'
				}`
			}}
			className="custom-scrollbar"
		>
			<PageWrapper {...allThemeData}>
				<div className="d-flex flex-column gap-3">
					<div className="d-flex flex-column flex-md-row gap-3">
						<div className="d-flex flex-1 p-3 defaultBoxShadow border-radius-16 bg-white">
							<GrowthChart />
						</div>
						<div className="bg-success d-flex flex-1 p-3 defaultBoxShadow border-radius-16 bg-white">
							<IncomeGrowthChart />
						</div>
					</div>
					<div className="d-flex flex-column flex-md-row gap-3">
						<div className="d-flex flex-column flex-5 p-3 defaultBoxShadow border-radius-16 bg-white">
							<RevenueChart />
						</div>
						<div className="d-flex flex-2 p-3 defaultBoxShadow border-radius-16 bg-white">
							<ActivityCard />
						</div>
					</div>
					<div className="d-flex flex-column flex-lg-row gap-3">
						<TotalCustomerCard />
					</div>
					<div className="p-3 defaultBoxShadow border-radius-16 bg-white">
						<TotalVenueCard />
					</div>
				</div>
			</PageWrapper>
		</Scrollbars>
	);
};
