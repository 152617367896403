import TableStyle from '../../Common/TableStyle';
import TableV6 from '../../Common/TableV6';
import { Pagination } from '../../Common/Pagination';
import TableSkeleton from './TableSkeleton';

const SummaryReportTable = ({
	loading,
	tableData,
	tableColumns,
	handlePageChange,
	totalRows,
	currentPage
}) => {
	return (
		<div>
			{loading ? (
				<TableSkeleton />
			) : (
				<TableStyle version={6}>
					<TableV6
						columns={tableColumns}
						data={tableData}
						NoDataText={'No data found'}
					/>
					{tableData?.length !== 0 && (
						<div className="pa-t-8">
							<Pagination
								handlePageChange={handlePageChange}
								total={totalRows}
								currentPage={currentPage}
							/>
						</div>
					)}
				</TableStyle>
			)}
		</div>
	);
};

export default SummaryReportTable;
