import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import PageStructure from '../../../Components/Common/PageStructure';
import ProductForm from '../../../Components/ManageMenu/ProductForm';
import authActions from '../../../Redux/auth/actions';

export const ManageMenuItemForm = () => {
	const authData = useSelector((state) => ({ ...state.auth }));
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	useEffect(() => {
		return () => {
			if (authData?.selectedVenue?.posStatus === '1') {
				dispatch(authActions.copy_paste_required_options(null));
			}
		};
	}, []);
	return (
		<PageStructure
			title={`${
				!pathname.includes('/edit') ? 'Add Menu Item' : 'Edit Menu Item'
			}`}
		>
			<ProductForm />
		</PageStructure>
	);
};
