import styled from 'styled-components';
import sectionImage from '../../Assets/images/section-common-back.png';

const PageWrapper = styled.div`
	width: 100%;
	height: 100%;
	/* new design styles */
	display: flex;
	.pageContainer {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 71px;
	}
	.iconImage {
		width: 51px;
		height: 44px;
	}
	.formSideSpace {
		padding-right: 26px;
	}

	.smallInfoWrraper {
		color: #000000;
		.smallInfo {
			font-family: 'poppins-regular';
			font-size: 21px;
		}
	}

	.headingStyle {
		padding-top: 30px;
		font-size: 42px;
		font-weight: 700;
		font-family: 'montserrat-bold' !important;
		color: #313132eb;
		padding-bottom: 3%;
	}
	.imageWrapper {
		flex: 1;
		display: flex;

		background: #fff !important;

		.text {
			max-width: 614px;
			font-family: 'montserrat-bold';
			font-size: 55px;
			color: #fff;
			text-align: center;
		}
		.image {
			width: 558px;
			height: 528px;
		}
		@media only screen and (max-width: 1199px) {
			display: none !important;
		}
	}
	.backWrraper {
		height: 100%;
		width: 100%;
		display: flex;
		gap: 29px;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background: url(${sectionImage});

		background-size: 100% 100% !important;
		background-repeat: no-repeat;
	}

	.followSteps {
		display: flex;
		margin-bottom: 23px;
		font-family: 'poppins-regular';
		p {
			font-size: 21px;
			font-family: 'poppins-regular';
			color: #000000;
		}
		.numberCircle {
			color: #ffffff;
			font-size: 20px;
			font-weight: 800;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 100%;
			height: 51px;
			width: 51px;
			background: #f95c69;
		}
	}

	.verifyOtpSubText {
		font-family: 'poppins-regular';
		font-size: 22px;
		margin-bottom: 0.5%;
		color: #000000;
	}

	.resendMail {
		font-family: 'poppins-regular';
		color: #000000;
		font-size: 21px;
		text-align: center;
		.spanLink {
			cursor: pointer;
			font-family: 'montserrat-semi-bold';
			color: #f95c69;
		}
	}

	@media (max-width: 600px) {
		.headingStyle {
			padding-top: 3%;
			font-size: 22px;
			padding-bottom: 5%;
		}

		.iconImage {
			width: 23.5px;
			height: 20.3px;
		}
		.imageWrapper {
			display: none;
		}

		.followSteps {
			p {
				font-size: 9.7px;
			}
			.numberCircle {
				font-size: 10px;
				font-weight: 800;

				height: 20px;
				width: 20px;
			}
		}
		.resendMail {
			font-size: 9.7px;
		}

		.verifyOtpSubText {
			font-size: 10.1px;
		}

		.smallInfoWrraper {
			.smallInfo {
				font-size: 9.7px !important;
			}
		}
		.formWrapper {
			padding: 0 20px !important;
		}
		.formSideSpace {
			padding-right: 0px !important;
		}
	}

	@media only screen and (min-width: 600px) and (max-width: 960px) {
		.headingStyle {
			font-size: 31.5px;
			padding-bottom: 7%;
		}

		.iconImage {
			width: 38.2px;
			height: 33px;
		}

		.followSteps {
			p {
				font-size: 15.7px;
			}
			.numberCircle {
				font-size: 12px;
				font-weight: 800;

				height: 33px;
				width: 33px;
			}
		}

		.smallInfoWrraper {
			.smallInfo {
				font-size: 15.7px !important;
			}
		}
		.verifyOtpSubText {
			font-size: 16.5px;
		}
		.resendMail {
			font-size: 15.7px !important;
		}

		.imageWrapper {
			display: none;
		}
		.formWrapper {
			padding: 0 50px !important;
		}
		.formSideSpace {
			padding-right: 0px !important;
		}
	}

	@media only screen and (min-width: 961px) and (max-width: 1824px) and (max-height: 800px) {
		.iconImage {
			width: 28.6px;
			height: 24.7px;
		}
		.imageWrapper {
			.text {
				font-size: 30.9px;
				max-width: 305.4px;
			}
			.image {
				width: 313.8px;
				height: 297px;
			}
		}
		.headingStyle {
			padding-top: 30px;
			font-size: 23.6px;
		}

		.followSteps {
			margin-bottom: 12.9px;
			p {
				font-size: 11.8px;
				font-family: 'poppins-regular';
			}
			.numberCircle {
				font-size: 12px;
				font-weight: 800;
				border-radius: 100%;
				height: 30.3px;
				width: 30.3px;
			}
		}
		.smallInfoWrraper {
			.smallInfo {
				font-size: 11.8px;
			}
		}
		.verifyOtpSubText {
			font-size: 13.3px;
		}
		.resendMail {
			font-size: 11.8px;
		}
	}
	.bulletPointOuter {
		height: 25px;
		width: 25px;
		border-radius: 50%;
		background-color: #fd7d8d;
		margin-top: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: 'poppins-regular';
		.bulletPointSpan {
			font-size: 14px;
			color: #fff;
			font-weight: 600;
			padding-top: 3px;
		}
		@media only screen and (min-width: 1400px) and (max-width: 1600px) {
			height: 27px;
			width: 27px;
			margin-top: 0px;
			.bulletPointSpan {
				font-size: 14px;
			}
		}
		@media only screen and (min-width: 1600px) {
			height: 45px;
			width: 45px;
			.bulletPointSpan {
				font-size: 20px;
			}
		}
		@media only screen and (max-width: 1100px) {
			height: 20px;
			width: 20px;
			.bulletPointSpan {
				font-size: 10px;
			}
		}
		@media only screen and (min-width: 400px) and (max-width: 1200px) {
			height: 30px;
			width: 30px;
			.bulletPointSpan {
				font-size: 16px;
			}
		}
	}
`;

export default PageWrapper;
