import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useMediaQuery } from 'react-responsive';

const ConnectVenueSkeleton = () => {
	const upToXL = useMediaQuery({ maxWidth: 1600 });

	return (
		<>
			<div>
				<Skeleton height="124px" width={'100%'} borderRadius={18} />
			</div>
			<div className="pa-t-24">
				<Skeleton height="124px" width={'100%'} borderRadius={18} />
			</div>
			<div className="pa-t-24">
				<Skeleton height="124px" width={'100%'} borderRadius={18} />
			</div>
			<div className="pa-t-32">
				<Skeleton
					height={`${upToXL ? '50px' : '60px'}`}
					width={'100%'}
					borderRadius={12}
				/>
			</div>
		</>
	);
};

export default ConnectVenueSkeleton;
