import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import PageWrapper from './index.style';
import { useSelector } from 'react-redux';

const PageNotFound = () => {
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	const navigate = useNavigate();
	return (
		<PageWrapper {...allThemeData}>
			<div className="d-flex flex-column align-items-center">
				<h1 className="fs-68 bold-text headingTextColor pa-b-8">404</h1>
				<p className="fs-16 medium-text pa-b-18">Page Not Found</p>
				<p className="fs-12 regular-text pa-b-24">
					The page you're looking for does not seem to exist
				</p>
				<Button className="themeButton" onClick={() => navigate(-2)}>
					Go Back
				</Button>
			</div>
		</PageWrapper>
	);
};

export default PageNotFound;
