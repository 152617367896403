import { Button } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import { useState, useEffect } from 'react';
import { PlusIcon } from '../../Icons';
import RequiredOptionsItem from './RequiredOptionsItem';
import RequiredOptionsSectionPopover from '../RequiredOptionsSectionPopover';

const RequiredOptions = ({
	name,
	values,
	handleChange,
	setFieldValue,
	touched,
	errors,
	handleDeletedRequiredOptions,
	handleDeletedRequiredOptionsItem,
	posStatus
}) => {
	const isXL = useMediaQuery({ maxWidth: 1199 });
	const [requiredOptions, setRequiredOptions] = useState(null);
	const handleAddSection = () => {
		if (posStatus) {
			setFieldValue(name, [
				...values?.requiredOptions,
				{
					serviceType: null,
					data: []
				}
			]);
		} else {
			setFieldValue(name, [
				...values?.requiredOptions,
				{
					serviceType: null,
					data: [
						{
							itemName: '',
							price: '0.00'
						}
					]
				}
			]);
		}
	};
	useEffect(() => {
		if (values?.requiredOptions?.length > 0) {
			setRequiredOptions(values?.requiredOptions);
		}
	}, [values, values?.requiredOptions, values?.requiredOptions?.length]);
	return (
		<>
			<div className="d-flex gap-3 justify-content-between align-items-center">
				<div>
					<p className="fs-14 medium-text themeText label-color pa-b-8">
						Required Options
					</p>
					<p className={`fs-12 medium-text hintColor h-px-36`}>
						(Customers must select one of the options under each
						“Please select...” subheading)
					</p>
				</div>
				<div
					className="d-flex align-items-center"
					style={{ gap: '12px' }}
				>
					<Button
						className="themeButton w-px-30 h-px-30 p-0 d-flex justify-content-center align-items-center"
						onClick={() => handleAddSection()}
					>
						<PlusIcon height={18} width={18} stroke={'#FFF'} />
					</Button>
					{!posStatus && (
						<RequiredOptionsSectionPopover
							name={name}
							setFieldValue={setFieldValue}
							values={values}
						/>
					)}
				</div>
			</div>
			{values?.requiredOptions?.length > 0 &&
				values?.requiredOptions?.map((item, index) => {
					return (
						<RequiredOptionsItem
							key={index}
							index={index}
							name={name}
							values={values}
							setFieldValue={setFieldValue}
							handleChange={handleChange}
							touched={touched}
							errors={errors}
							handleDeletedRequiredOptions={
								handleDeletedRequiredOptions
							}
							handleDeletedRequiredOptionsItem={
								handleDeletedRequiredOptionsItem
							}
							posStatus={posStatus}
						/>
					);
				})}
		</>
	);
};

export default RequiredOptions;
