import {
	BrowserRouter,
	Navigate,
	Outlet,
	Route,
	Routes
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { StyleRoot } from 'radium';

import themes from './Settings/themes';
import { themeConfig } from './Settings';
// Layout Routes
import { adminRoutes, venueRoutes } from './Routes/dashboardRoutes';
import indexRoutes from './Routes/indexRoutes';
import './Assets/scss/app.scss';
import { QueryClient, QueryClientProvider } from 'react-query';

import Layout from './Layouts/Layout';
import HomeLayout from './Layouts/HomeLayout';
import { CommonRoutes, VenueSetupRoutes } from './Utils/routes';
import PageNotFound from './Pages/PageNotFound';
import { LAYOUT } from './Helper/constant';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import PageLoader from './Components/PageLoader';
import Landing from './Pages/Landing';
import PublicRoute from './Components/Common/RouteWrappers/PublicRoute';
import venueProtectedRoutes from './Routes/venueProtectedRoutes';
import VenueProtectedLayout from './Layouts/VenueProtectedLayout';
import venueSetupRoutes from './Routes/venueSetupRoutes';

const queryClient = new QueryClient();

function App() {
	const authData = useSelector((state) => ({ ...state.auth }));
	const uiData = useSelector((state) => ({ ...state.ui }));
	const [isFirstTimeRender, setIsFirstTimeRender] = useState(true);
	// useEffect(() => {
	// 	if (!isFirstTimeRender) {
	// 		window.location.reload();
	// 	} else {
	// 		setIsFirstTimeRender(false);
	// 	}
	// }, [authData?.selectedVenue?.id]);
	return (
		<>
			<QueryClientProvider client={queryClient}>
				<ThemeProvider theme={themes[themeConfig.theme]}>
					<StyleRoot>
						<BrowserRouter>
							<Routes>
								<Route
									path={CommonRoutes.landingPage}
									exact
									element={
										<HomeLayout>
											<PublicRoute>
												<VenueProtectedLayout
													forPublic={true}
												/>
												<Landing />
											</PublicRoute>
										</HomeLayout>
									}
								/>
								{/* Public Routes */}
								<Route
									element={
										<HomeLayout>
											<Outlet />
										</HomeLayout>
									}
								>
									{indexRoutes?.length > 0 &&
										indexRoutes?.map((prop, key) => {
											return (
												<Route
													path={prop.path}
													element={prop.component}
													key={key}
												/>
											);
										})}
								</Route>
								{/* Protected Routes */}
								<Route
									element={
										<HomeLayout>
											<Outlet />
										</HomeLayout>
									}
								>
									{venueProtectedRoutes?.length > 0 &&
										venueProtectedRoutes?.map(
											(prop, key) => {
												return (
													<Route
														path={prop.path}
														element={prop.component}
														key={key}
													/>
												);
											}
										)}
								</Route>
								{/* Standardversion Routes */}
								{/* <Route
									element={
										<HomeLayout>
											<Outlet />
										</HomeLayout>
									}
								>
									{venueSetupRoutes?.length > 0 &&
										venueSetupRoutes?.map((prop, key) => {
											return (
												<Route
													path={prop.path}
													element={prop.component}
													key={key}
												/>
											);
										})}
								</Route> */}
								{/* Routes which have dashboard layout */}
								<Route
									element={
										<Layout>
											<Outlet />
										</Layout>
									}
								>
									{/* Admin Routes */}
									{adminRoutes?.length > 0 &&
										adminRoutes?.map((item, key) => {
											return item?.layout ===
												LAYOUT.dashboard ? (
												<Route
													path={item?.path}
													element={item?.component}
													key={key}
												/>
											) : (
												''
											);
										})}
									{/* Venue Routes */}
									{venueRoutes?.length > 0 &&
										venueRoutes?.map((item, key) => {
											return item?.layout ===
												LAYOUT.dashboard ? (
												<Route
													path={item?.path}
													element={item?.component}
													key={key}
												/>
											) : (
												''
											);
										})}
								</Route>
								{/* Routes which have home layout */}
								<Route
									element={
										<HomeLayout>
											<Outlet />
										</HomeLayout>
									}
								>
									{venueRoutes?.length > 0 &&
										venueRoutes?.map((item, key) => {
											return item?.layout ===
												LAYOUT.home ? (
												<Route
													path={item?.path}
													element={item?.component}
													key={key}
												/>
											) : (
												''
											);
										})}
								</Route>
								<Route path="/404" element={<PageNotFound />} />
								<Route
									path="*"
									element={
										<Navigate
											to={CommonRoutes.pageNotFound}
										/>
									}
								/>
							</Routes>
						</BrowserRouter>
					</StyleRoot>
				</ThemeProvider>
			</QueryClientProvider>
			<PageLoader loading={uiData?.pageLoading} />
		</>
	);
}

export default App;
