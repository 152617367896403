import { FormGroup, Label } from 'reactstrap';
import CustomDatePicker from '../Common/DateRangePicker/CustomDatePicker';

const FormDatePicker = ({
	id,
	label,
	error,
	errorMsg,
	className,
	name,
	onChange,
	onBlur,
	value,
	minDate,
	...rest
}) => {
	return (
		<FormGroup className={`mb-0 `}>
			{label && (
				<Label
					for={id}
					className="fs-12 medium-text themeText label-color ma-b-6"
				>
					{label}
				</Label>
			)}
			<CustomDatePicker
				name={name}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				minDate={minDate}
				{...rest}
			/>
			{error && (
				<p className="fs-10 semi-bold-text headingTextColor pa-t-6">
					{errorMsg}
				</p>
			)}
		</FormGroup>
	);
};

export default FormDatePicker;
