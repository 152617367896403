import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useMediaQuery } from 'react-responsive';
import Grid from '../../Grid';
import PageWrapper from './index.style';

const ProductItemSkeleton = () => {
	const isXL = useMediaQuery({ maxWidth: 1300, minWidth: 1200 });
	const isLG = useMediaQuery({ maxWidth: 1199, minWidth: 769 });
	const isMd = useMediaQuery({ maxWidth: 768 });
	return (
		<PageWrapper className="pr-12">
			<Grid columns={isMd ? 1 : isXL ? 3 : isLG ? 2 : 3} className="pt-0">
				{Array(9)
					.fill('1')
					.map((_, index) => {
						return (
							<div className="skeleton-wrapper" key={index}>
								<Skeleton
									height="100%"
									width={'100%'}
									borderRadius={10}
								/>
							</div>
						);
					})}
			</Grid>
		</PageWrapper>
	);
};

export default ProductItemSkeleton;
