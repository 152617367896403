import { components } from 'react-select';
import { DownArrowIcon } from '../Icons';

export const statusOptions = [
	{
		value: 'info-status-tag',
		label: 'Status Tag 1',
		class: 'info-status-tag'
	},
	{
		value: 'warning-status-tag',
		label: 'Status Tag 2',
		class: 'warning-status-tag'
	},
	{
		value: 'success-status-tag',
		label: 'Status Tag 3',
		class: 'success-status-tag'
	}
];

export const priorityOptions = [
	{
		value: 'Low',
		label: 'Low'
	},
	{
		value: 'Medium',
		label: 'Medium'
	},
	{
		value: 'High',
		label: 'High'
	}
];

export const usersOptions = [
	{
		value: '1',
		label: 'Savannah Nguyen'
	},
	{
		value: '2',
		label: 'Ralph Edwards'
	},
	{
		value: '3',
		label: 'Jane Cooper'
	},
	{
		value: '4',
		label: 'Marvin McKinney'
	}
];

export const CustomOption = ({ children, ...props }) => {
	return (
		<components.Option {...props}>
			<div className={`info-status-tag statusTag ${props.data.class}`}>
				{children}
			</div>
		</components.Option>
	);
};

export const DropdownIndicator = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<DownArrowIcon height={7} width={12} />
		</components.DropdownIndicator>
	);
};

export const ValueTagContainer = ({ children, ...props }) => {
	return (
		<components.DropdownIndicator {...props}>
			<div className={`statusTag ${props.getValue()?.[0]?.class}`}>
				{children}
			</div>
		</components.DropdownIndicator>
	);
};

export const ValueContainer = ({ children, ...props }) => {
	return (
		<components.DropdownIndicator {...props}>
			{children}
		</components.DropdownIndicator>
	);
};

export const select2Style = {
	control: (styles, state) => ({
		...styles,
		backgroundColor: 'white',
		boxShadow: 'none !important',
		borderColor: '#FFF !important',
		outline: state.isFocused && '#FFF',
		textAlign: 'left',
		padding: 6,
		borderRadius: 8,
		justifyContent: 'end',
		'&:hover': {
			borderColor: '#FFF',
			outline: 'none'
		}
	}),
	option: (styles, { isDisabled, isSelected }) => {
		return {
			...styles,
			backgroundColor: '#FFF',
			boxShadow: 'none !important',
			borderColor: '#FFF !important',
			color: '#4f4f4F',
			textAlign: 'left',
			cursor: isDisabled ? 'not-allowed' : 'default',
			'&:hover': {
				backgroundColor: '#FF5F5F',
				color: '#FFF'
			}
		};
	}
};

export const select2StyleStatus = {
	...select2Style,
	option: (styles, { isDisabled, isSelected }) => {
		return {
			...styles,
			backgroundColor: '#FFF',
			'&:hover': {
				backgroundColor: '#FFF'
			}
		};
	}
};
