import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Api from '../../Helper/Api';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const StripeAccountProcessClose = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const success = queryParams.get('success');
	const [stripeProcessLoading, setStripeProcessLoading] = useState(false);
	const fetchBarId = useSelector((state) => state.bar?.bar_id);

	const completeStripeIntegration = async () => {
		if (success === 'true') {
			try {
				setStripeProcessLoading(true);

				const res = await Api(
					'POST',
					'/venue/bar/complete-stripe-integration',
					{
						bar_id: fetchBarId
					}
				);

				if (res?.data?.status) {
					setStripeProcessLoading(false);
					window.close();
				} else {
					setStripeProcessLoading(false);
					window.close();
				}
			} catch (err) {
				setStripeProcessLoading(false);
				window.close();
			}
		}
	};

	useEffect(() => {
		completeStripeIntegration();
	}, [fetchBarId, success]);

	return (
		<div>
			{stripeProcessLoading ? `Please wait while process completes` : ``}
		</div>
	);
};

export default StripeAccountProcessClose;
