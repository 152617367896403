import styled from 'styled-components';

const ModuleWrapper = styled.div`
	.react-datepicker {
		border: 0px !important;
		background-color: #fff;
	}
	.react-datepicker__month-container {
		border: 0px !important;
		.react-datepicker__header {
			padding-top: 0px;
			border: 0px !important;
			background-color: #fff;
		}
		.react-datepicker__day-names {
			padding-top: 5px;
		}
		.react-datepicker__day-name {
			font-weight: 600;
			color: ${(props) => props.layoutTheme.buttonColor3} !important;
		}
		.react-datepicker__current-month {
			display: none;
		}
		.react-datepicker__year-dropdown-container--scroll {
			margin: 0px 25px;
		}
		.react-datepicker__month-read-view--down-arrow,
		.react-datepicker__year-read-view--down-arrow {
			top: 3px !important;
			border-color: #000 !important;
			border-width: 2px 2px 0 0 !important;
		}
		.react-datepicker__day--keyboard-selected {
			color: ${(props) => props.layoutTheme.textColor};
			background-color: #fff;
		}
		.react-datepicker__month-read-view--selected-month,
		.react-datepicker__year-read-view--selected-year,
		.react-datepicker__month-option,
		.react-datepicker__year-option {
			color: ${(props) => props.layoutTheme.textColor};
			background-color: #fff;
			font-weight: 600;
		}
		.react-datepicker__year-select,
		.react-datepicker__month-select {
			color: ${(props) => props.layoutTheme.textColor};
			background-color: #fff;
			font-weight: 600;
			padding: 5px;
			border: 0px;
			outline: none;
			padding-top: 3px;
		}
		.react-datepicker__month-read-view,
		.react-datepicker__year-read-view {
			visibility: visible !important;
		}
		.react-datepicker__month-dropdown,
		.react-datepicker__year-dropdown {
			background-color: #fff;
		}
		.react-datepicker__day--selected,
		.react-datepicker__day--in-selecting-range,
		.react-datepicker__day--in-range {
			background-color: ${(props) =>
				props.layoutTheme.buttonColor3} !important;
			color: #fff !important;
			border-radius: 50%;
		}
		.react-datepicker__day {
			color: ${(props) => props.layoutTheme.textColor};
		}
	}
	.react-datepicker__navigation-icon--next::before,
	.react-datepicker__navigation-icon--previous::before {
		border-color: #000 !important;
	}
	.react-datepicker__current-month--hasMonthDropdown {
		display: none;
	}
	.dashboard-dropdown {
		.activeColor {
			color: ${(props) => props.layoutTheme.buttonColor3} !important;
		}
		.dropdown-item {
			outline: 0 !important;
			border: 0px !important;
			padding-left: 16px;
			padding-right: 16px;
			&.active {
				background-color: ${(props) =>
					props.layoutTheme.buttonColor3} !important;
			}
			@media only screen and (max-width: 575.98px) {
				padding-top: 5px;
				margin-top: 6px;
				margin-bottom: 6px;
			}
			.dropdown-name {
				color: ${(props) => props.layoutTheme.textColor};
			}
			&:hover {
				outline: 0 !important;
				border: 0px !important;
			}
			&:active {
				outline: 0 !important;
				border: 0px !important;
				background-color: #fff !important;
				color: ${(props) => props.layoutTheme.textColor};
			}
		}
		.yearDropdownToggle {
			outline: 0 !important;
			border: 0px !important;
			width: 60px !important;
			padding-left: 8px;
			padding-right: 8px;
			&.active {
				background-color: ${(props) =>
					props.layoutTheme.buttonColor3} !important;
			}
			@media only screen and (max-width: 575.98px) {
				padding-top: 5px;
				margin-top: 6px;
				margin-bottom: 6px;
			}
			.dropdown-name {
				color: ${(props) => props.layoutTheme.textColor};
			}
			&:hover {
				outline: 0 !important;
				border: 0px !important;
			}
			&:active {
				outline: 0 !important;
				border: 0px !important;
				background-color: #fff !important;
				color: ${(props) => props.layoutTheme.textColor};
			}
		}
		.monthDropdownToggle {
			outline: 0 !important;
			border: 0px !important;
			width: 100px !important;
			padding-left: 8px;
			padding-right: 8px;
			&.active {
				background-color: ${(props) =>
					props.layoutTheme.buttonColor3} !important;
			}
			@media only screen and (max-width: 575.98px) {
				padding-top: 5px;
				margin-top: 6px;
				margin-bottom: 6px;
			}
			.dropdown-name {
				color: ${(props) => props.layoutTheme.textColor};
			}
			&:hover {
				outline: 0 !important;
				border: 0px !important;
			}
			&:active {
				outline: 0 !important;
				border: 0px !important;
				background-color: #fff !important;
				color: ${(props) => props.layoutTheme.textColor};
			}
		}
		/* .yearLabel {
			width: 66px !important;
			padding-left: 8px !important;
			padding-right: 8px !important;
		} */
		.dropdown-menu {
			border: 1px solid #eaeaea !important;
			border-radius: 6px !important;
			@media only screen and (max-width: 360px) {
			}
		}
		.monthMenu {
			padding-top: 0 !important;
			padding-bottom: 0 !important;
			margin-top: 6px !important;
			min-width: unset !important;
			.monthMenuItem {
				padding-top: 0.5px !important;
				padding-bottom: 0.5px !important;
			}
		}
		.yearDropdownMenu {
			max-height: 218px !important;
			overflow-x: hidden;
		}
	}
	.leftArrowIcon {
		margin-left: 12px !important;
	}
	.rightArrowIcon {
		margin-right: 12px !important;
	}
	.monthDropdown {
		width: 104px !important;
		border: 1px solid #ff5f5f !important;
		border-radius: 6px !important;
	}
	.yearDropdown {
		/* width: 104px !important; */
		border: 1px solid #ff5f5f !important;
		border-radius: 6px !important;
	}
`;

export default ModuleWrapper;
