import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import CustomButton from '../../Common/CustomButton';
import Api from '../../../Helper/Api';
import { VenueApiRoutes } from '../../../Utils/routes';
import Switch from '../../Common/Switch';
import PasscodeModal from './PasscodeModal';
import authActions from '../../../Redux/auth/actions';

const VenueChangePasscode = () => {
	const authData = useSelector((state) => ({ ...state.auth }));
	const [passcodeStatus, setPasscodeStatus] = useState(false);
	const [loading, setLoading] = useState(false);
	const [changePassscodeModal, setChangePassscodeModal] = useState(false);
	const dispatch = useDispatch(false);
	const handleChangePassscodeModal = () => {
		setChangePassscodeModal(!changePassscodeModal);
	};
	const handlePasscodeStatusChange = async () => {
		let payload = {
			bar_id: authData?.selectedVenue?.id,
			passCodeStatus: passcodeStatus ? 'Inactive' : 'Active'
		};
		setLoading(true);
		try {
			const res = await Api(
				'POST',
				VenueApiRoutes.updatePassCodeStatus,
				payload
			);
			if (res?.data?.status) {
				toast.success(res?.data?.message);
				dispatch(
					authActions?.set_passcode_status({
						barId: authData?.selectedVenue?.id,
						passcodeStatus: payload?.passCodeStatus
					})
				);
			} else {
				toast.error(res?.data?.message);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			if (err?.response?.data?.message) {
				toast.error(err?.response?.data?.message);
			}
		}
	};
	useEffect(() => {
		if (authData?.selectedVenue?.passcodeStatus === 'Inactive') {
			setPasscodeStatus(false);
		} else {
			setPasscodeStatus(true);
		}
	}, [authData?.selectedVenue?.passcodeStatus]);
	return (
		<div>
			<p className="fs-12 regular-text themeText">
				Assign an access passcode to prevent unauthorised access to your
				MyTab Venue settings.
			</p>
			<div className="pa-t-24">
				<span className="fs-14 pr-18">
					Passcode {passcodeStatus ? 'On' : 'Off'}
				</span>
				<Switch
					checked={passcodeStatus}
					onChange={() => handlePasscodeStatusChange()}
				/>
			</div>
			<div className="pa-t-24">
				<CustomButton
					type="button"
					className="themeButton"
					onClick={() => setChangePassscodeModal(true)}
				>
					{passcodeStatus ? 'Change Passcode' : 'Set Passcode'}
				</CustomButton>
			</div>
			{changePassscodeModal && (
				<PasscodeModal
					isOpen={changePassscodeModal}
					handleModal={handleChangePassscodeModal}
					passcodeStatus={passcodeStatus}
				/>
			)}
		</div>
	);
};

export default VenueChangePasscode;
