import React, { useEffect, useState } from 'react';
import {
	Tooltip,
	ResponsiveContainer,
	PieChart,
	Pie,
	Cell,
	Legend
} from 'recharts';
import useDevice from '../../../Hooks/useDevice';

const NewPieChart = ({
	heading,
	chartData,
	labelFormatter,
	labelAngle,
	tooltipValueFormatter
}) => {
	const [chartDimension, setChartDimension] = useState({
		innerRadius: 36,
		outerRadius: 89,
		labelFontSize: 14
	});
	const { isMobile } = useDevice();
	const RADIAN = Math.PI / 180;
	const renderLabel = (props) => {
		const { cx, cy, midAngle, outerRadius, innerRadius, percent, value } =
			props;
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);
		if (value) {
			return (
				<g transform={`translate(${x},${y})`}>
					<text
						x={0}
						y={0}
						fill="white"
						textAnchor="middle"
						dominantBaseline="central"
						transform={`${
							labelAngle ? `rotate(${labelAngle})` : 'rotate(0)'
						}`}
						fontSize={12}
					>
						{labelFormatter ? labelFormatter(value) : value}
					</text>
				</g>
			);
		}
	};
	useEffect(() => {
		if (isMobile) {
			setChartDimension({
				innerRadius: 36,
				outerRadius: 89,
				labelFontSize: 14
			});
		}
	}, [isMobile]);
	return (
		<div
			className={`pa-24 border-radius-12 bg-white d-flex w-100 h-100 flex-column defaultBoxShadow ${
				!chartData ||
				chartData?.length === 0 ||
				(chartData[0]?.value === 0 && chartData[1]?.value === 0)
					? 'position-relative'
					: ''
			}`}
		>
			{heading && (
				<div className="pa-b-12">
					<div className="fs-15 medium-text">{heading}</div>
				</div>
			)}
			{(!chartData ||
				chartData?.length === 0 ||
				(chartData[0]?.value === 0 && chartData[1]?.value === 0)) && (
				<div className="position-absolute top-50 start-50 translate-middle zIndex-100">
					<span className="fs-15 opacity-50 d-flex justify-content-center text-center">
						No Chart Data Available
					</span>
				</div>
			)}
			<ResponsiveContainer
				width={'100%'}
				height={234}
				className={`${
					!chartData ||
					chartData?.length === 0 ||
					(chartData[0]?.value === 0 && chartData[1]?.value === 0)
						? 'invisible'
						: ''
				}`}
			>
				<PieChart width={'100%'} height={'100%'}>
					<Pie
						data={chartData}
						dataKey="value"
						nameKey="name"
						cx="50%"
						cy="50%"
						innerRadius={chartDimension.innerRadius}
						outerRadius={chartDimension.outerRadius}
						label={renderLabel}
						labelLine={false}
						startAngle={-270}
						endAngle={90}
						stroke="none"
					>
						{chartData?.length > 0 &&
							chartData?.map((entry, index) => {
								return (
									<Cell
										key={`cell-${index}`}
										fill={entry.fill}
									/>
								);
							})}
					</Pie>
					<Tooltip
						formatter={(value) => {
							return tooltipValueFormatter
								? tooltipValueFormatter(value)
								: value;
						}}
						itemStyle={{ fontSize: '12px' }}
					/>
					<Legend
						verticalAlign="top"
						align="left"
						iconType="circle"
						iconSize={14}
						formatter={(value) => (
							<span
								style={{
									color: '#242424',
									fontSize: '12px'
								}}
							>
								{value}
							</span>
						)}
						wrapperStyle={{ left: '-28px' }}
					/>
				</PieChart>
			</ResponsiveContainer>
		</div>
	);
};

export { NewPieChart as PieChart };
