import { useState } from 'react';
import OtpInput from 'react-otp-input';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import * as yup from 'yup';
import * as validation from '../../../Helper/YupValidation';

import CustomModal from '../../Common/Modal/CustomModal';
import ModalWrapper from './securityVerifyOtp.style';
import passwordImage from '../../../Assets/images/password-icon.png';
import Api from '../../../Helper/Api';
import CustomButton from '../../Common/CustomButton';

const SecurityVerifyOtpModal = ({
	isOpen,
	handleModal,
	handleVerification
}) => {
	const [otp, setOtp] = useState('');
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	const profileData = useSelector((state) => ({ ...state.auth }));
	const [loading, setLoading] = useState(false);

	const handleOTPChange = (otp) => {
		setOtp(otp);
		verifyOtpFormik.setFieldValue('otp', otp);
	};

	const validationSchema = yup.object().shape({
		otp: validation.YUP_VALIDATION.OTP
	});

	const handelOnsubmit = async () => {
		const payload = {
			otp: verifyOtpFormik?.values?.otp
		};

		let api =
			profileData.login_type === 'venue'
				? '/venue/security/verify-otp'
				: '/cms/auth/verify-mfa-otp';
		setLoading(true);
		try {
			const res = await Api('POST', api, payload);
			setLoading(false);
			if (res?.data?.status) {
				handleModal();
				handleVerification();
				verifyOtpFormik?.resetForm();
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};

	const verifyOtpFormik = useFormik({
		initialValues: {
			otp: ''
		},
		validationSchema,
		validateOnChange: false,
		validateOnBlur: false,
		onSubmit: handelOnsubmit
	});

	return (
		<CustomModal
			isOpen={isOpen}
			size="md"
			handleModal={handleModal}
			modalClassName="verifyOtp"
		>
			<ModalWrapper {...allThemeData}>
				<div className="pa-b-30 d-flex justify-content-center">
					<img
						src={passwordImage}
						alt="password-icon"
						className="phoneImage"
					/>
				</div>
				<p className="fs-24 bold-text text-center pa-b-12">
					OTP Verification
				</p>
				<p className="fs-12 medium-text modalSubTitle text-center pa-b-30">
					Please enter your 6 digit verification code
				</p>
				<form
					className="otpForm text-center"
					onSubmit={verifyOtpFormik.handleSubmit}
				>
					<OtpInput
						value={verifyOtpFormik?.values?.otp}
						onChange={handleOTPChange}
						numInputs={6}
						containerStyle={{
							justifyContent: 'center'
						}}
						shouldAutoFocus={true}
					/>
					{verifyOtpFormik?.errors?.otp && (
						<p className="fs-10 semi-bold-text headingTextColor pa-t-6">
							{verifyOtpFormik?.errors?.otp}
						</p>
					)}
					<div className="pa-t-24">
						<CustomButton
							type="submit"
							className="themeButtonFullWidth"
							loading={loading}
						>
							Verify
						</CustomButton>
					</div>
				</form>
			</ModalWrapper>
		</CustomModal>
	);
};

export default SecurityVerifyOtpModal;
