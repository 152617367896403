import { useState } from 'react';
import { useSelector } from 'react-redux';
import { TabContent, TabPane } from 'reactstrap';
import { Tabs, Tab } from 'react-tabs-scrollable';
import 'react-tabs-scrollable/dist/rts.css';

import { PageWrapper, TabsWrapper } from './settings.style';
import PageStructure from '../../Components/Common/PageStructure';
import ServiceType from '../../Components/Settings/ServiceType';
import WaitTime from '../../Components/Settings/WaitTime';
import PickupLocation from '../../Components/Settings/PickupLocation';
import PromoCode from '../../Components/Settings/PromoCode';
import Taxes from '../../Components/Settings/Taxes';

export const Settings = () => {
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	const [tabId, setTabId] = useState(0);
	const tabTitle = [
		'Service Type',
		'Wait Times',
		'Pick Up Locations',
		'Promo Codes',
		'Taxes'
	];
	const onTabClick = (e, index) => {
		setTabId(index);
	};
	return (
		<>
			<PageStructure
				title="Venue Settings"
				pageText={
					<TabsWrapper {...allThemeData}>
						<div className="customScrollableNavbar position-relative pa-t-24">
							<Tabs
								activeTab={tabId}
								onTabClick={onTabClick}
								leftBtnIcon={
									<i className="fa fa-angle-left"></i>
								}
								rightBtnIcon={
									<i className="fa fa-angle-right"></i>
								}
								navBtnsIconColor={'#4F4F4F'}
								className="customScrollableNavbar"
							>
								{tabTitle?.length > 0 &&
									tabTitle?.map((item, i) => (
										<Tab
											key={i}
											className={`customScrollableNavItem fs-12 regular-text ${
												tabId === i ? 'active' : ''
											}`}
										>
											{item}
										</Tab>
									))}
							</Tabs>
							<div className="borderBottom position-absolute bottom-0 w-100 zIndex-1" />
						</div>
					</TabsWrapper>
				}
			>
				<PageWrapper {...allThemeData}>
					<TabContent activeTab={tabId} className="overflow-hidden">
						<TabPane tabId={0} key={0}>
							{tabId === 0 && <ServiceType />}
						</TabPane>
						<TabPane tabId={1} key={1}>
							{tabId === 1 && <WaitTime />}
						</TabPane>
						<TabPane tabId={2} key={2}>
							{tabId === 2 && <PickupLocation />}
						</TabPane>
						<TabPane tabId={3} key={3}>
							{tabId === 3 && <PromoCode />}
						</TabPane>
						<TabPane tabId={4} key={4}>
							{tabId === 4 && <Taxes />}
						</TabPane>
					</TabContent>
				</PageWrapper>
			</PageStructure>
		</>
	);
};
