export const formatResponseData = (data) => {
	let tableData = [];
	if (data?.length > 0) {
		tableData = data.map((item) => {
			return {
				id: item?.productID,
				menu_item_names: item?.product_name,
				category: item?.sub_category_name,
				sold_quantity: item?.soldQunitity
			};
		});
	}
	return tableData;
};

export const tableColumns = [
	{
		Header: <span className="fs-12 medium-text">Menu Item Names</span>,
		accessor: 'menu_item_names',
		className: 'justify-content-start fs-12 regular-text',
		minWidth: 150,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class justify-content-start'
	},
	{
		Header: <span className="fs-12 medium-text">Category</span>,
		accessor: 'category',
		className: 'justify-content-start fs-12 regular-text',
		minWidth: 150,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class justify-content-start'
	},
	{
		Header: 'Sold Quantity',
		accessor: 'sold_quantity',
		className: 'fs-12 regular-text',
		minWidth: 120,
		filterable: false,
		sortable: false,
		headerClassName: 'react-table-header-class fs-12 medium-text'
	}
];

export const summaryReportData = [
	{
		id: 1,
		menu_item_names: 'Salmon (Benny Side)',
		category: 'Pizza',
		sold_quantity: 10
	},
	{
		id: 2,
		menu_item_names: 'Extra Cheese',
		category: 'Coffee',
		sold_quantity: 42
	},
	{
		id: 3,
		menu_item_names: 'Crispy Bacon',
		category: 'Extras',
		sold_quantity: 43
	},
	{
		id: 4,
		menu_item_names: 'Hard Poached Eggs',
		category: 'Hot Drinks',
		sold_quantity: 8
	},
	{
		id: 5,
		menu_item_names: 'Chips',
		category: 'Pizza',
		sold_quantity: 13
	},
	{
		id: 6,
		menu_item_names: 'Fried Beet Cruches',
		category: 'Hot Drinks',
		sold_quantity: 18
	},
	{
		id: 7,
		menu_item_names: 'Sweet Potato Lasagna',
		category: 'Extras',
		sold_quantity: 20
	},
	{
		id: 8,
		menu_item_names: 'Spinach Pury',
		category: 'Coffee',
		sold_quantity: 33
	},
	{
		id: 9,
		menu_item_names: 'Bean Curry',
		category: 'Pizza',
		sold_quantity: 27
	},
	{
		id: 10,
		menu_item_names: 'Dragon Fruit Crush',
		category: 'Coffee',
		sold_quantity: 17
	},
	{
		id: 11,
		menu_item_names: 'Fried Beet Cruches',
		category: 'Hot Drinks',
		sold_quantity: 18
	},
	{
		id: 12,
		menu_item_names: 'Sweet Potato Lasagna',
		category: 'Extras',
		sold_quantity: 20
	},
	{
		id: 13,
		menu_item_names: 'Spinach Pury',
		category: 'Coffee',
		sold_quantity: 33
	},
	{
		id: 14,
		menu_item_names: 'Bean Curry',
		category: 'Pizza',
		sold_quantity: 27
	},
	{
		id: 15,
		menu_item_names: 'Dragon Fruit Crush',
		category: 'Coffee',
		sold_quantity: 17
	}
];
