import { useState } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import * as validation from '../../../Helper/YupValidation';
import Api from '../../../Helper/Api';
import {
	CommonRoutes,
	VenueApiRoutes,
	VenuePanelRoutes,
	VenueProtectedRoutes
} from '../../../Utils/routes';
import CustomButton from '../../Common/CustomButton';
import authActions from '../../../Redux/auth/actions';
import { useDispatch } from 'react-redux';
import NewCustomModal from '../../Common/Modal/NewCustomModal';
import NewVerifyModalWrapper from '../../Authentication/newVerifyOtp.style';
import NewFormInput from '../../Form/NewFormInput';
import { useNavigate } from 'react-router-dom';

const NewVerifyPhoneOtpModal = ({
	isOpen,
	handleModal,
	resetForm,
	navigateTo,
	resend,
	modalData,
	setDisable,
	refetch,
	routeState
}) => {
	const [otp, setOtp] = useState('');
	const [loading, setLoading] = useState(false);
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	const authData = useSelector((state) => ({ ...state.auth }));
	const dispatch = useDispatch();

	const navigate = useNavigate();

	const handleOTPChange = (otp) => {
		setOtp(otp);
		verifyOtpFormik.setFieldValue('otp', otp);
	};

	const validationSchema = yup.object().shape({
		otp: validation.YUP_VALIDATION.OTP
	});

	const createVenueAccount = async () => {
		try {
			setLoading(true);

			const res = await Api(
				'POST',
				VenueApiRoutes.createVenue,
				modalData
			);
			if (res?.data?.status) {
				setLoading(false);
				setDisable(true);
				toast.success(res?.data?.message);
				// resetForm();
				dispatch(authActions.prevenue_account_process(true));

				dispatch(
					authActions.prevenue_account_process_change(
						'/create-stripe-account'
					)
				);

				navigate(VenueProtectedRoutes.createStripeAccount, {
					state: res?.data
				});

				// dispatch(authActions.venue_change_login(true));

				handleModal();
			} else {
				setDisable(true);
				toast.error(res?.data?.message || 'Something went wrong');
			}

			setLoading(false);
		} catch (err) {
			setDisable(true);
			setLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};

	const handelOnsubmit = async () => {
		const payload = {
			email: modalData.get('email'),
			otp: verifyOtpFormik?.values?.otp
		};
		try {
			setLoading(true);
			const res = await Api(
				'POST',
				'/venue/bar/create-venue-otp-verify',
				payload
			);
			setLoading(false);
			if (res?.data?.status) {
				verifyOtpFormik.resetForm();
				toast.success(res?.data?.message);
				await createVenueAccount();
			} else {
				toast.error(res?.data?.message);
			}
		} catch (err) {
			setLoading(false);
			if (err?.message) {
				toast.error(err?.message);
			}
		}
	};

	const verifyOtpFormik = useFormik({
		initialValues: {
			otp: ''
		},
		validationSchema,
		validateOnChange: false,
		validateOnBlur: false,
		onSubmit: handelOnsubmit
	});

	return (
		<NewCustomModal
			isOpen={isOpen}
			size="md"
			handleModal={handleModal}
			modalClassName="verifyOtp"
		>
			<NewVerifyModalWrapper>
				<p className="verifyOtpHeading">
					Your security is our priority
				</p>
				<p className="verifyOtpSubText">
					Please enter the 6 digit verification code on your{' '}
					<span className="verifyOtpSubText-auth">venue email</span>
				</p>
				<form onSubmit={verifyOtpFormik.handleSubmit}>
					<NewFormInput
						name="otp"
						placeholder="Verification code"
						type="text"
						maxlength="6"
						onBlur={verifyOtpFormik.handleBlur}
						value={verifyOtpFormik?.values?.otp}
						onChange={verifyOtpFormik?.handleChange}
						error={verifyOtpFormik?.errors?.otp}
						errorMsg={
							verifyOtpFormik?.touched?.otp &&
							verifyOtpFormik?.errors?.otp
						}
					/>

					<div className="pa-t-20">
						<CustomButton
							type="submit"
							className="newThemeButtonFullWidth"
							loading={loading}
						>
							Verify
						</CustomButton>
					</div>
					<p className="helperText mt-2 mb-4">
						Didn’t receive a text? Click{' '}
						<span className="spanLink" onClick={resend}>
							here
						</span>{' '}
						to re-send.
					</p>
				</form>
			</NewVerifyModalWrapper>
		</NewCustomModal>
	);
};

export default NewVerifyPhoneOtpModal;
