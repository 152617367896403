import styled from 'styled-components';

const PageWrapper = styled.div`
	background-color: #ffffff;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
	border-radius: 16px;
	padding: 20px;
	height: calc(100% - 51px);
	color: ${(props) => props.layoutTheme.textColor} !important;
	@media only screen and (max-width: 767px) {
		height: 100%;
		padding: 10px;
		margin: 0px;
		margin-bottom: 10px;
		border-radius: 16px;
	}
	@media only screen and (max-width: 575.98px) {
		border-radius: 0px;
	}
	.pageButton {
		background-color: #fffdfd !important;
		color: ${(props) => props.layoutTheme.buttonColor2} !important;
	}
	.boxCard {
		border: 1px solid #eaeaea;
		border-radius: 8px;
	}
	.activeText {
		color: ${(props) => props.layoutTheme.buttonColor3} !important;
		text-decoration: underline;
	}
`;

export default PageWrapper;
