import styled from 'styled-components';

const PageWrapper = styled.div`
	.defaultItemCard {
		height: 170px !important;
		border: 1px solid #dddddd;
		border-radius: 8px;
		.itemImage img {
			width: 116px;
			height: 122px;
			border-radius: 8px;
			object-fit: cover;
		}
		@media only screen and (max-width: 1600px) {
			height: 126px !important;
			.itemImage img {
				width: 86px;
				height: 92px;
			}
		}
		@media only screen and (max-width: 991px) {
			height: unset !important;
		}
	}
	.dragHandler {
		padding: 0 4px !important;
	}
	.itemTitle {
		text-overflow: ellipsis;
		overflow: hidden;
		display: -webkit-box !important;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		white-space: normal;
	}
	.itemDescription {
		text-overflow: ellipsis;
		overflow: hidden;
		display: -webkit-box !important;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		white-space: normal;
	}
`;

export default PageWrapper;
