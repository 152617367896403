import { toast } from 'react-toastify';

export const handleFirebaseError = (err) => {
	switch (err.code) {
		case 'auth/too-many-requests':
			toast.error('Too many attempts try again later.');
			break;

		case 'auth/invalid-phone-number':
			toast.error('Invalid phone number format.');
			break;

		case 'auth/missing-phone-number':
			toast.error('Phone number is required.');
			break;

		case 'auth/quota-exceeded':
			toast.error('SMS quota for this phone number has been exceeded.');
			break;

		case 'auth/user-disabled':
			toast.error('User account is disabled.');
			break;

		case 'auth/credential-already-in-use':
			toast.error(
				'Phone number already associated with another account.'
			);
			break;

		case 'auth/operation-not-allowed':
			toast.error('Phone number sign-in is not enabled.');
			break;

		default:
			toast.error('An error occurred:', err);
	}
};
