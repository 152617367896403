export const ExportIcon = ({ fill, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 28 24" fill="none">
			<path
				d="M27.2656 14.4844L22.7656 9.98438C22.4844 9.70312 21.9688 9.70312 21.6875 9.98438C21.4062 10.2656 21.4062 10.7812 21.6875 11.0625L24.9219 14.25H10.25C9.82812 14.25 9.5 14.625 9.5 15C9.5 15.4219 9.82812 15.75 10.25 15.75H24.9219L21.6875 18.9844C21.4062 19.2656 21.4062 19.7812 21.6875 20.0625C21.8281 20.2031 22.0156 20.25 22.25 20.25C22.4375 20.25 22.625 20.2031 22.7656 20.0625L27.2656 15.5625C27.5469 15.2812 27.5469 14.7656 27.2656 14.4844ZM17.75 18C17.3281 18 17 18.375 17 18.75V21C17 21.8438 16.2969 22.5 15.5 22.5H3.5C2.65625 22.5 2 21.8438 2 21V3C2 2.20312 2.65625 1.5 3.5 1.5H9.5V6.75C9.5 8.01562 10.4844 9 11.75 9H17V11.25C17 11.6719 17.3281 12 17.75 12C18.125 12 18.5 11.6719 18.5 11.25V8.01562C18.5 7.21875 18.1719 6.46875 17.6094 5.90625L12.5938 0.890625C12.0312 0.328125 11.2812 0 10.4844 0H3.45312C1.8125 0 0.5 1.35938 0.5 3V21C0.5 22.6875 1.8125 24 3.5 24H15.5C17.1406 24 18.5 22.6875 18.5 21V18.75C18.5 18.375 18.125 18 17.75 18ZM11 1.64062C11.1875 1.6875 11.375 1.78125 11.5625 1.96875L16.5312 6.9375C16.7188 7.125 16.8125 7.3125 16.8594 7.5H11.75C11.3281 7.5 11 7.17188 11 6.75V1.64062Z"
				fill={fill ?? 'white'}
			/>
		</svg>
	);
};
