import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { format } from 'date-fns';

import PageStructure from '../../Components/Common/PageStructure';
import TableStyle from '../../Components/Common/TableStyle';
import TableV6 from '../../Components/Common/TableV6';
import MarkCompleted from '../../Components/Todo/MarkCompleted';
import FormCheckBox from '../../Components/Form/FormCheckBox';
import { EditIcon, PlusIcon } from '../../Components/Icons';
import PageWrapper from './index.style';
import TodoModal from '../../Components/Todo/TodoModal';
import { todoData } from './utils';

export const Todo = () => {
	const allThemeData = useSelector((state) => ({ ...state.themeChanger }));
	const [tableData, setTableData] = useState(todoData);
	const [markCompleted, setMarkCompleted] = useState(false);
	const [todoModal, setTodoModal] = useState(false);
	const [type, setType] = useState('add');
	const [modalData, setModalData] = useState({});

	const columns = [
		{
			Header: 'Completed',
			accessor: 'id',
			className: 'text-center',
			filterable: false,
			sortable: false,
			width: 120,
			headerClassName: 'react-table-header-class fs-16 medium-text',
			Cell: ({ row }) => (
				<FormCheckBox
					name="status"
					id="status"
					onChange={() => setMarkCompleted(!markCompleted)}
				/>
			) // Custom cell components!
		},
		{
			Header: 'Status',
			accessor: 'status',
			className: '',
			filterable: false,
			sortable: false,
			minWidth: 100,
			headerClassName:
				'react-table-header-class fs-16 medium-text text-left',
			Cell: ({ row }) => (
				<span
					className={`${row._original.tag} p-2 fs-12 medium-text statusTag`}
				>
					Status Tag
				</span>
			) // Custom cell components!
		},
		{
			Header: 'Priority',
			accessor: 'priority',
			className: 'text-dark',
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-16 medium-text'
		},
		{
			Header: 'Task',
			accessor: 'task',
			className: 'justify-content-start text-dark',
			filterable: false,
			sortable: false,
			minWidth: 400,
			headerClassName:
				'react-table-header-class fs-16 medium-text justify-content-start',
			Cell: ({ row }) => (
				<div className="d-flex flex-column gap-1 fs-12 medium-text">
					{row.task}
				</div>
			) // Custom cell components!
		},
		{
			Header: 'Assigned To',
			accessor: 'assigned_to',
			className: 'text-dark',
			filterable: false,
			sortable: false,
			minWidth: 150,
			headerClassName: 'react-table-header-class fs-16 medium-text'
		},
		{
			Header: 'Due Date',
			accessor: 'due_date',
			className: 'text-dark',
			filterable: false,
			sortable: false,
			headerClassName: 'react-table-header-class fs-16 medium-text',
			Cell: ({ row }) => format(new Date(row.due_date), 'dd/MM/Y') // Custom cell components!
		},
		{
			Header: 'Manage',
			accessor: 'action',
			className: 'text-center',
			headerClassName: 'react-table-header-class fs-16 medium-text',
			sortable: false,
			filterable: false,
			width: 100,
			Cell: ({ row }) => (
				<div className="react-action-class">
					<Button
						className="background-transparent no-border shadow-none"
						onClick={() => {
							setModalData(row._original);
							setType('edit');
							setTodoModal(true);
						}}
					>
						<div className="fs-14 medium-text">
							<EditIcon height="28px" width="28px" />
						</div>
					</Button>
				</div>
			) // Custom cell components!
		}
	];

	const handleMarkCompletedModal = () => {
		setMarkCompleted((prev) => !prev);
	};
	const handleTodoModal = () => {
		setTodoModal((prev) => !prev);
	};

	const handleSortBy = (sortBy) => {
		// setParams((prev) => ({ ...prev, sortBy: sortBy[0]?.id || "id", order: sortBy[0]?.desc ? "DESC" : "ASC" }));
	};

	return (
		<PageStructure
			title="To Do List"
			buttonName="Add Task"
			buttonIcon={<PlusIcon height="18" width="18" className="mr-10" />}
			handleButtonClick={() => {
				setType('add');
				setTodoModal(true);
			}}
			search={false}
		>
			<PageWrapper {...allThemeData}>
				<div className="mtb-15">
					<div className="roe-card-body">
						<TableStyle version={6}>
							<TableV6
								columns={columns}
								data={tableData}
								handleSortBy={handleSortBy}
								key={'master-todo-table'}
							/>
							{/* {data.length ? (
                <Pagination
                  handlePageChange={handlePageChange}
                  // pageSize={10}
                  // total={data?.totalCount || 0}
                  // currentPage={params.pageNo}
                  pageSize={5}
                  total={10}
                  currentPage={1}
                />
              ) : null} */}
						</TableStyle>
					</div>
				</div>
				<MarkCompleted
					isOpen={markCompleted}
					handleModal={handleMarkCompletedModal}
				/>
				<TodoModal
					isOpen={todoModal}
					handleModal={handleTodoModal}
					type={type}
					modalData={modalData}
				/>
			</PageWrapper>
		</PageStructure>
	);
};
