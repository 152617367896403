const ReportsIcon = ({ fill, ...props }) => {
	return (
		<svg {...props} viewBox="0 0 32 32" fill="none">
			<path
				d="M26 4H5C3.3125 4 2 5.35938 2 7V20.5C2 22.1875 3.3125 23.5 5 23.5H11.9844L11.0938 26.5H8.75C8.32812 26.5 8 26.875 8 27.25C8 27.6719 8.32812 28 8.75 28H22.25C22.625 28 23 27.6719 23 27.25C23 26.875 22.625 26.5 22.25 26.5H19.8594L18.9688 23.5H26C27.6406 23.5 29 22.1875 29 20.5V7C29 5.35938 27.6406 4 26 4ZM12.6406 26.5L13.5781 23.5H17.375L18.3125 26.5H12.6406ZM27.5 20.5C27.5 21.3438 26.7969 22 26 22H5C4.15625 22 3.5 21.3438 3.5 20.5V17.5H27.5V20.5ZM27.5 16H3.5V7C3.5 6.20312 4.15625 5.5 5 5.5H26C26.7969 5.5 27.5 6.20312 27.5 7V16Z"
				fill={fill ?? '#242424'}
			/>
			<path
				d="M17 8.75C16.5781 8.75 16.25 8.42188 16.25 8C16.25 7.60156 16.5781 7.25 17 7.25H20.75C21.1484 7.25 21.5 7.60156 21.5 8V11.75C21.5 12.1719 21.1484 12.5 20.75 12.5C20.3281 12.5 20 12.1719 20 11.75V9.82812L16.0156 13.7891C15.7344 14.0938 15.2422 14.0938 14.9609 13.7891L12.5 11.3281L9.26562 14.5391C8.98438 14.8438 8.49219 14.8438 8.21094 14.5391C7.90625 14.2578 7.90625 13.7656 8.21094 13.4844L11.9609 9.73438C12.2422 9.42969 12.7344 9.42969 13.0156 9.73438L15.5 12.1953L18.9219 8.75H17Z"
				fill={fill ?? '#242424'}
			/>
		</svg>
	);
};

export default ReportsIcon;
