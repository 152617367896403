const Grid = ({ children, columns, className }) => {
	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: `repeat(${columns}, 1fr)`,
				gridGap: 20,
				padding: '24px 0px'
			}}
			className={className}
		>
			{children}
		</div>
	);
};

export default Grid;
